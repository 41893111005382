import { IAutocompleteBasicProps } from "@/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { FieldUnit } from "../../schema/types";
import { useHealthParameterUnitsAutocompleteQuery } from "./gql";

export const HealthParameterUnitsAutocomplete: FC<IAutocompleteBasicProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, loading } = useHealthParameterUnitsAutocompleteQuery({
    skip: props.disabled || skip,
  });

  const _data = data?.getHealthParameterUnits as FieldUnit[];

  const options = mapToAutocompleteOptions(_data, "id", item => pickLocalizedValue(item?.display!, item?.arabicDisplay!));

  return <FormAutocomplete {...rest} loading={loading} options={options} label={label || (props.multiple ? t("Units") : t("Unit"))} />;
};
