import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { SubListsAutocompleteQuery } from "./gql";

export type ISubListAutocomplete = ExtractNodeType<SubListsAutocompleteQuery>;

export const subListSchema = createZodAutocompleteFromType<ISubListAutocomplete>({
  id: z.string().nullish(),
  display: z.string().nullish(),
});
