import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useBulkMessagesSendMutation, useSendBulkMessagesBySearchCriteriaMutation } from "../../gql";
import { convertBulkMessagesFormValuesToBackEndValues, convertBulkMessagesSendByCriteriaFormValuesToBackEndValues } from "../../others";
import { BulkMessageSenderUpsertForm, IBulkMessageSenderUpsertFormEvent } from "../../forms/BulkMessageSender/BulkMessageSenderForm";
import { useSetBulkMessageSenderBreadcrumbs } from "../../hooks";
import {
  BulkMessageSearchCriteriaModal,
  BulkMessageSearchCriteriaModalApi,
} from "../../modals/BulkMessageSearchCriteriaModal/BulkMessageSearchCriteriaModal";
import {
  BulkMessageSenderUsersModal,
  BulkMessageSenderUsersModalApi,
} from "../../modals/BulkMessageSenderUsersModal/BulkMessageSenderUsersModal";
import { IBulkMessageSenderFormValues } from "../../forms/BulkMessageSender/BulkMessageSenderFormSchema";
import { IBulkMessageSenderUsersFormValues } from "../../forms/BulkMessageSenderUsers/BulkMessageSenderUsersFormSchema";
import { IBulkMessageSearchCriteriaFormValues } from "../../forms/BulkMessagesSearchCriteria/BulkMessagesSearchCriteriaFormSchema";

export const BulkMessageSenderContainer = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const [sendBulkMessages, { loading: isSubmitting }] = useBulkMessagesSendMutation({
    onCompleted: data => {
      if (!data?.sendBulkMessages?.success) {
        const error = t(formatMessageErrors(data?.sendBulkMessages?.accountErrors));
        failed(error);
      } else {
        succeeded(t("Bulk messages sent successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const [SendBulkMessagesByCriteria, { loading: isSubmittingByCriteria }] = useSendBulkMessagesBySearchCriteriaMutation({
    onCompleted: data => {
      if (!data?.sendBulkMessagesBySearchCriteria?.success) {
        const error = t(formatMessageErrors(data?.sendBulkMessagesBySearchCriteria?.accountErrors));
        failed(error);
      } else {
        succeeded(t("Bulk messages sent successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onBulkMessageSenderUsersUpsertFormChange = (values: IBulkMessageSenderFormValues & IBulkMessageSenderUsersFormValues) => {
    sendBulkMessages({
      variables: {
        input: convertBulkMessagesFormValuesToBackEndValues(values),
      },
    });
  };

  const onBulkMessageSendByCriteria = (values: IBulkMessageSenderFormValues & IBulkMessageSearchCriteriaFormValues) => {
    SendBulkMessagesByCriteria({
      variables: {
        input: convertBulkMessagesSendByCriteriaFormValuesToBackEndValues(values),
      },
    });
  };

  const onBulkMessageSenderUpsertFormChange = (event: IBulkMessageSenderUpsertFormEvent) => {
    const { values } = event.payload;
    if (values?.publishWithCriteria) {
      BulkMessageSearchCriteriaModalApi.open(values);
    } else {
      BulkMessageSenderUsersModalApi.open(values);
    }
  };

  useSetBulkMessageSenderBreadcrumbs("SEND");

  return (
    <>
      <BulkMessageSenderUpsertForm isSubmitting={isSubmitting} onChange={onBulkMessageSenderUpsertFormChange} />
      <BulkMessageSearchCriteriaModal isLoading={isSubmittingByCriteria} hasClose={true} onFormValuesSubmit={onBulkMessageSendByCriteria} />
      <BulkMessageSenderUsersModal isLoading={false} hasClose={true} onFormValuesSubmit={onBulkMessageSenderUsersUpsertFormChange} />
    </>
  );
};
