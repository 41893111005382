import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const AgentUpsertFormSchema = z.object({
  conditionsAndRules: z.string().optional(),
  name: z
    .string()
    .min(1, i18n.t("Name is required", { ns: "domains" }))
    .max(255),
  description: z
    .string()
    .min(1, i18n.t("Description is required", { ns: "domains" }))
    .max(255),
  code: z.string(),
});

export type IAgentUpsertFormValues = z.infer<typeof AgentUpsertFormSchema>;

export const agentUpsertFormSchemaDefaultValues: Partial<IAgentUpsertFormValues> = {
  name: "",
  description: "",
  conditionsAndRules: "",
  code: "",
};
