import { Grid, ValueWithLabel } from "@toolkit/ui";
import { Address, Maybe } from "@/schema/types";
import { FC } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";

type AddressInformationProps = {
  address?: Maybe<Address>;
};

export const AddressInformation: FC<AddressInformationProps> = props => {
  const { address } = props;

  const { t } = useTranslation("domains");

  if (!address) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ValueWithLabel
          label={t("Street Address 1")}
          value={pickLocalizedValue(address?.streetAddress1, address?.streetAddress1Ar) || "-"}
        />
      </Grid>

      <Grid item xs={6}>
        <ValueWithLabel
          label={t("Street Address 2")}
          value={pickLocalizedValue(address?.streetAddress2, address?.streetAddress2Ar) || "-"}
        />
      </Grid>

      <Grid item xs={6}>
        <ValueWithLabel label={t("Area")} value={pickLocalizedValue(address?.area, address?.areaAr) || "-"} />
      </Grid>

      <Grid item xs={6}>
        <ValueWithLabel label={t("City")} value={pickLocalizedValue(address?.city?.name, address?.city?.nameAr) || "-"} />
      </Grid>

      <Grid item xs={6}>
        <ValueWithLabel label={t("Postal Code")} value={address?.postalCode || "-"} />
      </Grid>
    </Grid>
  );
};
