interface SadaConfig {
  sadaBaseUrl: string;
  sadaApiEnv: string;
  sadaApiBaseUrl: string;
}

class Config {
  private static instance: Config;
  private config: SadaConfig | null = null;

  private constructor() {}

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  loadConfig(config: SadaConfig): void {
    this.config = config;
  }

  getConfig(): SadaConfig {
    if (!this.config) {
      throw new Error("Configuration has not been loaded. Please call loadConfig() first.");
    }
    return this.config;
  }

  get(key: keyof SadaConfig): string {
    const config = this.getConfig();
    if (config[key] !== undefined) {
      return config[key];
    } else {
      throw new Error(`Key ${key} is not present in the configuration.`);
    }
  }
}

export const sadaConfig = Config.getInstance();

export const initializeSadaConfig = (config: SadaConfig): void => {
  sadaConfig.loadConfig(config);
};
