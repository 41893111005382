import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type AddressFragmentFragment = { __typename?: 'Address', id: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, area: string, areaAr: string, postalCode: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null };

export const AddressFragmentFragmentDoc = gql`
    fragment AddressFragment on Address {
  id
  streetAddress1
  streetAddress1Ar
  streetAddress2
  streetAddress2Ar
  area
  areaAr
  postalCode
  city {
    id
    name
    nameAr
  }
  coordinates {
    lng
    lat
  }
}
    `;