import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthProgramNetworksProvidersAutocompleteQuery } from "./gql";

export type IHealthProgramNetworkProviderAutocomplete = ExtractNodeType<HealthProgramNetworksProvidersAutocompleteQuery>;

export const healthProgramNetworkProviderSchema = createZodAutocompleteFromType<IHealthProgramNetworkProviderAutocomplete>({
  id: z.string(),
  provider: z
    .object({
      id: z.string(),
      name: z.string(),
      nameAr: z.string().nullish(),
    })
    .nullish(),
});
