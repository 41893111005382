import { Address, HealthProgramMember, Maybe, Payer } from "@/schema/types";
import { i18n, pickLocalizedValue } from "@toolkit/i18n";
import { InfoItems } from "@toolkit/ui";
import moment from "moment";

export const getAddressesInfoItems = (address?: Maybe<Address>): InfoItems => {
  return address
    ? [
        {
          label: i18n.t("City", { ns: "admin" }),
          value: pickLocalizedValue(address?.city?.name, address?.city?.nameAr) || "-",
        },
        {
          label: i18n.t("Area", { ns: "admin" }),
          value: pickLocalizedValue(address?.area, address?.areaAr) || "-",
        },
        {
          label: i18n.t("Street Address 1", { ns: "admin" }),
          value: pickLocalizedValue(address?.streetAddress1, address?.streetAddress1Ar) || "-",
          truncate: true,
        },
        {
          label: i18n.t("Street Address 2", { ns: "admin" }),
          value: pickLocalizedValue(address?.streetAddress2, address?.streetAddress2Ar) || "-",
        },
        {
          label: i18n.t("Building Number", { ns: "admin" }),
          value: address?.buildingNumber || "-",
        },
      ]
    : undefined;
};

export const getInsuranceInformationItems = (
  patientActiveHealthProgram?: Maybe<
    Pick<HealthProgramMember, "insuranceId" | "membershipStart" | "membershipEnd"> & {
      payer?: Maybe<Pick<Payer, "name" | "nameAr">>;
    }
  >
): InfoItems | undefined => {
  if (i18n.language === "ar") {
    moment.locale("ar");
  }

  return patientActiveHealthProgram
    ? [
        {
          label: i18n.t("Company Name", { ns: "admin" }),
          value: pickLocalizedValue(patientActiveHealthProgram.payer?.name, patientActiveHealthProgram.payer?.nameAr) || "-",
        },
        {
          label: i18n.t("Card Id", { ns: "admin" }),
          value: patientActiveHealthProgram.insuranceId || "-",
        },
        {
          label: i18n.t("Date", { ns: "admin" }),
          value:
            patientActiveHealthProgram.membershipStart && patientActiveHealthProgram.membershipEnd
              ? `${moment(patientActiveHealthProgram.membershipStart).format("D MMM YYYY")} - ${moment(
                  patientActiveHealthProgram.membershipEnd
                ).format("D MMM YYYY")}`
              : "-",
        },
      ]
    : undefined;
};
