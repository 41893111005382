import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSurveyMutationVariables = Types.Exact<{
  updateSurveyId: Types.Scalars['ID']['input'];
  input: Types.SurveyInput;
}>;


export type UpdateSurveyMutation = { __typename?: 'Mutation', updateSurvey?: { __typename?: 'Survey', description?: string | null, id?: string | null, label?: string | null, notification?: string | null, status?: Types.SurveyStatus | null, updateDate?: any | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', code?: Types.SurveyErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null, questions?: Array<{ __typename?: 'Question', id?: string | null, options?: Array<string | null> | null, questionType?: Types.QuestionType | null, required?: boolean | null }> | null } | null };


export const UpdateSurveyDocument = gql`
    mutation UpdateSurvey($updateSurveyId: ID!, $input: SurveyInput!) {
  updateSurvey(id: $updateSurveyId, input: $input) {
    description
    id
    label
    notification
    errors {
      code
      errorType
      field
      message
    }
    questions {
      id
      options
      questionType
      required
    }
    status
    updateDate
  }
}
    `;
export type UpdateSurveyMutationFn = Apollo.MutationFunction<UpdateSurveyMutation, UpdateSurveyMutationVariables>;

/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      updateSurveyId: // value for 'updateSurveyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSurveyMutation, UpdateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSurveyMutation, UpdateSurveyMutationVariables>(UpdateSurveyDocument, options);
      }
export type UpdateSurveyMutationHookResult = ReturnType<typeof useUpdateSurveyMutation>;
export type UpdateSurveyMutationResult = Apollo.MutationResult<UpdateSurveyMutation>;
export type UpdateSurveyMutationOptions = Apollo.BaseMutationOptions<UpdateSurveyMutation, UpdateSurveyMutationVariables>;