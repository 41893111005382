import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllBlockQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.BlockOrder>;
  filter?: Types.InputMaybe<Types.BlockFilterInput>;
}>;


export type GetAllBlockQuery = { __typename?: 'Query', blocks?: { __typename?: 'BlockCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BlockCountableEdge', node: { __typename?: 'Block', id: string, name: string, nameAr?: string | null, code: string, created: any, city: { __typename?: 'City', name: string, nameAr?: string | null, code: string } } }> } | null };


export const GetAllBlockDocument = gql`
    query getAllBlock($last: Int, $first: Int, $after: String, $before: String, $sortBy: BlockOrder, $filter: BlockFilterInput) {
  blocks(
    last: $last
    first: $first
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        city {
          name
          nameAr
          code
        }
        name
        nameAr
        code
        created
      }
    }
  }
}
    `;

/**
 * __useGetAllBlockQuery__
 *
 * To run a query within a React component, call `useGetAllBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlockQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllBlockQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBlockQuery, GetAllBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBlockQuery, GetAllBlockQueryVariables>(GetAllBlockDocument, options);
      }
export function useGetAllBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBlockQuery, GetAllBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBlockQuery, GetAllBlockQueryVariables>(GetAllBlockDocument, options);
        }
export function useGetAllBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllBlockQuery, GetAllBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllBlockQuery, GetAllBlockQueryVariables>(GetAllBlockDocument, options);
        }
export type GetAllBlockQueryHookResult = ReturnType<typeof useGetAllBlockQuery>;
export type GetAllBlockLazyQueryHookResult = ReturnType<typeof useGetAllBlockLazyQuery>;
export type GetAllBlockSuspenseQueryHookResult = ReturnType<typeof useGetAllBlockSuspenseQuery>;
export type GetAllBlockQueryResult = Apollo.QueryResult<GetAllBlockQuery, GetAllBlockQueryVariables>;