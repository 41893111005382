import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const BlobSuccessIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='76' height='76.965' viewBox='0 0 76 76.965' {...props}>
      <g id='Group_11344' data-name='Group 11344' transform='translate(-183 -333.457)'>
        <g id='Group_10686' data-name='Group 10686' transform='translate(183 333.457)'>
          <path
            id='Path_12745'
            data-name='Path 12745'
            d='M48.805,76.183C39.85,77.447,31.29,77.33,22.43,74.69c-8.782-2.651-17.849-7.747-20.989-15.3-3.062-7.562-.228-17.533,3.75-26.032A67.488,67.488,0,0,1,20.986,11.413C27.84,4.97,36.706-.9,44.679.113c7.939,1.054,14.985,8.98,20.214,16.818A63.072,63.072,0,0,1,75.232,41.169c1.634,8.739,1.412,18.479-3.39,24.7-4.756,6.25-14.093,8.976-23.037,10.319'
            transform='translate(-0.149 0)'
            fill='#46a200'
            opacity='0.05'
          />
          <path
            id='Path_12746'
            data-name='Path 12746'
            d='M37.04,75.472c-8.524-1.925-16.217-4.964-23.316-10.383C6.7,59.687.253,51.98,0,44.087c-.174-7.876,5.8-15.91,12.31-22.222A65.206,65.206,0,0,1,34.1,7.459C42.5,3.987,52.522,1.729,59.38,5.37c6.812,3.671,10.461,13.242,12.5,22.11a60.908,60.908,0,0,1,1.035,25.427c-1.519,8.451-5.057,17.169-11.525,21.138-6.44,4.014-15.809,3.277-24.348,1.427'
            transform='translate(1.15 -0.062)'
            fill='#46a200'
            opacity='0.1'
          />
        </g>
        <g id='icons8-ok' transform='translate(202.459 353.5)'>
          <path
            id='Path_13239'
            data-name='Path 13239'
            d='M21.521,39.884A17.54,17.54,0,0,1,4,22.364,18.08,18.08,0,0,1,7.87,11.305a1.314,1.314,0,0,1,2.071,1.618,15.44,15.44,0,0,0-3.313,9.441A14.909,14.909,0,0,0,21.521,37.256a15.293,15.293,0,0,0,4.427-.645,13.789,13.789,0,0,0,3.85-1.839,1.314,1.314,0,1,1,1.492,2.163,16.413,16.413,0,0,1-4.582,2.191A17.918,17.918,0,0,1,21.521,39.884Z'
            transform='translate(0 -0.843)'
            fill='#46a200'
          />
          <path
            id='Path_13240'
            data-name='Path 13240'
            d='M37.959,29.843a1.315,1.315,0,0,1-1.194-1.862,15.436,15.436,0,0,0,1.457-6.461A14.909,14.909,0,0,0,23.33,6.628a13.621,13.621,0,0,0-3.121.317,1.314,1.314,0,0,1-.591-2.561A16.386,16.386,0,0,1,23.33,4,17.54,17.54,0,0,1,40.851,21.521a18.044,18.044,0,0,1-1.7,7.556A1.314,1.314,0,0,1,37.959,29.843Z'
            transform='translate(-1.81)'
            fill='#46a200'
          />
          <path
            id='Path_13241'
            data-name='Path 13241'
            d='M20.694,29.388A1.31,1.31,0,0,1,19.765,29l-4.38-4.38a1.314,1.314,0,0,1,1.858-1.858l3.451,3.451,7.831-7.831a1.314,1.314,0,1,1,1.858,1.858L21.623,29A1.31,1.31,0,0,1,20.694,29.388Z'
            transform='translate(-1.364 -1.736)'
            fill='#46a200'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
