import { Box, Grid } from "@toolkit/ui";
import React, { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ISurveyUpsertFormValues } from "../SurveyUpsert/surveyUpsertFormSchema";
import { IQuestionOptionPath, ISurveyQuestionOptionManageStateType } from "../../types";
import { SurveyQuestionOptionForm } from "../SurveyQuestionOption/SurveyQuestionOptionForm";
import AddSurveyQuestionOptionForm from "../AddSurveyQuestionOption/AddSurveyQuestionOptionForm";
import { isNil } from "lodash-es";

type SurveyQuestionOptionsFormProps = {
  questionIndex: number;
  canManageSurvey: boolean;
};

export const SurveyQuestionOptionsForm: FC<SurveyQuestionOptionsFormProps> = ({ questionIndex, canManageSurvey }) => {
  const { control, watch } = useFormContext<ISurveyUpsertFormValues>();

  const [questionOptionsManageState, setQuestionOptionsManageState] = React.useState<ISurveyQuestionOptionManageStateType | null>(null);
  const [hoveredOptionIndex, setHoveredOptionIndex] = React.useState<number | undefined>(undefined);

  const onOptionHovered = (option: number | undefined) => {
    setHoveredOptionIndex(option);
  };

  const handleOptionEditButtonClick = (optionIndex?: number | null) => {
    setQuestionOptionsManageState(prev => ({ ...prev, selectedOptionForEditQuestionIndex: optionIndex }));
  };

  const handleOptionCopyButtonClick = (optionIndex: number) => {
    setQuestionOptionsManageState(prev => ({ ...prev, copiedOption: questionOptions?.[optionIndex].value }));
  };

  const questionOptionsPath = `questions.${questionIndex}.options` as const;
  const questionOptions = watch(questionOptionsPath);
  const questionType = watch(`questions.${questionIndex}.questionType`);

  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
    update: updateOption,
  } = useFieldArray<ISurveyUpsertFormValues, IQuestionOptionPath>({
    control,
    name: questionOptionsPath,
  });

  const onAddNewOption = (optionValue: string) => {
    appendOption({ value: optionValue });
  };

  const onRemoveOption = (optionIndex: number) => {
    removeOption(optionIndex);
  };
  const handleValueChange = (optionIndex: number) => (value: string) => updateOption(optionIndex, { value });

  return (
    <>
      {optionFields.map((optionField, index) => (
        <Box key={index} display='contents' onMouseEnter={() => onOptionHovered(index)} onMouseLeave={() => onOptionHovered(undefined)}>
          <SurveyQuestionOptionForm
            key={optionField.id}
            optionIndex={index}
            questionIndex={questionIndex}
            canManageSurvey={canManageSurvey}
            isOptionHovered={!isNil(hoveredOptionIndex) && hoveredOptionIndex === index}
            onRemoveOption={() => onRemoveOption(index)}
            onCopyOption={() => handleOptionCopyButtonClick(index)}
            onEditButtonClick={handleOptionEditButtonClick}
            questionOptionsManageState={questionOptionsManageState}
            questionOptionsType={questionType?.value}
            handleValueChange={handleValueChange(index)}
          />
        </Box>
      ))}

      {canManageSurvey && (
        <Grid item xs={12}>
          <AddSurveyQuestionOptionForm
            copiedValue={questionOptionsManageState?.copiedOption}
            allOptions={questionOptions ?? []}
            onAddOption={onAddNewOption}
            questionType={questionType?.value}
          />
        </Grid>
      )}
    </>
  );
};
