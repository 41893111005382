import { Box, Dot, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getSurveyStatusColor } from "../../others";
import { SurveyStatus } from "@/schema/types";
import { surveyStatusOptionsMap } from "@health/enum-options";

interface SurveyStatusIndicatorProps {
  status?: SurveyStatus | null;
}

export const SurveyStatusIndicator: FC<SurveyStatusIndicatorProps> = props => {
  const { status } = props;
  const theme = useTheme();

  const surveyStatusColor = getSurveyStatusColor(status, theme.palette);
  const surveyStatusText = status && surveyStatusOptionsMap[status]?.label;

  return (
    <Box display='flex' alignItems='center'>
      <Dot background={surveyStatusColor} />
      <Typography paddingLeft={1} color={surveyStatusColor} fontSize={theme.mixins.fonts.fontSize.xs}>
        {surveyStatusText}
      </Typography>
    </Box>
  );
};
