import { Card, Grid, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import ContentLoader from "react-content-loader";

const useSimpleCardLoaderStyles = makeStyles()({
  card: {
    padding: 10,
    height: 250,
    width: "100%",
  },
});

const ManagedListCardLoader: FC = () => {
  const { classes } = useSimpleCardLoaderStyles();
  return (
    <>
      <Grid container padding={4} spacing={2}>
        {new Array(10).fill(0).map((el, key) => (
          <Grid key={key} item xs={12} sm={6} lg={4} xl={3}>
            <Card elevation={1} className={classes.card}>
              <ContentLoader width={334} viewBox='0 0 350 160'>
                <rect x='10' y='73' rx='3' ry='3' width='300' height='8' />
                <rect x='8' y='88' rx='3' ry='3' width='300' height='8' />
                <rect x='8' y='103' rx='3' ry='3' width='300' height='8' />
                <rect x='8' y='118' rx='3' ry='3' width='300' height='8' />
                <rect x='105' y='133' rx='3' ry='3' width='118' height='8' />
                <circle cx='170' cy='27' r='22' />
                <circle cx='130' cy='151' r='6' />
                <circle cx='160' cy='151' r='6' />
                <circle cx='190' cy='151' r='6' />
              </ContentLoader>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ManagedListCardLoader;
