import { Button, Typography, styled } from "@toolkit/ui";
import React, { FC, memo } from "react";

const Root = styled("div")(() => ({
  minHeight: "100%",
  padding: "0 0.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
}));

type SadaErrorProps = {
  title: string;
  subtitle: string;
  onClick: () => void;
};

const SadaError: FC<SadaErrorProps> = ({ title, subtitle, onClick }) => {
  return (
    <Root>
      <h2>{title}</h2>
      <Typography>{subtitle}</Typography>
      <Button onClick={onClick}>Retry</Button>
    </Root>
  );
};

export default memo(SadaError);
