import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowDocsQueryVariables = Types.Exact<{
  chatFlowId: Types.Scalars['ID']['input'];
}>;


export type ChatFlowDocsQuery = { __typename?: 'Query', chatFlow?: { __typename?: 'ChatFlow', id: string, documents?: Array<{ __typename?: 'ChatFlowDocument', createdAt: any, fileName: string, fileSize: number, id: string, fileType: Types.FileType }> | null } | null };


export const ChatFlowDocsDocument = gql`
    query ChatFlowDocs($chatFlowId: ID!) {
  chatFlow(id: $chatFlowId) {
    id
    documents {
      createdAt
      fileName
      fileSize
      id
      fileType
    }
  }
}
    `;

/**
 * __useChatFlowDocsQuery__
 *
 * To run a query within a React component, call `useChatFlowDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatFlowDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatFlowDocsQuery({
 *   variables: {
 *      chatFlowId: // value for 'chatFlowId'
 *   },
 * });
 */
export function useChatFlowDocsQuery(baseOptions: Apollo.QueryHookOptions<ChatFlowDocsQuery, ChatFlowDocsQueryVariables> & ({ variables: ChatFlowDocsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatFlowDocsQuery, ChatFlowDocsQueryVariables>(ChatFlowDocsDocument, options);
      }
export function useChatFlowDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatFlowDocsQuery, ChatFlowDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatFlowDocsQuery, ChatFlowDocsQueryVariables>(ChatFlowDocsDocument, options);
        }
export function useChatFlowDocsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChatFlowDocsQuery, ChatFlowDocsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatFlowDocsQuery, ChatFlowDocsQueryVariables>(ChatFlowDocsDocument, options);
        }
export type ChatFlowDocsQueryHookResult = ReturnType<typeof useChatFlowDocsQuery>;
export type ChatFlowDocsLazyQueryHookResult = ReturnType<typeof useChatFlowDocsLazyQuery>;
export type ChatFlowDocsSuspenseQueryHookResult = ReturnType<typeof useChatFlowDocsSuspenseQuery>;
export type ChatFlowDocsQueryResult = Apollo.QueryResult<ChatFlowDocsQuery, ChatFlowDocsQueryVariables>;