import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { z } from "zod";
import { UsersAutocompleteQuery } from "./gql";

export type IUserAutocomplete = ExtractNodeType<UsersAutocompleteQuery>;

export const userSchema = createZodAutocompleteFromType<IUserAutocomplete>({
  id: z.string(),
  fullName: z.string().nullish(),
});
