import { useTranslation } from "@toolkit/i18n";
import React, { FC, MouseEvent, useRef } from "react";
import { Box, Button, DialogContent, DialogContentText, Typography, useTheme } from "../../../base/mui";
import { ErrorIcon } from "../../../icons";
import { CustomMuiDialog } from "../CustomMuiDialog";
import { DeleteConfirmationDialogProps } from "./types";

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  isOpen,
  onClose: handleClose,
  onDelete: handleDelete,
  isDeleteButtonDisabled = false,
  button,
  text: _text,
}) => {
  const { t } = useTranslation();
  const text = {
    ..._text,
    title: t("Delete Confirmation"),
    body: t("Are you sure you want to perform this action?"),
    subBody: undefined,
  };
  const theme = useTheme();
  const handleConfirmation = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleDelete();
    handleClose?.();
  };
  const childRef = useRef(null);

  return (
    <CustomMuiDialog
      button={React.isValidElement(button) ? button : undefined}
      ref={childRef}
      maxWidth={"xs"}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='delete-confirmation-dialog'
      aria-describedby='delete-confirmation-dialog'
      DialogTitleProps={{
        hasCloseButton: false,
        title: (
          <Typography
            color={theme.palette.error.main}
            fontSize={theme.mixins.fonts.fontSize.md}
            fontWeight={theme.mixins.fonts.fontWeight.medium}
          >
            {text?.title}
          </Typography>
        ),
      }}
      DialogActionsProps={{
        children: (
          <>
            <Box flex={1} />
            <Button variant='text' onClick={handleClose} data-testid='deleteConfirmationDialogCancelButton'>
              {t("Cancel")}
            </Button>
            <Button
              color={"error"}
              variant='contained'
              onClick={handleConfirmation}
              disabled={isDeleteButtonDisabled}
              data-testid='deleteConfirmationDialogConfirmButton'
            >
              {t("Delete")}
            </Button>
          </>
        ),
      }}
    >
      <DialogContent>
        <DialogContentText id='scroll-dialog-description'>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
            <ErrorIcon fill={theme.palette.error.main} sx={{ width: 100, height: 100 }} />
            <Typography color={theme.palette.error.main} fontSize={theme.mixins.fonts.fontSize.xl} marginTop={3} textAlign='center'>
              {text?.body}
            </Typography>
            {text?.subBody && (
              <Typography color={theme.palette.error.main} fontSize={theme.mixins.fonts.fontSize.xl} textAlign='center'>
                {text.subBody}
              </Typography>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
    </CustomMuiDialog>
  );
};
