import { AppTypes, PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { FC } from "react";
import { IUserListEvent, UserList } from "@/pages/Users/components/UserList/UserList";

export type IUserProviderListContainerEvent = IUserListEvent;

type UserProviderListContainerProps = {
  onChange: (event: IUserProviderListContainerEvent) => void;
};

export const UserProviderListContainer: FC<UserProviderListContainerProps> = props => {
  const { onChange } = props;

  const onUserListChange = (event: IUserListEvent) => {
    if (event.type === "ADD_NEW_CLICK") {
      onChange({
        type: "ADD_NEW_CLICK",
      });
    } else if (event.type === "EDIT_CLICK") {
      onChange({
        type: "EDIT_CLICK",
        payload: { item: event.payload.item },
      });
    }
  };

  return (
    <UserList
      filter={{ appType: [AppTypes.Vendor] }}
      isActionsShow={hasPermission(PermissionEnum.ManageUsers)}
      onChange={onUserListChange}
    />
  );
};
