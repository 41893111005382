import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";
import { PermissionsAutocompleteQuery } from "./gql";

export type IPermissionAutocomplete = Omit<NonNullable<NonNullable<PermissionsAutocompleteQuery["permissions"]>[number]>, "__typename">;

export const permissionSchema = createZodAutocompleteFromType<IPermissionAutocomplete>({
  id: z.string(),
  code: z.nativeEnum(PermissionEnum).nullish(),
  name: z.string(),
});
