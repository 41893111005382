import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceOrderCancelBySupportTeamMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  cancellationReason: Types.Scalars['String']['input'];
}>;


export type MarketplaceOrderCancelBySupportTeamMutation = { __typename?: 'Mutation', marketplaceOrderCancelBySupportTeam?: { __typename?: 'MarketplaceOrder', id: string } | null };


export const MarketplaceOrderCancelBySupportTeamDocument = gql`
    mutation MarketplaceOrderCancelBySupportTeam($id: ID!, $cancellationReason: String!) {
  marketplaceOrderCancelBySupportTeam(
    cancellationReason: $cancellationReason
    id: $id
  ) {
    id
  }
}
    `;
export type MarketplaceOrderCancelBySupportTeamMutationFn = Apollo.MutationFunction<MarketplaceOrderCancelBySupportTeamMutation, MarketplaceOrderCancelBySupportTeamMutationVariables>;

/**
 * __useMarketplaceOrderCancelBySupportTeamMutation__
 *
 * To run a mutation, you first call `useMarketplaceOrderCancelBySupportTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOrderCancelBySupportTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceOrderCancelBySupportTeamMutation, { data, loading, error }] = useMarketplaceOrderCancelBySupportTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useMarketplaceOrderCancelBySupportTeamMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceOrderCancelBySupportTeamMutation, MarketplaceOrderCancelBySupportTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceOrderCancelBySupportTeamMutation, MarketplaceOrderCancelBySupportTeamMutationVariables>(MarketplaceOrderCancelBySupportTeamDocument, options);
      }
export type MarketplaceOrderCancelBySupportTeamMutationHookResult = ReturnType<typeof useMarketplaceOrderCancelBySupportTeamMutation>;
export type MarketplaceOrderCancelBySupportTeamMutationResult = Apollo.MutationResult<MarketplaceOrderCancelBySupportTeamMutation>;
export type MarketplaceOrderCancelBySupportTeamMutationOptions = Apollo.BaseMutationOptions<MarketplaceOrderCancelBySupportTeamMutation, MarketplaceOrderCancelBySupportTeamMutationVariables>;