import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { PlanFilterInput } from "@/schema/types";
import { useSubscriptionPlansAutocompleteQuery } from "./gql";

type SubscriptionPlansAutocompleteProps = IAutocompleteProps<PlanFilterInput>;

export const SubscriptionPlansAutocomplete: FC<SubscriptionPlansAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useSubscriptionPlansAutocompleteQuery,
    variables: { filter },
    searchKey: "name_Icontains",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Subscription Plans") : t("Subscription Plan"))}
    />
  );
};
