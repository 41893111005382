import { createContext, useContext } from "react";
import { useAdminManagedListDetailsPage } from "./useAdminManagedListDetailsPageHook";

export const AdminManagedListDetailsPageContext = createContext<Partial<ReturnType<typeof useAdminManagedListDetailsPage>>>({});

export const useAdminManagedListDetailsPageContext = () => {
  const context = useContext(AdminManagedListDetailsPageContext);
  if (!context) {
    throw new Error("Sortable Item component cannot be used outside the Table Preferences Dialog component");
  }
  return context;
};
