import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportPaymentInvoicesMutationVariables = Types.Exact<{
  filter: Types.PaymentInvoiceFilterInput;
}>;


export type ExportPaymentInvoicesMutation = { __typename?: 'Mutation', exportPaymentInvoices?: string | null };


export const ExportPaymentInvoicesDocument = gql`
    mutation ExportPaymentInvoices($filter: PaymentInvoiceFilterInput!) {
  exportPaymentInvoices(filter: $filter)
}
    `;
export type ExportPaymentInvoicesMutationFn = Apollo.MutationFunction<ExportPaymentInvoicesMutation, ExportPaymentInvoicesMutationVariables>;

/**
 * __useExportPaymentInvoicesMutation__
 *
 * To run a mutation, you first call `useExportPaymentInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPaymentInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPaymentInvoicesMutation, { data, loading, error }] = useExportPaymentInvoicesMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExportPaymentInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<ExportPaymentInvoicesMutation, ExportPaymentInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPaymentInvoicesMutation, ExportPaymentInvoicesMutationVariables>(ExportPaymentInvoicesDocument, options);
      }
export type ExportPaymentInvoicesMutationHookResult = ReturnType<typeof useExportPaymentInvoicesMutation>;
export type ExportPaymentInvoicesMutationResult = Apollo.MutationResult<ExportPaymentInvoicesMutation>;
export type ExportPaymentInvoicesMutationOptions = Apollo.BaseMutationOptions<ExportPaymentInvoicesMutation, ExportPaymentInvoicesMutationVariables>;