import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionRecommendationsAutocompleteQuery } from "@/pages/Decisions/gql";

type DecisionRecommendationsAutocompleteProps = {
  name: string;
  label?: string;
};

export const DecisionRecommendationsAutocomplete: FC<DecisionRecommendationsAutocompleteProps> = props => {
  const { name, label } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionRecommendationsAutocompleteQuery,
    searchKey: "name",
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.name!, item?.nameAr!));

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={label || t("Recommendation")} />;
};
