const UNIQUE_KEYS = ["key", "id", "code", "healthLicense", "email"];

const getUniqueValue = (obj: any) => {
  if (!obj) {
    return undefined;
  }

  for (const key of UNIQUE_KEYS) {
    if (obj[key] !== undefined) {
      return obj[key];
    }
  }

  return obj?.user?.id !== undefined ? obj.user.id : undefined;
};

export const isOptionEqualToValue = (option: any, value: any) => {
  const optionKey = getUniqueValue(option);

  if (Array.isArray(value)) {
    return value.some(item => getUniqueValue(item) === optionKey);
  } else {
    return getUniqueValue(value) === optionKey;
  }
};
