import { makeStyles } from "@toolkit/ui";

export const usePossibleSuggestedFixesStyle = makeStyles()(theme => ({
  root: {
    background: theme.palette.error[100],
    borderRadius: 10,
    border: `2px solid ${theme.palette.common.white}`,
    paddingInline: 6,
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: 16,
    paddingInline: 8,
  },
  dividerOpen: {
    marginTop: -8,
  },
  icon: {
    marginInlineEnd: 16,
    padding: 0,
    height: 20,
    width: 20,
    flex: 0,
  },
  header: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "start",
  },
  possibleFixesWrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: 2,
    paddingBlock: 8,
  },
  possibleFix: {
    paddingBlock: 4,
    paddingInline: 8,
    textAlign: "start",
  },
  showButton: {
    padding: 0,
    margin: 0,
    fontSize: theme.mixins.fonts.fontSize.sm,
    width: "fit-content",
    height: "fit-content",
  },
  fixText: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
  },
  divider: {
    borderColor: theme.palette.error[200],
    marginTop: 8,
  },
}));
