import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BenefitMapDeleteMutationVariables = Types.Exact<{
  deleteBenefitMapId: Types.Scalars['ID']['input'];
}>;


export type BenefitMapDeleteMutation = { __typename?: 'Mutation', deleteBenefitMap?: { __typename?: 'BenefitMap', id: string } | null };


export const BenefitMapDeleteDocument = gql`
    mutation BenefitMapDelete($deleteBenefitMapId: ID!) {
  deleteBenefitMap(id: $deleteBenefitMapId) {
    id
  }
}
    `;
export type BenefitMapDeleteMutationFn = Apollo.MutationFunction<BenefitMapDeleteMutation, BenefitMapDeleteMutationVariables>;

/**
 * __useBenefitMapDeleteMutation__
 *
 * To run a mutation, you first call `useBenefitMapDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenefitMapDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benefitMapDeleteMutation, { data, loading, error }] = useBenefitMapDeleteMutation({
 *   variables: {
 *      deleteBenefitMapId: // value for 'deleteBenefitMapId'
 *   },
 * });
 */
export function useBenefitMapDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BenefitMapDeleteMutation, BenefitMapDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BenefitMapDeleteMutation, BenefitMapDeleteMutationVariables>(BenefitMapDeleteDocument, options);
      }
export type BenefitMapDeleteMutationHookResult = ReturnType<typeof useBenefitMapDeleteMutation>;
export type BenefitMapDeleteMutationResult = Apollo.MutationResult<BenefitMapDeleteMutation>;
export type BenefitMapDeleteMutationOptions = Apollo.BaseMutationOptions<BenefitMapDeleteMutation, BenefitMapDeleteMutationVariables>;