import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayerCreateMutationVariables = Types.Exact<{
  input: Types.PayerInput;
}>;


export type PayerCreateMutation = { __typename?: 'Mutation', payerCreate?: { __typename?: 'PayerCreate', payerErrors: Array<{ __typename?: 'PayerError', field?: string | null, message?: string | null }> } | null };


export const PayerCreateDocument = gql`
    mutation PayerCreate($input: PayerInput!) {
  payerCreate(input: $input) {
    payerErrors {
      field
      message
    }
  }
}
    `;
export type PayerCreateMutationFn = Apollo.MutationFunction<PayerCreateMutation, PayerCreateMutationVariables>;

/**
 * __usePayerCreateMutation__
 *
 * To run a mutation, you first call `usePayerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payerCreateMutation, { data, loading, error }] = usePayerCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayerCreateMutation(baseOptions?: Apollo.MutationHookOptions<PayerCreateMutation, PayerCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayerCreateMutation, PayerCreateMutationVariables>(PayerCreateDocument, options);
      }
export type PayerCreateMutationHookResult = ReturnType<typeof usePayerCreateMutation>;
export type PayerCreateMutationResult = Apollo.MutationResult<PayerCreateMutation>;
export type PayerCreateMutationOptions = Apollo.BaseMutationOptions<PayerCreateMutation, PayerCreateMutationVariables>;