/* eslint-disable max-lines */
import { formGirdSpacing, hasPermission } from "@toolkit/core";
import { Grid } from "@toolkit/ui";
import { FC } from "react";
import { ISiteSetting } from "../components/types";
import { GeneralSiteSetting } from "./GeneralSiteSetting.component";
import { MarketplaceSettings } from "./MarketplaceSettings";
import { MobileVersionsSettings } from "./MobileVersionsSettings.component";
import { OrdersSettings } from "./OrdersSettings";
import { PaymentSettings } from "./PaymentSettings.component";
import { DeliveryTimeSlotsSettings } from "./DeliveryTimeSlotsSettings";
import { PermissionEnum } from "@/schema/types";

export const SiteSetting: FC<ISiteSetting> = ({ loading, siteSettings, errors: formErrors }) => {
  return (
    <Grid container spacing={formGirdSpacing}>
      <GeneralSiteSetting errors={formErrors} loading={loading} siteSettings={siteSettings} />
      <MobileVersionsSettings errors={formErrors} loading={loading} siteSettings={siteSettings} />
      <MarketplaceSettings errors={formErrors} loading={loading} siteSettings={siteSettings} />
      <PaymentSettings errors={formErrors} loading={loading} siteSettings={siteSettings} />
      <OrdersSettings errors={formErrors} loading={loading} siteSettings={siteSettings} />
      {hasPermission(PermissionEnum.ManageDeliveryTimeSlots) && <DeliveryTimeSlotsSettings />}
    </Grid>
  );
};
