import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { OptimaCliniciansAutocompleteQuery } from "./gql";

export type IOptimaCliniciansAutocomplete = ExtractNodeType<OptimaCliniciansAutocompleteQuery>;

export const optimaClinicianSchema = createZodAutocompleteFromType<IOptimaCliniciansAutocomplete>({
  id: z.string(),
  clinicianName: z.string().nullish(),
  branchLicense: z.string().nullish(),
  branchName: z.string().nullish(),
  clinicianId: z.string().nullish(),
  doctorId: z.string().nullish(),
});
