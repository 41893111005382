import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.HealthProgramNetworkProviderInput;
}>;


export type HealthProgramNetworkProviderUpdateMutation = { __typename?: 'Mutation', healthProgramNetworkProviderUpdate?: { __typename?: 'HealthProgramNetworkProviderCRUD', entity?: { __typename?: 'HealthProgramNetworkProvider', id: string } | null } | null };


export const HealthProgramNetworkProviderUpdateDocument = gql`
    mutation HealthProgramNetworkProviderUpdate($id: ID!, $input: HealthProgramNetworkProviderInput!) {
  healthProgramNetworkProviderUpdate(input: $input, id: $id) {
    entity {
      id
    }
  }
}
    `;
export type HealthProgramNetworkProviderUpdateMutationFn = Apollo.MutationFunction<HealthProgramNetworkProviderUpdateMutation, HealthProgramNetworkProviderUpdateMutationVariables>;

/**
 * __useHealthProgramNetworkProviderUpdateMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkProviderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkProviderUpdateMutation, { data, loading, error }] = useHealthProgramNetworkProviderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkProviderUpdateMutation, HealthProgramNetworkProviderUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkProviderUpdateMutation, HealthProgramNetworkProviderUpdateMutationVariables>(HealthProgramNetworkProviderUpdateDocument, options);
      }
export type HealthProgramNetworkProviderUpdateMutationHookResult = ReturnType<typeof useHealthProgramNetworkProviderUpdateMutation>;
export type HealthProgramNetworkProviderUpdateMutationResult = Apollo.MutationResult<HealthProgramNetworkProviderUpdateMutation>;
export type HealthProgramNetworkProviderUpdateMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkProviderUpdateMutation, HealthProgramNetworkProviderUpdateMutationVariables>;