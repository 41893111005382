import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { ListFieldsAutocompleteQuery } from "./gql";

export type IListFieldAutocomplete = Omit<NonNullable<NonNullable<ListFieldsAutocompleteQuery["getAllListFields"]>[number]>, "__typename">;

export const listFieldSchema = createZodAutocompleteFromType<IListFieldAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
});
