import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { procedureAgesPaths, procedureAgesRoute } from "./ProcedureAgePaths";
import { ProcedureAgeCreateContainer, ProcedureAgeListContainer, ProcedureAgeUpdateContainer } from "../containers";

export const procedureAgesRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-procedure-ages-routes",
    text: i18n.t("Procedure Age", { ns: "admin" }),
    route: procedureAgesRoute,
    subItems: [
      {
        id: "optima-procedure-age-list-route",
        route: procedureAgesPaths.list.route,
        fullPath: procedureAgesPaths.list.fullPath,
        element: <ProcedureAgeListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-procedure-age-create-route",
        route: procedureAgesPaths.create.route,
        fullPath: procedureAgesPaths.create.fullPath,
        hidden: true,
        element: <ProcedureAgeCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-procedure-age-update-route",
        route: procedureAgesPaths.update.route,
        fullPath: procedureAgesPaths.update.fullPath,
        hidden: true,
        element: <ProcedureAgeUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
