import { createPathDefinition } from "@toolkit/core";
import { optimaMedicalNecessityPath } from "../../constants/OptimaMedicalNecessityPaths";

export const procedureAgesRoute = optimaMedicalNecessityPath + "/procedure-age";

export const procedureAgesPaths = createPathDefinition(procedureAgesRoute, {
  list: "",
  create: "new",
  update: ":procedureAgeId",
});
