import { FC } from "react";
import { Container, CustomIcon, Grid, IconButton } from "@toolkit/ui";
import { useDecisionConditionRemoveStyle } from "./useDecisionConditionRemoveStyle";

type DecisionConditionRemoveProps = {
  onRemoveCondition: () => void;
};

export const DecisionConditionRemove: FC<DecisionConditionRemoveProps> = props => {
  const { onRemoveCondition } = props;

  const { classes, theme } = useDecisionConditionRemoveStyle();

  return (
    <Grid item lg={1} className={classes.root}>
      <Container className={classes.container}></Container>

      <IconButton onClick={onRemoveCondition}>
        <CustomIcon color={theme.palette.error.main} icon={"minus"} />
      </IconButton>
    </Grid>
  );
};
