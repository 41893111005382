import React, { FC } from "react";
import { Box, Button, Card, CircularProgress, Grid, Typography } from "@toolkit/ui";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { useCaseManagementPatientNoteListQuery } from "../../gql";
import { CaseManagementPatientNoteCreate } from "../CaseManagementPatientNoteCreate/CaseManagementPatientNoteCreate";
import { useCaseManagementPatientNotesStyle } from "./useCaseManagementPatientNotesStyle";

type CaseManagementPatientNotesProps = {
  patientId: string;
};

export const CaseManagementPatientNotes: FC<CaseManagementPatientNotesProps> = props => {
  const { patientId } = props;

  const { t } = useTranslation("admin");

  const { classes } = useCaseManagementPatientNotesStyle();

  const {
    data,
    loading,
    hasMore,
    refetch: handleRefetch,
    handleFetchMore,
  } = useRelayStyleConnection({
    useQuery: useCaseManagementPatientNoteListQuery,
    variables: { filter: { patientId } },
    skip: !patientId,
  });

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>{t("Patient Notes")}</Typography>
      </Grid>

      <Grid item xs={12}>
        <CaseManagementPatientNoteCreate patientId={patientId} onChange={handleRefetch} />
      </Grid>

      {loading && (
        <Grid item xs={12}>
          <Box className={classes.wrapper}>
            <CircularProgress size={40} />
          </Box>
        </Grid>
      )}

      {data?.map(item => (
        <Grid key={item?.id} item xs={12}>
          <Card className={classes.card}>
            <Typography className={classes.noteText}>{item?.note}</Typography>

            <Box>
              <Typography className={classes.noteInfo}>{item?.createdBy}</Typography>
              <Typography className={classes.noteInfo}>
                {item?.createdDate ? moment(item?.createdDate).format("DD MMMM YYYY HH:mm A") : ""}
              </Typography>
            </Box>
          </Card>
        </Grid>
      ))}

      {hasMore && (
        <Grid item xs={12}>
          <Box className={classes.wrapper}>
            <Button
              disabled={loading}
              endIcon={loading ? <CircularProgress color={"inherit"} size={20} /> : null}
              onClick={handleFetchMore}
            >
              {t("Load More Notes")}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
