import { AppointmentStatus } from "@/schema/types";

export const getAllowedAppointmentStatus = () => {
  const appointmentStatuses = (Object.values(AppointmentStatus) || []) as AppointmentStatus[];

  return appointmentStatuses?.filter(item => item !== AppointmentStatus.Request && item !== AppointmentStatus.Tentative);
};

export const getDisAllowedAppointmentStatus = (status: AppointmentStatus): boolean => {
  const disallowedStatuses = [AppointmentStatus.Cancelled, AppointmentStatus.CanceledByConsumer, AppointmentStatus.CanceledByDoctor];

  return disallowedStatuses.includes(status);
};
