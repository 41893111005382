import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientMessageSendMutationVariables = Types.Exact<{
  input: Types.SendBulkMessagesInput;
}>;


export type PatientMessageSendMutation = { __typename?: 'Mutation', sendBulkMessages?: { __typename?: 'SendBulkMessages', accountErrors: Array<{ __typename?: 'AccountError', field?: string | null, message?: string | null }> } | null };


export const PatientMessageSendDocument = gql`
    mutation PatientMessageSend($input: SendBulkMessagesInput!) {
  sendBulkMessages(input: $input) {
    accountErrors {
      field
      message
    }
  }
}
    `;
export type PatientMessageSendMutationFn = Apollo.MutationFunction<PatientMessageSendMutation, PatientMessageSendMutationVariables>;

/**
 * __usePatientMessageSendMutation__
 *
 * To run a mutation, you first call `usePatientMessageSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientMessageSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientMessageSendMutation, { data, loading, error }] = usePatientMessageSendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientMessageSendMutation(baseOptions?: Apollo.MutationHookOptions<PatientMessageSendMutation, PatientMessageSendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientMessageSendMutation, PatientMessageSendMutationVariables>(PatientMessageSendDocument, options);
      }
export type PatientMessageSendMutationHookResult = ReturnType<typeof usePatientMessageSendMutation>;
export type PatientMessageSendMutationResult = Apollo.MutationResult<PatientMessageSendMutation>;
export type PatientMessageSendMutationOptions = Apollo.BaseMutationOptions<PatientMessageSendMutation, PatientMessageSendMutationVariables>;