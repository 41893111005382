import { createAutocompleteOption } from "@toolkit/ui";
import { IPermissionAutocomplete } from "./schema";

export const createPermissionAutocompleteOption = (unit: IPermissionAutocomplete) => {
  return createAutocompleteOption(
    {
      id: unit?.id!,
      code: unit?.code!,
      name: unit?.name!,
    },
    "id",
    "name"
  );
};
