import { useParams } from "react-router-dom";
import { Branch } from "@/schema/types";
import { useSetProvidersBreadcrumbs } from "../../hooks";
import { IProviderBranchUpdateContainerParams } from "../../types";
import { pickLocalizedValue } from "@toolkit/i18n";
import { BranchUpdateContainer } from "@health/domains";
import { useProviderBranchBreadcrumbGetQuery } from "@/pages/Providers/gql";

export const ProviderBranchUpdateContainer = () => {
  const { providerId, branchId } = useParams<IProviderBranchUpdateContainerParams>();

  const { data } = useProviderBranchBreadcrumbGetQuery({
    variables: { id: branchId! },
    skip: !branchId,
    fetchPolicy: "no-cache",
  });

  const branch = data?.branch as Branch;

  useSetProvidersBreadcrumbs("BRANCH_UPDATE", { providerId, branchName: pickLocalizedValue(branch?.name, branch?.nameAr)! });

  return <BranchUpdateContainer branchId={branchId!} isAdmin />;
};
