import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTemplatesAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramTemplateFilterInput>;
}>;


export type GuidedCareTemplatesAutocompleteQuery = { __typename?: 'Query', healthProgramTemplates?: { __typename?: 'HealthProgramTemplateCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramTemplateCountableEdge', node: { __typename?: 'HealthProgramTemplate', id: string, code?: string | null, name?: string | null } }> } | null };


export const GuidedCareTemplatesAutocompleteDocument = gql`
    query GuidedCareTemplatesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramTemplateFilterInput) {
  healthProgramTemplates(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        code
        name
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useGuidedCareTemplatesAutocompleteQuery__
 *
 * To run a query within a React component, call `useGuidedCareTemplatesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTemplatesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTemplatesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGuidedCareTemplatesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>(GuidedCareTemplatesAutocompleteDocument, options);
      }
export function useGuidedCareTemplatesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>(GuidedCareTemplatesAutocompleteDocument, options);
        }
export function useGuidedCareTemplatesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>(GuidedCareTemplatesAutocompleteDocument, options);
        }
export type GuidedCareTemplatesAutocompleteQueryHookResult = ReturnType<typeof useGuidedCareTemplatesAutocompleteQuery>;
export type GuidedCareTemplatesAutocompleteLazyQueryHookResult = ReturnType<typeof useGuidedCareTemplatesAutocompleteLazyQuery>;
export type GuidedCareTemplatesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTemplatesAutocompleteSuspenseQuery>;
export type GuidedCareTemplatesAutocompleteQueryResult = Apollo.QueryResult<GuidedCareTemplatesAutocompleteQuery, GuidedCareTemplatesAutocompleteQueryVariables>;