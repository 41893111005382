import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";
import { useUserCreateMutation } from "../../gql";
import { convertUserProviderCreateFormValuesToBackEndValues } from "../../others";
import { UserProviderUpsertForm, IUserProviderUpsertFormEvent } from "../../forms/UserProviderUpsert/UserProviderUpsertForm";

type UserProviderCreateContainerProps = {
  isAdmin?: boolean;
  vendorId?: string;
  onChange: () => void;
};

export const UserProviderCreateContainer: FC<UserProviderCreateContainerProps> = props => {
  const { isAdmin, vendorId, onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [createUserProvider, { loading: isSubmitting }] = useUserCreateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.userCreate?.accountErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("User created successfully"));
        onChange();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onUserProviderUpsertFormChange = (event: IUserProviderUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertUserProviderCreateFormValuesToBackEndValues(event.payload.values, vendorId);

      createUserProvider({
        variables: {
          input: values,
        },
      });
    }
  };

  return (
    <UserProviderUpsertForm
      buttonLabel={t("Create")}
      vendorId={vendorId}
      isAdmin={isAdmin}
      isSubmitting={isSubmitting}
      onChange={onUserProviderUpsertFormChange}
    />
  );
};
