import { z } from "zod";

export const itemCodeMaximumQuantityAllowedUpsertFormSchema = z.object({
  codeSystem: z.string().min(1).max(255),
  itemCode: z.string().min(1).max(255),
  maximumAllowed: z.number().int(),
  description: z.string().min(3).optional(),
});
export type IItemCodeMaximumQuantityAllowedUpsertFormValues = z.infer<typeof itemCodeMaximumQuantityAllowedUpsertFormSchema>;

export const itemCodeMaximumQuantityAllowedUpsertFormDefaultValues: Partial<IItemCodeMaximumQuantityAllowedUpsertFormValues> = {
  codeSystem: "",
  itemCode: "",
  maximumAllowed: 0,
  description: "",
};
