import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceBenefitApprovalLimitDeleteMutationVariables = Types.Exact<{
  deleteInsuranceBenefitApprovalLimitId: Types.Scalars['ID']['input'];
}>;


export type InsuranceBenefitApprovalLimitDeleteMutation = { __typename?: 'Mutation', deleteInsuranceBenefitApprovalLimit?: { __typename?: 'InsuranceBenefitApprovalLimit', id: string } | null };


export const InsuranceBenefitApprovalLimitDeleteDocument = gql`
    mutation InsuranceBenefitApprovalLimitDelete($deleteInsuranceBenefitApprovalLimitId: ID!) {
  deleteInsuranceBenefitApprovalLimit(id: $deleteInsuranceBenefitApprovalLimitId) {
    id
  }
}
    `;
export type InsuranceBenefitApprovalLimitDeleteMutationFn = Apollo.MutationFunction<InsuranceBenefitApprovalLimitDeleteMutation, InsuranceBenefitApprovalLimitDeleteMutationVariables>;

/**
 * __useInsuranceBenefitApprovalLimitDeleteMutation__
 *
 * To run a mutation, you first call `useInsuranceBenefitApprovalLimitDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceBenefitApprovalLimitDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceBenefitApprovalLimitDeleteMutation, { data, loading, error }] = useInsuranceBenefitApprovalLimitDeleteMutation({
 *   variables: {
 *      deleteInsuranceBenefitApprovalLimitId: // value for 'deleteInsuranceBenefitApprovalLimitId'
 *   },
 * });
 */
export function useInsuranceBenefitApprovalLimitDeleteMutation(baseOptions?: Apollo.MutationHookOptions<InsuranceBenefitApprovalLimitDeleteMutation, InsuranceBenefitApprovalLimitDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsuranceBenefitApprovalLimitDeleteMutation, InsuranceBenefitApprovalLimitDeleteMutationVariables>(InsuranceBenefitApprovalLimitDeleteDocument, options);
      }
export type InsuranceBenefitApprovalLimitDeleteMutationHookResult = ReturnType<typeof useInsuranceBenefitApprovalLimitDeleteMutation>;
export type InsuranceBenefitApprovalLimitDeleteMutationResult = Apollo.MutationResult<InsuranceBenefitApprovalLimitDeleteMutation>;
export type InsuranceBenefitApprovalLimitDeleteMutationOptions = Apollo.BaseMutationOptions<InsuranceBenefitApprovalLimitDeleteMutation, InsuranceBenefitApprovalLimitDeleteMutationVariables>;