/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, Dialog, DialogActions, Grid, LinearProgress, Typography } from "@toolkit/ui";
import { FC } from "react";
import { UploadToCloudIcon, XlsIcon } from "./icons";
import { useUploadDialog } from "./useUploadDialog";
import { useUploadDialogStyle } from "./useUploadDialogStyle";

type UploadDialogProps = {
  title: string;
  isOpen: boolean;
  subTitle: string;
  hasErrors?: any;
  errorMessage?: string;
  isDialogLoading: boolean;
  handleClose: () => void;
  handleResetError?: () => void;
  onFileUploadedSuccessfully: (fileName: string) => void;
};

export const UploadDialog: FC<UploadDialogProps> = props => {
  const { title, isOpen, subTitle, hasErrors, errorMessage, isDialogLoading, handleClose, handleResetError, onFileUploadedSuccessfully } =
    props;

  const { t } = useTranslation("domains");
  const { classes, theme } = useUploadDialogStyle();

  const {
    file,
    dragActive,
    customError,
    handleDrop,
    handleDrag,
    handleRemoveFileClick,
    handleUploadClick,
    handleUploadFile,
    handleCloseClick,
  } = useUploadDialog({ handleClose, handleResetError, onFileUploadedSuccessfully });

  return (
    <Dialog
      BackdropProps={{
        className: classes.backDropStyle,
      }}
      classes={{
        container: classes.container,
      }}
      PaperProps={{
        className: classes.paperStyle,
        sx: {
          width: "40%",
        },
      }}
      open={isOpen}
      onClose={handleCloseClick}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Box flex={1} padding={1} paddingLeft={3} paddingRight={3}>
        <Typography fontSize={theme.mixins.fonts.fontSize.xxl} textAlign='center'>
          {title ? title : "No title"}
        </Typography>

        <Typography fontSize={theme.mixins.fonts.fontSize.md} textAlign='center' color={theme.palette.stale.main}>
          {subTitle ? subTitle : "No subtitle"}
        </Typography>

        <Box
          onDragEnter={handleDrag}
          sx={{ backgroundColor: dragActive ? theme.palette.background.default : theme.palette.common.white }}
          className={classes.box}
        >
          <UploadToCloudIcon width={120} height={90} />

          <Typography
            fontSize={theme.mixins.fonts.fontSize.md}
            textAlign='center'
            color={theme.palette.stale.main}
            marginTop={1}
            marginBottom={1}
          >
            {t("Drag & Drop Your Files Here")}
          </Typography>

          <Typography fontSize={theme.mixins.fonts.fontSize.md} textAlign='center' marginBottom={1}>
            {t("OR")}
          </Typography>

          <Button variant='contained' component='label'>
            {t("Browse File")}
            <input type='file' accept='.xlsx' hidden onChange={handleUploadFile} />
          </Button>

          {dragActive && (
            <div
              className={classes.dragArea}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </Box>

        {!!file && (
          <Grid container spacing={1} marginTop={2}>
            <Grid item>
              <XlsIcon width={23} height={29} />
            </Grid>

            <Grid item flex={1}>
              <Typography flex={1} fontSize={theme.mixins.fonts.fontSize.sm} paddingBottom={0.5}>
                {t(file.name)}
              </Typography>

              {isDialogLoading && (
                <LinearProgress
                  color='success'
                  classes={{
                    root: classes.progressRoot,
                  }}
                  value={0}
                />
              )}
            </Grid>

            {!isDialogLoading && (
              <Grid item>
                <Grid onClick={() => handleRemoveFileClick(file)}>
                  <CustomIcon icon='icons8_cancel' color={theme.palette.gray.light} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>

      {customError && (
        <Box marginBottom='20px'>
          <Typography
            sx={{
              fontSize: theme.mixins.fonts.fontSize.md,
              textAlign: "center",
              color: theme.palette.secondary.main,
            }}
          >
            {t("Error")}: {customError}
          </Typography>
        </Box>
      )}

      {hasErrors && (
        <Box marginBottom='20px'>
          <Typography
            sx={{
              fontSize: theme.mixins.fonts.fontSize.md,
              textAlign: "center",
              color: theme.palette.secondary.main,
            }}
          >
            {t("Error")}: {t(errorMessage || "")}
          </Typography>
        </Box>
      )}

      <DialogActions className={classes.dialogContainerStyle}>
        <Button disabled={!file || isDialogLoading} onClick={handleUploadClick}>
          {t("Submit file")}
        </Button>
        <Button variant='outlined' onClick={handleCloseClick}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
