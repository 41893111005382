import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CptHcpcPrimaryProcedureListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.CptHcpcPrimaryProcedureFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.CptHcpcPrimaryProcedureSorting>;
}>;


export type CptHcpcPrimaryProcedureListQuery = { __typename?: 'Query', getAllCptHcpcPrimaryProcedure?: { __typename?: 'CptHcpcPrimaryProcedureConnection', edges: Array<{ __typename?: 'CptHcpcPrimaryProcedureEdge', node: { __typename?: 'CptHcpcPrimaryProcedure', id: string, itemCode: string, primaryProcedure: string, description?: string | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const CptHcpcPrimaryProcedureListDocument = gql`
    query CptHcpcPrimaryProcedureList($after: String, $before: String, $filter: CptHcpcPrimaryProcedureFilter, $first: Int, $last: Int, $sortBy: CptHcpcPrimaryProcedureSorting) {
  getAllCptHcpcPrimaryProcedure(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        itemCode
        primaryProcedure
        description
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useCptHcpcPrimaryProcedureListQuery__
 *
 * To run a query within a React component, call `useCptHcpcPrimaryProcedureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCptHcpcPrimaryProcedureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCptHcpcPrimaryProcedureListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCptHcpcPrimaryProcedureListQuery(baseOptions?: Apollo.QueryHookOptions<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>(CptHcpcPrimaryProcedureListDocument, options);
      }
export function useCptHcpcPrimaryProcedureListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>(CptHcpcPrimaryProcedureListDocument, options);
        }
export function useCptHcpcPrimaryProcedureListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>(CptHcpcPrimaryProcedureListDocument, options);
        }
export type CptHcpcPrimaryProcedureListQueryHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureListQuery>;
export type CptHcpcPrimaryProcedureListLazyQueryHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureListLazyQuery>;
export type CptHcpcPrimaryProcedureListSuspenseQueryHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureListSuspenseQuery>;
export type CptHcpcPrimaryProcedureListQueryResult = Apollo.QueryResult<CptHcpcPrimaryProcedureListQuery, CptHcpcPrimaryProcedureListQueryVariables>;