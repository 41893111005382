import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.DoctorFilterInput>;
}>;


export type DoctorsAutocompleteQuery = { __typename?: 'Query', doctors?: { __typename?: 'DoctorCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } }> } | null };


export const DoctorsAutocompleteDocument = gql`
    query DoctorsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: DoctorFilterInput) {
  doctors(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        user {
          id
          fullName
        }
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useDoctorsAutocompleteQuery__
 *
 * To run a query within a React component, call `useDoctorsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDoctorsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>(DoctorsAutocompleteDocument, options);
      }
export function useDoctorsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>(DoctorsAutocompleteDocument, options);
        }
export function useDoctorsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>(DoctorsAutocompleteDocument, options);
        }
export type DoctorsAutocompleteQueryHookResult = ReturnType<typeof useDoctorsAutocompleteQuery>;
export type DoctorsAutocompleteLazyQueryHookResult = ReturnType<typeof useDoctorsAutocompleteLazyQuery>;
export type DoctorsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDoctorsAutocompleteSuspenseQuery>;
export type DoctorsAutocompleteQueryResult = Apollo.QueryResult<DoctorsAutocompleteQuery, DoctorsAutocompleteQueryVariables>;