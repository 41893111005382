import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceNetworkUpdateMutationVariables = Types.Exact<{
  input: Types.InsuranceNetworkInput;
  id: Types.Scalars['ID']['input'];
}>;


export type InsuranceNetworkUpdateMutation = { __typename?: 'Mutation', insuranceNetworkUpdate?: { __typename?: 'InsuranceNetworkCRUD', insuranceNetworkErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, message?: string | null, code: Types.H_EntityErrorCode }> | null, insuranceNetwork?: { __typename?: 'InsuranceNetwork', id: string, name?: string | null, isActive?: boolean | null } | null } | null };


export const InsuranceNetworkUpdateDocument = gql`
    mutation insuranceNetworkUpdate($input: InsuranceNetworkInput!, $id: ID!) {
  insuranceNetworkUpdate(input: $input, id: $id) {
    insuranceNetworkErrors {
      field
      message
      code
    }
    insuranceNetwork {
      id
      name
      isActive
    }
  }
}
    `;
export type InsuranceNetworkUpdateMutationFn = Apollo.MutationFunction<InsuranceNetworkUpdateMutation, InsuranceNetworkUpdateMutationVariables>;

/**
 * __useInsuranceNetworkUpdateMutation__
 *
 * To run a mutation, you first call `useInsuranceNetworkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceNetworkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceNetworkUpdateMutation, { data, loading, error }] = useInsuranceNetworkUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInsuranceNetworkUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InsuranceNetworkUpdateMutation, InsuranceNetworkUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsuranceNetworkUpdateMutation, InsuranceNetworkUpdateMutationVariables>(InsuranceNetworkUpdateDocument, options);
      }
export type InsuranceNetworkUpdateMutationHookResult = ReturnType<typeof useInsuranceNetworkUpdateMutation>;
export type InsuranceNetworkUpdateMutationResult = Apollo.MutationResult<InsuranceNetworkUpdateMutation>;
export type InsuranceNetworkUpdateMutationOptions = Apollo.BaseMutationOptions<InsuranceNetworkUpdateMutation, InsuranceNetworkUpdateMutationVariables>;