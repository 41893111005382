import { PermissionEnum, ProcessingStatus } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useGridContext } from "@toolkit/ui";
import { useState } from "react";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const useActionsMenuHooks = () => {
  const { t } = useTranslation("domains");
  const [menuActionsAnchorEl, setMenuActionsAnchorEl] = useState<null | HTMLElement>(null);
  const { isNew, isEditable, processingStatus } = useAdminManagedListDetailsPageContext();
  const { customTableProps } = useGridContext();
  const isMenuOpened = Boolean(menuActionsAnchorEl);
  const hasData = customTableProps?.data?.length! > 0;
  const hasClone = isNew || !!isEditable;
  const hasImport = isNew || !!isEditable;
  const hasDownload = true;
  const hasApproveReject = processingStatus === ProcessingStatus.UnderReview;
  const hasPublish = processingStatus === ProcessingStatus.Approved;
  const isDraft = processingStatus === ProcessingStatus.Draft;
  const hasReassign = hasPermission(PermissionEnum.ManageCodeSystemEditorReviewers);
  const handleOpenMenuToAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuActionsAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuActionsAnchorEl(null);
  };

  return {
    isMenuOpened,
    menuActionsAnchorEl,
    hasClone,
    hasImport,
    hasDownload,
    hasApproveReject,
    hasPublish,
    isDraft,
    hasData,
    hasReassign,
    t,
    handleOpenMenuToAnchor,
    handleCloseMenu,
  };
};
