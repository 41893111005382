import { QuestionType } from "@/schema/types";
import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";
import { MedicalFormQuestionOptionUpsertFormSchema } from "../MedicalFormQuestionOptionUpsert/MedicalFormQuestionOptionUpsertFormSchema";

export const medicalFormQuestionUpsertFormSchema = z
  .object({
    question: z.string().min(1).max(255),
    questionType: zodEnumSchema.questionType,
    required: z.boolean(),
    image: z.string().nullish(),
    options: z.array(MedicalFormQuestionOptionUpsertFormSchema),
  })
  .superRefine((data, ctx) => {
    if (data.questionType?.value !== QuestionType.String && data.options.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Options are required for non-string question types",
        path: ["options"],
      });
    }
  });

export type IMedicalFormQuestionUpsertFormValues = z.infer<typeof medicalFormQuestionUpsertFormSchema>;

export const medicalFormQuestionUpsertFormDefaultValues: Partial<IMedicalFormQuestionUpsertFormValues> = {
  question: undefined,
  questionType: undefined,
  required: true,
  image: undefined,
  options: [],
};
