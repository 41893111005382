import { RedirectCellOptionsProps } from "./types";
import { IconButton, Tooltip, Box } from "../../../../base/mui";
import { CustomIcon } from "../../../CustomIcon";
import { useNavigate } from "react-router-dom";

export const RedirectCell = <RowType extends object>(props: RedirectCellOptionsProps<RowType>) => {
  const navigate = useNavigate();

  const { tooltipMessage, row, link, onClick, customIcon, isDisabled } = props;

  const resolvedTooltipMessage = typeof tooltipMessage === "function" ? tooltipMessage(row) : tooltipMessage || "";

  const resolveLinkUrl = (row: RowType): string | undefined => {
    if (link) {
      return typeof link === "function" ? link(row) : link;
    }
    return undefined;
  };

  const handleRedirect = (row: RowType) => {
    const url = resolveLinkUrl(row);
    if (url && typeof url === "string") {
      navigate(url);
      return;
    }
    onClick?.(row);
  };

  return (
    <Box display='flex' width='fit-contnet' justifyContent='center' maxWidth='60px' alignContent='center'>
      <Tooltip title={!isDisabled ? resolvedTooltipMessage : ""} disableInteractive leaveDelay={0}>
        <IconButton disabled={!!isDisabled} onClick={() => handleRedirect(row)} aria-label={resolvedTooltipMessage}>
          {customIcon || <CustomIcon viewBox='0 0 18 18' icon='externalLink' />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
