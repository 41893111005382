import React, { FC, memo } from "react";
import { useSadaContext } from "@sada/react";
import SadaError from "./SadaError";

const SadaLogin: FC = () => {
  const { oAuthBySehacity: handleRetry } = useSadaContext();

  return <SadaError title='Authentication Failed' subtitle='Your session is invalid or expired.' onClick={handleRetry} />;
};

export default memo(SadaLogin);
