import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemVersionsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  code?: Types.InputMaybe<Types.CodeSystemCode>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetCodeSystemVersionsQuery = { __typename?: 'Query', getCodeSystems?: { __typename?: 'CodeSystemConnection', edges?: Array<{ __typename?: 'CodeSystemEdge', node?: { __typename?: 'CodeSystem', id?: string | null, display?: string | null, isActive?: boolean | null, version?: number | null, processingStatus?: Types.ProcessingStatus | null, codeSystemHistory?: Array<{ __typename?: 'CodeSystemHistory', id?: string | null, processingStatus?: Types.ProcessingStatus | null, updateDate?: string | null } | null> | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const GetCodeSystemVersionsDocument = gql`
    query getCodeSystemVersions($after: String, $code: CodeSystemCode, $first: Int, $isActive: Boolean) {
  getCodeSystems(first: $first, after: $after, code: $code, isActive: $isActive) {
    edges {
      node {
        id
        display
        isActive
        version
        processingStatus
        codeSystemHistory {
          id
          processingStatus
          updateDate
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useGetCodeSystemVersionsQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemVersionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      code: // value for 'code'
 *      first: // value for 'first'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetCodeSystemVersionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>(GetCodeSystemVersionsDocument, options);
      }
export function useGetCodeSystemVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>(GetCodeSystemVersionsDocument, options);
        }
export function useGetCodeSystemVersionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>(GetCodeSystemVersionsDocument, options);
        }
export type GetCodeSystemVersionsQueryHookResult = ReturnType<typeof useGetCodeSystemVersionsQuery>;
export type GetCodeSystemVersionsLazyQueryHookResult = ReturnType<typeof useGetCodeSystemVersionsLazyQuery>;
export type GetCodeSystemVersionsSuspenseQueryHookResult = ReturnType<typeof useGetCodeSystemVersionsSuspenseQuery>;
export type GetCodeSystemVersionsQueryResult = Apollo.QueryResult<GetCodeSystemVersionsQuery, GetCodeSystemVersionsQueryVariables>;