import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useUsersAutocompleteQuery } from "./gql";
import { IUserAutocomplete } from "./schema";

export const createUserAutocompleteOption = (user: IUserAutocomplete) => {
  return createAutocompleteOption({ id: user?.id!, fullName: user?.fullName! }, "id", "fullName");
};

export const getUsersAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "users",
    query: useUsersAutocompleteQuery,
    labelBy: "fullName",
    backendAccessor: "id",
  });
};
