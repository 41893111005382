import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../Addresses/gql/fragments/__generated__/Address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.BranchOrder>;
  filter?: Types.InputMaybe<Types.BranchFilterInput>;
}>;


export type BranchListQuery = { __typename?: 'Query', branches?: { __typename?: 'BranchCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, contactEmail?: string | null, contactNumber?: string | null, type?: Types.BranchType | null, created: any, isActive: boolean, address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, area: string, areaAr: string, postalCode: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } }> } | null };


export const BranchListDocument = gql`
    query BranchList($first: Int, $last: Int, $after: String, $before: String, $sortBy: BranchOrder, $filter: BranchFilterInput) {
  branches(
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        nameAr
        contactEmail
        contactNumber
        type
        created
        isActive
        address {
          ...AddressFragment
        }
        workingHours {
          day
          openTimeRanges {
            openTime
            closeTime
          }
        }
      }
    }
  }
}
    ${AddressFragmentFragmentDoc}`;

/**
 * __useBranchListQuery__
 *
 * To run a query within a React component, call `useBranchListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBranchListQuery(baseOptions?: Apollo.QueryHookOptions<BranchListQuery, BranchListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchListQuery, BranchListQueryVariables>(BranchListDocument, options);
      }
export function useBranchListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchListQuery, BranchListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchListQuery, BranchListQueryVariables>(BranchListDocument, options);
        }
export function useBranchListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchListQuery, BranchListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchListQuery, BranchListQueryVariables>(BranchListDocument, options);
        }
export type BranchListQueryHookResult = ReturnType<typeof useBranchListQuery>;
export type BranchListLazyQueryHookResult = ReturnType<typeof useBranchListLazyQuery>;
export type BranchListSuspenseQueryHookResult = ReturnType<typeof useBranchListSuspenseQuery>;
export type BranchListQueryResult = Apollo.QueryResult<BranchListQuery, BranchListQueryVariables>;