import { i18n } from "@toolkit/i18n";
import { CareType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const careTypeOptionsMap: IEnumToOptionsMap<CareType> = {
  [CareType.ErxCoverage]: {
    key: CareType.ErxCoverage,
    get label() {
      return i18n.t("Erx Coverage", { ns: "domains" });
    },
    value: CareType.ErxCoverage,
  },
  [CareType.ImmediateCare]: {
    key: CareType.ImmediateCare,
    get label() {
      return i18n.t("Immediate Care", { ns: "domains" });
    },
    value: CareType.ImmediateCare,
  },
  [CareType.Proactive]: {
    key: CareType.Proactive,
    get label() {
      return i18n.t("Proactive", { ns: "domains" });
    },
    value: CareType.Proactive,
  },
  [CareType.Reactive]: {
    key: CareType.Reactive,
    get label() {
      return i18n.t("Reactive", { ns: "domains" });
    },
    value: CareType.Reactive,
  },
};

export const careTypeOptions = Object.values(careTypeOptionsMap);
