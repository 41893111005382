import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Control, Controller, ControllerProps, useFormContext } from "react-hook-form";
import {
  DateAdapter,
  DatePicker,
  InputAdornment,
  LocalizationProvider,
  TextField,
  useTheme,
  type DatePickerProps,
  type TextFieldProps,
} from "../../base/mui";
import { MuiCalendarTodayIcon } from "../../base/mui-icons";

export type CustomDatePickerProps = TextFieldProps & {
  label: string;
  control: Control<any>;
  name: string;
  placeholder: string;
  format?: string;
  helperText?: string;
  error?: string | boolean;
  controllerProps?: Omit<ControllerProps, "render" | "name">;
  datePickerProps?: Omit<DatePickerProps, "onChange" | "value" | "renderInput">;
  // eslint-disable-next-line react/boolean-prop-naming
  shouldHaveDefaultValidation?: boolean;
};

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
  format,
  label,
  control,
  name,
  placeholder,
  helperText,
  error,
  controllerProps,
  shouldHaveDefaultValidation = true,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [inputError, setInputError] = useState("");

  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const theme = useTheme();

  const isError = !!inputError || error;

  const handleTogglePicker = () => {
    setOpen(!open);
  };

  const handleOpenPicker = () => {
    setOpen(true);
  };

  const handleClosePicker = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.defaultValue && setValue(name, props.defaultValue);
  }, [props.defaultValue]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Controller
        name={name}
        control={controllerProps?.control || control}
        defaultValue={controllerProps?.defaultValue || props.defaultValue}
        rules={controllerProps?.rules}
        render={({ field: { onChange: handleOnChange, value } }) => {
          const handleSelectedDate = (date: unknown, keyboardInputValue: unknown) => {
            handleOnChange(moment(date as string)?.isValid() ? moment(date as string).format(format) : null);
            if (shouldHaveDefaultValidation && keyboardInputValue && !moment(keyboardInputValue, "DD/MM/yyyy").isValid()) {
              setInputError(t("Invalid date"));
            } else {
              setInputError("");
            }
          };
          return (
            <DatePicker
              open={open}
              onOpen={handleOpenPicker}
              onClose={handleClosePicker}
              label={label}
              value={value ? moment(value, format) : null}
              onChange={handleSelectedDate}
              allowSameDateSelection
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <MuiCalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
              renderInput={params => (
                <TextField
                  fullWidth
                  label={label}
                  error={error || isError}
                  helperText={inputError || helperText}
                  placeholder={placeholder}
                  required={props.required}
                  {...params}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: isError ? theme.palette.error.main : "primary",
                    },
                  }}
                  onClick={handleTogglePicker}
                  color={isError ? "error" : "primary"}
                />
              )}
              {...props.datePickerProps}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

CustomDatePicker.defaultProps = {
  format: "yyyy-MM-DD",
  helperText: undefined,
  error: undefined,
  datePickerProps: {},
  controllerProps: {},
};
