import { Vendor, VendorOrderField } from "@/schema/types";
import { getAutocompleteEnumFilter, vendorTypeOptionsMap } from "@health/enum-options";
import { AddressInformationModal, BranchWorkingHoursModal, UserListModal } from "@health/domains";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { ActivationSwitch, CustomTableColumnProps, GridImageModal, TruncateTypography } from "@toolkit/ui";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { providersPaths } from "../../constants";
import { useProviderActivationMutation, useProviderOutpatientJourneyActivationMutation } from "../../gql";
import { ProviderSubscriptionTermination } from "../../components/ProviderSubscriptionTermination/ProviderSubscriptionTermination";

export const useProviderListContainerColumns = (): CustomTableColumnProps<Vendor>[] => {
  const { t } = useTranslation("admin");

  const navigate = useNavigate();

  return useMemo(
    () => [
      {
        key: "logo",
        header: t("Logo "),
        accessor: ({ logo }) => <GridImageModal image={logo!} title={t("Logo")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => <TruncateTypography text={pickLocalizedValue(name, nameAr!)} />,
        sort: {
          columnEnum: VendorOrderField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: ({ nationalId }) => <TruncateTypography text={nationalId || "-"} />,
        filter: {
          type: "string",
          name: "nationalId_Icontains",
        },
      },
      {
        key: "owner",
        header: t("Owner"),
        accessor: ({ ownerName }) => <TruncateTypography text={ownerName || "-"} />,
        filter: {
          type: "string",
          name: "ownerName_Icontains",
        },
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => vendorTypeOptionsMap[type!]?.label,
        filter: getAutocompleteEnumFilter("VendorType", "type"),
      },
      {
        key: "address",
        header: t("Address"),
        accessor: ({ address }) => <AddressInformationModal address={address} />,
      },
      {
        key: "workingHours",
        header: t("Working Hours"),
        accessor: ({ branches }) => <BranchWorkingHoursModal workingHours={branches?.edges?.[0]?.node?.workingHours} />,
      },
      {
        key: "users",
        header: t("Users"),
        accessor: ({ id }) => <UserListModal filter={{ vendorId: id }} />,
      },
      {
        key: "branches",
        header: t("Branches"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => navigate(providersPaths.branchList.fullPathWithParams({ providerId: id })),
        },
      },
      {
        key: "created",
        header: t("Created"),
        accessor: "created",
        type: "datetime",
        sort: {
          columnEnum: VendorOrderField.Created,
        },
      },
      {
        key: "terminateSubscription",
        header: t("Terminate Subscription"),
        accessor: ({ id, approved, isActive }) => approved && <ProviderSubscriptionTermination id={id} isActive={isActive} />,
        settings: {
          hideFromPreferencesAndTable: true,
          disableToggleVisibility: false,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <ActivationSwitch id={id} isActive={isActive} mutation={useProviderActivationMutation} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "outpatientJourney",
        header: t("Out Patient Journey"),
        accessor: ({ id, supportOutpatientJourney }) => (
          <ActivationSwitch id={id} isActive={supportOutpatientJourney} mutation={useProviderOutpatientJourneyActivationMutation} />
        ),
      },
    ],
    [t]
  );
};
