import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { blocksPaths } from "../constants/BlocksPaths";

export const useSetBlocksBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Blocks Management");

  const blockListBreadcrumb = useMemo(() => {
    return {
      name: t("Block"),
      route: blocksPaths.list.fullPath,
    };
  }, [t]);

  const setBlockListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setBlockCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [blockListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, blockListBreadcrumb, t]);

  const setBlockUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [blockListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, blockListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setBlockListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setBlockCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setBlockUpdateContainerBreadcrumb();
    }
  }, [containerType, setBlockListContainerBreadcrumb, setBlockCreateContainerBreadcrumb, setBlockUpdateContainerBreadcrumb]);
};
