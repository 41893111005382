import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceBenefitApprovalLimitCreateMutationVariables = Types.Exact<{
  input: Types.InsuranceBenefitApprovalLimitCrudInput;
}>;


export type InsuranceBenefitApprovalLimitCreateMutation = { __typename?: 'Mutation', createInsuranceBenefitApprovalLimit?: { __typename?: 'InsuranceBenefitApprovalLimit', id: string, encounterType: string, insuranceLicense: string, insurancePolicy: string, limit: number, source: string } | null };


export const InsuranceBenefitApprovalLimitCreateDocument = gql`
    mutation InsuranceBenefitApprovalLimitCreate($input: InsuranceBenefitApprovalLimitCRUDInput!) {
  createInsuranceBenefitApprovalLimit(input: $input) {
    id
    encounterType
    insuranceLicense
    insurancePolicy
    limit
    source
  }
}
    `;
export type InsuranceBenefitApprovalLimitCreateMutationFn = Apollo.MutationFunction<InsuranceBenefitApprovalLimitCreateMutation, InsuranceBenefitApprovalLimitCreateMutationVariables>;

/**
 * __useInsuranceBenefitApprovalLimitCreateMutation__
 *
 * To run a mutation, you first call `useInsuranceBenefitApprovalLimitCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceBenefitApprovalLimitCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceBenefitApprovalLimitCreateMutation, { data, loading, error }] = useInsuranceBenefitApprovalLimitCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsuranceBenefitApprovalLimitCreateMutation(baseOptions?: Apollo.MutationHookOptions<InsuranceBenefitApprovalLimitCreateMutation, InsuranceBenefitApprovalLimitCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsuranceBenefitApprovalLimitCreateMutation, InsuranceBenefitApprovalLimitCreateMutationVariables>(InsuranceBenefitApprovalLimitCreateDocument, options);
      }
export type InsuranceBenefitApprovalLimitCreateMutationHookResult = ReturnType<typeof useInsuranceBenefitApprovalLimitCreateMutation>;
export type InsuranceBenefitApprovalLimitCreateMutationResult = Apollo.MutationResult<InsuranceBenefitApprovalLimitCreateMutation>;
export type InsuranceBenefitApprovalLimitCreateMutationOptions = Apollo.BaseMutationOptions<InsuranceBenefitApprovalLimitCreateMutation, InsuranceBenefitApprovalLimitCreateMutationVariables>;