import { IAutocompleteOption } from "@toolkit/ui";
import { ActionDefinitionField, Field } from "@/schema/types";
import { DecisionHealthParameterRiskParameterGetQuery } from "../gql";

export enum DecisionActionFieldListCode {
  RiskFactorTemplate = "riskTemplateCodes",
  ChronicTemplate = "chronicTemplateCodes",
  TaskDefinition = "taskDefinitionCode",
  Recommendation = "recommendationCode",
  OptimaEdit = "editCode",
  RiskParameter = "riskParameterCode",
}

export enum DecisionConditionFieldStringCode {
  Gender = "gender",
  GuidedCareTemplate = "guidedCareTemplateCode",
}

export enum DecisionConditionFieldListCode {
  City = "city",
  Division = "division",
}

export enum DecisionConditionFieldCategoryCode {
  GuidedCare = "GUIDED_CARE",
  HealthParameter = "HEALTH_PARAMETER",
  IOAgent = "AGENT",
}

export enum DecisionPlanCode {
  HealthParameterReference = "HEALTH_PARAMETER_REFERENCE",
  RiskParameter = "RISK_PARAMETER",
  PersonalizedGuidelines = "PERSONALIZED_GUIDELINES",
}

export type IDecisionUpdateContainerParams = {
  id: string;
};

export type IDecisionConditionGroupPath = `conditionsGroups.${number}`;
export type IDecisionConditionGroupConditionsPath = `conditionsGroups.${number}.conditions`;
export type IDecisionConditionGroupConditionPath = `${IDecisionConditionGroupConditionsPath}.${number}`;

export type IDecisionPlanActionFieldsKeyedBy = {
  [key: string]: ActionDefinitionField;
};

export type IDecisionPlanFieldsKeyedBy = {
  [key: string]: Field;
};

export type IDecisionActionFieldsValues = {
  valueBoolean: boolean | undefined;
  valueNumber: number | undefined;
  valueString: string | IAutocompleteOption<string> | IAutocompleteOption<{ id: string; name: string }> | undefined;
  valueList: IAutocompleteOption<{ id: string; name: string }> | undefined;
  valueDate: string | undefined;
};

export type IDecisionActionFieldsExtraValue = {
  id: string;
  name: string;
  nameAr?: string | null;
};

export type IDecisionConditionValues = {
  valueNumber: number | undefined;
  valueString: string | IAutocompleteOption<string> | undefined;
  valueList: IAutocompleteOption<{ id: string; display: string }> | undefined;
  valueID: IAutocompleteOption<{ id: string; name: string }> | undefined;
  valueDate: string | undefined;
};

export type IDecisionConditionExtraValue = {
  id: string;
  name?: string;
  nameAr?: string;
  display?: string;
};

export type IDecisionActionFieldBackEndValues = {
  valueBoolean?: boolean;
  valueNumber?: number;
  valueString?: string;
  valueList?: [string];
};

export type IDecisionConditionBackEndValues = {
  valueBoolean?: boolean;
  valueNumber?: number;
  valueString?: string;
  valueList?: string;
  valueDate?: string;
  valueID?: string;
  extra?: string;
};

export type IDecisionHealthParameterRiskParameter = NonNullable<
  NonNullable<NonNullable<NonNullable<DecisionHealthParameterRiskParameterGetQuery["getHealthParameters"]>["edges"]>[0]>["node"]
>["riskParameter"];
