import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkMessagesSendMutationVariables = Types.Exact<{
  input: Types.SendBulkMessagesInput;
}>;


export type BulkMessagesSendMutation = { __typename?: 'Mutation', sendBulkMessages?: { __typename?: 'SendBulkMessages', success?: boolean | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null, field?: string | null, code: Types.AccountErrorCode }> } | null };


export const BulkMessagesSendDocument = gql`
    mutation BulkMessagesSend($input: SendBulkMessagesInput!) {
  sendBulkMessages(input: $input) {
    success
    accountErrors {
      message
      field
      code
    }
  }
}
    `;
export type BulkMessagesSendMutationFn = Apollo.MutationFunction<BulkMessagesSendMutation, BulkMessagesSendMutationVariables>;

/**
 * __useBulkMessagesSendMutation__
 *
 * To run a mutation, you first call `useBulkMessagesSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkMessagesSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkMessagesSendMutation, { data, loading, error }] = useBulkMessagesSendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkMessagesSendMutation(baseOptions?: Apollo.MutationHookOptions<BulkMessagesSendMutation, BulkMessagesSendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkMessagesSendMutation, BulkMessagesSendMutationVariables>(BulkMessagesSendDocument, options);
      }
export type BulkMessagesSendMutationHookResult = ReturnType<typeof useBulkMessagesSendMutation>;
export type BulkMessagesSendMutationResult = Apollo.MutationResult<BulkMessagesSendMutation>;
export type BulkMessagesSendMutationOptions = Apollo.BaseMutationOptions<BulkMessagesSendMutation, BulkMessagesSendMutationVariables>;