import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowDeleteMutationVariables = Types.Exact<{
  chatFlowDeleteId: Types.Scalars['ID']['input'];
}>;


export type ChatFlowDeleteMutation = { __typename?: 'Mutation', chatFlowDelete?: { __typename?: 'ChatFlow', documents?: Array<{ __typename?: 'ChatFlowDocument', id: string }> | null } | null };


export const ChatFlowDeleteDocument = gql`
    mutation ChatFlowDelete($chatFlowDeleteId: ID!) {
  chatFlowDelete(id: $chatFlowDeleteId) {
    documents {
      id
    }
  }
}
    `;
export type ChatFlowDeleteMutationFn = Apollo.MutationFunction<ChatFlowDeleteMutation, ChatFlowDeleteMutationVariables>;

/**
 * __useChatFlowDeleteMutation__
 *
 * To run a mutation, you first call `useChatFlowDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowDeleteMutation, { data, loading, error }] = useChatFlowDeleteMutation({
 *   variables: {
 *      chatFlowDeleteId: // value for 'chatFlowDeleteId'
 *   },
 * });
 */
export function useChatFlowDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ChatFlowDeleteMutation, ChatFlowDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatFlowDeleteMutation, ChatFlowDeleteMutationVariables>(ChatFlowDeleteDocument, options);
      }
export type ChatFlowDeleteMutationHookResult = ReturnType<typeof useChatFlowDeleteMutation>;
export type ChatFlowDeleteMutationResult = Apollo.MutationResult<ChatFlowDeleteMutation>;
export type ChatFlowDeleteMutationOptions = Apollo.BaseMutationOptions<ChatFlowDeleteMutation, ChatFlowDeleteMutationVariables>;