import { optimaActionsRoutes } from "@/pages/OptimaActions/constants";
import { optimaEditsRoutes } from "@/pages/OptimaEdits/constants";
import { optimaMedicalNecessityRoutes } from "@/pages/OptimaMedicalNecessity/constants";
import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasAnyPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { ListViewIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";

export const optimaRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-routes",
    text: t("Optima", { ns: "admin" }),
    icon: <ListViewIcon />,
    hidden: !hasAnyPermission([PermissionEnum.ManageEditsAndActions, PermissionEnum.ManageMedicalNecessity]),
    isProhibited: !hasAnyPermission([PermissionEnum.ManageEditsAndActions, PermissionEnum.ManageMedicalNecessity]),
    subItems: [optimaActionsRoutes({ navigate, t }), optimaEditsRoutes({ navigate, t }), optimaMedicalNecessityRoutes({ navigate, t })],
  };
};
