/* eslint-disable react/require-default-props */
import { ProcessingStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { getCodeSystemProcessingStatusColor } from "../../shared/utils";
import { DotBadge } from "./Badge/DotBadge.components";

export type AMLStatusSummaryProps = {
  processingStatus?: ProcessingStatus | null;
};

export const AMLStatusSummary: FC<AMLStatusSummaryProps> = ({ processingStatus }) => {
  const { t } = useTranslation("domains");
  const theme = useTheme();
  return !processingStatus ? null : (
    <Box display='inline'>
      <Typography display='inline' fontSize={theme.mixins.fonts.fontSize.sm}>
        {t("List Status")}: &nbsp; &nbsp;
      </Typography>
      <Typography
        color={getCodeSystemProcessingStatusColor(processingStatus, theme.palette)}
        display='inline'
        marginRight={1}
        fontSize={theme.mixins.fonts.fontSize.sm}
      >
        <DotBadge processingStatus={processingStatus} />
      </Typography>
    </Box>
  );
};
