import { sadaConfig } from "./sadaConfig";

export function createScriptElement(src: string, type?: string) {
  const script = document.createElement("script");
  script.src = src;
  script.defer = true;
  script.type = type ?? "text/javascript";

  return script;
}

export function createStyleElement(href: string) {
  const link = document.createElement("link");
  link.href = href;
  link.type = "text/css";
  link.rel = "stylesheet";

  return link;
}

export type TSadaInstance = {
  init: (config: { appType: "admin" | "provider" | "consumer"; appSignIn: () => void }) => void;
  onRouteChange: (name: string) => void;
  onTokenChange: (authToken: { token: string; refresh_token?: string }) => void;
};

export function getSadaInstance(): TSadaInstance | null {
  if (typeof window === "undefined") {
    return null;
  }

  return window["SADA_EMBEDDED_INSTANCE"] ?? null;
}

export function getSadaConfig() {
  const appUrl = sadaConfig.get("sadaBaseUrl");
  const apiEnv = sadaConfig.get("sadaApiEnv");
  const apiUrl = sadaConfig.get("sadaApiBaseUrl");

  return {
    apiEnv,
    apiUrl: apiUrl?.replace(/\/+$/, ""),
    appUrl: appUrl?.replace(/\/+$/, ""),
  };
}
