import { ProcedureAge } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertProcedureAgeToFormValues } from "../../others";
import {
  procedureAgeUpsertFormDefaultValues,
  procedureAgeUpsertFormSchema,
  IProcedureAgeUpsertFormValues,
} from "./ProcedureAgeInformationUpsertFormSchema";

import { ProcedureAgeInformationForm } from "../ProcedureAgeInformation/ProcedureAgeInformationForm";

export type IProcedureAgeUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IProcedureAgeUpsertFormValues;
  };
};

type ProcedureAgeUpsertFormProps = {
  procedureAge?: ProcedureAge;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IProcedureAgeUpsertFormEvent) => void;
};

export const ProcedureAgeUpsertForm: FC<ProcedureAgeUpsertFormProps> = props => {
  const { buttonLabel, procedureAge, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IProcedureAgeUpsertFormValues>({
    defaultValues: procedureAgeUpsertFormDefaultValues,
    schema: procedureAgeUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IProcedureAgeUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (procedureAge) {
      const _procedureAge = convertProcedureAgeToFormValues(procedureAge);
      setValues(_procedureAge);
    }
  }, [procedureAge, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Procedure Age Information")} loading={isLoading} doYouHaveData>
                <ProcedureAgeInformationForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
