import { DecisionPlanCategory } from "@/schema/types";
import { DecisionUpdateContainer } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { decisionsBreadcrumbs } from "pages/Decisions/constants";
import { FC, useEffect } from "react";

type AdminDecisionUpdateContainerProps = {
  category: DecisionPlanCategory;
};

export const AdminDecisionUpdateContainer: FC<AdminDecisionUpdateContainerProps> = props => {
  const { category } = props;

  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    const { title, main, update } = decisionsBreadcrumbs();

    setBreadCrumb({ title: title(category), values: [main(category), update] });
  }, [category, setBreadCrumb, t]);

  return <DecisionUpdateContainer category={category} />;
};
