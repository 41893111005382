import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDepartmentsAutocompleteQuery } from "./gql";
import { IDepartmentAutocomplete } from "./schema";

export const createDepartmentAutocompleteOption = (department: IDepartmentAutocomplete) => {
  return createAutocompleteOption({ id: department?.id!, name: department?.name! }, "id", "name");
};

export const getDepartmentsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "vendorDepartments",
    query: useDepartmentsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
