import { z } from "zod";
import { decisionConditionsFormSchema } from "../DecisionConditions/DecisionConditionsFormSchema";
import { zodEnumSchema } from "@health/enum-options";

export const decisionConditionsGroupsFormSchema = z.object({
  outerConditionOperation: zodEnumSchema.conditionOperation.nullish(),
  innerConditionOperation: zodEnumSchema.conditionOperation.nullish(),
  conditions: z.array(decisionConditionsFormSchema),
});

export type IDecisionConditionsGroupsFormValues = z.infer<typeof decisionConditionsGroupsFormSchema>;
