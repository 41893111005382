/* eslint-disable react/jsx-handler-names */
import { Decision, DecisionFilterInput, DecisionPlanCategory } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useDecisionDeleteMutation, useDecisionListQuery } from "@/pages/Decisions/gql";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useDecisionListTableColumns } from "./useDecisionListTableColumns";

type DecisionListContainerProps = {
  category: DecisionPlanCategory;
  filter?: DecisionFilterInput;
  onAddNewClick: () => void;
  onEditRowClick: (item: Decision) => void;
};

export const DecisionListContainer: FC<DecisionListContainerProps> = props => {
  const { category, filter, onAddNewClick, onEditRowClick } = props;

  return (
    <GridProvider
      gridName={"decisionList"}
      hasTableSetting
      columns={useDecisionListTableColumns({ category })}
      query={useDecisionListQuery}
      variables={{
        filter: {
          categories: [category],
          ...filter,
        },
      }}
      hideFilterInput
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      fetchPolicy={"no-cache"}
      deleteItemProps={{
        name: "Decision",
        entityTypeName: "Decision",
        useDeleteMutation: useDecisionDeleteMutation,
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
