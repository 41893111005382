import { createPathDefinition } from "@toolkit/core";
import { optimaMedicalNecessityPath } from "../../constants/OptimaMedicalNecessityPaths";

export const insuranceBenefitApprovalLimitsRoute = optimaMedicalNecessityPath + "/insurance-benefit-approval-limit";

export const insuranceBenefitApprovalLimitsPaths = createPathDefinition(insuranceBenefitApprovalLimitsRoute, {
  list: "",
  create: "new",
  update: ":insuranceBenefitApprovalLimitId",
});
