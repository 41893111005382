import { OptionsStatistics } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Card, Tooltip, Typography, useTheme } from "@toolkit/ui";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Text } from "@visx/text";
import React, { useMemo, useState } from "react";
import { AnimatedPie } from "./AnimatedPie";
import { DEFAULT_MARGIN } from "./constants";
import { StatisticsCardProps } from "./StatisticsCard.types";
import { getOptionPercentage } from "./utils";

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
  width,
  height,
  margin = DEFAULT_MARGIN,
  animate = true,
  questionStatistics,
}) => {
  const { t } = useTranslation("admin");
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [tooltipData, setTooltipData] = useState<OptionsStatistics>();

  const optionNames = useMemo(() => (questionStatistics.question?.options?.filter(Boolean) ?? []) as string[], [questionStatistics]);
  const theme = useTheme();
  // color scales
  const COLORS_RANGE = [
    theme.palette.error.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.primary.dark,
  ];
  const getOptionColor = useMemo(
    () =>
      scaleOrdinal({
        domain: optionNames,
        range: COLORS_RANGE,
      }),
    [optionNames, COLORS_RANGE]
  );

  const optionsStatistics: OptionsStatistics[] = useMemo(
    () =>
      questionStatistics?.optionsStatistics?.map(option => ({
        option: option?.option,
        percentage: option?.percentage ?? 0,
      })) ?? [],
    [questionStatistics]
  );

  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<any>(null);
  const areaRef = React.useRef<SVGSVGElement>(null);

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 30;
  const outerRadius = radius;
  const innerRadius = outerRadius - donutThickness;

  const handleClickDatum = ({ data: { option } }) =>
    animate && setSelectedOption(selectedOption && selectedOption === option ? null : option ?? "");

  const handleMouseEnterText = (event, datum) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }

    setTooltipData(datum.data);
  };

  const handleMouseLeaveText = () => {
    setTooltipData(undefined);
  };

  return (
    <Card elevation={0}>
      <Tooltip title={questionStatistics.question?.question!} placement='top'>
        <Typography fontSize={theme.mixins.fonts.fontSize.md} color={theme.palette.primary.main} padding={2} fontWeight='bold' noWrap>
          {questionStatistics.question?.question}
        </Typography>
      </Tooltip>
      <Tooltip
        title={tooltipData ? `${(tooltipData as OptionsStatistics)?.option} (${(tooltipData as OptionsStatistics)?.percentage}%)` : ""}
        sx={{
          whiteSpace: "nowrap",
          maxWidth: "100%",
        }}
        placement='top'
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(positionRef.current.x, positionRef.current.y, 0, 0);
            },
          },
        }}
      >
        <svg ref={areaRef} width={width} height={height}>
          <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Text verticalAnchor='end' textAnchor='middle' fill={theme.palette.primary.main} fontSize={74} fontWeight='bold'>
              {questionStatistics.answeredParticipantsCount ?? 0}
            </Text>
            <Group top={24}>
              <Text verticalAnchor='start' textAnchor='middle' fill={theme.palette.primary.main} fontSize={theme.mixins.fonts.fontSize.lg}>
                {t("Participants")}
              </Text>
            </Group>
            <Pie
              data={selectedOption ? optionsStatistics?.filter(({ option }) => option === selectedOption) : optionsStatistics}
              pieValue={getOptionPercentage}
              outerRadius={outerRadius}
              innerRadius={innerRadius}
              cornerRadius={3}
              padAngle={0.005}
            >
              {pie => (
                <AnimatedPie<OptionsStatistics>
                  {...pie}
                  svgRef={areaRef}
                  containerWidth={width}
                  data={selectedOption ? optionsStatistics?.filter(({ option }) => option === selectedOption) : optionsStatistics}
                  outerRadius={outerRadius}
                  innerRadius={innerRadius}
                  animate={animate}
                  getKey={arc => arc.data.option + ` (${arc.data.percentage}%)`}
                  onClickDatum={(option: any) => handleClickDatum(option)}
                  getColor={arc => getOptionColor(arc.data.option ?? "")}
                  onMouseEnterText={handleMouseEnterText}
                  onMouseLeaveText={handleMouseLeaveText}
                />
              )}
            </Pie>
          </Group>
        </svg>
      </Tooltip>
    </Card>
  );
};

export default StatisticsCard;
