import { GuidedCareJourneyItemStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";
import { i18n } from "@toolkit/i18n";

export const guidedCareJourneyItemStatusOptionsMap: IEnumToOptionsMap<GuidedCareJourneyItemStatus> = {
  [GuidedCareJourneyItemStatus.Accepted]: {
    key: GuidedCareJourneyItemStatus.Accepted,
    get label() {
      return i18n.t("Accepted");
    },
    value: GuidedCareJourneyItemStatus.Accepted,
  },
  [GuidedCareJourneyItemStatus.Cancelled]: {
    key: GuidedCareJourneyItemStatus.Cancelled,
    get label() {
      return i18n.t("Cancelled");
    },
    value: GuidedCareJourneyItemStatus.Cancelled,
  },
  [GuidedCareJourneyItemStatus.Completed]: {
    key: GuidedCareJourneyItemStatus.Completed,

    get label() {
      return i18n.t("Completed");
    },
    value: GuidedCareJourneyItemStatus.Completed,
  },
  [GuidedCareJourneyItemStatus.Failed]: {
    key: GuidedCareJourneyItemStatus.Failed,

    get label() {
      return i18n.t("Failed");
    },
    value: GuidedCareJourneyItemStatus.Failed,
  },
  [GuidedCareJourneyItemStatus.InProgress]: {
    key: GuidedCareJourneyItemStatus.InProgress,
    get label() {
      return i18n.t("In Progress");
    },
    value: GuidedCareJourneyItemStatus.InProgress,
  },
  [GuidedCareJourneyItemStatus.Missed]: {
    key: GuidedCareJourneyItemStatus.Missed,

    get label() {
      return i18n.t("Missed");
    },
    value: GuidedCareJourneyItemStatus.Missed,
  },
  [GuidedCareJourneyItemStatus.OnHold]: {
    key: GuidedCareJourneyItemStatus.OnHold,
    get label() {
      return i18n.t("On Hold");
    },
    value: GuidedCareJourneyItemStatus.OnHold,
  },
  [GuidedCareJourneyItemStatus.Pending]: {
    key: GuidedCareJourneyItemStatus.Pending,
    get label() {
      return i18n.t("Pending");
    },
    value: GuidedCareJourneyItemStatus.Pending,
  },
  [GuidedCareJourneyItemStatus.Received]: {
    key: GuidedCareJourneyItemStatus.Received,
    get label() {
      return i18n.t("Received");
    },
    value: GuidedCareJourneyItemStatus.Received,
  },
  [GuidedCareJourneyItemStatus.Rejected]: {
    key: GuidedCareJourneyItemStatus.Rejected,
    get label() {
      return i18n.t("Rejected");
    },
    value: GuidedCareJourneyItemStatus.Rejected,
  },
  [GuidedCareJourneyItemStatus.Rescheduled]: {
    key: GuidedCareJourneyItemStatus.Rescheduled,
    get label() {
      return i18n.t("Rescheduled");
    },
    value: GuidedCareJourneyItemStatus.Rescheduled,
  },
};

export const guidedCareJourneyItemStatusOptions = Object.values(guidedCareJourneyItemStatusOptionsMap);
