import { OptimaAction } from "@/schema/types";
import { CustomTable } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaEditActionsColumns } from "./useOptimaEditActionsColumns";

type OptimaEditActionsProps = {
  actions: OptimaAction[];
};

export const OptimaEditActions: FC<OptimaEditActionsProps> = props => {
  const { actions } = props;

  return <CustomTable data={actions} columns={useOptimaEditActionsColumns()} hasFooter={false} scrollable />;
};
