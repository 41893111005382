import { makeStyles } from "@toolkit/ui";

export const useSurveyListActionsStyles = makeStyles()(theme => ({
  publishButton: {
    "&& svg": {
      fill: theme.palette.primary.main,
      width: 20,
      height: 20,
    },
  },
  cloneButton: {
    "&& svg": {
      fill: theme.palette.primary.main,
      width: 26,
      height: 26,
    },
  },
  unpublishButton: {
    "&& svg": {
      fill: theme.palette.primary.main,
      width: 20,
      height: 20,
    },
  },
  cancelButton: {
    "&& svg": {
      fill: theme.palette.error.main,
      width: 16,
      height: 16,
    },
  },
}));
