import React, { FC, useEffect, useState } from "react";
import { HealthProgramMembershipRequest } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useOpenState } from "@toolkit/core";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import {
  HealthProgramMembershipRequestApproveForm,
  HealthProgramMembershipRequestApproveFormApi,
  IHealthProgramMembershipRequestApproveFormEvent,
} from "../../forms/HealthProgramMembershipRequestApprove/HealthProgramMembershipRequestApproveForm";

type IHealthProgramMembershipRequestApproveModalData = {
  healthProgramMembershipRequest: HealthProgramMembershipRequest;
};

type IOpen = (data: IHealthProgramMembershipRequestApproveModalData) => void;
type IClose = () => void;

export const HealthProgramMembershipRequestApproveModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type HealthProgramMembershipRequestApproveModalProps = {
  isLoading?: boolean;
  onChange: (event: IHealthProgramMembershipRequestApproveFormEvent) => void;
};

export const HealthProgramMembershipRequestApproveModal: FC<HealthProgramMembershipRequestApproveModalProps> = props => {
  const { isLoading, onChange } = props;

  const [data, setData] = useState<IHealthProgramMembershipRequestApproveModalData>();

  const { t } = useTranslation("admin");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const onApproveClick = () => {
    HealthProgramMembershipRequestApproveFormApi.submit();
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    HealthProgramMembershipRequestApproveModalApi.open = open;
    HealthProgramMembershipRequestApproveModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Approve Insurance Request"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <Button
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onApproveClick}
          >
            {t("Approve")}
          </Button>
        ),
      }}
    >
      <HealthProgramMembershipRequestApproveForm
        healthProgramMembershipRequest={data?.healthProgramMembershipRequest!}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
