import { H_OrderDirection, ProviderGuidedCareHealthProgramTeamSortingField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { TableGrid } from "@toolkit/ui";
import { FC } from "react";
import { useCaseManagementPatientTeamListQuery } from "../../gql";
import { useCaseManagementPatientTeamsColumns } from "./useCaseManagementPatientTeamsColumns";

type CaseManagementPatientTeamsProps = {
  patientId: string;
  isEnrolled?: boolean;
};

export const CaseManagementPatientTeams: FC<CaseManagementPatientTeamsProps> = props => {
  const { patientId, isEnrolled } = props;

  return (
    <GridProvider
      gridName={"caseManagementPatientTeams"}
      query={useCaseManagementPatientTeamListQuery}
      columns={useCaseManagementPatientTeamsColumns()}
      hideFilterInput
      variables={{
        filter: {
          patients: isEnrolled ? [patientId] : undefined,
          patientsNotIn: isEnrolled ? undefined : [patientId],
          isProgramLatestVersion: isEnrolled ? undefined : true,
        },
        sortBy: {
          field: ProviderGuidedCareHealthProgramTeamSortingField.Created,
          direction: H_OrderDirection.Desc,
        },
      }}
      skipCall={!patientId}
    >
      <TableGrid />
    </GridProvider>
  );
};
