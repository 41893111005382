/* eslint-disable max-lines */
export enum EVENT_TOPIC {
  PING_PONG = "PING_PONG",
  ORDER_STATUS_UPDATE = "ORDER_STATUS_UPDATE",
  MEDICAL_DELIVERY_REQUEST_STATUS_CHANGED = "ERX_DELIVERY_STATUS_UPDATE",
  PRESCRIPTION_STATUS_CHANGED = "ERX_STATUS_UPDATE",
  ORDER_DELETED = "ORDER_DELETED",
  CHAT_MESSAGE_RECEIVED = "CHAT",
  NEED_ACTION_CODE_SYSTEM = "NEED_ACTION_CODE_SYSTEM",
  HEALTH_PROGRAM_STATUS_UPDATE = "HEALTH_PROGRAM_VISIT_STATUS_CHANGED",
  HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED = "HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED",
  DOCTOR_AVAILABILITY_STATUS_CHANGED = "DOCTOR_AVAILABILITY_STATUS_CHANGED",
  CHRONIC_PROGRAM_ENROLLMENT = "CHRONIC_PROGRAM_ENROLLMENT",
  PATIENT_PROFILE_ACCESS_REQUEST = "PATIENT_PROFILE_ACCESS_REQUEST",
  PATIENT_PROFILE_ACCESS_RESPONSE = "PATIENT_PROFILE_ACCESS_RESPONSE",
  PATIENT_PROFILE_ACCESS_CLOSE = "PATIENT_PROFILE_ACCESS_CLOSE",
  ORDER_TIMEOUT_ALERT = "ORDER_TIMEOUT_ALERT",
  HEALTH_PROGRAM_APPOINTMENT_REMINDER = "HEALTH_PROGRAM_APPOINTMENT_REMINDER",
  GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER = "GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER",
  NOTIFICATION_COUNT_AFTER_MARK_ALL_NOTIFICATION_AS_READ = "MARK_ALL_AS_READ",
  NOTIFICATION_COUNT_AFTER_MARK_A_NOTIFICATION_AS_READ = "MARK_AS_READ",
  NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION = "NEW_NOTIFICATION",
  VISIT_VITAL_SIGNS = "VISIT_VITAL_SIGNS",
  OPTIMA_VALIDATION_REQUEST = "VALIDATION_REQUEST",
  HEALTH_PARAMETERS = "HEALTH_PARAMETERS",
  MEDICAL_FORM = "MEDICAL_FORM",
  OPTIMA_NEW_TRANSACTION_NOTIFICATION = "NEW_TRANSACTION_NOTIFICATION",
  PATIENT_ENROLLMENT_REQUEST = "PATIENT_ENROLLMENT_REQUEST",
}
interface NotificationBaseEvent {
  topic: EVENT_TOPIC;
  data: any;
}

interface NotificationCountChangedEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION;
  data: {
    count: number;
    userId: string;
  };
}
interface MedicalDeliveryNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.MEDICAL_DELIVERY_REQUEST_STATUS_CHANGED;
  data: {
    id: string;
    status: string;
    branchId: string;
  };
}

interface PrescriptionStatusChangedNotification extends NotificationBaseEvent {
  topic: EVENT_TOPIC.PRESCRIPTION_STATUS_CHANGED;
  data: {
    id: string;
    status: string;
    branchId: string;
  };
}
export interface OrderStatusChangedNotification extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_STATUS_UPDATE;
  data: {
    id: string;
    status: string;
    branchId: string;
    deliveryStatus: string;
    userId: string;
  };
}
interface OrderDeletedNotification extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_DELETED;
  data: {
    id: string;
    userId: string;
    branchId: string;
  };
}
interface NeedActionCodeSystemNotification extends NotificationBaseEvent {
  topic: EVENT_TOPIC.NEED_ACTION_CODE_SYSTEM;
  data: {
    id: string;
    version: string;
    processingStatus: string;
    codeSystemCode: string;
  };
}
interface ChatMessageReceivedNotification extends NotificationBaseEvent {
  topic: EVENT_TOPIC.CHAT_MESSAGE_RECEIVED;
  data: {
    status: "NEW" | "EDITED" | "DELETED";
    chatGroupId: string;
    chatId: string;
    message?: string;
    attachments: [
      {
        id: string;
        name: string;
        type: string;
        url: string;
      }
    ];
  };
}
interface HealthProgramStatusUpdate extends NotificationBaseEvent {
  topic: EVENT_TOPIC.HEALTH_PROGRAM_STATUS_UPDATE;
  data: {
    id;
    graphqlType;
    creationDate;
    changes: [
      {
        field;
        value;
      }
    ];
  };
}

interface HealthProgramAppointmentStatusChanged extends NotificationBaseEvent {
  topic: EVENT_TOPIC.HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED;
  data: {
    id;
    graphqlType;
    creationDate;
    changes: [
      {
        field;
        value;
      }
    ];
  };
}

interface PatientProfileAccessRequest extends NotificationBaseEvent {
  topic: EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST;
  data: {
    patientId: string;
    patientProfileConsentFormId: string;
    patientUserId: string;
    requesterUserId: string;
  };
}
interface PatientProfileAccessResponse extends NotificationBaseEvent {
  topic: EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST;
  data: {
    patientId: string;
    patientProfileConsentFormId: string;
    patientUserId: string;
    requesterUserId: string;
    consentStatus: string;
  };
}
interface PatientProfileAccessClose extends NotificationBaseEvent {
  topic: EVENT_TOPIC.PATIENT_PROFILE_ACCESS_CLOSE;
  data: {
    patientId: string;
    patientProfileConsentFormId: string;
    patientUserId: string;
    requesterUserId: string;
    consentStatus: string;
  };
}

interface DoctorAvailabilityStatusChanged extends NotificationBaseEvent {
  topic: EVENT_TOPIC.DOCTOR_AVAILABILITY_STATUS_CHANGED;
  data: {
    doctor: string;
    status: string;
    startTime: string;
    endTime: string;
    period;
  };
}

interface ChronicProgramEnrollmentNotification extends NotificationBaseEvent {
  topic: EVENT_TOPIC.CHRONIC_PROGRAM_ENROLLMENT;
  data: {
    programName: string;
    userId: string;
    programId: string;
    chronicDiseaseSeverity: string;
    chronicDisease: string;
    doctorId: string;
  };
}
export interface OrderStatusChangedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_STATUS_UPDATE;
  data: {
    id: string;
    status: string;
    branchId: string;
    deliveryStatus: string;
    userId: string;
  };
}
export interface OrderDeletedNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_DELETED;
  data: {
    id: string;
    userId: string;
    branchId: string;
  };
}

export interface orderTimeoutAlertNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.ORDER_TIMEOUT_ALERT;
  data: {
    orderId: string;
    branchId: string;
    vendorId: string;
    message: string;
  };
}

export interface appointmentReminderNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.HEALTH_PROGRAM_APPOINTMENT_REMINDER;
  data: {
    id: string;
    englishText: string;
    arabicText: string;
    graphqlType: string;
    notificationType: string;
    users: string[];
    changes: { field: string; value: string }[];
  };
}

export interface guidedCareAppointmentBookingReminderNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER;
  data: {
    appointmentId: string;
    consumerId: string;
    consumerUserId: string;
    doctorUserId: string;
    careTeamAdminUserId: string;
    consumerMessage: string;
    careTeamAdminMessage: string;
  };
}

export interface visitsVitalSignsEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.VISIT_VITAL_SIGNS;
  data: {
    patientId: string;
    patientUserId: string;
    doctorUserId: string;
    hpId: string;
    hpCode: string;
  };
}

export interface healthProgramsEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.HEALTH_PARAMETERS;
  data: {
    patientId: string;
    patientUserId: string;
    doctorUserId: string;
    healthParameters: { id: string; code: string }[];
  };
}

interface MedicalFormNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.MEDICAL_FORM;
  data: {
    id: string;
    guidedCareItemId: string;
    userId: string;
    patientId: string;
  };
}

interface OptimaTransactionNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.OPTIMA_NEW_TRANSACTION_NOTIFICATION;
  data: Record<string, string>;
}

interface ValidationRequestNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.OPTIMA_VALIDATION_REQUEST;
  data: {
    id: string;
    visitId: string;
    vendorId: string;
    userIds: string[];
  };
}

interface PatientEnrollmentNotificationEvent extends NotificationBaseEvent {
  topic: EVENT_TOPIC.PATIENT_ENROLLMENT_REQUEST;
  data: {
    id: string;
    patientId: string;
    userId: string;
    teamLeaderId: string;
    teamId: string;
    status: string;
  };
}

type NotificationEvent =
  | ChatMessageReceivedNotification
  | MedicalDeliveryNotificationEvent
  | NeedActionCodeSystemNotification
  | NotificationBaseEvent
  | OrderDeletedNotification
  | OrderStatusChangedNotification
  | PrescriptionStatusChangedNotification
  | HealthProgramStatusUpdate
  | HealthProgramAppointmentStatusChanged
  | DoctorAvailabilityStatusChanged
  | ChronicProgramEnrollmentNotification
  | OrderStatusChangedNotificationEvent
  | OrderDeletedNotificationEvent
  | appointmentReminderNotificationEvent
  | orderTimeoutAlertNotificationEvent
  | guidedCareAppointmentBookingReminderNotificationEvent
  | NotificationCountChangedEvent
  | OptimaTransactionNotificationEvent
  | PatientEnrollmentNotificationEvent;

export interface EVENT_LISTENER<T extends NotificationBaseEvent = NotificationEvent> {
  (eventData: T): void;
}
export interface TOPIC_LISTENERS extends Record<EVENT_TOPIC, [EVENT_LISTENER<any>]> {
  [EVENT_TOPIC.CHAT_MESSAGE_RECEIVED]: [EVENT_LISTENER<ChatMessageReceivedNotification>];
  [EVENT_TOPIC.MEDICAL_DELIVERY_REQUEST_STATUS_CHANGED]: [EVENT_LISTENER<MedicalDeliveryNotificationEvent>];
  [EVENT_TOPIC.NEED_ACTION_CODE_SYSTEM]: [EVENT_LISTENER<NeedActionCodeSystemNotification>];
  [EVENT_TOPIC.ORDER_DELETED]: [EVENT_LISTENER<OrderDeletedNotification>];
  [EVENT_TOPIC.ORDER_STATUS_UPDATE]: [EVENT_LISTENER<OrderStatusChangedNotification>];
  [EVENT_TOPIC.PRESCRIPTION_STATUS_CHANGED]: [EVENT_LISTENER<PrescriptionStatusChangedNotification>];
  [EVENT_TOPIC.HEALTH_PROGRAM_STATUS_UPDATE]: [EVENT_LISTENER<HealthProgramStatusUpdate>];
  [EVENT_TOPIC.HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED]: [EVENT_LISTENER<HealthProgramAppointmentStatusChanged>];
  [EVENT_TOPIC.DOCTOR_AVAILABILITY_STATUS_CHANGED]: [EVENT_LISTENER<DoctorAvailabilityStatusChanged>];
  [EVENT_TOPIC.CHRONIC_PROGRAM_ENROLLMENT]: [EVENT_LISTENER<ChronicProgramEnrollmentNotification>];
  [EVENT_TOPIC.PATIENT_PROFILE_ACCESS_REQUEST]: [EVENT_LISTENER<PatientProfileAccessRequest>];
  [EVENT_TOPIC.PATIENT_PROFILE_ACCESS_RESPONSE]: [EVENT_LISTENER<PatientProfileAccessResponse>];
  [EVENT_TOPIC.PATIENT_PROFILE_ACCESS_CLOSE]: [EVENT_LISTENER<PatientProfileAccessClose>];
  [EVENT_TOPIC.HEALTH_PROGRAM_APPOINTMENT_REMINDER]: [EVENT_LISTENER<appointmentReminderNotificationEvent>];
  [EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_MARK_ALL_NOTIFICATION_AS_READ]: [EVENT_LISTENER<NotificationCountChangedEvent>];
  [EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_NEW_NOTIFICATION]: [EVENT_LISTENER<NotificationCountChangedEvent>];
  [EVENT_TOPIC.NOTIFICATION_COUNT_AFTER_MARK_A_NOTIFICATION_AS_READ]: [EVENT_LISTENER<NotificationCountChangedEvent>];
  [EVENT_TOPIC.VISIT_VITAL_SIGNS]: [EVENT_LISTENER<visitsVitalSignsEvent>];
  [EVENT_TOPIC.HEALTH_PARAMETERS]: [EVENT_LISTENER<healthProgramsEvent>];
  [EVENT_TOPIC.ORDER_TIMEOUT_ALERT]: [EVENT_LISTENER<orderTimeoutAlertNotificationEvent>];
  [EVENT_TOPIC.GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER]: [EVENT_LISTENER<guidedCareAppointmentBookingReminderNotificationEvent>];
  [EVENT_TOPIC.PING_PONG]: [EVENT_LISTENER<NotificationBaseEvent>];
  [EVENT_TOPIC.MEDICAL_FORM]: [EVENT_LISTENER<MedicalFormNotificationEvent>];
  [EVENT_TOPIC.OPTIMA_NEW_TRANSACTION_NOTIFICATION]: [EVENT_LISTENER<OptimaTransactionNotificationEvent>];
  [EVENT_TOPIC.OPTIMA_VALIDATION_REQUEST]: [EVENT_LISTENER<ValidationRequestNotificationEvent>];
  [EVENT_TOPIC.PATIENT_ENROLLMENT_REQUEST]: [EVENT_LISTENER<PatientEnrollmentNotificationEvent>];
}

export type OrgSubscribeFn = <T extends EVENT_TOPIC>(topic: T, callBack: TOPIC_LISTENERS[T][number]) => () => void;
export type SubscribeFn = <T extends EVENT_TOPIC>(topic: T, callBack: TOPIC_LISTENERS[T][number]) => void;
export declare type TOPIC_LISTENER<T extends NotificationBaseEvent> = Record<EVENT_TOPIC, EVENT_LISTENER<T>>;
