import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast, useGridContext } from "@toolkit/ui";
import { SyncChatFlowMutation, useSyncChatFlowMutation } from "../gql";
import { formatGraphQLError } from "@toolkit/apollo";

export const SyncButton = () => {
  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();
  const { refetch } = useGridContext();
  const [syncChatFlow, { loading }] = useSyncChatFlowMutation({
    onCompleted: (data: SyncChatFlowMutation) => {
      if (data?.syncChatFlow) {
        succeeded(t("AI Agent's Synced successfully!"));
        refetch({ first: 10, filter: null, after: null });
      } else {
        failed(t("AI Agent's Sync failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onClick = () => {
    syncChatFlow({});
  };
  return (
    <Button
      onClick={onClick}
      disabled={loading}
      sx={{
        paddingX: "40px",
        marginRight: "20px",
      }}
    >
      {t("Sync")}
    </Button>
  );
};
