import { MarketplaceProductSortingField, MarketplacePromotionSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { TogglePromotionsPublishStatus } from "pages/MarketplacePromotions/components/PublishPromotions";
import { AdminPromotionsQueryNode } from "pages/MarketplacePromotions/types";
import { useMemo } from "react";

export const usePromotionsListTableColumns = (): CustomTableColumnProps<AdminPromotionsQueryNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ photo }) => <GridImageModal image={photo!} title={t("Promotion Image")} altText={t("Promotion")} />,
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        filter: {
          type: "string",
          name: "name",
        },
        sort: {
          columnEnum: MarketplaceProductSortingField.Name,
        },
      },
      {
        key: "description",
        header: t("Description"),
        type: "truncated-text",
        accessor: ({ description, descriptionAr }) => pickLocalizedValue(description, descriptionAr),
      },
      {
        key: "startDate",
        header: t("Start Date"),
        type: "datetime",
        accessor: "startDate",
        filter: { type: "date", name: "startDate" },
        sort: {
          columnEnum: MarketplacePromotionSortingField.StartDate,
        },
      },
      {
        key: "endDate",
        header: t("End Date"),
        type: "datetime",
        accessor: "endDate",
        filter: {
          type: "date",
          name: "endDate",
        },
        sort: {
          columnEnum: MarketplacePromotionSortingField.EndDate,
        },
      },
      {
        key: "isPublished",
        header: t("Published"),
        accessor: ({ id, isPublished, endDate }) => <TogglePromotionsPublishStatus id={id} isPublished={isPublished} endDate={endDate} />,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
        sort: {
          columnEnum: MarketplacePromotionSortingField.Published,
        },
      },
      {
        key: "isActive",
        header: t("Is Active"),
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
        showOnlyForFilterField: true,
      },
    ];
  }, []);
};
