/* eslint-disable sonarjs/cognitive-complexity */
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, TextField, useTheme } from "@toolkit/ui";
import { QuestionType } from "@/schema/types";
import { ISurveyQuestionFormValues } from "../SurveyQuestions/SurveyQuestionFormSchema";
import { OptionItemIcon } from "../../components/OptionItemIcon/OptionItemIcon";

export interface EditSurveyQuestionOptionProps {
  defaultValue?: string;
  checked?: boolean;
  type?: QuestionType;
  color?: string;
  isEditing?: boolean;
  onSubmit?: (value: string) => void;
  allOptions?: ISurveyQuestionFormValues["options"];
  optionIndex: number;
}

export type EditSurveyQuestionOptionRef = {
  submit: () => void;
};

export const EditSurveyQuestionOption = forwardRef<EditSurveyQuestionOptionRef, EditSurveyQuestionOptionProps>(
  (
    {
      defaultValue = "",
      checked = false,
      type = QuestionType.MultiChoice,
      color,
      isEditing = false,
      onSubmit,
      allOptions = [],
      optionIndex,
    },
    ref
  ) => {
    const theme = useTheme();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [error, setError] = useState("");
    const { t } = useTranslation("admin");

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (inputRef.current) {
          validateAndSubmit(inputRef.current.value);
        }
      }
    };

    const validateAndSubmit = (value: string) => {
      if (!value.trim()) {
        setError(t("Option cannot be empty"));
        return;
      }

      if (allOptions.map((opt, idx) => idx !== optionIndex && opt.value.trim()).includes(value.trim())) {
        setError(t("Option Already Exist"));
        return;
      }

      setError("");
      onSubmit?.(value);
    };

    useImperativeHandle(ref, () => ({
      submit: () => {
        if (inputRef.current) {
          validateAndSubmit(inputRef.current.value);
        }
      },
    }));

    return (
      <Grid container alignItems='center' flexWrap='nowrap'>
        <Box
          sx={{
            width: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: -1,
          }}
        >
          <OptionItemIcon type={type} isChecked={checked} color={color || theme.palette.primary.main} />
        </Box>
        <Grid item xs={12} sm={8} md={4} sx={{ color: color ?? theme.palette.primary.main }}>
          {isEditing ? (
            <TextField
              fullWidth
              defaultValue={defaultValue}
              onKeyPress={handleKeyPress}
              size='small'
              inputRef={inputRef}
              error={!!error}
              helperText={error}
            />
          ) : (
            defaultValue
          )}
        </Grid>
      </Grid>
    );
  }
);

export default EditSurveyQuestionOption;
