import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionDivisionsAutocompleteQuery } from "../../gql";

type DecisionDivisionsAutocompleteProps = {
  name: string;
};

export const DecisionDivisionsAutocomplete: FC<DecisionDivisionsAutocompleteProps> = props => {
  const { name } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionDivisionsAutocompleteQuery,
    searchKey: "keyword",
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return <FormAutocomplete {...restRelayStyleConnection} name={name} label={t("Division")} options={options} />;
};
