import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderOutpatientJourneyActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProviderOutpatientJourneyActivationMutation = { __typename?: 'Mutation', vendorSupportOutpatientJourneyStatusUpdate?: { __typename?: 'VendorSupportOutpatientJourneyStatusUpdate', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null }> } | null };


export const ProviderOutpatientJourneyActivationDocument = gql`
    mutation ProviderOutpatientJourneyActivation($id: ID!) {
  vendorSupportOutpatientJourneyStatusUpdate(id: $id) {
    vendorErrors {
      field
      message
    }
  }
}
    `;
export type ProviderOutpatientJourneyActivationMutationFn = Apollo.MutationFunction<ProviderOutpatientJourneyActivationMutation, ProviderOutpatientJourneyActivationMutationVariables>;

/**
 * __useProviderOutpatientJourneyActivationMutation__
 *
 * To run a mutation, you first call `useProviderOutpatientJourneyActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderOutpatientJourneyActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerOutpatientJourneyActivationMutation, { data, loading, error }] = useProviderOutpatientJourneyActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderOutpatientJourneyActivationMutation(baseOptions?: Apollo.MutationHookOptions<ProviderOutpatientJourneyActivationMutation, ProviderOutpatientJourneyActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderOutpatientJourneyActivationMutation, ProviderOutpatientJourneyActivationMutationVariables>(ProviderOutpatientJourneyActivationDocument, options);
      }
export type ProviderOutpatientJourneyActivationMutationHookResult = ReturnType<typeof useProviderOutpatientJourneyActivationMutation>;
export type ProviderOutpatientJourneyActivationMutationResult = Apollo.MutationResult<ProviderOutpatientJourneyActivationMutation>;
export type ProviderOutpatientJourneyActivationMutationOptions = Apollo.BaseMutationOptions<ProviderOutpatientJourneyActivationMutation, ProviderOutpatientJourneyActivationMutationVariables>;