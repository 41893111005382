import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type PageInfoAutocompleteFragment = { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null };

export const PageInfoAutocompleteFragmentDoc = gql`
    fragment PageInfoAutocomplete on PageInfo {
  hasNextPage
  endCursor
}
    `;