import { createPathDefinition } from "@toolkit/core";

export const subListsRoute = "/decisions/sub-lists";

export const subListsPaths = createPathDefinition(subListsRoute, {
  main: "",
  new: "new",
  edit: ":id",
  editItem: "items/:itemId",
});
