import { createPathDefinition } from "@toolkit/core";
import { optimaMedicalNecessityPath } from "../../constants/OptimaMedicalNecessityPaths";

export const benefitMapsRoute = optimaMedicalNecessityPath + "/benefit-maps";

export const benefitMapsPaths = createPathDefinition(benefitMapsRoute, {
  list: "",
  create: "new",
  update: ":benefitMapId",
});
