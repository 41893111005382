import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, Grid } from "@toolkit/ui";
import { HeaderCell } from "./HeaderCell";

export const VersionListHeader = () => {
  const { t } = useTranslation("domains");

  return (
    <Grid container spacing={2} marginBottom='8px'>
      <HeaderCell />
      <HeaderCell icon={<CustomIcon icon='personalization' />} title={t("Status")} />
      <HeaderCell icon={<CustomIcon icon='icons8_download' />} title={t("Creation date")} />
      <HeaderCell icon={<CustomIcon icon='icons8_restart' />} title={t("Last update")} />
      <HeaderCell />
      <HeaderCell />
      <HeaderCell />
    </Grid>
  );
};
