import { makeStyles } from "@toolkit/ui";

export const useCardStyles = makeStyles()(theme => ({
  cardBase: {
    padding: 16,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
  },
  cardTitle: {
    textAlign: "start",
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    marginBottom: 8,
  },
  cardBodyText: {
    textAlign: "start",
  },
}));
