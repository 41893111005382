import { DiabetesPrognosis, Maybe } from "@/schema/types";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Grid } from "@toolkit/ui";
import { CaseManagementPatientDigitalTwinPrognosticRiskScore } from "../CaseManagementPatientDigitalTwinPrognosticRiskScore/CaseManagementPatientDigitalTwinPrognosticRiskScore";
import { CaseManagementPatientDigitalTwinLinearProgress } from "../CaseManagementPatientDigitalTwinLinearProgress/CaseManagementPatientDigitalTwinLinearProgress";

type CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetesProps = {
  category: string;
  value: number;
  complications?: Maybe<DiabetesPrognosis>;
};

export const CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetes: FC<
  CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetesProps
> = props => {
  const { category, value, complications } = props;

  const { t } = useTranslation("admin");

  return (
    <CaseManagementPatientDigitalTwinPrognosticRiskScore name={t("Diabetes")} category={category} value={value}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Nephropathy")}
            category={complications?.categoryNephropathy!}
            value={complications?.riskOfNephropathyComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Neuropathy")}
            category={complications?.categoryNeuropathy!}
            value={complications?.riskOfNeuropathyComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Retinopathy")}
            category={complications?.categoryRetinopathy!}
            value={complications?.riskOfRetinopathyComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Hypertension")}
            category={complications?.categoryHypertension!}
            value={complications?.riskOfHypertensionComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("IHD")}
            category={complications?.categoryIschemicHeartDisease!}
            value={complications?.riskOfIschemicHeartDiseaseComplications!}
          />
        </Grid>
      </Grid>
    </CaseManagementPatientDigitalTwinPrognosticRiskScore>
  );
};
