import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthProgramMembersAutocompleteQuery } from "./gql";

export type IHealthProgramMemberAutocomplete = ExtractNodeType<HealthProgramMembersAutocompleteQuery>;

export const healthProgramMemberSchema = createZodAutocompleteFromType<IHealthProgramMemberAutocomplete>({
  id: z.string(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
});
