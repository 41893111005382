import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { MarketplaceCategoryFilterInput } from "../../schema/types";
import { useMarketplaceProductCategoriesAutocompleteQuery } from "./gql";

type MarketplaceProductCategoriesAutocompleteProps = IAutocompleteProps<MarketplaceCategoryFilterInput>;

export const MarketplaceProductsCategoriesAutocomplete: FC<MarketplaceProductCategoriesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useMarketplaceProductCategoriesAutocompleteQuery,
    variables: { filter },
    searchKey: pickLocalizedValue("name", "nameAr"),
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.name!, item?.nameAr!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Categories") : t("Category"))}
    />
  );
};
