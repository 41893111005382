import { UserSortField } from "@/schema/types";
import { useUserActivationMutation } from "@health/domains";
import { appRoleTypeOptionsMap, getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { ActivationSwitch, CustomTableColumnProps, formatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { IUserAdminListNode } from "../../types";

export const useUserAdminListContainerColumns = (): CustomTableColumnProps<IUserAdminListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: "fullName",
        sort: {
          columnEnum: UserSortField.FirstName,
        },
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        sort: {
          columnEnum: UserSortField.Email,
        },
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
        type: "mobile",
      },
      {
        key: "appRole",
        header: t("App Role"),
        accessor: ({ appRole }) => appRoleTypeOptionsMap[appRole!]?.label,
        filter: getAutocompleteEnumFilter("AppRoleType", "appRole"),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <ActivationSwitch id={id} isActive={isActive} mutation={useUserActivationMutation} />,
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ dateJoined }) => formatDate(dateJoined, "DD MMM YYYY"),
        type: "date",
        sort: {
          columnEnum: UserSortField.DateJoined,
        },
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserStatus", "status"),
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "dateJoinedTo",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
    ],
    [t]
  );
};
