import { HealthProgramNetworkSortingField } from "@/schema/types";
import { getHealthProgramsAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramNetworkActivation } from "../../components/HealthProgramNetworkActivation/HealthProgramNetworkActivation";
import { healthProgramNetworksPaths } from "../../constants";
import { IHealthProgramNetworkNode } from "../../types";

export const useHealthProgramNetworkListContainerColumns = (): CustomTableColumnProps<IHealthProgramNetworkNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowProvidersClick = useCallback(
    (networkId: string) => {
      navigate(healthProgramNetworksPaths.providerList.fullPathWithParams({ networkId }));
    },
    [navigate]
  );

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        sort: {
          columnEnum: HealthProgramNetworkSortingField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "categoryCode",
        header: t("Category Code"),
        accessor: "categoryCode",
        sort: {
          columnEnum: HealthProgramNetworkSortingField.Code,
        },
      },
      {
        key: "policyNumber",
        header: t("Policy Number"),
        accessor: "policyNumber",
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ payer }) => payer?.name,
      },
      {
        key: "providers",
        header: t("Providers"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowProvidersClick(id),
          tooltipMessage: row => t("show {{name}} providers", { name: row?.name }),
        },
        filter: getVendorsAutocompleteFilter({ name: "providers", multiple: true }),
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthPrograms", multiple: true }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        type: "date",
        accessor: "created",
        sort: {
          columnEnum: HealthProgramNetworkSortingField.Created,
        },
      },
      {
        key: "isActive",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthProgramNetworkActivation id={id} isActive={!!isActive} />,
        sort: {
          columnEnum: HealthProgramNetworkSortingField.IsActive,
        },
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    [onShowProvidersClick, t]
  );
};
