import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnosisValidProcedureUpdateMutationVariables = Types.Exact<{
  updateDiagnosisValidProcedureId: Types.Scalars['ID']['input'];
  input: Types.DiagnosisValidProcedureInput;
}>;


export type DiagnosisValidProcedureUpdateMutation = { __typename?: 'Mutation', updateDiagnosisValidProcedure?: { __typename?: 'DiagnosisValidProcedure', procedureCode: string, diagnosisCode: string, id: string } | null };


export const DiagnosisValidProcedureUpdateDocument = gql`
    mutation DiagnosisValidProcedureUpdate($updateDiagnosisValidProcedureId: ID!, $input: DiagnosisValidProcedureInput!) {
  updateDiagnosisValidProcedure(
    id: $updateDiagnosisValidProcedureId
    input: $input
  ) {
    procedureCode
    diagnosisCode
    id
  }
}
    `;
export type DiagnosisValidProcedureUpdateMutationFn = Apollo.MutationFunction<DiagnosisValidProcedureUpdateMutation, DiagnosisValidProcedureUpdateMutationVariables>;

/**
 * __useDiagnosisValidProcedureUpdateMutation__
 *
 * To run a mutation, you first call `useDiagnosisValidProcedureUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisValidProcedureUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diagnosisValidProcedureUpdateMutation, { data, loading, error }] = useDiagnosisValidProcedureUpdateMutation({
 *   variables: {
 *      updateDiagnosisValidProcedureId: // value for 'updateDiagnosisValidProcedureId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiagnosisValidProcedureUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DiagnosisValidProcedureUpdateMutation, DiagnosisValidProcedureUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiagnosisValidProcedureUpdateMutation, DiagnosisValidProcedureUpdateMutationVariables>(DiagnosisValidProcedureUpdateDocument, options);
      }
export type DiagnosisValidProcedureUpdateMutationHookResult = ReturnType<typeof useDiagnosisValidProcedureUpdateMutation>;
export type DiagnosisValidProcedureUpdateMutationResult = Apollo.MutationResult<DiagnosisValidProcedureUpdateMutation>;
export type DiagnosisValidProcedureUpdateMutationOptions = Apollo.BaseMutationOptions<DiagnosisValidProcedureUpdateMutation, DiagnosisValidProcedureUpdateMutationVariables>;