import { NavigateFunction } from "react-router-dom";
import { blocksPaths, blocksRoute } from "./BlocksPaths";
import { BlockListContainer } from "../containers/BlockList/BlockListContainer";
import RouteItem from "@/shared/components/Root/routeTypes";
import { BlockCreateContainer } from "../containers/BlockCreate/BlockCreateContainer";
import { BlockUpdateContainer } from "../containers/BlockUpdate/BlockUpdateContainer";

export const blocksRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "blocks-routes",
    text: "Blocks Management",
    route: blocksRoute,
    subItems: [
      {
        id: "blocks-list-route",
        route: blocksPaths.list.route,
        fullPath: blocksPaths.list.fullPath,
        element: <BlockListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "blocks-create-route",
        route: blocksPaths.create.route,
        fullPath: blocksPaths.create.fullPath,
        hidden: true,
        element: <BlockCreateContainer />,
      },
      {
        id: "blocks-update-route",
        route: blocksPaths.update.route,
        fullPath: blocksPaths.update.fullPath,
        hidden: true,
        element: <BlockUpdateContainer />,
      },
    ],
  };
};
