import {
  addressUpsertFormDefaultValues,
  addressUpsertFormSchema,
  getDefaultDaysTimesRangesValues,
  zodDaysTimesRangesSchema,
} from "@health/domains";
import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const getProviderUpsertFormSchema = (isCreateMode: boolean) => {
  return z
    .object({
      name: z.string().min(3).max(255),
      nameAr: z.string().min(3).max(255),
      ownerName: z.string().min(3).max(255),
      nationalId: z.string().min(3).max(255),
      email: isCreateMode ? z.string().email() : z.string().email().nullish(),
      tradeName: z.string().min(3).max(255),
      commercialRegistrationNumber: z.string().min(10),
      taxLicenseNumber: z.string().min(15),
      deliveryPrice: z.coerce.number().min(0),
      type: zodEnumSchema.vendorType,
      mobileNumber: isCreateMode ? z.string().min(1) : z.string().min(1).nullish(),
      phoneNumber: z.string().min(1),
      description: z.string().min(3).max(255),
      isActive: z.boolean(),
      hasMultipleBranches: z.boolean(),
      hasOwnDrivers: z.boolean(),
      acceptsDelivery: z.boolean().nullish(),
      supportOutpatientJourney: z.boolean(),
      isIntegrated: z.boolean(),
      enableVisitDetails: z.boolean(),
      editLikeEnabled: z.boolean(),
      hasOwnPaymentGateway: z.boolean(),
      notifyByEmail: z.boolean(),
      healthLicenseNumber: z.string().nullish(),
      healthLicenseStartDate: z.string().nullish(),
      healthLicenseEndDate: z.string().nullish(),
      logo: z.string(),
      backgroundImage: z.string(),
      workingHours: z.array(zodDaysTimesRangesSchema).nullish(),
      generalManagerEmail: z.string().email().nullish(),
      financialManagerName: z.string().max(255).nullish(),
      financialManagerEmail: z.string().email().nullish(),
      financialManagerMobileNumber: z.string().nullish(),
      purchasingManagerName: z.string().max(255).nullish(),
      purchasingManagerEmail: z.string().email().nullish(),
      purchasingManagerMobileNumber: z.string().nullish(),
      subscriptionPlan: zodSchema.subscriptionPlan,
      subscriptionFixedCostAmount: z.coerce.number().min(0).nullish(),
      subscriptionFixedOrderAmount: z.coerce.number().min(0).nullish(),
      subscriptionFixedOrderPercentage: z.coerce.number().min(0).max(100).nullish(),
    })
    .merge(addressUpsertFormSchema);
};

export type IProviderUpsertForm = z.infer<ReturnType<typeof getProviderUpsertFormSchema>>;

export const providerUpsertFormDefaultValues: Partial<IProviderUpsertForm> = {
  name: undefined,
  nameAr: undefined,
  ownerName: undefined,
  nationalId: undefined,
  email: undefined,
  tradeName: undefined,
  commercialRegistrationNumber: undefined,
  taxLicenseNumber: undefined,
  deliveryPrice: undefined,
  type: undefined,
  phoneNumber: undefined,
  mobileNumber: undefined,
  description: undefined,
  isActive: false,
  hasMultipleBranches: false,
  hasOwnDrivers: false,
  acceptsDelivery: false,
  supportOutpatientJourney: false,
  isIntegrated: false,
  enableVisitDetails: false,
  editLikeEnabled: false,
  hasOwnPaymentGateway: false,
  notifyByEmail: false,
  healthLicenseNumber: undefined,
  healthLicenseStartDate: undefined,
  healthLicenseEndDate: undefined,
  logo: undefined,
  backgroundImage: undefined,
  workingHours: getDefaultDaysTimesRangesValues(),
  generalManagerEmail: undefined,
  financialManagerName: undefined,
  financialManagerEmail: undefined,
  financialManagerMobileNumber: undefined,
  purchasingManagerName: undefined,
  purchasingManagerEmail: undefined,
  purchasingManagerMobileNumber: undefined,
  subscriptionPlan: undefined,
  subscriptionFixedCostAmount: undefined,
  subscriptionFixedOrderAmount: undefined,
  subscriptionFixedOrderPercentage: undefined,
  ...addressUpsertFormDefaultValues,
};
