import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CaseManagementPatientNoteCreateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.PatientCaseManagementNotesInput>;
}>;


export type CaseManagementPatientNoteCreateMutation = { __typename?: 'Mutation', patientCaseManagementNotesCreate?: { __typename?: 'PatientCaseManagementNotes', id: string } | null };


export const CaseManagementPatientNoteCreateDocument = gql`
    mutation CaseManagementPatientNoteCreate($input: PatientCaseManagementNotesInput) {
  patientCaseManagementNotesCreate(input: $input) {
    id
  }
}
    `;
export type CaseManagementPatientNoteCreateMutationFn = Apollo.MutationFunction<CaseManagementPatientNoteCreateMutation, CaseManagementPatientNoteCreateMutationVariables>;

/**
 * __useCaseManagementPatientNoteCreateMutation__
 *
 * To run a mutation, you first call `useCaseManagementPatientNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementPatientNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [caseManagementPatientNoteCreateMutation, { data, loading, error }] = useCaseManagementPatientNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCaseManagementPatientNoteCreateMutation(baseOptions?: Apollo.MutationHookOptions<CaseManagementPatientNoteCreateMutation, CaseManagementPatientNoteCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CaseManagementPatientNoteCreateMutation, CaseManagementPatientNoteCreateMutationVariables>(CaseManagementPatientNoteCreateDocument, options);
      }
export type CaseManagementPatientNoteCreateMutationHookResult = ReturnType<typeof useCaseManagementPatientNoteCreateMutation>;
export type CaseManagementPatientNoteCreateMutationResult = Apollo.MutationResult<CaseManagementPatientNoteCreateMutation>;
export type CaseManagementPatientNoteCreateMutationOptions = Apollo.BaseMutationOptions<CaseManagementPatientNoteCreateMutation, CaseManagementPatientNoteCreateMutationVariables>;