import React, { FC } from "react";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { IAutocompleteBasicProps } from "@/types";
import { usePermissionsAutocompleteQuery } from "./gql";
import { Permission } from "@/schema/types";

export const PermissionsAutocomplete: FC<IAutocompleteBasicProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, loading } = usePermissionsAutocompleteQuery({
    skip: props.disabled || skip,
  });

  const _data = data?.permissions as Permission[];

  const options = mapToAutocompleteOptions(_data, "id", "name");

  return (
    <FormAutocomplete
      {...rest}
      loading={loading}
      options={options}
      label={label || (props.multiple ? t("Permissions") : t("Permission"))}
    />
  );
};
