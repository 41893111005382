import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { GlobeIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { CityCreateContainer, CityListContainer, CityUpdateContainer } from "../containers";
import { citiesPaths, citiesRoute } from "./CitiesPaths";
import { blocksRoutes } from "@/pages/Blocks/constants/BlocksRoutes";

export const citiesRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "cities-routes",
    text: i18n.t("Cities Management", { ns: "admin" }),
    icon: <GlobeIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageCities),
    subItems: [
      {
        id: "cities-list-route",
        text: i18n.t("Cities", { ns: "admin" }),
        route: citiesRoute,
        subItems: [
          {
            id: "cities-list-route",
            route: citiesPaths.list.route,
            fullPath: citiesPaths.list.fullPath,
            element: <CityListContainer />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "cities-create-route",
            route: citiesPaths.create.route,
            fullPath: citiesPaths.create.fullPath,
            hidden: true,
            element: <CityCreateContainer />,
          },
          {
            id: "cities-update-route",
            route: citiesPaths.update.route,
            fullPath: citiesPaths.update.fullPath,
            hidden: true,
            element: <CityUpdateContainer />,
          },
        ],
      },
      blocksRoutes(navigate),
    ],
  };
};
