import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";

export type ISadaVideoAutocomplete = {
  id: string;
  title: string;
};

export const sadaVideoSchema = createZodAutocompleteFromType<ISadaVideoAutocomplete>({
  id: z.string(),
  title: z.string(),
});
