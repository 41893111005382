import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPermissionGroupUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.PermissionGroupUpdateInput;
}>;


export type UserPermissionGroupUpdateMutation = { __typename?: 'Mutation', permissionGroupUpdate?: { __typename?: 'PermissionGroupUpdate', permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', field?: string | null, message?: string | null }> } | null };


export const UserPermissionGroupUpdateDocument = gql`
    mutation UserPermissionGroupUpdate($id: ID!, $input: PermissionGroupUpdateInput!) {
  permissionGroupUpdate(id: $id, input: $input) {
    permissionGroupErrors {
      field
      message
    }
  }
}
    `;
export type UserPermissionGroupUpdateMutationFn = Apollo.MutationFunction<UserPermissionGroupUpdateMutation, UserPermissionGroupUpdateMutationVariables>;

/**
 * __useUserPermissionGroupUpdateMutation__
 *
 * To run a mutation, you first call `useUserPermissionGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPermissionGroupUpdateMutation, { data, loading, error }] = useUserPermissionGroupUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPermissionGroupUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserPermissionGroupUpdateMutation, UserPermissionGroupUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPermissionGroupUpdateMutation, UserPermissionGroupUpdateMutationVariables>(UserPermissionGroupUpdateDocument, options);
      }
export type UserPermissionGroupUpdateMutationHookResult = ReturnType<typeof useUserPermissionGroupUpdateMutation>;
export type UserPermissionGroupUpdateMutationResult = Apollo.MutationResult<UserPermissionGroupUpdateMutation>;
export type UserPermissionGroupUpdateMutationOptions = Apollo.BaseMutationOptions<UserPermissionGroupUpdateMutation, UserPermissionGroupUpdateMutationVariables>;