import { useOpenState } from "@toolkit/core";
import React, { FC } from "react";
import { Dialog } from "../../../base/mui";
import { CustomMuiDialogProps } from "./CustomMuiDialog.types";
import { CustomMuiDialogActions } from "./CustomMuiDialogActions.component";
import { CustomMuiDialogContent } from "./CustomMuiDialogContent.component";
import { CustomMuiDialogTitle } from "./CustomMuiDialogTitle.component";

export const CustomMuiDialog: FC<CustomMuiDialogProps> = ({
  button,
  children,
  DialogTitleProps,
  DialogActionsProps,
  DialogContentProps,
  onButtonClicked,
  ...props
}) => {
  const { handleClose: _handleClose, handleOpen, open: isOpen } = useOpenState();

  const handleClose = (e?: object, r: "backdropClick" | "escapeKeyDown" = "backdropClick") => {
    _handleClose();
    props.onClose?.(e!, r);
    DialogTitleProps?.onClose?.();
  };

  const mergedClickHandler = (e: React.MouseEvent) => {
    if (button?.props?.onClick) {
      button.props.onClick(e);
    } else {
      handleOpen();
    }
    onButtonClicked?.();
  };

  return (
    <React.Fragment>
      {button &&
        React.cloneElement(button, {
          onClick: mergedClickHandler,
        })}
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={!!isOpen || !!props.open}
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        {...props}
      >
        {!DialogTitleProps?.hidden && <CustomMuiDialogTitle onClose={() => handleClose()} {...DialogTitleProps} />}
        <CustomMuiDialogContent {...DialogContentProps}>{children}</CustomMuiDialogContent>
        <CustomMuiDialogActions onClose={() => handleClose()} {...DialogActionsProps} />
      </Dialog>
    </React.Fragment>
  );
};
