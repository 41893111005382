import { Vendor, VendorOrderField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useSetProvidersBreadcrumbs } from "../../hooks";
import { providersPaths } from "../../constants";
import { useProviderListQuery } from "../../gql";
import { useProviderListContainerColumns } from "./useProviderListContainerColumns";

export const ProviderListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(providersPaths.create.fullPath);
  };

  const onEditRowClick = (item: Vendor) => {
    navigate(providersPaths.update.fullPathWithParams({ providerId: item?.id }));
  };

  useSetProvidersBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"providerList"}
      query={useProviderListQuery}
      columns={useProviderListContainerColumns()}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: VendorOrderField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
