import { useDebounce } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { isNil } from "lodash";
import { CircularProgress, Autocomplete as MuiAutocomplete, TextField, Chip } from "../../base/mui";
import { AutocompleteProps, IAutocompleteOption, IAutocompleteValue } from "../../types";
import useAutocompleteStyles from "./Autocomplete.styles";
import { TruncateTypography } from "../TruncateTypography";
import { CustomInfiniteScroll } from "../CustomInfiniteScroll";

const Autocomplete = <T,>(props: AutocompleteProps) => {
  const {
    label,
    placeholder,
    options,
    error,
    helperText,
    hasMore,
    TextFieldProps,
    blurOnSelect = true,
    renderOption,
    renderTags,
    handleFetchMore,
    handleSearch,
    ...rest
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const { t } = useTranslation();
  const { classes, theme } = useAutocompleteStyles();

  const debouncedSearchValue = useDebounce(searchValue, 350);

  const handleInputChange = (event: SyntheticEvent, value: string, reason: string) => {
    setInputValue(value);
    if (reason === "input") {
      setSearchValue(value);
    } else if ((reason === "clear" || reason === "reset") && !value) {
      setSearchValue("");
    }
  };

  const isOptionEqualToValue = (option: IAutocompleteOption<T>, value: IAutocompleteOption<T> | IAutocompleteOption<T>[]) => {
    if (Array.isArray(value)) {
      return value.some(item => item?.key === option?.key);
    } else {
      return option?.key === value?.key;
    }
  };

  useEffect(() => {
    if (isNil(debouncedSearchValue)) return;

    handleSearch?.(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <MuiAutocomplete
      options={options ?? []}
      noOptionsText={t("No options")}
      loadingText={t("Loading...")}
      className={classes.autocomplete}
      classes={{
        paper: options?.length! > 5 ? classes.paper : `${classes.paper} ${classes.height}`,
        tag: classes.tag,
      }}
      inputValue={inputValue}
      getOptionLabel={option => option?.label}
      onInputChange={handleInputChange}
      blurOnSelect={blurOnSelect}
      ListboxComponent={listBoxProps => (
        <CustomInfiniteScroll {...listBoxProps} dataLength={options?.length} onFetchMore={handleFetchMore} hasMore={hasMore} />
      )}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={
        renderOption ??
        ((_props, option) => (
          <li {..._props}>
            <TruncateTypography fontWeight={theme.mixins.fonts.fontWeight.medium} text={option?.label} maxWidth={"90%"} />
          </li>
        ))
      }
      renderTags={
        renderTags ??
        ((value: IAutocompleteValue<T>[], getTagProps) =>
          value?.map((option, index) => <Chip {...getTagProps({ index })} key={option?.key} label={option?.label} />))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          placeholder={props?.multiple && props?.value?.length ? "" : placeholder || label}
          error={error}
          helperText={helperText}
          variant={"filled"}
          className={classes.textField}
          fullWidth
          hiddenLabel
          autoComplete='off'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props?.loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          {...TextFieldProps}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
