import { i18n } from "@toolkit/i18n";
import { SendBulkMessagesMethodEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const sendBulkMessagesMethodOptionsMap: IEnumToOptionsMap<SendBulkMessagesMethodEnum> = {
  [SendBulkMessagesMethodEnum.Email]: {
    key: SendBulkMessagesMethodEnum.Email,
    get label() {
      return i18n.t("Email", { ns: "domains" });
    },
    value: SendBulkMessagesMethodEnum.Email,
  },
  [SendBulkMessagesMethodEnum.Mobile]: {
    key: SendBulkMessagesMethodEnum.Mobile,
    get label() {
      return i18n.t("Mobile", { ns: "domains" });
    },
    value: SendBulkMessagesMethodEnum.Mobile,
  },
};

export const sendBulkMessagesMethodOptions = Object.values(sendBulkMessagesMethodOptionsMap);
