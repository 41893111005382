import { InfoItems } from "@toolkit/ui";
import { Maybe } from "@/schema/types";
import { isEmpty } from "lodash-es";

export const getServicesInformationItems = (services?: Maybe<Array<Maybe<string>>>): InfoItems | undefined => {
  if (isEmpty(services)) {
    return undefined;
  }

  return [
    {
      value: services?.filter(str => !!str),
    },
  ];
};
