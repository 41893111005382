import { createPathDefinition } from "@toolkit/core";

export const surveysRoute = "/surveys";

export const surveysPaths = createPathDefinition(surveysRoute, {
  list: "",
  create: "new",
  update: ":surveyId",
  details: "details/:surveyId",
});
