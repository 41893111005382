import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthProgramNetworksAutocompleteQuery } from "./gql";

export type IHealthProgramNetworkAutocomplete = ExtractNodeType<HealthProgramNetworksAutocompleteQuery>;

export const healthProgramNetworkSchema = createZodAutocompleteFromType<IHealthProgramNetworkAutocomplete>({
  id: z.string(),
  name: z.string().nullish(),
});
