import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaActionsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OptimaActionFilterInput>;
}>;


export type OptimaActionsAutocompleteQuery = { __typename?: 'Query', optimaActions?: { __typename?: 'OptimaActionConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges: Array<{ __typename?: 'OptimaActionEdge', node: { __typename?: 'OptimaAction', id: string, action?: string | null } }> } | null };


export const OptimaActionsAutocompleteDocument = gql`
    query OptimaActionsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaActionFilterInput) {
  optimaActions(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        action
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useOptimaActionsAutocompleteQuery__
 *
 * To run a query within a React component, call `useOptimaActionsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaActionsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaActionsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOptimaActionsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>(OptimaActionsAutocompleteDocument, options);
      }
export function useOptimaActionsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>(OptimaActionsAutocompleteDocument, options);
        }
export function useOptimaActionsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>(OptimaActionsAutocompleteDocument, options);
        }
export type OptimaActionsAutocompleteQueryHookResult = ReturnType<typeof useOptimaActionsAutocompleteQuery>;
export type OptimaActionsAutocompleteLazyQueryHookResult = ReturnType<typeof useOptimaActionsAutocompleteLazyQuery>;
export type OptimaActionsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useOptimaActionsAutocompleteSuspenseQuery>;
export type OptimaActionsAutocompleteQueryResult = Apollo.QueryResult<OptimaActionsAutocompleteQuery, OptimaActionsAutocompleteQueryVariables>;