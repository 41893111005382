import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { VendorsAutocompleteQuery } from "./gql";

export type IVendorAutocomplete = ExtractNodeType<VendorsAutocompleteQuery>;

export const vendorSchema = createZodAutocompleteFromType<IVendorAutocomplete>({
  id: z.string(),
  name: z.string(),
  nameAr: z.string().nullish(),
});
