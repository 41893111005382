import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { DepartmentsAutocompleteQuery } from "./gql";

export type IDepartmentAutocomplete = ExtractNodeType<DepartmentsAutocompleteQuery>;

export const departmentSchema = createZodAutocompleteFromType<IDepartmentAutocomplete>({
  id: z.string(),
  name: z.string(),
});
