import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistItemListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  code?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.SubListItemSortingInput>;
}>;


export type SublistItemListQuery = { __typename?: 'Query', getSubListItems?: { __typename?: 'SubListItemConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'SubListItemEdge', node?: { __typename?: 'SubListItem', id?: string | null, code: string, display: string } | null } | null> | null } | null };


export const SublistItemListDocument = gql`
    query SublistItemList($id: ID!, $after: String, $before: String, $code: String, $first: Int, $last: Int, $sortBy: SubListItemSortingInput) {
  getSubListItems(
    subListId: $id
    first: $first
    after: $after
    before: $before
    last: $last
    code: $code
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        code
        display
      }
    }
  }
}
    `;

/**
 * __useSublistItemListQuery__
 *
 * To run a query within a React component, call `useSublistItemListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSublistItemListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSublistItemListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      code: // value for 'code'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSublistItemListQuery(baseOptions: Apollo.QueryHookOptions<SublistItemListQuery, SublistItemListQueryVariables> & ({ variables: SublistItemListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SublistItemListQuery, SublistItemListQueryVariables>(SublistItemListDocument, options);
      }
export function useSublistItemListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SublistItemListQuery, SublistItemListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SublistItemListQuery, SublistItemListQueryVariables>(SublistItemListDocument, options);
        }
export function useSublistItemListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SublistItemListQuery, SublistItemListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SublistItemListQuery, SublistItemListQueryVariables>(SublistItemListDocument, options);
        }
export type SublistItemListQueryHookResult = ReturnType<typeof useSublistItemListQuery>;
export type SublistItemListLazyQueryHookResult = ReturnType<typeof useSublistItemListLazyQuery>;
export type SublistItemListSuspenseQueryHookResult = ReturnType<typeof useSublistItemListSuspenseQuery>;
export type SublistItemListQueryResult = Apollo.QueryResult<SublistItemListQuery, SublistItemListQueryVariables>;