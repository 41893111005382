import { formGirdSpacing, hasPermission, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { surveyUpsertFormDefaultValues, surveyUpsertFormSchema, ISurveyUpsertFormValues } from "./surveyUpsertFormSchema";
import { SurveyInformationForm } from "../SurveyInformation/SurveyInformationForm";
import { ISurvey } from "../../types";
import { convertSurveyToFormValues } from "../../others";
import { SurveyQuestionsForm } from "../SurveyQuestions/SurveyQuestionsForm";
import { PermissionEnum } from "@/schema/types";

export type ISurveyUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: ISurveyUpsertFormValues;
  };
};

type SurveyUpsertFormProps = {
  survey?: ISurvey | null;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: ISurveyUpsertFormEvent) => void;
  buttonLabel: string;
};

export const SurveyUpsertForm: FC<SurveyUpsertFormProps> = props => {
  const { survey, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<ISurveyUpsertFormValues>({
    defaultValues: surveyUpsertFormDefaultValues,
    schema: surveyUpsertFormSchema,
  });

  const { handleSubmit, setValues, setFormDisabled } = form;

  const canManageSurvey = hasPermission(PermissionEnum.ManageSurveys);

  const onSubmit = (values: ISurveyUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (survey) {
      const _survey = convertSurveyToFormValues(survey);
      setValues(_survey);
    }
  }, [survey, setValues]);

  useEffect(() => {
    setFormDisabled(!canManageSurvey);
  }, [canManageSurvey, setFormDisabled]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Survey Information")} loading={isLoading} doYouHaveData>
                <SurveyInformationForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <SurveyQuestionsForm canManageSurvey={canManageSurvey} isLoadingSurvey={isLoading} />
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
