import { TFunction } from "@toolkit/i18n";

import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { CustomIcon } from "@toolkit/ui";
import { playgroundsPaths, playgroundsRoute } from "./Constants/playgroundsPaths";
import { IconExplorerContainer } from "./containers/IconExplorerContainer/IconExplorerContainer";

export const playgroundsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  const isDev = process.env.NODE_ENV === "development";
  return {
    id: "playgrounds-routes",
    text: t("Playground", { ns: "admin" }),
    route: playgroundsRoute,
    icon: <CustomIcon icon='card' />,
    hidden: !isDev,
    isProhibited: !isDev,
    subItems: [
      {
        id: "playgrounds-icons-route",
        text: t("Icons Explorer", { ns: "admin" }),
        route: playgroundsPaths.icons.route,
        fullPath: playgroundsPaths.icons.fullPath,
        element: <IconExplorerContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
