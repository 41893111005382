import { useAuth } from "react-oidc-context";

type UserInfoType = {
  app_role: string;
  app_type: string;
  aud: string;
  doctor_id?: number;
  email_verified: boolean;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  meeting_platform_id: string;
  name: string;
  permissions: Array<string>;
  preferred_username: string;
  sub: string;
  user_id: number;
  vendor_id: number;
};

export const useOidcUserProfile = () => {
  const auth = useAuth();
  const userInfo = auth.user?.profile! as unknown as UserInfoType;

  return {
    accessToken: auth?.user?.access_token,
    userInfo,
  };
};
