import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { CaseManagementPatientTeams } from "../../components/CaseManagementPatientTeams/CaseManagementPatientTeams";

type CaseManagementPatientTeamsEnrolledModalProps = {
  patientId: string;
};

export const CaseManagementPatientTeamsEnrolledModal: FC<CaseManagementPatientTeamsEnrolledModalProps> = props => {
  const { patientId } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!patientId} />} title={t("Patient Enrolled Journeys")}>
      <CaseManagementPatientTeams patientId={patientId} isEnrolled />
    </CustomDialog>
  );
};
