import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { GuidedCareProgramsAutocompleteQuery } from "./gql";

export type IGuidedCareProgramAutocomplete = ExtractNodeType<GuidedCareProgramsAutocompleteQuery>;

export const guidedCareProgramSchema = createZodAutocompleteFromType<IGuidedCareProgramAutocomplete>({
  id: z.string(),
  name: z.string().nullish(),
});
