import { CircularProgress, FormNumberField, Grid } from "@toolkit/ui";
import { SubscriptionPlansAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { useCustomFormContext } from "@toolkit/core";
import { IProviderUpsertForm } from "../ProviderUpsert/ProviderUpsertFormSchema";

type ProviderSubscriptionFormProps = {
  isLoading?: boolean;
};

export const ProviderSubscriptionForm: FC<ProviderSubscriptionFormProps> = props => {
  const { isLoading } = props;

  const { t } = useTranslation("admin");

  const form = useCustomFormContext<IProviderUpsertForm>();

  const { watch } = form;

  const fixedCostAmount = watch("subscriptionFixedCostAmount");
  const fixedOrderAmount = watch("subscriptionFixedOrderAmount");
  const fixedOrderPercentage = watch("subscriptionFixedOrderPercentage");

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SubscriptionPlansAutocomplete name={"subscriptionPlan"} filter={{ isActive: true }} />
      </Grid>

      {isLoading ? (
        <Grid item xs={3}>
          <CircularProgress size={30} />
        </Grid>
      ) : (
        <>
          {!!fixedCostAmount && (
            <Grid item xs={3}>
              <FormNumberField name={"subscriptionFixedCostAmount"} label={t("Fixed Cost Amount")} />
            </Grid>
          )}

          {!!fixedOrderAmount && (
            <Grid item xs={3}>
              <FormNumberField name={"subscriptionFixedOrderAmount"} label={t("Fixed Order Amount")} />
            </Grid>
          )}

          {!!fixedOrderPercentage && (
            <Grid item xs={3}>
              <FormNumberField name={"subscriptionFixedOrderPercentage"} label={t("Fixed Order Percentage")} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
