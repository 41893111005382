/* eslint-disable max-lines */
import { AppointmentSortingField } from "@/schema/types";
import { PatientContactInformation } from "@/shared/components/PatientContactInformation/PatientContactInformation";
import { getInsuranceInformationItems } from "@/shared/utils/CellInfo.utils";
import {
  getBranchesAutocompleteFilter,
  getDepartmentsAutocompleteFilter,
  getDoctorsAutocompleteFilter,
  getPatientsAutocompleteFilter,
  getVendorsAutocompleteFilter,
} from "@health/autocompletes";
import {
  appointmentStatusOptionsMap,
  appointmentTypeOptionsMap,
  getAutocompleteEnumFilter,
  paymentStatusOptionsMap,
  requestedByOptionsMap,
} from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { AppointmentCancel } from "../../components/AppointmentCancel/AppointmentCancel";
import { IAppointmentListNode } from "../../types";

export const useAppointmentListContainerColumns = (): CustomTableColumnProps<IAppointmentListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
      },
      {
        key: "ids",
        header: t("Number"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "ids",
        },
      },
      {
        key: "patient",
        header: t("Patient"),
        type: "truncated-text",
        accessor: ({ consumer }) => `${consumer?.firstName} ${consumer?.lastName}`,
        filter: getPatientsAutocompleteFilter({ name: "patients", multiple: true }),
      },
      {
        key: "contactInfo",
        header: t("Contact Info"),
        accessor: ({ consumer }) => <PatientContactInformation contactNumber={consumer?.contactNumber!} email={consumer?.email!} />,
      },
      {
        key: "appointmentTime",
        header: t("Appointment Time"),
        type: "date",
        accessor: "confirmedStartTime",
        sort: {
          columnEnum: AppointmentSortingField.ConfirmedStartDate,
        },
      },
      {
        key: "doctor",
        header: t("Doctor"),
        type: "truncated-text",
        accessor: "doctor.user.fullName",
        filter: getDoctorsAutocompleteFilter({ name: "doctors", multiple: true }),
      },
      {
        key: "provider",
        header: t("Provider"),
        type: "truncated-text",
        accessor: ({ branch }) => pickLocalizedValue(branch?.vendor?.name, branch?.vendor?.nameAr),
        filter: getVendorsAutocompleteFilter({ name: "vendors", multiple: true }),
      },
      {
        key: "branch",
        header: t("Branch"),
        type: "truncated-text",
        accessor: ({ branch }) => pickLocalizedValue(branch?.name, branch?.nameAr),
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => appointmentTypeOptionsMap[type!]?.label,
        filter: getAutocompleteEnumFilter("AppointmentType", "type", { multiple: true }),
      },
      {
        key: "status",
        header: t("Status"),
        type: "truncated-text",
        accessor: ({ status }) => appointmentStatusOptionsMap[status!]?.label,
        filter: getAutocompleteEnumFilter("AppointmentStatus", "status"),
      },
      {
        key: "payment",
        header: t("Payment"),
        accessor: ({ paymentStatus }) => paymentStatusOptionsMap[paymentStatus!]?.label,
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ requestedBy }) => requestedByOptionsMap[requestedBy!]?.label,
        filter: getAutocompleteEnumFilter("RequestedBy", "requestedBy"),
      },
      {
        key: "insurance",
        header: t("Insurance"),
        type: "info",
        infoCellOptions: {
          title: t("Insurance information"),
          items: ({ consumer }) => getInsuranceInformationItems(consumer?.activeHealthProgramMembers?.[0]),
          emptyMessage: t("There is No Insurance Information"),
        },
      },
      {
        key: "cancellationReason",
        header: t("Cancellation Reason"),
        type: "truncated-text",
        accessor: "cancellationReason",
      },
      {
        key: "cancel",
        header: t("Cancel"),
        accessor: ({ id, status }) => <AppointmentCancel id={id} status={status} />,
      },
      {
        key: "requestedStartTimeGte",
        header: t("Requested Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedStartTime.gte",
          label: t("Requested Start Time From"),
        },
      },
      {
        key: "requestedStartTimeLte",
        header: t("Requested Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedStartTime.lte",
          label: t("Requested Start Time To"),
        },
      },
      {
        key: "requestedEndTimeGte",
        header: t("Requested End Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedEndTime.gte",
          label: t("Requested End Time From"),
        },
      },
      {
        key: "requestedEndTimeLte",
        header: t("Requested End Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "requestedEndTime.lte",
          label: t("Requested End Time To"),
        },
      },
      {
        key: "confirmedStartTimeGte",
        header: t("Confirmed Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedStartTime.gte",
          label: t("Confirmed Start Time From"),
        },
      },
      {
        key: "confirmedStartTimeLte",
        header: t("Confirmed Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedStartTime.lte",
          label: t("Confirmed Start Time To"),
        },
      },
      {
        key: "confirmedEndTimeGte",
        header: t("Confirmed End Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedEndTime.gte",
          label: t("Confirm End Time From"),
        },
      },
      {
        key: "confirmedEndTimeLte",
        header: t("Confirmed End Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "confirmedEndTime.lte",
          label: t("Confirmed End Time To"),
        },
      },
      {
        key: "departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
    ],
    [t]
  );
};
