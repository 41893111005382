import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { GuidedCareTemplatesAutocompleteQuery } from "./gql";

export type IGuidedCareTemplateAutocomplete = ExtractNodeType<GuidedCareTemplatesAutocompleteQuery>;

export const guidedCareTemplateSchema = createZodAutocompleteFromType<IGuidedCareTemplateAutocomplete>({
  id: z.string(),
  code: z.string().nullish(),
  name: z.string().nullish(),
});
