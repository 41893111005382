import { ActionFieldType } from "@/schema/types";
import { createZodAutocomplete } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const decisionEffectsFormSchema = z
  .object({
    code: z.string(),
    display: z.string().nullish(),
    type: z.nativeEnum(ActionFieldType),
    isRequired: z.boolean(),
    allowedValues: z.any().optional(),
    valueBoolean: z.boolean().nullish(),
    valueNumber: z.number().nullish(),
    valueString: z
      .string()
      .or(createZodAutocomplete())
      .or(
        createZodAutocomplete(
          z.object({
            id: z.string(),
            name: z.string(),
            nameAr: z.string().nullish(),
          })
        )
      )
      .nullish(),
    valueList: createZodAutocomplete(
      z.object({
        id: z.string(),
        name: z.string(),
        nameAr: z.string().nullish(),
      })
    ).nullish(),
  })
  .superRefine((data, ctx) => {
    if (data.type === ActionFieldType.String && data.isRequired && !data.valueString) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "domains" }),
        path: ["valueString"],
      });
    }

    if (data.type === ActionFieldType.Number && data.isRequired && (data.valueNumber === undefined || data.valueNumber === null)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "domains" }),
        path: ["valueNumber"],
      });
    }

    if (data.type === ActionFieldType.List && data.isRequired && !data.valueList) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "domains" }),
        path: ["valueList"],
      });
    }
  });

export type IDecisionEffectsFormValues = z.infer<typeof decisionEffectsFormSchema>;
