import { useOpenState } from "@toolkit/core";
import { AuditHistoryDrawer, Box, CircularProgress, Typography } from "@toolkit/ui";
import { FC, useMemo } from "react";
import { OrderEventsTimeline } from "./OrderEventsTimeline";
import { useTranslation } from "@toolkit/i18n";
import { useGetOrderTrackingLinesQuery } from "@/pages/OrdersTracking/gql";

export const OrderEventsDrawer: FC<{ orderId: string }> = ({ orderId }) => {
  const { handleClose, handleOpen, open } = useOpenState();
  const { t } = useTranslation();

  const { data, loading } = useGetOrderTrackingLinesQuery({
    variables: {
      orderId,
    },
    skip: !open,
  });

  const orderLines = data?.marketplaceOrder?.orderEvents;
  const events = useMemo(
    () =>
      orderLines?.map(event => ({
        date: event?.date,
        id: event?.id,
        status: event?.status,
        firstName: event?.user?.firstName!,
        lastName: event?.user?.lastName!,
        defaultBranchName: event?.user?.defaultBranch?.name!,
      })),
    [orderLines]
  );
  return (
    <AuditHistoryDrawer open={open} onClickOpen={handleOpen} onClickClose={handleClose}>
      <Box padding='14px' height='100vh' overflow='auto' id='scrollableDiv'>
        <Typography fontWeight='bold' fontSize={20} gutterBottom>
          {t("Order ID")}: {orderId || ""}
        </Typography>
        {loading ? (
          <Box width='100%' display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          <OrderEventsTimeline events={events || []} />
        )}
      </Box>
    </AuditHistoryDrawer>
  );
};
