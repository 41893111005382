import { IAutocompleteBasicProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { useOptimaPayersAutocompleteQuery } from "./gql";

type OptimaPayersAutocompleteProps = IAutocompleteBasicProps;

export const OptimaPayersAutocomplete: FC<OptimaPayersAutocompleteProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useOptimaPayersAutocompleteQuery,
    searchKey: "nameContains",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => item?.name);

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Optima Payers") : t("Payer"))}
    />
  );
};
