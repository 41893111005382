import { useNavigate, useParams } from "react-router-dom";
import { BranchListContainer, IBranchListContainerEvent } from "@health/domains";
import { useSetProvidersBreadcrumbs } from "../../hooks";
import { providersPaths } from "../../constants";
import { IProviderBranchListContainerParams } from "../../types";
import { useProviderBranchesInfoGetQuery } from "../../gql";

export const ProviderBranchListContainer = () => {
  const navigate = useNavigate();

  const { providerId } = useParams<IProviderBranchListContainerParams>();

  const onBranchListContainerChange = (event: IBranchListContainerEvent) => {
    if (event.type === "ADD_ITEM_CLICK") {
      navigate(providersPaths.branchCreate.fullPathWithParams({ providerId: providerId! }));
    } else if (event.type === "EDIT_ROW_CLICK") {
      navigate(providersPaths.branchUpdate.fullPathWithParams({ providerId: providerId!, branchId: event.payload?.item?.id! }));
    }
  };

  useSetProvidersBreadcrumbs("BRANCH_LIST", { providerId });

  const { data } = useProviderBranchesInfoGetQuery({
    variables: { vendorId: providerId! },
    skip: !providerId,
  });

  return (
    <BranchListContainer
      vendorHasMultipleBranches={!!data?.vendor?.hasMultipleBranches}
      filter={{ vendors: [providerId!] }}
      onChange={onBranchListContainerChange}
    />
  );
};
