import { i18n } from "@toolkit/i18n";
import { EncounterType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const optimaEncounterTypeOptionsMap: IEnumToOptionsMap<EncounterType> = {
  [EncounterType.AmbulanceAirOrWater]: {
    key: EncounterType.AmbulanceAirOrWater,
    get label() {
      return i18n.t("Ambulance Air Or Water", { ns: "domains" });
    },
    value: EncounterType.AmbulanceAirOrWater,
  },
  [EncounterType.AmbulanceLand]: {
    key: EncounterType.AmbulanceLand,
    get label() {
      return i18n.t("Ambulance Land", { ns: "domains" });
    },
    value: EncounterType.AmbulanceLand,
  },
  [EncounterType.AssistedLivingFacility]: {
    key: EncounterType.AssistedLivingFacility,
    get label() {
      return i18n.t("Assisted Living Facility", { ns: "domains" });
    },
    value: EncounterType.AssistedLivingFacility,
  },
  [EncounterType.DaycaseBedEr]: {
    key: EncounterType.DaycaseBedEr,
    get label() {
      return i18n.t("Daycase Bed ER", { ns: "domains" });
    },
    value: EncounterType.DaycaseBedEr,
  },
  [EncounterType.DaycaseBedNoEr]: {
    key: EncounterType.DaycaseBedNoEr,
    get label() {
      return i18n.t("Daycase Bed No ER", { ns: "domains" });
    },
    value: EncounterType.DaycaseBedNoEr,
  },
  [EncounterType.Home]: {
    key: EncounterType.Home,
    get label() {
      return i18n.t("Home", { ns: "domains" });
    },
    value: EncounterType.Home,
  },
  [EncounterType.InpatientBedEr]: {
    key: EncounterType.InpatientBedEr,
    get label() {
      return i18n.t("Inpatient Bed ER", { ns: "domains" });
    },
    value: EncounterType.InpatientBedEr,
  },
  [EncounterType.InpatientBedNoEr]: {
    key: EncounterType.InpatientBedNoEr,
    get label() {
      return i18n.t("Inpatient Bed No ER", { ns: "domains" });
    },
    value: EncounterType.InpatientBedNoEr,
  },
  [EncounterType.MobileUnit]: {
    key: EncounterType.MobileUnit,
    get label() {
      return i18n.t("Mobile Unit", { ns: "domains" });
    },
    value: EncounterType.MobileUnit,
  },
  [EncounterType.NationalsScreening]: {
    key: EncounterType.NationalsScreening,
    get label() {
      return i18n.t("Nationals Screening", { ns: "domains" });
    },
    value: EncounterType.NationalsScreening,
  },
  [EncounterType.NewVisaScreening]: {
    key: EncounterType.NewVisaScreening,
    get label() {
      return i18n.t("New Visa Screening", { ns: "domains" });
    },
    value: EncounterType.NewVisaScreening,
  },
  [EncounterType.NoBedEr]: {
    key: EncounterType.NoBedEr,
    get label() {
      return i18n.t("No Bed ER", { ns: "domains" });
    },
    value: EncounterType.NoBedEr,
  },
  [EncounterType.NoBedNoEr]: {
    key: EncounterType.NoBedNoEr,
    get label() {
      return i18n.t("No Bed No ER", { ns: "domains" });
    },
    value: EncounterType.NoBedNoEr,
  },
  [EncounterType.RenewalVisaScreening]: {
    key: EncounterType.RenewalVisaScreening,
    get label() {
      return i18n.t("Renewal Visa Screening", { ns: "domains" });
    },
    value: EncounterType.RenewalVisaScreening,
  },
  [EncounterType.Telehealth]: {
    key: EncounterType.Telehealth,
    get label() {
      return i18n.t("Telehealth", { ns: "domains" });
    },
    value: EncounterType.Telehealth,
  },
};

export const optimaEncounterTypeOptions = Object.values(optimaEncounterTypeOptionsMap);
