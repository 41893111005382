import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPermissionGroupCreateMutationVariables = Types.Exact<{
  input: Types.PermissionGroupCreateInput;
}>;


export type UserPermissionGroupCreateMutation = { __typename?: 'Mutation', permissionGroupCreate?: { __typename?: 'PermissionGroupCreate', permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', field?: string | null, message?: string | null }> } | null };


export const UserPermissionGroupCreateDocument = gql`
    mutation UserPermissionGroupCreate($input: PermissionGroupCreateInput!) {
  permissionGroupCreate(input: $input) {
    permissionGroupErrors {
      field
      message
    }
  }
}
    `;
export type UserPermissionGroupCreateMutationFn = Apollo.MutationFunction<UserPermissionGroupCreateMutation, UserPermissionGroupCreateMutationVariables>;

/**
 * __useUserPermissionGroupCreateMutation__
 *
 * To run a mutation, you first call `useUserPermissionGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userPermissionGroupCreateMutation, { data, loading, error }] = useUserPermissionGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserPermissionGroupCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserPermissionGroupCreateMutation, UserPermissionGroupCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserPermissionGroupCreateMutation, UserPermissionGroupCreateMutationVariables>(UserPermissionGroupCreateDocument, options);
      }
export type UserPermissionGroupCreateMutationHookResult = ReturnType<typeof useUserPermissionGroupCreateMutation>;
export type UserPermissionGroupCreateMutationResult = Apollo.MutationResult<UserPermissionGroupCreateMutation>;
export type UserPermissionGroupCreateMutationOptions = Apollo.BaseMutationOptions<UserPermissionGroupCreateMutation, UserPermissionGroupCreateMutationVariables>;