import { makeStyles } from "@toolkit/ui";

export const usePatientProfileDrawerStyle = makeStyles()(theme => ({
  button: {
    paddingX: "1px",
    margin: 0,
    height: "8px",
    borderRadius: 0,
    width: "min-content",
  },
  buttonTruncate: {
    whiteSpace: "nowrap",
    marginBlockStart: 0,
    textDecoration: "underline",
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.secondary.main,
  },
  iFrame: {
    display: "flex",
    flex: 1,
    border: "none",
  },
}));
