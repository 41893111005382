import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPaymentInvoicesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.PaymentInvoiceFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetPaymentInvoicesQuery = { __typename?: 'Query', paymentInvoices?: { __typename?: 'PaymentInvoiceCountableConnection', pageInfo: { __typename?: 'H_PageInfo', startCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PaymentInvoiceCountableEdge', node: { __typename?: 'PaymentInvoice', id: string, visitId?: string | null, vendorId?: string | null, priceAfterVat?: number | null, priceBeforeVat?: number | null, discount?: number | null, merchantTransactionId?: string | null, paymentType?: string | null, vat?: number | null, paymentSource?: Types.PaymentSource | null, marketplaceOrderId?: string | null, currency?: string | null, createdDate?: any | null, createdBy?: string | null } }> } | null };


export const GetPaymentInvoicesDocument = gql`
    query GetPaymentInvoices($after: String, $before: String, $filter: PaymentInvoiceFilterInput, $first: Int, $last: Int) {
  paymentInvoices(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        visitId
        vendorId
        priceAfterVat
        priceBeforeVat
        discount
        merchantTransactionId
        paymentType
        vat
        paymentSource
        marketplaceOrderId
        currency
        createdDate
        createdBy
      }
    }
  }
}
    `;

/**
 * __useGetPaymentInvoicesQuery__
 *
 * To run a query within a React component, call `useGetPaymentInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentInvoicesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetPaymentInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>(GetPaymentInvoicesDocument, options);
      }
export function useGetPaymentInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>(GetPaymentInvoicesDocument, options);
        }
export function useGetPaymentInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>(GetPaymentInvoicesDocument, options);
        }
export type GetPaymentInvoicesQueryHookResult = ReturnType<typeof useGetPaymentInvoicesQuery>;
export type GetPaymentInvoicesLazyQueryHookResult = ReturnType<typeof useGetPaymentInvoicesLazyQuery>;
export type GetPaymentInvoicesSuspenseQueryHookResult = ReturnType<typeof useGetPaymentInvoicesSuspenseQuery>;
export type GetPaymentInvoicesQueryResult = Apollo.QueryResult<GetPaymentInvoicesQuery, GetPaymentInvoicesQueryVariables>;