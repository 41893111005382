import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { UsersGroupIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { usersAdminsRoutes } from "../UsersAdmin/constants/UsersAdminsRoutes";
import { usersProvidersRoutes } from "@/pages/Users/UsersProviders/constants/UsersProvidersRoutes";
import { usersPermissionsGroupsRoutes } from "../UsersPermissionsGroups/constants/UsersPermissionsGroupsRoutes";

export const usersRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  const hasViewUsersPermission = hasPermission(PermissionEnum.ViewUsers);
  const hasManageUsersPermission = hasPermission(PermissionEnum.ManageUsers);
  const hasManagePermissionGroupPermission = hasPermission(PermissionEnum.ManagePermissionGroup);

  return {
    id: "users-routes",
    text: i18n.t("Users Management", { ns: "admin" }),
    icon: <UsersGroupIcon />,
    isProhibited: !hasViewUsersPermission && !hasManageUsersPermission && !hasManagePermissionGroupPermission,
    subItems: [usersAdminsRoutes(navigate), usersProvidersRoutes(navigate), usersPermissionsGroupsRoutes(navigate)],
  };
};
