import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BenefitMapUpdateMutationVariables = Types.Exact<{
  updateBenefitMapId: Types.Scalars['ID']['input'];
  input: Types.BenefitMapInput;
}>;


export type BenefitMapUpdateMutation = { __typename?: 'Mutation', updateBenefitMap?: { __typename?: 'BenefitMap', benefitCode: string, itemCode: string, id: string } | null };


export const BenefitMapUpdateDocument = gql`
    mutation BenefitMapUpdate($updateBenefitMapId: ID!, $input: BenefitMapInput!) {
  updateBenefitMap(id: $updateBenefitMapId, input: $input) {
    benefitCode
    itemCode
    id
  }
}
    `;
export type BenefitMapUpdateMutationFn = Apollo.MutationFunction<BenefitMapUpdateMutation, BenefitMapUpdateMutationVariables>;

/**
 * __useBenefitMapUpdateMutation__
 *
 * To run a mutation, you first call `useBenefitMapUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenefitMapUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benefitMapUpdateMutation, { data, loading, error }] = useBenefitMapUpdateMutation({
 *   variables: {
 *      updateBenefitMapId: // value for 'updateBenefitMapId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenefitMapUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BenefitMapUpdateMutation, BenefitMapUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BenefitMapUpdateMutation, BenefitMapUpdateMutationVariables>(BenefitMapUpdateDocument, options);
      }
export type BenefitMapUpdateMutationHookResult = ReturnType<typeof useBenefitMapUpdateMutation>;
export type BenefitMapUpdateMutationResult = Apollo.MutationResult<BenefitMapUpdateMutation>;
export type BenefitMapUpdateMutationOptions = Apollo.BaseMutationOptions<BenefitMapUpdateMutation, BenefitMapUpdateMutationVariables>;