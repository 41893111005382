import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { SurveyIcon } from "@toolkit/ui";
import { surveysPaths, surveysRoute } from "pages/Surveys/constants";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { SurveysListContainer, SurveyCreateContainer } from "../containers";
import { SurveyUpdateContainer } from "../containers/SurveyUpdate/SurveyUpdateContainer";
import { SurveyStatisticsContainer } from "../containers/SurveyStatistics/SurveyStatisticsContainer";

export const surveysRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "surveys-routes",
    text: t("Surveys", { ns: "admin" }),
    icon: <SurveyIcon />,
    route: surveysRoute,
    isProhibited: !hasPermission(PermissionEnum.ViewSurveys),
    subItems: [
      {
        id: "surveys-list-route",
        text: t("Surveys", { ns: "admin" }),
        route: surveysPaths.list.route,
        fullPath: surveysPaths.list.fullPath,
        element: <SurveysListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "surveys-create-route",
        text: t("Create Survey", { ns: "admin" }),
        route: surveysPaths.create.route,
        fullPath: surveysPaths.create.fullPath,
        element: <SurveyCreateContainer />,
        hidden: true,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "surveys-update-route",
        text: t("Update Survey", { ns: "admin" }),
        route: surveysPaths.update.route,
        fullPath: surveysPaths.update.fullPath,
        element: <SurveyUpdateContainer />,
        hidden: true,
      },
      {
        id: "surveys-statistics-route",
        text: t("Survey Statistics", { ns: "admin" }),
        route: surveysPaths.details.route,
        fullPath: surveysPaths.details.fullPath,
        element: <SurveyStatisticsContainer />,
        hidden: true,
      },
    ],
  };
};
