import { Container, FormAutocomplete, Grid } from "@toolkit/ui";
import { conditionOperationOptions } from "@health/enum-options";
import { useDecisionConditionInnerOperationStyle } from "./useDecisionConditionInnerOperationStyle";
import { FC } from "react";

type DecisionConditionInnerOperationProps = {
  conditionGroupPath: string;
};

export const DecisionConditionInnerOperation: FC<DecisionConditionInnerOperationProps> = props => {
  const { conditionGroupPath } = props;

  const { classes } = useDecisionConditionInnerOperationStyle();

  return (
    <Grid item xs={1} className={classes.root}>
      <Container className={classes.container}>
        <FormAutocomplete
          name={`${conditionGroupPath}.innerConditionOperation`}
          options={conditionOperationOptions}
          disableClearable
          freeSolo
          forcePopupIcon
          className={classes.innerConditionOperation}
        />
      </Container>
    </Grid>
  );
};
