import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const addressUpsertFormSchema = z.object({
  streetAddress1: z.string().min(3).max(255),
  streetAddress1Ar: z.string().max(255).nullish(),
  streetAddress2: z.string().max(255).nullish(),
  streetAddress2Ar: z.string().max(255).nullish(),
  area: z.string().max(255),
  areaAr: z.string().max(255).nullish(),
  city: zodSchema.city,
  postalCode: z.string().nullish(),
  coordinates: z.object({
    lat: z.coerce.number(),
    lng: z.coerce.number(),
  }),
});

export type IAddressUpsertForm = z.infer<typeof addressUpsertFormSchema>;

export const addressUpsertFormDefaultValues: Partial<IAddressUpsertForm> = {
  streetAddress1: undefined,
  streetAddress1Ar: undefined,
  streetAddress2: undefined,
  streetAddress2Ar: undefined,
  area: undefined,
  areaAr: undefined,
  city: undefined,
  postalCode: undefined,
  coordinates: {
    lat: 24.750643,
    lng: 46.707766,
  },
};
