import { AppTypes, Group, Maybe, Permission, PermissionGroupCreateInput, PermissionGroupUpdateInput } from "@/schema/types";
import { IUserPermissionGroupUpsertFormValues } from "../forms/UserPermissionGroupUpsert/UserPermissionGroupUpsertFormSchema";
import { createPermissionAutocompleteOption } from "@health/autocompletes";

export const convertUserPermissionGroupToFormValues = (permissionGroup: Group): IUserPermissionGroupUpsertFormValues => {
  return {
    name: permissionGroup?.name,
    permissions: permissionGroup?.permissions ? permissionGroup?.permissions?.map(item => createPermissionAutocompleteOption(item!)) : [],
  };
};

export const convertUserPermissionGroupCreateFormValuesToBackEndValues = (
  values: IUserPermissionGroupUpsertFormValues
): PermissionGroupCreateInput => {
  return {
    name: values?.name,
    groupConfiguration: {
      groupType: AppTypes.Admin,
    },
    addPermissions: values?.permissions?.length ? values?.permissions?.map(item => item?.value?.code!) : [],
  };
};

export const convertUserPermissionGroupUpdateFormValuesToBackEndValues = (
  values: IUserPermissionGroupUpsertFormValues,
  oldPermission?: Maybe<Maybe<Permission>[]>
): PermissionGroupUpdateInput => {
  const formPermissions = values?.permissions?.map(item => item?.value?.code!);
  const userPermissions = oldPermission?.map(item => item?.code!) || [];
  const removedPermissions = userPermissions?.filter(item => !formPermissions?.includes(item));

  return {
    addPermissions: formPermissions?.length ? formPermissions : [],
    removePermissions: removedPermissions?.length ? removedPermissions : [],
    groupConfiguration: {}, // todo
  };
};
