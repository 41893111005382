import { OptionsStatistics } from "@/schema/types";
import { i18n, isRTL } from "@toolkit/i18n";
import { PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import moment from "moment";

export const formatDate = (updateDate?: null | string) =>
  updateDate ? moment(updateDate).locale(i18n.language).format("DD MMMM YYYY") : "";

// accessor functions
export const getOptionPercentage = (d: OptionsStatistics) => d.percentage ?? 0;

export const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

export const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

export const isPisitive = x => x > 0;

interface getTextAnchorReturnType {
  textAnchor: "end" | "start" | "inherit" | "middle";
  dx?: number;
  dy?: number;
}

// eslint-disable-next-line max-statements
export const getTextAnchor = (centroidX: number, centroidY: number, data: any[]): getTextAnchorReturnType => {
  const isRtl = isRTL();

  const isFirstQ = !isPisitive(centroidX) && !isPisitive(centroidY);
  const isSecondQ = isPisitive(centroidX) && !isPisitive(centroidY);
  const isThirdQ = isPisitive(centroidX) && isPisitive(centroidY);
  const isFourthQ = !isPisitive(centroidX) && isPisitive(centroidY);
  if (data.length <= 1) {
    return { textAnchor: "middle", dy: 18 };
  }
  const dy = isFirstQ || isSecondQ ? -18 : 18;
  const dx = isFirstQ || isFourthQ ? -18 : 18;
  let textAnchor: any = isSecondQ || isThirdQ ? "start" : "end";
  if (isRtl) {
    textAnchor = isFirstQ || isFourthQ ? "start" : "end";
  }

  return { textAnchor, dx, dy };
};

export const getVerticalAnchor = (centroidX: number, centroidY: number, data: any[]): "end" | "start" | "middle" => {
  const isFirstQ = !isPisitive(centroidX) && !isPisitive(centroidY);
  const isSecondQ = isPisitive(centroidX) && !isPisitive(centroidY);
  return (isFirstQ || isSecondQ) && data.length > 1 ? "end" : "start";
};

export const createTempText = (svg: SVGSVGElement, textContent: string): number => {
  const textElement = document.createElementNS("http://www.w3.org/2000/svg", "text");
  textElement.setAttribute("font-size", "12px");
  textElement.textContent = textContent;
  svg?.appendChild(textElement);
  const length = textElement.getComputedTextLength();
  svg?.removeChild(textElement);
  return length;
};

export const truncateText = (svg: SVGSVGElement, text: string, maxWidth: number): string => {
  let truncatedText = text;
  let textLength = createTempText(svg, truncatedText);
  while (textLength > maxWidth && truncatedText.length > 0) {
    truncatedText = truncatedText.slice(0, -1);
    textLength = createTempText(svg, truncatedText + "...");
  }
  return truncatedText.length < text.length ? truncatedText + "..." : truncatedText;
};

export const PIE_CHART_WIDTH = 320;
