export const toBase64 = (data: string): string => {
  try {
    return btoa(data);
  } catch (error) {
    return data;
  }
};

export const fromBase64 = (base64Txt: string): string => {
  try {
    return atob(base64Txt);
  } catch (error) {
    return base64Txt;
  }
};

export const extractIdFromBase64 = (base64Txt: string) => {
  if (!base64Txt) return;

  const decoded = atob(base64Txt);
  const match = decoded?.match(/\d+/);

  return match ? parseInt(match?.[0], 10) : undefined;
};

export const encodeEntityIdToBase64 = (entityName: string, id: string) => {
  return btoa(`${entityName}:${id}`);
};
