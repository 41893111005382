import { useOpenState } from "@toolkit/core";
import {
  CircularProgress,
  CustomDialog,
  CustomIcon,
  Divider,
  Grid,
  MenuItem,
  StyledButton,
  Typography,
  useGridContext,
  useTheme,
} from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";
import { useFetchToSubListDialog } from "./FetchToSublistDialog/useFetchToSubList";
import { SubListsAutocomplete } from "./SubListsAutocomplete";

export const FetchToSubListButton: FC<{ isDisabled: boolean; onClick: () => void }> = ({ isDisabled, onClick }) => {
  const { activeFiltersProps } = useGridContext();
  const { getSearchParameters } = useAdminManagedListDetailsPageContext();
  const searchParameters = getSearchParameters?.(activeFiltersProps?.activeFilters)!;
  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const handleClick = () => {
    onClick();
    handleOpen();
  };
  const { methods, isSubmitting, t, getValue, handleOnFetch } = useFetchToSubListDialog({ searchParameters });
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      onClose={handleClose}
      open={isOpen}
      button={
        <MenuItem disabled={isDisabled} onClick={handleClick}>
          <CustomIcon icon='fetchToSubList' />
          <Typography marginLeft='2px'> {t("Fetch To SubList")}</Typography>
        </MenuItem>
      }
      DialogTitleProps={{
        title: t("Import current list to Sublist"),
      }}
      DialogActionsProps={{
        children: (
          <StyledButton color='success' disabled={isSubmitting} size='medium' type={"submit"} onClick={methods.handleSubmit(handleOnFetch)}>
            {isSubmitting && <CircularProgress size={20} sx={{ mx: 1 }} />} {isSubmitting ? t("Fetching") : t("Fetch")}
          </StyledButton>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleOnFetch)}>
          <Grid container spacing={4} xs={12} lg={12} flexDirection='column' justifyContent={"stretch"}>
            <SubListsAutocomplete isOpen={isOpen as boolean} />
            {searchParameters?.length > 0 && (
              <>
                <Divider sx={{ mt: 2 }} />
                <Grid item>
                  <Typography variant='subtitle1'>{t("Applied Filters")}</Typography>
                </Grid>
                <Grid item container spacing={2} xs={12} lg={12}>
                  <Grid container spacing={2} xs={12} lg={12} sx={{ px: 2 }}>
                    {searchParameters?.map(param => (
                      <Grid key={param.code} item xs={6} sm={6} md={4}>
                        <Typography fontSize={theme.mixins.fonts.fontSize.sm}>
                          <b>{param.code}: </b>
                          {getValue(param)}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
