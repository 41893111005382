import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.BlockInput;
}>;


export type BlockUpdateMutation = { __typename?: 'Mutation', blockUpdate?: { __typename?: 'BlockUpdate', block?: { __typename?: 'Block', id: string } | null, blockErrors: Array<{ __typename?: 'BlockError', message?: string | null, field?: string | null, code: Types.BlockErrorCode }> } | null };


export const BlockUpdateDocument = gql`
    mutation BlockUpdate($id: ID!, $input: BlockInput!) {
  blockUpdate(id: $id, input: $input) {
    block {
      id
    }
    blockErrors {
      message
      field
      code
    }
  }
}
    `;
export type BlockUpdateMutationFn = Apollo.MutationFunction<BlockUpdateMutation, BlockUpdateMutationVariables>;

/**
 * __useBlockUpdateMutation__
 *
 * To run a mutation, you first call `useBlockUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUpdateMutation, { data, loading, error }] = useBlockUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BlockUpdateMutation, BlockUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUpdateMutation, BlockUpdateMutationVariables>(BlockUpdateDocument, options);
      }
export type BlockUpdateMutationHookResult = ReturnType<typeof useBlockUpdateMutation>;
export type BlockUpdateMutationResult = Apollo.MutationResult<BlockUpdateMutation>;
export type BlockUpdateMutationOptions = Apollo.BaseMutationOptions<BlockUpdateMutation, BlockUpdateMutationVariables>;