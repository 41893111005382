import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../Addresses/gql/fragments/__generated__/Address';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BranchGetQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, type?: Types.BranchType | null, contactEmail?: string | null, contactNumber?: string | null, healthLicense?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, description: string, isActive: boolean, acceptsDelivery: boolean, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null, address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, area: string, areaAr: string, postalCode: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null } | null };


export const BranchGetDocument = gql`
    query BranchGet($id: ID!) {
  branch(id: $id) {
    id
    name
    nameAr
    type
    contactEmail
    contactNumber
    healthLicense
    healthLicenseStartDate
    healthLicenseEndDate
    description
    isActive
    acceptsDelivery
    workingHours {
      day
      openTimeRanges {
        openTime
        closeTime
      }
    }
    address {
      ...AddressFragment
    }
  }
}
    ${AddressFragmentFragmentDoc}`;

/**
 * __useBranchGetQuery__
 *
 * To run a query within a React component, call `useBranchGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchGetQuery(baseOptions: Apollo.QueryHookOptions<BranchGetQuery, BranchGetQueryVariables> & ({ variables: BranchGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchGetQuery, BranchGetQueryVariables>(BranchGetDocument, options);
      }
export function useBranchGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchGetQuery, BranchGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchGetQuery, BranchGetQueryVariables>(BranchGetDocument, options);
        }
export function useBranchGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchGetQuery, BranchGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchGetQuery, BranchGetQueryVariables>(BranchGetDocument, options);
        }
export type BranchGetQueryHookResult = ReturnType<typeof useBranchGetQuery>;
export type BranchGetLazyQueryHookResult = ReturnType<typeof useBranchGetLazyQuery>;
export type BranchGetSuspenseQueryHookResult = ReturnType<typeof useBranchGetSuspenseQuery>;
export type BranchGetQueryResult = Apollo.QueryResult<BranchGetQuery, BranchGetQueryVariables>;