import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryTimeSlotsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type DeliveryTimeSlotsQuery = { __typename?: 'Query', deliveryTimeSlots?: { __typename?: 'DeliveryTimeSlotCountableConnection', edges: Array<{ __typename?: 'DeliveryTimeSlotCountableEdge', node: { __typename?: 'DeliveryTimeSlot', endTime: string, startTime: string, isActive: boolean, id: string, deliveryType: Types.DeliveryTypes } }> } | null };


export const DeliveryTimeSlotsDocument = gql`
    query DeliveryTimeSlots($first: Int, $after: String, $before: String, $last: Int) {
  deliveryTimeSlots(first: $first, after: $after, before: $before, last: $last) {
    edges {
      node {
        endTime
        startTime
        isActive
        id
        deliveryType
      }
    }
  }
}
    `;

/**
 * __useDeliveryTimeSlotsQuery__
 *
 * To run a query within a React component, call `useDeliveryTimeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryTimeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryTimeSlotsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useDeliveryTimeSlotsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>(DeliveryTimeSlotsDocument, options);
      }
export function useDeliveryTimeSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>(DeliveryTimeSlotsDocument, options);
        }
export function useDeliveryTimeSlotsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>(DeliveryTimeSlotsDocument, options);
        }
export type DeliveryTimeSlotsQueryHookResult = ReturnType<typeof useDeliveryTimeSlotsQuery>;
export type DeliveryTimeSlotsLazyQueryHookResult = ReturnType<typeof useDeliveryTimeSlotsLazyQuery>;
export type DeliveryTimeSlotsSuspenseQueryHookResult = ReturnType<typeof useDeliveryTimeSlotsSuspenseQuery>;
export type DeliveryTimeSlotsQueryResult = Apollo.QueryResult<DeliveryTimeSlotsQuery, DeliveryTimeSlotsQueryVariables>;