import { i18n } from "@toolkit/i18n";
import { ComplaintTypeEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const complaintTypeEnumOptionsMap: IEnumToOptionsMap<ComplaintTypeEnum> = {
  [ComplaintTypeEnum.Approvals]: {
    key: ComplaintTypeEnum.Approvals,
    get label() {
      return i18n.t("Approvals", { ns: "domains" });
    },
    value: ComplaintTypeEnum.Approvals,
  },
  [ComplaintTypeEnum.Claims]: {
    key: ComplaintTypeEnum.Claims,
    get label() {
      return i18n.t("Claims", { ns: "domains" });
    },
    value: ComplaintTypeEnum.Claims,
  },
  [ComplaintTypeEnum.CustomerService]: {
    key: ComplaintTypeEnum.CustomerService,
    get label() {
      return i18n.t("Customer Service", { ns: "domains" });
    },
    value: ComplaintTypeEnum.CustomerService,
  },
  [ComplaintTypeEnum.Others]: {
    key: ComplaintTypeEnum.Others,
    get label() {
      return i18n.t("Others", { ns: "domains" });
    },
    value: ComplaintTypeEnum.Others,
  },
  [ComplaintTypeEnum.PolicyAdmin]: {
    key: ComplaintTypeEnum.PolicyAdmin,
    get label() {
      return i18n.t("Policy Admin", { ns: "domains" });
    },
    value: ComplaintTypeEnum.PolicyAdmin,
  },
  [ComplaintTypeEnum.Sales]: {
    key: ComplaintTypeEnum.Sales,
    get label() {
      return i18n.t("Sales", { ns: "domains" });
    },
    value: ComplaintTypeEnum.Sales,
  },
};

export const complaintTypeEnumOptions = Object.values(complaintTypeEnumOptionsMap);
