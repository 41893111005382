import { FC, useEffect } from "react";
import { CircularProgress, Typography } from "@toolkit/ui";
import { useGuidedCareJourneyListLazyQuery } from "../../gql";

export const RenderActivities: FC<{ id: string; isActivities: boolean }> = ({ id, isActivities }) => {
  const [getActivities, { loading, data }] = useGuidedCareJourneyListLazyQuery();
  useEffect(() => {
    if (id) {
      getActivities({
        variables: {
          first: 1,
          filter: {
            patientsIds: [id!],
          },
        },
      });
    }
  }, []);

  if (loading) {
    return <CircularProgress size={"sm"} />;
  }

  const activities = data?.guidedCareJourneys?.edges?.map(edge => edge.node) || [];
  return <Typography>{(isActivities ? activities?.[0]?.activities : activities?.[0]?.adherence) || "-"}</Typography>;
};
