import { useNavigate } from "react-router-dom";
import { UserProviderCreateContainer } from "@health/domains";
import { useSetUsersProviderBreadcrumbs } from "../../hooks";
import { usersProvidersPaths } from "../../constants";

export const AdminUserProviderCreateContainer = () => {
  const navigate = useNavigate();

  const onUserVendorUpsertFormChange = () => {
    navigate(usersProvidersPaths.list.fullPath);
  };

  useSetUsersProviderBreadcrumbs("CREATE");

  return <UserProviderCreateContainer isAdmin onChange={onUserVendorUpsertFormChange} />;
};
