import { Maybe, OptimaAction } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { OptimaEditActions } from "@/pages/OptimaEdits/components/OptimaEditActions/OptimaEditActions";
import { FC } from "react";

type HealthSymptomsSpecializationsModalProps = {
  actions?: Maybe<Maybe<OptimaAction>[]>;
};

export const OptimaEditActionsModal: FC<HealthSymptomsSpecializationsModalProps> = props => {
  const { actions } = props;

  const { t } = useTranslation("domains");

  return (
    <CustomDialog type={"info"} title={t("Actions")} button={<ShowButton disabled={!actions || !actions?.length} />}>
      <OptimaEditActions actions={actions as OptimaAction[]} />
    </CustomDialog>
  );
};
