import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { DecisionPlanFilterInput } from "../../schema/types";
import { useDecisionPlansAutocompleteQuery } from "./gql";

type DecisionPlansAutocompleteProps = IAutocompleteProps<DecisionPlanFilterInput>;

export const DecisionPlansAutocomplete: FC<DecisionPlansAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionPlansAutocompleteQuery,
    variables: { filter },
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "display");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Decision Plans") : t("Decision Plan"))}
    />
  );
};
