import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const surveyQuestionFormSchema = z.object({
  id: z.string().nullish(),
  options: z.array(
    z.object({
      value: z.string().min(1),
    })
  ),
  question: z.string().min(1),
  questionType: zodEnumSchema.questionType.required(),
  required: z.boolean(),
});
export type ISurveyQuestionFormValues = z.infer<typeof surveyQuestionFormSchema>;
