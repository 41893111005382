import { destinationTypeOptions, zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const CredentialFormSchema = z.object({
  destination: zodEnumSchema.destinationType,
  username: z.string().min(1),
  password: z.string().optional().nullable(),
  license: z.string().min(1),
});

export type ICredentialFormValues = z.infer<typeof CredentialFormSchema>;

export const credentialFormSchemaDefaultValues: Partial<ICredentialFormValues> = {
  destination: destinationTypeOptions[0],
  username: undefined,
  password: undefined,
  license: undefined,
};
