import { Vendor } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useProviderGetQuery, useProviderUpdateMutation } from "../../gql";
import { convertProviderUpdateFormValuesToBackEndValues } from "../../others";
import { useSetProvidersBreadcrumbs } from "../../hooks";
import { IProviderUpdateContainerParams } from "../../types";
import { ProviderUpsertForm } from "../../forms/ProviderUpsert/ProviderUpsertForm";
import { IProviderUpsertForm } from "../../forms/ProviderUpsert/ProviderUpsertFormSchema";

export const ProviderUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { providerId } = useParams<IProviderUpdateContainerParams>();

  const { data, loading } = useProviderGetQuery({
    variables: { id: providerId! },
    skip: !providerId,
    fetchPolicy: "no-cache",
  });

  const provider = data?.vendor as Vendor;

  const [updateProvider, { loading: isSubmitting }] = useProviderUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.vendorUpdate?.vendorErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Provider updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onProviderUpsertFormChange = (values: IProviderUpsertForm) => {
    updateProvider({
      variables: {
        id: providerId!,
        input: convertProviderUpdateFormValuesToBackEndValues(values),
      },
    });
  };

  useSetProvidersBreadcrumbs("UPDATE", { providerName: pickLocalizedValue(provider?.name, provider?.nameAr)! });

  return (
    <ProviderUpsertForm
      buttonLabel={t("Update")}
      provider={provider}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onProviderUpsertFormChange}
    />
  );
};
