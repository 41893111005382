import { Priority } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { priorityOptionsMap, zodEnumSchema } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import { decisionEffectsFormSchema } from "@/pages/Decisions/forms/DecisionEffects/DecisionEffectsFormSchema";
import { z } from "zod";
import { decisionConditionsGroupsFormSchema } from "../DecisionConditionsGroups/DecisionConditionsGroupsFormSchema";

export const decisionUpsertFormSchema = z
  .object({
    name: z.string().min(3).max(255),
    decisionPlan: zodSchema.decisionPlan,
    priority: zodEnumSchema.priority,
    isActive: z.boolean(),
    isScript: z.boolean(),
    script: z.string().optional(),
    actionFields: z.array(decisionEffectsFormSchema),
    conditionsGroups: z.array(decisionConditionsGroupsFormSchema),
  })
  .superRefine((data, ctx) => {
    if (data.isScript && !data.script) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "domains" }),
        path: ["script"],
      });
    }
  });

export type IDecisionUpsertFormValues = z.infer<typeof decisionUpsertFormSchema>;

export const decisionUpsertFormSchemaDefaultValues: Partial<IDecisionUpsertFormValues> = {
  name: undefined,
  decisionPlan: undefined,
  priority: priorityOptionsMap[Priority.Low],
  isActive: false,
  isScript: false,
  script: undefined,
  actionFields: [],
  conditionsGroups: [],
};
