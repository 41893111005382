import { FC, useEffect, useState } from "react";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { DecisionActivationMutation, useDecisionActivationMutation } from "@/pages/Decisions/gql";

type DecisionActivationProps = {
  id: string;
  isActive: boolean;
};

export const DecisionActivation: FC<DecisionActivationProps> = props => {
  const { id, isActive } = props;

  const { t } = useTranslation("domains");

  const { succeeded, failed } = useAddToast();
  const [check, setCheck] = useState(isActive);

  const [updateDecisionStatusMutation, { loading }] = useDecisionActivationMutation({
    onCompleted: (data: DecisionActivationMutation) => {
      const mutationErrors = data?.updateDecisionStatus?.errors;
      if (mutationErrors && mutationErrors?.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
      } else {
        succeeded(t("Decision status updated successfully"));
      }
    },
  });

  const handleToggleChange = (event, active) => {
    updateDecisionStatusMutation({
      variables: {
        id,
        isActive: active,
      },
    });
  };

  useEffect(() => {
    setCheck(isActive);
  }, [isActive]);

  return (
    <Box sx={{ display: "flex", alignContent: "flex-start" }}>
      <CustomToggleButton disabled={loading} value={check} defaultChecked={Boolean(check)} onChange={handleToggleChange} />
    </Box>
  );
};
