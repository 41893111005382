import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.HealthProgramUpdateInput;
}>;


export type HealthProgramUpdateMutation = { __typename?: 'Mutation', healthProgramUpdate?: { __typename?: 'HealthProgramCRUD', healthProgram?: { __typename?: 'HealthProgram', id: string } | null } | null };


export const HealthProgramUpdateDocument = gql`
    mutation HealthProgramUpdate($id: ID!, $input: HealthProgramUpdateInput!) {
  healthProgramUpdate(input: $input, id: $id) {
    healthProgram {
      id
    }
  }
}
    `;
export type HealthProgramUpdateMutationFn = Apollo.MutationFunction<HealthProgramUpdateMutation, HealthProgramUpdateMutationVariables>;

/**
 * __useHealthProgramUpdateMutation__
 *
 * To run a mutation, you first call `useHealthProgramUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramUpdateMutation, { data, loading, error }] = useHealthProgramUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramUpdateMutation, HealthProgramUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramUpdateMutation, HealthProgramUpdateMutationVariables>(HealthProgramUpdateDocument, options);
      }
export type HealthProgramUpdateMutationHookResult = ReturnType<typeof useHealthProgramUpdateMutation>;
export type HealthProgramUpdateMutationResult = Apollo.MutationResult<HealthProgramUpdateMutation>;
export type HealthProgramUpdateMutationOptions = Apollo.BaseMutationOptions<HealthProgramUpdateMutation, HealthProgramUpdateMutationVariables>;