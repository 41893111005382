import { z } from "zod";
import moment from "moment/moment";
import { i18n } from "@toolkit/i18n";

export const zodTimePickerSchema = z
  .string()
  .refine(value => moment(value, "HH:mm").isValid() || moment(value, "hh:mm A").isValid(), { message: i18n.t("Invalid Time Format") });

export const zodDaysTimesRangesSchema = z.object({
  day: z.string(),
  timeRanges: z.array(
    z.object({
      startTime: zodTimePickerSchema,
      endTime: zodTimePickerSchema,
    })
  ),
});

export type DaysTimesRanges = z.infer<typeof zodDaysTimesRangesSchema>;
