import { PermissionEnum } from "@/schema/types";
import { isSuperAdmin } from "@/utils";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { BarChartIcon } from "@toolkit/ui";
import { IdTokenClaims } from "oidc-client-ts";
import { dashboardPaths, dashboardRoute } from "pages/Dashboard/constants/dashboard-paths";
import Dashboard from "pages/Dashboard/Dashboard";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const dashboardRoutes: (props: { navigate: NavigateFunction; userInfo: IdTokenClaims | undefined }) => RouteItem = ({
  navigate,
  userInfo,
}): RouteItem => {
  const hasDashboardAccess = hasPermission(PermissionEnum.ManageDashboard) || isSuperAdmin(userInfo);
  return {
    id: "dashboard-routes",
    text: i18n.t("Dashboard", { ns: "admin" }),
    route: dashboardRoute,
    fullPath: dashboardPaths.mainPath.fullPath,
    icon: <BarChartIcon />,
    element: <Dashboard />,
    hidden: !hasDashboardAccess,
    isProhibited: !hasDashboardAccess,
    onClick: (route: string) => navigate(route),
  };
};
