export default {};

export enum FieldUIComponent {
  custom = "CUSTOM",
  checkbox = "CHECKBOX",
  autocomplete = "AUTOCOMPLETE",
  lazyAutocomplete = "LAZY_AUTOCOMPLETE",
  input = "INPUT",
  date = "DATE",
}
