import { DecisionPlanCategory } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { kebabCase } from "lodash";
import { convertToTitleCase } from "utils";
import { decisionsPaths } from "./DecisionsPaths";

export const decisionsBreadcrumbs = () => ({
  title: (category: DecisionPlanCategory) => i18n.t(convertToTitleCase(category + "_PLANS"), { ns: "admin" }),
  main: (category: DecisionPlanCategory) => ({
    id: category,
    name: i18n.t(convertToTitleCase(category), { ns: "admin" }),
    route: decisionsPaths.main.fullPathWithParams({ category: kebabCase(category) }),
  }),
  create: {
    id: "decision-new",
    name: i18n.t("New", { ns: "admin" }),
  },
  update: {
    id: "decision-update",
    name: i18n.t("Edit", { ns: "admin" }),
  },
});
