import React, { FC } from "react";
import { Dialog, IconButton } from "../../base/mui";
import { MuiCloseIcon } from "../../base/mui-icons";
import { styles } from "./styles";
import { ZoomImage } from "./types";

const ZoomUploadedImage: FC<ZoomImage> = ({ open, onClose: handleClose, uploadedImage }) => {
  const { classes } = styles();

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.dialog,
      }}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <img src={uploadedImage} alt='ZoomUploadedImage' className={classes.dialogImage} />
      <IconButton size='small' color='default' onClick={handleClose} className={classes.zoomIcon}>
        <MuiCloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default ZoomUploadedImage;
