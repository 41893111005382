import { TFunction, useCustomForm, useOpenState } from "@toolkit/core";
import { credentialType } from "./type";
import { CredentialFormSchema, credentialFormSchemaDefaultValues, ICredentialFormValues } from "./CredentialForm";
import { useGetClinicianCredentialsQuery, useGetPharmacyCredentialsQuery } from "./gql/queries";
import { useSetClinicianCredentialsMutation, useSetPharmacyCredentialsMutation } from "./gql/mutations";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useEffect } from "react";
import { convertCredentialToFormValues, convertCredentialFormValuesToBackEndValues } from "./utils";

type UseCredentialDialogProps = {
  targetId: string;
  type: credentialType;
  t: TFunction;
};

export const useCredentialDialog = (props: UseCredentialDialogProps) => {
  const { targetId, type, t } = props;
  const { open, handleOpen, handleClose } = useOpenState();
  const { failed, succeeded } = useAddToast();

  const form = useCustomForm<ICredentialFormValues>({
    schema: CredentialFormSchema,
    defaultValues: credentialFormSchemaDefaultValues,
    mode: "onChange",
  });
  const { watch } = form;
  const destination = watch("destination");

  const { data: pharmacyCredentialData, loading: isPharmacyLoading } = useGetPharmacyCredentialsQuery({
    variables: {
      destinationType: destination?.value,
      branch: targetId,
    },
    skip: !destination || !targetId || type !== "pharmacy" || !open,
    fetchPolicy: "network-only",
  });

  const { data: clinicianCredentialData, loading: isClinicianLoading } = useGetClinicianCredentialsQuery({
    variables: {
      destinationType: destination?.value,
      user: targetId,
    },
    skip: !destination || !targetId || type !== "clinician" || !open,
    fetchPolicy: "network-only",
  });
  const pharmacyCredentials = pharmacyCredentialData?.getPharmacyCredentials;
  const clinicianCredentials = clinicianCredentialData?.getClinicianCredentials;
  const isLoading = isPharmacyLoading || isClinicianLoading;
  const credential =
    type === "pharmacy"
      ? {
          username: pharmacyCredentials?.userName || "",
          password: "",
          license: pharmacyCredentials?.license || "",
        }
      : {
          username: clinicianCredentials?.userName || "",
          password: "",
          license: clinicianCredentials?.license || "",
        };

  const [SetPharmacyCredentials, { loading: isSetPharmacyLoading }] = useSetPharmacyCredentialsMutation({
    onCompleted: () => {
      succeeded(t("Credentials updated Successfully"));
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const [SetClinicianCredentials, { loading: isSetClinicianLoading }] = useSetClinicianCredentialsMutation({
    onCompleted: () => {
      succeeded(t("Credentials updated Successfully"));
      handleClose();
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const isSubmitting = isSetPharmacyLoading || isSetClinicianLoading;

  useEffect(() => {
    form.setValues(convertCredentialToFormValues(credential));
  }, [JSON.stringify(credential)]);

  const onSubmit = (values: ICredentialFormValues) => {
    type === "pharmacy" &&
      SetPharmacyCredentials({
        variables: {
          destinationType: destination?.value,
          pharmacyCredentials: {
            branch: targetId,
            ...convertCredentialFormValuesToBackEndValues(values),
          },
        },
      });

    type === "clinician" &&
      SetClinicianCredentials({
        variables: {
          destinationType: destination?.value,
          clinicianCredentials: {
            user: targetId,
            ...convertCredentialFormValuesToBackEndValues(values),
          },
        },
      });
  };
  const handleSubmit = form.handleSubmit(onSubmit);
  return { open, handleOpen, handleClose, isLoading, isSubmitting, credential, form, onSubmit, handleSubmit };
};
