import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaValidationRequestActionCreateMutationVariables = Types.Exact<{
  visitId: Types.Scalars['String']['input'];
  action: Types.Scalars['String']['input'];
}>;


export type OptimaValidationRequestActionCreateMutation = { __typename?: 'Mutation', optimaValidationRequestActionCreate?: { __typename?: 'OptimaValidationRequestAction', id: string, createdDate: any, action: string, status: Types.OptimaActionStatus, modifiedDate?: any | null } | null };


export const OptimaValidationRequestActionCreateDocument = gql`
    mutation OptimaValidationRequestActionCreate($visitId: String!, $action: String!) {
  optimaValidationRequestActionCreate(visitId: $visitId, action: $action) {
    id
    createdDate
    action
    status
    modifiedDate
  }
}
    `;
export type OptimaValidationRequestActionCreateMutationFn = Apollo.MutationFunction<OptimaValidationRequestActionCreateMutation, OptimaValidationRequestActionCreateMutationVariables>;

/**
 * __useOptimaValidationRequestActionCreateMutation__
 *
 * To run a mutation, you first call `useOptimaValidationRequestActionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaValidationRequestActionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaValidationRequestActionCreateMutation, { data, loading, error }] = useOptimaValidationRequestActionCreateMutation({
 *   variables: {
 *      visitId: // value for 'visitId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useOptimaValidationRequestActionCreateMutation(baseOptions?: Apollo.MutationHookOptions<OptimaValidationRequestActionCreateMutation, OptimaValidationRequestActionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaValidationRequestActionCreateMutation, OptimaValidationRequestActionCreateMutationVariables>(OptimaValidationRequestActionCreateDocument, options);
      }
export type OptimaValidationRequestActionCreateMutationHookResult = ReturnType<typeof useOptimaValidationRequestActionCreateMutation>;
export type OptimaValidationRequestActionCreateMutationResult = Apollo.MutationResult<OptimaValidationRequestActionCreateMutation>;
export type OptimaValidationRequestActionCreateMutationOptions = Apollo.BaseMutationOptions<OptimaValidationRequestActionCreateMutation, OptimaValidationRequestActionCreateMutationVariables>;