import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthProgramMemberListFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramMemberListSortingInput>;
}>;


export type HealthProgramMemberListGetQuery = { __typename?: 'Query', healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', edges: Array<{ __typename?: 'HealthProgramMemberListCountableEdge', node: { __typename?: 'HealthProgramMemberList', id: string, name?: string | null, description?: string | null, isActive?: boolean | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } }> } | null };


export const HealthProgramMemberListGetDocument = gql`
    query HealthProgramMemberListGet($filter: HealthProgramMemberListFilterInput, $sortBy: HealthProgramMemberListSortingInput) {
  healthProgramMemberLists(first: 1, filter: $filter, sortBy: $sortBy) {
    edges {
      node {
        id
        name
        description
        isActive
        payer {
          id
          name
          nameAr
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMemberListGetQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberListGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberListGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramMemberListGetQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>(HealthProgramMemberListGetDocument, options);
      }
export function useHealthProgramMemberListGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>(HealthProgramMemberListGetDocument, options);
        }
export function useHealthProgramMemberListGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>(HealthProgramMemberListGetDocument, options);
        }
export type HealthProgramMemberListGetQueryHookResult = ReturnType<typeof useHealthProgramMemberListGetQuery>;
export type HealthProgramMemberListGetLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberListGetLazyQuery>;
export type HealthProgramMemberListGetSuspenseQueryHookResult = ReturnType<typeof useHealthProgramMemberListGetSuspenseQuery>;
export type HealthProgramMemberListGetQueryResult = Apollo.QueryResult<HealthProgramMemberListGetQuery, HealthProgramMemberListGetQueryVariables>;