import { DiagnosisValidProcedure } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertDiagnosisValidProcedureToFormValues } from "../../others";
import {
  diagnosisValidProcedureUpsertFormDefaultValues,
  diagnosisValidProcedureUpsertFormSchema,
  IDiagnosisValidProcedureUpsertFormValues,
} from "./diagnosisValidProcedureUpsertFormSchema";
import { DiagnosisValidProcedureInformationForm } from "../DiagnosisValidProcedureInformation/DiagnosisValidProcedureInformationForm";

export type IDiagnosisValidProcedureUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IDiagnosisValidProcedureUpsertFormValues;
  };
};

type DiagnosisValidProcedureUpsertFormProps = {
  diagnosisValidProcedure?: DiagnosisValidProcedure;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IDiagnosisValidProcedureUpsertFormEvent) => void;
};

export const DiagnosisValidProcedureUpsertForm: FC<DiagnosisValidProcedureUpsertFormProps> = props => {
  const { buttonLabel, diagnosisValidProcedure, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IDiagnosisValidProcedureUpsertFormValues>({
    defaultValues: diagnosisValidProcedureUpsertFormDefaultValues,
    schema: diagnosisValidProcedureUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IDiagnosisValidProcedureUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (diagnosisValidProcedure) {
      const _diagnosisValidProcedure = convertDiagnosisValidProcedureToFormValues(diagnosisValidProcedure);
      setValues(_diagnosisValidProcedure);
    }
  }, [diagnosisValidProcedure, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Diagnosis Valid Procedure Information")} loading={isLoading} doYouHaveData>
                <DiagnosisValidProcedureInformationForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
