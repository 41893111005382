import { TablePreferencesDialog } from "../TablePreferencesDialog";
import { useGridContext } from "./GridContext";

export const TableSettingComponent = () => {
  const { tablePreference, customTableProps } = useGridContext();
  const { onColumnsChanged: handleColumnsChanged, reOrderColumns, isColumnsLoading } = tablePreference!;

  return (
    <TablePreferencesDialog
      initialColumnPreferences={customTableProps?.defaultColumns!}
      savedPreferences={reOrderColumns!}
      onApplyPreferences={handleColumnsChanged!}
      isLoading={isColumnsLoading}
    />
  );
};
