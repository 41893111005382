import { useParams } from "react-router-dom";
import { useSetProvidersBreadcrumbs } from "../../hooks";
import { IProviderBranchCreateContainerParams } from "../../types";
import { BranchCreateContainer } from "@health/domains";

export const ProviderBranchCreateContainer = () => {
  const { providerId } = useParams<IProviderBranchCreateContainerParams>();

  useSetProvidersBreadcrumbs("BRANCH_CREATE", { providerId });

  return <BranchCreateContainer providerId={providerId} isAdmin />;
};
