import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type DecisionPlanFragment = { __typename?: 'DecisionPlan', id?: string | null, code?: string | null, display?: string | null, actionDefinitionFields?: Array<{ __typename?: 'ActionDefinitionField', id?: string | null, display?: string | null, type?: Types.ActionFieldType | null, code?: string | null, isRequired?: boolean | null, allowedValues?: Array<string | null> | null } | null> | null, field?: Array<{ __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null, operators?: Array<Types.Operator | null> | null, allowedValues?: Array<string | null> | null, category?: { __typename?: 'FieldCategory', code?: string | null, display?: string | null } | null, fieldType?: { __typename?: 'FieldType', id?: string | null, type?: Types.EntityType | null } | null } | null> | null };

export const DecisionPlanFragmentDoc = gql`
    fragment DecisionPlan on DecisionPlan {
  id
  code
  display
  actionDefinitionFields {
    id
    display
    type
    code
    isRequired
    allowedValues
  }
  field {
    id
    code
    display
    operators
    allowedValues
    category {
      code
      display
    }
    fieldType {
      id
      type
    }
  }
}
    `;