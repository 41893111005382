import { healthProgramMembersRoutes } from "@/pages/HealthProgramMembers/constants";
import { healthProgramMembersListsRoutes } from "@/pages/HealthProgramMembersLists/constants";
import { healthProgramNetworksRoutes } from "@/pages/HealthProgramNetworks/constants/HealthProgramNetworksRoutes";
import { healthProgramsRoutes } from "@/pages/HealthPrograms/constants";
import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasAnyPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { BookAnAppointmentIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";

export const programsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "programs-routes",
    text: i18n.t("Programs", { ns: "admin" }),
    icon: <BookAnAppointmentIcon />,
    isProhibited: !hasAnyPermission([PermissionEnum.ManageHealthPrograms, PermissionEnum.ViewHealthPrograms]),
    subItems: [
      healthProgramsRoutes(navigate),
      healthProgramNetworksRoutes(navigate),
      healthProgramMembersListsRoutes(navigate),
      healthProgramMembersRoutes(navigate),
      // healthProgramBenefitsGroupsRoutes(navigate),
      // healthProgramBenefitsOptionsRoutes(navigate),
      // healthProgramExclusionsRoutes(navigate),
      // healthProgramNetworkProvidersRoutes(navigate),
      // healthProgramDoctorsPointsReportsRoutes(navigate),
      // healthProgramVendorsPointsReportsRoutes(navigate),
    ],
  };
};
