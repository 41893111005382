import { DecisionMakerOrderDirection, SubList, SubListSortField } from "@/schema/types";
import { getListFieldsAutocompleteFilter } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useSublistListTableColumns = (): CustomTableColumnProps<SubList>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ display }) => display,
        isHidden: false,
        sort: {
          columnEnum: SubListSortField.Display,
          direction: DecisionMakerOrderDirection?.Asc,
        },
        settings: {
          hideFromSettings: true,
          disableToggleVisibility: true,
        },
      },
      {
        key: "display",
        header: t("Display"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "display",
        },
      },
      {
        key: "Field",
        header: t("Field"),
        accessor: ({ field }) => field?.display,
        type: "string",
        settings: {
          disableToggleVisibility: true,
        },
        sort: {
          columnEnum: SubListSortField.Field,
        },
        filter: getListFieldsAutocompleteFilter({ name: "field" }),
      },
    ];
  }, []);
};
