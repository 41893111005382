import { z } from "zod";
import { coverageByTypeOptionsMap, zodEnumSchema } from "@health/enum-options";
import { CoverageByType } from "@/schema/types";

export const healthProgramMembershipRequestApproveFormSchema = z.object({
  coverageBy: zodEnumSchema.coverageByType,
  membershipStartDate: z.string(),
  membershipEndDate: z.string(),
  insuranceClass: z.string().nullish(),
  insurancePolicyNumber: z.string().nullish(),
  insuranceCategory: z.string().nullish(),
  insuranceCategoryCode: z.string().nullish(),
});

export type IHealthProgramMembershipRequestApproveForm = z.infer<typeof healthProgramMembershipRequestApproveFormSchema>;

export const healthProgramMembershipRequestApproveFormDefaultValues: Partial<IHealthProgramMembershipRequestApproveForm> = {
  coverageBy: coverageByTypeOptionsMap[CoverageByType.Cash],
  membershipStartDate: undefined,
  membershipEndDate: undefined,
  insuranceClass: undefined,
  insurancePolicyNumber: undefined,
  insuranceCategory: undefined,
  insuranceCategoryCode: undefined,
};
