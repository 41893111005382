import { Grid } from "@toolkit/ui";
import { FC } from "react";
import { ManagedListCard } from "./ManagedListCard";
import ManagedListCardLoader from "./ManagedListCard/ManagedListCardLoader";
import { useAdminManagedListsIndexPage } from "./useAdminManagedListsIndexPage";

/**
 *
 * @returns renders the list of all managed lists, each in its own card
 */
export const AdminManagedLists: FC = () => {
  const { adminManagedLists, isLoading = true, onDetailsClick } = useAdminManagedListsIndexPage();
  return (
    <>
      {isLoading ? (
        <ManagedListCardLoader />
      ) : (
        <Grid container padding={4} spacing={2}>
          {adminManagedLists?.map(item => (
            <Grid key={item?.activeCodeSystem?.display} item xs={12} sm={6} lg={4} xl={3}>
              <ManagedListCard data={item} handleDetailsClick={onDetailsClick} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default AdminManagedLists;
