import { get } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTableColumnProps, OrderDirection } from "../types";
import { handleSortGeneric, nextSortDirection } from "../utils/countableConnection";
import { safeSetEncodedSearchParam } from "../utils/urlStateUtils";

export const useTableSort = <XQueryVariables, Z extends object>(
  variables: XQueryVariables,
  pageSize: number,
  setVariables: Dispatch<SetStateAction<XQueryVariables>>,
  setColumns: Dispatch<SetStateAction<CustomTableColumnProps<Z>[]>>,
  skipUrlState: boolean
) => {
  const navigate = useNavigate();

  const doSort = (field: string, _input?: SetStateAction<XQueryVariables>) => {
    setVariables(
      _input ||
        (state => {
          const direction = nextSortDirection(get(state, "sortBy.direction") as OrderDirection);
          const sortBy = direction
            ? {
                direction,
                field,
              }
            : null;

          if (!skipUrlState) {
            const params = safeSetEncodedSearchParam("sort", sortBy);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
          }

          return {
            ...state,
            first: pageSize,
            after: null,
            last: null,
            before: null,
            sortBy,
          };
        })
    );
  };

  const handleSortData = handleSortGeneric({ setColumns, doSort, direction: get(variables, "sortBy.direction") as OrderDirection });

  return {
    handleSortData,
  };
};
