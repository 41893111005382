import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";

export enum SelectionStatus {
  Selected = "Selected",
  NotSelected = "NotSelected",
  Disabled = "Disabled",
}

export enum TableRowsSelectionStatus {
  All = "All",
  Some = "Some",
  None = "None",
}

export const tableDateTypesFormatsMapper = {
  date: "DD MMM YYYY",
  time: "HH:mm A",
  datetime: DATE_FORMAT_FOR_TABLE_LONG,
};

export const DEFAULT_CELL_FALLBACK = "-";
