import { HealthParameter, TemplateFieldType } from "@/schema/types";
import { YesNo } from "@health/enum-options";
import { isNil } from "lodash";
import moment from "moment";
import { IPatientGuidedCareProgramEvaluationFieldsForm } from "pages/Patients/forms/PatientGuidedCareProgramEvaluationFields/PatientGuidedCareProgramEvaluationFieldsFormSchema";

export const convertRiskFactorTemplateFieldsToFormValues = (fields: (HealthParameter | null)[]) => {
  return fields?.map(field => ({
    id: field?.id!,
    code: field?.code!,
    display: field?.display!,
    arabicDisplay: field?.arabicDisplay,
    type: field?.type!,
    allowedValues: (field?.allowedValues || []) as string[],
    unit: {
      display: field?.unit?.display!,
      arabicDisplay: field?.unit?.arabicDisplay,
    },
    defaultValue: field?.defaultValue,
    isMandatory: !!field?.isMandatory,
    minNormalRangeValue: field?.minNormalRangeValue,
    maxNormalRangeValue: field?.maxNormalRangeValue,
  }));
};

export const convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues = (
  fields: IPatientGuidedCareProgramEvaluationFieldsForm[]
) => {
  const result = {};

  for (const field of fields) {
    const { code, type, allowedValues, valueNumber, valueString, valueBoolean, valueDate, valueStringList } = field;

    if (type === TemplateFieldType.Number && !isNil(valueNumber)) {
      result[code] = valueNumber;
    } else if (type === TemplateFieldType.Date && valueDate) {
      result[code] = moment(valueDate).format();
    } else if (type === TemplateFieldType.Boolean && !isNil(valueBoolean)) {
      result[code] = valueBoolean?.value === YesNo.Yes;
    } else if (type === TemplateFieldType.String) {
      if (allowedValues?.length && valueStringList) {
        result[code] = valueStringList?.value;
      } else if (valueString) {
        result[code] = valueString;
      }
    } else if (valueString) {
      result[code] = valueString;
    }
  }

  return JSON.stringify(result);
};
