import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import adminAr from "./locales/admin/ar.json";
import adminEn from "./locales/admin/en.json";
import consumerAr from "./locales/consumer/ar.json";
import consumerEn from "./locales/consumer/en.json";
import digitalTwinAr from "./locales/digital-twin/ar.json";
import digitalTwinEn from "./locales/digital-twin/en.json";
import domainsAr from "./locales/domains/ar.json";
import domainsEn from "./locales/domains/en.json";
import gcadminAr from "./locales/gcadmin/ar.json";
import gcadminEn from "./locales/gcadmin/en.json";
import optimaAr from "./locales/optima/ar.json";
import optimaEn from "./locales/optima/en.json";
import providerAr from "./locales/provider/ar.json";
import providerEn from "./locales/provider/en.json";
import translationAr from "./locales/translation/ar.json";
import translationEn from "./locales/translation/en.json";

import { ContextUi, InContextTools } from "@tolgee/web/tools";

import { BackendFetch, I18nextPlugin, Tolgee, withTolgee } from "@tolgee/i18next";
import { i18nConfig } from "./i18nConfig";
export const langDetectorOptions = {
  // order and from where user language should be detected
  order: ["querystring", "localStorage", "cookie", "navigator"],
  querystring: "lang",
  // keys or params to lookup language from
  lookupCookie: "locale",
  lookupLocalStorage: "locale",

  // cache user language on
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};
const getI18n = () => {
  const _i18n = i18next.use(LanguageDetector).use(initReactI18next);
  const enableTolgeeWizard = i18nConfig.get("enableTolgeeWizard") === "true";

  const tolgee = Tolgee()
    .use(I18nextPlugin())
    .use(enableTolgeeWizard ? InContextTools() : self => self)
    .use(enableTolgeeWizard ? ContextUi() : self => self)
    .use(
      BackendFetch({
        timeout: 2500,
        prefix:
          "https://axpebais8u12.objectstorage.me-dubai-1.oci.customer-oci.com/n/axpebais8u12/b/tolgee-cdn/o/28d4098662de5a56246b0398afff1159",
      })
    )
    .init({
      defaultLanguage: "en",
      availableLanguages: ["en", "ar"],
      ns: ["translation", "domains"],
      staticData: {
        en: {
          translation: translationEn,
          domains: domainsEn,
          admin: adminEn,
          provider: providerEn,
          gcadmin: gcadminEn,
          optima: optimaEn,
          consumer: consumerEn,
          ["digital-twin"]: digitalTwinEn,
        },
        ar: {
          translation: translationAr,
          domains: domainsAr,
          admin: adminAr,
          provider: providerAr,
          ["digital-twin"]: digitalTwinAr,
          gcadmin: gcadminAr,
          optima: optimaAr,
          consumer: consumerAr,
        },
      },
    });
  const i18n2 = withTolgee(_i18n, tolgee);

  i18n2.init({
    supportedLngs: ["en", "ar"],
    saveMissing: true,
    fallbackNS: "translation",
    ns: ["translation", "domains"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    detection: langDetectorOptions,
    load: "languageOnly",
    returnObjects: true,
    keySeparator: ":",
    cleanCode: true,
    react: {
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "b"],
    },
  });
  return i18n2;
};
const i18n = getI18n();
export default i18n;
