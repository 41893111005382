import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CptHcpcPrimaryProcedureUpdateMutationVariables = Types.Exact<{
  updateCptHcpcPrimaryProcedureId: Types.Scalars['ID']['input'];
  input: Types.CptHcpcPrimaryProcedureInput;
}>;


export type CptHcpcPrimaryProcedureUpdateMutation = { __typename?: 'Mutation', updateCptHcpcPrimaryProcedure?: { __typename?: 'CptHcpcPrimaryProcedure', primaryProcedure: string, itemCode: string, id: string } | null };


export const CptHcpcPrimaryProcedureUpdateDocument = gql`
    mutation CptHcpcPrimaryProcedureUpdate($updateCptHcpcPrimaryProcedureId: ID!, $input: CptHcpcPrimaryProcedureInput!) {
  updateCptHcpcPrimaryProcedure(
    id: $updateCptHcpcPrimaryProcedureId
    input: $input
  ) {
    primaryProcedure
    itemCode
    id
  }
}
    `;
export type CptHcpcPrimaryProcedureUpdateMutationFn = Apollo.MutationFunction<CptHcpcPrimaryProcedureUpdateMutation, CptHcpcPrimaryProcedureUpdateMutationVariables>;

/**
 * __useCptHcpcPrimaryProcedureUpdateMutation__
 *
 * To run a mutation, you first call `useCptHcpcPrimaryProcedureUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCptHcpcPrimaryProcedureUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cptHcpcPrimaryProcedureUpdateMutation, { data, loading, error }] = useCptHcpcPrimaryProcedureUpdateMutation({
 *   variables: {
 *      updateCptHcpcPrimaryProcedureId: // value for 'updateCptHcpcPrimaryProcedureId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCptHcpcPrimaryProcedureUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CptHcpcPrimaryProcedureUpdateMutation, CptHcpcPrimaryProcedureUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CptHcpcPrimaryProcedureUpdateMutation, CptHcpcPrimaryProcedureUpdateMutationVariables>(CptHcpcPrimaryProcedureUpdateDocument, options);
      }
export type CptHcpcPrimaryProcedureUpdateMutationHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureUpdateMutation>;
export type CptHcpcPrimaryProcedureUpdateMutationResult = Apollo.MutationResult<CptHcpcPrimaryProcedureUpdateMutation>;
export type CptHcpcPrimaryProcedureUpdateMutationOptions = Apollo.BaseMutationOptions<CptHcpcPrimaryProcedureUpdateMutation, CptHcpcPrimaryProcedureUpdateMutationVariables>;