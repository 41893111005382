import { useCallback, useEffect, useMemo } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { providersPaths } from "../constants";
import { useProviderBreadcrumbGetQuery } from "../gql";

type ProvidersBreadcrumbsProps = {
  providerId?: string;
  providerName?: string;
  branchName?: string;
};

export const useSetProvidersBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "BRANCH_LIST" | "BRANCH_CREATE" | "BRANCH_UPDATE",
  props?: ProvidersBreadcrumbsProps
) => {
  const { providerId, providerName, branchName } = props ?? {};

  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Health Providers");

  const { data, loading } = useProviderBreadcrumbGetQuery({
    variables: { id: providerId! },
    skip: !providerId,
  });

  const providerNameQuery = pickLocalizedValue(data?.vendor?.name, data?.vendor?.nameAr);

  const providerListBreadcrumb = useMemo(() => {
    return {
      name: t("Providers"),
      route: providersPaths.list.fullPath,
    };
  }, []);

  const providerBranchListBreadcrumb = useMemo(() => {
    return {
      name: t("Branches"),
      route: providersPaths.branchList.fullPathWithParams({ providerId: providerId! }),
    };
  }, [providerId]);

  const setProviderListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setProviderCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [providerListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, providerListBreadcrumb]);

  const setProviderUpdateContainerBreadcrumb = useCallback(() => {
    if (providerName) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [providerListBreadcrumb, { name: providerName }],
      });
    }
  }, [providerName, setBreadCrumb, breadcrumbTitle, providerListBreadcrumb]);

  const setProviderBranchListContainerBreadcrumb = useCallback(() => {
    if (providerNameQuery) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [providerListBreadcrumb, { name: providerNameQuery }, { name: t("Branches") }],
      });
    }
  }, [breadcrumbTitle, providerListBreadcrumb, providerNameQuery, setBreadCrumb]);

  const setProviderBranchCreateContainerBreadcrumb = useCallback(() => {
    if (providerNameQuery) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [providerListBreadcrumb, { name: providerNameQuery }, providerBranchListBreadcrumb, { name: t("New") }],
      });
    }
  }, [breadcrumbTitle, providerBranchListBreadcrumb, providerListBreadcrumb, providerNameQuery, setBreadCrumb]);

  const setProviderBranchUpdateContainerBreadcrumb = useCallback(() => {
    if (providerNameQuery && branchName) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [providerListBreadcrumb, { name: providerNameQuery }, providerBranchListBreadcrumb, { name: branchName }],
      });
    }
  }, [branchName, breadcrumbTitle, providerBranchListBreadcrumb, providerListBreadcrumb, providerNameQuery, setBreadCrumb]);

  useEffect(() => {
    if (!loading) {
      if (containerType === "LIST") {
        setProviderListContainerBreadcrumb();
      } else if (containerType === "CREATE") {
        setProviderCreateContainerBreadcrumb();
      } else if (containerType === "UPDATE") {
        setProviderUpdateContainerBreadcrumb();
      } else if (containerType === "BRANCH_LIST") {
        setProviderBranchListContainerBreadcrumb();
      } else if (containerType === "BRANCH_CREATE") {
        setProviderBranchCreateContainerBreadcrumb();
      } else if (containerType === "BRANCH_UPDATE") {
        setProviderBranchUpdateContainerBreadcrumb();
      }
    }
  }, [
    loading,
    containerType,
    setProviderListContainerBreadcrumb,
    setProviderCreateContainerBreadcrumb,
    setProviderUpdateContainerBreadcrumb,
    setProviderBranchListContainerBreadcrumb,
    setProviderBranchCreateContainerBreadcrumb,
    setProviderBranchUpdateContainerBreadcrumb,
  ]);
};
