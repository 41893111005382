import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useNavigate, useParams } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { User } from "@/schema/types";
import { useUserUpdateMutation } from "@health/domains";
import { useUserAdminGetQuery } from "../../gql";
import { convertUserAdminUpdateFormValuesToBackEndValues } from "../../others";
import { IUserAdminUpdateContainerParams } from "../../types";
import { useSetUsersAdminsBreadcrumbs } from "../../hooks";
import { UserAdminUpsertForm, IUserAdminUpsertFormEvent } from "../../forms/UserAdminUpsert/UserAdminUpsertForm";

export const UserAdminUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { userAdminId } = useParams<IUserAdminUpdateContainerParams>();

  const { data, loading } = useUserAdminGetQuery({
    variables: { id: userAdminId! },
    skip: !userAdminId,
    fetchPolicy: "no-cache",
  });

  const user = data?.user as User;

  const [updateUser, { loading: isSubmitting }] = useUserUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.userUpdate?.accountErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("User updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onUserAdminUpsertFormChange = (event: IUserAdminUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertUserAdminUpdateFormValuesToBackEndValues(event.payload.values, user?.permissionGroups);

      updateUser({
        variables: {
          id: userAdminId!,
          input: values,
        },
      });
    }
  };

  useSetUsersAdminsBreadcrumbs("UPDATE", `${user?.firstName} ${user?.lastName}`);

  return (
    <UserAdminUpsertForm
      buttonLabel={t("Update")}
      user={user}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onUserAdminUpsertFormChange}
    />
  );
};
