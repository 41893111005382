import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminPromotionPublishStatusUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  isPublish: Types.Scalars['Boolean']['input'];
}>;


export type AdminPromotionPublishStatusUpdateMutation = { __typename?: 'Mutation', publishAdminPromotion?: { __typename?: 'AdminPromotion', id: string, isPublished: boolean } | null, unPublishAdminPromotion?: { __typename?: 'AdminPromotion', id: string, isPublished: boolean } | null };


export const AdminPromotionPublishStatusUpdateDocument = gql`
    mutation AdminPromotionPublishStatusUpdate($id: ID!, $isPublish: Boolean!) {
  publishAdminPromotion: adminPromotionPublish(id: $id) @include(if: $isPublish) {
    id
    isPublished
  }
  unPublishAdminPromotion: adminPromotionUnpublish(id: $id) @skip(if: $isPublish) {
    id
    isPublished
  }
}
    `;
export type AdminPromotionPublishStatusUpdateMutationFn = Apollo.MutationFunction<AdminPromotionPublishStatusUpdateMutation, AdminPromotionPublishStatusUpdateMutationVariables>;

/**
 * __useAdminPromotionPublishStatusUpdateMutation__
 *
 * To run a mutation, you first call `useAdminPromotionPublishStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPromotionPublishStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPromotionPublishStatusUpdateMutation, { data, loading, error }] = useAdminPromotionPublishStatusUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isPublish: // value for 'isPublish'
 *   },
 * });
 */
export function useAdminPromotionPublishStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AdminPromotionPublishStatusUpdateMutation, AdminPromotionPublishStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPromotionPublishStatusUpdateMutation, AdminPromotionPublishStatusUpdateMutationVariables>(AdminPromotionPublishStatusUpdateDocument, options);
      }
export type AdminPromotionPublishStatusUpdateMutationHookResult = ReturnType<typeof useAdminPromotionPublishStatusUpdateMutation>;
export type AdminPromotionPublishStatusUpdateMutationResult = Apollo.MutationResult<AdminPromotionPublishStatusUpdateMutation>;
export type AdminPromotionPublishStatusUpdateMutationOptions = Apollo.BaseMutationOptions<AdminPromotionPublishStatusUpdateMutation, AdminPromotionPublishStatusUpdateMutationVariables>;