import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthPackagesQueryVariables = Types.Exact<{
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceHealthPackageFilterInput>;
  sortBy?: Types.InputMaybe<Types.MarketplaceHealthPackageSortingInput>;
}>;


export type HealthPackagesQuery = { __typename?: 'Query', marketplaceHealthPackages?: { __typename?: 'MarketplaceHealthPackageConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceHealthPackageEdge', node: { __typename?: 'MarketplaceHealthPackage', id: string, approvalStatus?: Types.MarketplaceApprovalStatus | null, updatable?: boolean | null, description?: string | null, descriptionAr?: string | null, targetFromAge?: number | null, targetToAge?: number | null, name?: string | null, nameAr?: string | null, price?: any | null, shortDescriptionAr?: string | null, shortDescription?: string | null, mainImageUrl?: string | null, rejectionReason?: string | null, isPublished: boolean, category?: { __typename?: 'MarketplaceHealthPackageCategory', id: string, name?: string | null, nameAr?: string | null } | null, activeDiscount?: { __typename?: 'MarketplaceDiscount', amount?: any | null, endDate: any } | null, vendor: { __typename?: 'Vendor', id: string, nameAr?: string | null, name: string } } }> } | null };


export const HealthPackagesDocument = gql`
    query HealthPackages($last: Int, $first: Int, $after: String, $before: String, $filter: MarketplaceHealthPackageFilterInput, $sortBy: MarketplaceHealthPackageSortingInput) {
  marketplaceHealthPackages(
    last: $last
    first: $first
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        approvalStatus
        category {
          id
          name
          nameAr
        }
        updatable
        description
        descriptionAr
        targetFromAge
        targetToAge
        name
        nameAr
        price
        shortDescriptionAr
        shortDescription
        mainImageUrl
        rejectionReason
        isPublished
        activeDiscount {
          amount
          endDate
        }
        vendor {
          id
          nameAr
          name
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useHealthPackagesQuery__
 *
 * To run a query within a React component, call `useHealthPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthPackagesQuery({
 *   variables: {
 *      last: // value for 'last'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthPackagesQuery(baseOptions?: Apollo.QueryHookOptions<HealthPackagesQuery, HealthPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthPackagesQuery, HealthPackagesQueryVariables>(HealthPackagesDocument, options);
      }
export function useHealthPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthPackagesQuery, HealthPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthPackagesQuery, HealthPackagesQueryVariables>(HealthPackagesDocument, options);
        }
export function useHealthPackagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthPackagesQuery, HealthPackagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthPackagesQuery, HealthPackagesQueryVariables>(HealthPackagesDocument, options);
        }
export type HealthPackagesQueryHookResult = ReturnType<typeof useHealthPackagesQuery>;
export type HealthPackagesLazyQueryHookResult = ReturnType<typeof useHealthPackagesLazyQuery>;
export type HealthPackagesSuspenseQueryHookResult = ReturnType<typeof useHealthPackagesSuspenseQuery>;
export type HealthPackagesQueryResult = Apollo.QueryResult<HealthPackagesQuery, HealthPackagesQueryVariables>;