/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { AppointmentStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const appointmentStatusOptionsMap: IEnumToOptionsMap<AppointmentStatus> = {
  [AppointmentStatus.Approved]: {
    key: AppointmentStatus.Approved,
    get label() {
      return i18n.t("Appointment Confirmed", { ns: "domains" });
    },
    value: AppointmentStatus.Approved,
  },
  [AppointmentStatus.CallInProgress]: {
    key: AppointmentStatus.CallInProgress,
    get label() {
      return i18n.t("Visit starting now", { ns: "domains" });
    },
    value: AppointmentStatus.CallInProgress,
  },
  [AppointmentStatus.CanceledByConsumer]: {
    key: AppointmentStatus.CanceledByConsumer,
    get label() {
      return i18n.t("Appointment Canceled By Consumer", { ns: "domains" });
    },
    value: AppointmentStatus.CanceledByConsumer,
  },
  [AppointmentStatus.CanceledByDoctor]: {
    key: AppointmentStatus.CanceledByDoctor,
    get label() {
      return i18n.t("Appointment Canceled By Doctor", { ns: "domains" });
    },
    value: AppointmentStatus.CanceledByDoctor,
  },
  [AppointmentStatus.Cancelled]: {
    key: AppointmentStatus.Cancelled,
    get label() {
      return i18n.t("Appointment Cancelled", { ns: "domains" });
    },
    value: AppointmentStatus.Cancelled,
  },
  [AppointmentStatus.ChatInProgress]: {
    key: AppointmentStatus.ChatInProgress,
    get label() {
      return i18n.t("Visit starting now", { ns: "domains" });
    },
    value: AppointmentStatus.ChatInProgress,
  },
  [AppointmentStatus.ComingSoon]: {
    key: AppointmentStatus.ComingSoon,
    get label() {
      return i18n.t("Coming Soon", { ns: "domains" });
    },
    value: AppointmentStatus.ComingSoon,
  },
  [AppointmentStatus.Confirmed]: {
    key: AppointmentStatus.Confirmed,
    get label() {
      return i18n.t("Appointment Confirmed", { ns: "domains" });
    },
    value: AppointmentStatus.Confirmed,
  },
  [AppointmentStatus.FinishedSuccessfully]: {
    key: AppointmentStatus.FinishedSuccessfully,
    get label() {
      return i18n.t("Finished Successfully", { ns: "domains" });
    },
    value: AppointmentStatus.FinishedSuccessfully,
  },
  [AppointmentStatus.OutPatientJourneyAssessmentDone]: {
    key: AppointmentStatus.OutPatientJourneyAssessmentDone,
    get label() {
      return i18n.t("Out Patient Journey Assessment Done", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyAssessmentDone,
  },
  [AppointmentStatus.OutPatientJourneyAssessmentPending]: {
    key: AppointmentStatus.OutPatientJourneyAssessmentPending,
    get label() {
      return i18n.t("Out Patient Journey Assessment Pending", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyAssessmentPending,
  },
  [AppointmentStatus.OutPatientJourneyCheckedIn]: {
    key: AppointmentStatus.OutPatientJourneyCheckedIn,
    get label() {
      return i18n.t("Out Patient Journey Checked In", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyCheckedIn,
  },
  [AppointmentStatus.OutPatientJourneyConsultationDone]: {
    key: AppointmentStatus.OutPatientJourneyConsultationDone,
    get label() {
      return i18n.t("Out Patient Journey Consultation Done", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyConsultationDone,
  },
  [AppointmentStatus.OutPatientJourneyConsultationPending]: {
    key: AppointmentStatus.OutPatientJourneyConsultationPending,
    get label() {
      return i18n.t("Out Patient Journey Consultation Pending", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyConsultationPending,
  },
  [AppointmentStatus.OutPatientJourneyPending]: {
    key: AppointmentStatus.OutPatientJourneyPending,
    get label() {
      return i18n.t("Out Patient Journey Pending", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyPending,
  },
  [AppointmentStatus.OutPatientJourneyTreatmentDone]: {
    key: AppointmentStatus.OutPatientJourneyTreatmentDone,
    get label() {
      return i18n.t("Out Patient Journey Treatment Done", { ns: "domains" });
    },
    value: AppointmentStatus.OutPatientJourneyTreatmentDone,
  },
  [AppointmentStatus.PaymentFailed]: {
    key: AppointmentStatus.PaymentFailed,
    get label() {
      return i18n.t("Payment Failed", { ns: "domains" });
    },
    value: AppointmentStatus.PaymentFailed,
  },
  [AppointmentStatus.PaymentPending]: {
    key: AppointmentStatus.PaymentPending,
    get label() {
      return i18n.t("Payment Pending", { ns: "domains" });
    },
    value: AppointmentStatus.PaymentPending,
  },
  [AppointmentStatus.PaymentSucceeded]: {
    key: AppointmentStatus.PaymentSucceeded,
    get label() {
      return i18n.t("Payment Succeeded", { ns: "domains" });
    },
    value: AppointmentStatus.PaymentSucceeded,
  },
  [AppointmentStatus.PendingApproval]: {
    key: AppointmentStatus.PendingApproval,
    get label() {
      return i18n.t("Appointment Request Confirmation", { ns: "domains" });
    },
    value: AppointmentStatus.PendingApproval,
  },
  [AppointmentStatus.Rejected]: {
    key: AppointmentStatus.Rejected,
    get label() {
      return i18n.t("Appointment Rejected", { ns: "domains" });
    },
    value: AppointmentStatus.Rejected,
  },
  [AppointmentStatus.Request]: {
    key: AppointmentStatus.Request,
    get label() {
      return i18n.t("New Appointment Request", { ns: "domains" });
    },
    value: AppointmentStatus.Request,
  },
  [AppointmentStatus.RescheduledByConsumer]: {
    key: AppointmentStatus.RescheduledByConsumer,
    get label() {
      return i18n.t("Appointment Rescheduled By Consumer", { ns: "domains" });
    },
    value: AppointmentStatus.RescheduledByConsumer,
  },
  [AppointmentStatus.RescheduledByDoctor]: {
    key: AppointmentStatus.RescheduledByDoctor,
    get label() {
      return i18n.t("Appointment Rescheduled By Doctor", { ns: "domains" });
    },
    value: AppointmentStatus.RescheduledByDoctor,
  },
  [AppointmentStatus.Tentative]: {
    key: AppointmentStatus.Tentative,
    get label() {
      return i18n.t("Appointment Tentative", { ns: "domains" });
    },
    value: AppointmentStatus.Tentative,
  },
};

export const appointmentStatusOption = Object.values(appointmentStatusOptionsMap);
