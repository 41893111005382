import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionHealthParametersAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthParameterFilterInput>;
}>;


export type DecisionHealthParametersAutocompleteQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', id?: string | null, name?: string | null, nameAr?: string | null } | null } | null> | null } | null };


export const DecisionHealthParametersAutocompleteDocument = gql`
    query DecisionHealthParametersAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthParameterFilterInput) {
  getHealthParameters(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        name: display
        nameAr: arabicDisplay
      }
    }
  }
}
    `;

/**
 * __useDecisionHealthParametersAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionHealthParametersAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionHealthParametersAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionHealthParametersAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionHealthParametersAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>(DecisionHealthParametersAutocompleteDocument, options);
      }
export function useDecisionHealthParametersAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>(DecisionHealthParametersAutocompleteDocument, options);
        }
export function useDecisionHealthParametersAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>(DecisionHealthParametersAutocompleteDocument, options);
        }
export type DecisionHealthParametersAutocompleteQueryHookResult = ReturnType<typeof useDecisionHealthParametersAutocompleteQuery>;
export type DecisionHealthParametersAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionHealthParametersAutocompleteLazyQuery>;
export type DecisionHealthParametersAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionHealthParametersAutocompleteSuspenseQuery>;
export type DecisionHealthParametersAutocompleteQueryResult = Apollo.QueryResult<DecisionHealthParametersAutocompleteQuery, DecisionHealthParametersAutocompleteQueryVariables>;