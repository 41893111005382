import React, { useRef, useEffect, FC } from "react";
import QRCodeStyling from "qr-code-styling";
import { Box, makeStyles } from "../../base/mui";

const useStyles = makeStyles()(theme => ({
  qrContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  qrWrapper: {
    padding: 0,
    backgroundColor: theme.palette.common.white,
  },
}));

type StyledQRCodeProps = {
  url: string;
  logoPath: string;
};
export const StyledQRCode: FC<StyledQRCodeProps> = props => {
  const { url, logoPath } = props;
  const qrRef = useRef<HTMLDivElement>(null);
  const { classes, theme } = useStyles();

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 200,
      height: 200,
      type: "svg",
      data: url,
      image: logoPath,
      dotsOptions: {
        color: theme.palette.primary.main,
        type: "rounded",
        gradient: {
          type: "radial",
          colorStops: [
            { offset: 0, color: theme.palette.primary.main },
            { offset: 1, color: theme.palette.secondary.main },
          ],
        },
      },
      backgroundOptions: {
        color: theme.palette.common.white,
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 2,
        imageSize: 0.5,
      },
      cornersSquareOptions: {
        color: theme.palette.primary.main,
        type: "extra-rounded",
        gradient: {
          type: "linear",
          colorStops: [
            { offset: 0, color: theme.palette.primary.main },
            { offset: 1, color: theme.palette.secondary.main },
          ],
        },
      },
      cornersDotOptions: {
        color: theme.palette.primary.main,
        type: "dot",
        gradient: {
          type: "linear",
          colorStops: [
            { offset: 0, color: theme.palette.primary.main },
            { offset: 1, color: theme.palette.secondary.main },
          ],
        },
      },
    });

    if (qrRef.current) {
      qrRef.current.innerHTML = "";
      qrCode.append(qrRef.current);
    }
  }, [url, logoPath, theme]);

  return (
    <Box className={classes.qrContainer}>
      <Box className={classes.qrWrapper}>
        <Box ref={qrRef} />
      </Box>
    </Box>
  );
};
