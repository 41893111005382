import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceBenefitApprovalLimitGetQueryVariables = Types.Exact<{
  getInsuranceBenefitApprovalLimitId: Types.Scalars['ID']['input'];
}>;


export type InsuranceBenefitApprovalLimitGetQuery = { __typename?: 'Query', getInsuranceBenefitApprovalLimit?: { __typename?: 'InsuranceBenefitApprovalLimit', source: string, limit: number, insurancePolicy: string, insuranceLicense: string, encounterType: string, itemCode: string, id: string, description?: string | null } | null };


export const InsuranceBenefitApprovalLimitGetDocument = gql`
    query InsuranceBenefitApprovalLimitGet($getInsuranceBenefitApprovalLimitId: ID!) {
  getInsuranceBenefitApprovalLimit(id: $getInsuranceBenefitApprovalLimitId) {
    source
    limit
    insurancePolicy
    insuranceLicense
    encounterType
    itemCode
    id
    description
  }
}
    `;

/**
 * __useInsuranceBenefitApprovalLimitGetQuery__
 *
 * To run a query within a React component, call `useInsuranceBenefitApprovalLimitGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceBenefitApprovalLimitGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceBenefitApprovalLimitGetQuery({
 *   variables: {
 *      getInsuranceBenefitApprovalLimitId: // value for 'getInsuranceBenefitApprovalLimitId'
 *   },
 * });
 */
export function useInsuranceBenefitApprovalLimitGetQuery(baseOptions: Apollo.QueryHookOptions<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables> & ({ variables: InsuranceBenefitApprovalLimitGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables>(InsuranceBenefitApprovalLimitGetDocument, options);
      }
export function useInsuranceBenefitApprovalLimitGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables>(InsuranceBenefitApprovalLimitGetDocument, options);
        }
export function useInsuranceBenefitApprovalLimitGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables>(InsuranceBenefitApprovalLimitGetDocument, options);
        }
export type InsuranceBenefitApprovalLimitGetQueryHookResult = ReturnType<typeof useInsuranceBenefitApprovalLimitGetQuery>;
export type InsuranceBenefitApprovalLimitGetLazyQueryHookResult = ReturnType<typeof useInsuranceBenefitApprovalLimitGetLazyQuery>;
export type InsuranceBenefitApprovalLimitGetSuspenseQueryHookResult = ReturnType<typeof useInsuranceBenefitApprovalLimitGetSuspenseQuery>;
export type InsuranceBenefitApprovalLimitGetQueryResult = Apollo.QueryResult<InsuranceBenefitApprovalLimitGetQuery, InsuranceBenefitApprovalLimitGetQueryVariables>;