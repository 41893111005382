import { CustomDialog, ShowButton } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { Address, Maybe } from "@/schema/types";
import { FC } from "react";
import { AddressInformation } from "@/pages/Addresses/components/AddressInformation/AddressInformation";

type AddressInformationModalProps = {
  address?: Maybe<Address>;
};

export const AddressInformationModal: FC<AddressInformationModalProps> = props => {
  const { address } = props;

  const { t } = useTranslation("domains");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!address} />} title={t("Address Information")}>
      <AddressInformation address={address} />
    </CustomDialog>
  );
};
