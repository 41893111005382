import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { AppointmentSchedulingIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { patientEnrollmentRequestsPaths, patientEnrollmentRequestsRoute } from "./PatientEnrollmentRequestsPaths";
import { PatientEnrollmentRequestsListContainer } from "../containers";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";

export const patientEnrollmentRequestsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "patient-enrollment-requests-routes",
    text: i18n.t("Patient Enrollment Requests", { ns: "admin" }),
    route: patientEnrollmentRequestsRoute,
    icon: <AppointmentSchedulingIcon />,
    isProhibited: !hasPermission(PermissionEnum.ViewPatientEnrollmentRequest),
    subItems: [
      {
        id: "patient-enrollment-requests-list-route",
        route: patientEnrollmentRequestsPaths.list.route,
        fullPath: patientEnrollmentRequestsPaths.list.fullPath,
        element: <PatientEnrollmentRequestsListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
