import { useCallback, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";

export const useSetHealthProgramMembershipRequestsBreadcrumbs = (containerType: "LIST") => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Insurance Approvals");

  const setHealthProgramMembershipRequestListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthProgramMembershipRequestListContainerBreadcrumb();
    }
  }, [containerType, setHealthProgramMembershipRequestListContainerBreadcrumb]);
};
