import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcedureAgeCreateMutationVariables = Types.Exact<{
  input: Types.ProcedureAgeInput;
}>;


export type ProcedureAgeCreateMutation = { __typename?: 'Mutation', createProcedureAge?: { __typename?: 'ProcedureAge', id: string } | null };


export const ProcedureAgeCreateDocument = gql`
    mutation ProcedureAgeCreate($input: ProcedureAgeInput!) {
  createProcedureAge(input: $input) {
    id
  }
}
    `;
export type ProcedureAgeCreateMutationFn = Apollo.MutationFunction<ProcedureAgeCreateMutation, ProcedureAgeCreateMutationVariables>;

/**
 * __useProcedureAgeCreateMutation__
 *
 * To run a mutation, you first call `useProcedureAgeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcedureAgeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [procedureAgeCreateMutation, { data, loading, error }] = useProcedureAgeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcedureAgeCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProcedureAgeCreateMutation, ProcedureAgeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcedureAgeCreateMutation, ProcedureAgeCreateMutationVariables>(ProcedureAgeCreateDocument, options);
      }
export type ProcedureAgeCreateMutationHookResult = ReturnType<typeof useProcedureAgeCreateMutation>;
export type ProcedureAgeCreateMutationResult = Apollo.MutationResult<ProcedureAgeCreateMutation>;
export type ProcedureAgeCreateMutationOptions = Apollo.BaseMutationOptions<ProcedureAgeCreateMutation, ProcedureAgeCreateMutationVariables>;