import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import React, { FC, useEffect, useRef, useState } from "react";
import { OrderCancelForm, OrderCancelFormRef, IOrderCancelFormEvent } from "../../forms/OrderCancel/OrderCancelForm";

type IOrderCancelModalData = {
  id: string;
};

type IOpen = (data: IOrderCancelModalData) => void;
type IClose = () => void;

export const OrderCancelModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type OrderCancelModalProps = {
  isLoading?: boolean;
  onChange: (event: IOrderCancelFormEvent) => void;
};

export const OrderCancelModal: FC<OrderCancelModalProps> = props => {
  const { isLoading, onChange } = props;

  const [data, setData] = useState<IOrderCancelModalData>();

  const { t } = useTranslation("domains");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const orderCancelFormRef = useRef<OrderCancelFormRef>(null);

  const onCancelClick = () => {
    orderCancelFormRef?.current?.submit();
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };
  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    OrderCancelModalApi.open = open;
    OrderCancelModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Order Cancel"),
        onClose: close,
      }}
      DialogActionsProps={{
        children: (
          <Button
            color={"error"}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onCancelClick}
          >
            {t("Cancel")}
          </Button>
        ),
      }}
    >
      <OrderCancelForm id={data?.id!} ref={orderCancelFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
