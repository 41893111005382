import { BenefitMap } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useBenefitMapGetQuery, useBenefitMapUpdateMutation } from "../../gql";
import { IBenefitMapUpdateContainerParams } from "../../types";
import { useSetBenefitMapsBreadcrumbs } from "../../hooks";
import { BenefitMapUpsertForm, IBenefitMapUpsertFormEvent } from "../../forms/BenefitMapUpsert/BenefitMapUpsertForm";
import { convertBenefitMapFormValuesToBackEndValues } from "../../others";

export const BenefitMapUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { benefitMapId } = useParams<IBenefitMapUpdateContainerParams>();

  const { data, loading } = useBenefitMapGetQuery({
    variables: { getBenefitMapId: benefitMapId! },
    skip: !benefitMapId,
    fetchPolicy: "no-cache",
  });

  const benefitMap = data?.getBenefitMap as BenefitMap;

  const [updateBenefitMap, { loading: isSubmitting }] = useBenefitMapUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.updateBenefitMap?.id) {
        succeeded(t("Benefit Map updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onBenefitMapUpsertFormChange = (event: IBenefitMapUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertBenefitMapFormValuesToBackEndValues(event.payload.values);

      updateBenefitMap({
        variables: {
          updateBenefitMapId: benefitMapId!,
          input: values,
        },
      });
    }
  };

  useSetBenefitMapsBreadcrumbs("UPDATE", benefitMap?.benefitCode);

  return (
    <BenefitMapUpsertForm
      buttonLabel={t("Update")}
      benefitMap={benefitMap}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onBenefitMapUpsertFormChange}
    />
  );
};
