import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Grid, IconButton, Typography, useTheme } from "../../../../base/mui";
import { CustomIcon } from "../../../CustomIcon";
import { getTimeFormat, TimePicker, TimePickerProps } from "../../TimePicker";
import { calculateMinTimes, isAddButtonDisabled, validateDaysTimesRanges } from "../others";

export type IDaysTimesRangesTimes = {
  startTime?: string;
  endTime?: string;
};

export type IDaysTimesRanges = {
  day: string;
  timeRanges: IDaysTimesRangesTimes[];
};

export type DaysTimesRangesProps = {
  value: IDaysTimesRanges[];
  startTimeLabel?: string;
  endTimeLabel?: string;
  timePicker?: TimePickerProps;
  onChange: (values: IDaysTimesRanges[]) => void;
};

export const DaysTimesRanges: FC<DaysTimesRangesProps> = props => {
  const { value, startTimeLabel, endTimeLabel, timePicker, onChange } = props;

  const [daysTimesRanges, setDaysTimesRanges] = useState<IDaysTimesRanges[]>([]);

  const { t } = useTranslation();

  const theme = useTheme();

  const format = getTimeFormat(timePicker?.ampm);

  const errors = validateDaysTimesRanges(daysTimesRanges, format);

  const handleAcceptTime = useCallback(
    (selectedValue: string | undefined, dayIndex: number, timeRangeIndex: number, field: "startTime" | "endTime") => {
      const updatedDaysTimesRanges = [...daysTimesRanges];
      const timeRange = updatedDaysTimesRanges[dayIndex].timeRanges[timeRangeIndex];

      if (field === "startTime") {
        timeRange.startTime = selectedValue;
      } else {
        timeRange.endTime = selectedValue;
      }

      setDaysTimesRanges(updatedDaysTimesRanges);
      onChange(updatedDaysTimesRanges);
    },
    [daysTimesRanges, onChange]
  );

  const handleAddTimeRange = useCallback(
    (dayIndex: number) => {
      const updatedDaysTimesRanges = [...daysTimesRanges];
      updatedDaysTimesRanges[dayIndex].timeRanges.push({ startTime: undefined, endTime: undefined });

      setDaysTimesRanges(updatedDaysTimesRanges);
      onChange(updatedDaysTimesRanges);
    },
    [daysTimesRanges, onChange]
  );

  const handleRemoveTimeRange = useCallback(
    (dayIndex: number, timeRangeIndex: number) => {
      const updatedDaysTimesRanges = [...daysTimesRanges];
      const day = updatedDaysTimesRanges[dayIndex];

      if (day.timeRanges.length > 1) {
        day.timeRanges = day.timeRanges.filter((_, idx) => idx !== timeRangeIndex);
        setDaysTimesRanges(updatedDaysTimesRanges);
        onChange(updatedDaysTimesRanges);
      }
    },
    [daysTimesRanges, onChange]
  );

  useEffect(() => {
    if (value && value?.length) {
      setDaysTimesRanges(value);
    }
  }, [value]);

  return (
    <Grid container spacing={2}>
      {daysTimesRanges?.map((dayData, dayIndex) => (
        <Grid key={dayData.day} item xs={12}>
          <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.bold} marginBottom={"8px"}>
            {dayData?.day}
          </Typography>

          {dayData.timeRanges?.map((timeRange, rangeIndex) => {
            const { minStartTime, minEndTime } = calculateMinTimes(dayIndex, rangeIndex, daysTimesRanges);

            return (
              <Grid key={`${timeRange?.startTime}-${timeRange?.endTime}`} container spacing={1} marginBottom={"8px"}>
                <Grid item xs={4}>
                  <TimePicker
                    {...timePicker}
                    label={startTimeLabel || t("Start Time")}
                    value={timeRange?.startTime}
                    minTime={minStartTime}
                    error={errors?.[dayIndex]?.[rangeIndex]?.startTime}
                    onAccept={selectedValue => handleAcceptTime(selectedValue, dayIndex, rangeIndex, "startTime")}
                  />
                </Grid>

                <Grid item xs={0.5} alignSelf={"center"} textAlign={"center"}>
                  -
                </Grid>

                <Grid item xs={4}>
                  <TimePicker
                    {...timePicker}
                    label={endTimeLabel || t("End Time")}
                    value={timeRange?.endTime}
                    minTime={minEndTime}
                    error={errors?.[dayIndex]?.[rangeIndex]?.endTime}
                    onAccept={selectedValue => handleAcceptTime(selectedValue, dayIndex, rangeIndex, "endTime")}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Grid container>
                    {rangeIndex === dayData.timeRanges.length - 1 && (
                      <Grid item>
                        <IconButton
                          onClick={() => handleAddTimeRange(dayIndex)}
                          disabled={isAddButtonDisabled(dayIndex, daysTimesRanges, errors)}
                        >
                          <CustomIcon icon={"add"} color={theme.palette.primary.main} width={30} height={30} />
                        </IconButton>
                      </Grid>
                    )}

                    <Grid item>
                      <IconButton onClick={() => handleRemoveTimeRange(dayIndex, rangeIndex)} disabled={dayData.timeRanges.length === 1}>
                        <CustomIcon icon={"close"} color={theme.palette.error.main} width={32} height={32} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ))}
    </Grid>
  );
};
