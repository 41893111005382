import { isEmpty } from "lodash";

// ** SET FILTERS PARAMS
export const safeSetFiltersSearchParams = ({ requested, form, active }): URLSearchParams => {
  return safeSetEncodedSearchParam(
    "filter",
    isEmpty(requested)
      ? {}
      : {
          requested: JSON.stringify(requested),
          form: JSON.stringify(form),
          active: JSON.stringify(active),
        }
  );
};
// ** GET FILTERS PARAMS
export const safeGetFiltersSearchParams = (): {
  requested: object | undefined;
  active: object | undefined;
  form: object | undefined;
} => {
  const defaultFilter = safeGetEncodedSearchParam("filter") as {
    requested: string;
    active: string;
    form: string;
  };

  const requestedFilter = JSON.parse(defaultFilter?.requested! || "{}");
  const activeFilters = JSON.parse(defaultFilter?.active! || "{}");
  const formFilters = JSON.parse(defaultFilter?.form! || "{}");

  return {
    requested: isEmpty(requestedFilter) ? undefined : requestedFilter,
    active: isEmpty(activeFilters) ? undefined : activeFilters,
    form: isEmpty(formFilters) ? undefined : formFilters,
  };
};

export const safeSetEncodedSearchParam = (paramName: string, value: string | object | undefined | null): URLSearchParams => {
  const params = new URLSearchParams(location.search);
  if (value && !isEmpty(value)) {
    const v = typeof value === "string" ? value : JSON.stringify(value);
    // params.set(paramName, btoa(JSON.stringify(value)));
    params.set(paramName, v);
  } else {
    params.delete(paramName);
  }
  return params;
};

export const safeGetEncodedSearchParam = (paramName: string): object => {
  const params = new URLSearchParams(location.search);

  if (params.get(paramName)) {
    // return params.get(paramName) !== "undefined" ? JSON.parse(atob(params.get(paramName) || "e30=") || "{}") : {};
    return params.get(paramName) !== "undefined" ? JSON.parse(params.get(paramName) || "e30=" || "{}") : {};
  } else {
    return {};
  }
};

export const getSearchParamsVariables = (): {
  after: string | undefined;
  filter: object | undefined;
  sortBy: object | undefined;
} => {
  const params = new URLSearchParams(location.search);

  const defaultSort = safeGetEncodedSearchParam("sort");
  const filters = safeGetFiltersSearchParams();

  return {
    after: params.get("cursor") || undefined,
    sortBy: isEmpty(defaultSort) ? undefined : defaultSort,
    filter: filters?.requested,
  };
};
