import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, MenuItem, Typography, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { SendCodeSystemToReviewMutation, useSendCodeSystemToReviewMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const SendToReviewButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation("domains");
  const { failed } = useAddToast();

  const [sendCodeSystemToReview, { loading }] = useSendCodeSystemToReviewMutation({
    onCompleted: (data: SendCodeSystemToReviewMutation) => {
      const mutationErrors = data?.sendCodeSystemToReview?.errors;
      if (mutationErrors && mutationErrors?.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const { refetchCodeSystem, versionParamId } = useAdminManagedListDetailsPageContext();
  const handleSendToReview = () => {
    sendCodeSystemToReview({
      variables: {
        codeSystemId: versionParamId as string,
      },
    }).then(() => {
      refetchCodeSystem?.();
    });
    onClick();
  };
  return (
    <MenuItem onClick={handleSendToReview} disabled={loading}>
      <CustomIcon icon='share' />
      <Typography marginLeft='2px'> {t("Send To Review")}</Typography>
    </MenuItem>
  );
};
