import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceNetworksQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.InsuranceNetworkFilterInput>;
  sortBy?: Types.InputMaybe<Types.InsuranceSortingInput>;
}>;


export type InsuranceNetworksQuery = { __typename?: 'Query', insuranceNetworks?: { __typename?: 'InsuranceNetworkCountableConnection', totalCount?: number | null, pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'InsuranceNetworkCountableEdge', node: { __typename?: 'InsuranceNetwork', id: string, name?: string | null, created?: any | null, isActive?: boolean | null, facilities?: { __typename?: 'InsuranceNetworkFacilityCountableConnection', totalCount?: number | null, edges: Array<{ __typename?: 'InsuranceNetworkFacilityCountableEdge', node: { __typename?: 'InsuranceNetworkFacility', id: string } }> } | null } }> } | null };


export const InsuranceNetworksDocument = gql`
    query insuranceNetworks($first: Int, $after: String, $last: Int, $before: String, $filter: InsuranceNetworkFilterInput, $sortBy: InsuranceSortingInput) {
  insuranceNetworks(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        created
        facilities(first: 5) {
          totalCount
          edges {
            node {
              id
            }
          }
        }
        isActive
      }
    }
  }
}
    `;

/**
 * __useInsuranceNetworksQuery__
 *
 * To run a query within a React component, call `useInsuranceNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsuranceNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsuranceNetworksQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useInsuranceNetworksQuery(baseOptions?: Apollo.QueryHookOptions<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>(InsuranceNetworksDocument, options);
      }
export function useInsuranceNetworksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>(InsuranceNetworksDocument, options);
        }
export function useInsuranceNetworksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>(InsuranceNetworksDocument, options);
        }
export type InsuranceNetworksQueryHookResult = ReturnType<typeof useInsuranceNetworksQuery>;
export type InsuranceNetworksLazyQueryHookResult = ReturnType<typeof useInsuranceNetworksLazyQuery>;
export type InsuranceNetworksSuspenseQueryHookResult = ReturnType<typeof useInsuranceNetworksSuspenseQuery>;
export type InsuranceNetworksQueryResult = Apollo.QueryResult<InsuranceNetworksQuery, InsuranceNetworksQueryVariables>;