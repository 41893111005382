import {
  articleCategorySchema,
  articleSchema,
  branchSchema,
  cancellationReasonSchema,
  citySchema,
  decisionPlanSchema,
  departmentSchema,
  doctorPatientSchema,
  doctorSchema,
  doctorTeamLeaderSchema,
  guidedCareProgramSchema,
  guidedCareTeamSchema,
  guidedCareTeamUserSchema,
  guidedCareTemplateSchema,
  healthConditionSchema,
  healthParameterCategorySchema,
  healthParameterSchema,
  healthParameterUnitSchema,
  healthProgramBenefitGroupSchema,
  healthProgramMemberListSchema,
  healthProgramMemberSchema,
  healthProgramNetworkSchema,
  healthProgramNetworkProviderSchema,
  healthProgramSchema,
  languageSchema,
  marketplaceBrandSchema,
  marketplaceHealthPackageCategorySchema,
  marketplaceHealthPackageSchema,
  marketplaceProductCategorySchema,
  marketplaceProductSchema,
  medicalFormClassificationSchema,
  medicalFormSchema,
  medicalMessageSchema,
  optimaActionSchema,
  optimaEncounterTypeSchema,
  patientSchema,
  payerSchema,
  permissionSchema,
  permissionGroupSchema,
  riskFactorTemplateSchema,
  sadaVideoSchema,
  subListSchema,
  subscriptionPlanSchema,
  systemCodeSchema,
  userSchema,
  userEmailSchema,
  vendorDepartmentSchema,
  vendorSchema,
  optimaClinicianSchema,
} from "../components";

export const zodSchema = {
  article: articleSchema,
  articleCategory: articleCategorySchema,
  branch: branchSchema,
  cancellationReason: cancellationReasonSchema,
  city: citySchema,
  decisionPlan: decisionPlanSchema,
  department: departmentSchema,
  doctor: doctorSchema,
  doctorPatient: doctorPatientSchema,
  doctorTeamLeader: doctorTeamLeaderSchema,
  guidedCareProgram: guidedCareProgramSchema,
  guidedCareTeam: guidedCareTeamSchema,
  guidedCareTeamUser: guidedCareTeamUserSchema,
  guidedCareTemplate: guidedCareTemplateSchema,
  healthCondition: healthConditionSchema,
  healthParameter: healthParameterSchema,
  healthParameterCategory: healthParameterCategorySchema,
  healthParameterUnit: healthParameterUnitSchema,
  healthProgram: healthProgramSchema,
  healthProgramBenefitGroup: healthProgramBenefitGroupSchema,
  healthProgramMember: healthProgramMemberSchema,
  healthProgramMemberList: healthProgramMemberListSchema,
  healthProgramNetwork: healthProgramNetworkSchema,
  healthProgramNetworkProvider: healthProgramNetworkProviderSchema,
  language: languageSchema,
  marketplaceBrand: marketplaceBrandSchema,
  marketplaceHealthPackage: marketplaceHealthPackageSchema,
  marketplaceHealthPackageCategory: marketplaceHealthPackageCategorySchema,
  marketplaceProduct: marketplaceProductSchema,
  marketplaceProductCategory: marketplaceProductCategorySchema,
  medicalForm: medicalFormSchema,
  medicalFormClassification: medicalFormClassificationSchema,
  medicalMessage: medicalMessageSchema,
  optimaAction: optimaActionSchema,
  optimaClinician: optimaClinicianSchema,
  optimaEncounterType: optimaEncounterTypeSchema,
  patient: patientSchema,
  payer: payerSchema,
  permission: permissionSchema,
  permissionGroup: permissionGroupSchema,
  riskFactorTemplate: riskFactorTemplateSchema,
  sadaVideo: sadaVideoSchema,
  subList: subListSchema,
  subscriptionPlan: subscriptionPlanSchema,
  systemCode: systemCodeSchema,
  user: userSchema,
  userEmail: userEmailSchema,
  vendor: vendorSchema,
  vendorDepartment: vendorDepartmentSchema,
};
