import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowDocumentCreateMutationVariables = Types.Exact<{
  chatFlowId: Types.Scalars['ID']['input'];
  input?: Types.InputMaybe<Types.ChatFlowDocumentInput>;
  documentStoreId: Types.Scalars['ID']['input'];
}>;


export type ChatFlowDocumentCreateMutation = { __typename?: 'Mutation', chatFlowDocumentCreate?: { __typename?: 'ChatFlowDocument', createdAt: any, fileName: string, fileSize: number, fileType: Types.FileType, id: string } | null };


export const ChatFlowDocumentCreateDocument = gql`
    mutation ChatFlowDocumentCreate($chatFlowId: ID!, $input: ChatFlowDocumentInput, $documentStoreId: ID!) {
  chatFlowDocumentCreate(
    chatFlowId: $chatFlowId
    input: $input
    documentStoreId: $documentStoreId
  ) {
    createdAt
    fileName
    fileSize
    fileType
    id
  }
}
    `;
export type ChatFlowDocumentCreateMutationFn = Apollo.MutationFunction<ChatFlowDocumentCreateMutation, ChatFlowDocumentCreateMutationVariables>;

/**
 * __useChatFlowDocumentCreateMutation__
 *
 * To run a mutation, you first call `useChatFlowDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowDocumentCreateMutation, { data, loading, error }] = useChatFlowDocumentCreateMutation({
 *   variables: {
 *      chatFlowId: // value for 'chatFlowId'
 *      input: // value for 'input'
 *      documentStoreId: // value for 'documentStoreId'
 *   },
 * });
 */
export function useChatFlowDocumentCreateMutation(baseOptions?: Apollo.MutationHookOptions<ChatFlowDocumentCreateMutation, ChatFlowDocumentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatFlowDocumentCreateMutation, ChatFlowDocumentCreateMutationVariables>(ChatFlowDocumentCreateDocument, options);
      }
export type ChatFlowDocumentCreateMutationHookResult = ReturnType<typeof useChatFlowDocumentCreateMutation>;
export type ChatFlowDocumentCreateMutationResult = Apollo.MutationResult<ChatFlowDocumentCreateMutation>;
export type ChatFlowDocumentCreateMutationOptions = Apollo.BaseMutationOptions<ChatFlowDocumentCreateMutation, ChatFlowDocumentCreateMutationVariables>;