import { IAutocompleteBasicProps } from "@/types";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { BenefitGroup } from "../../schema/types";
import { useHealthProgramBenefitsGroupsAutocompleteQuery } from "./gql";

export const HealthProgramBenefitsGroupsAutocomplete: FC<IAutocompleteBasicProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, loading } = useHealthProgramBenefitsGroupsAutocompleteQuery({
    skip: props.disabled || skip,
  });

  const _data = data?.healthProgramBenefitGroups as BenefitGroup[];

  const options = mapToAutocompleteOptions(_data, "id", "name");

  return (
    <FormAutocomplete
      {...rest}
      loading={loading}
      options={options}
      label={label || (props.multiple ? t("Benefits Groups") : t("Benefit Group"))}
    />
  );
};
