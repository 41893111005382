import { AppRoleTypes, VendorUserTypes } from "@/schema/types";
import { appRoleTypeOptions, appRoleTypeOptionsMap, userGenderOptions, vendorUserTypeOptions } from "@health/enum-options";
import { BACKEND_DATE_FORMAT, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormDatePickerField, FormPhoneInput, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { IUserProviderUpsertForm } from "../UserProviderUpsert/UserProviderUpsertFormSchema";

type UserProviderInformationFormProps = {
  isUpdateMode: boolean;
};

export const UserProviderInformationForm: FC<UserProviderInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("domains");

  const form = useCustomFormContext<IUserProviderUpsertForm>();

  const { watch, setValue } = form;

  const vendorUserType = watch("vendorUserType")?.value;

  useEffect(() => {
    if (!isUpdateMode) {
      if (vendorUserType === VendorUserTypes.Manager) {
        setValue("appRole", appRoleTypeOptionsMap[AppRoleTypes.Admin]);
      } else if (vendorUserType === VendorUserTypes.Rcm) {
        setValue("appRole", appRoleTypeOptionsMap[AppRoleTypes.User]);
      } else {
        setValue("appRole", undefined);
      }
    }
  }, [isUpdateMode, vendorUserType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"firstName"} label={t("First Name")} placeholder={t("First Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"lastName"} label={t("Last Name")} placeholder={t("Last Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nationalId"} label={t("National Id")} placeholder={t("National Id")} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"gender"} label={t("Gender")} options={userGenderOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"email"} label={t("Email")} placeholder={t("Email")} disabled={isUpdateMode} />
      </Grid>

      {!isUpdateMode && (
        <Grid item xs={4}>
          <FormTextField
            name={"password"}
            label={t("Password")}
            placeholder={t("Password")}
            type={"password"}
            autoComplete={"new-password"}
          />
        </Grid>
      )}

      <Grid item xs={4}>
        <FormPhoneInput name={"mobile"} label={t("Mobile Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"dateOfBirth"}
          label={t("Date of Birth")}
          placeholder={t("Date of Birth")}
          format={BACKEND_DATE_FORMAT}
        />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"vendorUserType"} label={t("User Type")} disabled={isUpdateMode} options={vendorUserTypeOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete
          name={"appRole"}
          label={t("App Role")}
          disabled={!vendorUserType || vendorUserType === VendorUserTypes.Manager || vendorUserType === VendorUserTypes.Rcm}
          options={appRoleTypeOptions}
        />
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"isActive"} label={t("Is Active")} />
      </Grid>
    </Grid>
  );
};
