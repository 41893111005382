import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderTrackingLinesQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type GetOrderTrackingLinesQuery = { __typename?: 'Query', marketplaceOrder?: { __typename?: 'MarketplaceOrder', id: string, orderEvents: Array<{ __typename?: 'MarketplaceOrderEvent', date: any, id: string, status: Types.MarketplaceOrderStatus, user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, defaultBranch?: { __typename?: 'Branch', name: string } | null } }> } | null };


export const GetOrderTrackingLinesDocument = gql`
    query getOrderTrackingLines($orderId: ID!) {
  marketplaceOrder(id: $orderId) {
    id
    orderEvents {
      date
      id
      status
      user {
        id
        firstName
        lastName
        defaultBranch {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetOrderTrackingLinesQuery__
 *
 * To run a query within a React component, call `useGetOrderTrackingLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderTrackingLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderTrackingLinesQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderTrackingLinesQuery(baseOptions: Apollo.QueryHookOptions<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables> & ({ variables: GetOrderTrackingLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables>(GetOrderTrackingLinesDocument, options);
      }
export function useGetOrderTrackingLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables>(GetOrderTrackingLinesDocument, options);
        }
export function useGetOrderTrackingLinesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables>(GetOrderTrackingLinesDocument, options);
        }
export type GetOrderTrackingLinesQueryHookResult = ReturnType<typeof useGetOrderTrackingLinesQuery>;
export type GetOrderTrackingLinesLazyQueryHookResult = ReturnType<typeof useGetOrderTrackingLinesLazyQuery>;
export type GetOrderTrackingLinesSuspenseQueryHookResult = ReturnType<typeof useGetOrderTrackingLinesSuspenseQuery>;
export type GetOrderTrackingLinesQueryResult = Apollo.QueryResult<GetOrderTrackingLinesQuery, GetOrderTrackingLinesQueryVariables>;