import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemCodeMaximumQuantityAllowedUpdateMutationVariables = Types.Exact<{
  updateItemCodeMaximumQuantityAllowedId: Types.Scalars['ID']['input'];
  input: Types.ItemCodeMaximumQuantityAllowedInput;
}>;


export type ItemCodeMaximumQuantityAllowedUpdateMutation = { __typename?: 'Mutation', updateItemCodeMaximumQuantityAllowed?: { __typename?: 'ItemCodeMaximumQuantityAllowed', id: string } | null };


export const ItemCodeMaximumQuantityAllowedUpdateDocument = gql`
    mutation ItemCodeMaximumQuantityAllowedUpdate($updateItemCodeMaximumQuantityAllowedId: ID!, $input: ItemCodeMaximumQuantityAllowedInput!) {
  updateItemCodeMaximumQuantityAllowed(
    id: $updateItemCodeMaximumQuantityAllowedId
    input: $input
  ) {
    id
  }
}
    `;
export type ItemCodeMaximumQuantityAllowedUpdateMutationFn = Apollo.MutationFunction<ItemCodeMaximumQuantityAllowedUpdateMutation, ItemCodeMaximumQuantityAllowedUpdateMutationVariables>;

/**
 * __useItemCodeMaximumQuantityAllowedUpdateMutation__
 *
 * To run a mutation, you first call `useItemCodeMaximumQuantityAllowedUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemCodeMaximumQuantityAllowedUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemCodeMaximumQuantityAllowedUpdateMutation, { data, loading, error }] = useItemCodeMaximumQuantityAllowedUpdateMutation({
 *   variables: {
 *      updateItemCodeMaximumQuantityAllowedId: // value for 'updateItemCodeMaximumQuantityAllowedId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemCodeMaximumQuantityAllowedUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ItemCodeMaximumQuantityAllowedUpdateMutation, ItemCodeMaximumQuantityAllowedUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ItemCodeMaximumQuantityAllowedUpdateMutation, ItemCodeMaximumQuantityAllowedUpdateMutationVariables>(ItemCodeMaximumQuantityAllowedUpdateDocument, options);
      }
export type ItemCodeMaximumQuantityAllowedUpdateMutationHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedUpdateMutation>;
export type ItemCodeMaximumQuantityAllowedUpdateMutationResult = Apollo.MutationResult<ItemCodeMaximumQuantityAllowedUpdateMutation>;
export type ItemCodeMaximumQuantityAllowedUpdateMutationOptions = Apollo.BaseMutationOptions<ItemCodeMaximumQuantityAllowedUpdateMutation, ItemCodeMaximumQuantityAllowedUpdateMutationVariables>;