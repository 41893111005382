import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useAddToast } from "@toolkit/ui";
import { useBranchCreateMutation } from "../../gql";
import { convertBranchFormValuesToBackEndValues } from "../../others";
import { BranchUpsertForm } from "../../forms/BranchUpsert/BranchUpsertForm";
import { IBranchUpsertForm } from "../../forms/BranchUpsert/BranchUpsertFormSchema";

type BranchCreateContainerProps = {
  providerId?: string;
  isAdmin?: boolean;
};

export const BranchCreateContainer: FC<BranchCreateContainerProps> = props => {
  const { providerId, isAdmin } = props;

  const { t } = useTranslation("domains");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createBranch, { loading: isSubmitting }] = useBranchCreateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.branchCreate?.branchErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Branch created successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onBranchUpsertFormChange = (values: IBranchUpsertForm) => {
    createBranch({
      variables: {
        providerId,
        input: convertBranchFormValuesToBackEndValues(values),
      },
    });
  };

  return <BranchUpsertForm buttonLabel={t("Create")} isAdmin={isAdmin} isSubmitting={isSubmitting} onChange={onBranchUpsertFormChange} />;
};
