import { camelCase, startCase } from "lodash";
import { ActionFieldType } from "@/schema/types";
import { FormAutocomplete, FormNumberField, FormSwitch, FormTextField } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { isCodeInDecisionActionFieldListCode } from "../../others";
import { DecisionActionFieldListCode } from "../../types";
import { DecisionActionAutocompletesForm } from "../DecisionActionAutocompletes/DecisionActionAutocompletesForm";
import { IDecisionEffectsFormValues } from "@/pages/Decisions/forms/DecisionEffects/DecisionEffectsFormSchema";

type DecisionActionFieldTypeFormProps = {
  index: number;
  item: IDecisionEffectsFormValues;
};

export const DecisionActionFieldTypeForm: FC<DecisionActionFieldTypeFormProps> = props => {
  const { index, item } = props;

  const { t } = useTranslation("domains");

  const label = startCase(camelCase(item?.display! || item?.code!));

  if (item?.type === ActionFieldType.Boolean) {
    return <FormSwitch name={`actionFields.${index}.valueBoolean`} label={label} />;
  } else if (item?.type === ActionFieldType.Number) {
    return <FormNumberField name={`actionFields.${index}.valueNumber`} label={label} isFloatAllowed />;
  } else if (item?.type === ActionFieldType.String) {
    if (isCodeInDecisionActionFieldListCode(item?.code)) {
      return (
        <DecisionActionAutocompletesForm
          name={`actionFields.${index}.valueString`}
          display={label}
          code={item?.code as DecisionActionFieldListCode}
        />
      );
    } else if (item?.allowedValues?.length) {
      return <FormAutocomplete name={`actionFields.${index}.valueString`} label={label} options={item?.allowedValues} />;
    } else {
      return <FormTextField name={`actionFields.${index}.valueString`} label={label} />;
    }
  } else if (item?.type === ActionFieldType.List) {
    return (
      <DecisionActionAutocompletesForm
        name={`actionFields.${index}.valueList`}
        display={label}
        code={item?.code as DecisionActionFieldListCode}
      />
    );
  } else {
    return <>{t("Not Supported Field")}</>;
  }
};
