import { i18n } from "@toolkit/i18n";
import { TimeOfDay } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const timeOfDayOptionsMap: IEnumToOptionsMap<TimeOfDay> = {
  [TimeOfDay.From_0To_2]: {
    key: TimeOfDay.From_0To_2,
    get label() {
      return i18n.t("12 AM - 2 AM", { ns: "domains" });
    },
    value: TimeOfDay.From_0To_2,
  },
  [TimeOfDay.From_2To_4]: {
    key: TimeOfDay.From_2To_4,
    get label() {
      return i18n.t("2 AM - 4 AM", { ns: "domains" });
    },
    value: TimeOfDay.From_2To_4,
  },
  [TimeOfDay.From_4To_6]: {
    key: TimeOfDay.From_4To_6,
    get label() {
      return i18n.t("4 AM - 6 AM", { ns: "domains" });
    },
    value: TimeOfDay.From_4To_6,
  },
  [TimeOfDay.From_6To_8]: {
    key: TimeOfDay.From_6To_8,
    get label() {
      return i18n.t("6 AM - 8 AM", { ns: "domains" });
    },
    value: TimeOfDay.From_6To_8,
  },
  [TimeOfDay.From_8To_10]: {
    key: TimeOfDay.From_8To_10,
    get label() {
      return i18n.t("8 AM - 10 AM", { ns: "domains" });
    },
    value: TimeOfDay.From_8To_10,
  },
  [TimeOfDay.From_10To_12]: {
    key: TimeOfDay.From_10To_12,
    get label() {
      return i18n.t("10 AM - 12 PM", { ns: "domains" });
    },
    value: TimeOfDay.From_10To_12,
  },
  [TimeOfDay.From_12To_14]: {
    key: TimeOfDay.From_12To_14,
    get label() {
      return i18n.t("12 PM - 2 PM", { ns: "domains" });
    },
    value: TimeOfDay.From_12To_14,
  },
  [TimeOfDay.From_14To_16]: {
    key: TimeOfDay.From_14To_16,
    get label() {
      return i18n.t("2 PM - 4 PM", { ns: "domains" });
    },
    value: TimeOfDay.From_14To_16,
  },
  [TimeOfDay.From_16To_18]: {
    key: TimeOfDay.From_16To_18,
    get label() {
      return i18n.t("4 PM - 6 PM", { ns: "domains" });
    },
    value: TimeOfDay.From_16To_18,
  },
  [TimeOfDay.From_18To_20]: {
    key: TimeOfDay.From_18To_20,
    get label() {
      return i18n.t("6 PM - 8 PM", { ns: "domains" });
    },
    value: TimeOfDay.From_18To_20,
  },
  [TimeOfDay.From_20To_22]: {
    key: TimeOfDay.From_20To_22,
    get label() {
      return i18n.t("8 PM - 10 PM", { ns: "domains" });
    },
    value: TimeOfDay.From_20To_22,
  },
  [TimeOfDay.From_22To_24]: {
    key: TimeOfDay.From_22To_24,
    get label() {
      return i18n.t("10 PM - 12 AM", { ns: "domains" });
    },
    value: TimeOfDay.From_22To_24,
  },
};

export const timeOfDayOptions = Object.values(timeOfDayOptionsMap);
