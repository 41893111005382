/* eslint-disable react/jsx-handler-names */
/* eslint-disable sonarjs/cognitive-complexity */
import { useTranslation } from "@toolkit/i18n";
import React, { forwardRef, PropsWithChildren } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, Typography } from "../../base/mui";
import { useInfiniteScrollStyles } from "./CustomInfiniteScroll.styles";
import { InfiniteScrollProps } from "./CustomInfiniteScroll.types";

type InfiniteScrollPropsWithChildren = PropsWithChildren<InfiniteScrollProps>;

export const CustomInfiniteScroll = forwardRef<InfiniteScroll, InfiniteScrollPropsWithChildren>(
  (
    {
      dataLength,
      onFetchMore,
      hasMore,
      inverse,
      customFilter,
      onRefresh,
      loader,
      height,
      scrollableTarget,
      ...rest
    }: InfiniteScrollPropsWithChildren,
    ref: React.ForwardedRef<InfiniteScroll>
  ) => {
    const { classes } = useInfiniteScrollStyles({ inverse });
    const { t } = useTranslation();

    const onHandleFetchMoreData = () => {
      onFetchMore && onFetchMore();
    };

    const onRefreshFunction = () => {
      onRefresh && onRefresh();
    };

    const getHeightValue = () => {
      if (height) {
        return height;
      }
      if (dataLength! <= 5) {
        return undefined;
      } else return !customFilter ? 200 : "100%";
    };
    const heightValue = scrollableTarget ? undefined : height || getHeightValue();

    return (
      <InfiniteScroll
        scrollableTarget={scrollableTarget}
        ref={ref}
        dataLength={dataLength || 0}
        next={onHandleFetchMoreData}
        hasMore={!!hasMore}
        height={heightValue}
        loader={loader || (hasMore && !customFilter && <Typography className={classes.loading}>{t("Loading...")}</Typography>)}
        className={classes.scroll}
        refreshFunction={onRefreshFunction}
        pullDownToRefresh={!inverse}
        initialScrollY={dataLength! > 10 ? (dataLength! - 1) * 16 : 0}
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={<Box textAlign='center'>&#8595; Pull down to refresh</Box>}
        releaseToRefreshContent={<Box textAlign='center'>&#8593; Release to refresh</Box>}
        inverse={inverse}
      >
        <span className={`infinite-scroll-component ${classes.scrollContent}`} {...rest} />
      </InfiniteScroll>
    );
  }
);
