import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientGuidedCareProgramInviteMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AddMemberToProgramInput>;
}>;


export type PatientGuidedCareProgramInviteMutation = { __typename?: 'Mutation', addMemberToProgram?: { __typename?: 'GuidedCareHealthProgram', id: string } | null };


export const PatientGuidedCareProgramInviteDocument = gql`
    mutation PatientGuidedCareProgramInvite($input: AddMemberToProgramInput) {
  addMemberToProgram(input: $input) {
    id
  }
}
    `;
export type PatientGuidedCareProgramInviteMutationFn = Apollo.MutationFunction<PatientGuidedCareProgramInviteMutation, PatientGuidedCareProgramInviteMutationVariables>;

/**
 * __usePatientGuidedCareProgramInviteMutation__
 *
 * To run a mutation, you first call `usePatientGuidedCareProgramInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientGuidedCareProgramInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientGuidedCareProgramInviteMutation, { data, loading, error }] = usePatientGuidedCareProgramInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientGuidedCareProgramInviteMutation(baseOptions?: Apollo.MutationHookOptions<PatientGuidedCareProgramInviteMutation, PatientGuidedCareProgramInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientGuidedCareProgramInviteMutation, PatientGuidedCareProgramInviteMutationVariables>(PatientGuidedCareProgramInviteDocument, options);
      }
export type PatientGuidedCareProgramInviteMutationHookResult = ReturnType<typeof usePatientGuidedCareProgramInviteMutation>;
export type PatientGuidedCareProgramInviteMutationResult = Apollo.MutationResult<PatientGuidedCareProgramInviteMutation>;
export type PatientGuidedCareProgramInviteMutationOptions = Apollo.BaseMutationOptions<PatientGuidedCareProgramInviteMutation, PatientGuidedCareProgramInviteMutationVariables>;