import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { insuranceBenefitApprovalLimitsPaths, insuranceBenefitApprovalLimitsRoute } from "./InsuranceBenefitApprovalLimitPaths";
import {
  InsuranceBenefitApprovalLimitCreateContainer,
  InsuranceBenefitApprovalLimitListContainer,
  InsuranceBenefitApprovalLimitUpdateContainer,
} from "../containers";

export const insuranceBenefitApprovalLimitsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-insurance-benefit-approval-limits-routes",
    text: i18n.t("Insurance Benefit Approval Limits", { ns: "admin" }),
    route: insuranceBenefitApprovalLimitsRoute,
    subItems: [
      {
        id: "optima-insurance-benefit-approval-limit-list-route",
        route: insuranceBenefitApprovalLimitsPaths.list.route,
        fullPath: insuranceBenefitApprovalLimitsPaths.list.fullPath,
        element: <InsuranceBenefitApprovalLimitListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-insurance-benefit-approval-limit-create-route",
        route: insuranceBenefitApprovalLimitsPaths.create.route,
        fullPath: insuranceBenefitApprovalLimitsPaths.create.fullPath,
        hidden: true,
        element: <InsuranceBenefitApprovalLimitCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-insurance-benefit-approval-limit-update-route",
        route: insuranceBenefitApprovalLimitsPaths.update.route,
        fullPath: insuranceBenefitApprovalLimitsPaths.update.fullPath,
        hidden: true,
        element: <InsuranceBenefitApprovalLimitUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
