/* eslint-disable react/jsx-handler-names */
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useGetMarketplaceOrdersQuery } from "@/pages/OrdersTracking/gql/queries";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useMarketplaceOrdersTrackingColumns } from "./useMarketplaceOrdersTrackingColumns";

type MarketplaceOrdersTrackingPageProps = {
  isAdmin?: boolean;
};

export const MarketplaceOrdersTrackingPage: FC<MarketplaceOrdersTrackingPageProps> = props => {
  const { isAdmin = false } = props;

  return (
    <GridProvider
      gridName={"marketplaceOrdersTracking"}
      variables={{
        isAdmin,
      }}
      fetchPolicy={"network-only"}
      nextFetchPolicy={"cache-first"}
      columns={useMarketplaceOrdersTrackingColumns({ isAdmin })}
      query={useGetMarketplaceOrdersQuery}
      hasTableSetting
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
