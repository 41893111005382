import { HealthProgramNetworkProviderProfessional } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { HealthProgramNetworkProviderProfessionalsModal } from "../../modals/HealthProgramNetworkProviderProfessionals/HealthProgramNetworkProviderProfessionalsModal";
import { IHealthProgramNetworkProviderProfessionalNode } from "../../types";

export const useHealthProgramNetworkProviderProfessionalListContainerColumns =
  (): CustomTableColumnProps<IHealthProgramNetworkProviderProfessionalNode>[] => {
    const { t } = useTranslation("admin");

    return useMemo(
      () => [
        {
          key: "id",
          header: t("ID"),
          accessor: "id",
        },
        {
          key: "name",
          header: t("Name"),
          accessor: ({ user }) => user?.fullName,
        },
        {
          key: "specializations",
          header: t("Specializations"),
          accessor: ({ specializations }) => (specializations?.length ? specializations?.map(item => item?.code!).join(", ") : undefined),
        },
        {
          key: "virtualProgram",
          header: t("Virtual Program"),
          accessor: ({ healthProgramNetworkProviderProfessionals }) => {
            const professionals = (healthProgramNetworkProviderProfessionals?.edges?.map(item => item?.node) ||
              []) as HealthProgramNetworkProviderProfessional[];

            return <HealthProgramNetworkProviderProfessionalsModal professionals={professionals} />;
          },
        },
      ],
      [t]
    );
  };
