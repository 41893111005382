import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { QueryHookOptions, QueryResult } from "@apollo/client";
import { IAutocompleteProps } from "@/types";
import { AppTypes, PermissionGroupFilterInput } from "@/schema/types";
import {
  PermissionsGroupsAutocompleteQuery,
  PermissionsGroupsAutocompleteQueryVariables,
  usePermissionsGroupsAutocompleteQuery,
} from "./gql";

type IPermissionsGroupsAutocompleteFilter = PermissionGroupFilterInput & {
  vendorId?: string;
  appType: AppTypes;
};

type PermissionsGroupsAutocompleteProps = IAutocompleteProps<IPermissionsGroupsAutocompleteFilter>;

export const PermissionsGroupsAutocomplete: FC<PermissionsGroupsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { vendorId, appType, ...restFilter } = filter || {};

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: usePermissionsGroupsAutocompleteQuery as (
      baseOptions?: QueryHookOptions<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>
    ) => QueryResult<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>,
    variables: { filter: restFilter, vendorId, appType: appType! },
    searchKey: "search",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Permissions Groups") : t("Permission Group"))}
    />
  );
};
