import { ProcedureAge, ProcedureAgeInput } from "@/schema/types";
import { IProcedureAgeUpsertFormValues } from "../forms/ProcedureAgeInformationUpsert/ProcedureAgeInformationUpsertFormSchema";

export const convertProcedureAgeToFormValues = (procedureAge: ProcedureAge): IProcedureAgeUpsertFormValues => {
  return {
    procedureCode: procedureAge.procedureCode,
    minAgeDays: procedureAge.minAgeDays,
    maxAgeDays: procedureAge.maxAgeDays,
    description: procedureAge.description ?? undefined,
  };
};

export const convertProcedureAgeFormValuesToBackEndValues = (values: IProcedureAgeUpsertFormValues): ProcedureAgeInput => {
  return {
    procedureCode: values.procedureCode,
    minAgeDays: values.minAgeDays,
    maxAgeDays: values.maxAgeDays,
    description: values.description,
  };
};
