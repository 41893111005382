import { useTranslation } from "@toolkit/i18n";
import { FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { FC } from "react";

export const ItemCodeMaximumQuantityAllowedInformationForm: FC = () => {
  const { t } = useTranslation("admin");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"itemCode"} label={t("Item Code")} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"codeSystem"} label={t("Code System")} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormNumberField name={"maximumAllowed"} label={t("Maximum Allowed")} />
        </Grid>
      </Grid>
      <Grid item xs={6} mt={2}>
        <FormTextField name='description' label={t("Description")} multiline maxRows={3} minRows={3} />
      </Grid>
    </>
  );
};
