import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionTaskDefinitionsAutocompleteQuery } from "../../gql";

type DecisionTaskDefinitionsAutocompleteProps = {
  name: string;
  label?: string;
};

export const DecisionTaskDefinitionsAutocomplete: FC<DecisionTaskDefinitionsAutocompleteProps> = props => {
  const { name, label } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionTaskDefinitionsAutocompleteQuery,
    searchKey: "code",
  });

  const options = mapToAutocompleteOptions(data, "id", item => `${item?.id} ${pickLocalizedValue(item?.name, item?.nameAr)}`);

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={label || t("Task Definition")} />;
};
