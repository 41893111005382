import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { diagnosisValidProceduresPaths, diagnosisValidProceduresRoute } from "./DiagnosisValidProcedurePaths";
import { DiagnosisValidProcedureCreateContainer, DiagnosisValidProcedureUpdateContainer } from "../containers";
import { DiagnosisValidProcedureListContainer } from "../containers/DiagnosisValidProceduresList/DiagnosisValidProcedureListContainer";

export const diagnosisValidProceduresRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-diagnosis-valid-procedures-routes",
    text: i18n.t("Diagnosis Valid Procedures", { ns: "admin" }),
    route: diagnosisValidProceduresRoute,
    subItems: [
      {
        id: "optima-diagnosis-valid-procedure-list-route",
        route: diagnosisValidProceduresPaths.list.route,
        fullPath: diagnosisValidProceduresPaths.list.fullPath,
        element: <DiagnosisValidProcedureListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-diagnosis-valid-procedure-create-route",
        route: diagnosisValidProceduresPaths.create.route,
        fullPath: diagnosisValidProceduresPaths.create.fullPath,
        hidden: true,
        element: <DiagnosisValidProcedureCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-diagnosis-valid-procedure-update-route",
        route: diagnosisValidProceduresPaths.update.route,
        fullPath: diagnosisValidProceduresPaths.update.fullPath,
        hidden: true,
        element: <DiagnosisValidProcedureUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
