import { CallbackRequestUpdateHistory } from "@/schema/types";
import { Box, CustomTable, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useRequestProcessingLog } from "./RequestProcessingLog.hook";

export const RequestProcessingLog: FC<{ id?: CallbackRequestUpdateHistory["id"] }> = ({ id }) => {
  const { t, tableData, hasNextPage, hasPreviousPage, isLoading, handleGotoNext, handleGoToPrevious, pageSize, columns } =
    useRequestProcessingLog(id);
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
          {t("Request Processing Log")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <CustomTable
          isLoading={isLoading}
          data={tableData}
          columns={columns}
          pageSize={pageSize}
          pageIndex={0}
          pagesCount={pageSize}
          hasFooter={true}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onGoToNext={handleGotoNext}
          onGoToPrevious={handleGoToPrevious}
          TableContainerProps={{
            sx: {
              height: 237.5,
            },
          }}
          withoutDataMessage={t("No Processing Log")}
          emptyIconHeight={100}
        />
      </Box>
    </Box>
  );
};
