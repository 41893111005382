import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionsGroupsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  vendorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  appType: Types.AppTypes;
  filter?: Types.InputMaybe<Types.PermissionGroupFilterInput>;
  sortBy?: Types.InputMaybe<Types.PermissionGroupSortingInput>;
}>;


export type PermissionsGroupsAutocompleteQuery = { __typename?: 'Query', permissionGroups?: { __typename?: 'GroupCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'GroupCountableEdge', node: { __typename?: 'Group', id: string, name: string } }> } | null };


export const PermissionsGroupsAutocompleteDocument = gql`
    query PermissionsGroupsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $vendorId: ID, $appType: AppTypes!, $filter: PermissionGroupFilterInput, $sortBy: PermissionGroupSortingInput) {
  permissionGroups(
    first: $first
    last: $last
    after: $after
    before: $before
    vendorId: $vendorId
    appType: $appType
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __usePermissionsGroupsAutocompleteQuery__
 *
 * To run a query within a React component, call `usePermissionsGroupsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsGroupsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsGroupsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      vendorId: // value for 'vendorId'
 *      appType: // value for 'appType'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePermissionsGroupsAutocompleteQuery(baseOptions: Apollo.QueryHookOptions<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables> & ({ variables: PermissionsGroupsAutocompleteQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>(PermissionsGroupsAutocompleteDocument, options);
      }
export function usePermissionsGroupsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>(PermissionsGroupsAutocompleteDocument, options);
        }
export function usePermissionsGroupsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>(PermissionsGroupsAutocompleteDocument, options);
        }
export type PermissionsGroupsAutocompleteQueryHookResult = ReturnType<typeof usePermissionsGroupsAutocompleteQuery>;
export type PermissionsGroupsAutocompleteLazyQueryHookResult = ReturnType<typeof usePermissionsGroupsAutocompleteLazyQuery>;
export type PermissionsGroupsAutocompleteSuspenseQueryHookResult = ReturnType<typeof usePermissionsGroupsAutocompleteSuspenseQuery>;
export type PermissionsGroupsAutocompleteQueryResult = Apollo.QueryResult<PermissionsGroupsAutocompleteQuery, PermissionsGroupsAutocompleteQueryVariables>;