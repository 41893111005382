import { FormAutocomplete, FormDatePickerField, FormPhoneInput, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { vendorTypeOptions } from "@health/enum-options";
import { BACKEND_DATE_FORMAT, useCustomFormContext } from "@toolkit/core";
import moment from "moment/moment";
import { IBranchUpsertForm } from "../BranchUpsert/BranchUpsertFormSchema";

export const BranchInformationForm = () => {
  const { t } = useTranslation("domains");

  const form = useCustomFormContext<IBranchUpsertForm>();

  const healthLicenseStartDate = form.watch("healthLicenseStartDate");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"type"} label={t("Type")} options={vendorTypeOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"healthLicenseNumber"} label={t("Health License Number")} placeholder={t("Health License Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"healthLicenseStartDate"}
          label={t("Health License Start Date")}
          placeholder={t("Health License Start Date")}
          format={BACKEND_DATE_FORMAT}
        />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"healthLicenseEndDate"}
          label={t("Health License End Date")}
          placeholder={t("Health License End Date")}
          format={BACKEND_DATE_FORMAT}
          datePickerProps={{
            minDate: healthLicenseStartDate ? moment(healthLicenseStartDate, BACKEND_DATE_FORMAT).add(1, "days") : undefined,
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"email"} label={t("Email")} placeholder={t("Email")} />
      </Grid>

      <Grid item xs={4}>
        <FormPhoneInput name={"mobileNumber"} label={t("Mobile Number")} />
      </Grid>

      <Grid item xs={12}>
        <FormTextField name={"description"} multiline rows={4} label={t("Description")} placeholder={t("Description")} />
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"isActive"} label={t("Active")} />
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"acceptsDelivery"} label={t("Accepts Delivery")} />
      </Grid>
    </Grid>
  );
};
