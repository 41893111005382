import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetCodeSystemQuery = { __typename?: 'Query', getCodeSystem?: { __typename?: 'CodeSystem', id?: string | null, processingStatus?: Types.ProcessingStatus | null, version?: number | null, codeSystemHistory?: Array<{ __typename?: 'CodeSystemHistory', processingStatus?: Types.ProcessingStatus | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null, codeSystemDefinition?: { __typename?: 'CodeSystemDefinition', id?: string | null, code?: Types.CodeSystemCode | null, display?: string | null, isEditable?: boolean | null, editorUserId?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, reviewerUserId?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, codeSystemConceptFields?: Array<{ __typename?: 'CodeSystemConceptFieldDefinition', code?: string | null, display?: string | null, fieldType?: Types.Type | null, isMandatory?: boolean | null } | null> | null } | null } | null };


export const GetCodeSystemDocument = gql`
    query getCodeSystem($id: ID!) {
  getCodeSystem(id: $id) {
    id
    processingStatus
    version
    codeSystemHistory {
      user {
        id
        firstName
        lastName
      }
      processingStatus
    }
    codeSystemDefinition {
      id
      code
      display
      isEditable
      editorUserId {
        id
        firstName
        lastName
        __typename
      }
      reviewerUserId {
        id
        firstName
        lastName
        __typename
      }
      codeSystemConceptFields {
        code
        display
        fieldType
        isMandatory
      }
    }
  }
}
    `;

/**
 * __useGetCodeSystemQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCodeSystemQuery(baseOptions: Apollo.QueryHookOptions<GetCodeSystemQuery, GetCodeSystemQueryVariables> & ({ variables: GetCodeSystemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemQuery, GetCodeSystemQueryVariables>(GetCodeSystemDocument, options);
      }
export function useGetCodeSystemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemQuery, GetCodeSystemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemQuery, GetCodeSystemQueryVariables>(GetCodeSystemDocument, options);
        }
export function useGetCodeSystemSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCodeSystemQuery, GetCodeSystemQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCodeSystemQuery, GetCodeSystemQueryVariables>(GetCodeSystemDocument, options);
        }
export type GetCodeSystemQueryHookResult = ReturnType<typeof useGetCodeSystemQuery>;
export type GetCodeSystemLazyQueryHookResult = ReturnType<typeof useGetCodeSystemLazyQuery>;
export type GetCodeSystemSuspenseQueryHookResult = ReturnType<typeof useGetCodeSystemSuspenseQuery>;
export type GetCodeSystemQueryResult = Apollo.QueryResult<GetCodeSystemQuery, GetCodeSystemQueryVariables>;