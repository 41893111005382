import { GuidedCareHealthProgramRiskFactorTemplateEvaluation } from "@/schema/types";
import React, { FC } from "react";
import { Button, CircularProgress, Grid, useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { usePatientGuidedCareProgramEvaluateMutation } from "../../gql";
import { convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues } from "../../others";
import { PatientGuidedCareProgramEvaluationResult } from "../../components/PatientGuidedCareProgramEvaluationResult/PatientGuidedCareProgramEvaluationResult";
import {
  PatientGuidedCareProgramEvaluationFieldsForm,
  IPatientGuidedCareProgramEvaluationFieldsFormEvent,
} from "../PatientGuidedCareProgramEvaluationFields/PatientGuidedCareProgramEvaluationFieldsForm";
import { IPatientGuidedCareProgramMemberAddForm } from "pages/Patients/forms/PatientGuidedCareProgramMemberAdd/PatientGuidedCareProgramMemberAddFormSchema";

export type IPatientGuidedCareProgramEvaluationEvent =
  | IPatientGuidedCareProgramEvaluationFieldsFormEvent
  | {
      type: "EVALUATE_RESULT";
      payload: {
        value: GuidedCareHealthProgramRiskFactorTemplateEvaluation;
      };
    };

type PatientGuidedCareProgramEvaluationProps = {
  visitId?: string;
  memberId?: string;
  isEvaluateButtonShown?: boolean;
  evaluationResult?: GuidedCareHealthProgramRiskFactorTemplateEvaluation;
  onChange: (event: IPatientGuidedCareProgramEvaluationEvent) => void;
};

export const PatientGuidedCareProgramEvaluation: FC<PatientGuidedCareProgramEvaluationProps> = props => {
  const { visitId, memberId, isEvaluateButtonShown, evaluationResult, onChange } = props;

  const { t } = useTranslation("domains");

  const { failed } = useAddToast();

  const form = useCustomFormContext<IPatientGuidedCareProgramMemberAddForm>();

  const { watch, trigger, isFormDisabled } = form;

  const program = watch("program");
  const riskFactorTemplateFields = watch("riskFactorTemplateFields");

  const [evaluateGuidedCareProgram, { loading }] = usePatientGuidedCareProgramEvaluateMutation({
    onCompleted: mutationData => {
      if (mutationData?.evaluateGuidedCareProgramRiskFactorTemplate) {
        onChange({
          type: "EVALUATE_RESULT",
          payload: {
            value: mutationData?.evaluateGuidedCareProgramRiskFactorTemplate,
          },
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onEvaluateClick = async () => {
    const isValid = await trigger(["riskFactorTemplateFields"]);

    if (!isValid) return;

    evaluateGuidedCareProgram({
      variables: {
        input: {
          visitId,
          memberId,
          programId: program?.value?.id,
          result: convertGuidedCareProgramRiskFactorTemplateFieldsValuesToBackEndValues(riskFactorTemplateFields),
        },
      },
    });
  };

  const onGuidedCareProgramEvaluationFieldsChange = (event: IPatientGuidedCareProgramEvaluationFieldsFormEvent) => {
    if (event.type === "EDIT_CLICK") {
      onChange(event);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PatientGuidedCareProgramEvaluationFieldsForm
          fields={riskFactorTemplateFields}
          hasEvaluationResult={!!evaluationResult}
          onChange={onGuidedCareProgramEvaluationFieldsChange}
        />
      </Grid>

      {evaluationResult && (
        <Grid item xs={12}>
          <PatientGuidedCareProgramEvaluationResult evaluationResult={evaluationResult} />
        </Grid>
      )}

      {isEvaluateButtonShown && (
        <Grid item xs={12}>
          <Button
            variant={"contained"}
            disabled={loading || !!evaluationResult || isFormDisabled}
            endIcon={loading ? <CircularProgress color={"inherit"} size={18} /> : null}
            onClick={onEvaluateClick}
          >
            {t("Evaluate")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
