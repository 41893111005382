import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useUserCreateMutation } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { convertUserAdminCreateFormValuesToBackEndValues } from "../../others";
import { useSetUsersAdminsBreadcrumbs } from "../../hooks";
import { usersAdminsPaths } from "../../constants";
import { UserAdminUpsertForm, IUserAdminUpsertFormEvent } from "../../forms/UserAdminUpsert/UserAdminUpsertForm";

export const UserAdminCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createUser, { loading: isSubmitting }] = useUserCreateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.userCreate?.accountErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("User created successfully"));
        navigate(usersAdminsPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onUserAdminUpsertFormChange = (event: IUserAdminUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertUserAdminCreateFormValuesToBackEndValues(event.payload.values);

      createUser({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetUsersAdminsBreadcrumbs("CREATE");

  return <UserAdminUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onUserAdminUpsertFormChange} />;
};
