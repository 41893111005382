import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserNationalIdGetQueryVariables = Types.Exact<{
  nationalId: Types.Scalars['String']['input'];
}>;


export type UserNationalIdGetQuery = { __typename?: 'Query', getClinician?: { __typename?: 'Clinician', nationalId?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, tempPassword?: string | null, license?: string | null, licenseStart?: any | null, licenseEnd?: any | null } | null };


export const UserNationalIdGetDocument = gql`
    query UserNationalIdGet($nationalId: String!) {
  getClinician(nationalId: $nationalId) {
    nationalId
    firstName
    lastName
    email
    phone
    tempPassword
    license
    licenseStart
    licenseEnd
  }
}
    `;

/**
 * __useUserNationalIdGetQuery__
 *
 * To run a query within a React component, call `useUserNationalIdGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNationalIdGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNationalIdGetQuery({
 *   variables: {
 *      nationalId: // value for 'nationalId'
 *   },
 * });
 */
export function useUserNationalIdGetQuery(baseOptions: Apollo.QueryHookOptions<UserNationalIdGetQuery, UserNationalIdGetQueryVariables> & ({ variables: UserNationalIdGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNationalIdGetQuery, UserNationalIdGetQueryVariables>(UserNationalIdGetDocument, options);
      }
export function useUserNationalIdGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNationalIdGetQuery, UserNationalIdGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNationalIdGetQuery, UserNationalIdGetQueryVariables>(UserNationalIdGetDocument, options);
        }
export function useUserNationalIdGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserNationalIdGetQuery, UserNationalIdGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserNationalIdGetQuery, UserNationalIdGetQueryVariables>(UserNationalIdGetDocument, options);
        }
export type UserNationalIdGetQueryHookResult = ReturnType<typeof useUserNationalIdGetQuery>;
export type UserNationalIdGetLazyQueryHookResult = ReturnType<typeof useUserNationalIdGetLazyQuery>;
export type UserNationalIdGetSuspenseQueryHookResult = ReturnType<typeof useUserNationalIdGetSuspenseQuery>;
export type UserNationalIdGetQueryResult = Apollo.QueryResult<UserNationalIdGetQuery, UserNationalIdGetQueryVariables>;