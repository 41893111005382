import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackageCategoryCreateMutationVariables = Types.Exact<{
  input: Types.MarketplaceCategoryInput;
}>;


export type MarketplaceHealthPackageCategoryCreateMutation = { __typename?: 'Mutation', marketplaceHealthPackageCategoryCreate?: { __typename?: 'MarketplaceHealthPackageCategory', description?: string | null, descriptionAr?: string | null, id: string, image?: string | null, name?: string | null, nameAr?: string | null, parent?: { __typename?: 'MarketplaceHealthPackageCategory', id: string } | null } | null };


export const MarketplaceHealthPackageCategoryCreateDocument = gql`
    mutation MarketplaceHealthPackageCategoryCreate($input: MarketplaceCategoryInput!) {
  marketplaceHealthPackageCategoryCreate(input: $input) {
    description
    descriptionAr
    id
    image
    name
    nameAr
    parent {
      id
    }
  }
}
    `;
export type MarketplaceHealthPackageCategoryCreateMutationFn = Apollo.MutationFunction<MarketplaceHealthPackageCategoryCreateMutation, MarketplaceHealthPackageCategoryCreateMutationVariables>;

/**
 * __useMarketplaceHealthPackageCategoryCreateMutation__
 *
 * To run a mutation, you first call `useMarketplaceHealthPackageCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackageCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceHealthPackageCategoryCreateMutation, { data, loading, error }] = useMarketplaceHealthPackageCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceHealthPackageCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceHealthPackageCategoryCreateMutation, MarketplaceHealthPackageCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceHealthPackageCategoryCreateMutation, MarketplaceHealthPackageCategoryCreateMutationVariables>(MarketplaceHealthPackageCategoryCreateDocument, options);
      }
export type MarketplaceHealthPackageCategoryCreateMutationHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoryCreateMutation>;
export type MarketplaceHealthPackageCategoryCreateMutationResult = Apollo.MutationResult<MarketplaceHealthPackageCategoryCreateMutation>;
export type MarketplaceHealthPackageCategoryCreateMutationOptions = Apollo.BaseMutationOptions<MarketplaceHealthPackageCategoryCreateMutation, MarketplaceHealthPackageCategoryCreateMutationVariables>;