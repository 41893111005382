import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamMembersUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GuidedCareTeamMembersUsersQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeam?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', teamMembers?: Array<{ __typename?: 'ProviderGuidedCareProgramTeamMember', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null> | null } | null };


export const GuidedCareTeamMembersUsersDocument = gql`
    query GuidedCareTeamMembersUsers($id: ID!) {
  providerGuidedCareHealthProgramTeam(id: $id) {
    teamMembers {
      id
      user {
        id
        fullName
      }
    }
  }
}
    `;

/**
 * __useGuidedCareTeamMembersUsersQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamMembersUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamMembersUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamMembersUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGuidedCareTeamMembersUsersQuery(baseOptions: Apollo.QueryHookOptions<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables> & ({ variables: GuidedCareTeamMembersUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables>(GuidedCareTeamMembersUsersDocument, options);
      }
export function useGuidedCareTeamMembersUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables>(GuidedCareTeamMembersUsersDocument, options);
        }
export function useGuidedCareTeamMembersUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables>(GuidedCareTeamMembersUsersDocument, options);
        }
export type GuidedCareTeamMembersUsersQueryHookResult = ReturnType<typeof useGuidedCareTeamMembersUsersQuery>;
export type GuidedCareTeamMembersUsersLazyQueryHookResult = ReturnType<typeof useGuidedCareTeamMembersUsersLazyQuery>;
export type GuidedCareTeamMembersUsersSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTeamMembersUsersSuspenseQuery>;
export type GuidedCareTeamMembersUsersQueryResult = Apollo.QueryResult<GuidedCareTeamMembersUsersQuery, GuidedCareTeamMembersUsersQueryVariables>;