import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberListFilterInput>;
}>;


export type HealthProgramMemberListsAutocompleteQuery = { __typename?: 'Query', healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberListCountableEdge', node: { __typename?: 'HealthProgramMemberList', id: string, name?: string | null } }> } | null };


export const HealthProgramMemberListsAutocompleteDocument = gql`
    query HealthProgramMemberListsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramMemberListFilterInput) {
  healthProgramMemberLists(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useHealthProgramMemberListsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberListsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberListsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramMemberListsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>(HealthProgramMemberListsAutocompleteDocument, options);
      }
export function useHealthProgramMemberListsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>(HealthProgramMemberListsAutocompleteDocument, options);
        }
export function useHealthProgramMemberListsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>(HealthProgramMemberListsAutocompleteDocument, options);
        }
export type HealthProgramMemberListsAutocompleteQueryHookResult = ReturnType<typeof useHealthProgramMemberListsAutocompleteQuery>;
export type HealthProgramMemberListsAutocompleteLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberListsAutocompleteLazyQuery>;
export type HealthProgramMemberListsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useHealthProgramMemberListsAutocompleteSuspenseQuery>;
export type HealthProgramMemberListsAutocompleteQueryResult = Apollo.QueryResult<HealthProgramMemberListsAutocompleteQuery, HealthProgramMemberListsAutocompleteQueryVariables>;