import { forEach, reduce } from "lodash";
import { CustomTableColumnProps } from "./types";

export const parseViewPreferences = <RowType extends object>(
  data: {
    consumerViewPreference: { data: string };
  },
  originalColumns: CustomTableColumnProps<RowType>[]
): { columns: CustomTableColumnProps<RowType>[] } => {
  if (data?.consumerViewPreference?.data && JSON.parse(data?.consumerViewPreference?.data)) {
    try {
      return JSON.parse(data?.consumerViewPreference?.data);
    } catch {
      return {
        columns: originalColumns.filter(r => !r.showOnlyForFilterField && !r.isHidden && !r?.settings?.hideFromPreferencesAndTable),
      };
    }
  }
  return { columns: originalColumns.filter(r => !r.showOnlyForFilterField && !r.isHidden && !r?.settings?.hideFromPreferencesAndTable) };
};
export const getColumnsForBackend = (displayedColumns: CustomTableColumnProps<any>[], initialColumns: CustomTableColumnProps<any>[]) => {
  const nonDisplayedColumnsIndices = reduce(
    initialColumns,
    (r, item, i) => (item?.settings?.hideFromPreferencesAndTable && r.push(i), r),
    [] as number[]
  );
  const allColumns = [...displayedColumns];
  forEach(nonDisplayedColumnsIndices, index => {
    allColumns.splice(index, 0, initialColumns[index]);
  });
  return allColumns.map(item => {
    return { key: item?.key, isHidden: item?.isHidden };
  });
};
