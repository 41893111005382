import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentDoctorAvailableTimeSlotListQueryVariables = Types.Exact<{
  doctorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  end?: Types.InputMaybe<Types.Scalars['Instant']['input']>;
  start?: Types.InputMaybe<Types.Scalars['Instant']['input']>;
}>;


export type AppointmentDoctorAvailableTimeSlotListQuery = { __typename?: 'Query', doctor?: { __typename?: 'Doctor', availableTimeSlots?: Array<{ __typename?: 'TimeSlot', startTime?: any | null, endTime?: any | null } | null> | null } | null };


export const AppointmentDoctorAvailableTimeSlotListDocument = gql`
    query AppointmentDoctorAvailableTimeSlotList($doctorId: ID, $end: Instant, $start: Instant) {
  doctor(id: $doctorId) {
    availableTimeSlots(end: $end, start: $start) {
      startTime
      endTime
    }
  }
}
    `;

/**
 * __useAppointmentDoctorAvailableTimeSlotListQuery__
 *
 * To run a query within a React component, call `useAppointmentDoctorAvailableTimeSlotListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDoctorAvailableTimeSlotListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentDoctorAvailableTimeSlotListQuery({
 *   variables: {
 *      doctorId: // value for 'doctorId'
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAppointmentDoctorAvailableTimeSlotListQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>(AppointmentDoctorAvailableTimeSlotListDocument, options);
      }
export function useAppointmentDoctorAvailableTimeSlotListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>(AppointmentDoctorAvailableTimeSlotListDocument, options);
        }
export function useAppointmentDoctorAvailableTimeSlotListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>(AppointmentDoctorAvailableTimeSlotListDocument, options);
        }
export type AppointmentDoctorAvailableTimeSlotListQueryHookResult = ReturnType<typeof useAppointmentDoctorAvailableTimeSlotListQuery>;
export type AppointmentDoctorAvailableTimeSlotListLazyQueryHookResult = ReturnType<typeof useAppointmentDoctorAvailableTimeSlotListLazyQuery>;
export type AppointmentDoctorAvailableTimeSlotListSuspenseQueryHookResult = ReturnType<typeof useAppointmentDoctorAvailableTimeSlotListSuspenseQuery>;
export type AppointmentDoctorAvailableTimeSlotListQueryResult = Apollo.QueryResult<AppointmentDoctorAvailableTimeSlotListQuery, AppointmentDoctorAvailableTimeSlotListQueryVariables>;