import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthProgramNetworkProviderFilterInput>;
}>;


export type HealthProgramNetworkProviderGetQuery = { __typename?: 'Query', healthProgramNetworkProviders?: { __typename?: 'HealthProgramNetworkProviderCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkProviderCountableEdge', node: { __typename?: 'HealthProgramNetworkProvider', id: string, services?: Array<string | null> | null, isActive?: boolean | null, virtualCallEnabled?: boolean | null, healthProgramNetwork?: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null } | null, provider?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } | null } }> } | null };


export const HealthProgramNetworkProviderGetDocument = gql`
    query HealthProgramNetworkProviderGet($filter: HealthProgramNetworkProviderFilterInput) {
  healthProgramNetworkProviders(first: 1, filter: $filter) {
    edges {
      node {
        id
        services
        isActive
        virtualCallEnabled
        healthProgramNetwork {
          id
          name
        }
        provider {
          id
          name
          nameAr
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkProviderGetQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkProviderGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkProviderGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderGetQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>(HealthProgramNetworkProviderGetDocument, options);
      }
export function useHealthProgramNetworkProviderGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>(HealthProgramNetworkProviderGetDocument, options);
        }
export function useHealthProgramNetworkProviderGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>(HealthProgramNetworkProviderGetDocument, options);
        }
export type HealthProgramNetworkProviderGetQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderGetQuery>;
export type HealthProgramNetworkProviderGetLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderGetLazyQuery>;
export type HealthProgramNetworkProviderGetSuspenseQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderGetSuspenseQuery>;
export type HealthProgramNetworkProviderGetQueryResult = Apollo.QueryResult<HealthProgramNetworkProviderGetQuery, HealthProgramNetworkProviderGetQueryVariables>;