import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubListsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.SubListSortingInput>;
  filter?: Types.InputMaybe<Types.SublistFilterInput>;
}>;


export type SubListsAutocompleteQuery = { __typename?: 'Query', getSubLists?: { __typename?: 'SubListConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'SubListEdge', node?: { __typename?: 'SubList', id?: string | null, display?: string | null } | null } | null> | null } | null };


export const SubListsAutocompleteDocument = gql`
    query SubListsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $sortBy: SubListSortingInput, $filter: SublistFilterInput) {
  getSubLists(
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        display
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useSubListsAutocompleteQuery__
 *
 * To run a query within a React component, call `useSubListsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubListsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubListsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSubListsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>(SubListsAutocompleteDocument, options);
      }
export function useSubListsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>(SubListsAutocompleteDocument, options);
        }
export function useSubListsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>(SubListsAutocompleteDocument, options);
        }
export type SubListsAutocompleteQueryHookResult = ReturnType<typeof useSubListsAutocompleteQuery>;
export type SubListsAutocompleteLazyQueryHookResult = ReturnType<typeof useSubListsAutocompleteLazyQuery>;
export type SubListsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useSubListsAutocompleteSuspenseQuery>;
export type SubListsAutocompleteQueryResult = Apollo.QueryResult<SubListsAutocompleteQuery, SubListsAutocompleteQueryVariables>;