import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorDepartmentsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.VendorDepartmentFilterInput>;
}>;


export type VendorDepartmentsAutocompleteQuery = { __typename?: 'Query', vendorDepartments?: { __typename?: 'DepartmentConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'DepartmentEdge', node?: { __typename?: 'Department', id: string, name: string } | null } | null> } | null };


export const VendorDepartmentsAutocompleteDocument = gql`
    query VendorDepartmentsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: VendorDepartmentFilterInput) {
  vendorDepartments(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useVendorDepartmentsAutocompleteQuery__
 *
 * To run a query within a React component, call `useVendorDepartmentsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorDepartmentsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorDepartmentsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVendorDepartmentsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>(VendorDepartmentsAutocompleteDocument, options);
      }
export function useVendorDepartmentsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>(VendorDepartmentsAutocompleteDocument, options);
        }
export function useVendorDepartmentsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>(VendorDepartmentsAutocompleteDocument, options);
        }
export type VendorDepartmentsAutocompleteQueryHookResult = ReturnType<typeof useVendorDepartmentsAutocompleteQuery>;
export type VendorDepartmentsAutocompleteLazyQueryHookResult = ReturnType<typeof useVendorDepartmentsAutocompleteLazyQuery>;
export type VendorDepartmentsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useVendorDepartmentsAutocompleteSuspenseQuery>;
export type VendorDepartmentsAutocompleteQueryResult = Apollo.QueryResult<VendorDepartmentsAutocompleteQuery, VendorDepartmentsAutocompleteQueryVariables>;