import { HealthProgramMember } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertHealthProgramMemberToFormValues } from "../../others";
import { HealthProgramMemberInformationForm } from "../HealthProgramMemberInformation/HealthProgramMemberInformationForm";
import { HealthProgramMemberInsuranceForm } from "../HealthProgramMemberInsurance/HealthProgramMemberInsuranceForm";
import {
  healthProgramMemberUpsertFormDefaultValues,
  IHealthProgramMemberUpsertFormValues,
  healthProgramMemberUpsertFormSchema,
} from "./HealthProgramMemberUpsertFormSchema";

export type IHealthProgramMemberUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthProgramMemberUpsertFormValues;
  };
};

type HealthProgramMemberUpsertFormProps = {
  healthProgramMember?: HealthProgramMember;
  memberListId: string | null;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IHealthProgramMemberUpsertFormEvent) => void;
};

export const HealthProgramMemberUpsertForm: FC<HealthProgramMemberUpsertFormProps> = props => {
  const { healthProgramMember, memberListId, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("admin");

  const isUpdateMode = !!healthProgramMember;

  const form = useCustomForm<IHealthProgramMemberUpsertFormValues>({
    defaultValues: healthProgramMemberUpsertFormDefaultValues,
    schema: healthProgramMemberUpsertFormSchema(isUpdateMode, memberListId),
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IHealthProgramMemberUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (healthProgramMember) {
      const _healthProgramMember = convertHealthProgramMemberToFormValues(healthProgramMember);
      setValues(_healthProgramMember);
    }
  }, [healthProgramMember, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Member Information")} loading={isLoading} doYouHaveData>
                <HealthProgramMemberInformationForm isUpdateMode={!!healthProgramMember} memberListId={memberListId} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Insurance Information")} loading={isLoading} doYouHaveData>
                <HealthProgramMemberInsuranceForm isUpdateMode={!!healthProgramMember} />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
