import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderBreadcrumbGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProviderBreadcrumbGetQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } | null };


export const ProviderBreadcrumbGetDocument = gql`
    query ProviderBreadcrumbGet($id: ID!) {
  vendor(id: $id) {
    id
    name
    nameAr
  }
}
    `;

/**
 * __useProviderBreadcrumbGetQuery__
 *
 * To run a query within a React component, call `useProviderBreadcrumbGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderBreadcrumbGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderBreadcrumbGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderBreadcrumbGetQuery(baseOptions: Apollo.QueryHookOptions<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables> & ({ variables: ProviderBreadcrumbGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables>(ProviderBreadcrumbGetDocument, options);
      }
export function useProviderBreadcrumbGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables>(ProviderBreadcrumbGetDocument, options);
        }
export function useProviderBreadcrumbGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables>(ProviderBreadcrumbGetDocument, options);
        }
export type ProviderBreadcrumbGetQueryHookResult = ReturnType<typeof useProviderBreadcrumbGetQuery>;
export type ProviderBreadcrumbGetLazyQueryHookResult = ReturnType<typeof useProviderBreadcrumbGetLazyQuery>;
export type ProviderBreadcrumbGetSuspenseQueryHookResult = ReturnType<typeof useProviderBreadcrumbGetSuspenseQuery>;
export type ProviderBreadcrumbGetQueryResult = Apollo.QueryResult<ProviderBreadcrumbGetQuery, ProviderBreadcrumbGetQueryVariables>;