import { Box, useTheme } from "@toolkit/ui";
import ContentLoader from "react-content-loader";

export const VersionLoader = () => {
  const theme = useTheme();
  return (
    <Box>
      {new Array(3).fill(0).map((el, key) => (
        <Box key={key}>
          <ContentLoader
            speed={2}
            width={900}
            viewBox='0 0 400 160'
            backgroundColor={theme.palette.gray.light}
            foregroundColor={theme.palette.gray.light}
          >
            <rect x='20' y='0' rx='0' ry='0' width='850' height='15' />
          </ContentLoader>
        </Box>
      ))}
    </Box>
  );
};
