import {
  Box,
  Button,
  CustomDialog,
  CustomInfiniteScroll,
  Divider,
  MuiExpandMoreIcon,
  StyledButton,
  Typography,
  VersionsIcon,
  useTheme,
} from "@toolkit/ui";
import { VersionListHeader } from "./ListHeader";
import { useVersionsListHooks } from "./VersionList.hooks";
import { VersionLoader } from "./VersionLoader";
import { VersionRow } from "./VersionRow";

export const VersionsList = () => {
  const {
    versionParamId,
    versionData,
    latestApprovedVersionNumber,
    isVersionsOpened,
    isPublishing,
    versionDataLoading,
    isFetchingMoreVersions,
    hasNextPageVersions,
    isRefetchingVersions,
    t,
    handleDownloadFileFromVersion,
    handleOpenVersionClick,
    handleOnFetchMoreVersions,
    handleChangeActiveVersion,
    handleToggleVersions,
  } = useVersionsListHooks();
  const theme = useTheme();
  return (
    <CustomDialog
      type='base'
      maxWidth='lg'
      open={isVersionsOpened}
      DialogTitleProps={{
        title: t("Versions"),
        onClose: handleToggleVersions,
      }}
      button={
        <StyledButton
          variant='outlined'
          color='primary'
          size='medium'
          startIcon={<VersionsIcon color='inherit' />}
          onClick={handleToggleVersions}
          sx={{
            border: "1px solid" + theme.palette.primary.main,
          }}
        >
          {t("Versions")}
        </StyledButton>
      }
    >
      {versionDataLoading ? (
        <VersionLoader />
      ) : (
        <CustomInfiniteScroll dataLength={versionData?.length || 0} hasMore={isFetchingMoreVersions} onRefresh={handleOnFetchMoreVersions}>
          {!isRefetchingVersions && versionData?.length ? (
            <>
              <VersionListHeader />
              <Divider sx={{ marginBottom: "16px", borderBottomWidth: "medium", borderColor: theme.palette.primary.main }} />
              {versionData.map(version => (
                <VersionRow
                  versionParamId={versionParamId!}
                  key={version?.id}
                  isLoading={isPublishing}
                  version={version}
                  onVersionOpenClick={handleOpenVersionClick}
                  onDownload={handleDownloadFileFromVersion}
                  onVersionActiveChange={handleChangeActiveVersion}
                  latestApprovedVersionNumber={latestApprovedVersionNumber}
                />
              ))}
              {hasNextPageVersions && (
                <Box display='flex' justifyContent='center'>
                  <Button disabled={!hasNextPageVersions} variant='outlined' onClick={handleOnFetchMoreVersions}>
                    {t("More versions")}
                    <MuiExpandMoreIcon />
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Typography variant='h6' color={theme.palette.primary.main}>
              {t("No versions has been added or created for this List ")}
            </Typography>
          )}
        </CustomInfiniteScroll>
      )}
    </CustomDialog>
  );
};
