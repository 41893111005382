import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useCitiesAutocompleteQuery } from "./gql";
import { ICityAutocomplete } from "./schema";

export const createCityAutocompleteOption = (city: ICityAutocomplete) => {
  return createAutocompleteOption(
    {
      id: city?.id!,
      name: city?.name!,
      nameAr: city?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getCitiesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "cities",
    query: useCitiesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name, item?.nameAr!),
    backendAccessor: "id",
  });
};
