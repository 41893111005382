import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { MedicalMessageFilterInput } from "../../schema/types";
import { useMedicalMessagesAutocompleteQuery } from "./gql";

type MedicalMessagesAutocompleteProps = IAutocompleteProps<MedicalMessageFilterInput>;

export const MedicalMessagesAutocomplete: FC<MedicalMessagesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useMedicalMessagesAutocompleteQuery,
    variables: { filter },
    searchKey: "search",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => `${item?.code} - ${item?.name}`);

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Medical Messages") : t("Medical Message"))}
    />
  );
};
