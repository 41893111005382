import { SearchParameter } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useImportTerminologyDataToSubListMutation } from "../../../../gql";
import { getConceptPropertyValue } from "../../../utils";

type FetchToSublistFormData = {
  sublist: { id: string; display: string };
};
export const useFetchToSubListDialog = ({ searchParameters }) => {
  const methods = useForm<FetchToSublistFormData>();
  const { t } = useTranslation("domains");
  const { id: codeSystemId } = useParams();
  const { addToast, updateToast } = useToasts();

  const [fetchImportTerminologyDataToSubList, { loading: isSubmitting }] = useImportTerminologyDataToSubListMutation();

  const getValue = ({ valueBoolean, valueDate, valueInteger, valueString, valueFloat }: SearchParameter) =>
    valueBoolean || valueDate || valueInteger || valueString || valueFloat;

  const handleOnFetch = (formData: FetchToSublistFormData) => {
    const toastId = "" + codeSystemId + searchParameters.map(p => `${p.code}:${getConceptPropertyValue(p)}`).join("-");
    addToast(t("Fetching to SubList Please Wait") + " ....", {
      id: toastId,
      appearance: "info",
    });
    fetchImportTerminologyDataToSubList({
      variables: {
        codeSystemId: codeSystemId!,
        subListId: formData.sublist.id!,
        searchParameters: searchParameters,
      },
      onCompleted() {
        updateToast(toastId, {
          content: t("Fetched Successfully"),
          appearance: "success",
          autoDismiss: true,
        });
      },
      onError() {
        updateToast(toastId, {
          content: t("Error fetching"),
          appearance: "error",
          autoDismiss: true,
        });
      },
    });
  };

  return {
    methods,
    isSubmitting,
    t,
    getValue,
    handleOnFetch,
  };
};
