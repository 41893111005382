import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CircularProgress, FormAutocomplete, FormTextField, Grid } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { ICredentialFormValues } from "./CredentialFormSchema";
import { destinationTypeOptions } from "@health/enum-options";
import { credentialQueryType } from "../type";

type CredentialFormProps = {
  onSubmit: (values: ICredentialFormValues) => void;
  form: ReturnType<typeof useCustomForm<ICredentialFormValues>>;
  credential: credentialQueryType;
  isLoading: boolean;
  isSubmitting: boolean;
};

export const CredentialForm: FC<CredentialFormProps> = props => {
  const { form, isLoading, onSubmit } = props;

  const { handleSubmit } = form;
  const { t } = useTranslation("domains");

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item xs={4}>
            <FormAutocomplete name='destination' options={destinationTypeOptions} label={t("Destination")} />
          </Grid>
        </Grid>
        {!isLoading ? (
          <Grid container spacing={formGirdSpacing} mt={1}>
            <Grid item xs={4}>
              <FormTextField name='license' label={t("License")} />
            </Grid>
            <Grid item xs={4}>
              <FormTextField name='username' label={t("User Name")} />
            </Grid>
            <Grid item xs={4}>
              <FormTextField type='password' name='password' label={t("New Password")} />
            </Grid>
          </Grid>
        ) : (
          <CircularProgress sx={{ mt: 2 }} />
        )}
      </form>
    </FormProvider>
  );
};
