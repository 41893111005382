import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Divider, Stack } from "@toolkit/ui";
import { PrescriptionsAdminRowData } from "@/pages/OrdersTracking/types";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { OrderDetails } from "./OrderDetails";
import { OrderHandlingForm } from "./OrderHandlingForm";
import { useHandleOrder } from "./useHandleOrder.hook";

export const OrderHandlingModal: FC<{ order?: PrescriptionsAdminRowData }> = ({ order }) => {
  const { t } = useTranslation("domains");
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const { handleUpdate, handleOpen, isOpen, handleToggleDialog, loading } = useHandleOrder(reset, order?.id);
  const isPrescriptionOrder = order?.prescription?.id;
  const onSubmit = data => {
    const request = {
      ...data,
    };
    handleUpdate(request);
  };
  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: t("Handle Order"),
        onClose: handleToggleDialog,
      }}
      button={
        <Button disabled={!isPrescriptionOrder} onClick={handleOpen}>
          {t("Handle")}
        </Button>
      }
      DialogActionsProps={{
        children: (
          <Button variant='outlined' onClick={handleToggleDialog}>
            {t("Close")}
          </Button>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={2}
              divider={<Divider sx={{ width: "100%" }} />}
            >
              <OrderDetails order={order} />
              <OrderHandlingForm errors={[]} loading={loading} />
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
