import { Box, Typography, CustomLinearProgress } from "@toolkit/ui";
import { digitalTwinComplicationCategoryOptionsMap } from "@health/enum-options";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { getDigitalTwinCategoryColor } from "../../others";
import { useCaseManagementPatientDigitalTwinLinearProgressStyle } from "./useCaseManagementPatientDigitalTwinLinearProgressStyle";

type CaseManagementPatientDigitalTwinLinearProgressProps = {
  name: string;
  category: string;
  value: number;
};

export const CaseManagementPatientDigitalTwinLinearProgress: FC<CaseManagementPatientDigitalTwinLinearProgressProps> = props => {
  const { name, category, value = 0 } = props;

  const { t } = useTranslation("admin");

  const { classes, theme } = useCaseManagementPatientDigitalTwinLinearProgressStyle();

  const color = getDigitalTwinCategoryColor(category, theme);

  return (
    <Box>
      <Box className={classes.wrapper}>
        <Typography>{name}</Typography>

        <Typography className={classes.category} color={color}>
          {category ? digitalTwinComplicationCategoryOptionsMap[category]?.label : t("N/A")}
        </Typography>
      </Box>

      <CustomLinearProgress value={value} color={color} />

      <Box className={classes.wrapper}>
        <Typography>{value}</Typography>
      </Box>
    </Box>
  );
};
