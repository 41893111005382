import { makeStyles } from "@toolkit/ui";

export const useAutoCoverageCardStyles = makeStyles()(theme => ({
  disclaimer: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginTop: -4,
    marginBottom: 8,
    textAlign: "start",
  },
}));
