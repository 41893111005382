import { IHealthProgramMemberNode } from "@/pages/HealthProgramMembers/types";
import { HealthProgramMemberSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { convertToTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, formatDate, Typography } from "@toolkit/ui";
import { useMemo } from "react";

export const useHealthProgramMemberListContainerColumns = (): CustomTableColumnProps<IHealthProgramMemberNode>[] => {
  const { t, i18n } = useTranslation("admin");
  const lang = i18n.language;
  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
        sort: {
          columnEnum: HealthProgramMemberSortingField.Name,
        },
        filter: {
          name: "name",
          type: "string",
        },
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: "nationalId",
        sort: {
          columnEnum: HealthProgramMemberSortingField.NationalId,
        },
        filter: {
          name: "nationalId",
          type: "string",
        },
      },
      {
        key: "sehacityId",
        header: t("iO Health Id"),
        accessor: "sehacityId",
      },
      {
        key: "insuranceId",
        header: t("Insurance Id"),
        accessor: "insuranceId",
        sort: {
          columnEnum: HealthProgramMemberSortingField.InsuranceId,
        },
        filter: {
          type: "string",
          name: "insuranceId",
        },
      },
      {
        key: "email",
        header: t("Email"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "email",
        },
      },
      {
        key: "gender",
        header: t("Gender"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserGender", "gender"),
      },
      {
        key: "dateOfBirth",
        header: t("Date of Birth"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateOfBirth",
        },
      },
      {
        key: "relationType",
        header: t("Relation Type"),
        accessor: ({ relationType }) => convertToTitleCase(relationType || "-"),
      },
      {
        key: "parentNationalId",
        header: t("Parent National Id"),
        accessor: "parentNationalId",
      },
      {
        key: "phone",
        header: t("Phone Number"),
        accessor: "phone",
        type: "mobile",
        filter: {
          name: "phone",
          type: "string",
        },
      },
      {
        key: "membership",
        header: t("Membership"),
        accessor: ({ membershipStart, membershipEnd }) => (
          <Typography sx={{ direction: "ltr" }}>
            {formatDate(membershipStart, lang)} - {formatDate(membershipEnd, lang)}
          </Typography>
        ),
        type: "date",
      },
      {
        key: "created",
        header: t("Created"),
        sort: {
          columnEnum: HealthProgramMemberSortingField.Created,
        },
        type: "datetime",
        accessor: "created",
      },
    ],
    [t]
  );
};
