import { i18n } from "@toolkit/i18n";
import { ArticleCategoryType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const articleCategoryTypeOptionsMap: IEnumToOptionsMap<ArticleCategoryType> = {
  [ArticleCategoryType.Parent]: {
    key: ArticleCategoryType.Parent,
    get label() {
      return i18n.t("Parent", { ns: "domains" });
    },
    value: ArticleCategoryType.Parent,
  },
  [ArticleCategoryType.Child]: {
    key: ArticleCategoryType.Child,
    get label() {
      return i18n.t("Child", { ns: "domains" });
    },
    value: ArticleCategoryType.Child,
  },
};

export const articleCategoryTypeOptions = Object.values(articleCategoryTypeOptionsMap);
