/* eslint-disable no-nested-ternary */
import { PermissionEnum } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useHealthProgramNetworkProviderVirtualCallEnableMutation } from "../../gql";

type HealthProgramNetworkProviderVirtualCallEnableProps = {
  id: string;
  isEnabled: boolean;
};

export const HealthProgramNetworkProviderVirtualCallEnable: FC<HealthProgramNetworkProviderVirtualCallEnableProps> = props => {
  const { id, isEnabled } = props;

  const [enabled, setEnabled] = useState(isEnabled);

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const [enableHealthProgramNetworkProviderVirtualCall, { loading: isSubmitting }] =
    useHealthProgramNetworkProviderVirtualCallEnableMutation({
      onCompleted: data => {
        if (data?.healthProgramNetworkProviderUpdateVirtualCallEnabled?.entity?.id) {
          setEnabled(!!data?.healthProgramNetworkProviderUpdateVirtualCallEnabled?.entity?.virtualCallEnabled);
          succeeded(t("Network Provider updated successfully"));
        } else {
          failed(t("Network Provider update failed"));
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });

  const handleChangeToggle = () => {
    enableHealthProgramNetworkProviderVirtualCall({
      variables: { id },
    });
  };

  return (
    <>
      {hasManageHealthProgramsPermission ? (
        <CustomToggleButton
          disabled={isSubmitting}
          isLoading={isSubmitting}
          isMarginDisabled
          checked={enabled}
          onChange={handleChangeToggle}
        />
      ) : enabled ? (
        t("Yes")
      ) : (
        t("No")
      )}
    </>
  );
};
