import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistDownloadQueryVariables = Types.Exact<{
  subListId: Types.Scalars['ID']['input'];
}>;


export type SublistDownloadQuery = { __typename?: 'Query', downloadSubList?: string | null };


export const SublistDownloadDocument = gql`
    query SublistDownload($subListId: ID!) {
  downloadSubList(subListId: $subListId)
}
    `;

/**
 * __useSublistDownloadQuery__
 *
 * To run a query within a React component, call `useSublistDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSublistDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSublistDownloadQuery({
 *   variables: {
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useSublistDownloadQuery(baseOptions: Apollo.QueryHookOptions<SublistDownloadQuery, SublistDownloadQueryVariables> & ({ variables: SublistDownloadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SublistDownloadQuery, SublistDownloadQueryVariables>(SublistDownloadDocument, options);
      }
export function useSublistDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SublistDownloadQuery, SublistDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SublistDownloadQuery, SublistDownloadQueryVariables>(SublistDownloadDocument, options);
        }
export function useSublistDownloadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SublistDownloadQuery, SublistDownloadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SublistDownloadQuery, SublistDownloadQueryVariables>(SublistDownloadDocument, options);
        }
export type SublistDownloadQueryHookResult = ReturnType<typeof useSublistDownloadQuery>;
export type SublistDownloadLazyQueryHookResult = ReturnType<typeof useSublistDownloadLazyQuery>;
export type SublistDownloadSuspenseQueryHookResult = ReturnType<typeof useSublistDownloadSuspenseQuery>;
export type SublistDownloadQueryResult = Apollo.QueryResult<SublistDownloadQuery, SublistDownloadQueryVariables>;