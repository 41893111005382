import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceNetworkCreateMutationVariables = Types.Exact<{
  input: Types.InsuranceNetworkInput;
}>;


export type InsuranceNetworkCreateMutation = { __typename?: 'Mutation', insuranceNetworkCreate?: { __typename?: 'InsuranceNetworkCRUD', insuranceNetworkErrors?: Array<{ __typename?: 'H_EntityError', field?: string | null, code: Types.H_EntityErrorCode, message?: string | null }> | null, insuranceNetwork?: { __typename?: 'InsuranceNetwork', id: string, name?: string | null, created?: any | null, isActive?: boolean | null } | null } | null };


export const InsuranceNetworkCreateDocument = gql`
    mutation insuranceNetworkCreate($input: InsuranceNetworkInput!) {
  insuranceNetworkCreate(input: $input) {
    insuranceNetworkErrors {
      field
      code
      message
    }
    insuranceNetwork {
      id
      name
      created
      isActive
    }
  }
}
    `;
export type InsuranceNetworkCreateMutationFn = Apollo.MutationFunction<InsuranceNetworkCreateMutation, InsuranceNetworkCreateMutationVariables>;

/**
 * __useInsuranceNetworkCreateMutation__
 *
 * To run a mutation, you first call `useInsuranceNetworkCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceNetworkCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceNetworkCreateMutation, { data, loading, error }] = useInsuranceNetworkCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsuranceNetworkCreateMutation(baseOptions?: Apollo.MutationHookOptions<InsuranceNetworkCreateMutation, InsuranceNetworkCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsuranceNetworkCreateMutation, InsuranceNetworkCreateMutationVariables>(InsuranceNetworkCreateDocument, options);
      }
export type InsuranceNetworkCreateMutationHookResult = ReturnType<typeof useInsuranceNetworkCreateMutation>;
export type InsuranceNetworkCreateMutationResult = Apollo.MutationResult<InsuranceNetworkCreateMutation>;
export type InsuranceNetworkCreateMutationOptions = Apollo.BaseMutationOptions<InsuranceNetworkCreateMutation, InsuranceNetworkCreateMutationVariables>;