import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BenefitMapCreateMutationVariables = Types.Exact<{
  input: Types.BenefitMapInput;
}>;


export type BenefitMapCreateMutation = { __typename?: 'Mutation', createBenefitMap?: { __typename?: 'BenefitMap', benefitCode: string, id: string, itemCode: string } | null };


export const BenefitMapCreateDocument = gql`
    mutation BenefitMapCreate($input: BenefitMapInput!) {
  createBenefitMap(input: $input) {
    benefitCode
    id
    itemCode
  }
}
    `;
export type BenefitMapCreateMutationFn = Apollo.MutationFunction<BenefitMapCreateMutation, BenefitMapCreateMutationVariables>;

/**
 * __useBenefitMapCreateMutation__
 *
 * To run a mutation, you first call `useBenefitMapCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBenefitMapCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [benefitMapCreateMutation, { data, loading, error }] = useBenefitMapCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBenefitMapCreateMutation(baseOptions?: Apollo.MutationHookOptions<BenefitMapCreateMutation, BenefitMapCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BenefitMapCreateMutation, BenefitMapCreateMutationVariables>(BenefitMapCreateDocument, options);
      }
export type BenefitMapCreateMutationHookResult = ReturnType<typeof useBenefitMapCreateMutation>;
export type BenefitMapCreateMutationResult = Apollo.MutationResult<BenefitMapCreateMutation>;
export type BenefitMapCreateMutationOptions = Apollo.BaseMutationOptions<BenefitMapCreateMutation, BenefitMapCreateMutationVariables>;