import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserPermissionListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserPermissionListQuery = { __typename?: 'Query', permissions: Array<{ __typename?: 'Permission', id: string, code?: Types.PermissionEnum | null, name: string } | null> };


export const UserPermissionListDocument = gql`
    query UserPermissionList {
  permissions {
    id
    code
    name
  }
}
    `;

/**
 * __useUserPermissionListQuery__
 *
 * To run a query within a React component, call `useUserPermissionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPermissionListQuery(baseOptions?: Apollo.QueryHookOptions<UserPermissionListQuery, UserPermissionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPermissionListQuery, UserPermissionListQueryVariables>(UserPermissionListDocument, options);
      }
export function useUserPermissionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionListQuery, UserPermissionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPermissionListQuery, UserPermissionListQueryVariables>(UserPermissionListDocument, options);
        }
export function useUserPermissionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserPermissionListQuery, UserPermissionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserPermissionListQuery, UserPermissionListQueryVariables>(UserPermissionListDocument, options);
        }
export type UserPermissionListQueryHookResult = ReturnType<typeof useUserPermissionListQuery>;
export type UserPermissionListLazyQueryHookResult = ReturnType<typeof useUserPermissionListLazyQuery>;
export type UserPermissionListSuspenseQueryHookResult = ReturnType<typeof useUserPermissionListSuspenseQuery>;
export type UserPermissionListQueryResult = Apollo.QueryResult<UserPermissionListQuery, UserPermissionListQueryVariables>;