import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramMemberSortingInput>;
}>;


export type HealthProgramMemberListQuery = { __typename?: 'Query', healthProgramMembers?: { __typename?: 'HealthProgramMemberCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', node: { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.H_Gender | null, nationalId?: string | null, sehacityId?: string | null, insuranceId?: string | null, phone?: string | null, email?: string | null, membershipStart?: any | null, membershipEnd?: any | null, created?: any | null, parentNationalId?: string | null, relationType?: string | null, healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', totalCount?: number | null } | null } }> } | null };


export const HealthProgramMemberListDocument = gql`
    query HealthProgramMemberList($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramMemberFilterInput, $sortBy: HealthProgramMemberSortingInput) {
  healthProgramMembers(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        firstName
        lastName
        gender
        nationalId
        sehacityId
        insuranceId
        phone
        email
        membershipStart
        membershipEnd
        created
        healthProgramMemberLists {
          totalCount
        }
        parentNationalId
        relationType
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMemberListQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramMemberListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>(HealthProgramMemberListDocument, options);
      }
export function useHealthProgramMemberListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>(HealthProgramMemberListDocument, options);
        }
export function useHealthProgramMemberListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>(HealthProgramMemberListDocument, options);
        }
export type HealthProgramMemberListQueryHookResult = ReturnType<typeof useHealthProgramMemberListQuery>;
export type HealthProgramMemberListLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberListLazyQuery>;
export type HealthProgramMemberListSuspenseQueryHookResult = ReturnType<typeof useHealthProgramMemberListSuspenseQuery>;
export type HealthProgramMemberListQueryResult = Apollo.QueryResult<HealthProgramMemberListQuery, HealthProgramMemberListQueryVariables>;