import { DiagnosisValidProcedureSortingField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IDiagnosisValidProcedureListNode } from "../../types";
import { getDiscrptionsInfoItems } from "@/pages/OptimaMedicalNecessity/others/cellInfo.utils";

export const useDiagnosisValidProcedureListContainerColumns = (): CustomTableColumnProps<IDiagnosisValidProcedureListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "procedureCode",
        header: t("Procedure Code"),
        accessor: "procedureCode",
        sort: {
          columnEnum: DiagnosisValidProcedureSortingField.ProcedureCode,
        },
        filter: {
          type: "string",
          name: "procedureCode",
        },
      },
      {
        key: "diagnosisCode",
        header: t("Diagnosis Code"),
        accessor: "diagnosisCode",
        sort: {
          columnEnum: DiagnosisValidProcedureSortingField.DiagnosisCode,
        },
        filter: {
          type: "string",
          name: "diagnosisCode",
        },
      },
      {
        key: "description",
        header: t("Description"),
        type: "info",
        infoCellOptions: {
          title: t("Description"),
          items: ({ description }) => getDiscrptionsInfoItems(description),
          disableShowButton: ({ description }) => !description?.length,
        },
      },
    ],
    [t]
  );
};
