import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { usersPermissionsGroupsPaths } from "../constants";

export const useSetUsersPermissionsGroupsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Users Permissions");

  const userUserPermissionGroupListBreadcrumb = useMemo(() => {
    return {
      name: t("Users Permissions"),
      route: usersPermissionsGroupsPaths.list.fullPath,
    };
  }, [t]);

  const setUserPermissionGroupListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setUserPermissionGroupCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [userUserPermissionGroupListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, userUserPermissionGroupListBreadcrumb, t]);

  const setUserPermissionGroupUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [userUserPermissionGroupListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, userUserPermissionGroupListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setUserPermissionGroupListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setUserPermissionGroupCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setUserPermissionGroupUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setUserPermissionGroupListContainerBreadcrumb,
    setUserPermissionGroupCreateContainerBreadcrumb,
    setUserPermissionGroupUpdateContainerBreadcrumb,
  ]);
};
