import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaEditActivationMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type OptimaEditActivationMutation = { __typename?: 'Mutation', updateOptimaEditActiveStatus?: { __typename?: 'OptimaEdit', code?: string | null, isActive?: boolean | null } | null };


export const OptimaEditActivationDocument = gql`
    mutation OptimaEditActivation($id: ID) {
  updateOptimaEditActiveStatus(id: $id) {
    code
    isActive
  }
}
    `;
export type OptimaEditActivationMutationFn = Apollo.MutationFunction<OptimaEditActivationMutation, OptimaEditActivationMutationVariables>;

/**
 * __useOptimaEditActivationMutation__
 *
 * To run a mutation, you first call `useOptimaEditActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaEditActivationMutation, { data, loading, error }] = useOptimaEditActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptimaEditActivationMutation(baseOptions?: Apollo.MutationHookOptions<OptimaEditActivationMutation, OptimaEditActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaEditActivationMutation, OptimaEditActivationMutationVariables>(OptimaEditActivationDocument, options);
      }
export type OptimaEditActivationMutationHookResult = ReturnType<typeof useOptimaEditActivationMutation>;
export type OptimaEditActivationMutationResult = Apollo.MutationResult<OptimaEditActivationMutation>;
export type OptimaEditActivationMutationOptions = Apollo.BaseMutationOptions<OptimaEditActivationMutation, OptimaEditActivationMutationVariables>;