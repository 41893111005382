import { OptimaAction } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { convertOptimaActionToFormValues } from "@/pages/OptimaActions/others";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import {
  IOptimaActionUpsertFormValues,
  optimaActionUpsertFormDefaultValues,
  optimaActionUpsertFormSchema,
} from "./OptimaActionUpsertFormSchema";

export type IOptimaActionUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IOptimaActionUpsertFormValues;
  };
};

type OptimaActionUpsertFormProps = {
  optimaAction?: OptimaAction;
  buttonLabel: string;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IOptimaActionUpsertFormEvent) => void;
};

export const OptimaActionUpsertForm: FC<OptimaActionUpsertFormProps> = props => {
  const { optimaAction, buttonLabel, isLoading, isButtonDisabled, onChange } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IOptimaActionUpsertFormValues>({
    defaultValues: optimaActionUpsertFormDefaultValues,
    schema: optimaActionUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IOptimaActionUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (optimaAction) {
      const _optimaAction = convertOptimaActionToFormValues(optimaAction);
      setValues(_optimaAction);
    }
  }, [optimaAction]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions hasCancel hasFormButton isLoading={isLoading} formButtonTitle={buttonLabel} newButtonDisabled={isButtonDisabled} />
          }
        >
          <FormCard title={t("Optima Action Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <FormTextField name={"action"} label={t("Action")} placeholder={t("Action")} />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormTextField name={"link"} label={t("Link")} placeholder={t("Link")} />
              </Grid>

              <Grid item xs={12}>
                <FormTextField name={"summary"} label={t("Summary")} placeholder={t("Summary")} multiline rows={4} />
              </Grid>

              <Grid item xs={12}>
                <FormSwitch name={"isActive"} label={t("Active")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
