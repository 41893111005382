import { formatUserNameFull } from "@toolkit/core";
import { AutocompleteComponent, Button, CircularProgress, CustomDialog, FormControl } from "@toolkit/ui";
import { FC, memo } from "react";
import { AMLAssignReviewerDialogProps } from "./AMLAssignReviewerDialog.types";
import { useAMLAssignReviewersHook } from "./useAMLAssignReviewersHook";

const AMLAssignReviewerDialog: FC<AMLAssignReviewerDialogProps> = props => {
  const {
    isOpen,
    errors,
    reviewer,
    editor,
    users,
    isLoading,
    isSubmitting,
    onFetchMoreUsers,
    t,
    handleOpen,
    handleClose,
    handleReviewerChange,
    handleEditorChange,
    handleAssignClick,
  } = useAMLAssignReviewersHook(props);
  const reviewerInputLabel = t("Reviewer Name");
  const editorInputLabel = t("Editor Name");
  return (
    <CustomDialog
      type='base'
      open={isOpen}
      button={"renderButton" in props ? props.renderButton?.(handleOpen) : <Button {...props.buttonProps} onClick={handleOpen} />}
      maxWidth='xs'
      DialogTitleProps={{
        onClose: handleClose,
        title: t("Assign"),
      }}
      keepMounted={false}
      DialogActionsProps={{
        children: (
          <>
            <Button onClick={handleAssignClick} disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <CircularProgress size={14} /> {t("Assign")}...
                </>
              ) : (
                t("Assign")
              )}
            </Button>
            <Button variant='outlined' onClick={handleClose} autoFocus>
              {t("Close")}
            </Button>
          </>
        ),
      }}
    >
      <FormControl fullWidth>
        <AutocompleteComponent
          fetchMoreData={onFetchMoreUsers}
          loading={isLoading}
          getOptionLabel={item => formatUserNameFull(item)}
          options={users || []}
          onChange={handleEditorChange}
          // placeholder={editorInputLabel}
          defaultValue={editor}
          TextFieldProps={{
            label: editorInputLabel,
            placeholder: editorInputLabel,
            error: !!errors.editorId,
            helperText: errors.editorId,
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <AutocompleteComponent
          fetchMoreData={onFetchMoreUsers}
          loading={isLoading}
          defaultValue={reviewer}
          getOptionLabel={item => formatUserNameFull(item)}
          options={users || []}
          isOptionEqualToValue={(o, v) => o.id === v.id || o == v}
          onChange={handleReviewerChange}
          // placeholder={reviewerInputLabel}
          TextFieldProps={{
            label: reviewerInputLabel,
            placeholder: reviewerInputLabel,

            error: !!errors.reviewerId,
            helperText: errors.reviewerId,
          }}
        />
      </FormControl>
    </CustomDialog>
  );
};

export default memo(AMLAssignReviewerDialog);
