import { ChangeEvent, useMemo, useState } from "react";
import { IGoogleMapAddress, ILocationPickerProps } from "./types";
import { useLocationHooks } from "../utils";
import { useTranslation } from "@toolkit/i18n";
import { mapConfig } from "../../mapConfig";
import { useLoadScript } from "@react-google-maps/api";

export const useLocationPickerHook = (props: ILocationPickerProps) => {
  const { getLocationResult } = useLocationHooks();
  const { t, i18n } = useTranslation();
  const { location: _location, defaultCenter, hasLocationLngLat, onLocationChange, locations: _locations } = props;
  const scriptOptions = {
    id: "google-maps-script",
    googleMapsApiKey: mapConfig.get("googleApiKey"),
    libraries: ["places"] as ("places" | "drawing" | "geometry" | "visualization")[],
    version: "weekly",
    nonce: undefined,
    region: undefined,
    language: undefined,
    preventLoad: false,
    channel: undefined,
    authReferrerPolicy: "origin",
    loading: {
      strategy: "async" as const,
    },
  } as const;
  const currentScriptOptions = useMemo(
    () => ({
      ...scriptOptions,
      language: i18n.language,
    }),
    [i18n.language]
  );

  const location = useMemo(
    () =>
      _location || {
        lat: defaultCenter?.lat!,
        lng: defaultCenter?.lng!,
      },
    [_location?.lat, _location?.lng, defaultCenter?.lat, defaultCenter?.lng]
  );

  const center = useMemo(
    () => (location?.lat && location?.lng ? location : defaultCenter),
    [location?.lat, location?.lng, defaultCenter?.lat, defaultCenter?.lng]
  );

  const [isChecked, setIsChecked] = useState(false);

  const locationsKey = useMemo(() => {
    if (!_locations) return "";
    return _locations.map(loc => `${loc.id}-${loc.coordinates?.lat}-${loc.coordinates?.lng}`).join("|");
  }, [_locations]);

  const locations = useMemo(() => _locations || [], [locationsKey]);

  const handlePickLocation = async (event: google.maps.MapMouseEvent) => {
    if (!event.latLng) return;

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const locationAddress = await getLocationResult(lat.toString(), lng.toString());
    onLocationChange?.(locationAddress);
  };

  const handleIsChecked = () => {
    setIsChecked(state => !state);
  };

  const handleLocationChanged = (type: "lng" | "lat") => async (event: ChangeEvent<HTMLInputElement>) => {
    console.log("handleLocationChanged,", type, event.target.value);
    const pickedLocation = {
      lng: center?.lng!,
      lat: center?.lat!,
      [type]: Number(event.target.value)!,
    };

    const locationAddress = await getLocationResult(pickedLocation.lat.toString(), pickedLocation.lng.toString());
    onLocationChange?.(locationAddress);
  };

  const { isLoaded, loadError } = useLoadScript(currentScriptOptions);

  const handleSearchLocation = (newLocation: IGoogleMapAddress) => {
    onLocationChange?.(newLocation);
  };

  return {
    ...props,
    locations,
    isLoaded,
    loadError,
    hasLocationLngLat,
    center,
    isChecked,
    t,
    handlePickLocation,
    handleIsChecked,
    handleLocationChanged,
    handleSearchLocation,
  };
};
