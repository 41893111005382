import { dayOfWeekOptions, dayOfWeekOptionsMap } from "@health/enum-options";
import { createMomentTime } from "@toolkit/ui";
import { Maybe, WorkingHour } from "@/schema/types";
import moment from "moment/moment";
import { DaysTimesRanges } from "../zodSchemas";
import { normalizeString } from "@toolkit/core";
import { DayOfWeekEnum } from "@/schema/types";

export const getDefaultDaysTimesRangesValues = () => {
  return dayOfWeekOptions.map(item => ({
    day: item?.label,
    timeRanges: [{ startTime: createMomentTime("08:00"), endTime: createMomentTime("17:00") }],
  }));
};

export const convertDaysTimesRangesToFormValues = (workingHours: Maybe<Array<Maybe<WorkingHour>>>) => {
  return workingHours?.length
    ? workingHours?.map(item => ({
        day: dayOfWeekOptionsMap[item?.day!]?.label!,
        timeRanges: item?.openTimeRanges?.length
          ? item?.openTimeRanges?.map(timeRange => ({
              startTime: moment(timeRange?.openTime, "HH:mm").format(),
              endTime: moment(timeRange?.closeTime, "HH:mm").format(),
            }))
          : [],
      }))
    : [];
};

export const convertDaysTimesRangesFormValuesToBackEndValues = (daysTimesRanges: DaysTimesRanges[]) => {
  return daysTimesRanges?.length
    ? daysTimesRanges?.map(item => {
        let dayEnum = Object.keys(dayOfWeekOptionsMap).find(key => {
          return normalizeString(dayOfWeekOptionsMap[key]?.label) === normalizeString(item?.day);
        });

        if (!dayEnum && item?.day && item.day.length >= 3) {
          const first3Letters = normalizeString(item.day).substring(0, 3);

          dayEnum = Object.keys(dayOfWeekOptionsMap).find(key => {
            const keyValue = key.toLowerCase();
            return keyValue.includes(first3Letters);
          });
        }

        return {
          day: dayEnum as DayOfWeekEnum,
          openTimeRanges: item?.timeRanges?.length
            ? item?.timeRanges?.map(timeRange => ({
                openTime: moment(timeRange?.startTime).format("HH:mm"),
                closeTime: moment(timeRange?.endTime).format("HH:mm"),
              }))
            : [],
        };
      })
    : [];
};
