import { makeStyles } from "@toolkit/ui";

export const useOptimaPassedItemStyles = makeStyles()(theme => ({
  score: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },
  passed: {
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    color: theme.palette.success.main,
  },
  divider: {
    marginBlock: theme.spacing(1.5),
  },
}));
