import React, { FC } from "react";
import { BaseDialogProps } from "./types";

import { ConsumerCustomDialog } from "../ConsumerCustomDialog/ConsumerCustomDialog";
import { CustomMuiDialog } from "../CustomMuiDialog";

export const BaseDialog: FC<BaseDialogProps> = props => {
  if (props.variant === "consumer") {
    return <ConsumerCustomDialog {...props} />;
  }

  return <CustomMuiDialog {...props} />;
};
