import { FC } from "react";
import { Box, DrawerProps, MuiDrawer } from "../../base/mui";
import { useCustomDrawerStyle } from "./useCustomDrawerStyle";

type CustomDrawerProps = DrawerProps & {
  isOpen: boolean;
  width?: number | string;
  onClose: () => void;
};

export const CustomDrawer: FC<CustomDrawerProps> = props => {
  const { isOpen, anchor = "right", width = "80vw", role = "presentation", onClose, children, ...rest } = props;

  const { classes } = useCustomDrawerStyle();

  return (
    <MuiDrawer open={isOpen} anchor={anchor} className={classes.root} onClose={onClose} {...rest}>
      <Box role={role} width={width}>
        {children}
      </Box>
    </MuiDrawer>
  );
};
