import { DiagnosisValidProcedureSortingField, SortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { diagnosisValidProceduresPaths } from "../../constants";
import { useDiagnosisValidProcedureDeleteMutation } from "../../gql/mutations/__generated__";
import { useDiagnosisValidProcedureListQuery } from "../../gql/queries/__generated__";
import { useSetDiagnosisValidProceduresBreadcrumbs } from "../../hooks/useSetDiagnosisValidProceduresBreadcrumbs";
import { IDiagnosisValidProcedureListNode } from "../../types";
import { useDiagnosisValidProcedureListContainerColumns } from "./useDiagnosisValidProcedureListContainerColumns";

export const DiagnosisValidProcedureListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(diagnosisValidProceduresPaths.create.fullPath);
  };

  const onEditRowClick = (item: IDiagnosisValidProcedureListNode) => {
    navigate(diagnosisValidProceduresPaths.update.fullPathWithParams({ diagnosisValidProcedureId: item?.id }));
  };

  useSetDiagnosisValidProceduresBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"diagnosisValidProcedureList"}
      query={useDiagnosisValidProcedureListQuery}
      columns={useDiagnosisValidProcedureListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: DiagnosisValidProcedureSortingField.ProcedureCode,
          direction: SortDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "DiagnosisValidProcedure",
        name: "Diagnosis Valid Procedure",
        useDeleteMutation: useDiagnosisValidProcedureDeleteMutation,
        idPropName: "deleteDiagnosisValidProcedureId",
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
