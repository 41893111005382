import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistItemCreateMutationVariables = Types.Exact<{
  input: Types.SubListItemInput;
  subListId: Types.Scalars['ID']['input'];
}>;


export type SublistItemCreateMutation = { __typename?: 'Mutation', addSubListItem?: { __typename?: 'SubListItem', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const SublistItemCreateDocument = gql`
    mutation SublistItemCreate($input: SubListItemInput!, $subListId: ID!) {
  addSubListItem(subListItem: $input, subListId: $subListId) {
    errors {
      field
      message
    }
  }
}
    `;
export type SublistItemCreateMutationFn = Apollo.MutationFunction<SublistItemCreateMutation, SublistItemCreateMutationVariables>;

/**
 * __useSublistItemCreateMutation__
 *
 * To run a mutation, you first call `useSublistItemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistItemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistItemCreateMutation, { data, loading, error }] = useSublistItemCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      subListId: // value for 'subListId'
 *   },
 * });
 */
export function useSublistItemCreateMutation(baseOptions?: Apollo.MutationHookOptions<SublistItemCreateMutation, SublistItemCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SublistItemCreateMutation, SublistItemCreateMutationVariables>(SublistItemCreateDocument, options);
      }
export type SublistItemCreateMutationHookResult = ReturnType<typeof useSublistItemCreateMutation>;
export type SublistItemCreateMutationResult = Apollo.MutationResult<SublistItemCreateMutation>;
export type SublistItemCreateMutationOptions = Apollo.BaseMutationOptions<SublistItemCreateMutation, SublistItemCreateMutationVariables>;