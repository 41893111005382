import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyncChatFlowMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SyncChatFlowMutation = { __typename?: 'Mutation', syncChatFlow?: boolean | null };


export const SyncChatFlowDocument = gql`
    mutation syncChatFlow {
  syncChatFlow
}
    `;
export type SyncChatFlowMutationFn = Apollo.MutationFunction<SyncChatFlowMutation, SyncChatFlowMutationVariables>;

/**
 * __useSyncChatFlowMutation__
 *
 * To run a mutation, you first call `useSyncChatFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncChatFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncChatFlowMutation, { data, loading, error }] = useSyncChatFlowMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncChatFlowMutation(baseOptions?: Apollo.MutationHookOptions<SyncChatFlowMutation, SyncChatFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncChatFlowMutation, SyncChatFlowMutationVariables>(SyncChatFlowDocument, options);
      }
export type SyncChatFlowMutationHookResult = ReturnType<typeof useSyncChatFlowMutation>;
export type SyncChatFlowMutationResult = Apollo.MutationResult<SyncChatFlowMutation>;
export type SyncChatFlowMutationOptions = Apollo.BaseMutationOptions<SyncChatFlowMutation, SyncChatFlowMutationVariables>;