import { createPathDefinition } from "@toolkit/core";
import { optimaMedicalNecessityPath } from "../../constants/OptimaMedicalNecessityPaths";

export const cptHcpcPrimaryProcedureRoute = optimaMedicalNecessityPath + "/cpt-hcpc-primary-procedures";

export const cptHcpcPrimaryProcedurePaths = createPathDefinition(cptHcpcPrimaryProcedureRoute, {
  list: "",
  create: "new",
  update: ":cptHcpcPrimaryProcedureId",
});
