import { HealthProgramMemberList } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import {
  HealthProgramMemberListUpsertForm,
  IHealthProgramMemberListUpsertFormEvent,
} from "../../forms/HealthProgramMemberListUpsert/HealthProgramMemberListUpsertForm";
import { useHealthProgramMemberListGetQuery, useHealthProgramMemberListUpdateMutation } from "../../gql";
import { useSetHealthProgramMemberListsBreadcrumbs } from "../../hooks";
import { convertHealthProgramMemberListFormValuesToBackEndValues } from "../../others";
import { IHealthProgramMemberListUpdateContainerParams } from "../../types";

export const HealthProgramMemberListUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { memberListId } = useParams<IHealthProgramMemberListUpdateContainerParams>();

  const { data, loading } = useHealthProgramMemberListGetQuery({
    variables: {
      filter: {
        ids: [memberListId!],
      },
    },
    skip: !memberListId,
    fetchPolicy: "no-cache",
  });

  const healthProgramMemberList = data?.healthProgramMemberLists?.edges?.[0]?.node as HealthProgramMemberList;

  const [updateHealthProgramMemberList, { loading: isSubmitting }] = useHealthProgramMemberListUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramMemberListUpdate?.entity?.id) {
        succeeded(t("Member List updated successfully"));
        navigate(-1);
      } else {
        failed(t("Member List update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramMemberListUpsertFormChange = (event: IHealthProgramMemberListUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramMemberListFormValuesToBackEndValues(event.payload.values);

      updateHealthProgramMemberList({
        variables: {
          id: memberListId!,
          input: values,
        },
      });
    }
  };

  useSetHealthProgramMemberListsBreadcrumbs("UPDATE", healthProgramMemberList?.name!);

  return (
    <HealthProgramMemberListUpsertForm
      buttonLabel={t("Update")}
      healthProgramMemberList={healthProgramMemberList}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramMemberListUpsertFormChange}
    />
  );
};
