import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaRemittanceActivitiesQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.TransactionRemittanceActivityFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type OptimaRemittanceActivitiesQuery = { __typename?: 'Query', optimaRemittanceActivities?: { __typename?: 'TransactionRemittanceActivityConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'TransactionRemittanceActivityEdge', node?: { __typename?: 'TransactionRemittanceActivity', list?: number | null, type: number, quantity?: number | null, start: string, priorAuthorizationID?: string | null, paymentAmount?: number | null, patientShare?: number | null, net: number, id: string, gross?: number | null, duration?: number | null, denialCode?: string | null, code: string, clinician: string, activityPenalty?: string | null, activityId: string, typeLookup?: { __typename?: 'TransactionActivityTypeLookup', name?: string | null, type?: number | null } | null, denialLookup?: { __typename?: 'TransactionDenialLookup', name?: string | null, id: string } | null, codeLookup?: { __typename?: 'TransactionActivityCodeLookup', name?: string | null } | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const OptimaRemittanceActivitiesDocument = gql`
    query OptimaRemittanceActivities($after: String, $before: String, $filter: TransactionRemittanceActivityFilterInput, $first: Int, $last: Int) {
  optimaRemittanceActivities(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      node {
        list
        type
        typeLookup {
          name
          type
        }
        quantity
        start
        priorAuthorizationID
        paymentAmount
        patientShare
        net
        id
        gross
        duration
        denialCode
        denialLookup {
          name
          id
        }
        code
        clinician
        activityPenalty
        activityId
        codeLookup {
          name
        }
      }
    }
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOptimaRemittanceActivitiesQuery__
 *
 * To run a query within a React component, call `useOptimaRemittanceActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaRemittanceActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaRemittanceActivitiesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOptimaRemittanceActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>(OptimaRemittanceActivitiesDocument, options);
      }
export function useOptimaRemittanceActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>(OptimaRemittanceActivitiesDocument, options);
        }
export function useOptimaRemittanceActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>(OptimaRemittanceActivitiesDocument, options);
        }
export type OptimaRemittanceActivitiesQueryHookResult = ReturnType<typeof useOptimaRemittanceActivitiesQuery>;
export type OptimaRemittanceActivitiesLazyQueryHookResult = ReturnType<typeof useOptimaRemittanceActivitiesLazyQuery>;
export type OptimaRemittanceActivitiesSuspenseQueryHookResult = ReturnType<typeof useOptimaRemittanceActivitiesSuspenseQuery>;
export type OptimaRemittanceActivitiesQueryResult = Apollo.QueryResult<OptimaRemittanceActivitiesQuery, OptimaRemittanceActivitiesQueryVariables>;