import { DiagnosisValidProcedure } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { IDiagnosisValidProcedureUpdateContainerParams } from "../../types";
import { convertDiagnosisValidProcedureFormValuesToBackEndValues } from "../../others";
import {
  DiagnosisValidProcedureUpsertForm,
  IDiagnosisValidProcedureUpsertFormEvent,
} from "../../forms/DiagnosisValidProcedureUpsert/DiagnosisValidProcedureUpsertForm";
import { useDiagnosisValidProcedureUpdateMutation } from "../../gql/mutations/__generated__";
import { useDiagnosisValidProcedureGetQuery } from "../../gql/queries/__generated__";
import { useSetDiagnosisValidProceduresBreadcrumbs } from "../../hooks/useSetDiagnosisValidProceduresBreadcrumbs";

export const DiagnosisValidProcedureUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { diagnosisValidProcedureId } = useParams<IDiagnosisValidProcedureUpdateContainerParams>();

  const { data, loading } = useDiagnosisValidProcedureGetQuery({
    variables: { getDiagnosisValidProcedureId: diagnosisValidProcedureId! },
    skip: !diagnosisValidProcedureId,
    fetchPolicy: "no-cache",
  });

  const diagnosisValidProcedure = data?.getDiagnosisValidProcedure as DiagnosisValidProcedure;

  const [updateDiagnosisValidProcedure, { loading: isSubmitting }] = useDiagnosisValidProcedureUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.updateDiagnosisValidProcedure?.id) {
        succeeded(t("Diagnosis Valid Procedure updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDiagnosisValidProcedureUpsertFormChange = (event: IDiagnosisValidProcedureUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertDiagnosisValidProcedureFormValuesToBackEndValues(event.payload.values);

      updateDiagnosisValidProcedure({
        variables: {
          updateDiagnosisValidProcedureId: diagnosisValidProcedureId!,
          input: values,
        },
      });
    }
  };

  useSetDiagnosisValidProceduresBreadcrumbs("UPDATE", diagnosisValidProcedure?.procedureCode);

  return (
    <DiagnosisValidProcedureUpsertForm
      buttonLabel={t("Update")}
      diagnosisValidProcedure={diagnosisValidProcedure}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onDiagnosisValidProcedureUpsertFormChange}
    />
  );
};
