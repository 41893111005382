import { i18n } from "@toolkit/i18n";
import { PatientIdType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const patientIdTypeOptionsMap: IEnumToOptionsMap<PatientIdType> = {
  [PatientIdType.NationalCard]: {
    key: PatientIdType.NationalCard,
    get label() {
      return i18n.t("National Card", { ns: "domains" });
    },
    value: PatientIdType.NationalCard,
  },
  [PatientIdType.Passport]: {
    key: PatientIdType.Passport,
    get label() {
      return i18n.t("Passport", { ns: "domains" });
    },
    value: PatientIdType.Passport,
  },
  [PatientIdType.ResidentCard]: {
    key: PatientIdType.ResidentCard,
    get label() {
      return i18n.t("Resident Card", { ns: "domains" });
    },
    value: PatientIdType.ResidentCard,
  },
  [PatientIdType.BorderNumber]: {
    key: PatientIdType.BorderNumber,
    get label() {
      return i18n.t("Border Number", { ns: "domains" });
    },
    value: PatientIdType.BorderNumber,
  },
};

export const patientIdTypeOptions = Object.values(patientIdTypeOptionsMap);
