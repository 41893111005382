/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { startCase } from "lodash";
import { useMemo } from "react";
import { CallbackRequestUpdateHistoryQueryNodeType } from "./types";

export type CallbackRequestListColumnsTypes = CustomTableColumnProps<CallbackRequestUpdateHistoryQueryNodeType>[];

export const useCallbackHistoryColumns = (): CallbackRequestListColumnsTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "fullName",
        header: t("Admin Name"),
        type: "truncated-text",
        accessor: row => row?.user?.fullName,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: row => t(startCase(row?.status?.toLowerCase())),
      },
      {
        key: "comments",
        header: t("Comment"),
        type: "truncated-text",
        accessor: row => row?.comments,
      },
      {
        key: "requestDate",
        header: t("Date"),
        type: "date",
        accessor: "created",
      },
      {
        key: "requestTime",
        header: t("Time"),
        type: "time",
        accessor: "created",
      },
    ];
  }, [t]);
};
