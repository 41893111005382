import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";
import { addressUpsertFormDefaultValues, addressUpsertFormSchema } from "@/pages/Addresses";
import { zodDaysTimesRangesSchema } from "../../../../zodSchemas";
import { getDefaultDaysTimesRangesValues } from "../../../../utils";

export const branchUpsertFormSchema = z
  .object({
    name: z.string().min(3).max(255),
    nameAr: z.string().min(3).max(255),
    type: zodEnumSchema.vendorType,
    healthLicenseNumber: z.string().nullish(),
    healthLicenseStartDate: z.string().nullish(),
    healthLicenseEndDate: z.string().nullish(),
    email: z.string().email(),
    mobileNumber: z.string().min(1),
    description: z.string().max(255).nullish(),
    isActive: z.boolean(),
    acceptsDelivery: z.boolean().nullish(),
    workingHours: z.array(zodDaysTimesRangesSchema),
  })
  .merge(addressUpsertFormSchema);

export type IBranchUpsertForm = z.infer<typeof branchUpsertFormSchema>;

export const branchUpsertFormDefaultValues: Partial<IBranchUpsertForm> = {
  name: undefined,
  nameAr: undefined,
  email: undefined,
  type: undefined,
  mobileNumber: undefined,
  description: undefined,
  isActive: false,
  acceptsDelivery: false,
  healthLicenseNumber: undefined,
  healthLicenseStartDate: undefined,
  healthLicenseEndDate: undefined,
  workingHours: getDefaultDaysTimesRangesValues(),
  ...addressUpsertFormDefaultValues,
};
