import { z } from "zod";

export const cptHcpcPrimaryProcedureUpsertFormSchema = z.object({
  primaryProcedure: z.string().min(1).max(255),
  itemCode: z.string().min(1).max(255),
  description: z.string().min(1).max(255).optional(),
});
export type ICptHcpcPrimaryProcedureUpsertFormValues = z.infer<typeof cptHcpcPrimaryProcedureUpsertFormSchema>;

export const cptHcpcPrimaryProcedureUpsertFormDefaultValues: Partial<ICptHcpcPrimaryProcedureUpsertFormValues> = {
  primaryProcedure: "",
  itemCode: "",
  description: "",
};
