import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { benefitMapsPaths, benefitMapsRoute } from "./BenefitMapsPaths";
import { BenefitMapCreateContainer, BenefitMapUpdateContainer, BenefitMapListContainer } from "../containers";

export const benefitMapsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-medical-necessity-benefit-maps-routes",
    text: i18n.t("Benefit Maps", { ns: "admin" }),
    route: benefitMapsRoute,
    onClick: (route: string) => navigate(route),
    subItems: [
      {
        id: "optima-medical-necessity-benefit-map-list-route",
        route: benefitMapsPaths.list.route,
        fullPath: benefitMapsPaths.list.fullPath,
        element: <BenefitMapListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-medical-necessity-benefit-map-create-route",
        route: benefitMapsPaths.create.route,
        fullPath: benefitMapsPaths.create.fullPath,
        hidden: true,
        element: <BenefitMapCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-medical-necessity-benefit-map-update-route",
        route: benefitMapsPaths.update.route,
        fullPath: benefitMapsPaths.update.fullPath,
        hidden: true,
        element: <BenefitMapUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
