import { CodeSystemConceptFieldDefinition } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import FieldInput from "./FieldInput";
import EditAdminManagedListFormSkeleton from "./PageSkeleton";
import { useEditCentralListItemStyles } from "./styles";
import { useEditAdminManagedListDetailsHook } from "./useEditAdminManagedListDetailsHook";

const EditAdminManagedListDetailsPage: FC = () => {
  const { methods, isLoading, isFetchingUpdate, conceptFieldsDefenitions, codeDisplayName, isChanged, onSubmit } =
    useEditAdminManagedListDetailsHook();

  const { t } = useTranslation("domains");

  const { classes } = useEditCentralListItemStyles();

  return (
    <PageWrapper
      actions={
        <FormActions
          loadingIndicators={{
            edit: isFetchingUpdate,
          }}
          hasEdit
          hasCancel
          onEditItem={methods.handleSubmit(onSubmit)}
          isChanged={isChanged}
        />
      }
    >
      <FormCard
        doYouHaveData={!isLoading && conceptFieldsDefenitions.length > 0}
        loading={isLoading}
        title={t("Edit") + " " + codeDisplayName}
      >
        {isLoading ? (
          <EditAdminManagedListFormSkeleton />
        ) : (
          <FormProvider {...methods}>
            <div className={classes.root}>
              <Grid container spacing={2}>
                {conceptFieldsDefenitions?.map(fieldDefinition => {
                  return (
                    <Grid container alignItems={"center"} key={fieldDefinition?.code} item xs={12} sm={6} spacing={1}>
                      <Grid flex={1} item>
                        <FieldInput fieldDefenition={fieldDefinition as CodeSystemConceptFieldDefinition} />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </FormProvider>
        )}
      </FormCard>
    </PageWrapper>
  );
};

export default EditAdminManagedListDetailsPage;
