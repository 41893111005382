import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteCodeSystemConceptMutationVariables = Types.Exact<{
  codeSystemConceptId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type DeleteCodeSystemConceptMutation = { __typename?: 'Mutation', deleteCodeSystemConcept?: { __typename?: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', code?: string | null, fieldType?: Types.Type | null, valueDate?: any | null, valueBoolean?: boolean | null, valueFloat?: number | null, valueInteger?: number | null, valueString?: string | null } | null> | null } | null };


export const DeleteCodeSystemConceptDocument = gql`
    mutation deleteCodeSystemConcept($codeSystemConceptId: ID) {
  deleteCodeSystemConcept(codeSystemConceptId: $codeSystemConceptId) {
    id
    code
    display
    properties {
      code
      fieldType
      valueDate
      valueBoolean
      valueFloat
      valueInteger
      valueString
    }
  }
}
    `;
export type DeleteCodeSystemConceptMutationFn = Apollo.MutationFunction<DeleteCodeSystemConceptMutation, DeleteCodeSystemConceptMutationVariables>;

/**
 * __useDeleteCodeSystemConceptMutation__
 *
 * To run a mutation, you first call `useDeleteCodeSystemConceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCodeSystemConceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCodeSystemConceptMutation, { data, loading, error }] = useDeleteCodeSystemConceptMutation({
 *   variables: {
 *      codeSystemConceptId: // value for 'codeSystemConceptId'
 *   },
 * });
 */
export function useDeleteCodeSystemConceptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCodeSystemConceptMutation, DeleteCodeSystemConceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCodeSystemConceptMutation, DeleteCodeSystemConceptMutationVariables>(DeleteCodeSystemConceptDocument, options);
      }
export type DeleteCodeSystemConceptMutationHookResult = ReturnType<typeof useDeleteCodeSystemConceptMutation>;
export type DeleteCodeSystemConceptMutationResult = Apollo.MutationResult<DeleteCodeSystemConceptMutation>;
export type DeleteCodeSystemConceptMutationOptions = Apollo.BaseMutationOptions<DeleteCodeSystemConceptMutation, DeleteCodeSystemConceptMutationVariables>;