import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientEligibilityRequestHistoryQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID']['input'];
}>;


export type PatientEligibilityRequestHistoryQuery = { __typename?: 'Query', patientEligibilityRequestHistory?: { __typename?: 'PatientEligibilityRequestHistory', id: string, isFounded: boolean, isCompleted: boolean, updatedDate?: any | null, patientEligibility: { __typename?: 'OptimaPatientEligibility', firstName?: string | null, lastName?: string | null, summary?: string | null, mdSummary?: string | null, insuranceName?: string | null, dhaId?: string | null, policyNumber?: string | null, effectiveTo?: any | null, companyName?: string | null, network?: string | null, snapshots?: Array<string | null> | null, benefitEligibility?: Array<{ __typename?: 'BenefitEligibility', aiJustification?: string | null, benefit?: Types.Benefit | null, isEligible?: boolean | null } | null> | null, benefits?: Array<{ __typename?: 'PatientEligibilityBenefit', id: string, code?: string | null, name?: string | null, benefitType?: string | null, authorizationRequired?: boolean | null, coverage?: Array<{ __typename?: 'PatientEligibilityCoverage', usedUnits?: number | null, usedMoney?: number | null, roomType?: string | null, id: string, deductiblePercent?: number | null, deductibleMax?: number | null, deductibleAmount?: number | null, copayPercent?: number | null, copayMax?: number | null, copayAmount?: number | null, allowedUnits?: number | null, allowedMoney?: number | null } | null> | null } | null> | null }, payerEligibilityRequestHistories?: Array<{ __typename?: 'PayerEligibilityRequestHistory', id: string, failed?: boolean | null, isFounded?: boolean | null, insuranceName?: string | null, exceptionMessage?: string | null, exceptionScreenshotFileName?: string | null, updateDate?: string | null } | null> | null } | null };


export const PatientEligibilityRequestHistoryDocument = gql`
    query PatientEligibilityRequestHistory($patientId: ID!) {
  patientEligibilityRequestHistory(id: $patientId) {
    id
    isFounded
    isCompleted
    updatedDate
    patientEligibility {
      firstName
      lastName
      summary
      mdSummary
      insuranceName
      dhaId
      policyNumber
      effectiveTo
      companyName
      network
      benefitEligibility {
        aiJustification
        benefit
        isEligible
      }
      snapshots
      benefits {
        id
        code
        name
        benefitType
        authorizationRequired
        coverage {
          usedUnits
          usedMoney
          roomType
          id
          deductiblePercent
          deductibleMax
          deductibleAmount
          copayPercent
          copayMax
          copayAmount
          allowedUnits
          allowedMoney
        }
      }
    }
    payerEligibilityRequestHistories {
      id
      failed
      isFounded
      insuranceName
      exceptionMessage
      exceptionScreenshotFileName
      updateDate
    }
  }
}
    `;

/**
 * __usePatientEligibilityRequestHistoryQuery__
 *
 * To run a query within a React component, call `usePatientEligibilityRequestHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientEligibilityRequestHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientEligibilityRequestHistoryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientEligibilityRequestHistoryQuery(baseOptions: Apollo.QueryHookOptions<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables> & ({ variables: PatientEligibilityRequestHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables>(PatientEligibilityRequestHistoryDocument, options);
      }
export function usePatientEligibilityRequestHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables>(PatientEligibilityRequestHistoryDocument, options);
        }
export function usePatientEligibilityRequestHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables>(PatientEligibilityRequestHistoryDocument, options);
        }
export type PatientEligibilityRequestHistoryQueryHookResult = ReturnType<typeof usePatientEligibilityRequestHistoryQuery>;
export type PatientEligibilityRequestHistoryLazyQueryHookResult = ReturnType<typeof usePatientEligibilityRequestHistoryLazyQuery>;
export type PatientEligibilityRequestHistorySuspenseQueryHookResult = ReturnType<typeof usePatientEligibilityRequestHistorySuspenseQuery>;
export type PatientEligibilityRequestHistoryQueryResult = Apollo.QueryResult<PatientEligibilityRequestHistoryQuery, PatientEligibilityRequestHistoryQueryVariables>;