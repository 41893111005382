import { BulkTemplateCode } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Dialog, ExcelIcon, Typography, UploadCloudIcon, useTheme } from "@toolkit/ui";
import { ChangeEvent, DragEvent, FC, useRef, useState } from "react";
import { FileDownload } from "../FileDownload/FileDownload";
import { FileUpload } from "../FileUpload/FileUpload";

type FileImportDialogProps = {
  label?: string;
  title: string;
  subTitle: string;
  isOpen: boolean;
  templateCode: BulkTemplateCode;
  onSuccessUpload: () => void;
  onToggle: () => void;
};

export const FileImportDialog: FC<FileImportDialogProps> = props => {
  const { isOpen, label, title, subTitle, onToggle, templateCode, onSuccessUpload } = props;
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("domains");
  const theme = useTheme();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files?.[0];

    if (!fileObj) {
      return;
    }

    setFile(fileObj);
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const _file = event.dataTransfer.files?.[0];

    if (_file) {
      setFile(_file);
    }
  };

  const handleResetUploadFile = () => {
    setFile(null);
  };

  const handleSuccessUpload = () => {
    onSuccessUpload();
  };

  return (
    <>
      <Button sx={{ margin: "10px", paddingInline: 5, borderRadius: "10px" }} startIcon={<ExcelIcon />} onClick={onToggle}>
        {label || t("Import.xlx")}
      </Button>

      <Dialog open={isOpen} onClose={onToggle}>
        <Box sx={{ textAlign: "center", width: 450, padding: 2 }}>
          <Typography fontSize={24} fontWeight={theme.mixins.fonts.fontWeight.medium}>
            {title}
          </Typography>

          <Typography fontSize={16} fontWeight={theme.mixins.fonts.fontWeight.regular} sx={{ color: theme.palette.gray.main }} gutterBottom>
            {subTitle}
          </Typography>

          <Box
            sx={{ border: "1px dashed", borderRadius: 2, padding: 2 }}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {!file ? (
              <>
                <UploadCloudIcon />

                <Typography fontSize={16} fontWeight={theme.mixins.fonts.fontWeight.regular} sx={{ color: theme.palette.gray.main }}>
                  {t("Drag & Drop Your Files Here")}
                </Typography>
              </>
            ) : (
              <>
                <ExcelIcon
                  fill={theme.palette.success.main}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />

                <Typography fontSize={16} fontWeight={theme.mixins.fonts.fontWeight.regular} sx={{ color: theme.palette.gray.main }}>
                  {file?.name}
                </Typography>
              </>
            )}

            <Typography fontSize={16} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {t("OR")}
            </Typography>

            <Button sx={{ margin: "10px" }} onClick={handleClick}>
              {t("Browse Files")}
            </Button>

            <FileDownload label={t("Download Template")} templateCode={templateCode} isButton={false} />
          </Box>
        </Box>

        <Box sx={{ boxShadow: theme.mixins.shadows.xs }}>
          {/* eslint-disable-next-line react/forbid-dom-props */}
          <input style={{ display: "none" }} ref={inputRef} type={"file"} onChange={handleFileChange} />

          <FileUpload
            file={file}
            templateCode={templateCode}
            onToggle={onToggle}
            onResetUploadFile={handleResetUploadFile}
            onSuccessUpload={handleSuccessUpload}
          />

          <Button sx={{ margin: "10px", border: "1px solid" }} variant={"outlined"} onClick={onToggle}>
            {t("Close")}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
