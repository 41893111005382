import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchLicenseIdGetQueryVariables = Types.Exact<{
  value: Types.Scalars['String']['input'];
}>;


export type BranchLicenseIdGetQuery = { __typename?: 'Query', getCodeSystemConceptsByCode?: { __typename?: 'CodeSystemConceptConnection', edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', id?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', id?: string | null, code?: string | null, fieldType?: Types.Type | null, valueString?: string | null, valueInteger?: number | null, valueFloat?: number | null, valueBoolean?: boolean | null, valueDate?: any | null } | null> | null } | null } | null> | null } | null };


export const BranchLicenseIdGetDocument = gql`
    query BranchLicenseIdGet($value: String!) {
  getCodeSystemConceptsByCode(
    first: 1
    codeSystemCode: HEALTH_PROVIDER
    searchParameters: [{code: "code", valueString: $value, type: STRING}]
  ) {
    edges {
      node {
        id
        properties {
          id
          code
          fieldType
          valueString
          valueInteger
          valueFloat
          valueBoolean
          valueDate
        }
      }
    }
  }
}
    `;

/**
 * __useBranchLicenseIdGetQuery__
 *
 * To run a query within a React component, call `useBranchLicenseIdGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchLicenseIdGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchLicenseIdGetQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useBranchLicenseIdGetQuery(baseOptions: Apollo.QueryHookOptions<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables> & ({ variables: BranchLicenseIdGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables>(BranchLicenseIdGetDocument, options);
      }
export function useBranchLicenseIdGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables>(BranchLicenseIdGetDocument, options);
        }
export function useBranchLicenseIdGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables>(BranchLicenseIdGetDocument, options);
        }
export type BranchLicenseIdGetQueryHookResult = ReturnType<typeof useBranchLicenseIdGetQuery>;
export type BranchLicenseIdGetLazyQueryHookResult = ReturnType<typeof useBranchLicenseIdGetLazyQuery>;
export type BranchLicenseIdGetSuspenseQueryHookResult = ReturnType<typeof useBranchLicenseIdGetSuspenseQuery>;
export type BranchLicenseIdGetQueryResult = Apollo.QueryResult<BranchLicenseIdGetQuery, BranchLicenseIdGetQueryVariables>;