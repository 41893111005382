import Geocode from "react-geocode";
import { mapConfig } from "../mapConfig";
import { IGoogleMapAddress } from "./LocationPicker";

export const useLocationHooks = () => {
  Geocode.setApiKey(mapConfig.get("googleApiKey"));
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");

  const parseAddressComponents = (result: google.maps.GeocoderResult): Partial<IGoogleMapAddress> => {
    let city: IGoogleMapAddress["city"], state: IGoogleMapAddress["state"], country: IGoogleMapAddress["country"];
    const components = result.address_components || [];

    for (const component of components) {
      const types = component.types || [];
      if (types.includes("locality")) city = component.long_name;
      if (types.includes("administrative_area_level_1")) state = component.long_name;
      if (types.includes("country")) country = component.long_name;
    }

    return {
      city,
      state,
      country,
      streetAddress1: result.formatted_address,
      formatted_address: result.formatted_address,
      address_components: components,
    };
  };

  const getLocationResult = async (lat: string, lng: string): Promise<IGoogleMapAddress> => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);
      const result = response.results[0];
      const addressData = parseAddressComponents(result);

      return {
        ...addressData,
        coordinates: { lat: Number(lat), lng: Number(lng) },
      } as IGoogleMapAddress;
    } catch (error) {
      console.error(error);
      return { coordinates: { lat: Number(lat), lng: Number(lng) } };
    }
  };

  const getAddressFromLatLng = getLocationResult;

  const getLatLngFromAddress = async (address: string): Promise<IGoogleMapAddress["coordinates"] | undefined> => {
    try {
      const response = await Geocode.fromAddress(address);
      return response.results[0].geometry.location;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  return { getLatLngFromAddress, getLocationResult, getAddressFromLatLng };
};
