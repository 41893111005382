import { useNavigate } from "react-router-dom";
import { IUserProviderListContainerEvent, UserProviderListContainer } from "@health/domains";
import { usersProvidersPaths } from "../../constants";
import { useSetUsersProviderBreadcrumbs } from "../../hooks";

export const AdminUserProviderListContainer = () => {
  const navigate = useNavigate();

  const onUserProviderListContainerChange = (event: IUserProviderListContainerEvent) => {
    if (event.type === "ADD_NEW_CLICK") {
      navigate(usersProvidersPaths.create.fullPath);
    } else if (event.type === "EDIT_CLICK") {
      navigate(usersProvidersPaths.update.fullPathWithParams({ userProviderId: event.payload?.item?.id }));
    }
  };

  useSetUsersProviderBreadcrumbs("LIST");

  return <UserProviderListContainer onChange={onUserProviderListContainerChange} />;
};
