import { H_EntityError } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { HandelCallbackRequestMutation, useHandelCallbackRequestMutation } from "../../../../gql";
import { useOpenState } from "@toolkit/core";

export const useCallbackRequestHandleHook = (reset, id?: string) => {
  const { t } = useTranslation("admin");
  const [errors, setErrors] = useState<H_EntityError[]>([]);

  const { succeeded, failed } = useAddToast();
  const { open: isOpen, handleOpen: handleOpenState, handleClose, handleToggle } = useOpenState();

  const [handelCallbackRequest, { loading }] = useHandelCallbackRequestMutation({
    onCompleted: (mutationData: HandelCallbackRequestMutation) => {
      const error = mutationData?.callbackRequestUpdate?.entityErrors as H_EntityError[];
      if (error && error?.length > 0) {
        failed(t(formatMessageErrors(error)));
        setErrors(error);
      } else {
        succeeded(t("Request Handled successfully"));
        handleClose();
        reset();
      }
    },
    onError: () => {
      failed(t("Failed in handling request"));
    },
  });

  const handleOpen = e => {
    e.stopPropagation();
    handleOpenState();
  };

  const handleUpdate = inputData => {
    handelCallbackRequest({
      variables: {
        callbackRequestUpdateId: id as string,
        input: {
          ...inputData,
          status: inputData?.status?.value,
        },
      },
    });
  };
  return { handleUpdate, errors, loading, handleToggleDialog: handleToggle, handleOpen, isOpen };
};
