import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type SublistGetQuery = { __typename?: 'Query', getSubList?: { __typename?: 'SubList', id?: string | null, display?: string | null, field?: { __typename?: 'Field', code?: string | null, display?: string | null, id?: string | null } | null } | null };


export const SublistGetDocument = gql`
    query SublistGet($id: ID!) {
  getSubList(id: $id) {
    id
    display
    field {
      code
      display
      id
    }
  }
}
    `;

/**
 * __useSublistGetQuery__
 *
 * To run a query within a React component, call `useSublistGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSublistGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSublistGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSublistGetQuery(baseOptions: Apollo.QueryHookOptions<SublistGetQuery, SublistGetQueryVariables> & ({ variables: SublistGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SublistGetQuery, SublistGetQueryVariables>(SublistGetDocument, options);
      }
export function useSublistGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SublistGetQuery, SublistGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SublistGetQuery, SublistGetQueryVariables>(SublistGetDocument, options);
        }
export function useSublistGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SublistGetQuery, SublistGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SublistGetQuery, SublistGetQueryVariables>(SublistGetDocument, options);
        }
export type SublistGetQueryHookResult = ReturnType<typeof useSublistGetQuery>;
export type SublistGetLazyQueryHookResult = ReturnType<typeof useSublistGetLazyQuery>;
export type SublistGetSuspenseQueryHookResult = ReturnType<typeof useSublistGetSuspenseQuery>;
export type SublistGetQueryResult = Apollo.QueryResult<SublistGetQuery, SublistGetQueryVariables>;