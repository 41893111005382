import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ProviderAddressFragmentFragmentDoc } from '../../fragments/__generated__/ProviderAddress';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.VendorOrder>;
  filter?: Types.InputMaybe<Types.VendorFilterInput>;
}>;


export type ProviderListQuery = { __typename?: 'Query', vendors?: { __typename?: 'VendorCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VendorCountableEdge', node: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, ownerName: string, nationalId: string, logo?: string | null, type?: Types.VendorType | null, approved: boolean, isActive: boolean, supportOutpatientJourney: boolean, created: any, address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, area: string, areaAr: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null, branches?: { __typename?: 'BranchCountableConnection', edges: Array<{ __typename?: 'BranchCountableEdge', node: { __typename?: 'Branch', id: string, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } }> } | null } }> } | null };


export const ProviderListDocument = gql`
    query ProviderList($first: Int, $last: Int, $after: String, $before: String, $sortBy: VendorOrder, $filter: VendorFilterInput) {
  vendors(
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        nameAr
        ownerName
        nationalId
        logo
        type
        approved
        isActive
        supportOutpatientJourney
        created
        address {
          ...ProviderAddressFragment
        }
        branches(first: 1) {
          edges {
            node {
              id
              workingHours {
                day
                openTimeRanges {
                  openTime
                  closeTime
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ProviderAddressFragmentFragmentDoc}`;

/**
 * __useProviderListQuery__
 *
 * To run a query within a React component, call `useProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProviderListQuery(baseOptions?: Apollo.QueryHookOptions<ProviderListQuery, ProviderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderListQuery, ProviderListQueryVariables>(ProviderListDocument, options);
      }
export function useProviderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderListQuery, ProviderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderListQuery, ProviderListQueryVariables>(ProviderListDocument, options);
        }
export function useProviderListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderListQuery, ProviderListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderListQuery, ProviderListQueryVariables>(ProviderListDocument, options);
        }
export type ProviderListQueryHookResult = ReturnType<typeof useProviderListQuery>;
export type ProviderListLazyQueryHookResult = ReturnType<typeof useProviderListLazyQuery>;
export type ProviderListSuspenseQueryHookResult = ReturnType<typeof useProviderListSuspenseQuery>;
export type ProviderListQueryResult = Apollo.QueryResult<ProviderListQuery, ProviderListQueryVariables>;