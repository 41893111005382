import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DoctorPatientsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  doctorId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type DoctorPatientsAutocompleteQuery = { __typename?: 'Query', doctorTeamEnrolledMembers?: { __typename?: 'HealthProgramMemberCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', node: { __typename?: 'HealthProgramMember', user?: { __typename?: 'User', id: string, fullName?: string | null } | null } }> } | null };


export const DoctorPatientsAutocompleteDocument = gql`
    query DoctorPatientsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $doctorId: ID, $teamId: ID) {
  doctorTeamEnrolledMembers(
    first: $first
    last: $last
    after: $after
    before: $before
    doctorId: $doctorId
    teamId: $teamId
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        user {
          id
          fullName
        }
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useDoctorPatientsAutocompleteQuery__
 *
 * To run a query within a React component, call `useDoctorPatientsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorPatientsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorPatientsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      doctorId: // value for 'doctorId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useDoctorPatientsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>(DoctorPatientsAutocompleteDocument, options);
      }
export function useDoctorPatientsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>(DoctorPatientsAutocompleteDocument, options);
        }
export function useDoctorPatientsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>(DoctorPatientsAutocompleteDocument, options);
        }
export type DoctorPatientsAutocompleteQueryHookResult = ReturnType<typeof useDoctorPatientsAutocompleteQuery>;
export type DoctorPatientsAutocompleteLazyQueryHookResult = ReturnType<typeof useDoctorPatientsAutocompleteLazyQuery>;
export type DoctorPatientsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDoctorPatientsAutocompleteSuspenseQuery>;
export type DoctorPatientsAutocompleteQueryResult = Apollo.QueryResult<DoctorPatientsAutocompleteQuery, DoctorPatientsAutocompleteQueryVariables>;