import React, { FC } from "react";
import { Box, ClickAwayListener, IconButton, Tooltip } from "../../base/mui";
import { QuastionMarkIcon } from "../../icons/icons/QuastionMarkIcon";
import { useHintComponentStyles } from "./HintComponent.styles";
import { useOpenState } from "@toolkit/core";

export interface HintComponentProps {
  title?: string;
}

const HintComponent: FC<HintComponentProps> = props => {
  const { title } = props;

  const { open, handleOpen, handleClose } = useOpenState();

  const { classes } = useHintComponentStyles();

  return (
    <Box display='flex' justifyContent='center' alignContent='center'>
      <ClickAwayListener onClickAway={handleClose}>
        <Tooltip
          title={title!}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleClose}
        >
          <IconButton className={open ? classes.openIcon : classes.icon} onClick={handleOpen}>
            <QuastionMarkIcon />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default HintComponent;
