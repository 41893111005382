import { Address, AddressInput, Maybe } from "@/schema/types";
import { createCityAutocompleteOption } from "@health/autocompletes";

export const convertAddressToFormValues = (address?: Maybe<Address>) => {
  return address
    ? {
        streetAddress1: address?.streetAddress1!,
        streetAddress1Ar: address?.streetAddress1Ar,
        streetAddress2: address?.streetAddress2!,
        streetAddress2Ar: address?.streetAddress2Ar,
        area: address?.area,
        areaAr: address?.areaAr,
        city: createCityAutocompleteOption(address?.city!),
        postalCode: address?.postalCode!,
        coordinates: {
          lat: address?.coordinates?.lat!,
          lng: address?.coordinates?.lng!,
        },
      }
    : undefined;
};

export const convertAddressFormValuesToBackEndValues = (values: any): AddressInput => {
  return {
    streetAddress1: values?.streetAddress1,
    streetAddress1Ar: values?.streetAddress1Ar,
    streetAddress2: values?.streetAddress2,
    streetAddress2Ar: values?.streetAddress2Ar,
    area: values?.area,
    areaAr: values?.areaAr,
    city: values?.city?.value?.id,
    postalCode: values?.postalCode,
    coordinates: {
      lat: values?.coordinates?.lat,
      lng: values?.coordinates?.lng,
    },
  };
};
