import { ICredentialFormValues } from "./CredentialForm/CredentialFormSchema";
import { credentialQueryType } from "./type";

export const convertCredentialFormValuesToBackEndValues = (values: ICredentialFormValues) => {
  return {
    userName: values.username,
    password: values.password || "",
    license: values.license || "",
  } as const;
};

export const convertCredentialToFormValues = (credential: credentialQueryType): Omit<ICredentialFormValues, "destination"> => {
  return {
    username: credential?.username!,
    password: "",
    license: credential.license!,
  };
};
