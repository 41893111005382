import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SiteSettingsUpdateMutationVariables = Types.Exact<{
  input: Types.SiteSettingsInput;
}>;


export type SiteSettingsUpdateMutation = { __typename?: 'Mutation', siteSettingsUpdate?: { __typename?: 'SiteSettings', vatPercentage?: number | null, orderDeliveryTimeout?: number | null, orderBaseDeliveryFee?: number | null, orderAcceptanceTimeout?: number | null, orderDispensingTimeout?: number | null, defaultCurrency: string, orderErxHubApprovalTimeout?: number | null } | null };


export const SiteSettingsUpdateDocument = gql`
    mutation siteSettingsUpdate($input: SiteSettingsInput!) {
  siteSettingsUpdate(input: $input) {
    vatPercentage
    orderDeliveryTimeout
    orderBaseDeliveryFee
    orderAcceptanceTimeout
    orderDispensingTimeout
    defaultCurrency
    orderErxHubApprovalTimeout
  }
}
    `;
export type SiteSettingsUpdateMutationFn = Apollo.MutationFunction<SiteSettingsUpdateMutation, SiteSettingsUpdateMutationVariables>;

/**
 * __useSiteSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useSiteSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteSettingsUpdateMutation, { data, loading, error }] = useSiteSettingsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteSettingsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SiteSettingsUpdateMutation, SiteSettingsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SiteSettingsUpdateMutation, SiteSettingsUpdateMutationVariables>(SiteSettingsUpdateDocument, options);
      }
export type SiteSettingsUpdateMutationHookResult = ReturnType<typeof useSiteSettingsUpdateMutation>;
export type SiteSettingsUpdateMutationResult = Apollo.MutationResult<SiteSettingsUpdateMutation>;
export type SiteSettingsUpdateMutationOptions = Apollo.BaseMutationOptions<SiteSettingsUpdateMutation, SiteSettingsUpdateMutationVariables>;