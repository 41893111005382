import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalFormDeactivateMutationVariables = Types.Exact<{
  deactivateMedicalFormId: Types.Scalars['ID']['input'];
}>;


export type MedicalFormDeactivateMutation = { __typename?: 'Mutation', deactivateMedicalForm?: { __typename?: 'MedicalForm', active?: boolean | null, errors?: Array<{ __typename?: 'MedicalFormGraphqlError', errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const MedicalFormDeactivateDocument = gql`
    mutation MedicalFormDeactivate($deactivateMedicalFormId: ID!) {
  deactivateMedicalForm(id: $deactivateMedicalFormId) {
    active
    errors {
      errorType
      field
      message
    }
  }
}
    `;
export type MedicalFormDeactivateMutationFn = Apollo.MutationFunction<MedicalFormDeactivateMutation, MedicalFormDeactivateMutationVariables>;

/**
 * __useMedicalFormDeactivateMutation__
 *
 * To run a mutation, you first call `useMedicalFormDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalFormDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalFormDeactivateMutation, { data, loading, error }] = useMedicalFormDeactivateMutation({
 *   variables: {
 *      deactivateMedicalFormId: // value for 'deactivateMedicalFormId'
 *   },
 * });
 */
export function useMedicalFormDeactivateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalFormDeactivateMutation, MedicalFormDeactivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalFormDeactivateMutation, MedicalFormDeactivateMutationVariables>(MedicalFormDeactivateDocument, options);
      }
export type MedicalFormDeactivateMutationHookResult = ReturnType<typeof useMedicalFormDeactivateMutation>;
export type MedicalFormDeactivateMutationResult = Apollo.MutationResult<MedicalFormDeactivateMutation>;
export type MedicalFormDeactivateMutationOptions = Apollo.BaseMutationOptions<MedicalFormDeactivateMutation, MedicalFormDeactivateMutationVariables>;