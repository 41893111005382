import { DecisionPlan, DecisionPlanCategory } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { DecisionUpsertForm, IDecisionUpsertFormEvent } from "../../forms/DecisionUpsert/DecisionUpsertForm";
import { useDecisionCreateMutation } from "../../gql";
import { convertDecisionFormValuesToBackEndValues } from "../../others";

export type IDecisionCreateEvent = {
  type: "CREATE";
};

type DecisionCreateProps = {
  category: DecisionPlanCategory;
  healthParameterCode?: string;
  guidedCareTemplateCode?: string;
  ioAgentCode?: string;
  selectedDecisionPlan?: DecisionPlan;
  onChange: (event: IDecisionCreateEvent) => void;
};

export const DecisionCreate: FC<DecisionCreateProps> = props => {
  const { category, healthParameterCode, guidedCareTemplateCode, ioAgentCode, selectedDecisionPlan, onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [createDecision, { loading: isSubmitting }] = useDecisionCreateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.addDecision?.errors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Decision created successfully"));

        onChange({
          type: "CREATE",
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDecisionUpsertFormChange = (event: IDecisionUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertDecisionFormValuesToBackEndValues(
        event.payload.values,
        event.payload.decisionPlan,
        guidedCareTemplateCode,
        healthParameterCode,
        ioAgentCode
      );

      createDecision({
        variables: {
          decision: {
            ...values,
          },
        },
      });
    }
  };

  return (
    <DecisionUpsertForm
      buttonLabel={t("Create")}
      category={category}
      healthParameterCode={healthParameterCode}
      guidedCareTemplateCode={guidedCareTemplateCode}
      ioAgentCode={ioAgentCode}
      selectedDecisionPlan={selectedDecisionPlan}
      isButtonDisabled={isSubmitting}
      onChange={onDecisionUpsertFormChange}
    />
  );
};
