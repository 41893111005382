import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, FormTextField, Grid } from "@toolkit/ui";
import { optimaEncounterTypeOptions } from "@health/enum-options";
import { FC } from "react";

export const InsuranceBenefitApprovalLimitInformationForm: FC = () => {
  const { t } = useTranslation("admin");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"itemCode"} label={t("Item Code")} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"insuranceLicense"} label={t("Insurance License")} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"insurancePolicy"} label={t("Insurance Policy")} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormAutocomplete name={"encounterType"} label={t("Encounter Type")} options={optimaEncounterTypeOptions} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"source"} label={t("Source")} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormNumberField name={"limit"} label={t("Limit")} />
        </Grid>
      </Grid>
      <Grid item xs={6} mt={2}>
        <FormTextField name='description' label={t("Description")} multiline maxRows={3} minRows={3} />
      </Grid>
    </>
  );
};
