import { BenefitMap, BenefitMapInput } from "@/schema/types";
import { IBenefitMapUpsertFormValues } from "../forms/BenefitMapUpsert/benefitMapUpsertFormSchema";

export const convertBenefitMapToFormValues = (benefitMap: BenefitMap): IBenefitMapUpsertFormValues => {
  return {
    benefitCode: benefitMap.benefitCode,
    itemCode: benefitMap.itemCode,
    description: benefitMap.description ?? undefined,
  };
};

export const convertBenefitMapFormValuesToBackEndValues = (values: IBenefitMapUpsertFormValues): BenefitMapInput => {
  return {
    benefitCode: values.benefitCode,
    itemCode: values.itemCode,
    description: values.description,
  };
};
