import { useCallback, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";

export const useSetBulkMessageSenderBreadcrumbs = (containerType: "SEND") => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Bulk Message Sender");

  const setBulkMessageSenderBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  useEffect(() => {
    if (containerType === "SEND") {
      setBulkMessageSenderBreadcrumb();
    }
  }, [containerType, setBulkMessageSenderBreadcrumb]);
};
