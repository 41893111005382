import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { CityFilterInput } from "../../schema/types";
import { useCitiesAutocompleteQuery } from "./gql";

type CitiesAutocompleteProps = IAutocompleteProps<CityFilterInput>;

export const CitiesAutocomplete: FC<CitiesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useCitiesAutocompleteQuery,
    variables: { filter },
    searchKey: "name_Icontains",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.name, item?.nameAr!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Cities") : t("City"))}
    />
  );
};
