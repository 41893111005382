import { CallbackRequest } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, Divider, Stack } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RequestHandlingForm } from "./components//RequestHandlingForm";
import { useCallbackRequestHandleHook } from "./components/CallbackRequestHandle.hook";
import { PatientNote } from "./components/PatientNote";
import { RequestDetails } from "./components/ReqestDetails";
import { RequestProcessingLog } from "./components/RequestProcessingLog/RequestProcessingLog";
export const RequestHandlingModal: FC<{ callBackRequest?: CallbackRequest }> = ({ callBackRequest }) => {
  const { t } = useTranslation("admin");
  const methods = useForm();
  const { handleSubmit, reset } = methods;
  const { errors, handleUpdate, handleOpen, isOpen, handleToggleDialog, loading } = useCallbackRequestHandleHook(
    reset,
    callBackRequest?.id
  );
  const onSubmit = data => {
    const request = {
      ...data,
    };
    handleUpdate(request);
  };
  return (
    <CustomDialog
      type='base'
      open={isOpen}
      DialogTitleProps={{
        title: t("Handle Request"),
        onClose: handleToggleDialog,
      }}
      button={<Button onClick={handleOpen}>{t("Handle")}</Button>}
      DialogActionsProps={{
        children: (
          <Button variant='outlined' onClick={handleToggleDialog}>
            {t("Close")}
          </Button>
        ),
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Stack
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={2}
              divider={<Divider sx={{ width: "100%" }} />}
            >
              <RequestDetails callBackRequest={callBackRequest} />
              <PatientNote note={callBackRequest?.patientNotes} />
              <RequestHandlingForm errors={errors} loading={loading} />
              <RequestProcessingLog id={callBackRequest?.id || ""} />
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </CustomDialog>
  );
};
