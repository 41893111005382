import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderBranchBreadcrumbGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProviderBranchBreadcrumbGetQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null };


export const ProviderBranchBreadcrumbGetDocument = gql`
    query ProviderBranchBreadcrumbGet($id: ID!) {
  branch(id: $id) {
    id
    name
    nameAr
  }
}
    `;

/**
 * __useProviderBranchBreadcrumbGetQuery__
 *
 * To run a query within a React component, call `useProviderBranchBreadcrumbGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderBranchBreadcrumbGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderBranchBreadcrumbGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderBranchBreadcrumbGetQuery(baseOptions: Apollo.QueryHookOptions<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables> & ({ variables: ProviderBranchBreadcrumbGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables>(ProviderBranchBreadcrumbGetDocument, options);
      }
export function useProviderBranchBreadcrumbGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables>(ProviderBranchBreadcrumbGetDocument, options);
        }
export function useProviderBranchBreadcrumbGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables>(ProviderBranchBreadcrumbGetDocument, options);
        }
export type ProviderBranchBreadcrumbGetQueryHookResult = ReturnType<typeof useProviderBranchBreadcrumbGetQuery>;
export type ProviderBranchBreadcrumbGetLazyQueryHookResult = ReturnType<typeof useProviderBranchBreadcrumbGetLazyQuery>;
export type ProviderBranchBreadcrumbGetSuspenseQueryHookResult = ReturnType<typeof useProviderBranchBreadcrumbGetSuspenseQuery>;
export type ProviderBranchBreadcrumbGetQueryResult = Apollo.QueryResult<ProviderBranchBreadcrumbGetQuery, ProviderBranchBreadcrumbGetQueryVariables>;