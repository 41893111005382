import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { FC, useEffect, useRef, useState } from "react";
import {
  BulkMessageSenderUsersUpsertForm,
  BulkMessageSenderUsersUpsertFormRef,
  IBulkMessageSenderUsersUpsertFormEvent,
} from "../../forms/BulkMessageSenderUsers/BulkMessageSenderUsersForm";
import { ModalApi } from "../../others/types";
import { IBulkMessageSenderFormValues } from "../../forms/BulkMessageSender/BulkMessageSenderFormSchema";
import { IBulkMessageSenderUsersFormValues } from "../../forms/BulkMessageSenderUsers/BulkMessageSenderUsersFormSchema";

export const BulkMessageSenderUsersModalApi: ModalApi<IBulkMessageSenderFormValues> = {
  open: () => {},
  close: () => {},
};

type BulkMessageSenderUsersModalProps = {
  isLoading: boolean;
  hasClose: boolean;
  onFormValuesSubmit: (values: IBulkMessageSenderFormValues & IBulkMessageSenderUsersFormValues) => void;
};

export const BulkMessageSenderUsersModal: FC<BulkMessageSenderUsersModalProps> = ({ isLoading, hasClose, onFormValuesSubmit }) => {
  const { t } = useTranslation("admin");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();
  const [senderData, setSenderData] = useState<IBulkMessageSenderFormValues | null>(null);
  const formRef = useRef<BulkMessageSenderUsersUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const handleOpenWithData = (data?: IBulkMessageSenderFormValues) => {
    setSenderData(data ?? null);
    handleOpen();
  };

  const handleFormChange = (event: IBulkMessageSenderUsersUpsertFormEvent) => {
    if (!senderData) return;
    onFormValuesSubmit({ ...senderData, ...event.payload.values });
  };

  useEffect(() => {
    BulkMessageSenderUsersModalApi.open = handleOpenWithData;
    BulkMessageSenderUsersModalApi.close = handleClose;
    return () => {
      BulkMessageSenderUsersModalApi.open = () => {};
      BulkMessageSenderUsersModalApi.close = () => {};
    };
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='sm'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: t("Publish Bulk Message to Users"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {t("Publish")}
          </Button>
        ),
      }}
    >
      <BulkMessageSenderUsersUpsertForm ref={formRef} onChange={handleFormChange} />
    </CustomDialog>
  );
};
