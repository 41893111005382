import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { ChatMessageIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { bulkMessageSenderPaths } from "./BulkMessageSenderPaths";
import { BulkMessageSenderContainer } from "../containers";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";

export const bulkMessageSenderRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "bulk-messages-routes",
    text: i18n.t("Bulk Messages Management", { ns: "admin" }),
    icon: <ChatMessageIcon />,
    isProhibited: !hasPermission(PermissionEnum.SendBulkMessages),
    subItems: [
      {
        id: "bulk-messages-list-route",
        route: bulkMessageSenderPaths.send.fullPath,
        fullPath: bulkMessageSenderPaths.send.fullPath,
        element: <BulkMessageSenderContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
