import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { LanguageFilterInput } from "../../schema/types";
import { useLanguagesAutocompleteQuery } from "./gql";

type LanguagesAutocompleteProps = IAutocompleteProps<LanguageFilterInput>;

export const LanguagesAutocomplete: FC<LanguagesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useLanguagesAutocompleteQuery,
    variables: { filter },
    searchKey: "display",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "code", item => pickLocalizedValue(item?.display!, item?.displayAr!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Languages") : t("Language"))}
    />
  );
};
