import { createZodAutocomplete } from "@toolkit/core";
import { YesNo } from "../enums";
import {
  ActionFieldType,
  AppointmentFrequencyType,
  AppointmentPaymentType,
  AppointmentStatus,
  AppointmentType,
  AppRoleTypes,
  ArticleCategoryType,
  AssigneeType,
  Benefit,
  CallbackRequestStatus,
  CareType,
  ChartType,
  CodeSystemCode,
  ConditionOperation,
  ConferenceProviderType,
  Coverage,
  CoverageByType,
  CustomerSupportOrderStatusEnum,
  DayOfWeekEnum,
  DestinationType,
  DoctorSeniorityEnum,
  EligabilityPayersEnum,
  EncounterType,
  FieldStandard,
  GuidedCareActivityType,
  GuidedCareJourneyItemSource,
  GuidedCareType,
  HealthConditionType,
  HealthDocumentType,
  HealthProgramBenefitOptionCode,
  HealthProgramOptionType,
  HealthProgramSubscriptionPeriod,
  HealthProgramType,
  ManualOrderStatus,
  MarketplaceApprovalStatus,
  MarketplaceDiscountType,
  MarketplaceGender,
  MarketplaceOrderStatus,
  MarketplaceOrderType,
  MedicalFormCategory,
  MedicalFormType,
  MedicalMessageCategory,
  MedicalMessageType,
  NotificationFrequency,
  Operator,
  OptimaEditLevel,
  OptimaAgentPayer,
  OptimaPriority,
  OrderStatusEnum,
  PatientIdType,
  PayerTpo,
  PayerType,
  PaymentSource,
  PaymentStatus,
  PaymentType,
  PlanPeriod,
  Priority,
  ProviderGuidedCareTeamStatus,
  QuestionType,
  RequestedBy,
  SendBulkMessagesMethodEnum,
  Source,
  TaskDefinitionStatus,
  TaskWorkflowType,
  TeamMemberPosition,
  TemplateFieldType,
  TimeOfDay,
  UserGender,
  UserStatus,
  VendorTypeEnum,
  VendorUserTypes,
  VisitDiagnosisType,
  VisitStatus,
  VisitType,
} from "../schema/types";

export const zodEnumSchema = {
  actionFieldType: createZodAutocomplete(ActionFieldType),
  appointmentFrequencyType: createZodAutocomplete(AppointmentFrequencyType),
  appointmentPaymentType: createZodAutocomplete(AppointmentPaymentType),
  appointmentStatus: createZodAutocomplete(AppointmentStatus),
  appointmentType: createZodAutocomplete(AppointmentType),
  appRoleType: createZodAutocomplete(AppRoleTypes),
  articleCategoryType: createZodAutocomplete(ArticleCategoryType),
  assigneeType: createZodAutocomplete(AssigneeType),
  bulkMessagesMethodEnum: createZodAutocomplete(SendBulkMessagesMethodEnum),
  callbackRequestStatus: createZodAutocomplete(CallbackRequestStatus),
  careType: createZodAutocomplete(CareType),
  conferenceProviderType: createZodAutocomplete(ConferenceProviderType),
  chartType: createZodAutocomplete(ChartType),
  systemCode: createZodAutocomplete(CodeSystemCode),
  conditionOperation: createZodAutocomplete(ConditionOperation),
  coverage: createZodAutocomplete(Coverage),
  coverageByType: createZodAutocomplete(CoverageByType),
  customerSupportOrderStatus: createZodAutocomplete(CustomerSupportOrderStatusEnum),
  dayOfWeek: createZodAutocomplete(DayOfWeekEnum),
  destinationType: createZodAutocomplete(DestinationType),
  doctorSeniority: createZodAutocomplete(DoctorSeniorityEnum),
  fieldStandard: createZodAutocomplete(FieldStandard),
  guidedCareActivityType: createZodAutocomplete(GuidedCareActivityType),
  guidedCareJourneyItemSource: createZodAutocomplete(GuidedCareJourneyItemSource),
  guidedCareType: createZodAutocomplete(GuidedCareType),
  healthConditionType: createZodAutocomplete(HealthConditionType),
  healthDocumentType: createZodAutocomplete(HealthDocumentType),
  healthProgramBenefitOptionCode: createZodAutocomplete(HealthProgramBenefitOptionCode),
  healthProgramOptionType: createZodAutocomplete(HealthProgramOptionType),
  healthProgramSubscriptionPeriod: createZodAutocomplete(HealthProgramSubscriptionPeriod),
  healthProgramType: createZodAutocomplete(HealthProgramType),
  ManualOrderStatus: createZodAutocomplete(ManualOrderStatus),
  marketplaceApprovalStatus: createZodAutocomplete(MarketplaceApprovalStatus),
  marketplaceDiscountType: createZodAutocomplete(MarketplaceDiscountType),
  marketplaceOrderStatus: createZodAutocomplete(MarketplaceOrderStatus),
  marketplaceOrderType: createZodAutocomplete(MarketplaceOrderType),
  marketplaceGenderType: createZodAutocomplete(MarketplaceGender),
  medicalFormCategory: createZodAutocomplete(MedicalFormCategory),
  medicalFormType: createZodAutocomplete(MedicalFormType),
  medicalMessageCategory: createZodAutocomplete(MedicalMessageCategory),
  medicalMessageType: createZodAutocomplete(MedicalMessageType),
  notificationFrequency: createZodAutocomplete(NotificationFrequency),
  operator: createZodAutocomplete(Operator),
  encounterType: createZodAutocomplete(EncounterType),
  optimaEditLevel: createZodAutocomplete(OptimaEditLevel),
  optimaPriority: createZodAutocomplete(OptimaPriority),
  optimaAgentPayer: createZodAutocomplete(OptimaAgentPayer),
  optimaEligabilityPayers: createZodAutocomplete(EligabilityPayersEnum),
  orderStatus: createZodAutocomplete(OrderStatusEnum),
  patientIdType: createZodAutocomplete(PatientIdType),
  payerTpo: createZodAutocomplete(PayerTpo),
  payerType: createZodAutocomplete(PayerType),
  paymentSource: createZodAutocomplete(PaymentSource),
  paymentStatus: createZodAutocomplete(PaymentStatus),
  paymentType: createZodAutocomplete(PaymentType),
  planPeriod: createZodAutocomplete(PlanPeriod),
  priority: createZodAutocomplete(Priority),
  providerGuidedCareTeamStatus: createZodAutocomplete(ProviderGuidedCareTeamStatus),
  patientEligibilityBenefits: createZodAutocomplete(Benefit),
  questionType: createZodAutocomplete(QuestionType),
  requestedBy: createZodAutocomplete(RequestedBy),
  sendBulkMessagesMethod: createZodAutocomplete(SendBulkMessagesMethodEnum),
  source: createZodAutocomplete(Source),
  taskDefinitionStatus: createZodAutocomplete(TaskDefinitionStatus),
  taskWorkflowType: createZodAutocomplete(TaskWorkflowType),
  teamMemberPosition: createZodAutocomplete(TeamMemberPosition),
  templateFieldType: createZodAutocomplete(TemplateFieldType),
  timeOfDay: createZodAutocomplete(TimeOfDay),
  userGender: createZodAutocomplete(UserGender),
  userStatus: createZodAutocomplete(UserStatus),
  vendorType: createZodAutocomplete(VendorTypeEnum),
  vendorUserType: createZodAutocomplete(VendorUserTypes),
  visitDiagnosisType: createZodAutocomplete(VisitDiagnosisType),
  visitStatus: createZodAutocomplete(VisitStatus),
  visitType: createZodAutocomplete(VisitType),
  yesNo: createZodAutocomplete(YesNo),
  patientHealthDocumentType: createZodAutocomplete(HealthDocumentType),
};
