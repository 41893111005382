import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { procedureAgesPaths } from "../constants/ProcedureAgePaths";

export const useSetProcedureAgesBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Procedure Age");

  const procedureAgeListBreadcrumb = useMemo(() => {
    return {
      name: t("Procedure Age"),
      route: procedureAgesPaths.list.fullPath,
    };
  }, [t]);

  const setProcedureAgeListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setProcedureAgeCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [procedureAgeListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, procedureAgeListBreadcrumb, t]);

  const setProcedureAgeUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [procedureAgeListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, procedureAgeListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setProcedureAgeListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setProcedureAgeCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setProcedureAgeUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setProcedureAgeListContainerBreadcrumb,
    setProcedureAgeCreateContainerBreadcrumb,
    setProcedureAgeUpdateContainerBreadcrumb,
  ]);
};
