import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { PermissionsGroupsAutocompleteQuery } from "./gql";

export type IPermissionGroupAutocomplete = ExtractNodeType<PermissionsGroupsAutocompleteQuery>;

export const permissionGroupSchema = createZodAutocompleteFromType<IPermissionGroupAutocomplete>({
  id: z.string(),
  name: z.string(),
});
