import { Branch, BranchOrderField } from "@/schema/types";
import { getAutocompleteEnumFilter, vendorTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { ActivationSwitch, CustomTableColumnProps } from "@toolkit/ui";
import { AddressInformationModal } from "@/pages/Addresses";
import { BranchWorkingHoursModal } from "@/pages/Branches/modals/BranchWorkingHours/BranchWorkingHoursModal";
import { UserListModal } from "@/pages/Users";
import { useMemo } from "react";
import { CredentialDialog } from "../../../../components";
import { useBranchActivationMutation } from "../../gql";

export const useBranchListContainerColumns = (): CustomTableColumnProps<Branch>[] => {
  const { t } = useTranslation("domains");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr!),
        sort: {
          columnEnum: BranchOrderField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "email",
        header: t("Email"),
        accessor: ({ contactEmail }) => contactEmail || "-",
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "contactNumber",
        type: "mobile",
        filter: {
          type: "string",
          name: "contactNumber_Icontains",
        },
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => vendorTypeOptionsMap[type!]?.label,
        filter: getAutocompleteEnumFilter("VendorType", "type"),
      },
      {
        key: "eRxHubCredential",
        header: t("eRxHub Credential"),
        accessor: ({ id, isActive }) => <CredentialDialog targetId={id} type={"pharmacy"} isDisabled={!isActive} />,
      },
      {
        key: "address",
        header: t("Address"),
        accessor: ({ address }) => <AddressInformationModal address={address} />,
      },
      {
        key: "workingHours",
        header: t("Working Hours"),
        accessor: ({ workingHours }) => <BranchWorkingHoursModal workingHours={workingHours} />,
      },
      {
        key: "users",
        header: t("Users"),
        accessor: ({ id }) => <UserListModal filter={{ branchId: id }} />,
      },
      {
        key: "created",
        header: t("Created"),
        type: "datetime",
        accessor: "created",
        sort: {
          columnEnum: BranchOrderField.Created,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <ActivationSwitch id={id} isActive={isActive} mutation={useBranchActivationMutation} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "healthLicense",
        header: t("Health License"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "healthLicenseNumber",
        },
      },
      {
        key: "acceptsDelivery",
        header: t("Accepts Delivery"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "acceptsDelivery"),
      },
    ],
    [t]
  );
};
