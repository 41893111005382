import { Branch, BranchFilterInput, BranchOrderField } from "@/schema/types";
import { FilterGrid, OrderDirection, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useBranchListQuery } from "../../gql";
import { useBranchListContainerColumns } from "./useBranchListContainerColumns";
import { BranchListMainActions } from "../../components/BranchListMainActions/BranchListMainActions";

export type IBranchListContainerEvent =
  | {
      type: "ADD_ITEM_CLICK";
    }
  | {
      type: "EDIT_ROW_CLICK";
      payload: {
        item: Branch;
      };
    };

type BranchListContainerProps = {
  vendorHasMultipleBranches?: boolean;
  filter?: BranchFilterInput;
  onChange: (event: IBranchListContainerEvent) => void;
};

export const BranchListContainer: FC<BranchListContainerProps> = props => {
  const { vendorHasMultipleBranches, filter, onChange } = props;

  const onAddNewItemClick = () => {
    onChange({
      type: "ADD_ITEM_CLICK",
    });
  };

  const onEditRowClick = (item: Branch) => {
    onChange({
      type: "EDIT_ROW_CLICK",
      payload: { item },
    });
  };

  return (
    <GridProvider
      gridName={"branchList"}
      query={useBranchListQuery}
      columns={useBranchListContainerColumns()}
      hasTableSetting
      skipUrlState={false}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        filter,
        sortBy: {
          direction: OrderDirection.Desc,
          field: BranchOrderField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <BranchListMainActions vendorHasMultipleBranches={!!vendorHasMultipleBranches} onAddNewItemClick={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
