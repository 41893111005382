import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPayersAutocompleteQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nameContains?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type OptimaPayersAutocompleteQuery = { __typename?: 'Query', optimaPayers?: { __typename?: 'OptimaPayerLookupConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges: Array<{ __typename?: 'OptimaPayerLookupEdge', node: { __typename?: 'OptimaPayerLookup', name: string, id: string, licenseNumber: string } }> } | null };


export const OptimaPayersAutocompleteDocument = gql`
    query OptimaPayersAutocomplete($after: String, $first: Int, $nameContains: String) {
  optimaPayers(after: $after, first: $first, nameContains: $nameContains) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        name
        id
        licenseNumber
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useOptimaPayersAutocompleteQuery__
 *
 * To run a query within a React component, call `useOptimaPayersAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaPayersAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaPayersAutocompleteQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      nameContains: // value for 'nameContains'
 *   },
 * });
 */
export function useOptimaPayersAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>(OptimaPayersAutocompleteDocument, options);
      }
export function useOptimaPayersAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>(OptimaPayersAutocompleteDocument, options);
        }
export function useOptimaPayersAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>(OptimaPayersAutocompleteDocument, options);
        }
export type OptimaPayersAutocompleteQueryHookResult = ReturnType<typeof useOptimaPayersAutocompleteQuery>;
export type OptimaPayersAutocompleteLazyQueryHookResult = ReturnType<typeof useOptimaPayersAutocompleteLazyQuery>;
export type OptimaPayersAutocompleteSuspenseQueryHookResult = ReturnType<typeof useOptimaPayersAutocompleteSuspenseQuery>;
export type OptimaPayersAutocompleteQueryResult = Apollo.QueryResult<OptimaPayersAutocompleteQuery, OptimaPayersAutocompleteQueryVariables>;