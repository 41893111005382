import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
import { useMarketplaceProductCategoriesAutocompleteQuery } from "./gql";
import { IMarketplaceProductCategoryAutocomplete } from "./schema";

export const createMarketplaceProductCategoryAutocompleteOption = (category: IMarketplaceProductCategoryAutocomplete) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getMarketplaceProductCategoriesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "marketplaceProductCategories",
    query: useMarketplaceProductCategoriesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name!, item?.nameAr!),
    backendAccessor: "id",
    filterSearchKey: "nameContains",
  });
};
