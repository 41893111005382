import { Type } from "@/schema/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Autocomplete, CustomDatePicker, MuiExpandMoreIcon, TextField, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { getFieldType } from "../AdminManagedListDetails/utils";
import { FieldUIComponent } from "../types";
import { IFields } from "./types";

const FieldInput: FC<IFields> = ({ fieldDefenition }) => {
  const { register, getValues, formState, control } = useFormContext();
  const { t } = useTranslation("domains");
  const rendererType = getFieldType(fieldDefenition);
  const { code, display, isMandatory, fieldType } = fieldDefenition;
  const defaultValue = getValues(code!) || undefined;
  const isBooleanField = fieldType === Type.Boolean;
  const isFloatField = fieldType === Type.Float;
  switch (rendererType) {
    case FieldUIComponent.autocomplete:
      return (
        <Autocomplete
          defaultValue={defaultValue}
          popupIcon={<MuiExpandMoreIcon />}
          disableClearable={!isBooleanField}
          freeSolo={!isBooleanField}
          options={isBooleanField ? ["Yes", "No"] : []}
          renderInput={params => (
            <TextField
              variant='outlined'
              defaultValue={defaultValue}
              hiddenLabel
              placeholder={display || ""}
              {...params}
              {...register(code!, {
                required: getRequiredValidation(t, !!isMandatory),
              })}
              error={!!formState.errors?.[code!]?.message}
              helperText={(formState.errors?.[code!]?.message as string) || " "}
            />
          )}
        />
      );
    case FieldUIComponent.date:
      return (
        <CustomDatePicker
          defaultValue={defaultValue}
          required={!!isMandatory}
          label={display || ""}
          placeholder={display || ""}
          control={control}
          {...register(code!, {
            required: getRequiredValidation(t, !!isMandatory),
            valueAsDate: true,
          })}
          error={!!formState.errors?.[code!]?.message}
          helperText={(formState.errors?.[code!]?.message as string) || " "}
        />
      );
    case FieldUIComponent.input:
      return (
        <TextField
          defaultValue={defaultValue}
          required={!!isMandatory}
          label={display || ""}
          placeholder={display || ""}
          inputProps={{
            step: isFloatField ? "0.01" : undefined,
          }}
          fullWidth
          {...register(code!, {
            required: getRequiredValidation(t, !!isMandatory),
          })}
          error={!!formState.errors?.[code!]?.message}
          helperText={(formState.errors?.[code!]?.message as string) || " "}
        />
      );
    default:
      return <Typography> {t("Component type not defined")}</Typography>;
  }
};

export default FieldInput;
