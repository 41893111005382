import { Permission } from "@/schema/types";
import { InfoItems } from "@toolkit/ui";
import { Maybe } from "@/schema/types";

export const getPermissionsInfoItems = (permissions?: Maybe<Array<Maybe<Permission>>>): InfoItems => {
  return permissions
    ? [
        {
          value: permissions.filter((item): item is Permission => item !== undefined).map(item => item.name),
          valueDisplayMode: "chips",
        },
      ]
    : undefined;
};
