import { i18n } from "@toolkit/i18n";
import { HealthConditionType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const healthConditionTypeOptionsMap: IEnumToOptionsMap<HealthConditionType> = {
  [HealthConditionType.Chronic]: {
    key: HealthConditionType.Chronic,
    get label() {
      return i18n.t("Chronic", { ns: "domains" });
    },
    value: HealthConditionType.Chronic,
  },
  [HealthConditionType.Acute]: {
    key: HealthConditionType.Acute,
    get label() {
      return i18n.t("Acute", { ns: "domains" });
    },
    value: HealthConditionType.Acute,
  },
  [HealthConditionType.SubAcute]: {
    key: HealthConditionType.SubAcute,
    get label() {
      return i18n.t("Sub Acute", { ns: "domains" });
    },
    value: HealthConditionType.SubAcute,
  },
};

export const healthConditionTypeOptions = Object.values(healthConditionTypeOptionsMap);
