import moment from "moment";

export const getTimeFormat = (isAmPm = false) => (isAmPm ? "hh:mm A" : "HH:mm");

export const getTimeFormatFromTime = (time: string) => {
  if (!time) return "";

  if (/AM|PM/i.test(time)) {
    return "hh:mm A";
  } else {
    return "HH:mm";
  }
};

export const createMomentTime = (time: string) => {
  if (!time) return "";

  const valid24HourFormat = /^([01]?[0-9]|2[0-3]):([0-5][0-9])$/;
  const valid12HourFormat = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(AM|PM)$/i;

  if (valid24HourFormat.test(time) || valid12HourFormat.test(time)) {
    return moment(time, getTimeFormatFromTime(time)).clone().format();
  }

  return "";
};
