import { z } from "zod";

export const benefitMapUpsertFormSchema = z.object({
  benefitCode: z.string().min(1).max(255),
  itemCode: z.string().min(1).max(255),
  description: z.string().min(1).max(255).optional(),
});
export type IBenefitMapUpsertFormValues = z.infer<typeof benefitMapUpsertFormSchema>;

export const benefitMapUpsertFormDefaultValues: Partial<IBenefitMapUpsertFormValues> = {
  benefitCode: "",
  itemCode: "",
  description: "",
};
