import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderCreateMutationVariables = Types.Exact<{
  input: Types.VendorAdminInput;
}>;


export type ProviderCreateMutation = { __typename?: 'Mutation', vendorCreate?: { __typename?: 'VendorCreate', vendor?: { __typename?: 'Vendor', id: string, hasMultipleBranches: boolean } | null, vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null }> } | null };


export const ProviderCreateDocument = gql`
    mutation ProviderCreate($input: VendorAdminInput!) {
  vendorCreate(input: $input) {
    vendor {
      id
      hasMultipleBranches
    }
    vendorErrors {
      field
      message
    }
  }
}
    `;
export type ProviderCreateMutationFn = Apollo.MutationFunction<ProviderCreateMutation, ProviderCreateMutationVariables>;

/**
 * __useProviderCreateMutation__
 *
 * To run a mutation, you first call `useProviderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerCreateMutation, { data, loading, error }] = useProviderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProviderCreateMutation, ProviderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderCreateMutation, ProviderCreateMutationVariables>(ProviderCreateDocument, options);
      }
export type ProviderCreateMutationHookResult = ReturnType<typeof useProviderCreateMutation>;
export type ProviderCreateMutationResult = Apollo.MutationResult<ProviderCreateMutation>;
export type ProviderCreateMutationOptions = Apollo.BaseMutationOptions<ProviderCreateMutation, ProviderCreateMutationVariables>;