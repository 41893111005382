import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CptHcpcPrimaryProcedureDeleteMutationVariables = Types.Exact<{
  deleteCptHcpcPrimaryProcedureId: Types.Scalars['ID']['input'];
}>;


export type CptHcpcPrimaryProcedureDeleteMutation = { __typename?: 'Mutation', deleteCptHcpcPrimaryProcedure?: { __typename?: 'CptHcpcPrimaryProcedure', id: string } | null };


export const CptHcpcPrimaryProcedureDeleteDocument = gql`
    mutation CptHcpcPrimaryProcedureDelete($deleteCptHcpcPrimaryProcedureId: ID!) {
  deleteCptHcpcPrimaryProcedure(id: $deleteCptHcpcPrimaryProcedureId) {
    id
  }
}
    `;
export type CptHcpcPrimaryProcedureDeleteMutationFn = Apollo.MutationFunction<CptHcpcPrimaryProcedureDeleteMutation, CptHcpcPrimaryProcedureDeleteMutationVariables>;

/**
 * __useCptHcpcPrimaryProcedureDeleteMutation__
 *
 * To run a mutation, you first call `useCptHcpcPrimaryProcedureDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCptHcpcPrimaryProcedureDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cptHcpcPrimaryProcedureDeleteMutation, { data, loading, error }] = useCptHcpcPrimaryProcedureDeleteMutation({
 *   variables: {
 *      deleteCptHcpcPrimaryProcedureId: // value for 'deleteCptHcpcPrimaryProcedureId'
 *   },
 * });
 */
export function useCptHcpcPrimaryProcedureDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CptHcpcPrimaryProcedureDeleteMutation, CptHcpcPrimaryProcedureDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CptHcpcPrimaryProcedureDeleteMutation, CptHcpcPrimaryProcedureDeleteMutationVariables>(CptHcpcPrimaryProcedureDeleteDocument, options);
      }
export type CptHcpcPrimaryProcedureDeleteMutationHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureDeleteMutation>;
export type CptHcpcPrimaryProcedureDeleteMutationResult = Apollo.MutationResult<CptHcpcPrimaryProcedureDeleteMutation>;
export type CptHcpcPrimaryProcedureDeleteMutationOptions = Apollo.BaseMutationOptions<CptHcpcPrimaryProcedureDeleteMutation, CptHcpcPrimaryProcedureDeleteMutationVariables>;