import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { diagnosisValidProceduresPaths } from "../constants";

export const useSetDiagnosisValidProceduresBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Diagnosis Valid Procedures");

  const diagnosisValidProcedureListBreadcrumb = useMemo(() => {
    return {
      name: t("Diagnosis Valid Procedures"),
      route: diagnosisValidProceduresPaths.list.fullPath,
    };
  }, [t]);

  const setDiagnosisValidProcedureListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setDiagnosisValidProcedureCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [diagnosisValidProcedureListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, diagnosisValidProcedureListBreadcrumb, t]);

  const setDiagnosisValidProcedureUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [diagnosisValidProcedureListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, diagnosisValidProcedureListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setDiagnosisValidProcedureListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setDiagnosisValidProcedureCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setDiagnosisValidProcedureUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setDiagnosisValidProcedureListContainerBreadcrumb,
    setDiagnosisValidProcedureCreateContainerBreadcrumb,
    setDiagnosisValidProcedureUpdateContainerBreadcrumb,
  ]);
};
