import { Timeline } from "@toolkit/ui";
import { FC, Fragment } from "react";
import { EventType, OrderEventTimelineItem } from "./OrderEventTimelineItem";

export type OrdersEventsType = EventType[];

export const OrderEventsTimeline: FC<{ events: OrdersEventsType }> = ({ events }) => {
  const handleVoid = () => {};
  return (
    <Timeline placeholder='' sx={{ margin: 0, padding: "0px 16px" }} onPointerEnterCapture={handleVoid} onPointerLeaveCapture={handleVoid}>
      {events?.map((event, index) => {
        return (
          <Fragment key={index + event?.id!}>
            <OrderEventTimelineItem event={event} />
          </Fragment>
        );
      })}
    </Timeline>
  );
};
