import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useBranchesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IBranchAutocomplete } from "./schema";

export const createBranchAutocompleteOption = (branch: IBranchAutocomplete) => {
  return createAutocompleteOption(
    {
      id: branch?.id!,
      name: branch?.name!,
      nameAr: branch?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getBranchesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "branches",
    query: useBranchesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name, item?.nameAr!),
    backendAccessor: "id",
    filterSearchKey: "name_Icontains",
  });
};
