import { useOpenState } from "@toolkit/core";
import { FC } from "react";
import { DrawerProps, MuiDrawer } from "../../../../base/mui";
import { ShowButton } from "../../../ShowButton";
import { useInfoDrawerStyles } from "./InfoDrawer.styless";

export type InfoDrawerProps = DrawerProps & {
  classes?: Partial<ReturnType<typeof useInfoDrawerStyles>["classes"]>;
  onShowButtonClick?: () => void;
};

export const InfoDrawer: FC<InfoDrawerProps> = props => {
  const { classes: _classes, onShowButtonClick } = props;
  const { open, handleClose, handleOpen } = useOpenState();
  const { classes, cx } = useInfoDrawerStyles();

  const handleButtonClick = () => {
    onShowButtonClick?.();
    handleOpen();
  };
  return (
    <>
      <ShowButton onClick={handleButtonClick} />
      <MuiDrawer
        anchor='right'
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: cx(classes.drawer, _classes?.drawer),
        }}
        {...props}
      />
    </>
  );
};
