import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { isPossiblePhoneNumber } from "libphonenumber-js/mobile";
import { i18n } from "@toolkit/i18n";

export const healthProgramMemberUpsertFormSchema = (isUpdateMode?: boolean, memberListId?: string | null) => {
  return z
    .object({
      firstName: z.string().min(3).max(30),
      lastName: z.string().min(3).max(30),
      gender: zodEnumSchema.userGender,
      dateOfBirth: z.string(),
      nationalId: z.string().min(1).max(255),
      iOHealthId: z.string().min(1).max(255),
      patientIdType: zodEnumSchema.patientIdType,
      nationality: zodSchema.systemCode,
      documentExpiryDate: z.string(),
      phoneNumber: z
        .string()
        .min(1, { message: i18n.t("Required") })
        .refine(
          value => {
            return isPossiblePhoneNumber(value);
          },
          {
            message: i18n.t("Required"),
          }
        ),
      email: z.string().email(),
      network: z.string().min(1).max(255),
      payer: zodSchema.payer,
      coverageByType: zodEnumSchema.coverageByType,
      memberShipStartDate: z.string(),
      memberShipEndDate: z.string(),
      insuranceId: z.string().min(1).max(255),
      insurancePolicyNumber: z.string().min(1).max(255),
      memberLists: z.array(zodSchema.healthProgramMemberList).optional(),
      insuranceCategory: z.string().min(1).max(70),
      insuranceClass: z.string().min(1).max(70),
      insuranceCompanyLicenseNumber: z.string().min(1).max(70),
    })
    .superRefine((data, ctx) => {
      if (!isUpdateMode && !memberListId && (!data.memberLists || data.memberLists.length === 0)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("ٍRequired", { ns: "admin" }),
          path: ["memberLists"],
        });
      }
    });
};

export type IHealthProgramMemberUpsertFormValues = z.infer<ReturnType<typeof healthProgramMemberUpsertFormSchema>>;

export const healthProgramMemberUpsertFormDefaultValues: Partial<IHealthProgramMemberUpsertFormValues> = {
  firstName: undefined,
  lastName: undefined,
  gender: undefined,
  dateOfBirth: undefined,
  nationalId: undefined,
  iOHealthId: undefined,
  patientIdType: undefined,
  nationality: undefined,
  documentExpiryDate: undefined,
  phoneNumber: "+971",
  email: undefined,
  memberLists: [],
  network: undefined,
  payer: undefined,
  coverageByType: undefined,
  memberShipStartDate: undefined,
  memberShipEndDate: undefined,
  insuranceId: undefined,
  insurancePolicyNumber: undefined,
  insuranceCategory: undefined,
  insuranceClass: undefined,
  insuranceCompanyLicenseNumber: undefined,
};
