import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionRiskFactorTemplatesAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.RiskFactorTemplateFilterInput>;
}>;


export type DecisionRiskFactorTemplatesAutocompleteQuery = { __typename?: 'Query', getRiskFactorTemplates?: { __typename?: 'RiskFactorTemplateConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'RiskFactorTemplateEdge', node?: { __typename?: 'RiskFactorTemplate', id?: string | null, name?: string | null, nameAr?: string | null } | null } | null> | null } | null };


export const DecisionRiskFactorTemplatesAutocompleteDocument = gql`
    query DecisionRiskFactorTemplatesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: RiskFactorTemplateFilterInput) {
  getRiskFactorTemplates(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        name: display
        nameAr: arabicDisplay
      }
    }
  }
}
    `;

/**
 * __useDecisionRiskFactorTemplatesAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionRiskFactorTemplatesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionRiskFactorTemplatesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionRiskFactorTemplatesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionRiskFactorTemplatesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>(DecisionRiskFactorTemplatesAutocompleteDocument, options);
      }
export function useDecisionRiskFactorTemplatesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>(DecisionRiskFactorTemplatesAutocompleteDocument, options);
        }
export function useDecisionRiskFactorTemplatesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>(DecisionRiskFactorTemplatesAutocompleteDocument, options);
        }
export type DecisionRiskFactorTemplatesAutocompleteQueryHookResult = ReturnType<typeof useDecisionRiskFactorTemplatesAutocompleteQuery>;
export type DecisionRiskFactorTemplatesAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionRiskFactorTemplatesAutocompleteLazyQuery>;
export type DecisionRiskFactorTemplatesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionRiskFactorTemplatesAutocompleteSuspenseQuery>;
export type DecisionRiskFactorTemplatesAutocompleteQueryResult = Apollo.QueryResult<DecisionRiskFactorTemplatesAutocompleteQuery, DecisionRiskFactorTemplatesAutocompleteQueryVariables>;