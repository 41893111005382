/* eslint-disable react/jsx-handler-names */
import { StandaloneSearchBox } from "@react-google-maps/api";
import { getCurrentLocation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, IconButton, TextField as MuiTextField, MyLocationIcon, Typography } from "@toolkit/ui";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocationHooks } from "../utils";
import { useGoogleMapSearchWrapperStyles } from "./styles";
import { IGoogleMapSearchProps } from "./types";
import { IGoogleMapAddress } from "../LocationPicker";

const GoogleMapSearchComponent = (props: IGoogleMapSearchProps) => {
  const { onLocationChange, defaultValue, showDetails, classes: _classes, placeholder } = props;
  const { t } = useTranslation();
  const { getLocationResult } = useLocationHooks();
  const { classes, cx } = useGoogleMapSearchWrapperStyles();
  const refs = useRef<google.maps.places.SearchBox>(null);
  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([]);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddress(defaultValue || "");
  }, [defaultValue]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handlePlacesChanged = () => {
    const newPlaces = refs.current?.getPlaces() || [];
    setPlaces(newPlaces);

    if (newPlaces.length) {
      const selectedPlace = newPlaces[0];
      const addressComponents = selectedPlace.address_components || [];

      let city: IGoogleMapAddress["city"], state: IGoogleMapAddress["state"], country: IGoogleMapAddress["country"];

      for (const component of addressComponents) {
        const types = component.types || [];
        if (types.includes("locality")) city = component.long_name;
        if (types.includes("administrative_area_level_1")) state = component.long_name;
        if (types.includes("country")) country = component.long_name;
      }

      const locationData: IGoogleMapAddress = {
        coordinates: {
          lat: selectedPlace?.geometry?.location?.lat() || 0,
          lng: selectedPlace?.geometry?.location?.lng() || 0,
        },
        city,
        state,
        country,
        streetAddress1: selectedPlace?.formatted_address,
        formatted_address: selectedPlace?.formatted_address,
        name: selectedPlace?.name,
        address_components: addressComponents,
      };

      onLocationChange?.(locationData);
      setAddress(selectedPlace?.formatted_address || "");
    }
  };

  const handleGetMyLocation = async () => {
    try {
      setLoading(true);
      const location = await getCurrentLocation();
      if (location?.lat && location?.lng) {
        const locationData = await getLocationResult(location.lat.toString(), location.lng.toString());
        onLocationChange?.(locationData);
        setAddress(locationData.formatted_address || "");
      }
    } catch (error) {
      alert(
        t("You did not allow location permissions, to enjoy this feature please go to settings and give your browser location permissions")
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSearchLocation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };
  return (
    <div className={cx(classes.root, _classes?.root)}>
      <StandaloneSearchBox
        onLoad={instance => {
          _.set(refs, "current", instance);
        }}
        onPlacesChanged={handlePlacesChanged}
      >
        <Box className={cx(classes.searchBoxContainer, _classes?.searchBoxContainer)}>
          <MuiTextField
            placeholder={placeholder || t("Enter Area")}
            hiddenLabel
            fullWidth
            value={address}
            className={cx(classes.text, _classes?.text)}
            onChange={handleSearchLocation}
          />
          <IconButton onClick={handleGetMyLocation} className={cx(classes.locationButton, _classes?.locationButton)}>
            {loading ? <CircularProgress size={20} color='inherit' /> : <MyLocationIcon color='inherit' />}
            <Typography className={cx(classes.buttonText, _classes?.buttonText)} color='inherit'>
              {t("Current Location")}
            </Typography>
          </IconButton>
        </Box>
      </StandaloneSearchBox>
      {showDetails && (
        <Box className={cx(classes.placesListContainer, _classes?.placesListContainer)}>
          <ol>
            {places?.map(({ place_id, formatted_address, geometry }) => (
              <li key={place_id}>
                {formatted_address}
                {" at "}({geometry?.location?.lat()}, {geometry?.location?.lng()})
              </li>
            ))}
          </ol>
        </Box>
      )}
    </div>
  );
};

export default GoogleMapSearchComponent;
