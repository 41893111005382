import React from "react";
import { Grid } from "@toolkit/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useCustomForm } from "@toolkit/core";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { BranchesAutocomplete } from "@health/autocompletes";
import { convertUserWorkspaceDoctorToFormValues } from "../../others";
import {
  userWorkspaceDoctorUpsertFormSchema,
  userWorkspaceDoctorUpsertFormDefaultValues,
  IUserWorkspaceDoctorUpsertFormValues,
} from "./UserWorkspaceDoctorUpsertFormSchema";
import { IUserWorkspaceDoctorValue } from "../../types/types";

export type IUserWorkspaceDoctorUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IUserWorkspaceDoctorUpsertFormValues;
  };
};

type UserWorkspaceDoctorUpsertFormProps = {
  onChange: (event: IUserWorkspaceDoctorUpsertFormEvent) => void;
  userWorkspaceDoctor: IUserWorkspaceDoctorValue | null;
};

export type UserWorkspaceDoctorUpsertFormRef = {
  getForm: () => UseFormReturn<IUserWorkspaceDoctorUpsertFormValues>;
  submit: () => void;
};

const UserWorkspaceDoctorUpsertFormForwardRef: React.ForwardRefRenderFunction<
  UserWorkspaceDoctorUpsertFormRef,
  UserWorkspaceDoctorUpsertFormProps
> = (props, ref) => {
  const { userWorkspaceDoctor, onChange } = props;

  const form = useCustomForm<IUserWorkspaceDoctorUpsertFormValues>({
    defaultValues: userWorkspaceDoctorUpsertFormDefaultValues,
    schema: userWorkspaceDoctorUpsertFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (values: IUserWorkspaceDoctorUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (userWorkspaceDoctor) {
      const _userWorkspaceDoctor = convertUserWorkspaceDoctorToFormValues(userWorkspaceDoctor);
      setValues(_userWorkspaceDoctor);
    }
  }, [userWorkspaceDoctor, setValues]);

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BranchesAutocomplete name={"branch"} filter={{ isActive: true }} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export const UserWorkspaceDoctorUpsertForm = forwardRef(UserWorkspaceDoctorUpsertFormForwardRef);
