import React, { FC, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon, useAddToast, useGridContext } from "@toolkit/ui";
import { useAuth } from "react-oidc-context";
import moment from "moment";
import { useExportPaymentInvoicesMutation } from "../gql";

type FileDownloadProps = {
  label?: string;
};

export const ReportDownload: FC<FileDownloadProps> = props => {
  const { label } = props;
  const { t } = useTranslation("domains");
  const [isDownloading, setIsDownloading] = useState(false);
  const { failed } = useAddToast();

  const [exportPaymentInvoices, { loading }] = useExportPaymentInvoicesMutation();
  const { user } = useAuth();

  const { activeFiltersProps } = useGridContext();

  const filters = activeFiltersProps?.filters || {};
  const handleDownloadFile = async () => {
    if (isDownloading || loading) return;
    exportPaymentInvoices({
      variables: {
        filter: {
          ...filters,
          createdDate: {
            gte: filters?.createdDate?.gte || moment().subtract(3, "months").startOf("month").format("YYYY-MM-DD"),
            lte: filters?.createdDate?.lte || moment().endOf("month").format("YYYY-MM-DD"),
          },
          marketplaceOrderIdNotNull: filters?.marketplaceOrderIdNotNull?.value === "Yes",
        },
      },
    }).then(response => {
      if (response?.data?.exportPaymentInvoices) {
        setIsDownloading(true);
        const myHeaders = new Headers();

        myHeaders.append("Authorization", `Bearer ${user?.access_token}`);

        fetch(response?.data?.exportPaymentInvoices, {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        })
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", `order-invoices.csv`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);

            setIsDownloading(false);
          })
          .catch(error => {
            console.error("Error fetching template:", error);
            failed(t("Failed to download report"));
            setIsDownloading(false);
          });
      }
    });
  };

  return (
    <Button
      sx={{ margin: "10px", paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }}
      startIcon={<ExcelIcon />}
      onClick={handleDownloadFile}
    >
      {label || t("Download Report")}
    </Button>
  );
};
