import React, { FC, useEffect } from "react";
import { Button, FormCard, FormSwitch, FormTextField, Grid, RemoveIcon } from "@toolkit/ui";
import { SurveyQuestionOptionsForm } from "../SurveyQuestionOptions/SurveyQuestionOptionsForm";
import { FormQuestionTypeField } from "../../components/FormQuestionTypeField/FormQuestionTypeField";
import { useTranslation } from "@toolkit/i18n";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum, QuestionType } from "@/schema/types";
import { useSurveyQuestionFormStyles } from "./useSurveyQuestionForm.styles";
import { useFormContext } from "react-hook-form";
import { ISurveyUpsertFormValues } from "../SurveyUpsert/surveyUpsertFormSchema";
import { IQuestionPath } from "../../types";
import { shouldQuestionHaveOptions } from "../../others";
import { isNil } from "lodash-es";

type SurveyQuestionFormProps = {
  questionPath: IQuestionPath;
  onRemoveQuestion: () => void;
  questionIndex: number;
};

export const SurveyQuestionForm: FC<SurveyQuestionFormProps> = ({ questionPath, onRemoveQuestion, questionIndex }) => {
  const { t } = useTranslation("admin");
  const { classes } = useSurveyQuestionFormStyles();
  const canManageSurvey = hasPermission(PermissionEnum.ManageSurveys);

  const { watch, setValue } = useFormContext<ISurveyUpsertFormValues>();
  const questionType = watch(`${questionPath}.questionType`);
  const previousQuestionTypeRef = React.useRef(questionType);

  useEffect(() => {
    if (
      !isNil(previousQuestionTypeRef.current) &&
      [QuestionType.Boolean, QuestionType.SingleChoice]?.includes(questionType?.value) &&
      previousQuestionTypeRef.current?.value !== questionType.value
    ) {
      setValue(`${questionPath}.options`, []);
      previousQuestionTypeRef.current = questionType;
    }
  }, [questionType]);

  return (
    <FormCard doYouHaveData={true} loading={false} classes={{ root: classes.root }}>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} container className={classes.header} alignItems='center'>
          <Grid item xs>
            <FormSwitch name={`${questionPath}.required`} label={t("Required")} />
          </Grid>
          <Grid item>
            {canManageSurvey && (
              <Button startIcon={<RemoveIcon />} variant='text' onClick={onRemoveQuestion}>
                {t("Remove Question")}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} padding={2} paddingBottom={0}>
          <Grid item xs={12} sm={8}>
            <FormTextField fullWidth size='small' label={t("Question")} name={`${questionPath}.question`} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormQuestionTypeField name={`${questionPath}.questionType`} />
          </Grid>
          {questionType?.value && shouldQuestionHaveOptions(questionType.value) && (
            <SurveyQuestionOptionsForm questionIndex={questionIndex} canManageSurvey={canManageSurvey} />
          )}
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default SurveyQuestionForm;
