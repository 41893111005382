import { customerSupportOrderStatusOptions } from "@health/enum-options";
import { combineErrors, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Box, Button, Grid, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const OrderHandlingForm: FC<{ errors: any[]; loading: boolean }> = ({ errors, loading }) => {
  const { t } = useTranslation("domains");
  const theme = useTheme();

  const {
    formState: { errors: formErrors },
    control,
  } = useFormContext();

  const combinedErrors = combineErrors(formErrors, errors);
  const name = "status";

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
          {t("Handle Order")}
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={formGirdSpacing} justifyContent='space-between'>
          <Grid item xs={6} textAlign='left'>
            <AutocompleteController
              ControllerProps={{
                name: name,
                control: control,
                rules: { required: true },
              }}
              TextFieldProps={{
                placeholder: t("Order Status"),
                error: Boolean(combinedErrors?.[name]?.type),
                helperText: t(combinedErrors?.[name]?.type && t("Required")),
                label: t("Order Status"),
              }}
              getOptionLabel={option => option?.label}
              options={customerSupportOrderStatusOptions}
            />
          </Grid>

          <Grid item xs={6} textAlign='right'>
            <Button type='submit' disabled={loading}>
              {t("Handle")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
