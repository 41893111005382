import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import {
  MedicalFormActivateMutation,
  MedicalFormDeactivateMutation,
  useMedicalFormActivateMutation,
  useMedicalFormDeactivateMutation,
} from "../../gql";

type MedicalFormActivationProps = {
  id: string;
  isActive: boolean;
};

export const MedicalFormActivation: FC<MedicalFormActivationProps> = props => {
  const { id, isActive } = props;

  const { t, i18n } = useTranslation("domains");
  const currentDir = i18n.dir(i18n.language);
  const { open, handleToggle } = useOpenState();
  const { succeeded, failed } = useAddToast();
  const [active, setActive] = useState(isActive);

  const [fetchMedicalFormDeactivateMutation] = useMedicalFormDeactivateMutation({
    onCompleted: (data: MedicalFormDeactivateMutation) => {
      const mutationErrors = data?.deactivateMedicalForm?.errors;
      if (mutationErrors?.length) {
        failed(t("Medical form update failed"));
      } else {
        setActive(!!data?.deactivateMedicalForm?.active);
        succeeded(t("Medical form updated successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const [fetchMedicalFormActivateMutation] = useMedicalFormActivateMutation({
    onCompleted: (data: MedicalFormActivateMutation) => {
      const errors = data?.activateMedicalForm?.errors;
      if (errors && errors.length > 0) {
        failed(t("Medical form update failed"));
      } else {
        setActive(!!data?.activateMedicalForm?.active);
        succeeded(t("Medical form updated successfully"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeActive = () => {
    if (active) {
      fetchMedicalFormDeactivateMutation({
        variables: {
          deactivateMedicalFormId: id,
        },
      });
    } else {
      fetchMedicalFormActivateMutation({
        variables: {
          activateMedicalFormId: id,
        },
      });
    }
  };

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <>
      <CustomToggleButton
        dir={currentDir}
        checked={active}
        onChange={handleToggle}
        formGroupProps={{
          sx: {
            display: "block",
          },
        }}
      />

      {open && (
        <CustomDialog
          type='warning'
          text={{
            body: t("Are you sure you want to perform this action?"),
            title: active ? t("Medical Form Deactivation") : t("Medical Form Activation"),
          }}
          isOpen={open}
          onClose={handleToggle}
          onConfirm={handleChangeActive}
        />
      )}
    </>
  );
};
