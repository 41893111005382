import { IAutocompleteBasicProps } from "@/types";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { Field } from "../../schema/types";
import { useListFieldsAutocompleteQuery } from "./gql";

export const ListFieldsAutocomplete: FC<IAutocompleteBasicProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, loading } = useListFieldsAutocompleteQuery({
    skip: props.disabled || skip,
  });

  const _data = data?.getAllListFields as Field[];

  const options = mapToAutocompleteOptions(_data, "id", "display");

  return <FormAutocomplete {...rest} loading={loading} options={options} label={label || (props.multiple ? t("Fields") : t("Field"))} />;
};
