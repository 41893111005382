import { i18n } from "@toolkit/i18n";
import { AppRoleTypes } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const appRoleTypeOptionsMap: IEnumToOptionsMap<AppRoleTypes> = {
  [AppRoleTypes.Admin]: {
    key: AppRoleTypes.Admin,
    get label() {
      return i18n.t("Admin", { ns: "domains" });
    },
    value: AppRoleTypes.Admin,
  },
  [AppRoleTypes.User]: {
    key: AppRoleTypes.User,
    get label() {
      return i18n.t("User", { ns: "domains" });
    },
    value: AppRoleTypes.User,
  },
};

export const appRoleTypeOptions = Object.values(appRoleTypeOptionsMap);
