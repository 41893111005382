import { CodeSystemConceptFieldDefinition, CodeSystemConceptPropertyInput, Type } from "@/schema/types";
import moment from "moment";
import { getFilterValuePropertyName } from "../AdminManagedListDetails/utils";

export const getPropertyValueObjectForBackend = (
  item: Pick<CodeSystemConceptFieldDefinition, "fieldType" | "code">,
  formValue: string
): CodeSystemConceptPropertyInput => {
  const valuePropName = getFilterValuePropertyName(item?.fieldType);

  return {
    code: item?.code,
    fieldType: item?.fieldType,
    valueBoolean: null,
    valueString: null,
    valueDate: null,
    valueInteger: null,
    valueFloat: null,
    [valuePropName]: mapStringValueToBackendFieldType(item?.fieldType!, formValue),
  } as CodeSystemConceptPropertyInput;
};

export function mapStringValueToBackendFieldType(fieldType: Type, propertyValue: string) {
  if (propertyValue === "") return null;
  switch (fieldType) {
    case Type.String:
      return propertyValue;
    case Type.Boolean:
      return typeof propertyValue === "string" ? propertyValue?.toLowerCase() === "yes" : propertyValue["value"] === "yes";
    case Type.Float:
      return !isNaN(propertyValue as any) ? parseFloat(propertyValue) : null;
    case Type.Integer:
      return !isNaN(propertyValue as any) ? parseInt(propertyValue) : null;
    case Type.Date:
      return propertyValue ? moment(propertyValue, "YYYY-MM-DD").format("YYYY-MM-DD") : null;
    default:
      return propertyValue;
  }
}

export const isFieldDefinitionValueValid = (
  item: Pick<CodeSystemConceptFieldDefinition, "fieldType" | "display" | "isMandatory">,
  formValue: string
) => {
  const isValueInvalid = [null, "", undefined].includes(mapStringValueToBackendFieldType(item.fieldType!, formValue) as any);
  return !item?.isMandatory || !isValueInvalid;
};

export function getValidationErrors(
  codeSystemConceptFields: CodeSystemConceptFieldDefinition[],
  data: Record<string, string>
): Array<{ code; message }> {
  const initialValue: Array<{ code; message }> = [];
  return (
    codeSystemConceptFields?.reduce((_errors, item) => {
      const propertyValue = data[item?.code!];
      if (isFieldDefinitionValueValid(item!, propertyValue)) {
        return _errors;
      }
      const message = `${item?.display} is required`;
      return [..._errors, { code: item?.code!, message }];
    }, initialValue) || initialValue
  );
}
