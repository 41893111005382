import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthConditionsAutocompleteQuery } from "./gql";

export type IHealthConditionAutocomplete = ExtractNodeType<HealthConditionsAutocompleteQuery>;

export const healthConditionSchema = createZodAutocompleteFromType<IHealthConditionAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  arabicDisplay: z.string().nullish(),
});
