import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignCodeSystemEditorAndReviewerMutationVariables = Types.Exact<{
  code: Types.CodeSystemCode;
  editorUser: Types.Scalars['ID']['input'];
  reviewerUser: Types.Scalars['ID']['input'];
}>;


export type AssignCodeSystemEditorAndReviewerMutation = { __typename?: 'Mutation', assignCodeSystemEditorAndReviewer?: { __typename?: 'CodeSystemDefinition', id?: string | null, code?: Types.CodeSystemCode | null, display?: string | null, codeSystemConceptFields?: Array<{ __typename?: 'CodeSystemConceptFieldDefinition', code?: string | null, display?: string | null, fieldType?: Types.Type | null, isMandatory?: boolean | null } | null> | null, editorUserId?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, reviewerUserId?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null };


export const AssignCodeSystemEditorAndReviewerDocument = gql`
    mutation assignCodeSystemEditorAndReviewer($code: CodeSystemCode!, $editorUser: ID!, $reviewerUser: ID!) {
  assignCodeSystemEditorAndReviewer(
    code: $code
    editorUser: $editorUser
    reviewerUser: $reviewerUser
  ) {
    id
    code
    codeSystemConceptFields {
      code
      display
      fieldType
      isMandatory
    }
    display
    editorUserId {
      id
      firstName
      lastName
    }
    reviewerUserId {
      id
      firstName
      lastName
    }
  }
}
    `;
export type AssignCodeSystemEditorAndReviewerMutationFn = Apollo.MutationFunction<AssignCodeSystemEditorAndReviewerMutation, AssignCodeSystemEditorAndReviewerMutationVariables>;

/**
 * __useAssignCodeSystemEditorAndReviewerMutation__
 *
 * To run a mutation, you first call `useAssignCodeSystemEditorAndReviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCodeSystemEditorAndReviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCodeSystemEditorAndReviewerMutation, { data, loading, error }] = useAssignCodeSystemEditorAndReviewerMutation({
 *   variables: {
 *      code: // value for 'code'
 *      editorUser: // value for 'editorUser'
 *      reviewerUser: // value for 'reviewerUser'
 *   },
 * });
 */
export function useAssignCodeSystemEditorAndReviewerMutation(baseOptions?: Apollo.MutationHookOptions<AssignCodeSystemEditorAndReviewerMutation, AssignCodeSystemEditorAndReviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignCodeSystemEditorAndReviewerMutation, AssignCodeSystemEditorAndReviewerMutationVariables>(AssignCodeSystemEditorAndReviewerDocument, options);
      }
export type AssignCodeSystemEditorAndReviewerMutationHookResult = ReturnType<typeof useAssignCodeSystemEditorAndReviewerMutation>;
export type AssignCodeSystemEditorAndReviewerMutationResult = Apollo.MutationResult<AssignCodeSystemEditorAndReviewerMutation>;
export type AssignCodeSystemEditorAndReviewerMutationOptions = Apollo.BaseMutationOptions<AssignCodeSystemEditorAndReviewerMutation, AssignCodeSystemEditorAndReviewerMutationVariables>;