import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkNotificationsAsSeenMutationVariables = Types.Exact<{
  markNotificationAsSeenId: Types.Scalars['ID']['input'];
}>;


export type MarkNotificationsAsSeenMutation = { __typename?: 'Mutation', markNotificationAsSeen?: { __typename?: 'UserNotification', errors?: Array<{ __typename?: 'SubscriptionGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const MarkNotificationsAsSeenDocument = gql`
    mutation markNotificationsAsSeen($markNotificationAsSeenId: ID!) {
  markNotificationAsSeen(id: $markNotificationAsSeenId) {
    errors {
      field
      message
    }
  }
}
    `;
export type MarkNotificationsAsSeenMutationFn = Apollo.MutationFunction<MarkNotificationsAsSeenMutation, MarkNotificationsAsSeenMutationVariables>;

/**
 * __useMarkNotificationsAsSeenMutation__
 *
 * To run a mutation, you first call `useMarkNotificationsAsSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationsAsSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationsAsSeenMutation, { data, loading, error }] = useMarkNotificationsAsSeenMutation({
 *   variables: {
 *      markNotificationAsSeenId: // value for 'markNotificationAsSeenId'
 *   },
 * });
 */
export function useMarkNotificationsAsSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationsAsSeenMutation, MarkNotificationsAsSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationsAsSeenMutation, MarkNotificationsAsSeenMutationVariables>(MarkNotificationsAsSeenDocument, options);
      }
export type MarkNotificationsAsSeenMutationHookResult = ReturnType<typeof useMarkNotificationsAsSeenMutation>;
export type MarkNotificationsAsSeenMutationResult = Apollo.MutationResult<MarkNotificationsAsSeenMutation>;
export type MarkNotificationsAsSeenMutationOptions = Apollo.BaseMutationOptions<MarkNotificationsAsSeenMutation, MarkNotificationsAsSeenMutationVariables>;