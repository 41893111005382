import { BulkTemplateCode } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, ExcelIcon, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useAuth } from "react-oidc-context";
import { domainsConfig } from "../../domainsConfig";
import { useFileDownload } from "@toolkit/core";

type FileDownloadProps = {
  label?: string;
  templateCode: BulkTemplateCode;
  isButton?: boolean;
};

export const FileDownload: FC<FileDownloadProps> = props => {
  const { label, templateCode, isButton = true } = props;
  const { t } = useTranslation("domains");
  const { user } = useAuth();

  const { downloadFile, isDownloading } = useFileDownload();

  const handleDownloadFile = () => {
    const fileServiceApiUrl = domainsConfig.get("fileServiceApiUrl");
    const baseURL = `${fileServiceApiUrl}/bulk/template/${templateCode}`;

    downloadFile({
      url: baseURL,
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
      filename: `${templateCode}.xlsx`,
    });
  };

  return isButton ? (
    <Button
      sx={{ margin: "10px", paddingInline: 6, borderRadius: "10px", whiteSpace: "nowrap" }}
      startIcon={<ExcelIcon />}
      disabled={isDownloading}
      onClick={handleDownloadFile}
    >
      {label || t("Download .xlx")}
    </Button>
  ) : (
    <Typography fontSize={14} sx={{ textDecoration: "underline", cursor: "pointer" }} onClick={handleDownloadFile}>
      {label || t("Download .xlx")}
    </Typography>
  );
};
