import { i18n } from "@toolkit/i18n";
import { UserStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const userStatusOptionsMap: IEnumToOptionsMap<UserStatus> = {
  [UserStatus.Active]: {
    key: UserStatus.Active,
    get label() {
      return i18n.t("Active", { ns: "domains" });
    },
    value: UserStatus.Active,
  },
  [UserStatus.Deactivated]: {
    key: UserStatus.Deactivated,
    get label() {
      return i18n.t("Inactive", { ns: "domains" });
    },
    value: UserStatus.Deactivated,
  },
};

export const userStatusOptions = Object.values(userStatusOptionsMap);
