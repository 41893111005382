import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useHealthProgramMemberListGetQuery } from "@/pages/HealthProgramMembersLists/gql";
import { healthProgramMemberListsPaths } from "@/pages/HealthProgramMembersLists/constants";
import { healthProgramMembersPaths } from "../constants";

type SetHealthProgramMembersBreadcrumbsProps = {
  memberListId?: string | null;
  name?: string;
};

export const useSetHealthProgramMembersBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE",
  props: SetHealthProgramMembersBreadcrumbsProps
) => {
  const { memberListId, name } = props;

  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Members");

  const { data, loading } = useHealthProgramMemberListGetQuery({
    variables: {
      filter: {
        ids: [memberListId!],
      },
    },
    fetchPolicy: "no-cache",
    skip: !memberListId,
  });

  const healthProgramMemberList = data?.healthProgramMemberLists?.edges?.[0]?.node;

  const healthProgramMemberListBreadcrumb = useMemo(() => {
    return {
      name: t("Members"),
      route: healthProgramMembersPaths.list.fullPath,
    };
  }, [t]);

  const healthProgramMembersListsListBreadcrumb = useMemo(() => {
    return {
      name: t("Members Lists"),
      route: healthProgramMemberListsPaths.list.fullPath,
    };
  }, [t]);

  const setHealthProgramMemberListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb(
      healthProgramMemberList?.name
        ? {
            title: breadcrumbTitle,
            values: [healthProgramMembersListsListBreadcrumb, { name: healthProgramMemberList.name }, { name: breadcrumbTitle }],
          }
        : { title: breadcrumbTitle }
    );
  }, [healthProgramMemberList?.name, setBreadCrumb, breadcrumbTitle, healthProgramMembersListsListBreadcrumb]);

  const setHealthProgramMemberCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb(
      healthProgramMemberList?.name
        ? {
            title: breadcrumbTitle,
            values: [
              healthProgramMembersListsListBreadcrumb,
              { name: healthProgramMemberList.name },
              { name: breadcrumbTitle, route: `${healthProgramMembersPaths.list.fullPath}?memberListId=${memberListId}` },
              { name: t("New") },
            ],
          }
        : {
            title: breadcrumbTitle,
            values: [healthProgramMemberListBreadcrumb, { name: t("New") }],
          }
    );
  }, [
    healthProgramMemberList?.name,
    setBreadCrumb,
    breadcrumbTitle,
    healthProgramMembersListsListBreadcrumb,
    memberListId,
    t,
    healthProgramMemberListBreadcrumb,
  ]);

  const setHealthProgramMemberUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb(
        healthProgramMemberList?.name
          ? {
              title: breadcrumbTitle,
              values: [
                healthProgramMembersListsListBreadcrumb,
                { name: healthProgramMemberList.name },
                { name: breadcrumbTitle, route: `${healthProgramMembersPaths.list.fullPath}?memberListId=${memberListId}` },
                { name },
              ],
            }
          : {
              title: breadcrumbTitle,
              values: [healthProgramMemberListBreadcrumb, { name }],
            }
      );
    }
  }, [
    name,
    healthProgramMemberList?.name,
    setBreadCrumb,
    breadcrumbTitle,
    healthProgramMembersListsListBreadcrumb,
    memberListId,
    healthProgramMemberListBreadcrumb,
  ]);

  useEffect(() => {
    if (!loading) {
      if (containerType === "LIST") {
        setHealthProgramMemberListContainerBreadcrumb();
      } else if (containerType === "CREATE") {
        setHealthProgramMemberCreateContainerBreadcrumb();
      } else if (containerType === "UPDATE") {
        setHealthProgramMemberUpdateContainerBreadcrumb();
      }
    }
  }, [
    loading,
    containerType,
    setHealthProgramMemberListContainerBreadcrumb,
    setHealthProgramMemberCreateContainerBreadcrumb,
    setHealthProgramMemberUpdateContainerBreadcrumb,
  ]);
};
