import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const blockUpsertFormSchema = z.object({
  name: z.string().min(1).max(255),
  nameAr: z.string().min(1).max(255),
  code: z.string().min(1).max(255),
  city: zodSchema.city,
});

export type IBlockUpsertFormValues = z.infer<typeof blockUpsertFormSchema>;

export const blockUpsertFormDefaultValues: Partial<IBlockUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  code: undefined,
  city: undefined,
};
