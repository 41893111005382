import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramMemberListsAutocompleteQuery } from "./gql";
import { IHealthProgramMemberListAutocomplete } from "./schema";

export const createHealthProgramMemberListAutocompleteOption = (memberList: IHealthProgramMemberListAutocomplete) => {
  return createAutocompleteOption({ id: memberList?.id!, name: memberList?.name! }, "id", "name");
};

export const getHealthProgramMemberListsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "healthProgramMemberLists",
    query: useHealthProgramMemberListsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
