import { i18n } from "@toolkit/i18n";
import { PatientEnrollmentRequestStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const patientEnrollmentRequestStatusOptionsMap: IEnumToOptionsMap<PatientEnrollmentRequestStatus> = {
  [PatientEnrollmentRequestStatus.InProgress]: {
    key: PatientEnrollmentRequestStatus.InProgress,
    get label() {
      return i18n.t("In Progress", { ns: "domains" });
    },
    value: PatientEnrollmentRequestStatus.InProgress,
  },
  [PatientEnrollmentRequestStatus.FinishedSuccessfully]: {
    key: PatientEnrollmentRequestStatus.FinishedSuccessfully,
    get label() {
      return i18n.t("Finished Successfully", { ns: "domains" });
    },
    value: PatientEnrollmentRequestStatus.FinishedSuccessfully,
  },
  [PatientEnrollmentRequestStatus.Failed]: {
    key: PatientEnrollmentRequestStatus.Failed,
    get label() {
      return i18n.t("Failed", { ns: "domains" });
    },
    value: PatientEnrollmentRequestStatus.Failed,
  },
};

export const patientEnrollmentRequestStatusOptions = Object.values(patientEnrollmentRequestStatusOptionsMap);
