import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcedureAgeUpdateMutationVariables = Types.Exact<{
  updateProcedureAgeId: Types.Scalars['ID']['input'];
  input: Types.ProcedureAgeInput;
}>;


export type ProcedureAgeUpdateMutation = { __typename?: 'Mutation', updateProcedureAge?: { __typename?: 'ProcedureAge', id: string } | null };


export const ProcedureAgeUpdateDocument = gql`
    mutation ProcedureAgeUpdate($updateProcedureAgeId: ID!, $input: ProcedureAgeInput!) {
  updateProcedureAge(id: $updateProcedureAgeId, input: $input) {
    id
  }
}
    `;
export type ProcedureAgeUpdateMutationFn = Apollo.MutationFunction<ProcedureAgeUpdateMutation, ProcedureAgeUpdateMutationVariables>;

/**
 * __useProcedureAgeUpdateMutation__
 *
 * To run a mutation, you first call `useProcedureAgeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcedureAgeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [procedureAgeUpdateMutation, { data, loading, error }] = useProcedureAgeUpdateMutation({
 *   variables: {
 *      updateProcedureAgeId: // value for 'updateProcedureAgeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcedureAgeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProcedureAgeUpdateMutation, ProcedureAgeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcedureAgeUpdateMutation, ProcedureAgeUpdateMutationVariables>(ProcedureAgeUpdateDocument, options);
      }
export type ProcedureAgeUpdateMutationHookResult = ReturnType<typeof useProcedureAgeUpdateMutation>;
export type ProcedureAgeUpdateMutationResult = Apollo.MutationResult<ProcedureAgeUpdateMutation>;
export type ProcedureAgeUpdateMutationOptions = Apollo.BaseMutationOptions<ProcedureAgeUpdateMutation, ProcedureAgeUpdateMutationVariables>;