import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersPermissionsGroupListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  appType: Types.AppTypes;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.PermissionGroupFilterInput>;
  sortBy?: Types.InputMaybe<Types.PermissionGroupSortingInput>;
}>;


export type UsersPermissionsGroupListQuery = { __typename?: 'Query', permissionGroups?: { __typename?: 'GroupCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'GroupCountableEdge', node: { __typename?: 'Group', id: string, name: string, created?: any | null, permissions?: Array<{ __typename?: 'Permission', id: string, name: string } | null> | null } }> } | null };


export const UsersPermissionsGroupListDocument = gql`
    query UsersPermissionsGroupList($first: Int, $before: String, $after: String, $appType: AppTypes!, $last: Int, $filter: PermissionGroupFilterInput, $sortBy: PermissionGroupSortingInput) {
  permissionGroups(
    first: $first
    last: $last
    filter: $filter
    sortBy: $sortBy
    before: $before
    after: $after
    appType: $appType
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        name
        created
        permissions {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useUsersPermissionsGroupListQuery__
 *
 * To run a query within a React component, call `useUsersPermissionsGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPermissionsGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPermissionsGroupListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      appType: // value for 'appType'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUsersPermissionsGroupListQuery(baseOptions: Apollo.QueryHookOptions<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables> & ({ variables: UsersPermissionsGroupListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables>(UsersPermissionsGroupListDocument, options);
      }
export function useUsersPermissionsGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables>(UsersPermissionsGroupListDocument, options);
        }
export function useUsersPermissionsGroupListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables>(UsersPermissionsGroupListDocument, options);
        }
export type UsersPermissionsGroupListQueryHookResult = ReturnType<typeof useUsersPermissionsGroupListQuery>;
export type UsersPermissionsGroupListLazyQueryHookResult = ReturnType<typeof useUsersPermissionsGroupListLazyQuery>;
export type UsersPermissionsGroupListSuspenseQueryHookResult = ReturnType<typeof useUsersPermissionsGroupListSuspenseQuery>;
export type UsersPermissionsGroupListQueryResult = Apollo.QueryResult<UsersPermissionsGroupListQuery, UsersPermissionsGroupListQueryVariables>;