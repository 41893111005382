import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";
import { zodSchema } from "@health/autocompletes";

export const optimaEditUpsertFormSchema = z.object({
  code: z.string().min(1),
  category: z.string().min(1),
  subCategory: z.string().min(1),
  rejectionCode: z.string().min(1),
  source: z.string().min(1),
  priority: zodEnumSchema.optimaPriority,
  editLevel: zodEnumSchema.optimaEditLevel,
  transactionLevel: z.boolean(),
  details: z.string().min(1),
  message: z.string().min(1),
  actions: z.array(zodSchema.optimaAction),
  isActive: z.boolean(),
});

export type IOptimaEditUpsertFormValues = z.infer<typeof optimaEditUpsertFormSchema>;

export const optimaEditUpsertFormDefaultValues: Partial<IOptimaEditUpsertFormValues> = {
  code: undefined,
  category: undefined,
  subCategory: undefined,
  source: undefined,
  rejectionCode: undefined,
  editLevel: undefined,
  transactionLevel: false,
  priority: undefined,
  details: undefined,
  message: undefined,
  actions: [],
  isActive: false,
};
