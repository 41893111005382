import { useTranslation } from "@toolkit/i18n";
import React, { FC, PropsWithChildren } from "react";
import { CircularProgress } from "../../base/mui";
import { PlusIcon } from "../../icons";
import { CustomDialog } from "../Dialogs";
import { FormActionsWrapperStyled, StyledButton } from "./FormActions.styles";
import { FormActionsProps } from "./types";
import { useOpenState } from "@toolkit/core";
import { useNavigate } from "react-router-dom";

export const FormActions: FC<PropsWithChildren<FormActionsProps>> = props => {
  const { t } = useTranslation();

  const {
    children,
    isLoading,
    loadingIndicators,
    hasSave,
    isSaveDisabled = false,
    hasEdit,
    hasCancel,
    hasFormButton,
    hasAddNewButton,
    formButtonTitle,
    TitleNewButton,
    isChanged,
    newButtonDisabled,
    onSave: handleSave,
    onEditItem: handleEditItem,
    onAddNewButton: handleAddNewButton,
  } = props;

  const navigate = useNavigate();

  const { open, handleOpen, handleToggle } = useOpenState();

  const handleNavigation = () => {
    navigate(-1);
  };

  const handleCancel = () => {
    if (isChanged) {
      handleOpen();
    } else {
      handleNavigation();
    }
  };

  return (
    <FormActionsWrapperStyled>
      <div>
        {children}
        {hasEdit && (
          <StyledButton
            color='success'
            size='medium'
            type='submit'
            disabled={loadingIndicators?.edit}
            startIcon={loadingIndicators?.edit ? <CircularProgress size={12} /> : <PlusIcon />}
            onClick={handleEditItem}
          >
            {loadingIndicators?.edit ? t("Updating") + "..." : t("Update")}
          </StyledButton>
        )}

        {hasAddNewButton && (
          <StyledButton
            color='warning'
            size='medium'
            type='submit'
            startIcon={<PlusIcon />}
            onClick={handleAddNewButton}
            sx={{ minWidth: "max-content" }}
          >
            {TitleNewButton}
          </StyledButton>
        )}
        {!isLoading && hasFormButton && (
          <StyledButton color='success' size='medium' type='submit' disabled={newButtonDisabled}>
            {formButtonTitle}
          </StyledButton>
        )}
        {hasSave && (
          <StyledButton
            color='success'
            size='medium'
            type='submit'
            startIcon={loadingIndicators?.save ? <CircularProgress size={12} /> : <PlusIcon />}
            onClick={handleSave}
            disabled={isSaveDisabled}
            sx={{ minWidth: "max-content" }}
          >
            {loadingIndicators?.save ? t("Saving...") : t("Save")}
          </StyledButton>
        )}
      </div>
      {hasCancel && (
        <StyledButton variant='outlined' size='medium' onClick={handleCancel}>
          {t("Cancel")}
        </StyledButton>
      )}
      <CustomDialog
        type='warning'
        text={{
          body: t("Are you sure you want to cancel editing fields?"),
        }}
        isOpen={open}
        onConfirm={handleNavigation}
        onClose={handleToggle}
      />
    </FormActionsWrapperStyled>
  );
};
