import { QuestionType } from "@/schema/types";
import { ISurveyQuestionFormValues } from "../forms/SurveyQuestions/SurveyQuestionFormSchema";
import { questionTypeOptionsMap } from "@health/enum-options";

export const newSurveyQuestionDefaultsValue: ISurveyQuestionFormValues = {
  question: "",
  questionType: questionTypeOptionsMap[QuestionType.MultiChoice],
  required: true,
  options: [],
} as const;
