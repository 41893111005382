import { CptHcpcPrimaryProcedure } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertCptHcpcPrimaryProcedureToFormValues } from "../../others";
import {
  cptHcpcPrimaryProcedureUpsertFormDefaultValues,
  cptHcpcPrimaryProcedureUpsertFormSchema,
  ICptHcpcPrimaryProcedureUpsertFormValues,
} from "./cptHcpcPrimaryProcedureUpsertFormSchema";
import { CptHcpcPrimaryProcedureInformationForm } from "../CptHcpcPrimaryProcedureInformation/CptHcpcPrimaryProcedureInformationForm";

export type ICptHcpcPrimaryProcedureUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: ICptHcpcPrimaryProcedureUpsertFormValues;
  };
};

type CptHcpcPrimaryProcedureUpsertFormProps = {
  cptHcpcPrimaryProcedure?: CptHcpcPrimaryProcedure;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: ICptHcpcPrimaryProcedureUpsertFormEvent) => void;
};

export const CptHcpcPrimaryProcedureUpsertForm: FC<CptHcpcPrimaryProcedureUpsertFormProps> = props => {
  const { buttonLabel, cptHcpcPrimaryProcedure, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<ICptHcpcPrimaryProcedureUpsertFormValues>({
    defaultValues: cptHcpcPrimaryProcedureUpsertFormDefaultValues,
    schema: cptHcpcPrimaryProcedureUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: ICptHcpcPrimaryProcedureUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (cptHcpcPrimaryProcedure) {
      const _cptHcpcPrimaryProcedure = convertCptHcpcPrimaryProcedureToFormValues(cptHcpcPrimaryProcedure);
      setValues(_cptHcpcPrimaryProcedure);
    }
  }, [cptHcpcPrimaryProcedure, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("CPT HCPC Primary Procedure Information")} loading={isLoading} doYouHaveData>
                <CptHcpcPrimaryProcedureInformationForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
