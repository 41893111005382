import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import React from "react";
import { useSetSurveysBreadcrumbs } from "../../hooks";

import { GridProvider } from "@health/domains";
import { useFetchSurveysQuery } from "../../gql";
import { useSurveyListContainerColumns } from "./useSurveyListContainerColumns";
import { SortDirection, SurveySortingField } from "@/schema/types";
import { useNavigate } from "react-router-dom";
import { surveysPaths } from "../../constants";

export const SurveysListContainer: React.FC = () => {
  const navigate = useNavigate();

  useSetSurveysBreadcrumbs("LIST");

  const onAddNewItemClick = () => {
    navigate(surveysPaths.create.fullPath);
  };

  const onEditRowClick = (item: any) => {
    navigate(surveysPaths.update.fullPathWithParams({ surveyId: item?.id }));
  };

  return (
    <GridProvider
      hasTableSetting
      gridName={"surveyList"}
      query={useFetchSurveysQuery}
      columns={useSurveyListContainerColumns()}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          field: SurveySortingField.CreationDate,
          direction: SortDirection.Desc,
        },
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};

export default SurveysListContainer;
