import {
  HealthProgramNetwork,
  HealthProgramNetworkInput,
  HealthProgramNetworkProvider,
  HealthProgramNetworkProviderInput,
} from "@/schema/types";
import { createPayerAutocompleteOption, createVendorAutocompleteOption } from "@health/autocompletes";
import { IHealthProgramNetworkProviderUpsertFormValues } from "../forms/HealthProgramNetworkProviderUpsert/HealthProgramNetworkProviderUpsertFormSchema";
import { IHealthProgramNetworkUpsertFormValues } from "../forms/HealthProgramNetworkUpsert/HealthProgramNetworkUpsertFormSchema";

export const convertHealthProgramNetworkToFormValues = (
  healthProgramNetwork: HealthProgramNetwork
): IHealthProgramNetworkUpsertFormValues => {
  return {
    name: healthProgramNetwork?.name!,
    payer: createPayerAutocompleteOption(healthProgramNetwork?.payer!),
    categoryCode: healthProgramNetwork?.categoryCode!,
    policyNumber: healthProgramNetwork?.policyNumber!,
    isActive: !!healthProgramNetwork?.isActive,
  };
};

export const convertHealthProgramNetworkFormValuesToBackEndValues = (
  values: IHealthProgramNetworkUpsertFormValues
): HealthProgramNetworkInput => {
  return {
    name: values?.name,
    payerId: values?.payer?.value?.id,
    categoryCode: values?.categoryCode,
    policyNumber: values?.policyNumber,
    isActive: values?.isActive,
  };
};

export const convertHealthProgramNetworkProviderToFormValues = (
  healthProgramNetworkProvider: HealthProgramNetworkProvider
): IHealthProgramNetworkProviderUpsertFormValues => {
  return {
    vendor: createVendorAutocompleteOption(healthProgramNetworkProvider?.provider!),
    services: healthProgramNetworkProvider?.services?.length ? (healthProgramNetworkProvider?.services as string[]) : [],
    isVirtualCallEnabled: !!healthProgramNetworkProvider?.virtualCallEnabled,
    isActive: !!healthProgramNetworkProvider?.isActive,
  };
};

export const convertHealthProgramNetworkProviderFormValuesToBackEndValues = (
  values: IHealthProgramNetworkProviderUpsertFormValues
): HealthProgramNetworkProviderInput => {
  return {
    providerId: values?.vendor?.value?.id,
    services: values?.services,
    virtualCallEnabled: values?.isVirtualCallEnabled,
    isActive: values?.isActive,
  };
};
