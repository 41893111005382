import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthParametersAutocompleteQuery } from "./gql";

export type IHealthParameterAutocomplete = ExtractNodeType<HealthParametersAutocompleteQuery>;

export const healthParameterSchema = createZodAutocompleteFromType<IHealthParameterAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  arabicDisplay: z.string().nullish(),
});
