import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { convertItemCodeMaximumQuantityAllowedFormValuesToBackEndValues } from "../../others";

import { useItemCodeMaximumQuantityAllowedCreateMutation } from "../../gql/mutations/__generated__";
import { itemCodeMaximumQuantityAllowedsPaths } from "../../constants/ItemCodeMaximumQuantityAllowedPaths";
import {
  IItemCodeMaximumQuantityAllowedUpsertFormEvent,
  ItemCodeMaximumQuantityAllowedUpsertForm,
} from "../../forms/ItemCodeMaximumQuantityAllowedInformationUpsert/ItemCodeMaximumQuantityAllowedInformationUpsertForm";
import { useSetItemCodeMaximumQuantityAllowedsBreadcrumbs } from "../../hooks/useSetItemCodeMaximumQuantityAllowedsBreadcrumbs";

export const ItemCodeMaximumQuantityAllowedCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createItemCodeMaximumQuantityAllowed, { loading: isSubmitting }] = useItemCodeMaximumQuantityAllowedCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.createItemCodeMaximumQuantityAllowed?.id) {
        succeeded(t("Item Code Maximum Quantity Allowed created successfully"));
        navigate(itemCodeMaximumQuantityAllowedsPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onItemCodeMaximumQuantityAllowedUpsertFormChange = (event: IItemCodeMaximumQuantityAllowedUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertItemCodeMaximumQuantityAllowedFormValuesToBackEndValues(event.payload.values);

      createItemCodeMaximumQuantityAllowed({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetItemCodeMaximumQuantityAllowedsBreadcrumbs("CREATE");

  return (
    <ItemCodeMaximumQuantityAllowedUpsertForm
      buttonLabel={t("Create")}
      isSubmitting={isSubmitting}
      onChange={onItemCodeMaximumQuantityAllowedUpsertFormChange}
    />
  );
};
