import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramNetworkProviderInput;
}>;


export type HealthProgramNetworkProviderCreateMutation = { __typename?: 'Mutation', healthProgramNetworkProviderCreate?: { __typename?: 'HealthProgramNetworkProviderCRUD', entity?: { __typename?: 'HealthProgramNetworkProvider', id: string } | null } | null };


export const HealthProgramNetworkProviderCreateDocument = gql`
    mutation HealthProgramNetworkProviderCreate($input: HealthProgramNetworkProviderInput!) {
  healthProgramNetworkProviderCreate(input: $input) {
    entity {
      id
    }
  }
}
    `;
export type HealthProgramNetworkProviderCreateMutationFn = Apollo.MutationFunction<HealthProgramNetworkProviderCreateMutation, HealthProgramNetworkProviderCreateMutationVariables>;

/**
 * __useHealthProgramNetworkProviderCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkProviderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkProviderCreateMutation, { data, loading, error }] = useHealthProgramNetworkProviderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkProviderCreateMutation, HealthProgramNetworkProviderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkProviderCreateMutation, HealthProgramNetworkProviderCreateMutationVariables>(HealthProgramNetworkProviderCreateDocument, options);
      }
export type HealthProgramNetworkProviderCreateMutationHookResult = ReturnType<typeof useHealthProgramNetworkProviderCreateMutation>;
export type HealthProgramNetworkProviderCreateMutationResult = Apollo.MutationResult<HealthProgramNetworkProviderCreateMutation>;
export type HealthProgramNetworkProviderCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkProviderCreateMutation, HealthProgramNetworkProviderCreateMutationVariables>;