import { BulkFile, BulkFileSortField } from "@/schema/types";
import { getUsersAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { CancelBulkFile } from "./CancelBulkFile";
import { DownloadBulkFile } from "./DownloadBulkFile";

export type BulkFilesColumnTypes = CustomTableColumnProps<BulkFile | any>[];

export const useUploadBulkFilesColumns = (): BulkFilesColumnTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        filter: getAutocompleteEnumFilter("BulkTemplateCode", "code"),
        sort: {
          columnEnum: BulkFileSortField.Code,
        },
      },
      {
        key: "name",
        header: t("File Name"),
        type: "truncated-text",
        accessor: "fileName",
        filter: {
          type: "string",
          name: "fileName",
        },
        sort: {
          columnEnum: BulkFileSortField.FileName,
        },
      },
      {
        key: "status",
        header: t("Status"),
        accessor: "processingStatus",
        filter: getAutocompleteEnumFilter("BulkProcessingStatus", "processingStatus"),
      },
      {
        key: "recordsCount",
        header: t("Records Count"),
        accessor: "recordsCount",
        sort: {
          columnEnum: BulkFileSortField.RecordsCount,
        },
      },
      {
        key: "failedRecordCount",
        header: t("Failed Records Count"),
        accessor: "failedRecordCount",
        sort: {
          columnEnum: BulkFileSortField.FailedRecordCount,
        },
      },
      {
        key: "processedRecordCount",
        header: t("Processed Records Count"),
        accessor: "processedRecordCount",
        sort: {
          columnEnum: BulkFileSortField.ProcessedRecordCount,
        },
      },
      {
        key: "createdBy",
        header: t("Created By"),
        type: "truncated-text",
        accessor: ({ createdBy }) => (createdBy?.firstName || "") + " " + (createdBy?.lastName || ""),
        filter: getUsersAutocompleteFilter({ name: "createdBy" }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        type: "date",
        accessor: "createdDate",
        sort: {
          columnEnum: BulkFileSortField.CreatedDate,
        },
      },
      {
        key: "fileName",
        header: t("Download File"),
        accessor: row => <DownloadBulkFile isButton title={t("Download .xlx")} id={row?.id} processingStatus={row?.processingStatus} />,
        isHidden: false,
      },
      {
        key: "cancel",
        header: t("Stop File Processing"),
        accessor: row => <CancelBulkFile isButton title={t("Cancel")} id={row?.id} processingStatus={row?.processingStatus} />,
        isHidden: false,
      },
    ];
  }, []);
};
