import { Field } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { DecisionConditionFieldTypeForm } from "../DecisionConditionFieldType/DecisionConditionFieldTypeForm";
import { IDecisionUpsertFormValues } from "../DecisionUpsert/DecisionUpsertFormSchema";
import {
  getConditionAllowedValuesOptions,
  getConditionCategoriesOptions,
  getConditionFieldsOptions,
  getConditionOperatorsOptions,
  shouldDecisionConditionOperatorHide,
} from "../../others";
import { IDecisionConditionGroupConditionPath } from "../../types";

type DecisionConditionsUpsertFormProps = {
  isFieldsMoreThanOne: boolean;
  conditionPath: IDecisionConditionGroupConditionPath;
  decisionPlanFields: Field[];
};

export const DecisionConditionsUpsertForm: FC<DecisionConditionsUpsertFormProps> = props => {
  const { isFieldsMoreThanOne, decisionPlanFields, conditionPath } = props;

  const { t } = useTranslation("domains");

  const form = useFormContext<IDecisionUpsertFormValues>();

  const { watch } = form;

  const condition = watch(conditionPath);

  const categoriesOptions = getConditionCategoriesOptions(decisionPlanFields);
  const fieldsOptions = getConditionFieldsOptions(decisionPlanFields, condition?.category?.value?.code!);
  const operatorOptions = getConditionOperatorsOptions(decisionPlanFields, condition?.field?.value?.code);
  const allowedValuesOptions = getConditionAllowedValuesOptions(decisionPlanFields, condition?.field?.value?.code);

  return (
    <>
      <Grid item xs={12} md={6} lg={isFieldsMoreThanOne ? 2.5 : 3}>
        <FormAutocomplete
          name={`${conditionPath}.category`}
          label={t("Category")}
          placeholder={t("Category")}
          options={categoriesOptions}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={isFieldsMoreThanOne ? 2.5 : 3}>
        <FormAutocomplete name={`${conditionPath}.field`} label={t("Field")} placeholder={t("Field")} options={fieldsOptions} />
      </Grid>

      <Grid item xs={12} md={6} lg={isFieldsMoreThanOne ? 2.5 : 3}>
        <FormAutocomplete name={`${conditionPath}.operator`} label={t("Operator")} placeholder={t("Operator")} options={operatorOptions} />
      </Grid>

      <Grid item xs={12} md={6} lg={isFieldsMoreThanOne ? 2.5 : 3}>
        {condition && !shouldDecisionConditionOperatorHide(condition?.operator?.key) && (
          <DecisionConditionFieldTypeForm conditionPath={conditionPath} condition={condition} allowedValuesOptions={allowedValuesOptions} />
        )}
      </Grid>
    </>
  );
};
