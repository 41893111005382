import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import {
  CptHcpcPrimaryProcedureCreateContainer,
  CptHcpcPrimaryProcedureListContainer,
  CptHcpcPrimaryProcedureUpdateContainer,
} from "../containers";
import { cptHcpcPrimaryProcedurePaths, cptHcpcPrimaryProcedureRoute } from "./CptHcpcPrimaryProcedurePaths";

export const cptHcpcPrimaryProcedureRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "optima-cpt-hcpc-primary-procedures-routes",
    text: i18n.t("CPT HCPC Primary Procedure", { ns: "admin" }),
    route: cptHcpcPrimaryProcedureRoute,
    subItems: [
      {
        id: "optima-cpt-hcpc-primary-procedure-list-route",
        route: cptHcpcPrimaryProcedurePaths.list.route,
        fullPath: cptHcpcPrimaryProcedurePaths.list.fullPath,
        element: <CptHcpcPrimaryProcedureListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-cpt-hcpc-primary-procedure-create-route",
        route: cptHcpcPrimaryProcedurePaths.create.route,
        fullPath: cptHcpcPrimaryProcedurePaths.create.fullPath,
        hidden: true,
        element: <CptHcpcPrimaryProcedureCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-cpt-hcpc-primary-procedure-update-route",
        route: cptHcpcPrimaryProcedurePaths.update.route,
        fullPath: cptHcpcPrimaryProcedurePaths.update.fullPath,
        hidden: true,
        element: <CptHcpcPrimaryProcedureUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
