import { InsuranceBenefitApprovalLimit } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { IInsuranceBenefitApprovalLimitUpdateContainerParams } from "../../types";
import { convertInsuranceBenefitApprovalLimitFormValuesToBackEndValues } from "../../others";
import {
  InsuranceBenefitApprovalLimitUpsertForm,
  IInsuranceBenefitApprovalLimitUpsertFormEvent,
} from "../../forms/InsuranceBenefitApprovalLimitUpsert/InsuranceBenefitApprovalLimitUpsertForm";
import { useSetInsuranceBenefitApprovalLimitsBreadcrumbs } from "../../hooks/useSetInsuranceBenefitApprovalLimitsBreadcrumbs";
import { useInsuranceBenefitApprovalLimitGetQuery, useInsuranceBenefitApprovalLimitUpdateMutation } from "../../gql";
import { insuranceBenefitApprovalLimitsPaths } from "../../constants/InsuranceBenefitApprovalLimitPaths";

export const InsuranceBenefitApprovalLimitUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { insuranceBenefitApprovalLimitId } = useParams<IInsuranceBenefitApprovalLimitUpdateContainerParams>();

  const { data, loading } = useInsuranceBenefitApprovalLimitGetQuery({
    variables: { getInsuranceBenefitApprovalLimitId: insuranceBenefitApprovalLimitId! },
    skip: !insuranceBenefitApprovalLimitId,
    fetchPolicy: "no-cache",
  });

  const insuranceBenefitApprovalLimit = data?.getInsuranceBenefitApprovalLimit as InsuranceBenefitApprovalLimit;

  const [updateInsuranceBenefitApprovalLimit, { loading: isSubmitting }] = useInsuranceBenefitApprovalLimitUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.updateInsuranceBenefitApprovalLimit?.id) {
        succeeded(t("Insurance Benefit Approval Limits updated successfully"));
        navigate(insuranceBenefitApprovalLimitsPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onInsuranceBenefitApprovalLimitUpsertFormChange = (event: IInsuranceBenefitApprovalLimitUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertInsuranceBenefitApprovalLimitFormValuesToBackEndValues(event.payload.values);

      updateInsuranceBenefitApprovalLimit({
        variables: {
          updateInsuranceBenefitApprovalLimitId: insuranceBenefitApprovalLimitId!,
          input: values,
        },
      });
    }
  };

  useSetInsuranceBenefitApprovalLimitsBreadcrumbs("UPDATE", insuranceBenefitApprovalLimit?.insuranceLicense);

  return (
    <InsuranceBenefitApprovalLimitUpsertForm
      buttonLabel={t("Update")}
      insuranceBenefitApprovalLimit={insuranceBenefitApprovalLimit}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onInsuranceBenefitApprovalLimitUpsertFormChange}
    />
  );
};
