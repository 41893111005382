import { makeStyles } from "@toolkit/ui";

export const useDecisionConditionsGroupsFormStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    padding: "0px 10px !important",
    marginBlock: 8,
  },
  addGroupButton: {
    backgroundColor: theme.palette.success.main,
  },
}));
