import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMembersAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberFilterInput>;
}>;


export type HealthProgramMembersAutocompleteQuery = { __typename?: 'Query', healthProgramMembers?: { __typename?: 'HealthProgramMemberCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', node: { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null } }> } | null };


export const HealthProgramMembersAutocompleteDocument = gql`
    query HealthProgramMembersAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramMemberFilterInput) {
  healthProgramMembers(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        firstName
        lastName
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useHealthProgramMembersAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramMembersAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMembersAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMembersAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramMembersAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>(HealthProgramMembersAutocompleteDocument, options);
      }
export function useHealthProgramMembersAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>(HealthProgramMembersAutocompleteDocument, options);
        }
export function useHealthProgramMembersAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>(HealthProgramMembersAutocompleteDocument, options);
        }
export type HealthProgramMembersAutocompleteQueryHookResult = ReturnType<typeof useHealthProgramMembersAutocompleteQuery>;
export type HealthProgramMembersAutocompleteLazyQueryHookResult = ReturnType<typeof useHealthProgramMembersAutocompleteLazyQuery>;
export type HealthProgramMembersAutocompleteSuspenseQueryHookResult = ReturnType<typeof useHealthProgramMembersAutocompleteSuspenseQuery>;
export type HealthProgramMembersAutocompleteQueryResult = Apollo.QueryResult<HealthProgramMembersAutocompleteQuery, HealthProgramMembersAutocompleteQueryVariables>;