/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { VisitStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const visitStatusOptionsMap: IEnumToOptionsMap<VisitStatus> = {
  [VisitStatus.Accepted]: {
    key: VisitStatus.Accepted,
    get label() {
      return i18n.t("Accepted", { ns: "domains" });
    },
    value: VisitStatus.Accepted,
  },
  [VisitStatus.Assigned]: {
    key: VisitStatus.Assigned,
    get label() {
      return i18n.t("Assigned", { ns: "domains" });
    },
    value: VisitStatus.Assigned,
  },
  [VisitStatus.CallInProgress]: {
    key: VisitStatus.CallInProgress,
    get label() {
      return i18n.t("Call in Progress", { ns: "domains" });
    },
    value: VisitStatus.CallInProgress,
  },
  [VisitStatus.CallRejoinable]: {
    key: VisitStatus.CallRejoinable,
    get label() {
      return i18n.t("Call Rejoinable", { ns: "domains" });
    },
    value: VisitStatus.CallRejoinable,
  },
  [VisitStatus.CallTimedOut]: {
    key: VisitStatus.CallTimedOut,
    get label() {
      return i18n.t("Call Timed Out", { ns: "domains" });
    },
    value: VisitStatus.CallTimedOut,
  },
  [VisitStatus.Canceled]: {
    key: VisitStatus.Canceled,
    get label() {
      return i18n.t("Canceled", { ns: "domains" });
    },
    value: VisitStatus.Canceled,
  },
  [VisitStatus.CanceledByConsumer]: {
    key: VisitStatus.CanceledByConsumer,
    get label() {
      return i18n.t("Canceled by Consumer", { ns: "domains" });
    },
    value: VisitStatus.CanceledByConsumer,
  },
  [VisitStatus.ChatInProgress]: {
    key: VisitStatus.ChatInProgress,
    get label() {
      return i18n.t("Chat in Progress", { ns: "domains" });
    },
    value: VisitStatus.ChatInProgress,
  },
  [VisitStatus.Completed]: {
    key: VisitStatus.Completed,
    get label() {
      return i18n.t("Completed", { ns: "domains" });
    },
    value: VisitStatus.Completed,
  },
  [VisitStatus.DoctorAnswerTimedOut]: {
    key: VisitStatus.DoctorAnswerTimedOut,
    get label() {
      return i18n.t("Doctor Answer Timed Out", { ns: "domains" });
    },
    value: VisitStatus.DoctorAnswerTimedOut,
  },
  [VisitStatus.EndedByOneOfTheParticipants]: {
    key: VisitStatus.EndedByOneOfTheParticipants,
    get label() {
      return i18n.t("Ended by One of the Participants", { ns: "domains" });
    },
    value: VisitStatus.EndedByOneOfTheParticipants,
  },
  [VisitStatus.Incompleted]: {
    key: VisitStatus.Incompleted,
    get label() {
      return i18n.t("Incompleted", { ns: "domains" });
    },
    value: VisitStatus.Incompleted,
  },
  [VisitStatus.OutPatientJourneyAssessmentDone]: {
    key: VisitStatus.OutPatientJourneyAssessmentDone,
    get label() {
      return i18n.t("Out Patient Journey Assessment Done", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyAssessmentDone,
  },
  [VisitStatus.OutPatientJourneyAssessmentPending]: {
    key: VisitStatus.OutPatientJourneyAssessmentPending,
    get label() {
      return i18n.t("Out Patient Journey Assessment Pending", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyAssessmentPending,
  },
  [VisitStatus.OutPatientJourneyCheckedIn]: {
    key: VisitStatus.OutPatientJourneyCheckedIn,
    get label() {
      return i18n.t("Out Patient Journey Checked In", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyCheckedIn,
  },
  [VisitStatus.OutPatientJourneyConsultationDone]: {
    key: VisitStatus.OutPatientJourneyConsultationDone,
    get label() {
      return i18n.t("Out Patient Journey Consultation Done", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyConsultationDone,
  },
  [VisitStatus.OutPatientJourneyConsultationPending]: {
    key: VisitStatus.OutPatientJourneyConsultationPending,
    get label() {
      return i18n.t("Out Patient Journey Consultation Pending", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyConsultationPending,
  },
  [VisitStatus.OutPatientJourneyPending]: {
    key: VisitStatus.OutPatientJourneyPending,
    get label() {
      return i18n.t("Out Patient Journey Pending", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyPending,
  },
  [VisitStatus.OutPatientJourneyTreatmentDone]: {
    key: VisitStatus.OutPatientJourneyTreatmentDone,
    get label() {
      return i18n.t("Out Patient Journey Treatment Done", { ns: "domains" });
    },
    value: VisitStatus.OutPatientJourneyTreatmentDone,
  },
  [VisitStatus.PaymentFailed]: {
    key: VisitStatus.PaymentFailed,
    get label() {
      return i18n.t("Payment Failed", { ns: "domains" });
    },
    value: VisitStatus.PaymentFailed,
  },
  [VisitStatus.PaymentPending]: {
    key: VisitStatus.PaymentPending,
    get label() {
      return i18n.t("Payment Pending", { ns: "domains" });
    },
    value: VisitStatus.PaymentPending,
  },
  [VisitStatus.PaymentSucceeded]: {
    key: VisitStatus.PaymentSucceeded,
    get label() {
      return i18n.t("Payment Succeeded", { ns: "domains" });
    },
    value: VisitStatus.PaymentSucceeded,
  },
  [VisitStatus.PaymentTimedOut]: {
    key: VisitStatus.PaymentTimedOut,
    get label() {
      return i18n.t("Payment Timed Out", { ns: "domains" });
    },
    value: VisitStatus.PaymentTimedOut,
  },
  [VisitStatus.Pending]: {
    key: VisitStatus.Pending,
    get label() {
      return i18n.t("Pending", { ns: "domains" });
    },
    value: VisitStatus.Pending,
  },
  [VisitStatus.Rejected]: {
    key: VisitStatus.Rejected,
    get label() {
      return i18n.t("Rejected", { ns: "domains" });
    },
    value: VisitStatus.Rejected,
  },
};

export const visitStatusOptions = Object.values(visitStatusOptionsMap);
