import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PaymentSiteSettingsUpdateMutationVariables = Types.Exact<{
  input: Types.Payment_SiteSettingsInput;
}>;


export type PaymentSiteSettingsUpdateMutation = { __typename?: 'Mutation', paymentSiteSettingsUpdate?: { __typename?: 'SiteSettings', atHomeAppointmentPaymentCollectionEnabled?: boolean | null, onSiteAppointmentPaymentCollectionEnabled?: boolean | null, pharmacyCoPaymentPaymentCollectionEnabled?: boolean | null } | null };


export const PaymentSiteSettingsUpdateDocument = gql`
    mutation PaymentSiteSettingsUpdate($input: Payment_SiteSettingsInput!) {
  paymentSiteSettingsUpdate(input: $input) {
    atHomeAppointmentPaymentCollectionEnabled
    onSiteAppointmentPaymentCollectionEnabled
    pharmacyCoPaymentPaymentCollectionEnabled
  }
}
    `;
export type PaymentSiteSettingsUpdateMutationFn = Apollo.MutationFunction<PaymentSiteSettingsUpdateMutation, PaymentSiteSettingsUpdateMutationVariables>;

/**
 * __usePaymentSiteSettingsUpdateMutation__
 *
 * To run a mutation, you first call `usePaymentSiteSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentSiteSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentSiteSettingsUpdateMutation, { data, loading, error }] = usePaymentSiteSettingsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentSiteSettingsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PaymentSiteSettingsUpdateMutation, PaymentSiteSettingsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentSiteSettingsUpdateMutation, PaymentSiteSettingsUpdateMutationVariables>(PaymentSiteSettingsUpdateDocument, options);
      }
export type PaymentSiteSettingsUpdateMutationHookResult = ReturnType<typeof usePaymentSiteSettingsUpdateMutation>;
export type PaymentSiteSettingsUpdateMutationResult = Apollo.MutationResult<PaymentSiteSettingsUpdateMutation>;
export type PaymentSiteSettingsUpdateMutationOptions = Apollo.BaseMutationOptions<PaymentSiteSettingsUpdateMutation, PaymentSiteSettingsUpdateMutationVariables>;