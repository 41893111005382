import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const procedureAgeUpsertFormSchema = z
  .object({
    procedureCode: z.string().min(1).max(255),
    minAgeDays: z.number().min(1).int(),
    maxAgeDays: z.number().min(1).int(),
    description: z.string().min(1).max(255).optional(),
  })
  .superRefine((data, ctx) => {
    if (data.minAgeDays > data.maxAgeDays) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Max Age Days should be greater than Min Age Days", { ns: "admin" }),
        path: ["maxAgeDays"],
      });
    }
  });
export type IProcedureAgeUpsertFormValues = z.infer<typeof procedureAgeUpsertFormSchema>;

export const procedureAgeUpsertFormDefaultValues: Partial<IProcedureAgeUpsertFormValues> = {
  procedureCode: "",
  minAgeDays: 0,
  maxAgeDays: 0,
  description: "",
};
