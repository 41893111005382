import { OptimaEditInput } from "@/schema/types";
import { createOptimaActionAutocompleteOption } from "@health/autocompletes";
import { optimaEditLevelTypeOptionsMap, optimaPriorityOptionsMap } from "@health/enum-options";
import { IOptimaEditUpsertFormValues } from "@/pages/OptimaEdits/forms/OptimaEditUpsert/OptimaEditUpsertFormSchema";
import { OptimaEditDataType } from "../types";

export const convertOptimaEditToFormValues = (optimaEdit: OptimaEditDataType): IOptimaEditUpsertFormValues => {
  return {
    code: optimaEdit?.code!,
    category: optimaEdit?.category!,
    subCategory: optimaEdit?.subCategory!,
    source: optimaEdit?.source!,
    priority: optimaPriorityOptionsMap[optimaEdit?.priority!],
    editLevel: optimaEditLevelTypeOptionsMap[optimaEdit?.editLevel!],
    transactionLevel: !!optimaEdit?.transactionLevel,
    details: optimaEdit?.details!,
    rejectionCode:
      optimaEdit?.rejectionCode
        ?.map(item => item?.trim())
        .filter(item => item)
        .join(",") || "",
    message: optimaEdit?.message!,
    actions: optimaEdit?.actions?.length ? optimaEdit?.actions?.map(item => createOptimaActionAutocompleteOption(item!)) : [],
    isActive: !!optimaEdit?.isActive,
  };
};

export const convertOptimaEditFormValuesToBackEndValues = (values: IOptimaEditUpsertFormValues): OptimaEditInput => {
  return {
    code: values?.code!,
    category: values?.category!,
    subCategory: values?.subCategory!,
    rejectionCode:
      values?.rejectionCode
        ?.split(",")
        ?.map(item => item.trim())
        .filter(item => item) || [],
    editLevel: values?.editLevel?.value,
    transactionLevel: values?.transactionLevel!,
    priority: values?.priority?.value,
    source: values?.source!,
    details: values?.details!,
    message: values?.message!,
    actionIds: values?.actions?.map(item => item?.value?.id),
    isActive: values?.isActive!,
  };
};
