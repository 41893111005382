import { CodeSystemCode } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { ExcelIcon, MenuItem, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { domainsConfig } from "../../../../../domainsConfig";
import { useDownloadCodeSystemFileLazyQuery } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const DownloadButton: FC<{ hasData: boolean; onClick: () => void }> = ({ onClick, hasData }) => {
  const { t } = useTranslation("domains");
  const { failed } = useAddToast();
  const theme = useTheme();
  const { code, versionParamId } = useAdminManagedListDetailsPageContext();
  const handleDownloadFile = () => {
    onClick();
    getDownloadCodeSystemFile({
      variables: {
        code: code as CodeSystemCode,
        codeSystemId: versionParamId!,
      },
    });
  };
  const handleDownloadTemplateFile = () => {
    onClick();
    getDownloadCodeSystemFile({
      variables: {
        code: code as CodeSystemCode,
      },
    });
  };

  const [getDownloadCodeSystemFile, { loading }] = useDownloadCodeSystemFileLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data?.downloadCodeSystemFile) {
        const baseURL = domainsConfig.get("serverUrl");
        window.open(baseURL + data.downloadCodeSystemFile, "_self");
      }
    },
    onError: () => {
      failed(t("Download Failed"));
    },
  });
  return (
    <>
      <MenuItem disabled={loading || !hasData} onClick={handleDownloadFile}>
        <ExcelIcon fill={theme.palette.primary.main} />
        <Typography marginLeft='8px'> {t("Export Data")} .xlsx</Typography>
      </MenuItem>
      <MenuItem disabled={loading} onClick={handleDownloadTemplateFile}>
        <ExcelIcon fill={theme.palette.primary.main} />
        <Typography marginLeft='8px'> {t("Download Template")} .xlsx</Typography>
      </MenuItem>
    </>
  );
};
