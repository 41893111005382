import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const bulkMessageSenderFormSchema = z.object({
  title: z.string().min(1).max(255),
  titleAr: z.string().min(1).max(255),
  message: z.string().min(1).max(1000),
  messageAr: z.string().min(1).max(1000),
  method: zodEnumSchema.bulkMessagesMethodEnum,
  publishWithCriteria: z.boolean(),
});

export type IBulkMessageSenderFormValues = z.infer<typeof bulkMessageSenderFormSchema>;

export const bulkMessageSenderFormDefaultValues: Partial<IBulkMessageSenderFormValues> = {
  title: "",
  titleAr: "",
  message: "",
  messageAr: "",
  method: undefined,
  publishWithCriteria: false,
};
