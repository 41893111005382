import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchDataForEditCodeSystemConceptQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  codeSystemConceptId: Types.Scalars['ID']['input'];
}>;


export type FetchDataForEditCodeSystemConceptQuery = { __typename?: 'Query', schemaData?: { __typename?: 'CodeSystem', id?: string | null, codeSystemDefinition?: { __typename?: 'CodeSystemDefinition', id?: string | null, code?: Types.CodeSystemCode | null, codeSystemConceptFields?: Array<{ __typename?: 'CodeSystemConceptFieldDefinition', code?: string | null, display?: string | null, fieldType?: Types.Type | null, isMandatory?: boolean | null } | null> | null } | null } | null, propertiesData?: { __typename?: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', code?: string | null, fieldType?: Types.Type | null, valueDate?: any | null, valueBoolean?: boolean | null, valueFloat?: number | null, valueInteger?: number | null, valueString?: string | null } | null> | null } | null };


export const FetchDataForEditCodeSystemConceptDocument = gql`
    query fetchDataForEditCodeSystemConcept($id: ID!, $codeSystemConceptId: ID!) {
  schemaData: getCodeSystem(id: $id) {
    id
    codeSystemDefinition {
      id
      code
      codeSystemConceptFields {
        code
        display
        fieldType
        isMandatory
      }
    }
  }
  propertiesData: getCodeSystemConcept(id: $codeSystemConceptId) {
    id
    code
    display
    properties {
      code
      fieldType
      valueDate
      valueBoolean
      valueFloat
      valueInteger
      valueString
    }
  }
}
    `;

/**
 * __useFetchDataForEditCodeSystemConceptQuery__
 *
 * To run a query within a React component, call `useFetchDataForEditCodeSystemConceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDataForEditCodeSystemConceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDataForEditCodeSystemConceptQuery({
 *   variables: {
 *      id: // value for 'id'
 *      codeSystemConceptId: // value for 'codeSystemConceptId'
 *   },
 * });
 */
export function useFetchDataForEditCodeSystemConceptQuery(baseOptions: Apollo.QueryHookOptions<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables> & ({ variables: FetchDataForEditCodeSystemConceptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables>(FetchDataForEditCodeSystemConceptDocument, options);
      }
export function useFetchDataForEditCodeSystemConceptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables>(FetchDataForEditCodeSystemConceptDocument, options);
        }
export function useFetchDataForEditCodeSystemConceptSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables>(FetchDataForEditCodeSystemConceptDocument, options);
        }
export type FetchDataForEditCodeSystemConceptQueryHookResult = ReturnType<typeof useFetchDataForEditCodeSystemConceptQuery>;
export type FetchDataForEditCodeSystemConceptLazyQueryHookResult = ReturnType<typeof useFetchDataForEditCodeSystemConceptLazyQuery>;
export type FetchDataForEditCodeSystemConceptSuspenseQueryHookResult = ReturnType<typeof useFetchDataForEditCodeSystemConceptSuspenseQuery>;
export type FetchDataForEditCodeSystemConceptQueryResult = Apollo.QueryResult<FetchDataForEditCodeSystemConceptQuery, FetchDataForEditCodeSystemConceptQueryVariables>;