/* eslint-disable max-statements */
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSadaContext } from "@sada/react";
import React from "react";
import { CircularProgress, styled } from "@toolkit/ui";
import { TSadaInstance, getSadaConfig } from "./util";
import { useLoadAssets } from "./useLoadAssets";
import SadaLogin from "./SadaLogin";
import SadaError from "./SadaError";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "app-root": React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

type SadaAppProps = {
  currentUrl: { url: string };
  appType: "admin" | "provider" | "consumer";
  appSignIn?: () => void;
};

const Content = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
}));

const SadaApp: FC<SadaAppProps> = ({ currentUrl, appType, appSignIn = () => {} }) => {
  const { state } = useSadaContext();
  const { token, refresh_token, oauthError, oauthLoading } = state;
  const authToken = useMemo(() => ({ token, refresh_token }), [token, refresh_token]);
  const { appUrl: baseUrl } = useMemo(() => getSadaConfig(), []);
  const { handleGetAssets, assetsError } = useLoadAssets(baseUrl, !!oauthLoading);

  const [sada, setSada] = useState<TSadaInstance | null>();
  const onReady = useCallback(
    (value: TSadaInstance) => {
      value.init({ appType, appSignIn });
      setSada(value);
    },
    [appType, appSignIn]
  );

  useEffect(() => {
    window["SADA_EMBEDDED_VIEW"] = { onReady };

    return () => {
      window["SADA_EMBEDDED_VIEW"] = {};
    };
  }, [onReady]);

  useEffect(() => {
    if (sada) {
      sada.init({ appType, appSignIn });
    }
  }, [sada, appType, appSignIn]);

  useEffect(() => {
    sada?.onRouteChange(currentUrl.url);
  }, [sada, currentUrl]);

  useEffect(() => {
    if (!authToken?.token || !authToken?.refresh_token) return;
    sada?.onTokenChange({
      token: authToken.token,
      refresh_token: authToken.refresh_token,
    });
  }, [sada, authToken]);

  if (assetsError) {
    return <SadaError title='Oops!' subtitle='Sorry, an unexpected error occurred.' onClick={handleGetAssets} />;
  }

  if (oauthError) {
    return <SadaLogin />;
  }

  return (
    // eslint-disable-next-line react/forbid-dom-props
    <app-root style={{ display: "flex", minHeight: "100%", backgroundColor: "#ffffff", padding: 10, flexDirection: "column" }}>
      <Content>
        <CircularProgress color='primary' size={50} />
      </Content>
    </app-root>
  );
};

export default memo(SadaApp);
