import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { usePlansQuery } from "gql/queries";
import { subscriptionsManagementTitleBreadcrumb } from "pages/SubscriptionsMangment/constants/subscriptions-management-breadcrumbs";
import { subscriptionsManagementPaths } from "pages/SubscriptionsMangment/constants/subscriptions-management-paths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSubscriptionsColumns } from "./components/SubscriptionPlans/useSubscriptionsColumns";

const SubscriptionsPage: FC = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();

  const handleAddNew = () => {
    navigate(subscriptionsManagementPaths.newPath.fullPath);
  };

  const handleEditRow = row => {
    navigate(subscriptionsManagementPaths.editPath.fullPathWithParams(row?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: subscriptionsManagementTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='plans'
      columns={useSubscriptionsColumns()}
      query={usePlansQuery}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: false,
        onEditRow: handleEditRow,
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={handleAddNew} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};

export default SubscriptionsPage;
