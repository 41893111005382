import React, { FC } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, TruncateTypography, Typography } from "@toolkit/ui";
import {
  UserWorkspaceDoctorUpsertModal,
  UserWorkspaceDoctorUpsertModalApi,
} from "../../modals/UserWorkspaceDoctorUpsertModal/UserWorkspaceDoctorUpsertModal";
import { IUserWorkspaceDoctorUpsertFormEvent } from "../../forms/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertForm";
import { useUserWorkspaceDoctorStyle } from "./useUserWorkspaceDoctorStyle";
import { IUserWorkspaceDoctorValue } from "../../types";
import { useDefaultBranchAssignMutation } from "../../gql/mutations";
import { InternalRefetchQueriesInclude } from "@apollo/client";

type BaseWorkspaceDoctorProps = {
  doctorCurrentWorkspace: IUserWorkspaceDoctorValue | null;
  refetchQueries: InternalRefetchQueriesInclude;
  renderBranchStatus?: (branchName: string | null, handleOpen: () => void) => React.ReactNode;
  hasClose?: boolean;
};

export const BaseWorkspaceDoctor: FC<BaseWorkspaceDoctorProps> = props => {
  const { hasClose, doctorCurrentWorkspace, refetchQueries, renderBranchStatus } = props;
  const { t } = useTranslation("provider");

  const { classes, theme } = useUserWorkspaceDoctorStyle();

  const branchName = pickLocalizedValue(doctorCurrentWorkspace?.branch?.name, doctorCurrentWorkspace?.branch?.nameAr);

  const handleOpen = () => {
    UserWorkspaceDoctorUpsertModalApi.open({
      userWorkspaceDoctor: doctorCurrentWorkspace,
    });
  };

  const handleClose = () => {
    UserWorkspaceDoctorUpsertModalApi.close();
  };

  const [assignDefaultBranch, { loading: isSubmitting }] = useDefaultBranchAssignMutation({
    refetchQueries: refetchQueries,
    onCompleted: handleClose,
  });

  const onDefaultBranchCreateModalChange = (event: IUserWorkspaceDoctorUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const { branch } = event.payload.values;

      assignDefaultBranch({
        variables: {
          branchId: branch?.value?.id,
        },
      });
    }
  };

  return (
    <>
      {renderBranchStatus ? (
        renderBranchStatus(branchName ?? null, handleOpen)
      ) : (
        <Box className={classes.wrapper}>
          {!!branchName && <Typography className={classes.message}>{t("Your workspace")}: </Typography>}

          <TruncateTypography
            maxWidth={250}
            lineHeight={1}
            color={theme.palette.primary.main}
            text={branchName || t("Select Your Working Branch")}
            onClick={handleOpen}
          />
        </Box>
      )}
      <UserWorkspaceDoctorUpsertModal
        isLoading={isSubmitting}
        hasClose={!!doctorCurrentWorkspace?.branch && !!hasClose}
        onChange={onDefaultBranchCreateModalChange}
      />
    </>
  );
};
