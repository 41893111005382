import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { UsersEmailsAutocompleteQuery } from "./gql";

export type IUserEmailAutocomplete = ExtractNodeType<UsersEmailsAutocompleteQuery>;

export const userEmailSchema = createZodAutocompleteFromType<IUserEmailAutocomplete>({
  id: z.string(),
  email: z.string().nullish(),
});
