import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";
import { ClaimStatus } from "../../schema/types";

export const claimStatusOptionsMap: IEnumToOptionsMap<ClaimStatus> = {
  [ClaimStatus.Checked]: {
    key: ClaimStatus.Checked,
    get label() {
      return i18n.t("Checked", { ns: "domains" });
    },
    value: ClaimStatus.Checked,
  },
  [ClaimStatus.Open]: {
    key: ClaimStatus.Open,
    get label() {
      return i18n.t("Open", { ns: "domains" });
    },
    value: ClaimStatus.Open,
  },
  [ClaimStatus.Submitted]: {
    key: ClaimStatus.Submitted,
    get label() {
      return i18n.t("Submitted", { ns: "domains" });
    },
    value: ClaimStatus.Submitted,
  },
  [ClaimStatus.Validated]: {
    key: ClaimStatus.Validated,
    get label() {
      return i18n.t("Validated", { ns: "domains" });
    },
    value: ClaimStatus.Validated,
  },
};

export const claimStatusOptions = Object.values(claimStatusOptionsMap);
