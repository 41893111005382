import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { benefitMapsPaths } from "../constants";

export const useSetBenefitMapsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Benefit Maps");

  const benefitMapListBreadcrumb = useMemo(() => {
    return {
      name: t("Benefit Maps"),
      route: benefitMapsPaths.list.fullPath,
    };
  }, [t]);

  const setBenefitMapListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setBenefitMapCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [benefitMapListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, benefitMapListBreadcrumb, t]);

  const setBenefitMapUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [benefitMapListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, benefitMapListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setBenefitMapListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setBenefitMapCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setBenefitMapUpdateContainerBreadcrumb();
    }
  }, [containerType, setBenefitMapListContainerBreadcrumb, setBenefitMapCreateContainerBreadcrumb, setBenefitMapUpdateContainerBreadcrumb]);
};
