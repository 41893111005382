import { useFileUpload } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";

const FileExtNotSupportedText = "File extension is not supported,please upload only xlsx format";
const MaxFileSizeMessage = "Please import a file with less than {{maxSize}} mb";
const maxFileSyzeBytes = 20971520;
const One_MB = 2 ** 20;

type FileValidationError = {
  type: "noFile" | "SizeTooBig" | "invalidExtension";
  message: string;
};

type UploadDialogHook = {
  handleClose: () => void;
  handleResetError?: () => void;
  onFileUploadedSuccessfully: (fileName: string) => void;
};

export const useUploadDialog = (props: UploadDialogHook) => {
  const { handleClose, handleResetError, onFileUploadedSuccessfully: handleOnFileUploadedSuccessfully } = props;
  const { t } = useTranslation("domains");

  // TODO - fix this
  const auth = useAuth();
  const token = auth?.user?.access_token;

  const [customError, setCustomError] = useState("");
  const [dragActive, setDragActive] = React.useState(false);
  const { files, fetchUploadFile, clearAllFiles, removeFile, setFiles, handleDragDropEvent } = useFileUpload({
    token: token!,
  });
  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files?.[0];
    handleFileSelected(selectedFile!, event);
  };
  const handleFileSelected = (selectedFile: File, e: React.ChangeEvent<HTMLInputElement>) => {
    if (!selectedFile) return;
    const errors = getFileValidationErrors(selectedFile);
    if (!isFileValid(errors)) {
      setCustomError(errors[0].message);
      return;
    }
    setCustomError("");
    setFiles(e as any, "a");
  };
  const isFileValid = (fileErrors: FileValidationError[]): boolean => {
    return fileErrors.length == 0;
  };

  const getFileValidationErrors = (file: File): FileValidationError[] => {
    const issues: FileValidationError[] = [];
    if (file?.size > maxFileSyzeBytes) {
      issues.push({
        type: "SizeTooBig",
        message: t(MaxFileSizeMessage, { maxSize: Math.floor(maxFileSyzeBytes / One_MB) }),
      });
    }
    const fileName = file?.name;
    const rE = /(\.xlsx)$/i;
    if (!rE.exec(fileName)) {
      issues.push({
        type: "invalidExtension",
        message: t(FileExtNotSupportedText),
      });
    }

    return issues;
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleDragDropEvent(e);
    const selectedFile = e.dataTransfer.files?.[0]!;
    handleFileSelected(selectedFile, e);
  };

  const handleRemoveFileClick = file => {
    handleResetError?.();
    removeFile(file);
    setCustomError("");
  };

  const handleCloseClick = () => {
    clearAllFiles();
    handleClose();
    setCustomError("");
  };

  const handleUploadClick = () => {
    fetchUploadFile({
      onComplete: fileName => {
        handleOnFileUploadedSuccessfully(fileName);
      },
      onProgress: progress => {
        console.log(progress, "progress");
      },
    });
  };

  return {
    file: files[0],
    dragActive,
    customError,
    handleDrop,
    handleDrag,
    handleClose,
    handleUploadFile,
    handleCloseClick,
    handleUploadClick,
    handleRemoveFileClick,
  };
};
