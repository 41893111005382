import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { MedicalFormsClassificationsAutocompleteQuery } from "./gql";

export type IMedicalFormClassificationAutocomplete = ExtractNodeType<MedicalFormsClassificationsAutocompleteQuery>;

export const medicalFormClassificationSchema = createZodAutocompleteFromType<IMedicalFormClassificationAutocomplete>({
  id: z.string(),
  code: z.string().nullish(),
  name: z.string().nullish(),
  nameAr: z.string().nullish(),
  medicalFormClassifications: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        rangeFrom: z.number().nullable(),
        rangeTo: z.number().nullable(),
      })
    )
    .nullish(),
});
