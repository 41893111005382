import React, { FC, memo, PropsWithChildren, useMemo } from "react";
import { SadaProvider as Provider } from "@sada/react";
import { getSadaConfig } from "./util";

type SadaProviderProps = PropsWithChildren<{
  token?: string;
}>;
const SadaProvider: FC<SadaProviderProps> = ({ token, children }) => {
  const { apiEnv: env, apiUrl: baseUrl } = useMemo(() => getSadaConfig(), []);

  return (
    <Provider baseUrl={baseUrl!} token={token || ""} env={env}>
      {children}
    </Provider>
  );
};

export default memo(SadaProvider);
