import { SubListItemInput } from "@/schema/types";
import { getMaxLengthValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type SublistItemUpsertFormProps = {
  onHandleSubmit: () => void;
  submitButtonText?: string | undefined;
  defaults?: { code: string; display: string };
  isSubmitting?: boolean;
};

export const SublistItemUpsertForm: FC<SublistItemUpsertFormProps> = props => {
  const { onHandleSubmit: handleSubmit, submitButtonText = undefined, defaults = undefined, isSubmitting } = props;

  const { t } = useTranslation("domains");

  const methods = useFormContext<SubListItemInput>();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={2} alignItems='center'>
        <Grid item>
          <TextField
            label={t("Name")}
            defaultValue={defaults?.display}
            {...methods.register("display", {
              required: getRequiredValidation(t, true),
              maxLength: getMaxLengthValidation(t, 500),
            })}
            error={Boolean(methods.formState.errors?.display?.message)}
            helperText={methods.formState.errors?.display?.message}
          />
        </Grid>

        <Grid item>
          <TextField
            label={t("Code")}
            defaultValue={defaults?.code}
            {...methods.register("code", {
              required: getRequiredValidation(t, true),
              maxLength: getMaxLengthValidation(t, 500),
            })}
            error={Boolean(methods.formState.errors?.code?.message)}
            helperText={methods.formState.errors?.code?.message}
          />
        </Grid>

        {Boolean(submitButtonText) && (
          <Grid item>
            <Button onClick={handleSubmit} disabled={isSubmitting}>
              {submitButtonText}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
