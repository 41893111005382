import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  tradeDrugTypeNewBreadcrumb,
  tradeDrugTypesListBreadcrumb,
  tradeDrugTypesTitleBreadcrumb,
} from "../constants/trade-drug-types-breadcrumbs";
import { TradeDrugTypeTrackerForm } from "./components/TradeDrugTypeTrackerForm";
import { useTrackerTradeDrugTypeCreateMutation } from "../gql";
import { tradeDrugTypesPaths } from "../constants/trade-drug-types-paths";

export const TradeDrugTypeTrackerNewPage: FC = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();

  const [tradeDrugTypeCreate, { loading: isSubmitting }] = useTrackerTradeDrugTypeCreateMutation({
    onCompleted: response => {
      const id = response?.trackerTradeDrugTypeCreate?.id;
      if (id) {
        succeeded(t("Trade Drug Type Created Successfully"));
        navigate(tradeDrugTypesPaths.listPath.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      const message = formatGraphQLError(graphQLErrors);
      failed(message);
    },
  });

  const handleSubmit = data => {
    tradeDrugTypeCreate({
      variables: {
        input: {
          ...data,
        },
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: tradeDrugTypesTitleBreadcrumb(t),
      values: [tradeDrugTypesListBreadcrumb(t), tradeDrugTypeNewBreadcrumb(t)],
    });
  }, []);

  return <TradeDrugTypeTrackerForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />;
};
