import { formGirdSpacing, hasPermission, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, CustomToggleButton, FormCard, Grid, Typography, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useDeliveryTimeSlotsQuery, useUpdateDeliveryTimeSlotStatusMutation } from "../gql";
import { PermissionEnum } from "@/schema/types";
import { useStyle } from "./DeliveryTimeSlotsStyles";

export const DeliveryTimeSlotsSettings: FC = () => {
  const { t } = useTranslation("admin");
  const { failed, succeeded } = useAddToast();
  const [updatingId, setUpdatingId] = useState<string | null>(null);
  const { classes } = useStyle();
  const { handleToggle, handleOpen, open } = useOpenState();

  const { data, loading } = useDeliveryTimeSlotsQuery({
    variables: {
      first: 100,
    },
    skip: !open,
  });
  const deliveryTimeSlots = data?.deliveryTimeSlots?.edges?.map(item => item?.node) || [];

  const [updateDeliveryTimeSlotStatusMutation] = useUpdateDeliveryTimeSlotStatusMutation({
    onCompleted: data => {
      if (data?.updateDeliveryTimeSlotStatus?.id) {
        succeeded(t("Delivery Time Slot updated successfully"));
      } else {
        failed(t("Delivery Time Slot update failed"));
      }
      setUpdatingId(null);
    },
    onError: () => {
      failed(t("Delivery Time Slot update failed"));
      setUpdatingId(null);
    },
  });
  const handleChangeDeliveryTimeSlotStatus = (id: string, isActive: boolean) => {
    setUpdatingId(id);
    updateDeliveryTimeSlotStatusMutation({
      variables: {
        input: {
          id,
          isActive: !isActive,
        },
      },
    });
  };

  return (
    <Grid item xs={12}>
      <FormCard title={t("Delivery Time Slots Settings")} loading={loading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <CustomDialog
            type='base'
            open={open}
            DialogTitleProps={{
              title: t("Manage Delivery Time Slots"),
              onClose: handleToggle,
            }}
            button={
              <Button onClick={handleOpen} disabled={!hasPermission(PermissionEnum.ManageDeliveryTimeSlots)} sx={{ marginLeft: 2 }}>
                {t("Manage Delivery Time Slots")}
              </Button>
            }
            DialogActionsProps={{
              children: (
                <Button variant='outlined' onClick={handleToggle}>
                  {t("Close")}
                </Button>
              ),
            }}
          >
            <Typography variant='h6' sx={{ marginBottom: 2 }}>
              {t("Note changes will be saved automatically")}
            </Typography>
            <Grid container spacing={2} justifyContent='flex-start'>
              {deliveryTimeSlots?.map(item => {
                return (
                  <Grid item xs={6} sm={4} md={3} key={item?.id}>
                    <Box className={classes.box}>
                      <Typography fontWeight='bold'>
                        {item?.startTime} - {item?.endTime}
                      </Typography>
                      <CustomToggleButton
                        checked={item?.isActive}
                        onChange={() => handleChangeDeliveryTimeSlotStatus(item?.id!, item?.isActive)}
                        disabled={updatingId === item?.id}
                        isLoading={updatingId === item?.id}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </CustomDialog>
        </Grid>
      </FormCard>
    </Grid>
  );
};
