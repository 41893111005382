import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionCreateMutationVariables = Types.Exact<{
  decision: Types.DecisionInput;
}>;


export type DecisionCreateMutation = { __typename?: 'Mutation', addDecision?: { __typename?: 'Decision', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const DecisionCreateDocument = gql`
    mutation DecisionCreate($decision: DecisionInput!) {
  addDecision(decision: $decision) {
    errors {
      field
      message
    }
  }
}
    `;
export type DecisionCreateMutationFn = Apollo.MutationFunction<DecisionCreateMutation, DecisionCreateMutationVariables>;

/**
 * __useDecisionCreateMutation__
 *
 * To run a mutation, you first call `useDecisionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDecisionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [decisionCreateMutation, { data, loading, error }] = useDecisionCreateMutation({
 *   variables: {
 *      decision: // value for 'decision'
 *   },
 * });
 */
export function useDecisionCreateMutation(baseOptions?: Apollo.MutationHookOptions<DecisionCreateMutation, DecisionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DecisionCreateMutation, DecisionCreateMutationVariables>(DecisionCreateDocument, options);
      }
export type DecisionCreateMutationHookResult = ReturnType<typeof useDecisionCreateMutation>;
export type DecisionCreateMutationResult = Apollo.MutationResult<DecisionCreateMutation>;
export type DecisionCreateMutationOptions = Apollo.BaseMutationOptions<DecisionCreateMutation, DecisionCreateMutationVariables>;