import { makeStyles } from "tss-react/mui";

export const useOptimaEditCardStyle = makeStyles()(theme => ({
  root: {
    padding: 2,
    textAlign: "start",
  },
  wrapper: {
    display: "flex",
    gap: 6,
    rowGap: 16,
    paddingInline: 8,
    paddingBlock: 16,
    borderRadius: 15,
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
  },
  display: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 4,
  },
  category: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 4,
  },
  code: {
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
    marginBottom: 2,
  },
  message: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    textAlign: "start",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
    width: "100%",
  },
  fixButton: {
    marginLeft: 0,
    borderRadius: 30,
    minWidth: 100,
  },
  seeEvidenceButton: {
    borderRadius: 30,
  },
  likeDislikeButtonsWrapper: {
    display: "flex",
    marginInline: "auto",
    alignSelf: "flex-end",
    marginInlineEnd: 5,
  },
}));
