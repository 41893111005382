import { Branch } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormDaysTimesRanges, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { AddressUpsertForm } from "@/pages/Addresses";
import { convertBranchToFormValues } from "../../others";
import { BranchLicenseIdForm } from "../BranchLicenseId/BranchLicenseIdForm";
import { BranchInformationForm } from "../BranchInformation/BranchInformationForm";
import { branchUpsertFormSchema, branchUpsertFormDefaultValues, IBranchUpsertForm } from "./BranchUpsertFormSchema";

type BranchUpsertFormProps = {
  branch?: Branch;
  buttonLabel: string;
  isAdmin?: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (values: IBranchUpsertForm) => void;
};

export const BranchUpsertForm: FC<BranchUpsertFormProps> = props => {
  const { buttonLabel, branch, isAdmin, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IBranchUpsertForm>({
    defaultValues: branchUpsertFormDefaultValues,
    schema: branchUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IBranchUpsertForm) => {
    onChange(values);
  };

  useEffect(() => {
    if (branch) {
      const _branch = convertBranchToFormValues(branch);
      setValues(_branch);
    }
  }, [branch, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            {isAdmin && !branch && (
              <Grid item xs={12}>
                <FormCard title={t("Search By License ID")} loading={isLoading} doYouHaveData>
                  <BranchLicenseIdForm />
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Branch Information")} loading={isLoading} doYouHaveData>
                <BranchInformationForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Branch Working Hours")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FormDaysTimesRanges name={"workingHours"} startTimeLabel={t("Open Time")} endTimeLabel={t("Close Time")} />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Branch Address")} loading={isLoading} doYouHaveData>
                <AddressUpsertForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
