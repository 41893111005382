import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthProgramNetworkFilterInput>;
}>;


export type HealthProgramNetworkGetQuery = { __typename?: 'Query', healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null, categoryCode?: string | null, policyNumber?: string | null, isActive?: boolean | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } }> } | null };


export const HealthProgramNetworkGetDocument = gql`
    query HealthProgramNetworkGet($filter: HealthProgramNetworkFilterInput) {
  healthProgramNetworks(first: 1, filter: $filter) {
    edges {
      node {
        id
        name
        categoryCode
        policyNumber
        isActive
        payer {
          id
          name
          nameAr
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkGetQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramNetworkGetQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>(HealthProgramNetworkGetDocument, options);
      }
export function useHealthProgramNetworkGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>(HealthProgramNetworkGetDocument, options);
        }
export function useHealthProgramNetworkGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>(HealthProgramNetworkGetDocument, options);
        }
export type HealthProgramNetworkGetQueryHookResult = ReturnType<typeof useHealthProgramNetworkGetQuery>;
export type HealthProgramNetworkGetLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkGetLazyQuery>;
export type HealthProgramNetworkGetSuspenseQueryHookResult = ReturnType<typeof useHealthProgramNetworkGetSuspenseQuery>;
export type HealthProgramNetworkGetQueryResult = Apollo.QueryResult<HealthProgramNetworkGetQuery, HealthProgramNetworkGetQueryVariables>;