import { FC } from "react";
import { ISurveyListNode } from "../../types";
import { Box, Button, useTheme } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { surveysPaths } from "../../constants";
import { getLastPublishedCriteria } from "../../others";

type SurveyAnsweredParticipantsCountProps = {
  survey: ISurveyListNode;
};
export const SurveyAnsweredParticipantsCount: FC<SurveyAnsweredParticipantsCountProps> = ({ survey }) => {
  const lastPublishedCriteria = getLastPublishedCriteria(survey?.publishCriteria);
  const theme = useTheme();
  const navigate = useNavigate();
  const handleShowStatistics = () => {
    survey.id && navigate(surveysPaths.details.fullPathWithParams({ surveyId: survey.id }));
  };

  return (
    <Box display='flex' justifyContent='center' width='100%'>
      {lastPublishedCriteria?.participantsCount ? (
        <Button
          variant='text'
          onClick={handleShowStatistics}
          color='secondary'
          sx={{
            textDecoration: "underline",
            fontWeight: theme.mixins.fonts.fontWeight.medium,
          }}
        >
          {survey?.answeredParticipantsCount}
        </Button>
      ) : (
        "-"
      )}
    </Box>
  );
};
