import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { useBlockCreateMutation } from "../../gql/mutations";
import { blocksPaths } from "../../constants/BlocksPaths";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useSetBlocksBreadcrumbs } from "../../hooks";
import { BlockUpsertForm, IBlockUpsertFormEvent } from "../../forms/BlockUpsert/BlockUpsertForm";
import { convertBlockFormValuesToBackEndValues } from "../../others";

export const BlockCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [blockCreateMutation, { loading }] = useBlockCreateMutation({
    onCompleted: data => {
      if (data?.blockCreate?.blockErrors?.length! > 0) {
        const error = t(formatMessageErrors(data?.blockCreate?.blockErrors));
        failed(error);
      } else {
        succeeded(t("Block created successfully"));
        navigate(blocksPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const onBlockUpsertFormChange = (event: IBlockUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const input = convertBlockFormValuesToBackEndValues(event.payload.values);

      blockCreateMutation({
        variables: {
          input,
        },
      });
    }
  };
  useSetBlocksBreadcrumbs("CREATE");
  return <BlockUpsertForm buttonLabel={t("Create")} isLoading={loading} onChange={onBlockUpsertFormChange} />;
};
