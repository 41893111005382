import { ProcedureAgeSortingField, SortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { procedureAgesPaths } from "../../constants/ProcedureAgePaths";
import { useProcedureAgeDeleteMutation } from "../../gql/mutations/__generated__";
import { useProcedureAgeListQuery } from "../../gql/queries/__generated__";
import { useSetProcedureAgesBreadcrumbs } from "../../hooks/useSetInsuranceBenefitApprovalLimitsBreadcrumbs";
import { IProcedureAgeListNode } from "../../types";
import { useProcedureAgeListContainerColumns } from "./ProcedureAgeListContainerColumns";

export const ProcedureAgeListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(procedureAgesPaths.create.fullPath);
  };

  const onEditRowClick = (item: IProcedureAgeListNode) => {
    navigate(procedureAgesPaths.update.fullPathWithParams({ procedureAgeId: item?.id }));
  };

  useSetProcedureAgesBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"procedureAgeList"}
      query={useProcedureAgeListQuery}
      columns={useProcedureAgeListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: ProcedureAgeSortingField.ProcedureCode,
          direction: SortDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "ProcedureAge",
        name: "Procedure Age",
        useDeleteMutation: useProcedureAgeDeleteMutation,
        idPropName: "deleteProcedureAgeId",
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
