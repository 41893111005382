import { useTranslation } from "@toolkit/i18n";
import {
  BulkMessageSearchCriteriaUpsertForm,
  BulkMessageSearchCriteriaUpsertFormRef,
  IBulkMessageSearchCriteriaUpsertFormEvent,
} from "../../forms/BulkMessagesSearchCriteria/BulkMessagesSearchCriteriaForm";
import { ModalApi } from "../../others/types";
import { useOpenState } from "@toolkit/core";
import { FC, useEffect, useRef, useState } from "react";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { IBulkMessageSenderFormValues } from "../../forms/BulkMessageSender/BulkMessageSenderFormSchema";
import { IBulkMessageSearchCriteriaFormValues } from "../../forms/BulkMessagesSearchCriteria/BulkMessagesSearchCriteriaFormSchema";

export const BulkMessageSearchCriteriaModalApi: ModalApi<IBulkMessageSenderFormValues> = {
  open: () => {},
  close: () => {},
};

type BulkMessageSearchCriteriaModalProps = {
  isLoading: boolean;
  hasClose: boolean;
  onFormValuesSubmit: (values: IBulkMessageSenderFormValues & IBulkMessageSearchCriteriaFormValues) => void;
};

export const BulkMessageSearchCriteriaModal: FC<BulkMessageSearchCriteriaModalProps> = ({ isLoading, hasClose, onFormValuesSubmit }) => {
  const { t } = useTranslation("admin");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [data, setData] = useState<IBulkMessageSenderFormValues | null>(null);

  const formRef = useRef<BulkMessageSearchCriteriaUpsertFormRef>(null);

  const handleSubmit = () => formRef.current?.submit();

  const onOpen = (data?: IBulkMessageSenderFormValues) => {
    setData(data || null);
    handleOpen();
  };

  const onChange = (event: IBulkMessageSearchCriteriaUpsertFormEvent) => {
    if (data) {
      onFormValuesSubmit({ ...data, ...event.payload.values });
    }
  };

  useEffect(() => {
    BulkMessageSearchCriteriaModalApi.open = onOpen;
    BulkMessageSearchCriteriaModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type='base'
      maxWidth='sm'
      open={isOpen}
      onClose={hasClose ? handleClose : undefined}
      DialogTitleProps={{
        title: t("Publish Bulk Message by Search Criteria"),
        hasCloseButton: hasClose,
      }}
      DialogActionsProps={{
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null} onClick={handleSubmit}>
            {t("Publish")}
          </Button>
        ),
      }}
    >
      <BulkMessageSearchCriteriaUpsertForm ref={formRef} onChange={onChange} />
    </CustomDialog>
  );
};
