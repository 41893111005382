import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { HealthParameterFilterInput } from "../../schema/types";
import { useHealthParametersAutocompleteQuery } from "./gql";

type HealthParametersAutocompleteProps = IAutocompleteProps<HealthParameterFilterInput>;

export const HealthParametersAutocomplete: FC<HealthParametersAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useHealthParametersAutocompleteQuery,
    variables: { filter },
    searchKey: pickLocalizedValue("display", "arabicDisplay"),
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "code", item => pickLocalizedValue(item?.display!, item?.arabicDisplay!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Health Parameters") : t("Health Parameter"))}
    />
  );
};
