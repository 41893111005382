/* eslint-disable sonarjs/cognitive-complexity */
import { CodeSystemCode, ProcessingStatus, SearchParameter, Type } from "@/schema/types";
import { formatUserNameFull, toSentenceCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  adminManageListBreadcrumb,
  adminManageListFieldBreadcrumb,
  adminManageListsTitleBreadcrumb,
  adminManagedListsPaths,
} from "../constants";
import { useGetCodeSystemQuery } from "../gql";
import { useMapConceptDefinitionsToDataGridColumns } from "./useMapConceptDefinitionsToDataGridColumns";
import { getFilterValuePropertyName } from "./utils";

export const useAdminManagedListDetailsPage = () => {
  const navigate = useNavigate();
  const { code, id: _id } = useParams();
  const { t } = useTranslation("domains");
  const id = _id == "0" ? null : _id;

  const { setBreadCrumb } = useBreadCrumbs();

  const { data: getCodeSystemResponse, refetch: refetchCodeSystem } = useGetCodeSystemQuery({
    variables: {
      id: id as string,
    },
    skip: !id || id === "new",
  });

  const { processingStatus, codeSystemDefinition, codeSystemHistory, version: versionNumber } = getCodeSystemResponse?.getCodeSystem || {};

  const assignedReviewerData = codeSystemHistory?.find(item => item?.processingStatus === ProcessingStatus.Approved);

  const reviewerUserName = formatUserNameFull(codeSystemDefinition?.reviewerUserId!) || formatUserNameFull(assignedReviewerData?.user!);

  const { codeSystemConceptFields, isEditable, editorUserId: editorUser, reviewerUserId: reviewerUser } = codeSystemDefinition! || {};

  const columnsDefinition = useMapConceptDefinitionsToDataGridColumns(codeSystemConceptFields);

  const handleEditRow = (rowData: any) => {
    navigate(adminManagedListsPaths.detailsEditPath.fullPathWithParams(code!, id!, rowData?.id));
  };

  function getSearchParameters(data?: Record<string, any>) {
    if (!data) return;
    const filterData = {};
    Object.keys(data).forEach(item => {
      if (data[item]) {
        filterData[item] = data[item];
      }
    });

    const getFilterPropertyType = (item: string) => {
      return codeSystemConceptFields?.find(column => column?.code == item)?.fieldType;
    };
    return Object.keys(filterData).map(
      item =>
        ({
          code: item,
          type: getFilterPropertyType(item),
          [getFilterValuePropertyName(getFilterPropertyType(item))]:
            getFilterPropertyType(item) == Type.Boolean ? filterData[item]?.toLowerCase() == "yes" : filterData[item],
        } as SearchParameter)
    );
  }

  useEffect(() => {
    if (code) {
      setBreadCrumb({
        title: adminManageListsTitleBreadcrumb(t),
        values: [
          adminManageListBreadcrumb(t),
          adminManageListFieldBreadcrumb(versionNumber ? `${toSentenceCase(code)} (v${versionNumber})` : toSentenceCase(code)),
        ],
      });
    }
  }, [code, versionNumber]);

  return {
    id,
    code: code as CodeSystemCode,
    isNew: id === "new",
    isEditable: isEditable,
    versionNumber,
    versionParamId: id,
    columnsDefinition: columnsDefinition || [],
    editorUser,
    reviewerUser,
    processingStatus,
    reviewerUserName,
    t,
    getSearchParameters,
    refetchCodeSystem,
    handleEditRow,
  };
};
