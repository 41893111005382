import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";

export const patientMessageSendFormSchema = z.object({
  title: z.string().max(30).nullish(),
  titleAr: z.string().max(30).nullish(),
  method: zodEnumSchema.sendBulkMessagesMethod,
  message: z.string().min(3).max(255),
  messageAr: z.string().min(3).max(255),
});

export type IPatientMessageSendForm = z.infer<typeof patientMessageSendFormSchema>;

export const patientMessageSendFormDefaultValues: Partial<IPatientMessageSendForm> = {
  title: undefined,
  titleAr: undefined,
  message: undefined,
  messageAr: undefined,
};
