import { makeStyles } from "../../base/mui";

type CustomLinearProgressStyleProps = {
  color?: string;
  height: number;
  borderRadius: number;
};

export const useCustomLinearProgressStyle = makeStyles<CustomLinearProgressStyleProps>()((_, props) => ({
  root: {
    height: props.height,
    borderRadius: props.borderRadius,
    "& .MuiLinearProgress-bar": {
      borderRadius: props.borderRadius,
      backgroundColor: props.color,
    },
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: props.color ? `${props.color}30` : undefined,
    },
  },
}));
