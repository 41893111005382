import { IOptimaActionUpdateContainerEvent, OptimaActionUpdateContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";

export const AdminOptimaActionUpdateContainer = () => {
  const navigate = useNavigate();

  const onOptimaActionUpdateContainerChange = (event: IOptimaActionUpdateContainerEvent) => {
    if (event.type === "SUBMIT_SUCCESS") {
      navigate(-1);
    }
  };

  return <OptimaActionUpdateContainer onChange={onOptimaActionUpdateContainerChange} />;
};
