import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceProductCategoriesAutocompleteQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceCategoryFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceDefaultSortingInput>;
}>;


export type MarketplaceProductCategoriesAutocompleteQuery = { __typename?: 'Query', marketplaceProductCategories?: { __typename?: 'MarketplaceProductCategoryConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceProductCategoryEdge', node: { __typename?: 'MarketplaceProductCategory', id: string, name?: string | null, nameAr?: string | null } }> } | null };


export const MarketplaceProductCategoriesAutocompleteDocument = gql`
    query MarketplaceProductCategoriesAutocomplete($after: String, $filter: MarketplaceCategoryFilterInput, $before: String, $first: Int, $last: Int, $sortBy: MarketplaceDefaultSortingInput) {
  marketplaceProductCategories(
    after: $after
    filter: $filter
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useMarketplaceProductCategoriesAutocompleteQuery__
 *
 * To run a query within a React component, call `useMarketplaceProductCategoriesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceProductCategoriesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceProductCategoriesAutocompleteQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplaceProductCategoriesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>(MarketplaceProductCategoriesAutocompleteDocument, options);
      }
export function useMarketplaceProductCategoriesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>(MarketplaceProductCategoriesAutocompleteDocument, options);
        }
export function useMarketplaceProductCategoriesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>(MarketplaceProductCategoriesAutocompleteDocument, options);
        }
export type MarketplaceProductCategoriesAutocompleteQueryHookResult = ReturnType<typeof useMarketplaceProductCategoriesAutocompleteQuery>;
export type MarketplaceProductCategoriesAutocompleteLazyQueryHookResult = ReturnType<typeof useMarketplaceProductCategoriesAutocompleteLazyQuery>;
export type MarketplaceProductCategoriesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useMarketplaceProductCategoriesAutocompleteSuspenseQuery>;
export type MarketplaceProductCategoriesAutocompleteQueryResult = Apollo.QueryResult<MarketplaceProductCategoriesAutocompleteQuery, MarketplaceProductCategoriesAutocompleteQueryVariables>;