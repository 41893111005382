import { i18n } from "@toolkit/i18n";
import { TransactionSource } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const transactionSourceTypeOptionsMap: IEnumToOptionsMap<TransactionSource> = {
  [TransactionSource.Eclaim]: {
    key: TransactionSource.Eclaim,
    get label() {
      return i18n.t("eClaimlink", { ns: "domains" });
    },
    value: TransactionSource.Eclaim,
  },
  [TransactionSource.Riayati]: {
    key: TransactionSource.Riayati,
    get label() {
      return i18n.t("Riayati", { ns: "domains" });
    },
    value: TransactionSource.Riayati,
  },
};

export const transactionSourceTypeOptions = Object.values(transactionSourceTypeOptionsMap);
