import { CodeSystem, CodeSystemCode, Maybe } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { domainsConfig } from "../../../../../domainsConfig";
import { adminManagedListsPaths } from "../../../constants";
import { useDownloadCodeSystemFileLazyQuery, useGetCodeSystemVersionsLazyQuery, usePublishCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const useVersionsListHooks = () => {
  const { t } = useTranslation("domains");
  const navigate = useNavigate();
  const { code, id: versionParamId, refetchCodeSystem } = useAdminManagedListDetailsPageContext();
  const [isVersionsOpened, setIsVersionsOpened] = useState(false);
  const { succeeded, failed } = useAddToast();
  const [
    fetchGetVersions,
    { data: versionResponseData, loading: versionDataLoading, networkStatus, fetchMore: fetchMoreVersions, refetch: refetchVersions },
  ] = useGetCodeSystemVersionsLazyQuery();

  const [publishCodeSystem, { loading: isPublishing }] = usePublishCodeSystemMutation({
    onCompleted: data => {
      const message = data?.publishCodeSystem?.errors;
      if (message?.length) {
        failed(message[0]?.message as string);
      } else {
        succeeded(t("Data published successfully"));
      }
    },
    onError: error => {
      failed(t(error?.message));
    },
  });

  const [getDownloadCodeSystemFile] = useDownloadCodeSystemFileLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data?.downloadCodeSystemFile) {
        const baseURL = domainsConfig.get("serverUrl");
        window.open(baseURL + data.downloadCodeSystemFile, "_self");
      }
    },
  });

  const versionData = versionResponseData?.getCodeSystems?.edges?.map(item => item?.node) as CodeSystem[];
  const hasNextPageVersions = versionResponseData?.getCodeSystems?.pageInfo?.hasNextPage;
  const latestApprovedVersionNumber = versionData?.find(item => item?.processingStatus === "APPROVED")?.version;

  const handleOnFetchMoreVersions = () => {
    fetchMoreVersions({
      variables: {
        first: 10,
        after: versionResponseData?.getCodeSystems?.pageInfo?.endCursor,
      },
    });
  };

  const handleChangeActiveVersion = (versionId?: Maybe<string>) => {
    versionId && onVersionActiveChange(versionId);
    setIsVersionsOpened(false);
  };

  const handleVersionDialogOpen = () => {
    fetchGetVersions({
      variables: {
        first: 10,
        code: code as CodeSystemCode,
      },
    });
  };
  const onVersionActiveChange = (id: string) => {
    publishCodeSystem({
      variables: {
        codeSystemId: id,
      },
    }).then(() => {
      refetchCodeSystem?.();
      refetchVersions();
    });
  };

  const handleDownloadFileFromVersion = (fileId?: string) => {
    getDownloadCodeSystemFile({
      variables: {
        code: code as CodeSystemCode,
        codeSystemId: fileId,
      },
    });
  };

  const handleToggleVersions = () => {
    if (!isVersionsOpened) {
      handleVersionDialogOpen();
    }
    setIsVersionsOpened(!isVersionsOpened);
  };

  const handleOpenVersionClick = (id: string, versionNumber) => {
    navigate(`${adminManagedListsPaths.detailsPath.fullPathWithParams(code!, id)}?${versionNumber ? "v=" + versionNumber : ""}`);
    setIsVersionsOpened(false);
  };

  return {
    versionData,
    isPublishing,
    versionParamId,
    isVersionsOpened,
    versionDataLoading,
    hasNextPageVersions,
    latestApprovedVersionNumber,
    isLoadingVersions: networkStatus === NetworkStatus.loading,
    isRefetchingVersions: networkStatus === NetworkStatus.refetch,
    isFetchingMoreVersions: networkStatus === NetworkStatus.fetchMore,
    t,
    handleToggleVersions,
    handleChangeActiveVersion,
    handleOnFetchMoreVersions,
    handleDownloadFileFromVersion,
    handleVersionDialogOpen,
    handleOpenVersionClick,
  };
};
