import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetClinicianCredentialsMutationVariables = Types.Exact<{
  clinicianCredentials: Types.ClinicianCredentialsInput;
  destinationType: Types.DestinationType;
}>;


export type SetClinicianCredentialsMutation = { __typename?: 'Mutation', setClinicianCredentials?: { __typename?: 'ClinicianCredentials', userName?: string | null, password?: string | null } | null };


export const SetClinicianCredentialsDocument = gql`
    mutation SetClinicianCredentials($clinicianCredentials: ClinicianCredentialsInput!, $destinationType: DestinationType!) {
  setClinicianCredentials(
    clinicianCredentials: $clinicianCredentials
    destinationType: $destinationType
  ) {
    userName
    password
  }
}
    `;
export type SetClinicianCredentialsMutationFn = Apollo.MutationFunction<SetClinicianCredentialsMutation, SetClinicianCredentialsMutationVariables>;

/**
 * __useSetClinicianCredentialsMutation__
 *
 * To run a mutation, you first call `useSetClinicianCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClinicianCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClinicianCredentialsMutation, { data, loading, error }] = useSetClinicianCredentialsMutation({
 *   variables: {
 *      clinicianCredentials: // value for 'clinicianCredentials'
 *      destinationType: // value for 'destinationType'
 *   },
 * });
 */
export function useSetClinicianCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<SetClinicianCredentialsMutation, SetClinicianCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClinicianCredentialsMutation, SetClinicianCredentialsMutationVariables>(SetClinicianCredentialsDocument, options);
      }
export type SetClinicianCredentialsMutationHookResult = ReturnType<typeof useSetClinicianCredentialsMutation>;
export type SetClinicianCredentialsMutationResult = Apollo.MutationResult<SetClinicianCredentialsMutation>;
export type SetClinicianCredentialsMutationOptions = Apollo.BaseMutationOptions<SetClinicianCredentialsMutation, SetClinicianCredentialsMutationVariables>;