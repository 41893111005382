import React from "react";
import { Grid, IconButton, CloseIcon, ClickAwayListener, CopyIcon, EditIcon, CompleteIcon } from "@toolkit/ui";
import { useQuestionFormStyles } from "./useQuestionForm.styles";
import { useFormContext } from "react-hook-form";
import { ISurveyQuestionOptionManageStateType } from "../../types";
import { ISurveyUpsertFormValues } from "../SurveyUpsert/surveyUpsertFormSchema";
import { QuestionType } from "@/schema/types";
import EditSurveyQuestionOption, { EditSurveyQuestionOptionRef } from "../EditSurveyQuestionOption/EditSurveyQuestionOption";

interface SurveyQuestionOptionFormProps {
  optionIndex: number;
  questionIndex: number;
  canManageSurvey: boolean;
  questionOptionsManageState?: ISurveyQuestionOptionManageStateType | null;
  onRemoveOption: () => void;
  onCopyOption: (optionIndex: number) => void;
  isOptionHovered: boolean;
  onEditButtonClick: (optionIndex?: number) => void;
  questionOptionsType?: QuestionType;
  handleValueChange: (value: string) => void;
}

export const SurveyQuestionOptionForm: React.FC<SurveyQuestionOptionFormProps> = ({
  optionIndex,
  questionOptionsManageState,
  questionIndex,
  canManageSurvey,
  questionOptionsType,
  isOptionHovered,
  onRemoveOption,
  onEditButtonClick,
  onCopyOption,
  handleValueChange,
}) => {
  const { classes, theme } = useQuestionFormStyles();
  const isEditing = optionIndex === questionOptionsManageState?.selectedOptionForEditQuestionIndex;
  const { watch } = useFormContext<ISurveyUpsertFormValues>();
  const questionOptionValue = watch(`questions.${questionIndex}.options.${optionIndex}.value`);
  const allOptions = watch(`questions.${questionIndex}.options`);
  const editFormRef = React.useRef<EditSurveyQuestionOptionRef>(null);

  const handleSubmitEditForm = () => {
    if (editFormRef.current) {
      editFormRef.current.submit();
    }
  };

  const handleSelectOptionForEdit = () => {
    onEditButtonClick(optionIndex);
  };

  const handleCloseEditing = () => {
    onEditButtonClick(undefined);
  };

  const handleClickAway = () => {
    if (isEditing) {
      handleCloseEditing();
    }
  };

  const handleOnSubmit = (value: string) => {
    handleValueChange(value);
    handleCloseEditing();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid
        item
        paddingX={2}
        xs={12}
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{ background: isOptionHovered ? theme.palette.gray.light : undefined }}
      >
        <Grid item xs container alignItems='center'>
          <EditSurveyQuestionOption
            ref={editFormRef}
            allOptions={allOptions}
            defaultValue={questionOptionValue}
            type={questionOptionsType}
            isEditing={isEditing && canManageSurvey}
            onSubmit={handleOnSubmit}
            optionIndex={optionIndex}
          />
        </Grid>
        <Grid item sx={{ visibility: (isOptionHovered || isEditing) && canManageSurvey ? "visible" : "hidden" }}>
          {isEditing ? (
            <>
              <IconButton disabled={false} size='small' color='primary' onClick={handleSubmitEditForm}>
                <CompleteIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
              <IconButton size='small' color='primary' onClick={handleCloseEditing}>
                <CloseIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton size='small' color='primary' onClick={handleSelectOptionForEdit}>
                <EditIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
              <IconButton size='small' color='primary' onClick={() => onCopyOption(optionIndex)}>
                <CopyIcon fontSize='small' className={classes.icon} />
              </IconButton>
              <IconButton size='small' color='primary' onClick={onRemoveOption}>
                <CloseIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export default SurveyQuestionOptionForm;
