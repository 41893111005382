import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberListFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramMemberListSortingInput>;
}>;


export type HealthProgramMemberListListQuery = { __typename?: 'Query', healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberListCountableEdge', node: { __typename?: 'HealthProgramMemberList', id: string, name?: string | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } }> } | null };


export const HealthProgramMemberListListDocument = gql`
    query HealthProgramMemberListList($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramMemberListFilterInput, $sortBy: HealthProgramMemberListSortingInput) {
  healthProgramMemberLists(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        payer {
          id
          name
          nameAr
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMemberListListQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberListListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberListListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramMemberListListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>(HealthProgramMemberListListDocument, options);
      }
export function useHealthProgramMemberListListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>(HealthProgramMemberListListDocument, options);
        }
export function useHealthProgramMemberListListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>(HealthProgramMemberListListDocument, options);
        }
export type HealthProgramMemberListListQueryHookResult = ReturnType<typeof useHealthProgramMemberListListQuery>;
export type HealthProgramMemberListListLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberListListLazyQuery>;
export type HealthProgramMemberListListSuspenseQueryHookResult = ReturnType<typeof useHealthProgramMemberListListSuspenseQuery>;
export type HealthProgramMemberListListQueryResult = Apollo.QueryResult<HealthProgramMemberListListQuery, HealthProgramMemberListListQueryVariables>;