/* eslint-disable sonarjs/cognitive-complexity */
import { ApolloQueryResult, DefaultContext, NetworkStatus, OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { ExtractFilter, ExtractNodeType, ResponseDataAccessor } from "../types";
import { get, set } from "lodash";

type NestedKeyOf<ObjectType> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object | null | undefined
    ? `${Key}` | `${Key}.${NestedKeyOf<NonNullable<ObjectType[Key]>>}`
    : Key;
}[keyof ObjectType & (string | number)];

export type IRelayStyleConnectionProps<T, V extends OperationVariables> = {
  useQuery: (baseOptions?: QueryHookOptions<T, V>) => QueryResult<T, V>;
  variables?: V;
  searchKey?: keyof ExtractFilter<V>;
  skip?: boolean;
  context?: DefaultContext;
  accessor?: NestedKeyOf<T>;
};

export type IRelayStyleConnectionReturn<T, V extends OperationVariables> = {
  data: ExtractNodeType<T>[];
  loading: boolean;
  hasMore: boolean;
  refetch: (variables?: Partial<V>) => Promise<ApolloQueryResult<T>>;
  handleFetchMore: () => void;
  handleSearch: (value: string) => void;
  networkStatus: NetworkStatus;
};

export const useRelayStyleConnection = <T, V extends OperationVariables>(
  props: IRelayStyleConnectionProps<T, V>
): IRelayStyleConnectionReturn<T, V> => {
  const { useQuery, variables, searchKey, skip, context, accessor } = props;
  const [search, setSearch] = useState<string | undefined>(undefined);

  const memoizedVariables = useMemo(() => {
    const localVariables = { first: 10, ...variables! };

    if ("filter" in localVariables) {
      return { ...localVariables!, filter: { ...localVariables?.filter, [searchKey!]: search } };
    }

    return { ...localVariables, [searchKey!]: search };
  }, [variables, searchKey, search]);

  const {
    data: queryData,
    loading: isQueryLoading,
    networkStatus,
    refetch,
    fetchMore,
  } = useQuery({
    variables: memoizedVariables,
    skip: skip || !memoizedVariables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context,
  });

  const responseDataAccessorKey = queryData ? (Object.keys(queryData)[0] as keyof T) : undefined;
  const dataPath = accessor || responseDataAccessorKey;

  const responseDataAccessor = dataPath ? (get(queryData, dataPath) as ResponseDataAccessor<T>) : undefined;

  const data = responseDataAccessor?.edges?.map(item => item?.node) || [];
  const hasMore = !!responseDataAccessor?.pageInfo?.hasNextPage;
  const loading = isQueryLoading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;

  const handleFetchMore = useCallback(() => {
    if (hasMore && !isQueryLoading && dataPath) {
      fetchMore({
        variables: {
          ...variables,
          after: responseDataAccessor?.pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          const prevData = get(prev, dataPath) as ResponseDataAccessor<T>;
          const newData = get(fetchMoreResult, dataPath) as ResponseDataAccessor<T>;

          const mergedEdges = [...(prevData?.edges || []), ...(newData?.edges || [])];

          const updatedResult = { ...fetchMoreResult };
          set(updatedResult, dataPath, {
            ...newData,
            edges: mergedEdges,
            pageInfo: newData?.pageInfo,
          });

          return updatedResult;
        },
      });
    }
  }, [fetchMore, hasMore, loading, variables, dataPath, responseDataAccessor]);

  const handleSearch = (value: string) => {
    if (!searchKey) return;
    setSearch(value);
  };

  return {
    data,
    loading,
    hasMore,
    refetch,
    networkStatus,
    handleFetchMore,
    handleSearch,
  };
};
