import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersLifeCycleQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OrderFilterInput>;
}>;


export type OrdersLifeCycleQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderCountableConnection', pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null, edges: Array<{ __typename?: 'OrderCountableEdge', node: { __typename?: 'Order', id: string, number: string, status: Types.OrderStatus, failReason?: string | null, type: Types.OrderType, totalPatientShareAmount?: number | null, totalOrderDistance?: number | null, totalNetAmount?: number | null, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null } | null, orderEvents?: Array<{ __typename?: 'OrderEvent', id: string, date: any, type?: Types.OrderEventType | null, status: Types.OrderStatus } | null> | null, children?: Array<{ __typename?: 'Order', id: string, number: string, status: Types.OrderStatus, type: Types.OrderType, created: any, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null } | null, orderEvents?: Array<{ __typename?: 'OrderEvent', id: string, date: any, type?: Types.OrderEventType | null, status: Types.OrderStatus } | null> | null } | null> | null } }> } | null };


export const OrdersLifeCycleDocument = gql`
    query ordersLifeCycle($first: Int, $after: String, $filter: OrderFilterInput) {
  orders(first: $first, after: $after, filter: $filter) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        number
        status
        failReason
        type
        totalPatientShareAmount
        totalOrderDistance
        totalNetAmount
        branch {
          name
          nameAr
        }
        orderEvents {
          id
          date
          type
          status
        }
        children {
          id
          branch {
            name
            nameAr
          }
          number
          status
          type
          created
          orderEvents {
            id
            date
            type
            status
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOrdersLifeCycleQuery__
 *
 * To run a query within a React component, call `useOrdersLifeCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersLifeCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersLifeCycleQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrdersLifeCycleQuery(baseOptions?: Apollo.QueryHookOptions<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>(OrdersLifeCycleDocument, options);
      }
export function useOrdersLifeCycleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>(OrdersLifeCycleDocument, options);
        }
export function useOrdersLifeCycleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>(OrdersLifeCycleDocument, options);
        }
export type OrdersLifeCycleQueryHookResult = ReturnType<typeof useOrdersLifeCycleQuery>;
export type OrdersLifeCycleLazyQueryHookResult = ReturnType<typeof useOrdersLifeCycleLazyQuery>;
export type OrdersLifeCycleSuspenseQueryHookResult = ReturnType<typeof useOrdersLifeCycleSuspenseQuery>;
export type OrdersLifeCycleQueryResult = Apollo.QueryResult<OrdersLifeCycleQuery, OrdersLifeCycleQueryVariables>;