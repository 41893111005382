import { SubList } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { GetSubListsQuery, useGetSubListsQuery } from "../../../gql";

export const SubListsAutocomplete: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { control, formState, register } = useFormContext();
  const { t } = useTranslation("domains");
  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useGetSubListsQuery({
    variables: {
      first: 10,
      after: null,
      before: null,
      last: null,
    },
    skip: !isOpen,
  });

  const subLists = data?.subLists?.edges?.map(e => e?.node)! as SubList[];
  const hasMoreData = data?.subLists?.pageInfo?.hasNextPage;

  const fetchMoreData = () => {
    if (!data?.subLists?.pageInfo?.hasNextPage) {
      return;
    }
    fetchMore({
      variables: {
        first: 10,
        after: data?.subLists?.pageInfo?.endCursor,
      },
      updateQuery: (prev: GetSubListsQuery, { fetchMoreResult }: any) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          subLists: {
            ...fetchMoreResult?.subLists,
            edges: [...(prev?.subLists?.edges || []), ...(fetchMoreResult?.subLists?.edges || [])],
          },
        };
      },
    });
  };

  return (
    <Grid item md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
      <Typography sx={{ minWidth: "60px" }}> {t("Sublist")}</Typography>
      <AutocompleteController
        ControllerProps={{
          name: "sublist",
          control: control,
        }}
        fetchMoreData={fetchMoreData}
        options={subLists || []}
        hasMore={hasMoreData}
        loading={isLoading}
        getOptionLabel={o => o?.display}
        TextFieldProps={{
          error: Boolean(formState.errors.sublist?.message),
          helperText: formState.errors.sublist?.message as string,
          label: t("SubList"),
        }}
        {...register("sublist")}
      />
    </Grid>
  );
};
