import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMarketplaceBrandsAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IMarketplaceBrandAutocomplete } from "./schema";

export const createMarketplaceBrandAutocompleteOption = (brand: IMarketplaceBrandAutocomplete) => {
  return createAutocompleteOption(
    {
      id: brand?.id!,
      name: brand?.name!,
      nameAr: brand?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getMarketplaceBrandsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "marketplaceBrands",
    query: useMarketplaceBrandsAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name!, item?.nameAr!),
    backendAccessor: "id",
    filterSearchKey: "nameContains",
  });
};
