import { MarketplaceProductsCategoriesAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormHelperText, FormTextField, Grid, useTheme } from "@toolkit/ui";
import { FC } from "react";

type CategoryInformationFormProps = {
  hasParent?: boolean;
};

export const CategoryInformationForm: FC<CategoryInformationFormProps> = props => {
  const { hasParent } = props;

  const { t } = useTranslation("admin");

  const theme = useTheme();

  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid container spacing={formGirdSpacing} item xs={12}>
        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"name"} label={t("Name")} />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"nameAr"} label={t("Arabic Name")} />
        </Grid>

        {!hasParent && (
          <Grid item {...formGirdBreakPoints}>
            <MarketplaceProductsCategoriesAutocomplete name={"parentCategory"} label={t("Parent Category")} />

            <FormHelperText sx={{ color: theme.palette.primary.main }}>
              {t("If no category is selected, this will be set as Parent Category")}.
            </FormHelperText>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={formGirdSpacing} item xs={12}>
        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"description"} label={t("Description")} multiline rows={3} />
        </Grid>

        <Grid item {...formGirdBreakPoints}>
          <FormTextField name={"descriptionAr"} label={t("Arabic Description")} multiline rows={3} />
        </Grid>
      </Grid>
    </Grid>
  );
};
