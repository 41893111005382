import { InsuranceBenefitApprovalLimitSortingField, SortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { insuranceBenefitApprovalLimitsPaths } from "../../constants/InsuranceBenefitApprovalLimitPaths";
import { useInsuranceBenefitApprovalLimitDeleteMutation } from "../../gql/mutations/__generated__";
import { useInsuranceBenefitApprovalLimitListQuery } from "../../gql/queries/__generated__";
import { useSetInsuranceBenefitApprovalLimitsBreadcrumbs } from "../../hooks/useSetInsuranceBenefitApprovalLimitsBreadcrumbs";
import { IInsuranceBenefitApprovalLimitListNode } from "../../types";
import { useInsuranceBenefitApprovalLimitListContainerColumns } from "./useInsuranceBenefitApprovalLimitListContainerColumns";

export const InsuranceBenefitApprovalLimitListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(insuranceBenefitApprovalLimitsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IInsuranceBenefitApprovalLimitListNode) => {
    navigate(insuranceBenefitApprovalLimitsPaths.update.fullPathWithParams({ insuranceBenefitApprovalLimitId: item?.id }));
  };

  useSetInsuranceBenefitApprovalLimitsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"insuranceBenefitApprovalLimitList"}
      query={useInsuranceBenefitApprovalLimitListQuery}
      columns={useInsuranceBenefitApprovalLimitListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: InsuranceBenefitApprovalLimitSortingField.ItemCode,
          direction: SortDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "InsuranceBenefitApprovalLimit",
        name: "Insurance Benefit Approval Limit",
        useDeleteMutation: useInsuranceBenefitApprovalLimitDeleteMutation,
        idPropName: "deleteInsuranceBenefitApprovalLimitId",
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
