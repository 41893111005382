import { DayOfWeekEnum } from "../../schema/types";
import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";

export const dayOfWeekOptionsMap: IEnumToOptionsMap<DayOfWeekEnum> = {
  [DayOfWeekEnum.Sat]: {
    key: DayOfWeekEnum.Sat,
    get label() {
      return i18n.t("Saturday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Sat,
  },
  [DayOfWeekEnum.Sun]: {
    key: DayOfWeekEnum.Sun,
    get label() {
      return i18n.t("Sunday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Sun,
  },
  [DayOfWeekEnum.Mon]: {
    key: DayOfWeekEnum.Mon,
    get label() {
      return i18n.t("Monday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Mon,
  },
  [DayOfWeekEnum.Tue]: {
    key: DayOfWeekEnum.Tue,
    get label() {
      return i18n.t("Tuesday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Tue,
  },
  [DayOfWeekEnum.Wed]: {
    key: DayOfWeekEnum.Wed,
    get label() {
      return i18n.t("Wednesday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Wed,
  },
  [DayOfWeekEnum.Thu]: {
    key: DayOfWeekEnum.Thu,
    get label() {
      return i18n.t("Thursday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Thu,
  },
  [DayOfWeekEnum.Fri]: {
    key: DayOfWeekEnum.Fri,
    get label() {
      return i18n.t("Friday", { ns: "domains" });
    },
    value: DayOfWeekEnum.Fri,
  },
};

export const dayOfWeekOptions = Object.values(dayOfWeekOptionsMap);
