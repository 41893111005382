import { makeStyles } from "@toolkit/ui";

export const useDecisionConditionInnerOperationStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    minWidth: 110,
    ["@media (min-width:1200px)"]: {
      paddingRight: "10px",
      borderRight: "dashed 2px" + theme.palette.stale[400],
      margin: "24px 0 30px 0",
    },
  },
  container: {
    padding: "0px 10px !important",
  },
  innerConditionOperation: {
    ".MuiAutocomplete-inputRoot": {
      backgroundColor: theme.palette.primary.main,
      paddingInline: "10px !important",
    },
    ".MuiAutocomplete-input": {
      color: theme.palette.common.white,
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    ".MuiAutocomplete-endAdornment": {
      button: {
        color: theme.palette.common.white,
      },
    },
  },
}));
