import { i18n } from "@toolkit/i18n";
import { AssigneeType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const assigneeTypeOptionsMap: IEnumToOptionsMap<AssigneeType> = {
  [AssigneeType.Patient]: {
    key: AssigneeType.Patient,
    get label() {
      return i18n.t("Patient", { ns: "domains" });
    },
    value: AssigneeType.Patient,
  },
  [AssigneeType.TeamMember]: {
    key: AssigneeType.TeamMember,
    get label() {
      return i18n.t("Team Member", { ns: "domains" });
    },
    value: AssigneeType.TeamMember,
  },
};

export const assigneeTypeOptions = Object.values(assigneeTypeOptionsMap);
