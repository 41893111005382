import { createBranchAutocompleteOption } from "@health/autocompletes";
import { IUserWorkspaceDoctorUpsertFormValues } from "../forms/UserWorkspaceDoctorUpsert/UserWorkspaceDoctorUpsertFormSchema";
import { IUserWorkspaceDoctorValue } from "../types/types";

export const convertUserWorkspaceDoctorToFormValues = (
  userWorkspaceDoctor: IUserWorkspaceDoctorValue
): Partial<IUserWorkspaceDoctorUpsertFormValues> => {
  return {
    branch: createBranchAutocompleteOption(userWorkspaceDoctor?.branch),
  };
};
