import { i18n } from "@toolkit/i18n";
import { RequestedBy } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const requestedByOptionsMap: IEnumToOptionsMap<RequestedBy> = {
  [RequestedBy.Patient]: {
    key: RequestedBy.Patient,
    get label() {
      return i18n.t("Patient", { ns: "domains" });
    },
    value: RequestedBy.Patient,
  },
  [RequestedBy.ProviderGuidedCareHealthProgramTeam]: {
    key: RequestedBy.ProviderGuidedCareHealthProgramTeam,
    get label() {
      return i18n.t("Guided Care Team", { ns: "domains" });
    },
    value: RequestedBy.ProviderGuidedCareHealthProgramTeam,
  },
};

export const requestedByOptions = Object.values(requestedByOptionsMap);
