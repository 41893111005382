import { BulkTemplateCode } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams, useSearchParams } from "react-router-dom";
import { domainsConfig } from "../../domainsConfig";
import { useUploadBulkFileMutation } from "./gql";

type FileUploadProps = {
  file: File | null;
  templateCode: BulkTemplateCode;
  onSuccessUpload: () => void;
  onToggle: () => void;
  onResetUploadFile: () => void;
};

export const FileUpload: FC<FileUploadProps> = props => {
  const { file, templateCode, onToggle, onSuccessUpload, onResetUploadFile } = props;

  const [isSending, setIsSending] = useState(false);

  const { t } = useTranslation("domains");
  const { user } = useAuth();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const memberListId = searchParams.get("memberListId");
  const { succeeded, failed } = useAddToast();

  console.log({ memberListId });
  const [uploadBulkFile] = useUploadBulkFileMutation({
    onCompleted: data => {
      if (data?.uploadBulkFile?.errors?.length) {
        failed(formatMessageErrors(data?.uploadBulkFile?.errors));
      } else {
        succeeded(t("Upload File Successfully"));
        onToggle();
        onResetUploadFile();
        setTimeout(() => {
          onSuccessUpload();
        }, 10000);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleUploadBulkFile = (fileName: string) => {
    uploadBulkFile({
      variables: {
        code: templateCode,
        fileName,
        entityId: params?.id || memberListId || "",
      },
    });
  };

  const handleSendFile = () => {
    const myHeaders = new Headers();

    myHeaders.append("Authorization", `Bearer ${user?.access_token}`);

    const formData = new FormData();

    if (file) {
      formData.append("file", file);

      setIsSending(true);

      const fileServiceApiUrl = domainsConfig.get("fileServiceApiUrl");
      const baseURL = `${fileServiceApiUrl}/bulk/upload/${templateCode}`;

      fetch(baseURL, {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      })
        .then(result => result.json())
        .then(fileName => {
          if (fileName?.error) {
            failed(fileName?.error);
            onResetUploadFile();
            onToggle();
          } else {
            handleUploadBulkFile(fileName?.fileName);
          }
        })
        .catch(error => {
          failed(error);
          console.error({ error });
        })
        .finally(() => {
          setIsSending(false);
        });
    }
  };

  return (
    <Button sx={{ margin: "10px" }} onClick={handleSendFile} disabled={isSending}>
      {t("Submit")}
    </Button>
  );
};
