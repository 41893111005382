import { CptHcpcPrimaryProcedure } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { ICptHcpcPrimaryProcedureUpdateContainerParams } from "../../types";
import {
  CptHcpcPrimaryProcedureUpsertForm,
  ICptHcpcPrimaryProcedureUpsertFormEvent,
} from "../../forms/CptHcpcPrimaryProcedureUpsert/CptHcpcPrimaryProcedureUpsertForm";
import { convertCptHcpcPrimaryProcedureFormValuesToBackEndValues } from "../../others";
import { useCptHcpcPrimaryProcedureGetQuery } from "../../gql/queries/__generated__/CptHcpcPrimaryProcedureGet";
import { useSetCptHcpcPrimaryProcedureBreadcrumbs } from "../../hooks/useSetCptHcpcPrimaryProceduresBreadcrumbs";
import { useCptHcpcPrimaryProcedureUpdateMutation } from "../../gql/mutations/__generated__/CptHcpcPrimaryProcedureUpdate";

export const CptHcpcPrimaryProcedureUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { cptHcpcPrimaryProcedureId } = useParams<ICptHcpcPrimaryProcedureUpdateContainerParams>();

  const { data, loading } = useCptHcpcPrimaryProcedureGetQuery({
    variables: { getCptHcpcPrimaryProcedureId: cptHcpcPrimaryProcedureId! },
    skip: !cptHcpcPrimaryProcedureId,
    fetchPolicy: "no-cache",
  });

  const cptHcpcPrimaryProcedure = data?.getCptHcpcPrimaryProcedure as CptHcpcPrimaryProcedure;

  const [updateCptHcpcPrimaryProcedure, { loading: isSubmitting }] = useCptHcpcPrimaryProcedureUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.updateCptHcpcPrimaryProcedure?.id) {
        succeeded(t("CPT HCPC Primary Procedure updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onCptHcpcPrimaryProcedureUpsertFormChange = (event: ICptHcpcPrimaryProcedureUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertCptHcpcPrimaryProcedureFormValuesToBackEndValues(event.payload.values);

      updateCptHcpcPrimaryProcedure({
        variables: {
          updateCptHcpcPrimaryProcedureId: cptHcpcPrimaryProcedureId!,
          input: values,
        },
      });
    }
  };

  useSetCptHcpcPrimaryProcedureBreadcrumbs("UPDATE", cptHcpcPrimaryProcedure?.primaryProcedure);

  return (
    <CptHcpcPrimaryProcedureUpsertForm
      buttonLabel={t("Update")}
      cptHcpcPrimaryProcedure={cptHcpcPrimaryProcedure}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onCptHcpcPrimaryProcedureUpsertFormChange}
    />
  );
};
