import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormTextField, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { patientMessageSendFormSchema, patientMessageSendFormDefaultValues, IPatientMessageSendForm } from "./PatientMessageSendFormSchema";
import { SendBulkMessagesMethodEnum } from "@health/enum-options/dist/schema/types";
import { sendBulkMessagesMethodOptions, sendBulkMessagesMethodOptionsMap } from "@health/enum-options";

export const PatientMessageSendFormApi: {
  submit: () => void;
  getForm: () => UseFormReturn<IPatientMessageSendForm> | null;
} = {
  submit: () => {},
  getForm: () => null,
};

export type PatientMessageSendFormProps = {
  method?: SendBulkMessagesMethodEnum;
  onChange: (values: IPatientMessageSendForm) => void;
};

export const PatientMessageSendForm: FC<PatientMessageSendFormProps> = props => {
  const { method, onChange } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IPatientMessageSendForm>({
    defaultValues: patientMessageSendFormDefaultValues,
    schema: patientMessageSendFormSchema,
  });

  const { setValue, handleSubmit } = form;

  const onSubmit = (values: IPatientMessageSendForm) => {
    onChange(values);
  };

  useEffect(() => {
    if (method) {
      setValue("method", sendBulkMessagesMethodOptionsMap[method]);
    }
  }, [method]);

  useEffect(() => {
    PatientMessageSendFormApi.submit = handleSubmit(onSubmit);
    PatientMessageSendFormApi.getForm = () => form;

    return () => {
      PatientMessageSendFormApi.submit = () => {};
      PatientMessageSendFormApi.getForm = () => null;
    };
  }, [form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormTextField name={"title"} label={t("Title")} />
          </Grid>

          <Grid item xs={6}>
            <FormTextField name={"titleAr"} label={t("Title (Arabic)")} />
          </Grid>

          {!method && (
            <Grid item xs={12}>
              <FormAutocomplete name={"method"} label={t("Method")} options={sendBulkMessagesMethodOptions} />
            </Grid>
          )}

          <Grid item xs={6}>
            <FormTextField name={"message"} label={t("Message")} multiline rows={4} />
          </Grid>

          <Grid item xs={6}>
            <FormTextField name={"messageAr"} label={t("Message (Arabic)")} multiline rows={4} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
