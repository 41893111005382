import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { DecisionPlanFragmentDoc } from '../../fragments/__generated__/DecisionPlan';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionPlanGetQueryVariables = Types.Exact<{
  getPlanId: Types.Scalars['ID']['input'];
}>;


export type DecisionPlanGetQuery = { __typename?: 'Query', getPlan?: { __typename?: 'DecisionPlan', id?: string | null, code?: string | null, display?: string | null, actionDefinitionFields?: Array<{ __typename?: 'ActionDefinitionField', id?: string | null, display?: string | null, type?: Types.ActionFieldType | null, code?: string | null, isRequired?: boolean | null, allowedValues?: Array<string | null> | null } | null> | null, field?: Array<{ __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null, operators?: Array<Types.Operator | null> | null, allowedValues?: Array<string | null> | null, category?: { __typename?: 'FieldCategory', code?: string | null, display?: string | null } | null, fieldType?: { __typename?: 'FieldType', id?: string | null, type?: Types.EntityType | null } | null } | null> | null } | null };


export const DecisionPlanGetDocument = gql`
    query DecisionPlanGet($getPlanId: ID!) {
  getPlan(id: $getPlanId) {
    ...DecisionPlan
  }
}
    ${DecisionPlanFragmentDoc}`;

/**
 * __useDecisionPlanGetQuery__
 *
 * To run a query within a React component, call `useDecisionPlanGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionPlanGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionPlanGetQuery({
 *   variables: {
 *      getPlanId: // value for 'getPlanId'
 *   },
 * });
 */
export function useDecisionPlanGetQuery(baseOptions: Apollo.QueryHookOptions<DecisionPlanGetQuery, DecisionPlanGetQueryVariables> & ({ variables: DecisionPlanGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionPlanGetQuery, DecisionPlanGetQueryVariables>(DecisionPlanGetDocument, options);
      }
export function useDecisionPlanGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionPlanGetQuery, DecisionPlanGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionPlanGetQuery, DecisionPlanGetQueryVariables>(DecisionPlanGetDocument, options);
        }
export function useDecisionPlanGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionPlanGetQuery, DecisionPlanGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionPlanGetQuery, DecisionPlanGetQueryVariables>(DecisionPlanGetDocument, options);
        }
export type DecisionPlanGetQueryHookResult = ReturnType<typeof useDecisionPlanGetQuery>;
export type DecisionPlanGetLazyQueryHookResult = ReturnType<typeof useDecisionPlanGetLazyQuery>;
export type DecisionPlanGetSuspenseQueryHookResult = ReturnType<typeof useDecisionPlanGetSuspenseQuery>;
export type DecisionPlanGetQueryResult = Apollo.QueryResult<DecisionPlanGetQuery, DecisionPlanGetQueryVariables>;