import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";

export enum DigitalTwinComplicationCategory {
  Diagnosed = "Diagnosed",
  HighRisk = "high_risk",
  MidRisk = "mid_risk",
  LowRisk = "low_risk",
}

export const digitalTwinComplicationCategoryOptionsMap: IEnumToOptionsMap<DigitalTwinComplicationCategory> = {
  [DigitalTwinComplicationCategory.Diagnosed]: {
    key: DigitalTwinComplicationCategory.Diagnosed,
    get label() {
      return i18n.t("Diagnosed", { ns: "domains" });
    },
    value: DigitalTwinComplicationCategory.Diagnosed,
  },
  [DigitalTwinComplicationCategory.HighRisk]: {
    key: DigitalTwinComplicationCategory.HighRisk,
    get label() {
      return i18n.t("High Risk", { ns: "domains" });
    },
    value: DigitalTwinComplicationCategory.HighRisk,
  },
  [DigitalTwinComplicationCategory.MidRisk]: {
    key: DigitalTwinComplicationCategory.MidRisk,
    get label() {
      return i18n.t("Mid Risk", { ns: "domains" });
    },
    value: DigitalTwinComplicationCategory.MidRisk,
  },
  [DigitalTwinComplicationCategory.LowRisk]: {
    key: DigitalTwinComplicationCategory.LowRisk,
    get label() {
      return i18n.t("Low Risk", { ns: "domains" });
    },
    value: DigitalTwinComplicationCategory.LowRisk,
  },
};

export const digitalTwinComplicationCategoryOptions = Object.values(digitalTwinComplicationCategoryOptionsMap);
