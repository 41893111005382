import { H_EntityError } from "@/schema/types";
import { callbackRequestStatusOptions } from "@health/enum-options";
import { combineErrors, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Box, Button, Grid, TextField, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
export const RequestHandlingForm: FC<{ errors: H_EntityError[]; loading: boolean }> = ({ errors, loading }) => {
  const { t } = useTranslation("admin");
  const {
    formState: { errors: formErrors },
    control,
    register,
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);
  const name = "status";
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
          {t("Handle Request")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: 1 }}>
        <TextField
          rows={5}
          fullWidth
          multiline
          error={Boolean(combinedErrors?.comments?.message)}
          helperText={t(combinedErrors?.comments?.message)}
          label={t("Add your comment")}
          {...register("comments")}
        />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={formGirdSpacing} justifyContent='space-between'>
          <Grid item xs={6} textAlign='left'>
            <AutocompleteController
              ControllerProps={{
                name: name,
                control: control,
                rules: { required: true },
              }}
              TextFieldProps={{
                placeholder: t("Request Status"),
                error: Boolean(combinedErrors?.[name]?.type),
                helperText: t(combinedErrors?.[name]?.type && t("Required")),
                label: t("Request Status"),
              }}
              getOptionLabel={option => t(option?.name)}
              options={callbackRequestStatusOptions}
            />
          </Grid>
          <Grid item xs={6} textAlign='right'>
            <Button type='submit' disabled={loading}>
              {t("Handle")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
