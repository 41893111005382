import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPharmacyCredentialsQueryVariables = Types.Exact<{
  branch: Types.Scalars['ID']['input'];
  destinationType: Types.DestinationType;
}>;


export type GetPharmacyCredentialsQuery = { __typename?: 'Query', getPharmacyCredentials?: { __typename?: 'PharmacyCredentials', userName?: string | null, license?: string | null } | null };


export const GetPharmacyCredentialsDocument = gql`
    query GetPharmacyCredentials($branch: ID!, $destinationType: DestinationType!) {
  getPharmacyCredentials(branch: $branch, destinationType: $destinationType) {
    userName
    license
  }
}
    `;

/**
 * __useGetPharmacyCredentialsQuery__
 *
 * To run a query within a React component, call `useGetPharmacyCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPharmacyCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPharmacyCredentialsQuery({
 *   variables: {
 *      branch: // value for 'branch'
 *      destinationType: // value for 'destinationType'
 *   },
 * });
 */
export function useGetPharmacyCredentialsQuery(baseOptions: Apollo.QueryHookOptions<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables> & ({ variables: GetPharmacyCredentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>(GetPharmacyCredentialsDocument, options);
      }
export function useGetPharmacyCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>(GetPharmacyCredentialsDocument, options);
        }
export function useGetPharmacyCredentialsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>(GetPharmacyCredentialsDocument, options);
        }
export type GetPharmacyCredentialsQueryHookResult = ReturnType<typeof useGetPharmacyCredentialsQuery>;
export type GetPharmacyCredentialsLazyQueryHookResult = ReturnType<typeof useGetPharmacyCredentialsLazyQuery>;
export type GetPharmacyCredentialsSuspenseQueryHookResult = ReturnType<typeof useGetPharmacyCredentialsSuspenseQuery>;
export type GetPharmacyCredentialsQueryResult = Apollo.QueryResult<GetPharmacyCredentialsQuery, GetPharmacyCredentialsQueryVariables>;