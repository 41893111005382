import { z } from "zod";
import { zodSchema } from "@health/autocompletes";

export const userPermissionGroupUpsertFormSchema = z.object({
  name: z.string().min(3).max(30),
  permissions: z.array(zodSchema.permission).min(1),
});

export type IUserPermissionGroupUpsertFormValues = z.infer<typeof userPermissionGroupUpsertFormSchema>;

export const userPermissionGroupUpsertFormDefaultValues: Partial<IUserPermissionGroupUpsertFormValues> = {
  name: undefined,
  permissions: [],
};
