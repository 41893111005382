import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistUploadMutationVariables = Types.Exact<{
  subListId: Types.Scalars['ID']['input'];
  file: Types.Scalars['String']['input'];
}>;


export type SublistUploadMutation = { __typename?: 'Mutation', uploadSubList?: { __typename?: 'SubList', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null } | null> | null } | null };


export const SublistUploadDocument = gql`
    mutation SublistUpload($subListId: ID!, $file: String!) {
  uploadSubList(fileName: $file, subListId: $subListId) {
    errors {
      message
      field
    }
  }
}
    `;
export type SublistUploadMutationFn = Apollo.MutationFunction<SublistUploadMutation, SublistUploadMutationVariables>;

/**
 * __useSublistUploadMutation__
 *
 * To run a mutation, you first call `useSublistUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistUploadMutation, { data, loading, error }] = useSublistUploadMutation({
 *   variables: {
 *      subListId: // value for 'subListId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSublistUploadMutation(baseOptions?: Apollo.MutationHookOptions<SublistUploadMutation, SublistUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SublistUploadMutation, SublistUploadMutationVariables>(SublistUploadDocument, options);
      }
export type SublistUploadMutationHookResult = ReturnType<typeof useSublistUploadMutation>;
export type SublistUploadMutationResult = Apollo.MutationResult<SublistUploadMutation>;
export type SublistUploadMutationOptions = Apollo.BaseMutationOptions<SublistUploadMutation, SublistUploadMutationVariables>;