import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionPlansAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.DecisionPlanFilterInput>;
}>;


export type DecisionPlansAutocompleteQuery = { __typename?: 'Query', getPlans?: { __typename?: 'DecisionPlanConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'DecisionPlanEdge', node?: { __typename?: 'DecisionPlan', id?: string | null, code?: string | null, display?: string | null } | null } | null> | null } | null };


export const DecisionPlansAutocompleteDocument = gql`
    query DecisionPlansAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: DecisionPlanFilterInput) {
  getPlans(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        code
        display
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useDecisionPlansAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionPlansAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionPlansAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionPlansAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionPlansAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>(DecisionPlansAutocompleteDocument, options);
      }
export function useDecisionPlansAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>(DecisionPlansAutocompleteDocument, options);
        }
export function useDecisionPlansAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>(DecisionPlansAutocompleteDocument, options);
        }
export type DecisionPlansAutocompleteQueryHookResult = ReturnType<typeof useDecisionPlansAutocompleteQuery>;
export type DecisionPlansAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionPlansAutocompleteLazyQuery>;
export type DecisionPlansAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionPlansAutocompleteSuspenseQuery>;
export type DecisionPlansAutocompleteQueryResult = Apollo.QueryResult<DecisionPlansAutocompleteQuery, DecisionPlansAutocompleteQueryVariables>;