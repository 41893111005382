import { Payer } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertPayerToFormValues } from "../../others";
import { PayerImagesForm } from "../PayerImages/PayerImagesForm";
import { PayerInformationForm } from "../PayerInformation/PayerInformationForm";
import { PayerInsuranceForm } from "../PayerInsurance/PayerInsuranceForm";
import { IPayerUpsertFormValues, payerUpsertFormDefaultValues, payerUpsertFormSchema } from "./PayerUpsertFormSchema";

export type IPayerUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IPayerUpsertFormValues;
  };
};

type PayerUpsertFormProps = {
  payer?: Payer;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IPayerUpsertFormEvent) => void;
};

export const PayerUpsertForm: FC<PayerUpsertFormProps> = props => {
  const { buttonLabel, payer, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IPayerUpsertFormValues>({
    defaultValues: payerUpsertFormDefaultValues,
    schema: payerUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IPayerUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (payer) {
      const _payer = convertPayerToFormValues(payer);
      setValues(_payer);
    }
  }, [payer, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Payer Information")} loading={isLoading} doYouHaveData>
                <PayerInformationForm isUpdateMode={!!payer} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Payer Images")} loading={isLoading} doYouHaveData>
                <PayerImagesForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Payer Insurance")} loading={isLoading} doYouHaveData>
                <PayerInsuranceForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
