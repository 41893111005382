import { UserFilterInput } from "@/schema/types";
import { CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { UserList } from "@/pages/Users/components/UserList/UserList";
import { useTranslation } from "@toolkit/i18n";

type UserListProps = {
  filter?: UserFilterInput;
};

export const UserListModal: FC<UserListProps> = props => {
  const { filter } = props;

  const { t } = useTranslation("domains");

  return (
    <CustomDialog type={"info"} title={t("Users")}>
      <UserList filter={filter} />
    </CustomDialog>
  );
};
