import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CaseManagementPatientTeamListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamSortingInput>;
  filter?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamFilterInput>;
}>;


export type CaseManagementPatientTeamListQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeams?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge', node: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', id: string, guidedCareHealthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, name?: string | null } | null } } }> } | null };


export const CaseManagementPatientTeamListDocument = gql`
    query CaseManagementPatientTeamList($first: Int, $last: Int, $after: String, $before: String, $sortBy: ProviderGuidedCareHealthProgramTeamSortingInput, $filter: ProviderGuidedCareHealthProgramTeamFilterInput) {
  providerGuidedCareHealthProgramTeams(
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        guidedCareHealthProgram {
          id
          guidedCareHealthProgramTemplate {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCaseManagementPatientTeamListQuery__
 *
 * To run a query within a React component, call `useCaseManagementPatientTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementPatientTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagementPatientTeamListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCaseManagementPatientTeamListQuery(baseOptions?: Apollo.QueryHookOptions<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>(CaseManagementPatientTeamListDocument, options);
      }
export function useCaseManagementPatientTeamListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>(CaseManagementPatientTeamListDocument, options);
        }
export function useCaseManagementPatientTeamListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>(CaseManagementPatientTeamListDocument, options);
        }
export type CaseManagementPatientTeamListQueryHookResult = ReturnType<typeof useCaseManagementPatientTeamListQuery>;
export type CaseManagementPatientTeamListLazyQueryHookResult = ReturnType<typeof useCaseManagementPatientTeamListLazyQuery>;
export type CaseManagementPatientTeamListSuspenseQueryHookResult = ReturnType<typeof useCaseManagementPatientTeamListSuspenseQuery>;
export type CaseManagementPatientTeamListQueryResult = Apollo.QueryResult<CaseManagementPatientTeamListQuery, CaseManagementPatientTeamListQueryVariables>;