import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderPlanGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProviderPlanGetQuery = { __typename?: 'Query', plan?: { __typename?: 'Plan', id: string, fixedCostAmount: number, fixedOrderCostAmount: number, fixedOrderPercentage: number } | null };


export const ProviderPlanGetDocument = gql`
    query ProviderPlanGet($id: ID!) {
  plan(id: $id) {
    id
    fixedCostAmount
    fixedOrderCostAmount
    fixedOrderPercentage
  }
}
    `;

/**
 * __useProviderPlanGetQuery__
 *
 * To run a query within a React component, call `useProviderPlanGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderPlanGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderPlanGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderPlanGetQuery(baseOptions: Apollo.QueryHookOptions<ProviderPlanGetQuery, ProviderPlanGetQueryVariables> & ({ variables: ProviderPlanGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderPlanGetQuery, ProviderPlanGetQueryVariables>(ProviderPlanGetDocument, options);
      }
export function useProviderPlanGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderPlanGetQuery, ProviderPlanGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderPlanGetQuery, ProviderPlanGetQueryVariables>(ProviderPlanGetDocument, options);
        }
export function useProviderPlanGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderPlanGetQuery, ProviderPlanGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderPlanGetQuery, ProviderPlanGetQueryVariables>(ProviderPlanGetDocument, options);
        }
export type ProviderPlanGetQueryHookResult = ReturnType<typeof useProviderPlanGetQuery>;
export type ProviderPlanGetLazyQueryHookResult = ReturnType<typeof useProviderPlanGetLazyQuery>;
export type ProviderPlanGetSuspenseQueryHookResult = ReturnType<typeof useProviderPlanGetSuspenseQuery>;
export type ProviderPlanGetQueryResult = Apollo.QueryResult<ProviderPlanGetQuery, ProviderPlanGetQueryVariables>;