import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnosisValidProcedureCreateMutationVariables = Types.Exact<{
  input: Types.DiagnosisValidProcedureInput;
}>;


export type DiagnosisValidProcedureCreateMutation = { __typename?: 'Mutation', createDiagnosisValidProcedure?: { __typename?: 'DiagnosisValidProcedure', procedureCode: string, id: string, diagnosisCode: string } | null };


export const DiagnosisValidProcedureCreateDocument = gql`
    mutation DiagnosisValidProcedureCreate($input: DiagnosisValidProcedureInput!) {
  createDiagnosisValidProcedure(input: $input) {
    procedureCode
    id
    diagnosisCode
  }
}
    `;
export type DiagnosisValidProcedureCreateMutationFn = Apollo.MutationFunction<DiagnosisValidProcedureCreateMutation, DiagnosisValidProcedureCreateMutationVariables>;

/**
 * __useDiagnosisValidProcedureCreateMutation__
 *
 * To run a mutation, you first call `useDiagnosisValidProcedureCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisValidProcedureCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diagnosisValidProcedureCreateMutation, { data, loading, error }] = useDiagnosisValidProcedureCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiagnosisValidProcedureCreateMutation(baseOptions?: Apollo.MutationHookOptions<DiagnosisValidProcedureCreateMutation, DiagnosisValidProcedureCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiagnosisValidProcedureCreateMutation, DiagnosisValidProcedureCreateMutationVariables>(DiagnosisValidProcedureCreateDocument, options);
      }
export type DiagnosisValidProcedureCreateMutationHookResult = ReturnType<typeof useDiagnosisValidProcedureCreateMutation>;
export type DiagnosisValidProcedureCreateMutationResult = Apollo.MutationResult<DiagnosisValidProcedureCreateMutation>;
export type DiagnosisValidProcedureCreateMutationOptions = Apollo.BaseMutationOptions<DiagnosisValidProcedureCreateMutation, DiagnosisValidProcedureCreateMutationVariables>;