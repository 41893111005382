import { MarketplaceApprovalStatus, MarketplaceProductSortingField, MarketplaceSortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { marketplaceProductsBreadcrumb } from "../constants/MarketplaceProductsBreadcrumbs";
import { useMarketplaceProductsQuery } from "../gql/queries";
import { useProductsListTableColumns } from "./useProductsListTableColumns";

export const ProductsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: marketplaceProductsBreadcrumb(),
    });
  }, []);

  return (
    <GridProvider
      gridName='marketplace-products'
      query={useMarketplaceProductsQuery}
      columns={useProductsListTableColumns()}
      hasTableSetting
      hideFilterInput
      filterInput={{ approvalStatuses: [MarketplaceApprovalStatus.PendingApproval] }}
      variables={{
        sortBy: {
          field: MarketplaceProductSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
        filter: {
          approvalStatuses: [MarketplaceApprovalStatus.PendingApproval],
        },
      }}
      skipUrlState={false}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
