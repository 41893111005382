import { i18n } from "@toolkit/i18n";
import { ConditionOperation } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const conditionOperationOptionsMap: IEnumToOptionsMap<ConditionOperation> = {
  [ConditionOperation.And]: {
    key: ConditionOperation.And,
    get label() {
      return i18n.t("And", { ns: "domains" });
    },
    value: ConditionOperation.And,
  },
  [ConditionOperation.Or]: {
    key: ConditionOperation.Or,
    get label() {
      return i18n.t("Or", { ns: "domains" });
    },
    value: ConditionOperation.Or,
  },
};

export const conditionOperationOptions = Object.values(conditionOperationOptionsMap);
