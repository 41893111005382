import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const BlobWarningIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='76' height='76.965' viewBox='0 0 76 76.965' {...props}>
      <g id='Group_12189' data-name='Group 12189' transform='translate(-183 -333.457)'>
        <g id='Group_10686' data-name='Group 10686' transform='translate(183 333.457)'>
          <path
            id='Path_12745'
            data-name='Path 12745'
            d='M48.805,76.183C39.85,77.447,31.29,77.33,22.43,74.69c-8.782-2.651-17.849-7.747-20.989-15.3-3.062-7.562-.228-17.533,3.75-26.032A67.488,67.488,0,0,1,20.986,11.413C27.84,4.97,36.706-.9,44.679.113c7.939,1.054,14.985,8.98,20.214,16.818A63.072,63.072,0,0,1,75.232,41.169c1.634,8.739,1.412,18.479-3.39,24.7-4.756,6.25-14.093,8.976-23.037,10.319'
            transform='translate(-0.149 0)'
            fill='#d46a3d'
            opacity='0.05'
          />
          <path
            id='Path_12746'
            data-name='Path 12746'
            d='M37.04,75.472c-8.524-1.925-16.217-4.964-23.316-10.383C6.7,59.687.253,51.98,0,44.087c-.174-7.876,5.8-15.91,12.31-22.222A65.206,65.206,0,0,1,34.1,7.459C42.5,3.987,52.522,1.729,59.38,5.37c6.812,3.671,10.461,13.242,12.5,22.11a60.908,60.908,0,0,1,1.035,25.427c-1.519,8.451-5.057,17.169-11.525,21.138-6.44,4.014-15.809,3.277-24.348,1.427'
            transform='translate(1.15 -0.062)'
            fill='#f9e9e2'
          />
        </g>
        <g id='icons8-alert_3_' data-name='icons8-alert (3)' transform='translate(207.554 356.892)'>
          <path
            id='Line_1589'
            data-name='Line 1589'
            d='M-.317,10.331A1.183,1.183,0,0,1-1.5,9.147V-.317A1.183,1.183,0,0,1-.317-1.5,1.183,1.183,0,0,1,.866-.317V9.147A1.183,1.183,0,0,1-.317,10.331Z'
            transform='translate(16.879 9.387)'
            fill='#d46a3d'
          />
          <circle
            id='Ellipse_2936'
            data-name='Ellipse 2936'
            cx='1.577'
            cy='1.577'
            r='1.577'
            transform='translate(14.984 22.084)'
            fill='#d46a3d'
          />
          <path
            id='Path_12990'
            data-name='Path 12990'
            d='M33.315,25.32a1.183,1.183,0,0,1-.837-2.02l2.366-2.366a2.054,2.054,0,0,0,0-2.9L22.549,5.974l-.008-.008a2.054,2.054,0,0,0-2.9,0l-7.017,7.1A1.183,1.183,0,0,1,10.942,11.4l7.019-7.1,0,0a4.42,4.42,0,0,1,6.243,0l12.3,12.063.008.008a4.423,4.423,0,0,1,0,6.248l-2.366,2.366A1.179,1.179,0,0,1,33.315,25.32Z'
            transform='translate(-4.607 -3)'
            fill='#d46a3d'
          />
          <path
            id='Path_12991'
            data-name='Path 12991'
            d='M19.642,39.23a4.4,4.4,0,0,1-3.12-1.289L4.3,25.877l-.005-.005a4.423,4.423,0,0,1,0-6.248l1.577-1.577A1.183,1.183,0,0,1,7.544,19.72L5.966,21.3a2.054,2.054,0,0,0,0,2.9L18.186,36.26l.005.005a2.054,2.054,0,0,0,2.9,0l6.778-6.935a1.183,1.183,0,1,1,1.692,1.654l-6.783,6.941-.01.01A4.4,4.4,0,0,1,19.642,39.23Z'
            transform='translate(-3.001 -6.106)'
            fill='#d46a3d'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
