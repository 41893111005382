import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import React, { cloneElement, FC, ReactElement } from "react";
import {
  PatientMessageSendForm,
  PatientMessageSendFormApi,
  PatientMessageSendFormProps,
} from "pages/Patients/forms/PatientMessageSend/PatientMessageSendForm";

export type PatientMessageSendModalProps = PatientMessageSendFormProps & {
  isOpen: boolean;
  isLoading: boolean;
  button?: ReactElement;
  handleToggle: () => void;
};

export const PatientMessageSendModal: FC<PatientMessageSendModalProps> = props => {
  const { isOpen, isLoading, method, button, handleToggle, onChange } = props;

  const { t } = useTranslation("domains");

  const onSendClick = () => {
    PatientMessageSendFormApi?.submit();
  };

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      button={button ? cloneElement(button, { onClick: handleToggle }) : undefined}
      DialogTitleProps={{
        title: t("Send Message To Patient"),
        onClose: handleToggle,
      }}
      DialogActionsProps={{
        hasClose: true,
        children: (
          <Button disabled={isLoading} endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null} onClick={onSendClick}>
            {t("Send")}
          </Button>
        ),
      }}
    >
      <PatientMessageSendForm method={method} onChange={onChange} />
    </CustomDialog>
  );
};
