import { useCallback, useEffect, useState } from "react";
import { createScriptElement, createStyleElement } from "./util";

export const useLoadAssets = (baseUrl: string, isLoading: boolean) => {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState<any>();
  const [assets, setAssets] = useState<Record<string, { js?: string; css?: string; type?: string }>>();

  const getAssets = useCallback(async () => {
    try {
      setError(null);
      const res = await fetch(`${baseUrl}/assets/assets.json`, { cache: "force-cache" });
      setAssets(await res.json());
    } catch (err) {
      setError(err);
    }
  }, []);

  useEffect(() => {
    if (isLoading || isReady) {
      return;
    }

    const timeoutId = setTimeout(() => setIsReady(true), 200);
    return () => clearTimeout(timeoutId);
  }, [isLoading, isReady]);

  useEffect(() => {
    if (!assets || !isReady) {
      return;
    }

    const elements = Object.keys(assets).map(key => {
      if (assets[key].js) {
        return createScriptElement(`${baseUrl}/${assets[key].js}`, assets[key].type);
      } else {
        return createStyleElement(`${baseUrl}/${assets[key].css}`);
      }
    });

    elements.forEach(element => document.body.appendChild(element));
    return () => {
      elements.forEach(element => document.body.removeChild(element));
    };
  }, [assets, isReady]);

  useEffect(() => {
    if (!baseUrl || assets) {
      return;
    }

    getAssets();
  }, [baseUrl, assets, getAssets]);

  return { assets, handleGetAssets: getAssets, assetsError: error };
};
