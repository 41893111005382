import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { Maybe, WorkingHour } from "@/schema/types";
import { BranchWorkingHours } from "@/pages/Branches/components/BranchWorkingHours/BranchWorkingHours";

type BranchWorkingHoursModalProps = {
  workingHours?: Maybe<Maybe<WorkingHour>[]>;
};

export const BranchWorkingHoursModal: FC<BranchWorkingHoursModalProps> = props => {
  const { workingHours } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!workingHours || !workingHours?.length} />} title={t("Working Hours")}>
      <BranchWorkingHours workingHours={workingHours as WorkingHour[]} />
    </CustomDialog>
  );
};
