import { get } from "lodash";
import { CustomTableColumnProps, SchemaSortDirection } from "../types";

export declare type PageInfo = {
  endCursor?: string | null;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  startCursor?: string | null;
};
declare type CountableConnection<T> = {
  edges?: Array<{ node: T | null | undefined }> | null;
  pageInfo?: PageInfo | null;
  totalCount?: number | null;
};

export type ExtractCountableConnectionDataExtractor<M, T> = (x: { responseData: M; entityName: null | keyof Omit<M, "__typename"> }) => {
  nodes: T[];
  pageInfo: PageInfo | null | undefined;
  totalCount: number | null | undefined;
};
export const extractCountableConnectionData = <M, T>({
  responseData,
  entityName,
}: {
  responseData: M;
  entityName: null | keyof Omit<M, "__typename">;
}): {
  nodes: T[];
  pageInfo: PageInfo | null | undefined;
  totalCount: number | null | undefined;
} => {
  if (!entityName) return { nodes: [], pageInfo: null, totalCount: null };

  const entity = get(responseData, entityName) as CountableConnection<T>;
  return {
    nodes:
      entity?.edges != undefined
        ? (entity?.edges?.map(edge => edge?.node) as T[]) || []
        : ((entity as T[])?.map(edge => edge) as T[]) || [],
    pageInfo: entity?.pageInfo,
    totalCount: entity?.totalCount,
  };
};

// TODO: Remove
export const nextSortDirection = (direction: SchemaSortDirection | undefined): SchemaSortDirection | undefined => {
  return direction === "ASC" ? "DESC" : "ASC";
};
declare enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}
export const getNextSort = (direction: SchemaSortDirection | undefined): SchemaSortDirection | undefined => {
  if (direction === "ASC") return "DESC";
  return direction === "DESC" ? undefined : "ASC";
};
export const handleSortGeneric =
  <T extends object>(configs: {
    setColumns: (value: React.SetStateAction<CustomTableColumnProps<T>[]>) => void;
    doSort: (sortColumnEnum: string) => void;
    direction: OrderDirection | undefined;
  }) =>
  (column: CustomTableColumnProps<T>): void => {
    const { doSort } = configs;
    doSort(column?.sort?.columnEnum!);
  };

export default { nextSortDirection };
