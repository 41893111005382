import { Block, BlockInput } from "@/schema/types";
import { IBlockUpsertFormValues } from "../forms/BlockUpsert/BlockUpsertFormSchema";
import { createCityAutocompleteOption } from "@health/autocompletes";

export const convertBlockToFormValues = (block: Block): IBlockUpsertFormValues => {
  return {
    name: block?.name,
    nameAr: block?.nameAr!,
    city: createCityAutocompleteOption(block?.city!),
    code: block?.code!,
  };
};

export const convertBlockFormValuesToBackEndValues = (values: IBlockUpsertFormValues): BlockInput => {
  return {
    name: values?.name,
    nameAr: values?.nameAr,
    code: values?.code,
    city: values?.city?.value?.id,
  };
};
