import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useUserPermissionGroupCreateMutation } from "../../gql";
import { convertUserPermissionGroupCreateFormValuesToBackEndValues } from "../../others";
import { useSetUsersPermissionsGroupsBreadcrumbs } from "../../hooks";
import { usersPermissionsGroupsPaths } from "../../constants";
import {
  UserPermissionGroupUpsertForm,
  IUserPermissionGroupUpsertFormEvent,
} from "../../forms/UserPermissionGroupUpsert/UserPermissionGroupUpsertForm";

export const UserPermissionGroupCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createUserPermissionGroup, { loading: isSubmitting }] = useUserPermissionGroupCreateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.permissionGroupCreate?.permissionGroupErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Permission Group created successfully"));
        navigate(usersPermissionsGroupsPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onUserPermissionGroupUpsertFormChange = (event: IUserPermissionGroupUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertUserPermissionGroupCreateFormValuesToBackEndValues(event.payload.values);

      createUserPermissionGroup({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetUsersPermissionsGroupsBreadcrumbs("CREATE");

  return (
    <UserPermissionGroupUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onUserPermissionGroupUpsertFormChange} />
  );
};
