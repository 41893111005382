import { createAutocompleteOption, IAutocompleteFilter } from "@toolkit/ui";
import { useListFieldsAutocompleteQuery } from "./gql";
import { IListFieldAutocomplete } from "./schema";

export const createListFieldAutocompleteOption = (unit: IListFieldAutocomplete) => {
  return createAutocompleteOption(
    {
      id: unit?.id!,
      code: unit?.code!,
      display: unit?.display!,
    },
    "id",
    "display"
  );
};

export const getListFieldsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return {
    name,
    multiple,
    type: "autocomplete" as const,
    accessor: "getAllListFields",
    query: useListFieldsAutocompleteQuery,
    getOptionLabel: option => option?.display,
    getValueForBackend: options => options?.id,
  };
};
