import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, Grid } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";

import {
  bulkMessageSearchCriteriaFormDefaultValues,
  bulkMessageSearchCriteriaFormSchema,
  IBulkMessageSearchCriteriaFormValues,
} from "./BulkMessagesSearchCriteriaFormSchema";
import { userGenderOptions } from "@health/enum-options";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { CodeSystemCode } from "@/schema/types";

export type IBulkMessageSearchCriteriaUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IBulkMessageSearchCriteriaFormValues;
  };
};

type BulkMessageSearchCriteriaUpsertFormProps = {
  isSubmitting?: boolean;
  onChange: (event: IBulkMessageSearchCriteriaUpsertFormEvent) => void;
};

export type BulkMessageSearchCriteriaUpsertFormRef = {
  submit: () => void;
};

const BulkMessageSearchCriteriaUpsertFormForwardRef: ForwardRefRenderFunction<
  BulkMessageSearchCriteriaUpsertFormRef,
  BulkMessageSearchCriteriaUpsertFormProps
> = (props, ref) => {
  const { onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IBulkMessageSearchCriteriaFormValues>({
    defaultValues: bulkMessageSearchCriteriaFormDefaultValues,
    schema: bulkMessageSearchCriteriaFormSchema,
  });

  const onSubmit = (values: IBulkMessageSearchCriteriaFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    submit: () => form.handleSubmit(onSubmit)(),
  }));

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormNumberField name='fromAge' label={t("From Age")} placeholder={t("From Age")} />
          </Grid>
          <Grid item xs={12}>
            <FormNumberField name='toAge' label={t("To Age")} placeholder={t("To Age")} />
          </Grid>
          <Grid item xs={12}>
            <FormAutocomplete name='gender' label={t("Gender")} placeholder={t("Gender")} options={userGenderOptions} />
          </Grid>
          <Grid item xs={12}>
            <SystemCodeAutocomplete
              name='chronicDiseases'
              label={t("Chronic Diseases")}
              placeholder={t("Chronic Diseases")}
              multiple
              filter={{
                codeSystemCode: CodeSystemCode.ChronicDisease,
              }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const BulkMessageSearchCriteriaUpsertForm = forwardRef(BulkMessageSearchCriteriaUpsertFormForwardRef);
