import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientGuidedCareProgramEvaluateMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.EvaluateProgramRiskFactorTemplateInput>;
}>;


export type PatientGuidedCareProgramEvaluateMutation = { __typename?: 'Mutation', evaluateGuidedCareProgramRiskFactorTemplate?: { __typename?: 'GuidedCareHealthProgramRiskFactorTemplateEvaluation', level?: string | null, suggestedGuidedPrograms?: Array<{ __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null, allowDoctorDirectEnrollment?: boolean | null } | null> | null } | null };


export const PatientGuidedCareProgramEvaluateDocument = gql`
    mutation PatientGuidedCareProgramEvaluate($input: EvaluateProgramRiskFactorTemplateInput) {
  evaluateGuidedCareProgramRiskFactorTemplate(input: $input) {
    level
    suggestedGuidedPrograms {
      id
      name
      allowDoctorDirectEnrollment
    }
  }
}
    `;
export type PatientGuidedCareProgramEvaluateMutationFn = Apollo.MutationFunction<PatientGuidedCareProgramEvaluateMutation, PatientGuidedCareProgramEvaluateMutationVariables>;

/**
 * __usePatientGuidedCareProgramEvaluateMutation__
 *
 * To run a mutation, you first call `usePatientGuidedCareProgramEvaluateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientGuidedCareProgramEvaluateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientGuidedCareProgramEvaluateMutation, { data, loading, error }] = usePatientGuidedCareProgramEvaluateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientGuidedCareProgramEvaluateMutation(baseOptions?: Apollo.MutationHookOptions<PatientGuidedCareProgramEvaluateMutation, PatientGuidedCareProgramEvaluateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatientGuidedCareProgramEvaluateMutation, PatientGuidedCareProgramEvaluateMutationVariables>(PatientGuidedCareProgramEvaluateDocument, options);
      }
export type PatientGuidedCareProgramEvaluateMutationHookResult = ReturnType<typeof usePatientGuidedCareProgramEvaluateMutation>;
export type PatientGuidedCareProgramEvaluateMutationResult = Apollo.MutationResult<PatientGuidedCareProgramEvaluateMutation>;
export type PatientGuidedCareProgramEvaluateMutationOptions = Apollo.BaseMutationOptions<PatientGuidedCareProgramEvaluateMutation, PatientGuidedCareProgramEvaluateMutationVariables>;