import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistUpdateMutationVariables = Types.Exact<{
  subList?: Types.InputMaybe<Types.SubListInput>;
}>;


export type SublistUpdateMutation = { __typename?: 'Mutation', updateSubList?: { __typename?: 'SubList', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const SublistUpdateDocument = gql`
    mutation SublistUpdate($subList: SubListInput) {
  updateSubList(subList: $subList) {
    errors {
      field
      message
    }
  }
}
    `;
export type SublistUpdateMutationFn = Apollo.MutationFunction<SublistUpdateMutation, SublistUpdateMutationVariables>;

/**
 * __useSublistUpdateMutation__
 *
 * To run a mutation, you first call `useSublistUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistUpdateMutation, { data, loading, error }] = useSublistUpdateMutation({
 *   variables: {
 *      subList: // value for 'subList'
 *   },
 * });
 */
export function useSublistUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SublistUpdateMutation, SublistUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SublistUpdateMutation, SublistUpdateMutationVariables>(SublistUpdateDocument, options);
      }
export type SublistUpdateMutationHookResult = ReturnType<typeof useSublistUpdateMutation>;
export type SublistUpdateMutationResult = Apollo.MutationResult<SublistUpdateMutation>;
export type SublistUpdateMutationOptions = Apollo.BaseMutationOptions<SublistUpdateMutation, SublistUpdateMutationVariables>;