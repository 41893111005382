import { BACKEND_DATE_FORMAT, useCustomFormContext } from "@toolkit/core";
import { FormDatePickerField, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment/moment";
import { IUserProviderUpsertForm } from "../UserProviderUpsert/UserProviderUpsertFormSchema";

export const UserProviderDoctorDetailsQualificationsInformationForm = () => {
  const { t } = useTranslation("domains");

  const form = useCustomFormContext<IUserProviderUpsertForm>();

  const qualificationFromDate = form.watch("doctorInfo.qualifications.fromDate");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormTextField name={"doctorInfo.qualifications.code"} label={t("Code")} placeholder={t("Code")} />
          </Grid>

          <Grid item xs={4}>
            <FormTextField name={"doctorInfo.qualifications.issuer"} label={t("Issuer")} placeholder={t("Issuer")} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormDatePickerField
              name={"doctorInfo.qualifications.fromDate"}
              label={t("From Date")}
              placeholder={t("From Date")}
              format={BACKEND_DATE_FORMAT}
            />
          </Grid>

          <Grid item xs={4}>
            <FormDatePickerField
              name={"doctorInfo.qualifications.toDate"}
              label={t("To Date")}
              placeholder={t("To Date")}
              format={BACKEND_DATE_FORMAT}
              datePickerProps={{
                minDate: qualificationFromDate ? moment(qualificationFromDate, BACKEND_DATE_FORMAT).add(1, "days") : undefined,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
