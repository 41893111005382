import { Grid, Typography, useTheme } from "@toolkit/ui";
import React, { FC, ReactNode } from "react";

export const HeaderCell: FC<{ icon?: ReactNode; title?: ReactNode }> = ({ icon, title }) => {
  const { palette, mixins }: { palette: any; mixins: any } = useTheme();
  return (
    <Grid item flex={1} container direction={"column"} alignItems={"center"}>
      {title && (
        <Grid item marginRight={1} display='flex' marginLeft={mixins.fonts.fontSize.md}>
          {icon}
          <Typography sx={{ width: "max-content" }} color={palette.primary.main} fontWeight={"bold"} fontSize={mixins.fonts.fontSize.sm}>
            {title}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

HeaderCell.defaultProps = {
  title: null,
  icon: null,
};
