import { ProcessingStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { ApproveIcon, CustomIcon, MenuItem, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useReviewCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const ApproveRejectButtons: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { versionParamId, refetchCodeSystem } = useAdminManagedListDetailsPageContext();
  const [reviewCodeSystem, { loading }] = useReviewCodeSystemMutation();
  const { t } = useTranslation("domains");

  const handleSubmit = (status: ProcessingStatus) => {
    onClick();
    reviewCodeSystem({
      variables: {
        codeSystemId: versionParamId as string,
        processingStatus: status,
      },
    }).then(() => {
      // To Do Handle with cache
      refetchCodeSystem?.();
    });
  };
  const handleReject = () => {
    handleSubmit(ProcessingStatus.Rejected);
  };

  const handleApprove = () => {
    handleSubmit(ProcessingStatus.Approved);
  };
  return (
    <>
      <MenuItem disabled={loading} onClick={handleReject}>
        <CustomIcon icon='x' viewBox='0 0 20 20' />
        <Typography marginLeft='5px'> {t("Reject")}</Typography>
      </MenuItem>
      <MenuItem disabled={loading} onClick={handleApprove}>
        <ApproveIcon color='inherit' />
        <Typography marginLeft='5px'> {t("Approve")}</Typography>
      </MenuItem>
    </>
  );
};
