import { CodeSystemConcept, Exact, Type } from "@/schema/types";
import { QueryResult } from "@apollo/client";
import { Box, CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { mapStringValueToBackendFieldType } from "../EditAdminManagedListDetails/utils";
import { GetCodeSystemQuery } from "../gql";
import { getConceptPropertyValue, getFilterValuePropertyName, getInputType } from "./utils";

type codeSystemConceptFieldsFromQuery = NonNullable<
  NonNullable<
    NonNullable<
      QueryResult<
        GetCodeSystemQuery,
        Exact<{
          id: string;
        }>
      >["data"]
    >["getCodeSystem"]
  >["codeSystemDefinition"]
>["codeSystemConceptFields"];
export function useMapConceptDefinitionsToDataGridColumns(
  codeSystemConceptFields: codeSystemConceptFieldsFromQuery
): CustomTableColumnProps<CodeSystemConcept>[] {
  return useMemo(() => {
    return (
      codeSystemConceptFields?.map<CustomTableColumnProps<CodeSystemConcept>>(item => {
        const type = getInputType(item);
        const isBooleanField = item?.fieldType! === Type.Boolean;
        const booleanFieldFilterOptions = !isBooleanField
          ? {}
          : {
              options: ["Yes", "No"],
              getOptionLabel: o => o,
            };
        return {
          key: item?.code!,
          header: item?.display,
          accessor: ({ properties }) => {
            const itemData = properties?.find(row => row?.code == item?.code);
            return itemData ? (
              <Box component='div' sx={{ width: "max-content" }}>
                {getConceptPropertyValue(itemData)}
              </Box>
            ) : undefined;
          },
          filter: {
            name: item?.code!,
            accessor: item?.code!,
            type,
            ...booleanFieldFilterOptions,

            getValueForBackend: o => {
              if (!o || (typeof o === "string" && o?.trim().length === 0)) return undefined;
              return {
                code: item?.code,
                type: item?.fieldType,
                [getFilterValuePropertyName(item?.fieldType)]: mapStringValueToBackendFieldType(item?.fieldType!, o),
              };
            },
          } as CustomTableColumnProps<CodeSystemConcept>["filter"],
        };
      }) ?? []
    );
  }, [JSON.stringify(codeSystemConceptFields || "{}")]);
}
