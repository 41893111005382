import { Branch, BranchInput, CodeSystemConceptProperty, Maybe, Type, VendorBranchTypeEnum } from "@/schema/types";
import { vendorTypeOptionsMap } from "@health/enum-options";
import { convertAddressFormValuesToBackEndValues, convertAddressToFormValues } from "@/pages/Addresses";
import { convertDaysTimesRangesFormValuesToBackEndValues, convertDaysTimesRangesToFormValues } from "../../../utils";
import { IBranchUpsertForm } from "../forms/BranchUpsert/BranchUpsertFormSchema";
import { IBranchLicenseIdValues } from "../types";

export const convertBranchToFormValues = (branch: Branch): IBranchUpsertForm => {
  return {
    name: branch?.name,
    nameAr: branch?.nameAr!,
    type: vendorTypeOptionsMap[branch?.type!],
    healthLicenseNumber: branch?.healthLicense,
    healthLicenseStartDate: branch?.healthLicenseStartDate,
    healthLicenseEndDate: branch?.healthLicenseEndDate,
    mobileNumber: branch?.contactNumber!,
    email: branch?.contactEmail!,
    description: branch?.description,
    isActive: branch?.isActive,
    acceptsDelivery: branch?.acceptsDelivery,
    workingHours: branch?.workingHours?.length ? convertDaysTimesRangesToFormValues(branch?.workingHours) : [],
    ...convertAddressToFormValues(branch?.address)!,
  };
};

export const convertBranchFormValuesToBackEndValues = (values: IBranchUpsertForm): BranchInput => {
  return {
    name: values?.name,
    nameAr: values?.nameAr,
    type: values?.type?.value as unknown as VendorBranchTypeEnum,
    healthLicense: values?.healthLicenseNumber,
    healthLicenseStartDate: values?.healthLicenseStartDate,
    healthLicenseEndDate: values?.healthLicenseEndDate,
    contactEmail: values?.email,
    contactNumber: values?.mobileNumber,
    description: values?.description,
    isActive: values?.isActive,
    acceptsDelivery: values?.acceptsDelivery,
    workingHours: convertDaysTimesRangesFormValuesToBackEndValues(values?.workingHours),
    addressInput: convertAddressFormValuesToBackEndValues(values),
  };
};

export const convertCodeSystemConceptPropertiesToFormValues = (properties: Maybe<CodeSystemConceptProperty>[]) => {
  const codeSystemConceptMapperValue = Object.fromEntries(properties?.map(item => [item?.code, getCodeSystemConceptMapperValue(item)]));

  return convertBranchLicenseIdToFormValues(codeSystemConceptMapperValue);
};

export const getCodeSystemConceptMapperValue = (item: Maybe<CodeSystemConceptProperty>) => {
  const fieldType = item?.fieldType;

  if (fieldType === Type.String) {
    return item?.valueString;
  } else if (fieldType === Type.Integer) {
    return item?.valueInteger;
  } else if (fieldType === Type.Float) {
    return item?.valueFloat;
  } else if (fieldType === Type.Boolean) {
    return item?.valueBoolean;
  } else if (fieldType === Type.Date) {
    return item?.valueDate;
  } else {
    return "";
  }
};

export const convertBranchLicenseIdToFormValues = (values: IBranchLicenseIdValues): Partial<IBranchUpsertForm> => {
  return {
    name: values?.display,
    nameAr: values?.arabic_name,
    healthLicenseNumber: values?.code,
    healthLicenseStartDate: values.license_start,
    healthLicenseEndDate: values.license_end,
    email: values.email,
    mobileNumber: values?.phone,
    streetAddress1: values.street!,
    streetAddress1Ar: values.street_arabic,
    area: values.area,
    coordinates: {
      lat: values?.latitude!,
      lng: values?.longitude!,
    },
  };
};
