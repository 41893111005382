import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { usePayersAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IPayerAutocomplete } from "./schema";

export const createPayerAutocompleteOption = (payer: IPayerAutocomplete) => {
  return createAutocompleteOption(
    {
      id: payer?.id!,
      name: payer?.name!,
      nameAr: payer?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getPayersAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "payers",
    query: usePayersAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name, item?.nameAr!),
    backendAccessor: "id",
    filtersForQuery: {
      isActive: true,
    },
  });
};
