import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useMarketplaceHealthPackageCategoriesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IMarketplaceHealthPackageCategoryAutocomplete } from "./schema";

export const createMarketplaceHealthPackageCategoryAutocompleteOption = (category: IMarketplaceHealthPackageCategoryAutocomplete) => {
  return createAutocompleteOption(
    {
      id: category?.id!,
      name: category?.name!,
      nameAr: category?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getMarketplaceHealthPackageCategoriesAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "marketplaceHealthPackageCategories",
    query: useMarketplaceHealthPackageCategoriesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name!, item?.nameAr!),
    backendAccessor: "id",
    filterSearchKey: "nameContains",
  });
};
