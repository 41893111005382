import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { MarketplaceHealthPackageCategoriesAutocompleteQuery } from "./gql";

export type IMarketplaceHealthPackageCategoryAutocomplete = ExtractNodeType<MarketplaceHealthPackageCategoriesAutocompleteQuery>;

export const marketplaceHealthPackageCategorySchema = createZodAutocompleteFromType<IMarketplaceHealthPackageCategoryAutocomplete>({
  id: z.string(),
  name: z.string().nullish(),
  nameAr: z.string().nullish(),
});
