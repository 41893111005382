import { GridProvider } from "@health/domains";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { usePatientEnrollmentRequestsListQuery } from "../../gql";
import { useSetPatientEnrollmentRequestsBreadcrumbs } from "../../hooks";
import { usePatientEnrollmentRequestsListContainerColumns } from "./usePatientEnrollmentRequestsListContainerColumns";

export const PatientEnrollmentRequestsListContainer = () => {
  useSetPatientEnrollmentRequestsBreadcrumbs("LIST");

  return (
    <GridProvider
      hasTableSetting
      gridName={"patientEnrollmentRequests"}
      query={usePatientEnrollmentRequestsListQuery}
      columns={usePatientEnrollmentRequestsListContainerColumns()}
      skipUrlState={false}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
