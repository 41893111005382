import { DecisionPlanCategory } from "@/schema/types";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DecisionUpdate, IDecisionUpdateEvent } from "@/pages/Decisions/components/DecisionUpdate/DecisionUpdate";
import { IDecisionUpdateContainerParams } from "../../types";

type DecisionUpdateContainerProps = {
  category: DecisionPlanCategory;
  healthParameterCode?: string;
  guidedCareTemplateCode?: string;
  ioAgentCode?: string;
};

export const DecisionUpdateContainer: FC<DecisionUpdateContainerProps> = props => {
  const { category, healthParameterCode, guidedCareTemplateCode, ioAgentCode } = props;

  const navigate = useNavigate();

  const { id } = useParams<IDecisionUpdateContainerParams>();

  const onDecisionUpdateChange = (event: IDecisionUpdateEvent) => {
    if (event.type === "UPDATE") {
      navigate(-1);
    }
  };

  return (
    <DecisionUpdate
      decisionId={id!}
      category={category}
      healthParameterCode={healthParameterCode}
      guidedCareTemplateCode={guidedCareTemplateCode}
      ioAgentCode={ioAgentCode}
      onChange={onDecisionUpdateChange}
    />
  );
};
