import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useCustomerSupportErxOrderStatusUpdateMutation } from "@/pages/OrdersTracking/gql/mutations";

export const useHandleOrder = (reset, id?: string) => {
  const { t } = useTranslation("domains");

  const { succeeded, failed } = useAddToast();

  const { open: isOpen, handleOpen: handleOpenState, handleClose, handleToggle } = useOpenState();

  const [handelOrder, { loading }] = useCustomerSupportErxOrderStatusUpdateMutation({
    onCompleted: () => {
      succeeded(t("Order handled successfully"));
      handleClose();
      reset();
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });

  const handleOpen = e => {
    e.stopPropagation();
    handleOpenState();
  };

  const handleUpdate = inputData => {
    handelOrder({
      variables: {
        customerSupportErxOrderStatusUpdateId: id as string,
        status: inputData?.status?.value,
      },
    });
  };
  return { handleUpdate, loading, handleToggleDialog: handleToggle, handleOpen, isOpen };
};
