import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramsAutocompleteQuery } from "./gql";
import { IHealthProgramAutocomplete } from "./schema";

export const createHealthProgramAutocompleteOption = (program: IHealthProgramAutocomplete) => {
  return createAutocompleteOption({ id: program?.id!, name: program?.name! }, "id", "name");
};

export const getHealthProgramsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "healthPrograms",
    query: useHealthProgramsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
