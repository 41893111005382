import { ItemCodeMaximumQuantityAllowedSortingField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IItemCodeMaximumQuantityAllowedListNode } from "../../types";
import { getDiscrptionsInfoItems } from "@/pages/OptimaMedicalNecessity/others/cellInfo.utils";

export const useItemCodeMaximumQuantityAllowedListContainerColumns =
  (): CustomTableColumnProps<IItemCodeMaximumQuantityAllowedListNode>[] => {
    const { t } = useTranslation("admin");

    return useMemo(
      () => [
        {
          key: "CodeSystem",
          header: t("Code System"),
          accessor: "codeSystem",
          sort: {
            columnEnum: ItemCodeMaximumQuantityAllowedSortingField.CodeSystem,
          },
          filter: {
            type: "string",
            name: "codeSystem",
          },
        },
        {
          key: "ItemCode",
          header: t("Item Code"),
          accessor: "itemCode",
          sort: {
            columnEnum: ItemCodeMaximumQuantityAllowedSortingField.ItemCode,
          },
          filter: {
            type: "string",
            name: "itemCode",
          },
        },
        {
          key: "maximumAllowed",
          header: t("Maximum Allowed"),
          accessor: "maximumAllowed",
          sort: {
            columnEnum: ItemCodeMaximumQuantityAllowedSortingField.MaximumAllowed,
          },
        },
        {
          key: "maximumAllowedMinFilter",
          header: t("Maximum Allowed From"),
          showOnlyForFilterField: true,
          filter: {
            type: "number",
            name: "maximumAllowedGTE",
          },
        },
        {
          key: "maximumAllowedMaxFilter",
          header: t("Maximum Allowed To"),
          showOnlyForFilterField: true,
          filter: {
            type: "number",
            name: "maximumAllowedLTE",
          },
        },
        {
          key: "description",
          header: t("Description"),
          type: "info",
          infoCellOptions: {
            title: t("Description"),
            items: ({ description }) => getDiscrptionsInfoItems(description),
            disableShowButton: ({ description }) => !description?.length,
          },
        },
      ],
      [t]
    );
  };
