import { useCustomFormContext } from "@toolkit/core";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { DaysTimesRanges, DaysTimesRangesProps, IDaysTimesRanges } from "../DaysTimesRanges/DaysTimesRanges";

type FormDaysTimeRangesProps = Omit<DaysTimesRangesProps, "value" | "onChange"> & {
  name: string;
};

export const FormDaysTimesRanges: FC<FormDaysTimeRangesProps> = props => {
  const { name, ...rest } = props;

  const form = useCustomFormContext();

  const { control, setValue } = form;

  const onDaysTimesRangesChange = (value: IDaysTimesRanges[]) => {
    setValue(name, value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <DaysTimesRanges {...rest} value={field.value} onChange={onDaysTimesRangesChange} />}
    />
  );
};
