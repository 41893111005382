import { IOptimaEditUpdateContainerEvent, OptimaEditUpdateContainer } from "@health/domains";
import { useNavigate } from "react-router-dom";

export const AdminOptimaEditUpdateContainer = () => {
  const navigate = useNavigate();

  const onOptimaEditUpdateContainerChange = (event: IOptimaEditUpdateContainerEvent) => {
    if (event.type === "SUBMIT_SUCCESS") {
      navigate(-1);
    }
  };

  return <OptimaEditUpdateContainer onChange={onOptimaEditUpdateContainerChange} />;
};
