import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useNavigate, useParams } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { Group } from "@/schema/types";
import { useUsersPermissionsGroupGetQuery, useUserPermissionGroupUpdateMutation } from "../../gql";
import { convertUserPermissionGroupUpdateFormValuesToBackEndValues } from "../../others";
import { IUserPermissionGroupUpdateContainerParams } from "../../types";
import { useSetUsersPermissionsGroupsBreadcrumbs } from "../../hooks";
import {
  UserPermissionGroupUpsertForm,
  IUserPermissionGroupUpsertFormEvent,
} from "../../forms/UserPermissionGroupUpsert/UserPermissionGroupUpsertForm";

export const UserPermissionGroupUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { permissionGroupId } = useParams<IUserPermissionGroupUpdateContainerParams>();

  const { data, loading } = useUsersPermissionsGroupGetQuery({
    variables: { id: permissionGroupId! },
    skip: !permissionGroupId,
    fetchPolicy: "no-cache",
  });

  const permissionGroup = data?.permissionGroup as Group;

  const [updateUserPermissionGroup, { loading: isSubmitting }] = useUserPermissionGroupUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.permissionGroupUpdate?.permissionGroupErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Permission Group updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onUserPermissionGroupUpsertFormChange = (event: IUserPermissionGroupUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertUserPermissionGroupUpdateFormValuesToBackEndValues(event.payload.values, permissionGroup?.permissions);

      updateUserPermissionGroup({
        variables: {
          id: permissionGroupId!,
          input: values,
        },
      });
    }
  };

  useSetUsersPermissionsGroupsBreadcrumbs("UPDATE", permissionGroup?.name);

  return (
    <UserPermissionGroupUpsertForm
      buttonLabel={t("Update")}
      permissionGroup={permissionGroup}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onUserPermissionGroupUpsertFormChange}
    />
  );
};
