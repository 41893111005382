import { QuestionType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, TextField, useIsMobileView } from "@toolkit/ui";
import { FC, useEffect, useState } from "react";
import { OptionItemIcon } from "../../components/OptionItemIcon/OptionItemIcon";
import { ISurveyQuestionFormValues } from "../SurveyQuestions/SurveyQuestionFormSchema";

const AddSurveyQuestionOptionForm: FC<{
  questionType?: QuestionType;
  allOptions: ISurveyQuestionFormValues["options"];
  onAddOption: (option: string) => void;
  copiedValue?: string | null;
}> = ({ allOptions, questionType, copiedValue, onAddOption }) => {
  const { t } = useTranslation("admin");
  const [error, setError] = useState("");
  const [value, setValue] = useState("");
  const isMobileView = useIsMobileView();

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (!newValue) {
      setError("");
    }
  };

  const handleAddOption = () => {
    if (!value) {
      return;
    }

    if (!allOptions?.map(val => val.value.trim())?.includes(value.trim())) {
      onAddOption(value);
      setValue("");
      setError("");
    } else {
      setError(t("Option Already Exist"));
    }
  };

  useEffect(() => {
    if (copiedValue) {
      setValue(copiedValue);
    }
  }, [copiedValue]);

  return (
    <Grid container alignItems='center' flexDirection='row' flexWrap={isMobileView ? "nowrap" : "wrap"} paddingRight='0px !important'>
      <Grid item sx={{ marginInlineEnd: 1 }}>
        <OptionItemIcon type={questionType} isChecked={false} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          value={value}
          placeholder={t("Add new option")}
          label={t("Option")}
          onChange={handleValueChange}
          error={!!error}
          helperText={error}
        />
      </Grid>
      <Grid item xs={4} alignSelf='flex-start' container alignItems='center'>
        <Button disabled={!value || !questionType} onClick={handleAddOption} sx={{ display: "flex", height: 50, whiteSpace: "nowrap" }}>
          + {t("Add Option")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddSurveyQuestionOptionForm;
