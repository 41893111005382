import { i18n } from "@toolkit/i18n";
import { MedicalMessageCategory } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const medicalMessageCategoryOptionsMap: IEnumToOptionsMap<MedicalMessageCategory> = {
  [MedicalMessageCategory.Instruction]: {
    key: MedicalMessageCategory.Instruction,
    get label() {
      return i18n.t("Instruction", { ns: "domains" });
    },
    value: MedicalMessageCategory.Instruction,
  },
  [MedicalMessageCategory.General]: {
    key: MedicalMessageCategory.General,
    get label() {
      return i18n.t("General", { ns: "domains" });
    },
    value: MedicalMessageCategory.General,
  },
  [MedicalMessageCategory.Reminder]: {
    key: MedicalMessageCategory.Reminder,
    get label() {
      return i18n.t("Reminder", { ns: "domains" });
    },
    value: MedicalMessageCategory.Reminder,
  },
  [MedicalMessageCategory.Medical]: {
    key: MedicalMessageCategory.Medical,
    get label() {
      return i18n.t("Medical", { ns: "domains" });
    },
    value: MedicalMessageCategory.Medical,
  },
  [MedicalMessageCategory.Monitoring]: {
    key: MedicalMessageCategory.Monitoring,
    get label() {
      return i18n.t("Monitoring", { ns: "domains" });
    },
    value: MedicalMessageCategory.Monitoring,
  },
};

export const medicalMessageCategoryOptions = Object.values(medicalMessageCategoryOptionsMap);
