import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportSubListMutationVariables = Types.Exact<{
  subListId: Types.Scalars['ID']['input'];
  file: Types.Scalars['String']['input'];
}>;


export type ImportSubListMutation = { __typename?: 'Mutation', result?: { __typename?: 'SubList', display?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', message?: string | null, field?: string | null } | null> | null } | null };


export const ImportSubListDocument = gql`
    mutation importSubList($subListId: ID!, $file: String!) {
  result: uploadSubList(fileName: $file, subListId: $subListId) {
    display
    errors {
      message
      field
    }
  }
}
    `;
export type ImportSubListMutationFn = Apollo.MutationFunction<ImportSubListMutation, ImportSubListMutationVariables>;

/**
 * __useImportSubListMutation__
 *
 * To run a mutation, you first call `useImportSubListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSubListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSubListMutation, { data, loading, error }] = useImportSubListMutation({
 *   variables: {
 *      subListId: // value for 'subListId'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportSubListMutation(baseOptions?: Apollo.MutationHookOptions<ImportSubListMutation, ImportSubListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportSubListMutation, ImportSubListMutationVariables>(ImportSubListDocument, options);
      }
export type ImportSubListMutationHookResult = ReturnType<typeof useImportSubListMutation>;
export type ImportSubListMutationResult = Apollo.MutationResult<ImportSubListMutation>;
export type ImportSubListMutationOptions = Apollo.BaseMutationOptions<ImportSubListMutation, ImportSubListMutationVariables>;