/* eslint-disable max-statements */
import { useTranslation } from "@toolkit/i18n";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { filter, some } from "lodash";
import { Grid, Typography } from "../../../../base/mui";
import { ITransferListCardListEvent, TransferListCardList } from "../TransferListCardList/TransferListCardList";
import { ITransferListActionsEvent, TransferListActions } from "../TransferListActions/TransferListActions";

export type ITransferListOption<T> = {
  key: string;
  label: string;
  value: T;
};

export type TransferListProps<T> = {
  options: ITransferListOption<T>[];
  value: ITransferListOption<T>[];
  error?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  availableOptionsLabel?: string;
  selectedOptionsLabel?: string;
  onChange: (values: ITransferListOption<T>[]) => void;
};

export const TransferList = <T,>(props: TransferListProps<T>) => {
  const { options, value = [], error, isDisabled, isLoading, availableOptionsLabel, selectedOptionsLabel, onChange } = props;

  const [left, setLeft] = useState<ITransferListOption<T>[]>([]);
  const [right, setRight] = useState<ITransferListOption<T>[]>([]);
  const [leftChecked, setLeftChecked] = useState<ITransferListOption<T>[]>([]);
  const [rightChecked, setRightChecked] = useState<ITransferListOption<T>[]>([]);
  const [leftSearch, setLeftSearch] = useState("");
  const [rightSearch, setRightSearch] = useState("");

  const { t } = useTranslation();

  const filterItems = (items: ITransferListOption<T>[], search: string) => {
    return items?.filter(item => item?.label?.toLowerCase()?.includes(search?.toLowerCase()));
  };

  const handleToggle = (
    item: ITransferListOption<T>,
    checked: ITransferListOption<T>[],
    setChecked: Dispatch<SetStateAction<ITransferListOption<T>[]>>
  ) => {
    if (checked.some(checkedItem => checkedItem?.key === item?.key)) {
      setChecked(checked.filter(i => i?.key !== item?.key));
    } else {
      setChecked([...checked, item]);
    }
  };

  const onTransferListActionsChange = (event: ITransferListActionsEvent) => {
    let updatedLeft: ITransferListOption<T>[] = [];
    let updatedRight: ITransferListOption<T>[] = [];

    if (event.type === "ALL_RIGHT_CHECK") {
      updatedLeft = [];
      updatedRight = [...right, ...left];
    } else if (event.type === "RIGHT_CHECK") {
      updatedLeft = left?.filter(item => !leftChecked?.some(checkedItem => checkedItem?.key === item?.key));
      updatedRight = [...right, ...leftChecked];
      setLeftChecked([]);
    } else if (event.type === "LEFT_CHECK") {
      updatedLeft = [...left, ...rightChecked];
      updatedRight = right.filter(item => !rightChecked.some(checkedItem => checkedItem?.key === item?.key));
      setRightChecked([]);
    } else if (event.type === "ALL_LEFT_CHECK") {
      updatedLeft = [...left, ...right];
      updatedRight = [];
    }

    setRight(updatedRight);
    setLeft(updatedLeft);

    onChange(updatedRight);
  };

  const onTransferListCardListChange = (
    event: ITransferListCardListEvent<T>,
    setSearch: Dispatch<SetStateAction<string>>,
    checked: ITransferListOption<T>[],
    setChecked: Dispatch<SetStateAction<ITransferListOption<T>[]>>
  ) => {
    if (event.type === "SEARCH") {
      setSearch(event.payload.value);
    } else if (event.type === "CHECKBOX_CLICK") {
      handleToggle(event.payload.item, checked, setChecked);
    }
  };

  useEffect(() => {
    if (options?.length) {
      if (value?.length) {
        setLeft(filter(options, option => !some(value, { key: option?.key })));
        setRight(value);
      } else {
        setLeft(options);
      }
    }
  }, [options, value]);

  return (
    <Grid container spacing={4} alignItems={"center"}>
      {error && (
        <Grid item xs={12}>
          <Typography color={"error"} variant={"caption"}>
            {error}
          </Typography>
        </Grid>
      )}

      <Grid item xs={5.5}>
        <TransferListCardList
          title={availableOptionsLabel || t("Available Options")}
          options={filterItems(left, leftSearch)}
          checkedOptions={leftChecked}
          searchValue={leftSearch}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onChange={event => onTransferListCardListChange(event, setLeftSearch, leftChecked, setLeftChecked)}
        />
      </Grid>

      <Grid item xs={1}>
        <Grid container spacing={2} direction={"column"} alignItems={"center"}>
          <TransferListActions
            left={left}
            right={right}
            leftChecked={leftChecked}
            rightChecked={rightChecked}
            isDisabled={isDisabled}
            isLoading={isLoading}
            onChange={onTransferListActionsChange}
          />
        </Grid>
      </Grid>

      <Grid item xs={5.5}>
        <TransferListCardList
          title={selectedOptionsLabel || t("Selected Options")}
          options={filterItems(right, rightSearch)}
          checkedOptions={rightChecked}
          searchValue={rightSearch}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onChange={event => onTransferListCardListChange(event, setRightSearch, rightChecked, setRightChecked)}
        />
      </Grid>
    </Grid>
  );
};
