import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import { isNil } from "lodash-es";
import { z } from "zod";

export const bulkMessageSearchCriteriaFormSchema = z
  .object({
    fromAge: z.number().int().min(0).max(120).nullish(),
    toAge: z.number().int().min(0).max(120).nullish(),
    gender: zodEnumSchema.userGender.nullish(),
    chronicDiseases: z.array(zodSchema.systemCode).nullish(),
  })
  .superRefine((data, ctx) => {
    if (!isNil(data.fromAge) && !isNil(data.toAge) && data.toAge < data.fromAge) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("toAge must be greater than or equal to fromAge.", { ns: "admin" }),
        path: ["toAge"],
      });
    }
  });

export type IBulkMessageSearchCriteriaFormValues = z.infer<typeof bulkMessageSearchCriteriaFormSchema>;

export const bulkMessageSearchCriteriaFormDefaultValues: Partial<IBulkMessageSearchCriteriaFormValues> = {
  fromAge: 0,
  toAge: 120,
  gender: undefined,
  chronicDiseases: [],
};
