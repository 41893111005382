import { CustomDialog } from "@toolkit/ui";
import { cloneElement, FC, ReactElement } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  PatientGuidedCareProgramMemberAddForm,
  PatientGuidedCareProgramMemberAddFormProps,
} from "pages/Patients/forms/PatientGuidedCareProgramMemberAdd/PatientGuidedCareProgramMemberAddForm";
import { useOpenState } from "@toolkit/core";

type GuidedCareProgramMemberAddModalProps = PatientGuidedCareProgramMemberAddFormProps & {
  button?: ReactElement;
  onChange?: () => void;
};

export const PatientGuidedCareProgramMemberAddModal: FC<GuidedCareProgramMemberAddModalProps> = props => {
  const { button, onChange, ...rest } = props;

  const { t } = useTranslation("domains");

  const { open, handleToggle } = useOpenState();

  const onPatientGuidedCareProgramMemberAddFormChange = () => {
    onChange?.();
    handleToggle();
  };

  return (
    <CustomDialog
      type={"base"}
      open={open}
      button={button ? cloneElement(button, { onClick: handleToggle }) : undefined}
      maxWidth={"sm"}
      DialogTitleProps={{
        title: t("Add Patient to Program"),
        onClose: handleToggle,
      }}
    >
      <PatientGuidedCareProgramMemberAddForm {...rest} onChange={onPatientGuidedCareProgramMemberAddFormChange} />
    </CustomDialog>
  );
};
