/* eslint-disable react/prop-types */
import { CodeSystemCode, MarketplaceApprovalStatus, Type } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, IconButton, useAddToast, XIcon } from "@toolkit/ui";
import { ProductRejectMutation, useProductRejectMutation } from "pages/MarketplaceProducts/gql/mutations";
import { useGetCodeSystemConceptSearchQuery } from "pages/MarketplaceProducts/gql/queries";
import { useState } from "react";
import { ReasonsForm } from "./ReasonsForm/ReasonForm";

export const ProductRejectionModal: React.FC<{
  productID: string;
  approvalStatus?: MarketplaceApprovalStatus;
  isProductUpdatable?: boolean | null;
}> = ({ productID, approvalStatus, isProductUpdatable }) => {
  const [value, setValue] = useState({ value: "", isThereValue: false });
  const { failed, succeeded } = useAddToast();
  const { handleToggle, open } = useOpenState();
  const { t } = useTranslation("admin");
  const { data, loading: isLoading } = useGetCodeSystemConceptSearchQuery({
    variables: {
      keyword: "",
      first: 10,
      codeSystemCode: CodeSystemCode.RejectionReason,
      searchParameters: [{ valueString: "marketplace", code: "Type", type: Type.String }],
    },
    skip: !open,
  });
  const reasons =
    data?.codeSystemConceptSearch?.edges?.map(item => Object.create({ label: item?.node?.display, value: item?.node?.display })) || [];

  const [productRejectMutation, { loading: isSubmitting }] = useProductRejectMutation({
    onCompleted: (data: ProductRejectMutation) => {
      if (data?.marketplaceProductReject?.approvalStatus === MarketplaceApprovalStatus.Rejected) {
        handleToggle();
        succeeded(t("Product rejected successfully!"));
      } else {
        failed(t("Failed to reject product!"));
      }
    },
  });
  const handleApproveProduct = () => {
    productRejectMutation({
      variables: {
        marketplaceProductRejectId: productID,
        rejectionReason: value?.value,
      },
    });
  };
  const onReasonChange = (reason, isThereValue) => {
    setValue({ value: reason, isThereValue: isThereValue });
  };
  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Reject Product"),
        onClose: handleToggle,
      }}
      maxWidth='sm'
      button={
        <IconButton
          onClick={handleToggle}
          disabled={MarketplaceApprovalStatus.PendingApproval !== approvalStatus || !isProductUpdatable}
          sx={{
            "& svg": {
              width: "19px",
              height: "19px",
            },
          }}
        >
          <XIcon />
        </IconButton>
      }
      DialogActionsProps={{
        children: (
          <Button disabled={!value?.isThereValue || isSubmitting || isLoading} onClick={handleApproveProduct} color='error'>
            {t("Reject")}
          </Button>
        ),
      }}
    >
      <ReasonsForm reasons={reasons} isLoading={isLoading} onChange={onReasonChange} />
    </CustomDialog>
  );
};
