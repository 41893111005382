import { makeStyles } from "../../../../base/mui";

export const useInfoCellStyles = makeStyles()(theme => ({
  groupTitle: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.bold,
    marginBottom: theme.spacing(2),
  },
  groupContainer: {
    display: "grid",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr))",
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  value: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  arrayValueInline: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  arrayValueStacked: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  chip: {
    backgroundColor: theme.palette.primary[50],
    padding: 0,
    color: theme.palette.primary.main,
    height: "30px",
    dispay: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
    paddingInline: "10px",
    display: "inline-flex",
  },
  mutilinesString: {
    whiteSpace: "pre-wrap",
    fontSize: theme.mixins.fonts.fontSize.lg,
    lineHeight: 2,
  },
  valueContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  statusChip: {
    display: "inline-flex",
    alignItems: "center",
    padding: theme.spacing(0.25, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.mixins.fonts.fontSize.sm,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  secondaryText: {
    color: theme.palette.text.secondary,
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  list: {
    listStyleType: "disc",
    paddingInline: 24,
    fontSize: theme.mixins.fonts.fontSize.xxl,
  },
  listItem: {
    display: "list-item",
    paddingLeft: 0,
    paddingBlock: 0,
  },
  listItemText: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  listItemContent: {
    display: "flex",
    flexDirection: "column",
  },
  groupHighlight: {
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  groupBordered: {
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  groupCompact: {
    "& $groupContainer": {
      gap: theme.spacing(1),
    },
  },
  accordion: {
    "&:before": {
      display: "none",
    },
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  accordionSummary: {
    padding: 0,
    minHeight: "auto",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: theme.spacing(2, 0),
  },
  expandIcon: {
    marginRight: theme.spacing(1),
  },
  oneColumn: {
    gridTemplateColumns: "1fr",
  },
  twoColumns: {
    gridTemplateColumns: "repeat(2, 1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  threeColumns: {
    gridTemplateColumns: "repeat(3, 1fr)",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  statusSuccess: {
    color: theme.palette.success.main,
  },
  statusWarning: {
    color: theme.palette.warning.main,
  },
  statusError: {
    color: theme.palette.error.main,
  },
  statusInfo: {
    color: theme.palette.info.main,
  },
}));
