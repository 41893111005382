import { Typography } from "../../../../base/mui";
import { InformationCell } from "../InformationCell";
import { RedirectCell } from "../RedirectButtonCell";
import { CustomTableColumnProps } from "../../types";
import { TruncateTypography } from "../../../TruncateTypography";
import { DEFAULT_CELL_FALLBACK } from "../../constants";
import { useTableCellRendererStyles } from "./useTableCellRenderer.styless";
import { getRowValue } from "../../utils";
import { isNumber, isString } from "lodash";

export type TableCellRendererProps<RowType extends object> = {
  row: RowType;
  column: CustomTableColumnProps<RowType>;
  index: number;
};

export const TableCellRenderer = <RowType extends object>({ row, column, index }: TableCellRendererProps<RowType>) => {
  const { classes, cx } = useTableCellRendererStyles();
  const rawValue = getRowValue({ row, column, index });
  const isDate = column.type && ["date", "datetime", "time"].includes(column.type);

  if (column.type === "redirect" && column.redirectCellOptions) {
    return <RedirectCell row={row} {...column.redirectCellOptions} onClick={column.redirectCellOptions?.onClick ?? (() => {})} />;
  }

  if (column.type === "info" && column.infoCellOptions) {
    return (
      <InformationCell
        row={row}
        {...column.infoCellOptions}
        title={column.infoCellOptions?.title ?? (typeof column.header === "string" ? column.header : DEFAULT_CELL_FALLBACK)}
      />
    );
  }

  if (column.type === "truncated-text" && isString(rawValue)) {
    return <TruncateTypography className={classes.commonText} text={rawValue} />;
  }
  if (column.type === "mobile") {
    return <Typography className={classes.mobile}>{rawValue}</Typography>;
  }

  if (isDate) {
    return <Typography className={cx(classes.commonText, classes.dateCell)}>{rawValue}</Typography>;
  }

  if (isString(rawValue) || isNumber(rawValue))
    return (
      <Typography component='span' className={cx(classes.commonText, classes.typographyCell)}>
        {rawValue}
      </Typography>
    );
  return rawValue;
};
