import React, { FC, useEffect } from "react";
import { useCustomForm } from "@toolkit/core";
import { AppRoleTypes, AppTypes, User } from "@/schema/types";
import { PermissionsGroupsAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { convertUserAdminToFormValues } from "../../others";
import { UserAdminInformationForm } from "../UserAdminInformation/UserAdminInformationForm";
import { userAdminUpsertFormSchema, userAdminUpsertFormDefaultValues, IUserAdminUpsertFormValues } from "./UserAdminUpsertFormSchema";

export type IUserAdminUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IUserAdminUpsertFormValues;
  };
};

type UserAdminUpsertFormProps = {
  user?: User;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IUserAdminUpsertFormEvent) => void;
};

export const UserAdminUpsertForm: FC<UserAdminUpsertFormProps> = props => {
  const { buttonLabel, user, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IUserAdminUpsertFormValues>({
    defaultValues: userAdminUpsertFormDefaultValues,
    schema: userAdminUpsertFormSchema(!!user),
  });

  const { watch, handleSubmit, setValues } = form;

  const appRole = watch("appRole")?.value;
  const isAppRoleUser = appRole && appRole === AppRoleTypes.User;

  const onSubmit = (values: IUserAdminUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (user) {
      const _user = convertUserAdminToFormValues(user);
      setValues(_user);
    }
  }, [user, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("User Information")} loading={isLoading} doYouHaveData>
                <UserAdminInformationForm isUpdateMode={!!user} />
              </FormCard>
            </Grid>

            {isAppRoleUser && (
              <Grid item xs={12}>
                <FormCard title={t("User Permissions")} loading={isLoading} doYouHaveData>
                  <PermissionsGroupsAutocomplete name={"permissionsGroups"} multiple filter={{ appType: AppTypes.Admin }} />
                </FormCard>
              </Grid>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
