import { CptHcpcPrimaryProcedureSortingField, SortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { cptHcpcPrimaryProcedurePaths } from "../../constants";
import { useCptHcpcPrimaryProcedureDeleteMutation } from "../../gql/mutations/__generated__/CptHcpcPrimaryProcedureDelete";
import { useCptHcpcPrimaryProcedureListQuery } from "../../gql/queries/__generated__/CptHcpcPrimaryProcedureList";
import { useSetCptHcpcPrimaryProcedureBreadcrumbs } from "../../hooks/useSetCptHcpcPrimaryProceduresBreadcrumbs";
import { ICptHcpcPrimaryProcedureListNode } from "../../types";
import { useCptHcpcPrimaryProcedureListContainerColumns } from "./useCptHcpcPrimaryProcedureListContainerColumns";

export const CptHcpcPrimaryProcedureListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(cptHcpcPrimaryProcedurePaths.create.fullPath);
  };

  const onEditRowClick = (item: ICptHcpcPrimaryProcedureListNode) => {
    navigate(cptHcpcPrimaryProcedurePaths.update.fullPathWithParams({ cptHcpcPrimaryProcedureId: item?.id }));
  };

  useSetCptHcpcPrimaryProcedureBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"cptHcpcPrimaryProcedureList"}
      query={useCptHcpcPrimaryProcedureListQuery}
      columns={useCptHcpcPrimaryProcedureListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: CptHcpcPrimaryProcedureSortingField.PrimaryProcedure,
          direction: SortDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "CptHcpcPrimaryProcedure",
        name: "CPT HCPC Primary Procedure",
        useDeleteMutation: useCptHcpcPrimaryProcedureDeleteMutation,
        idPropName: "deleteCptHcpcPrimaryProcedureId",
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
