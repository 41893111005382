import { z } from "zod";

export const optimaActionUpsertFormSchema = z.object({
  action: z.string().min(1).max(255),
  link: z.string().min(1).max(255),
  summary: z.string().min(1).max(255),
  isActive: z.boolean(),
});

export type IOptimaActionUpsertFormValues = z.infer<typeof optimaActionUpsertFormSchema>;

export const optimaActionUpsertFormDefaultValues: Partial<IOptimaActionUpsertFormValues> = {
  action: undefined,
  link: undefined,
  summary: undefined,
  isActive: false,
};
