import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";

export enum YesNo {
  Yes = "Yes",
  No = "No",
}

export const yesNoOptionsMap: IEnumToOptionsMap<YesNo> = {
  [YesNo.Yes]: {
    key: YesNo.Yes,
    get label() {
      return i18n.t("Yes", { ns: "domains" });
    },
    value: YesNo.Yes,
  },
  [YesNo.No]: {
    key: YesNo.No,
    get label() {
      return i18n.t("No", { ns: "domains" });
    },
    value: YesNo.No,
  },
};

export const yesNoOptions = Object.values(yesNoOptionsMap);
