import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaActionListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OptimaActionFilterInput>;
}>;


export type OptimaActionListQuery = { __typename?: 'Query', optimaActions?: { __typename?: 'OptimaActionConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges: Array<{ __typename?: 'OptimaActionEdge', node: { __typename?: 'OptimaAction', id: string, link?: string | null, action?: string | null, isActive?: boolean | null } }> } | null };


export const OptimaActionListDocument = gql`
    query OptimaActionList($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaActionFilterInput) {
  optimaActions(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        link
        action
        isActive
      }
    }
  }
}
    `;

/**
 * __useOptimaActionListQuery__
 *
 * To run a query within a React component, call `useOptimaActionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaActionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaActionListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOptimaActionListQuery(baseOptions?: Apollo.QueryHookOptions<OptimaActionListQuery, OptimaActionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaActionListQuery, OptimaActionListQueryVariables>(OptimaActionListDocument, options);
      }
export function useOptimaActionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaActionListQuery, OptimaActionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaActionListQuery, OptimaActionListQueryVariables>(OptimaActionListDocument, options);
        }
export function useOptimaActionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaActionListQuery, OptimaActionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaActionListQuery, OptimaActionListQueryVariables>(OptimaActionListDocument, options);
        }
export type OptimaActionListQueryHookResult = ReturnType<typeof useOptimaActionListQuery>;
export type OptimaActionListLazyQueryHookResult = ReturnType<typeof useOptimaActionListLazyQuery>;
export type OptimaActionListSuspenseQueryHookResult = ReturnType<typeof useOptimaActionListSuspenseQuery>;
export type OptimaActionListQueryResult = Apollo.QueryResult<OptimaActionListQuery, OptimaActionListQueryVariables>;