import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArticleCategoriesAutocompleteQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ArticleCategoryFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ArticleCategoriesAutocompleteQuery = { __typename?: 'Query', articleCategories?: { __typename?: 'ArticleCategoryCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'ArticleCategoryCountableEdge', node: { __typename?: 'ArticleCategory', id: string, code?: string | null, display?: string | null, displayAr?: string | null } }> } | null };


export const ArticleCategoriesAutocompleteDocument = gql`
    query ArticleCategoriesAutocomplete($after: String, $before: String, $filter: ArticleCategoryFilterInput, $first: Int, $last: Int) {
  articleCategories(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        code
        display
        displayAr
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useArticleCategoriesAutocompleteQuery__
 *
 * To run a query within a React component, call `useArticleCategoriesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoriesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoriesAutocompleteQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useArticleCategoriesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>(ArticleCategoriesAutocompleteDocument, options);
      }
export function useArticleCategoriesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>(ArticleCategoriesAutocompleteDocument, options);
        }
export function useArticleCategoriesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>(ArticleCategoriesAutocompleteDocument, options);
        }
export type ArticleCategoriesAutocompleteQueryHookResult = ReturnType<typeof useArticleCategoriesAutocompleteQuery>;
export type ArticleCategoriesAutocompleteLazyQueryHookResult = ReturnType<typeof useArticleCategoriesAutocompleteLazyQuery>;
export type ArticleCategoriesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useArticleCategoriesAutocompleteSuspenseQuery>;
export type ArticleCategoriesAutocompleteQueryResult = Apollo.QueryResult<ArticleCategoriesAutocompleteQuery, ArticleCategoriesAutocompleteQueryVariables>;