import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaActionGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type OptimaActionGetQuery = { __typename?: 'Query', optimaAction?: { __typename?: 'OptimaAction', id: string, link?: string | null, action?: string | null, summary?: string | null, isActive?: boolean | null } | null };


export const OptimaActionGetDocument = gql`
    query OptimaActionGet($id: ID!) {
  optimaAction(id: $id) {
    id
    link
    action
    summary
    isActive
  }
}
    `;

/**
 * __useOptimaActionGetQuery__
 *
 * To run a query within a React component, call `useOptimaActionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaActionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaActionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptimaActionGetQuery(baseOptions: Apollo.QueryHookOptions<OptimaActionGetQuery, OptimaActionGetQueryVariables> & ({ variables: OptimaActionGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaActionGetQuery, OptimaActionGetQueryVariables>(OptimaActionGetDocument, options);
      }
export function useOptimaActionGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaActionGetQuery, OptimaActionGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaActionGetQuery, OptimaActionGetQueryVariables>(OptimaActionGetDocument, options);
        }
export function useOptimaActionGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaActionGetQuery, OptimaActionGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaActionGetQuery, OptimaActionGetQueryVariables>(OptimaActionGetDocument, options);
        }
export type OptimaActionGetQueryHookResult = ReturnType<typeof useOptimaActionGetQuery>;
export type OptimaActionGetLazyQueryHookResult = ReturnType<typeof useOptimaActionGetLazyQuery>;
export type OptimaActionGetSuspenseQueryHookResult = ReturnType<typeof useOptimaActionGetSuspenseQuery>;
export type OptimaActionGetQueryResult = Apollo.QueryResult<OptimaActionGetQuery, OptimaActionGetQueryVariables>;