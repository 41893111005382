import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type HPageInfoAutocompleteFragment = { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null };

export const HPageInfoAutocompleteFragmentDoc = gql`
    fragment HPageInfoAutocomplete on H_PageInfo {
  hasNextPage
  endCursor
}
    `;