import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useVendorDepartmentsAutocompleteQuery } from "./gql";
import { IVendorDepartmentAutocomplete } from "./schema";

export const createVendorDepartmentAutocompleteOption = (department: IVendorDepartmentAutocomplete) => {
  return createAutocompleteOption({ id: department?.id!, name: department?.name! }, "id", "name");
};

export const getVendorDepartmentsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "vendorDepartments",
    query: useVendorDepartmentsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
