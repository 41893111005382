import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramMemberListInput;
}>;


export type HealthProgramMemberListCreateMutation = { __typename?: 'Mutation', healthProgramMemberListCreate?: { __typename?: 'HealthProgramMemberListCRUD', entity?: { __typename?: 'HealthProgramMemberList', id: string } | null } | null };


export const HealthProgramMemberListCreateDocument = gql`
    mutation HealthProgramMemberListCreate($input: HealthProgramMemberListInput!) {
  healthProgramMemberListCreate(input: $input) {
    entity {
      id
    }
  }
}
    `;
export type HealthProgramMemberListCreateMutationFn = Apollo.MutationFunction<HealthProgramMemberListCreateMutation, HealthProgramMemberListCreateMutationVariables>;

/**
 * __useHealthProgramMemberListCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramMemberListCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMemberListCreateMutation, { data, loading, error }] = useHealthProgramMemberListCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramMemberListCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMemberListCreateMutation, HealthProgramMemberListCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMemberListCreateMutation, HealthProgramMemberListCreateMutationVariables>(HealthProgramMemberListCreateDocument, options);
      }
export type HealthProgramMemberListCreateMutationHookResult = ReturnType<typeof useHealthProgramMemberListCreateMutation>;
export type HealthProgramMemberListCreateMutationResult = Apollo.MutationResult<HealthProgramMemberListCreateMutation>;
export type HealthProgramMemberListCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramMemberListCreateMutation, HealthProgramMemberListCreateMutationVariables>;