import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { convertProcedureAgeFormValuesToBackEndValues } from "../../others";

import { useProcedureAgeCreateMutation } from "../../gql/mutations/__generated__";
import { procedureAgesPaths } from "../../constants/ProcedureAgePaths";

import { useSetProcedureAgesBreadcrumbs } from "../../hooks/useSetInsuranceBenefitApprovalLimitsBreadcrumbs";
import {
  IProcedureAgeUpsertFormEvent,
  ProcedureAgeUpsertForm,
} from "../../forms/ProcedureAgeInformationUpsert/ProcedureAgeInformationUpsertForm";

export const ProcedureAgeCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createProcedureAge, { loading: isSubmitting }] = useProcedureAgeCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.createProcedureAge?.id) {
        succeeded(t("Procedure Age created successfully"));
        navigate(procedureAgesPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onProcedureAgeUpsertFormChange = (event: IProcedureAgeUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertProcedureAgeFormValuesToBackEndValues(event.payload.values);

      createProcedureAge({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetProcedureAgesBreadcrumbs("CREATE");

  return <ProcedureAgeUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onProcedureAgeUpsertFormChange} />;
};
