import { Divider, Grid, Typography } from "@toolkit/ui";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "@toolkit/i18n";
import { DigitalTwinComplicationCategory } from "@health/enum-options";
import { CaseManagementPatientDigitalTwinLinearProgress } from "../CaseManagementPatientDigitalTwinLinearProgress/CaseManagementPatientDigitalTwinLinearProgress";

type CaseManagementPatientDigitalTwinPrognosticRiskScoreProps = PropsWithChildren<{
  name: string;
  category: string;
  value: number;
}>;

export const CaseManagementPatientDigitalTwinPrognosticRiskScore: FC<CaseManagementPatientDigitalTwinPrognosticRiskScoreProps> = props => {
  const { name, category, value } = props;

  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CaseManagementPatientDigitalTwinLinearProgress name={name} category={category!} value={value} />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {category === DigitalTwinComplicationCategory.Diagnosed ? (
          props.children
        ) : (
          <Typography>{t("There are no complications for {{name}}", { name })}</Typography>
        )}
      </Grid>
    </Grid>
  );
};
