import { makeStyles } from "@toolkit/ui";

export const useDotBadgeStyle = makeStyles()(theme => ({
  colorApproved: {
    backgroundColor: theme.palette.stale.main,
  },
  colorUnderReview: {
    backgroundColor: theme.palette.warning.main,
  },
  colorRejected: {
    backgroundColor: theme.palette.secondary.main,
  },
  colorPublished: {
    backgroundColor: theme.palette.success.main,
  },
  colorInfo: {
    backgroundColor: theme.palette.stale.main,
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  colorDraft: {
    backgroundColor: theme.palette.stale.main,
  },
}));
