import { makeStyles } from "tss-react/mui";

export const useTransferListCardListStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  divider: {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  },
  list: {
    minHeight: 500,
    maxHeight: 500,
    overflow: "auto",
  },
  listItem: {
    cursor: "pointer",
    paddingBlock: 4,
    marginBottom: 4,
  },
  searchField: {
    padding: 4,
    color: theme.palette.stale.main,
  },
  loadingWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));
