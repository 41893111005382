import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { CancellationReasonsAutocompleteQuery } from "./gql";
import { ExtractNodeType } from "@toolkit/apollo";

export type ICancellationReasonAutocomplete = Omit<ExtractNodeType<CancellationReasonsAutocompleteQuery>, "properties"> & {
  arabicDisplay?: string | null;
};

export const cancellationReasonSchema = createZodAutocompleteFromType<ICancellationReasonAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  arabicDisplay: z.string().nullish(),
});
