import { IAutocompleteProps } from "@/types";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete } from "@toolkit/ui";
import { FC } from "react";
import { useOptimaEncounterTypesAutocompleteQuery } from "./gql";

type OptimaEncounterTypesAutocompleteProps = IAutocompleteProps<{ id: string; name: string }>;

export const OptimaEncounterTypesAutocomplete: FC<OptimaEncounterTypesAutocompleteProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data } = useOptimaEncounterTypesAutocompleteQuery({
    skip: props.disabled || skip,
  });

  const options = data?.optimaEncounterTypes!.map(d => ({ id: d?.id!, name: d?.name! })) || [];

  return <FormAutocomplete {...rest} options={options} label={label || (props.multiple ? t("OptimaEncounterTypes") : t("Branch"))} />;
};
