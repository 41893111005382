import { FormCard, FormDaysTimesRanges, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { UserProviderDoctorDetailsInformationForm } from "../UserProviderDoctorDetailsInformation/UserProviderDoctorDetailsInformationForm";
import { UserProviderDoctorDetailsAppointmentInformationForm } from "../UserProviderDoctorDetailsAppointmentInformation/UserProviderDoctorDetailsAppointmentInformationForm";
import { UserProviderDoctorDetailsQualificationsInformationForm } from "../UserProviderDoctorDetailsQualificationsInformation/UserProviderDoctorDetailsQualificationsInformationForm";
import React, { FC } from "react";

type UserVendorDoctorDetailsFormProps = {
  isLoading?: boolean;
};

export const UserProviderDoctorDetailsForm: FC<UserVendorDoctorDetailsFormProps> = props => {
  const { isLoading } = props;

  const { t } = useTranslation("domains");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormCard title={t("Doctor Information")} loading={isLoading} doYouHaveData>
          <UserProviderDoctorDetailsInformationForm />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Doctor Appointment Information")} loading={isLoading} doYouHaveData>
          <UserProviderDoctorDetailsAppointmentInformationForm />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Doctor Qualifications")} loading={isLoading} doYouHaveData>
          <UserProviderDoctorDetailsQualificationsInformationForm />
        </FormCard>
      </Grid>

      <Grid item xs={12}>
        <FormCard title={t("Doctor Working Hours")} loading={isLoading} doYouHaveData>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <FormDaysTimesRanges name={"doctorInfo.workingHours"} startTimeLabel={t("Open Time")} endTimeLabel={t("Close Time")} />
            </Grid>
          </Grid>
        </FormCard>
      </Grid>
    </Grid>
  );
};
