/* eslint-disable max-lines */
import { useTranslation } from "@toolkit/i18n";
import { isEmpty } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "../../../../base/mui";
import { MuiExpandMoreIcon } from "../../../../base/mui-icons";
import { ValueWithLabel } from "../../../LabeledComponents";
import { TruncateTypography } from "../../../TruncateTypography";
import { BaseDetailsViewer } from "../BaseDetailsViewer/BaseDetailsViewer";
import {
  ComplexValue,
  GroupVariants,
  InfoCellOptionsProps,
  InfoGroup,
  InfoItem,
  LayoutVariants,
  StatusVariants,
  ValueDisplayModes,
} from "./types";
import { useInfoCellStyles } from "./useInformationCellStyles";

export const InformationCell = <RowType, ExternalData>(props: InfoCellOptionsProps<RowType, ExternalData>) => {
  const { classes, cx } = useInfoCellStyles();
  const {
    containerProps,
    row,
    externalData,
    emptyMessage,
    title,
    groups,
    items,
    direction = "column",
    valueDisplayMode = "inline",
    layout = "two-columns",
    loading = false,
    additionalContent,
    disableShowButton,
    onShowButtonClick,
  } = props;

  const getStatusClass = (variant: StatusVariants): string => {
    const statusClasses = {
      success: classes.statusSuccess,
      warning: classes.statusWarning,
      error: classes.statusError,
      info: classes.statusInfo,
    };
    return statusClasses[variant] || "";
  };

  const getGroupClasses = (variant?: GroupVariants, layout?: LayoutVariants): string => {
    return cx({
      [classes.groupContainer]: true,
      [classes.groupHighlight]: variant === "highlight",
      [classes.groupBordered]: variant === "bordered",
      [classes.groupCompact]: variant === "compact",
      [classes.twoColumns]: layout === "two-columns",
      [classes.threeColumns]: layout === "three-columns",
      [classes.oneColumn]: layout === "one-column",
    });
  };

  const renderComplexValue = (value: ComplexValue) => (
    <Box display='flex' alignItems='center' gap={1}>
      <Box className={classes.valueContainer}>
        {value.primary && <Typography className={classes.value}>{value.primary}</Typography>}
        {value.secondary && <Typography className={classes.secondaryText}>{value.secondary}</Typography>}
        {value.status && <Box className={cx(classes.statusChip, getStatusClass(value.status.variant))}>{value.status.label}</Box>}
      </Box>
    </Box>
  );

  const renderListValue = (values: Array<string | ComplexValue>) => {
    return (
      <List className={classes.list}>
        {values.map((value, index) => (
          <ListItem key={index} className={classes.listItem}>
            <ListItemText
              primary={
                typeof value === "string" ? (
                  <Typography className={classes.listItemText}>{value}</Typography>
                ) : (
                  <Box className={classes.listItemContent}>
                    {value.primary && <Typography className={classes.value}>{value.primary}</Typography>}
                    {value.secondary && <Typography className={classes.secondaryText}>{value.secondary}</Typography>}
                    {value.status && (
                      <Box className={cx(classes.statusChip, getStatusClass(value.status.variant))}>{value.status.label}</Box>
                    )}
                  </Box>
                )
              }
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const renderArrayValue = (values: Array<string | ComplexValue>, displayMode: ValueDisplayModes) => {
    if (displayMode === "list") {
      return renderListValue(values);
    }

    const containerClass = cx({
      [classes.arrayValueStacked]: displayMode === "stacked",
      [classes.arrayValueInline]: displayMode !== "stacked",
    });

    return (
      <Box className={containerClass}>
        {values.map((value, index) => {
          if (typeof value === "string") {
            if (displayMode === "chips") {
              return (
                <Box key={index} className={classes.chip}>
                  {value}
                </Box>
              );
            }
            return (
              <Typography key={index} className={classes.value}>
                {value}
              </Typography>
            );
          }
          return <Box key={index}>{renderComplexValue(value)}</Box>;
        })}
      </Box>
    );
  };

  const renderValue = (item: InfoItem) => {
    const displayMode = item.valueDisplayMode || valueDisplayMode;

    if (Array.isArray(item.value)) {
      return renderArrayValue(item.value, displayMode);
    }

    if (typeof item.value === "object" && (item.value as ComplexValue).primary) {
      return renderComplexValue(item.value as ComplexValue);
    }

    if (item.truncate) {
      return <TruncateTypography className={classes.value} text={item.value as string} maxWidth={item.maxWidth} />;
    }

    if (typeof item.value === "string" && displayMode === "multiple-line-string") {
      return (
        <Typography className={cx(classes.mutilinesString, classes.value)} variant='body2'>
          {item.value}
        </Typography>
      );
    }

    return item.value;
  };

  const renderItem = (item: InfoItem) => (
    <ValueWithLabel
      key={item.label}
      label={item?.label || ""}
      value={renderValue(item)}
      direction={item.direction || direction}
      labelProps={{ className: classes.label }}
      valueProps={{ className: cx(classes.value) }}
    />
  );

  const resolveData = <T,>(
    data: ((row: RowType, externalData?: ExternalData) => T[] | undefined) | T[] | undefined,
    rowData: RowType,
    externalData?: ExternalData
  ): T[] | undefined => {
    if (!data) return [];
    return typeof data === "function" ? data(rowData, externalData) : data;
  };

  const resolvedItems = items ? resolveData(items, row, externalData) : undefined;
  const resolvedGroups = resolveData(groups, row, externalData);
  const resolvedDisableShowButton = typeof disableShowButton === "function" ? disableShowButton(row) : disableShowButton;

  const renderGroup = (group: InfoGroup) => {
    if (group.variant === "accordion") {
      return (
        <Accordion key={group.title} className={cx(classes.accordion)} defaultExpanded={false}>
          <AccordionSummary expandIcon={<MuiExpandMoreIcon />} className={classes.accordionSummary}>
            <Typography className={cx(classes.groupTitle)}>{group.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box className={cx(getGroupClasses(group.variant, group.layout))}>{group?.items?.map(renderItem)}</Box>
          </AccordionDetails>
        </Accordion>
      );
    }
    return (
      <Box key={group.title}>
        <Typography className={cx(classes.groupTitle)}>{group.title}</Typography>
        <Box className={cx(getGroupClasses(group.variant, group.layout))}>{group?.items?.map(renderItem)}</Box>
      </Box>
    );
  };

  const { t } = useTranslation();

  return (
    <BaseDetailsViewer
      disableShowButton={resolvedDisableShowButton}
      onShowButtonClicked={onShowButtonClick}
      {...containerProps}
      title={title}
    >
      {loading ? (
        <Box width='100%' display='flex' justifyContent='center' height='100%' alignItems='center'>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {!isEmpty(resolvedItems) && <Box className={getGroupClasses("default", layout)}>{resolvedItems?.map(renderItem)}</Box>}
          {!isEmpty(resolvedGroups) && resolvedGroups?.map(renderGroup)}
          {isEmpty(resolvedGroups) && isEmpty(resolvedItems) && <Typography>{emptyMessage || t("There is No Data")}</Typography>}
        </Box>
      )}
      {additionalContent}
    </BaseDetailsViewer>
  );
};
