import { WorkingHour } from "@/schema/types";
import { FC, Fragment } from "react";
import moment from "moment";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { dayOfWeekOptionsMap } from "@health/enum-options";
import { useBranchWorkingHoursStyle } from "./useBranchWorkingHoursStyle";

type BranchWorkingHoursProps = {
  workingHours: WorkingHour[];
};

export const BranchWorkingHours: FC<BranchWorkingHoursProps> = props => {
  const { workingHours } = props;

  const { t } = useTranslation("domains");

  const { classes } = useBranchWorkingHoursStyle();

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead className={classes.borderBottom}>
          <TableRow>
            <TableCell className={classes.tableCellHeader}>{t("Day")}</TableCell>
            <TableCell className={classes.tableCellHeader}>{t("Start Time")}</TableCell>
            <TableCell className={classes.tableCellHeader}>{t("Close Time")}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {workingHours.map(dayData => (
            <Fragment key={dayData?.day}>
              {dayData?.openTimeRanges?.map((timeRange, timeIndex) => {
                const openTimeRangesLength = dayData?.openTimeRanges?.length;

                return (
                  <TableRow
                    key={timeRange?.openTime}
                    className={openTimeRangesLength && timeIndex === openTimeRangesLength - 1 ? classes.borderBottom : undefined}
                  >
                    {timeIndex === 0 ? (
                      <TableCell rowSpan={dayData.openTimeRanges?.length} className={classes.tableCellDay}>
                        {dayOfWeekOptionsMap[dayData.day!]?.label}
                      </TableCell>
                    ) : null}

                    <TableCell>{timeRange?.openTime ? moment(timeRange.openTime, "HH:mm:ss").format("hh:mm A") : "-"}</TableCell>

                    <TableCell>{timeRange?.closeTime ? moment(timeRange.closeTime, "HH:mm:ss").format("hh:mm A") : "-"}</TableCell>
                  </TableRow>
                );
              })}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
