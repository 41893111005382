import { IAutocompleteBasicProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { useDoctorsTeamLeadersAutocompleteQuery } from "./gql";

type DoctorsTeamLeadersAutocompleteProps = IAutocompleteBasicProps;

export const DoctorsTeamLeadersAutocomplete: FC<DoctorsTeamLeadersAutocompleteProps> = props => {
  const { label, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDoctorsTeamLeadersAutocompleteQuery,
    skip: props.disabled || skip,
  });

  const _data = data?.map(item => item?.user!) || [];

  const options = mapToAutocompleteOptions(_data, "id", "fullName");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Doctors") : t("Doctor"))}
    />
  );
};
