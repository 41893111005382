import { makeStyles } from "tss-react/mui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useOptimaEditDetailsStyle = makeStyles()(theme => ({
  card: {
    padding: 16,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 8,
  },
  message: {
    marginBottom: 8,
    textAlign: pickLocalizedValue("left", "right"),
  },
  details: {
    color: theme.palette.gray.main,
    textAlign: pickLocalizedValue("left", "right"),
  },
}));
