/**
 * Normalizes a string by removing invisible characters.
 * Invisible characters such as zero-width spaces and non-breaking spaces.
 */
export const normalizeString = (value: string) => {
  return value
    .replace(/[\u200B-\u200D\uFEFF]/g, "")
    .toLowerCase()
    .trim();
};

export const parseNonStandardJson = (jsonLike: string) => {
  const toJsObject = new Function(`return ${jsonLike};`);
  return toJsObject();
};
