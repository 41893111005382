import { ProcedureAge } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { IProcedureAgeUpdateContainerParams } from "../../types";
import { convertProcedureAgeFormValuesToBackEndValues } from "../../others";
import { useProcedureAgeGetQuery, useProcedureAgeUpdateMutation } from "../../gql";

import { useSetProcedureAgesBreadcrumbs } from "../../hooks/useSetInsuranceBenefitApprovalLimitsBreadcrumbs";
import {
  IProcedureAgeUpsertFormEvent,
  ProcedureAgeUpsertForm,
} from "../../forms/ProcedureAgeInformationUpsert/ProcedureAgeInformationUpsertForm";

export const ProcedureAgeUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { procedureAgeId } = useParams<IProcedureAgeUpdateContainerParams>();

  const { data, loading } = useProcedureAgeGetQuery({
    variables: { getProcedureAgeId: procedureAgeId! },
    skip: !procedureAgeId,
    fetchPolicy: "no-cache",
  });

  const procedureAge = data?.getProcedureAge as ProcedureAge;

  const [updateProcedureAge, { loading: isSubmitting }] = useProcedureAgeUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.updateProcedureAge?.id) {
        succeeded(t("Procedure Age updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onProcedureAgeUpsertFormChange = (event: IProcedureAgeUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertProcedureAgeFormValuesToBackEndValues(event.payload.values);

      updateProcedureAge({
        variables: {
          updateProcedureAgeId: procedureAgeId!,
          input: values,
        },
      });
    }
  };

  useSetProcedureAgesBreadcrumbs("UPDATE", procedureAge?.procedureCode);

  return (
    <ProcedureAgeUpsertForm
      buttonLabel={t("Update")}
      procedureAge={procedureAge}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onProcedureAgeUpsertFormChange}
    />
  );
};
