import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloneCodeSystemMutationVariables = Types.Exact<{
  codeSystemCode: Types.CodeSystemCode;
}>;


export type CloneCodeSystemMutation = { __typename?: 'Mutation', cloneCodeSystem?: { __typename?: 'CodeSystem', id?: string | null, errors?: Array<{ __typename?: 'TerminologyGraphqlError', message?: string | null, field?: string | null, code?: Types.TerminologyErrorCodes | null } | null> | null } | null };


export const CloneCodeSystemDocument = gql`
    mutation cloneCodeSystem($codeSystemCode: CodeSystemCode!) {
  cloneCodeSystem(codeSystemCode: $codeSystemCode) {
    id
    errors {
      message
      field
      code
    }
  }
}
    `;
export type CloneCodeSystemMutationFn = Apollo.MutationFunction<CloneCodeSystemMutation, CloneCodeSystemMutationVariables>;

/**
 * __useCloneCodeSystemMutation__
 *
 * To run a mutation, you first call `useCloneCodeSystemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneCodeSystemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneCodeSystemMutation, { data, loading, error }] = useCloneCodeSystemMutation({
 *   variables: {
 *      codeSystemCode: // value for 'codeSystemCode'
 *   },
 * });
 */
export function useCloneCodeSystemMutation(baseOptions?: Apollo.MutationHookOptions<CloneCodeSystemMutation, CloneCodeSystemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneCodeSystemMutation, CloneCodeSystemMutationVariables>(CloneCodeSystemDocument, options);
      }
export type CloneCodeSystemMutationHookResult = ReturnType<typeof useCloneCodeSystemMutation>;
export type CloneCodeSystemMutationResult = Apollo.MutationResult<CloneCodeSystemMutation>;
export type CloneCodeSystemMutationOptions = Apollo.BaseMutationOptions<CloneCodeSystemMutation, CloneCodeSystemMutationVariables>;