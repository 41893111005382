import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { ProviderGuidedCareHealthProgramTeamFilterInput } from "../../schema/types";
import { useGuidedCareTeamsAutocompleteQuery } from "./gql";

type GuidedCareTeamsAutocompleteProps = IAutocompleteProps<ProviderGuidedCareHealthProgramTeamFilterInput>;

export const GuidedCareTeamsAutocomplete: FC<GuidedCareTeamsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useGuidedCareTeamsAutocompleteQuery,
    variables: { filter },
    searchKey: "name",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Teams") : t("Team"))}
    />
  );
};
