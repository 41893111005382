import { i18n } from "@toolkit/i18n";
import { PaymentSource } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const paymentSourceOptionsMap: IEnumToOptionsMap<PaymentSource> = {
  [PaymentSource.Checkout]: {
    key: PaymentSource.Checkout,
    get label() {
      return i18n.t("Checkout", { ns: "domains" });
    },
    value: PaymentSource.Checkout,
  },
  [PaymentSource.Wallet]: {
    key: PaymentSource.Wallet,
    get label() {
      return i18n.t("Wallet", { ns: "domains" });
    },
    value: PaymentSource.Wallet,
  },
};

export const paymentSourceOptions = Object.values(paymentSourceOptionsMap);
