import { i18n } from "@toolkit/i18n";
import { AppTypes } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const appTypeOptionsMap: IEnumToOptionsMap<AppTypes> = {
  [AppTypes.Admin]: {
    key: AppTypes.Admin,
    get label() {
      return i18n.t("Admin", { ns: "domains" });
    },
    value: AppTypes.Admin,
  },
  [AppTypes.Aggregator]: {
    key: AppTypes.Aggregator,
    get label() {
      return i18n.t("Aggregator", { ns: "domains" });
    },
    value: AppTypes.Aggregator,
  },
  [AppTypes.Customer]: {
    key: AppTypes.Customer,
    get label() {
      return i18n.t("Customer", { ns: "domains" });
    },
    value: AppTypes.Customer,
  },
  [AppTypes.Payer]: {
    key: AppTypes.Payer,
    get label() {
      return i18n.t("Payer", { ns: "domains" });
    },
    value: AppTypes.Payer,
  },
  [AppTypes.Vendor]: {
    key: AppTypes.Vendor,
    get label() {
      return i18n.t("Vendor", { ns: "domains" });
    },
    value: AppTypes.Vendor,
  },
};

export const appTypeOptions = Object.values(appTypeOptionsMap);
