import { OrderEventType, OrderStatus } from "@/schema/types";
import { OrdersEvent, Timeline } from "@toolkit/ui";
import { FC, Fragment } from "react";
import { OrdersEventsProps, OrdersEventType } from "./types";

export const OrdersEvents: FC<OrdersEventsProps> = ({ events, helpDiskTicketId, failReason, status }) => {
  const handleVoid = () => {};
  return (
    <Timeline placeholder='' sx={{ margin: 0, padding: "0px 16px" }} onPointerEnterCapture={handleVoid} onPointerLeaveCapture={handleVoid}>
      {events?.map((event, index) => {
        const failedType =
          event.type === OrderEventType.Failed ||
          event.type === OrderEventType.PaymentFailed ||
          event.type === OrderEventType.ErxHubClaimFailed ||
          event.type === OrderEventType.OrderDeliveryFailed ||
          event.type === OrderEventType.ErxHubAuthRequestFailed;
        return (
          <Fragment key={String(event?.id!) + index}>
            <OrdersEvent<OrdersEventType, OrderStatus>
              event={event}
              number={event?.number!}
              branchName={event?.branchName!}
              failReason={failReason}
              helpDiskTicketId={helpDiskTicketId}
              failedType={failedType && status === OrderStatus.Failed && event?.isParentEvent}
            />
          </Fragment>
        );
      })}
    </Timeline>
  );
};
