import { OptimaAction } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog } from "@toolkit/ui";
import { OptimaEditActions } from "@/pages/OptimaVisitValidationRequest/components/OptimaEditActions/OptimaEditActions";
import { useEffect, useState } from "react";

type IOptimaEditActionsModalData = {
  actions: OptimaAction[];
};

type IOpen = (data: IOptimaEditActionsModalData) => void;
type IClose = () => void;

export const OptimaEditActionsModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const OptimaEditActionsModal = () => {
  const [data, setData] = useState<IOptimaEditActionsModalData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const { t } = useTranslation();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  const close: IClose = () => {
    handleClose();
  };

  useEffect(() => {
    OptimaEditActionsModalApi.open = open;
    OptimaEditActionsModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      maxWidth={"sm"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Actions"),
        onClose: close,
      }}
      DialogActionsProps={{
        hasClose: true,
      }}
    >
      <OptimaEditActions actions={data?.actions!} />
    </CustomDialog>
  );
};
