import { ItemCodeMaximumQuantityAllowed, ItemCodeMaximumQuantityAllowedInput } from "@/schema/types";
import { IItemCodeMaximumQuantityAllowedUpsertFormValues } from "../forms/ItemCodeMaximumQuantityAllowedInformationUpsert/itemCodeMaximumQuantityAllowedInformationUpsertFormSchema";

export const convertItemCodeMaximumQuantityAllowedToFormValues = (
  itemCodeMaximumQuantityAllowed: ItemCodeMaximumQuantityAllowed
): IItemCodeMaximumQuantityAllowedUpsertFormValues => {
  return {
    codeSystem: itemCodeMaximumQuantityAllowed.codeSystem,
    itemCode: itemCodeMaximumQuantityAllowed.itemCode,
    maximumAllowed: itemCodeMaximumQuantityAllowed.maximumAllowed,
    description: itemCodeMaximumQuantityAllowed.description ?? "",
  };
};

export const convertItemCodeMaximumQuantityAllowedFormValuesToBackEndValues = (
  values: IItemCodeMaximumQuantityAllowedUpsertFormValues
): ItemCodeMaximumQuantityAllowedInput => {
  return {
    maximumAllowed: values.maximumAllowed,
    itemCode: values.itemCode,
    codeSystem: values.codeSystem,
    description: values.description,
  };
};
