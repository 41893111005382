import { formatUserNameFull } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, Card, Dot, Grid, Tooltip, Typography } from "@toolkit/ui";
import { FC, memo } from "react";
import { AMLAssignReviewerDialog } from "../../shared/AMLAssignReviewerDialog";
import { useManagedListCardStyle } from "./ManagedListCard.style";
import { ManagedListCardProps } from "./ManagedListCard.types";
import { useManagedListCardHook } from "./useManagedListCardHook";
import { getCreatorName } from "./utils";

const ManagedListCard: FC<ManagedListCardProps> = props => {
  const { data, handleDetailsClick } = props;
  const { t } = useTranslation("domains");

  const {
    id,
    code,
    creator,
    display,
    svgIcon,
    statusText,
    updateDate,
    uploadDate,
    statusColor,
    editorUserId,
    lastCodeSystem,
    reviewerUserId,
    lastCadeSystemProcessingStatus,
  } = useManagedListCardHook({ data });
  const { classes, theme } = useManagedListCardStyle();
  return (
    <>
      <Card elevation={0}>
        <Box minHeight={330} padding={1} paddingBottom={0}>
          <Grid container alignItems='center'>
            {lastCadeSystemProcessingStatus ? (
              <>
                {lastCodeSystem === null ? undefined : <Dot background={statusColor} />}
                <Typography paddingLeft={1} color={statusColor} fontSize={theme.mixins.fonts.fontSize.xs}>
                  {getCreatorName(statusText, creator, t)}
                </Typography>
              </>
            ) : (
              <Box height='18px'></Box>
            )}
          </Grid>
          <Grid container flexDirection={"column"} alignItems='center' paddingTop={3}>
            <div dangerouslySetInnerHTML={{ __html: svgIcon.replace(/height=*"/, 'height="70px"') }} className={classes.imgHeight} />
            <Typography fontSize={theme.mixins.fonts.fontSize.lg} marginTop={1} marginBottom={1}>
              {display}
            </Typography>

            <Grid container justifyContent={"center"} spacing={2}>
              <Grid item container flex={1} display={"flex"} direction='column' rowSpacing={0.5}>
                {[
                  { id: "Upload Date", title: t("Upload Date"), value: t(uploadDate) || "-" },
                  { id: "Last Update", title: t("Last Update"), value: t(updateDate) || "-" },
                  { id: "Assigned Editor", title: <b>{t("Assigned Editor")}</b>, value: formatUserNameFull(editorUserId!) },
                  { id: "Assigned Reviewer", title: <b>{t("Assigned Reviewer")}</b>, value: formatUserNameFull(reviewerUserId!) },
                ].map(item => {
                  return (
                    <Grid key={item.id} item container columnSpacing={2}>
                      <Grid item xs={6} sx={{ textAlign: "end" }}>
                        <Typography fontSize={theme.mixins.fonts.fontSize.xs}>{item.title}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip title={item.value}>
                          <Typography
                            fontSize={theme.mixins.fonts.fontSize.xs}
                            sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "90%!important", overflow: "hidden" }}
                          >
                            {item.value}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} marginTop={2}>
              <Button variant='contained' onClick={() => handleDetailsClick(code!, id!)}>
                {t("Details")}
              </Button>

              <AMLAssignReviewerDialog
                code={code!}
                editorUser={editorUserId}
                reviewerUser={reviewerUserId}
                buttonProps={{
                  variant: "contained",
                  color: "success",
                  children: editorUserId || reviewerUserId ? t("Reassign") : t("Assign"),
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default memo(ManagedListCard);
