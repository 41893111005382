import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthParameterCategoriesAutocompleteQuery } from "./gql";

export type IHealthParameterCategoryAutocomplete = ExtractNodeType<HealthParameterCategoriesAutocompleteQuery>;

export const healthParameterCategorySchema = createZodAutocompleteFromType<IHealthParameterCategoryAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  arabicDisplay: z.string().nullish(),
});
