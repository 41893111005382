import { Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { useSurveyStatisticsContainer } from "./useSurveyStatisticsContainer";
import { ParentSize } from "@visx/responsive";
import { StatisticsCard } from "../../components/SurveyStatisticsTab/StatisticsCard";
import { CARD_HEIGHT } from "../../components/SurveyStatisticsTab/constants";
import { useSetSurveysBreadcrumbs } from "../../hooks";
import SurveyCardLoader from "../../components/SurveyStatisticsTab/StatisticsCard/StatisticsCardLoader";

export const SurveyStatisticsContainer: FC = () => {
  const { isLoading, surveyStatistics, survey } = useSurveyStatisticsContainer();
  useSetSurveysBreadcrumbs("DETAILS", survey?.label || "");

  return (
    <PageWrapper>
      <Grid container padding={4} spacing={2}>
        {isLoading ? (
          <SurveyCardLoader />
        ) : (
          surveyStatistics?.questionsStatistics?.map(item => (
            <Grid key={item!.question?.id} item xs={12} sm={6}>
              <ParentSize>
                {({ width }) => <StatisticsCard height={Math.min(CARD_HEIGHT, width)} width={width} questionStatistics={item!} />}
              </ParentSize>
            </Grid>
          ))
        )}
      </Grid>
    </PageWrapper>
  );
};
