import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDoctorsAutocompleteQuery } from "./gql";
import { IDoctorAutocomplete } from "./schema";

export const createDoctorAutocompleteOption = (doctor: IDoctorAutocomplete) => {
  return createAutocompleteOption(
    {
      id: doctor?.id!,
      user: { id: doctor?.user?.id!, fullName: doctor?.user?.fullName! },
    },
    "id",
    item => item?.user?.fullName
  );
};

export const getDoctorsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "doctors",
    query: useDoctorsAutocompleteQuery,
    labelBy: option => option?.user?.fullName!,
    backendAccessor: "id",
    filterSearchKey: "name",
  });
};
