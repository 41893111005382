import { Field, SubListInput } from "@/schema/types";
import { getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { useAllListFieldListQuery } from "@/pages/Sublists/gql";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

type FieldsAutocompleteProps = {
  defaultValue: Field;
};

export const FieldsAutocomplete: FC<FieldsAutocompleteProps> = props => {
  const { defaultValue } = props;

  const { t } = useTranslation("domains");
  const methods = useFormContext<SubListInput>();

  const { data, loading: isLoadingFields } = useAllListFieldListQuery();
  const fieldsOptionItems = data?.getAllListFields ?? [];
  const { errors } = methods.formState;

  return (
    <AutocompleteController
      ControllerProps={{
        control: methods.control,
        name: "field",
        defaultValue: defaultValue,
        rules: {
          required: getRequiredValidation(t, true),
        },
      }}
      options={fieldsOptionItems}
      loading={isLoadingFields}
      isOptionEqualToValue={(o, v) => {
        return o === v || o?.id === v?.id;
      }}
      getOptionLabel={o => o.display}
      defaultValue={defaultValue}
      TextFieldProps={{
        error: Boolean(errors?.field?.["message"]),
        helperText: errors?.field?.["message"],
        label: t("Field"),
      }}
    />
  );
};
