import React, { FC, useEffect, useState } from "react";
import { HealthProgramMembershipRequest } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useOpenState } from "@toolkit/core";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import {
  HealthProgramMembershipRequestRejectForm,
  HealthProgramMembershipRequestRejectFormApi,
  IHealthProgramMembershipRequestRejectFormEvent,
} from "../../forms/HealthProgramMembershipRequestReject/HealthProgramMembershipRequestRejectForm";

type IHealthProgramMembershipRequestRejectModalData = {
  healthProgramMembershipRequest: HealthProgramMembershipRequest;
};

type IOpen = (data: IHealthProgramMembershipRequestRejectModalData) => void;
type IClose = () => void;

export const HealthProgramMembershipRequestRejectModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type HealthProgramMembershipRequestRejectModalProps = {
  isLoading?: boolean;
  onChange: (event: IHealthProgramMembershipRequestRejectFormEvent) => void;
};

export const HealthProgramMembershipRequestRejectModal: FC<HealthProgramMembershipRequestRejectModalProps> = props => {
  const { isLoading, onChange } = props;

  const [data, setData] = useState<IHealthProgramMembershipRequestRejectModalData>();

  const { t } = useTranslation("admin");

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const onRejectClick = () => {
    HealthProgramMembershipRequestRejectFormApi.submit();
  };

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    HealthProgramMembershipRequestRejectModalApi.open = open;
    HealthProgramMembershipRequestRejectModalApi.close = handleClose;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Reject Insurance Request"),
        onClose: handleClose,
      }}
      DialogActionsProps={{
        children: (
          <Button
            color={"error"}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onRejectClick}
          >
            {t("Reject")}
          </Button>
        ),
      }}
    >
      <HealthProgramMembershipRequestRejectForm
        healthProgramMembershipRequest={data?.healthProgramMembershipRequest!}
        onChange={onChange}
      />
    </CustomDialog>
  );
};
