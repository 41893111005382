import { FormAutocomplete, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { BranchesAutocomplete, VendorsAutocomplete } from "@health/autocompletes";
import { FC } from "react";
import { useCustomFormContext } from "@toolkit/core";
import { AppRoleTypes } from "@/schema/types";
import { IUserProviderUpsertForm } from "../UserProviderUpsert/UserProviderUpsertFormSchema";

type UserProviderVendorInformationFormProps = {
  isAdmin?: boolean;
  vendorId?: string;
  isUpdateMode: boolean;
};

export const UserProviderVendorInformationForm: FC<UserProviderVendorInformationFormProps> = props => {
  const { isAdmin, vendorId, isUpdateMode } = props;

  const { t } = useTranslation("domains");

  const form = useCustomFormContext<IUserProviderUpsertForm>();

  const { watch } = form;

  const provider = watch("provider")?.value;
  const appRole = watch("appRole")?.value;
  const branches = watch("branches");

  const isAppRoleUser = appRole === AppRoleTypes.User;

  const selectedVendorId = isAdmin ? provider?.id! : vendorId!;

  return (
    <Grid container spacing={2}>
      {isAdmin && (
        <Grid item xs={4}>
          <VendorsAutocomplete name={"provider"} disabled={isUpdateMode} />
        </Grid>
      )}

      {isAppRoleUser ? (
        <>
          <Grid item xs={4}>
            <BranchesAutocomplete name={"branches"} multiple disabled={!selectedVendorId} filter={{ vendors: [selectedVendorId] }} />
          </Grid>

          {branches?.length > 1 && (
            <Grid item xs={4}>
              <FormAutocomplete name={"defaultBranch"} label={t("Default Branch")} options={branches} />
            </Grid>
          )}
        </>
      ) : (
        <Grid item xs={4}>
          <BranchesAutocomplete
            name={"defaultBranch"}
            label={t("Default Branch")}
            disabled={!selectedVendorId}
            filter={{ vendors: [selectedVendorId] }}
          />
        </Grid>
      )}
    </Grid>
  );
};
