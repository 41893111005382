import { ActionDefinitionField, ActionField, Condition, Decision, DecisionInput, DecisionPlan, Field } from "@/schema/types";
import { operatorOptionsMap, priorityOptionsMap } from "@health/enum-options";
import { toSentenceCase } from "@toolkit/core";
import { mapStringsToAutocompleteOptions, mapToAutocompleteOptions } from "@toolkit/ui";
import { createDecisionPlanAutocompleteOption } from "@health/autocompletes";
import { keyBy, uniqBy } from "lodash";
import { IDecisionUpsertFormValues } from "../forms/DecisionUpsert/DecisionUpsertFormSchema";
import {
  DecisionActionFieldListCode,
  IDecisionHealthParameterRiskParameter,
  IDecisionPlanActionFieldsKeyedBy,
  IDecisionPlanFieldsKeyedBy,
} from "../types";
import {
  convertDecisionActionFieldsFormValuesToBackEndValues,
  convertDecisionActionFieldsToFormValues,
  convertDecisionPlanActionFieldsToFormValues,
} from "./decisionActionFieldsUtils";
import {
  convertDecisionConditionGroupsToFormValues,
  convertDecisionConditionsFormValuesToBackEndValues,
  convertDecisionPlanConditionGroupToFormValues,
} from "./decisionConditionUtils";

export const convertDecisionToFormValues = (
  decision: Decision,
  guidedCareTemplateCode?: string,
  healthParameterCode?: string,
  ioAgentCode?: string
): IDecisionUpsertFormValues => {
  const decisionPlanActionsFields: IDecisionPlanActionFieldsKeyedBy = keyBy(
    (decision?.decisionPlan?.actionDefinitionFields! || []) as ActionDefinitionField[],
    "code"
  );

  const decisionPlanFields: IDecisionPlanFieldsKeyedBy = keyBy((decision?.decisionPlan?.field! || []) as Field[], "code");

  return {
    name: decision?.name!,
    decisionPlan: createDecisionPlanAutocompleteOption(decision?.decisionPlan!),
    priority: priorityOptionsMap[decision?.priority!],
    isActive: decision?.isActive!,
    isScript: decision?.isScript!,
    script: decision?.drl!,
    actionFields: convertDecisionActionFieldsToFormValues(decision?.actionFields as ActionField[], decisionPlanActionsFields),
    conditionsGroups: convertDecisionConditionGroupsToFormValues(
      decision?.conditions as Condition[],
      decisionPlanFields,
      guidedCareTemplateCode,
      healthParameterCode,
      ioAgentCode
    ),
  };
};

export const convertDecisionFormValuesToBackEndValues = (
  values: IDecisionUpsertFormValues,
  decisionPlan: DecisionPlan,
  guidedCareTemplateCode?: string,
  healthParameterCode?: string,
  ioAgentCode?: string
): DecisionInput => {
  return {
    name: values?.name,
    decisionPlan: { id: values?.decisionPlan?.value?.id! },
    priority: values?.priority?.value,
    isActive: values?.isActive,
    isScript: values?.isScript,
    drl: values?.isScript ? values?.script : undefined,
    healthParameterCode,
    guidedCareTemplateCode,
    agentCode: ioAgentCode,
    actionFields: convertDecisionActionFieldsFormValuesToBackEndValues(values?.actionFields),
    conditions: convertDecisionConditionsFormValuesToBackEndValues(
      values,
      decisionPlan?.field as Field[],
      guidedCareTemplateCode,
      healthParameterCode,
      ioAgentCode
    ),
  };
};

export const isCodeInDecisionActionFieldListCode = (code?: string) => {
  return code && Object.values(DecisionActionFieldListCode).includes(code as DecisionActionFieldListCode);
};

export const getConditionCategoriesOptions = (decisionPlanFields: Field[]) => {
  return uniqBy(
    mapToAutocompleteOptions(
      decisionPlanFields?.map(item => ({
        code: item?.category?.code!,
        display: item?.category?.display!,
      })) || [],
      "code",
      "display"
    ),
    "key"
  );
};

export const getConditionFieldsOptions = (decisionPlanFields: Field[], categoryCode: string) => {
  return mapToAutocompleteOptions(
    decisionPlanFields
      ?.filter(item => item?.category?.code === categoryCode)
      ?.sort((a, b) => (a?.display || "")?.localeCompare(b?.display || ""))
      .map(item => ({
        id: item?.id!,
        code: item?.code!,
        display: item?.display!,
        type: item?.fieldType?.type!,
      })) || [],
    "code",
    "display"
  );
};

export const getConditionOperatorsOptions = (decisionPlanFields: Field[], fieldCode: string) => {
  return (
    decisionPlanFields
      ?.find(item => item?.code === fieldCode)
      ?.operators?.map(operator => operatorOptionsMap[operator!])
      ?.sort((a, b) => a?.value?.localeCompare(b?.value)) || []
  );
};

export const getConditionAllowedValuesOptions = (decisionPlanFields: Field[], fieldCode: string) => {
  const allowedValues = (decisionPlanFields?.find(item => item?.code === fieldCode)?.allowedValues || []) as string[];
  const allowedValuesOptions = mapStringsToAutocompleteOptions(allowedValues, toSentenceCase);

  return allowedValuesOptions?.sort((a, b) => a?.value?.localeCompare(b?.value));
};

export const convertDecisionPlanToFormValues = (
  decisionPlan: DecisionPlan,
  healthParameterCode?: string,
  healthRiskParameter?: IDecisionHealthParameterRiskParameter
): Partial<IDecisionUpsertFormValues> => {
  return {
    name:
      healthParameterCode && healthRiskParameter?.id
        ? `${healthParameterCode}.${healthRiskParameter.id}`
        : healthParameterCode || undefined,
    actionFields: convertDecisionPlanActionFieldsToFormValues(
      decisionPlan?.actionDefinitionFields as ActionDefinitionField[],
      healthRiskParameter
    ),
    conditionsGroups: convertDecisionPlanConditionGroupToFormValues(decisionPlan?.field as Field[]),
  };
};
