export const getMutationErrors = (obj: any) => {
  if (!obj || typeof obj !== "object") return null;

  const errors: { field: string; message: string }[] = [];

  for (const key in obj) {
    if (key?.toLowerCase()?.includes("errors") && Array.isArray(obj[key])) {
      errors?.push(...obj[key]);
    } else if (typeof obj[key] === "object") {
      const nestedErrors = getMutationErrors(obj[key]);

      if (nestedErrors) {
        errors.push(...nestedErrors);
      }
    }
  }

  return errors.length ? errors : [];
};
