import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useVendorsAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { IVendorAutocomplete } from "./schema";

export const createVendorAutocompleteOption = (vendor: IVendorAutocomplete) => {
  return createAutocompleteOption(
    {
      id: vendor?.id!,
      name: vendor?.name!,
      nameAr: vendor?.nameAr!,
    },
    "id",
    item => pickLocalizedValue(item?.name, item?.nameAr)
  );
};

export const getVendorsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "vendors",
    query: useVendorsAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.name, item?.nameAr!),
    backendAccessor: "id",
  });
};
