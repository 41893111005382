import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { ProviderIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import {
  ProviderBranchCreateContainer,
  ProviderBranchListContainer,
  ProviderBranchUpdateContainer,
  ProviderCreateContainer,
  ProviderListContainer,
  ProviderUpdateContainer,
} from "../containers";
import { providersPaths, providersRoute } from "./ProvidersPaths";

export const providersRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "provider-routes",
    text: i18n.t("Health Providers", { ns: "admin" }),
    route: providersRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageVendors),
    icon: <ProviderIcon />,
    subItems: [
      {
        id: "provider-list-route",
        route: providersPaths.list.route,
        fullPath: providersPaths.list.fullPath,
        element: <ProviderListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "provider-create-route",
        route: providersPaths.create.route,
        fullPath: providersPaths.create.fullPath,
        hidden: true,
        element: <ProviderCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "provider-update-route",
        route: providersPaths.update.route,
        fullPath: providersPaths.update.fullPath,
        hidden: true,
        element: <ProviderUpdateContainer />,
        onClick: route => navigate(route),
      },
      {
        id: "provider-branch-list-route",
        route: providersPaths.branchList.route,
        fullPath: providersPaths.branchList.fullPath,
        hidden: true,
        element: <ProviderBranchListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "provider-branch-create-route",
        route: providersPaths.branchCreate.route,
        fullPath: providersPaths.branchCreate.fullPath,
        hidden: true,
        element: <ProviderBranchCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "provider-branch-update-route",
        route: providersPaths.branchUpdate.route,
        fullPath: providersPaths.branchUpdate.fullPath,
        hidden: true,
        element: <ProviderBranchUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
