import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProcedureAgeDeleteMutationVariables = Types.Exact<{
  deleteProcedureAgeId: Types.Scalars['ID']['input'];
}>;


export type ProcedureAgeDeleteMutation = { __typename?: 'Mutation', deleteProcedureAge?: { __typename?: 'ProcedureAge', id: string } | null };


export const ProcedureAgeDeleteDocument = gql`
    mutation ProcedureAgeDelete($deleteProcedureAgeId: ID!) {
  deleteProcedureAge(id: $deleteProcedureAgeId) {
    id
  }
}
    `;
export type ProcedureAgeDeleteMutationFn = Apollo.MutationFunction<ProcedureAgeDeleteMutation, ProcedureAgeDeleteMutationVariables>;

/**
 * __useProcedureAgeDeleteMutation__
 *
 * To run a mutation, you first call `useProcedureAgeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcedureAgeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [procedureAgeDeleteMutation, { data, loading, error }] = useProcedureAgeDeleteMutation({
 *   variables: {
 *      deleteProcedureAgeId: // value for 'deleteProcedureAgeId'
 *   },
 * });
 */
export function useProcedureAgeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ProcedureAgeDeleteMutation, ProcedureAgeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcedureAgeDeleteMutation, ProcedureAgeDeleteMutationVariables>(ProcedureAgeDeleteDocument, options);
      }
export type ProcedureAgeDeleteMutationHookResult = ReturnType<typeof useProcedureAgeDeleteMutation>;
export type ProcedureAgeDeleteMutationResult = Apollo.MutationResult<ProcedureAgeDeleteMutation>;
export type ProcedureAgeDeleteMutationOptions = Apollo.BaseMutationOptions<ProcedureAgeDeleteMutation, ProcedureAgeDeleteMutationVariables>;