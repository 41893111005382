import { AppTypes, OrderDirection, PermissionEnum, UserSortField } from "@/schema/types";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { GridProvider, useUserDeleteMutation } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { usersAdminsPaths } from "../../constants";
import { useUserAdminListQuery } from "../../gql";
import { useSetUsersAdminsBreadcrumbs } from "../../hooks";
import { IUserAdminListNode } from "../../types";
import { useUserAdminListContainerColumns } from "./useUserAdminListContainerColumns";

export const UserAdminListContainer = () => {
  const navigate = useNavigate();
  const { userInfo } = useOidcUserProfile();

  const hasManageUsersPermission = hasPermission(PermissionEnum.ManageUsers);

  const isRowEditable = (item: IUserAdminListNode) => {
    return hasManageUsersPermission && item?.id !== userInfo?.user_id?.toString();
  };

  const onAddNewItemClick = () => {
    navigate(usersAdminsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IUserAdminListNode) => {
    navigate(usersAdminsPaths.update.fullPathWithParams({ userAdminId: item?.id }));
  };

  useSetUsersAdminsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"userAdminList"}
      query={useUserAdminListQuery}
      columns={useUserAdminListContainerColumns()}
      hideFilterInput
      hasTableSetting
      variables={{
        filter: {
          appType: [AppTypes.Admin],
        },
        sortBy: {
          direction: OrderDirection.Desc,
          field: UserSortField.DateJoined,
        },
      }}
      tableAction={{
        isEditVisible: true,
        isRowEditable,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
        isRowDeletable: isRowEditable,
      }}
      deleteItemProps={{
        entityTypeName: "User",
        name: "User Admin",
        useDeleteMutation: useUserDeleteMutation,
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />

            {hasManageUsersPermission && <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
