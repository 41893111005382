import { QuestionType } from "@/schema/types";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import {
  Button,
  FormNumberField,
  FormTextField,
  Grid,
  IconButton,
  MuiDeleteForeverIcon,
  PlusIcon,
  useIsMobileView,
  useTheme,
} from "@toolkit/ui";
import { isNil, uniqueId } from "lodash";
import { useFieldArray } from "react-hook-form";
import { IMedicalFormQuestionUpsertFormValues } from "../MedicalFormQuestionUpsert/MedicalFormQuestionUpsertFormSchema";

export const MedicalFormQuestionOptionUpsertForm = () => {
  const { t } = useTranslation("domains");
  const isMobileView = useIsMobileView();
  const theme = useTheme();

  const form = useCustomFormContext<IMedicalFormQuestionUpsertFormValues>();
  const { watch, control } = form;

  const { append, remove } = useFieldArray<IMedicalFormQuestionUpsertFormValues, "options">({
    control: control,
    name: "options",
  });

  const options = watch("options");
  const isQuestionTypeBoolean = watch("questionType")?.value === QuestionType.Boolean;

  const isAddOptionDisabled = !!options.find(item => !item.value || isNil(item?.score));
  const isRemoveQuestionAvailable = !isQuestionTypeBoolean && options.length !== 1;

  const onAddQuestionClick = () => {
    append({
      id: uniqueId("field-"),
      value: "",
      score: null,
    });
  };

  const onRemoveQuestionClick = (index: number) => {
    remove(index);
  };

  return (
    <Grid container spacing={2}>
      {options.map((option, index) => {
        return (
          <Grid key={option?.id} item xs={12}>
            <Grid container alignItems='center' flexWrap={isMobileView ? "nowrap" : "wrap"}>
              <Grid item xs={isQuestionTypeBoolean ? 12 : 10}>
                <Grid container spacing={1} alignItems={"center"}>
                  <Grid item xs={8}>
                    <FormTextField
                      name={`options.${index}.value`}
                      placeholder={t("Option")}
                      label={t("Option")}
                      disabled={isQuestionTypeBoolean}
                    />
                  </Grid>

                  <Grid item xs={isRemoveQuestionAvailable ? 3 : 4}>
                    <FormNumberField name={`options.${index}.score`} placeholder={t("Score")} label={t("Score")} />
                  </Grid>

                  {isRemoveQuestionAvailable && (
                    <Grid item xs={1}>
                      <IconButton onClick={() => onRemoveQuestionClick(index)}>
                        <MuiDeleteForeverIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {!isQuestionTypeBoolean && index === 0 && (
                <Grid item xs={2} textAlign={"end"}>
                  <Button
                    onClick={onAddQuestionClick}
                    disabled={isAddOptionDisabled}
                    startIcon={<PlusIcon fill={theme.palette.common.white} />}
                  >
                    {t("Add Option")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};
