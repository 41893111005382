import { BenefitMapSortingField, SortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { benefitMapsPaths } from "../../constants";
import { useBenefitMapDeleteMutation, useBenefitMapListQuery } from "../../gql";
import { useSetBenefitMapsBreadcrumbs } from "../../hooks/useSetBenefitMapsBreadcrumbs";
import { IBenefitMapListNode } from "../../types";
import { useBenefitMapListContainerColumns } from "./useBenefitMapListContainerColumns";

export const BenefitMapListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(benefitMapsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IBenefitMapListNode) => {
    navigate(benefitMapsPaths.update.fullPathWithParams({ benefitMapId: item?.id }));
  };

  useSetBenefitMapsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"benefitMapList"}
      query={useBenefitMapListQuery}
      columns={useBenefitMapListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: BenefitMapSortingField.BenefitCode,
          direction: SortDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "BenefitMap",
        name: "Benefit Map",
        useDeleteMutation: useBenefitMapDeleteMutation,
        idPropName: "deleteBenefitMapId",
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
