import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaEncounterTypesAutocompleteQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OptimaEncounterTypesAutocompleteQuery = { __typename?: 'Query', optimaEncounterTypes?: Array<{ __typename?: 'OptimaEncounterType', id: string, name: string } | null> | null };


export const OptimaEncounterTypesAutocompleteDocument = gql`
    query OptimaEncounterTypesAutocomplete {
  optimaEncounterTypes {
    id
    name
  }
}
    `;

/**
 * __useOptimaEncounterTypesAutocompleteQuery__
 *
 * To run a query within a React component, call `useOptimaEncounterTypesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaEncounterTypesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaEncounterTypesAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useOptimaEncounterTypesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>(OptimaEncounterTypesAutocompleteDocument, options);
      }
export function useOptimaEncounterTypesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>(OptimaEncounterTypesAutocompleteDocument, options);
        }
export function useOptimaEncounterTypesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>(OptimaEncounterTypesAutocompleteDocument, options);
        }
export type OptimaEncounterTypesAutocompleteQueryHookResult = ReturnType<typeof useOptimaEncounterTypesAutocompleteQuery>;
export type OptimaEncounterTypesAutocompleteLazyQueryHookResult = ReturnType<typeof useOptimaEncounterTypesAutocompleteLazyQuery>;
export type OptimaEncounterTypesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useOptimaEncounterTypesAutocompleteSuspenseQuery>;
export type OptimaEncounterTypesAutocompleteQueryResult = Apollo.QueryResult<OptimaEncounterTypesAutocompleteQuery, OptimaEncounterTypesAutocompleteQueryVariables>;