import { BlockOrderField, OrderDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { blocksPaths } from "../../constants/BlocksPaths";
import { useGetAllBlockQuery } from "../../gql/queries";
import { useBlockListContainerColumns } from "./useBlockListContainerColumns";
import { useSetBlocksBreadcrumbs } from "../../hooks";

export const BlockListContainer = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(blocksPaths.create.fullPath);
  };

  const onEditRowClick = item => {
    navigate(blocksPaths.update.fullPathWithParams({ blockId: item?.id }));
  };

  useSetBlocksBreadcrumbs("LIST");

  return (
    <GridProvider
      hasTableSetting
      gridName={"blockList"}
      query={useGetAllBlockQuery}
      columns={useBlockListContainerColumns()}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          field: BlockOrderField.Created,
          direction: OrderDirection.Desc,
        },
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
