import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";
import { usersPermissionsGroupsPaths, usersPermissionsGroupsRoute } from "./UsersPermissionsGroupsPaths";
import { UserPermissionGroupListContainer, UserPermissionGroupCreateContainer, UserPermissionGroupUpdateContainer } from "../containers";

export const usersPermissionsGroupsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  const hasManagePermissionGroupPermission = hasPermission(PermissionEnum.ManagePermissionGroup);

  return {
    id: "users-permissions-routes",
    text: i18n.t("Users Permissions", { ns: "admin" }),
    route: usersPermissionsGroupsRoute,
    isProhibited: !hasManagePermissionGroupPermission,
    subItems: [
      {
        id: "user-permission-list-route",
        route: usersPermissionsGroupsPaths.list.route,
        fullPath: usersPermissionsGroupsPaths.list.fullPath,
        element: <UserPermissionGroupListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-permission-create-route",
        route: usersPermissionsGroupsPaths.create.route,
        fullPath: usersPermissionsGroupsPaths.create.fullPath,
        hidden: true,
        element: <UserPermissionGroupCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-permission-update-route",
        route: usersPermissionsGroupsPaths.update.route,
        fullPath: usersPermissionsGroupsPaths.update.fullPath,
        hidden: true,
        element: <UserPermissionGroupUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
