import { ItemCodeMaximumQuantityAllowedSortingField, SortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { itemCodeMaximumQuantityAllowedsPaths } from "../../constants/ItemCodeMaximumQuantityAllowedPaths";
import { useItemCodeMaximumQuantityAllowedDeleteMutation } from "../../gql/mutations/__generated__";
import { useItemCodeMaximumQuantityAllowedListQuery } from "../../gql/queries/__generated__";
import { useSetItemCodeMaximumQuantityAllowedsBreadcrumbs } from "../../hooks/useSetItemCodeMaximumQuantityAllowedsBreadcrumbs";
import { IItemCodeMaximumQuantityAllowedListNode } from "../../types";
import { useItemCodeMaximumQuantityAllowedListContainerColumns } from "./useItemCodeMaximumQuantityAllowedListContainerColumns";

export const ItemCodeMaximumQuantityAllowedListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(itemCodeMaximumQuantityAllowedsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IItemCodeMaximumQuantityAllowedListNode) => {
    navigate(itemCodeMaximumQuantityAllowedsPaths.update.fullPathWithParams({ itemCodeMaximumQuantityAllowedId: item?.id }));
  };

  useSetItemCodeMaximumQuantityAllowedsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"itemCodeMaximumQuantityAllowedList"}
      query={useItemCodeMaximumQuantityAllowedListQuery}
      columns={useItemCodeMaximumQuantityAllowedListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
        isDeleteVisible: true,
      }}
      variables={{
        sortBy: {
          field: ItemCodeMaximumQuantityAllowedSortingField.ItemCode,
          direction: SortDirection.Desc,
        },
      }}
      deleteItemProps={{
        entityTypeName: "ItemCodeMaximumQuantityAllowed",
        name: "Item Code Maximum Quantity Allowed",
        useDeleteMutation: useItemCodeMaximumQuantityAllowedDeleteMutation,
        idPropName: "deleteItemCodeMaximumQuantityAllowedId",
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
