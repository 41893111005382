import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CptHcpcPrimaryProcedureGetQueryVariables = Types.Exact<{
  getCptHcpcPrimaryProcedureId: Types.Scalars['ID']['input'];
}>;


export type CptHcpcPrimaryProcedureGetQuery = { __typename?: 'Query', getCptHcpcPrimaryProcedure?: { __typename?: 'CptHcpcPrimaryProcedure', primaryProcedure: string, itemCode: string, description?: string | null } | null };


export const CptHcpcPrimaryProcedureGetDocument = gql`
    query CptHcpcPrimaryProcedureGet($getCptHcpcPrimaryProcedureId: ID!) {
  getCptHcpcPrimaryProcedure(id: $getCptHcpcPrimaryProcedureId) {
    primaryProcedure
    itemCode
    description
  }
}
    `;

/**
 * __useCptHcpcPrimaryProcedureGetQuery__
 *
 * To run a query within a React component, call `useCptHcpcPrimaryProcedureGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCptHcpcPrimaryProcedureGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCptHcpcPrimaryProcedureGetQuery({
 *   variables: {
 *      getCptHcpcPrimaryProcedureId: // value for 'getCptHcpcPrimaryProcedureId'
 *   },
 * });
 */
export function useCptHcpcPrimaryProcedureGetQuery(baseOptions: Apollo.QueryHookOptions<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables> & ({ variables: CptHcpcPrimaryProcedureGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables>(CptHcpcPrimaryProcedureGetDocument, options);
      }
export function useCptHcpcPrimaryProcedureGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables>(CptHcpcPrimaryProcedureGetDocument, options);
        }
export function useCptHcpcPrimaryProcedureGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables>(CptHcpcPrimaryProcedureGetDocument, options);
        }
export type CptHcpcPrimaryProcedureGetQueryHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureGetQuery>;
export type CptHcpcPrimaryProcedureGetLazyQueryHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureGetLazyQuery>;
export type CptHcpcPrimaryProcedureGetSuspenseQueryHookResult = ReturnType<typeof useCptHcpcPrimaryProcedureGetSuspenseQuery>;
export type CptHcpcPrimaryProcedureGetQueryResult = Apollo.QueryResult<CptHcpcPrimaryProcedureGetQuery, CptHcpcPrimaryProcedureGetQueryVariables>;