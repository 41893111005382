import { coreConfig } from "../coreConfig";
import { FrontEndApps } from "../types/FrontEndApps";

export const runningFrontEndAppUtils = {
  isAdmin: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.Admin;
  },
  isOptima: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.Optima;
  },
  isConsumer: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.Consumer;
  },
  isDigitalTwin: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.DigitalTwin;
  },
  isPatientProfile: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.PatientProfile;
  },
  isProvider: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.Provider;
  },
  isGCAdmin: () => {
    const app = coreConfig.get("app");
    return app === FrontEndApps.GcAdmin;
  },
  getAppName: () => {
    return coreConfig.get("app");
  },
};
