import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { SublistFilterInput } from "../../schema/types";
import { useSubListsAutocompleteQuery } from "./gql";

type SubListsAutocompleteProps = IAutocompleteProps<SublistFilterInput>;

export const SubListsAutocomplete: FC<SubListsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useSubListsAutocompleteQuery,
    variables: { filter },
    searchKey: "display",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "display");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Lists") : t("List"))}
    />
  );
};
