import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionHealthParametersAutocompleteQuery } from "../../gql";

type DecisionHealthParametersAutocompleteProps = {
  name: string;
  label?: string;
};

export const DecisionHealthParametersAutocomplete: FC<DecisionHealthParametersAutocompleteProps> = props => {
  const { name, label } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionHealthParametersAutocompleteQuery,
    variables: {
      filter: {
        categoryCode: ["RISK_PARAMETER", "OVERALL_RISK_PARAMETER"],
      },
    },
    searchKey: pickLocalizedValue("display", "arabicDisplay"),
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.name!, item?.nameAr!));

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={label || t("Health Parameter")} />;
};
