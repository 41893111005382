import { BulkTemplateCode, PermissionEnum } from "@/schema/types";
import { FileDownload, FileImportDialog } from "@health/domains";
import { hasPermission, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { healthProgramMembersPaths } from "../../constants";

type HealthProgramMemberListPageWrapperProps = {
  memberListId: string | null;
};

export const HealthProgramMemberListPageWrapper: FC<HealthProgramMemberListPageWrapperProps> = props => {
  const { memberListId } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { open, handleToggle } = useOpenState();
  const { customTableProps } = useGridContext();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const onAddNewItemClick = () => {
    const createPath = healthProgramMembersPaths.create.fullPath;
    const createPathWithParams = memberListId ? `${createPath}?memberListId=${memberListId}` : createPath;
    navigate(createPathWithParams);
  };

  const handleSuccessUpload = () => {
    customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  };

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          <TableSettingComponent />

          {hasManageHealthProgramsPermission && (
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick}>
              {memberListId && (
                <>
                  <FileImportDialog
                    title={t("Import Member")}
                    subTitle={t("Upload Members list you want to get")}
                    isOpen={open}
                    onToggle={handleToggle}
                    onSuccessUpload={handleSuccessUpload}
                    templateCode={BulkTemplateCode.HealthProgramMember}
                  />

                  <FileDownload templateCode={BulkTemplateCode.HealthProgramMember} />
                </>
              )}
            </MainListActions>
          )}
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};
