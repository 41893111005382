import { questionTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { AlignLeftIcon, Box, CheckboxIcon, FormAutocomplete, MuiExpandCircleDownRoundedIcon, MuiThumbsUpDownIcon } from "@toolkit/ui";
import { FC, ComponentProps } from "react";

import { makeStyles } from "@toolkit/ui";
import { QuestionType } from "@/schema/types";

export const useStyle = makeStyles()(() => ({
  questionTypeOptionWrapper: {
    paddingInline: 12,
  },
  questionTypeOptionValue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },
  divider: {
    width: "100%",
  },
}));

export const getOptionIcon = (questionType: QuestionType, primary: string) => {
  switch (questionType) {
    case QuestionType.Boolean:
      return <MuiThumbsUpDownIcon sx={{ color: primary }} />;
    case QuestionType.MultiChoice:
      return <CheckboxIcon fill={primary} />;
    case QuestionType.SingleChoice:
      return <MuiExpandCircleDownRoundedIcon fill={primary} />;
    case QuestionType.String:
      return <AlignLeftIcon fill={primary} />;
    default:
      return null;
  }
};

type FormQuestionTypeFieldProps = ComponentProps<typeof FormAutocomplete>;

export const FormQuestionTypeField: FC<FormQuestionTypeFieldProps> = props => {
  const { t } = useTranslation("admin");
  const { classes, theme } = useStyle();

  return (
    <FormAutocomplete
      label={t("Question Type")}
      options={questionTypeOptions}
      renderOption={(_, option) => {
        return (
          <Box component={"li"} className={classes.questionTypeOptionWrapper} {..._}>
            <Box className={classes.questionTypeOptionValue}>{getOptionIcon(option?.value, theme.palette.primary.main)}</Box>
            <Box>{option?.label}</Box>
          </Box>
        );
      }}
      {...props}
    />
  );
};
