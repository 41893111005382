import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { surveysPaths } from "../constants";

export const useSetSurveysBreadcrumbs = (containerType: "LIST" | "NEW" | "DETAILS" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Surveys");

  const surveysListBreadcrumb = useMemo(() => {
    return {
      name: t("Surveys"),
      route: surveysPaths.list.fullPath,
    };
  }, [t]);

  const setSurveysListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setSurveysNewContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [surveysListBreadcrumb, { name: t("New Survey") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, surveysListBreadcrumb, t]);

  const setSurveysDetailsContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [surveysListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, surveysListBreadcrumb]);

  const setSurveysUpdateContainerBreadcrumb = useCallback(
    name => {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [surveysListBreadcrumb, { name: name }],
      });
    },
    [breadcrumbTitle, setBreadCrumb, surveysListBreadcrumb, t]
  );

  useEffect(() => {
    if (containerType === "LIST") {
      setSurveysListContainerBreadcrumb();
    } else if (containerType === "NEW") {
      setSurveysNewContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setSurveysUpdateContainerBreadcrumb(name);
    } else if (containerType === "DETAILS") {
      setSurveysDetailsContainerBreadcrumb();
    }
  }, [containerType, setSurveysListContainerBreadcrumb, setSurveysNewContainerBreadcrumb, setSurveysDetailsContainerBreadcrumb]);
};
