import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkBreadcrumbGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthProgramNetworkFilterInput>;
}>;


export type HealthProgramNetworkBreadcrumbGetQuery = { __typename?: 'Query', healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null } }> } | null };


export const HealthProgramNetworkBreadcrumbGetDocument = gql`
    query HealthProgramNetworkBreadcrumbGet($filter: HealthProgramNetworkFilterInput) {
  healthProgramNetworks(first: 1, filter: $filter) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkBreadcrumbGetQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkBreadcrumbGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkBreadcrumbGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkBreadcrumbGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramNetworkBreadcrumbGetQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>(HealthProgramNetworkBreadcrumbGetDocument, options);
      }
export function useHealthProgramNetworkBreadcrumbGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>(HealthProgramNetworkBreadcrumbGetDocument, options);
        }
export function useHealthProgramNetworkBreadcrumbGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>(HealthProgramNetworkBreadcrumbGetDocument, options);
        }
export type HealthProgramNetworkBreadcrumbGetQueryHookResult = ReturnType<typeof useHealthProgramNetworkBreadcrumbGetQuery>;
export type HealthProgramNetworkBreadcrumbGetLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkBreadcrumbGetLazyQuery>;
export type HealthProgramNetworkBreadcrumbGetSuspenseQueryHookResult = ReturnType<typeof useHealthProgramNetworkBreadcrumbGetSuspenseQuery>;
export type HealthProgramNetworkBreadcrumbGetQueryResult = Apollo.QueryResult<HealthProgramNetworkBreadcrumbGetQuery, HealthProgramNetworkBreadcrumbGetQueryVariables>;