import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionCitiesAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.CityFilterInput>;
}>;


export type DecisionCitiesAutocompleteQuery = { __typename?: 'Query', cities?: { __typename?: 'CityCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'CityCountableEdge', node: { __typename?: 'City', id: string, name: string, nameAr?: string | null } }> } | null };


export const DecisionCitiesAutocompleteDocument = gql`
    query DecisionCitiesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: CityFilterInput) {
  cities(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    `;

/**
 * __useDecisionCitiesAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionCitiesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionCitiesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionCitiesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionCitiesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>(DecisionCitiesAutocompleteDocument, options);
      }
export function useDecisionCitiesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>(DecisionCitiesAutocompleteDocument, options);
        }
export function useDecisionCitiesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>(DecisionCitiesAutocompleteDocument, options);
        }
export type DecisionCitiesAutocompleteQueryHookResult = ReturnType<typeof useDecisionCitiesAutocompleteQuery>;
export type DecisionCitiesAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionCitiesAutocompleteLazyQuery>;
export type DecisionCitiesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionCitiesAutocompleteSuspenseQuery>;
export type DecisionCitiesAutocompleteQueryResult = Apollo.QueryResult<DecisionCitiesAutocompleteQuery, DecisionCitiesAutocompleteQueryVariables>;