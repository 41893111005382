import React from "react";
import { Controller, ControllerProps, FieldValues, useFormContext } from "react-hook-form";
import { FormHelperText } from "../../base/mui";
import { MDTextEditor } from "./MDTextEditor";

interface MDEditorFieldProps<T extends FieldValues> {
  controllerProps: Pick<ControllerProps<T>, "name" | "rules" | "control">;
  defaultValue: T[keyof T];
  placeholder?: string;
  label?: string;
  token?: string;
  direction?: "ltr" | "rtl";
}
export const MDEditorField = <T extends FieldValues>({
  controllerProps,
  defaultValue,
  placeholder,
  label,
  token,
  direction,
}: MDEditorFieldProps<T>): React.ReactElement => {
  const { getFieldState } = useFormContext<T>();
  const { name } = controllerProps || {};
  const fieldState = getFieldState(name!);

  return (
    <>
      <Controller
        {...controllerProps}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MDTextEditor
            token={token}
            label={label}
            placeholder={placeholder}
            value={field.value || ""}
            onChange={field.onChange}
            direction={direction}
          />
        )}
      />

      <FormHelperText error={Boolean(fieldState?.error?.message)}>{fieldState?.error?.message}</FormHelperText>
    </>
  );
};
