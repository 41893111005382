const findFirstValidPath = route => {
  if (route.fullPath && !route.hidden && !route.isProhibited) {
    return route.fullPath;
  }
  if (route.subItems?.length) {
    const validSubItem = route.subItems.find(subItem => {
      return !(subItem.hidden || subItem.isProhibited) && (subItem?.route || subItem?.fullPath);
    });
    return validSubItem?.route || validSubItem?.fullPath;
  }
  return null;
};

export const findFirstAccessibleRoute = (routes, defaultRoutePath: string, excludedRoutes = ["playgrounds-routes"]) => {
  const firstValidRoute = routes.find(route => {
    if (excludedRoutes.includes(route.id)) return false;
    if (route?.hidden || route.isProhibited) return false;
    const validPath = findFirstValidPath(route);
    return !!validPath;
  });

  if (firstValidRoute) {
    return findFirstValidPath(firstValidRoute);
  }
  return defaultRoutePath;
};
