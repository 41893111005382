import { H_OrderDirection, HealthProgramMembershipRequestSortingField } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "@health/domains";
import { useHealthProgramMembershipRequestListQuery } from "../../gql";
import { useSetHealthProgramMembershipRequestsBreadcrumbs } from "../../hooks";
import { HealthProgramMembershipRequestApprove } from "../../components/HealthProgramMembershipRequestApprove/HealthProgramMembershipRequestApprove";
import { HealthProgramMembershipRequestReject } from "../../components/HealthProgramMembershipRequestReject/HealthProgramMembershipRequestReject";
import { useHealthProgramMembershipRequestListContainerColumns } from "./useHealthProgramMembershipRequestListContainerColumns";

export const HealthProgramMembershipRequestListContainer = () => {
  useSetHealthProgramMembershipRequestsBreadcrumbs("LIST");

  return (
    <>
      <HealthProgramMembershipRequestApprove />
      <HealthProgramMembershipRequestReject />

      <GridProvider
        gridName={"hHealthProgramMembershipRequestList"}
        query={useHealthProgramMembershipRequestListQuery}
        columns={useHealthProgramMembershipRequestListContainerColumns()}
        hasTableSetting
        variables={{
          sortBy: {
            direction: H_OrderDirection.Desc,
            field: HealthProgramMembershipRequestSortingField.Created,
          },
        }}
      >
        <PageWrapper
          filters={<FilterGrid />}
          actions={
            <>
              <TableSettingComponent />
              <MainListActions />
            </>
          }
        >
          <TableGrid />
        </PageWrapper>
      </GridProvider>
    </>
  );
};
