import { i18n } from "@toolkit/i18n";
import { ConferenceProviderType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const conferenceProviderTypeOptionsMap: IEnumToOptionsMap<ConferenceProviderType> = {
  [ConferenceProviderType.Default]: {
    key: ConferenceProviderType.Default,
    get label() {
      return i18n.t("Default", { ns: "domains" });
    },
    value: ConferenceProviderType.Default,
  },
  [ConferenceProviderType.AntMedia]: {
    key: ConferenceProviderType.AntMedia,
    get label() {
      return i18n.t("AntMedia", { ns: "domains" });
    },
    value: ConferenceProviderType.AntMedia,
  },
  [ConferenceProviderType.Zoom]: {
    key: ConferenceProviderType.Zoom,
    get label() {
      return i18n.t("Zoom", { ns: "domains" });
    },
    value: ConferenceProviderType.Zoom,
  },
  [ConferenceProviderType.Sanar]: {
    key: ConferenceProviderType.Sanar,
    get label() {
      return i18n.t("Sanar", { ns: "domains" });
    },
    value: ConferenceProviderType.Sanar,
  },
};

export const conferenceProviderTypeOptions = Object.values(conferenceProviderTypeOptionsMap);
