import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { MarketplaceBrandsAutocompleteQuery } from "./gql";

export type IMarketplaceBrandAutocomplete = ExtractNodeType<MarketplaceBrandsAutocompleteQuery>;

export const marketplaceBrandSchema = createZodAutocompleteFromType<IMarketplaceBrandAutocomplete>({
  id: z.string(),
  name: z.string().nullish(),
  nameAr: z.string().nullish(),
});
