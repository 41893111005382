import { makeStyles } from "@toolkit/ui";

export const useEditCentralListItemStyles = makeStyles()(theme => ({
  selectStyle: {
    color: theme.palette.common.black,
    // boxShadow: theme.mixins.shadows.none,
    boxShadow: "none",
    borderRadius: 20,
    option: {
      borderRadius: 20,
    },
  },
  root: {
    width: "100%",
  },
  heading: {
    fontWeight: 600,
    marginLeft: 10,
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
    backgroundColor: theme.palette.gray[400],
  },
  padding: {
    padding: "25px 15px",
  },
  titleColumn: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  column: {
    flexBasis: "33.33%",
    marginRight: 8,
  },
  input: {
    marginRight: 15,
    width: "100%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  createButton: {
    color: theme.palette.common.white,
    background: theme.palette.success.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      background: theme.palette.success.light,
    },
  },
  cancelButton: {
    color: theme.palette.primary.main,
    background: theme.palette.common.black,
    opacity: 0.5,
    marginRight: theme.spacing(2),
  },
}));
