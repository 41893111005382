import { i18n } from "@toolkit/i18n";
import { DestinationType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const destinationTypeOptionsMap: IEnumToOptionsMap<DestinationType> = {
  [DestinationType.Eclaim]: {
    key: DestinationType.Eclaim,
    get label() {
      return i18n.t("Eclaim", { ns: "domains" });
    },
    value: DestinationType.Eclaim,
  },
};

export const destinationTypeOptions = Object.values(destinationTypeOptionsMap);
