import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSubListsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.SubListSortingInput>;
  filter?: Types.InputMaybe<Types.SublistFilterInput>;
}>;


export type GetSubListsQuery = { __typename?: 'Query', subLists?: { __typename?: 'SubListConnection', totalCount?: number | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'SubListEdge', node?: { __typename?: 'SubList', display?: string | null, id?: string | null, field?: { __typename?: 'Field', id?: string | null, display?: string | null, code?: string | null } | null } | null } | null> | null } | null };


export const GetSubListsDocument = gql`
    query getSubLists($first: Int, $after: String, $before: String, $last: Int, $sortBy: SubListSortingInput, $filter: SublistFilterInput) {
  subLists: getSubLists(
    first: $first
    after: $after
    before: $before
    last: $last
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        display
        id
        field {
          id
          display
          code
        }
      }
    }
  }
}
    `;

/**
 * __useGetSubListsQuery__
 *
 * To run a query within a React component, call `useGetSubListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubListsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSubListsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubListsQuery, GetSubListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubListsQuery, GetSubListsQueryVariables>(GetSubListsDocument, options);
      }
export function useGetSubListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubListsQuery, GetSubListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubListsQuery, GetSubListsQueryVariables>(GetSubListsDocument, options);
        }
export function useGetSubListsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubListsQuery, GetSubListsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubListsQuery, GetSubListsQueryVariables>(GetSubListsDocument, options);
        }
export type GetSubListsQueryHookResult = ReturnType<typeof useGetSubListsQuery>;
export type GetSubListsLazyQueryHookResult = ReturnType<typeof useGetSubListsLazyQuery>;
export type GetSubListsSuspenseQueryHookResult = ReturnType<typeof useGetSubListsSuspenseQuery>;
export type GetSubListsQueryResult = Apollo.QueryResult<GetSubListsQuery, GetSubListsQueryVariables>;