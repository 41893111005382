import { i18n } from "@toolkit/i18n";
import { VisitType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const visitTypeOptionsMap: IEnumToOptionsMap<VisitType> = {
  [VisitType.DirectAppointment]: {
    key: VisitType.DirectAppointment,
    get label() {
      return i18n.t("Direct Appointment", { ns: "domains" });
    },
    value: VisitType.DirectAppointment,
  },
  [VisitType.DirectAtHome]: {
    key: VisitType.DirectAtHome,
    get label() {
      return i18n.t("Direct At Home", { ns: "domains" });
    },
    value: VisitType.DirectAtHome,
  },
  [VisitType.DirectCall]: {
    key: VisitType.DirectCall,
    get label() {
      return i18n.t("Direct Call", { ns: "domains" });
    },
    value: VisitType.DirectCall,
  },
  [VisitType.DirectInPerson]: {
    key: VisitType.DirectInPerson,
    get label() {
      return i18n.t("Direct In Person", { ns: "domains" });
    },
    value: VisitType.DirectInPerson,
  },
  [VisitType.HealthProgram]: {
    key: VisitType.HealthProgram,
    get label() {
      return i18n.t("Health Program", { ns: "domains" });
    },
    value: VisitType.HealthProgram,
  },
};

export const visitTypeOptions = Object.values(visitTypeOptionsMap);
