/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { Condition, ConditionInput, ConditionOperation, EntityType, Field, Maybe, Operator } from "@/schema/types";
import { conditionOperationOptionsMap, operatorOptionsMap, userGenderOptionsMap } from "@health/enum-options";
import { toSentenceCase } from "@toolkit/core";
import { pickLocalizedValue } from "@toolkit/i18n";
import { createAutocompleteOption, mapStringsToAutocompleteOptions } from "@toolkit/ui";
import { isObject } from "lodash";
import {
  DecisionConditionFieldCategoryCode,
  IDecisionConditionBackEndValues,
  IDecisionConditionExtraValue,
  IDecisionConditionValues,
  IDecisionPlanFieldsKeyedBy,
} from "../types";
import { IDecisionConditionsGroupsFormValues } from "../forms/DecisionConditionsGroups/DecisionConditionsGroupsFormSchema";
import { IDecisionUpsertFormValues } from "../forms/DecisionUpsert/DecisionUpsertFormSchema";
import { IDecisionConditionsFormValues } from "../forms/DecisionConditions/DecisionConditionsFormSchema";

export const createDecisionConditionFromCode = (
  decisionPlanFields: Field[],
  categoryCode: DecisionConditionFieldCategoryCode,
  code: string,
  conditionOperation?: ConditionOperation
) => {
  const decisionField = decisionPlanFields?.find(item => item?.category?.code === categoryCode);

  return {
    isGroup: false,
    conditionOperation: conditionOperation || undefined,
    entityType: decisionField?.fieldType?.type,
    field: { id: decisionField?.id! },
    operator: Operator.Equal,
    value: {
      valueString: code,
    },
  };
};

export const convertDecisionBaseConditionsFormValuesToBackEndValues = (
  conditionsGroups: IDecisionConditionsGroupsFormValues[]
): ConditionInput[] => {
  return conditionsGroups?.map(conditionGroup => {
    return {
      isGroup: true,
      conditionOperation: conditionGroup?.outerConditionOperation?.value,
      conditions: conditionGroup?.conditions?.map((condition, index) => {
        const type = condition?.field?.value?.type;

        const actionFieldsBackEndValues: IDecisionConditionBackEndValues = {
          valueNumber: undefined,
          valueString: undefined,
          valueList: undefined,
          valueDate: undefined,
          valueID: undefined,
          extra: undefined,
        };

        if (type === EntityType.Number) {
          actionFieldsBackEndValues.valueNumber = condition?.valueNumber!;
        } else if (type === EntityType.String) {
          if (isObject(condition?.valueString)) {
            actionFieldsBackEndValues.valueString = condition?.valueString?.key;
          } else {
            actionFieldsBackEndValues.valueString = condition?.valueString!;
          }
        } else if (type === EntityType.List) {
          actionFieldsBackEndValues.valueList = condition?.valueList?.value?.id;
          actionFieldsBackEndValues.extra = JSON.stringify(condition?.valueList?.value);
        } else if (type === EntityType.Id) {
          actionFieldsBackEndValues.valueID = condition?.valueID?.value?.id;
          actionFieldsBackEndValues.extra = JSON.stringify(condition?.valueID?.value);
        } else if (type === EntityType.Date) {
          actionFieldsBackEndValues.valueDate = condition?.valueDate || undefined;
        }

        return {
          isGroup: false,
          conditionOperation: index === 0 ? null : conditionGroup?.innerConditionOperation?.value,
          entityType: type,
          field: { id: condition?.field?.value?.id },
          operator: condition?.operator?.value,
          value: shouldDecisionConditionOperatorHide(condition?.operator?.value) ? undefined : actionFieldsBackEndValues,
        };
      }),
    };
  });
};

export const convertDecisionConditionsFormValuesToBackEndValues = (
  values: IDecisionUpsertFormValues,
  decisionPlanFields: Field[],
  guidedCareTemplateCode?: string,
  healthParameterCode?: string,
  ioAgentCode?: string
): ConditionInput[] => {
  const baseConditions = values?.isScript ? [] : convertDecisionBaseConditionsFormValuesToBackEndValues(values?.conditionsGroups);

  const healthParameterCondition = healthParameterCode
    ? createDecisionConditionFromCode(decisionPlanFields, DecisionConditionFieldCategoryCode.HealthParameter, healthParameterCode)
    : undefined;

  const guidedCareTemplateCondition = guidedCareTemplateCode
    ? createDecisionConditionFromCode(
        decisionPlanFields,
        DecisionConditionFieldCategoryCode.GuidedCare,
        guidedCareTemplateCode,
        ConditionOperation.And
      )
    : undefined;

  const ioAgentCondition = ioAgentCode
    ? createDecisionConditionFromCode(decisionPlanFields, DecisionConditionFieldCategoryCode.IOAgent, ioAgentCode)
    : undefined;

  const codesConditions = [healthParameterCondition, guidedCareTemplateCondition, ioAgentCondition].filter(Boolean);

  const additionalConditions: ConditionInput[] = [];

  if (codesConditions?.length) {
    additionalConditions.push({
      isGroup: true,
      conditions: codesConditions as ConditionInput[],
    });
  }

  if (additionalConditions.length && baseConditions.length) {
    baseConditions[0] = {
      ...baseConditions[0],
      conditionOperation: ConditionOperation.And,
    };
  }

  return [...additionalConditions, ...baseConditions];
};

export const shouldDecisionConditionOperatorHide = (operator: Operator) => {
  return [Operator.IsEmpty, Operator.IsNotEmpty, Operator.No, Operator.Yes].includes(operator);
};

export const convertDecisionPlanConditionGroupToFormValues = (decisionPlanFields: Field[]): IDecisionConditionsGroupsFormValues[] => {
  return [
    {
      outerConditionOperation: undefined,
      innerConditionOperation: undefined,
      conditions: convertDecisionPlanConditionToFormValues(decisionPlanFields),
    },
  ];
};

export const convertDecisionPlanConditionToFormValues = (decisionPlanFields?: Maybe<Maybe<Field>[]>): [IDecisionConditionsFormValues] => {
  const firstField = decisionPlanFields?.[0];

  return [
    {
      id: firstField?.id!,
      category: createAutocompleteOption(
        {
          code: firstField?.category?.code!,
          display: firstField?.category?.display!,
        },
        "code",
        "display"
      ),
      field: undefined as any,
      operator: undefined as any,
    },
  ];
};

export const convertDecisionConditionGroupsToFormValues = (
  conditionsGroups: Condition[],
  decisionPlanFields: IDecisionPlanFieldsKeyedBy,
  guidedCareTemplateCode?: string,
  healthParameterCode?: string,
  ioAgentCode?: string
) => {
  return (
    conditionsGroups
      ?.filter(item => {
        const containsGuidedCare = item?.conditions?.some(
          condition =>
            condition?.field?.category?.code === DecisionConditionFieldCategoryCode.GuidedCare &&
            condition?.value?.valueString === guidedCareTemplateCode
        );

        const containsHealthParameter = item?.conditions?.some(
          condition =>
            condition?.field?.category?.code === DecisionConditionFieldCategoryCode.HealthParameter &&
            condition?.value?.valueString === healthParameterCode
        );

        const containsIOAgent = item?.conditions?.some(
          condition =>
            condition?.field?.category?.code === DecisionConditionFieldCategoryCode.IOAgent && condition?.value?.valueString === ioAgentCode
        );

        return !(containsGuidedCare || containsHealthParameter || containsIOAgent);
      })
      ?.map(item => {
        return {
          outerConditionOperation: conditionOperationOptionsMap[item?.conditionOperation!] || null,
          innerConditionOperation: conditionOperationOptionsMap[item?.conditions?.[1]?.conditionOperation!] || null,
          conditions: convertDecisionConditionsToFormValues(item?.conditions as Condition[], decisionPlanFields),
        };
      }) || []
  );
};

export const resetDecisionConditionValues = (actionValues: IDecisionConditionValues, except: keyof IDecisionConditionValues) => {
  for (const key in actionValues) {
    if (key !== except) {
      actionValues[key] = undefined;
    }
  }
};

export const convertDecisionConditionsToFormValues = (conditions: Condition[], decisionPlanFields: IDecisionPlanFieldsKeyedBy) => {
  return (
    conditions?.map(item => {
      const decisionField = decisionPlanFields[item?.field?.code!];

      const extra: IDecisionConditionExtraValue = item?.value?.extra ? JSON.parse(item?.value?.extra) : undefined;

      const actionValues: IDecisionConditionValues = {
        valueNumber: undefined,
        valueString: undefined,
        valueList: undefined,
        valueDate: undefined,
        valueID: undefined,
      };

      if (item?.entityType === EntityType.Number) {
        actionValues.valueNumber = item?.value?.valueNumber!;
        resetDecisionConditionValues(actionValues, "valueNumber");
      } else if (item?.entityType === EntityType.String) {
        if (item?.field?.code === "gender") {
          actionValues.valueString = userGenderOptionsMap[item?.value?.valueString!];
        } else if (decisionField?.allowedValues?.length) {
          const allowedValuesOptions = mapStringsToAutocompleteOptions(decisionField?.allowedValues as string[], toSentenceCase);
          actionValues.valueString = allowedValuesOptions.find(option => option?.value === item?.value?.valueString)!;
        } else {
          actionValues.valueString = item?.value?.valueString!;
        }

        resetDecisionConditionValues(actionValues, "valueString");
      } else if (item?.entityType === EntityType.List && extra) {
        actionValues.valueList = createAutocompleteOption(
          {
            id: extra?.id!,
            display: extra?.display!,
          },
          "id",
          "display"
        );

        resetDecisionConditionValues(actionValues, "valueList");
      } else if (item?.entityType === EntityType.Id && extra) {
        actionValues.valueID = createAutocompleteOption(
          {
            id: extra?.id!,
            name: extra?.name!,
            nameAr: extra?.nameAr,
          },
          "id",
          _item => pickLocalizedValue(_item?.name, _item?.nameAr!)
        );

        resetDecisionConditionValues(actionValues, "valueID");
      } else if (item?.entityType === EntityType.Date) {
        actionValues.valueDate = item?.value?.valueDate!;
        resetDecisionConditionValues(actionValues, "valueDate");
      }

      return {
        ...actionValues,
        id: item?.id!,
        conditionOperation: conditionOperationOptionsMap[item?.conditionOperation!] || null,
        category: createAutocompleteOption(
          { code: item?.field?.category?.code!, display: item?.field?.category?.display! },
          "code",
          "display"
        ),
        operator: operatorOptionsMap[item?.operator!],
        field: createAutocompleteOption(
          {
            id: item?.field?.id!,
            code: item?.field?.code!,
            display: item?.field?.display!,
            type: item?.entityType!,
          },
          "code",
          "display"
        ),
        allowedValues: mapStringsToAutocompleteOptions(decisionField?.allowedValues as string[], toSentenceCase),
      };
    }) || []
  );
};
