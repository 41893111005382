import { useTranslation } from "@toolkit/i18n";
import { useSetSurveysBreadcrumbs } from "../../hooks";
import { ISurveyUpsertFormEvent, SurveyUpsertForm } from "../../forms/SurveyUpsert/surveyUpsertForm";
import { useFetchSurveyQuery, useUpdateSurveyMutation } from "../../gql";
import { useNavigate, useParams } from "react-router-dom";
import { ISurveyUpdateContainerParams } from "../../types";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { convertSurveyFormValuesToBackEndValues } from "../../others";

export const SurveyUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const { surveyId } = useParams<ISurveyUpdateContainerParams>();
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { data, loading } = useFetchSurveyQuery({
    variables: {
      surveyId: surveyId!,
    },
    skip: !surveyId,
  });

  const survey = data?.survey;

  const [updateSurveyMutation, { loading: isUpdateLoading }] = useUpdateSurveyMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.updateSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onSurveyUpsertFormChange = (event: ISurveyUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertSurveyFormValuesToBackEndValues(event.payload.values);

      updateSurveyMutation({
        variables: {
          input: values,
          updateSurveyId: surveyId!,
        },
      });
    }
  };

  useSetSurveysBreadcrumbs("UPDATE", survey?.label || "");

  return (
    <SurveyUpsertForm
      buttonLabel={t("Update")}
      survey={survey}
      isLoading={loading}
      isSubmitting={isUpdateLoading}
      onChange={onSurveyUpsertFormChange}
    />
  );
};
