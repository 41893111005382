import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionOptimaEditsAutocompleteQuery } from "@/pages/Decisions/gql";

type DecisionOptimaEditsAutocompleteProps = {
  name: string;
  label?: string;
};

export const DecisionOptimaEditsAutocomplete: FC<DecisionOptimaEditsAutocompleteProps> = props => {
  const { name, label } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionOptimaEditsAutocompleteQuery,
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={label || t("Optima Edits")} />;
};
