import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemCodeMaximumQuantityAllowedDeleteMutationVariables = Types.Exact<{
  deleteItemCodeMaximumQuantityAllowedId: Types.Scalars['ID']['input'];
}>;


export type ItemCodeMaximumQuantityAllowedDeleteMutation = { __typename?: 'Mutation', deleteItemCodeMaximumQuantityAllowed?: { __typename?: 'ItemCodeMaximumQuantityAllowed', id: string } | null };


export const ItemCodeMaximumQuantityAllowedDeleteDocument = gql`
    mutation ItemCodeMaximumQuantityAllowedDelete($deleteItemCodeMaximumQuantityAllowedId: ID!) {
  deleteItemCodeMaximumQuantityAllowed(
    id: $deleteItemCodeMaximumQuantityAllowedId
  ) {
    id
  }
}
    `;
export type ItemCodeMaximumQuantityAllowedDeleteMutationFn = Apollo.MutationFunction<ItemCodeMaximumQuantityAllowedDeleteMutation, ItemCodeMaximumQuantityAllowedDeleteMutationVariables>;

/**
 * __useItemCodeMaximumQuantityAllowedDeleteMutation__
 *
 * To run a mutation, you first call `useItemCodeMaximumQuantityAllowedDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemCodeMaximumQuantityAllowedDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemCodeMaximumQuantityAllowedDeleteMutation, { data, loading, error }] = useItemCodeMaximumQuantityAllowedDeleteMutation({
 *   variables: {
 *      deleteItemCodeMaximumQuantityAllowedId: // value for 'deleteItemCodeMaximumQuantityAllowedId'
 *   },
 * });
 */
export function useItemCodeMaximumQuantityAllowedDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ItemCodeMaximumQuantityAllowedDeleteMutation, ItemCodeMaximumQuantityAllowedDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ItemCodeMaximumQuantityAllowedDeleteMutation, ItemCodeMaximumQuantityAllowedDeleteMutationVariables>(ItemCodeMaximumQuantityAllowedDeleteDocument, options);
      }
export type ItemCodeMaximumQuantityAllowedDeleteMutationHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedDeleteMutation>;
export type ItemCodeMaximumQuantityAllowedDeleteMutationResult = Apollo.MutationResult<ItemCodeMaximumQuantityAllowedDeleteMutation>;
export type ItemCodeMaximumQuantityAllowedDeleteMutationOptions = Apollo.BaseMutationOptions<ItemCodeMaximumQuantityAllowedDeleteMutation, ItemCodeMaximumQuantityAllowedDeleteMutationVariables>;