import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionOptimaEditsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.OptimaEditFilterInput>;
}>;


export type DecisionOptimaEditsAutocompleteQuery = { __typename?: 'Query', optimaEdits?: { __typename?: 'OptimaEditConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges: Array<{ __typename?: 'OptimaEditEdge', node: { __typename?: 'OptimaEdit', id?: string | null, name?: string | null } }> } | null };


export const DecisionOptimaEditsAutocompleteDocument = gql`
    query DecisionOptimaEditsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: OptimaEditFilterInput) {
  optimaEdits(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        name: message
      }
    }
  }
}
    `;

/**
 * __useDecisionOptimaEditsAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionOptimaEditsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionOptimaEditsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionOptimaEditsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionOptimaEditsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>(DecisionOptimaEditsAutocompleteDocument, options);
      }
export function useDecisionOptimaEditsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>(DecisionOptimaEditsAutocompleteDocument, options);
        }
export function useDecisionOptimaEditsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>(DecisionOptimaEditsAutocompleteDocument, options);
        }
export type DecisionOptimaEditsAutocompleteQueryHookResult = ReturnType<typeof useDecisionOptimaEditsAutocompleteQuery>;
export type DecisionOptimaEditsAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionOptimaEditsAutocompleteLazyQuery>;
export type DecisionOptimaEditsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionOptimaEditsAutocompleteSuspenseQuery>;
export type DecisionOptimaEditsAutocompleteQueryResult = Apollo.QueryResult<DecisionOptimaEditsAutocompleteQuery, DecisionOptimaEditsAutocompleteQueryVariables>;