import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaEditCreateMutationVariables = Types.Exact<{
  input: Types.OptimaEditInput;
}>;


export type OptimaEditCreateMutation = { __typename?: 'Mutation', optimaEditCreate?: { __typename?: 'OptimaEdit', code?: string | null } | null };


export const OptimaEditCreateDocument = gql`
    mutation OptimaEditCreate($input: OptimaEditInput!) {
  optimaEditCreate(input: $input) {
    code
  }
}
    `;
export type OptimaEditCreateMutationFn = Apollo.MutationFunction<OptimaEditCreateMutation, OptimaEditCreateMutationVariables>;

/**
 * __useOptimaEditCreateMutation__
 *
 * To run a mutation, you first call `useOptimaEditCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaEditCreateMutation, { data, loading, error }] = useOptimaEditCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptimaEditCreateMutation(baseOptions?: Apollo.MutationHookOptions<OptimaEditCreateMutation, OptimaEditCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaEditCreateMutation, OptimaEditCreateMutationVariables>(OptimaEditCreateDocument, options);
      }
export type OptimaEditCreateMutationHookResult = ReturnType<typeof useOptimaEditCreateMutation>;
export type OptimaEditCreateMutationResult = Apollo.MutationResult<OptimaEditCreateMutation>;
export type OptimaEditCreateMutationOptions = Apollo.BaseMutationOptions<OptimaEditCreateMutation, OptimaEditCreateMutationVariables>;