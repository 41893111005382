import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { BranchesHealthLicenseAutocompleteQuery } from "./gql";

export type IBranchHealthLicenseAutocomplete = ExtractNodeType<BranchesHealthLicenseAutocompleteQuery>;

export const branchHealthLicenseSchema = createZodAutocompleteFromType<IBranchHealthLicenseAutocomplete>({
  id: z.string(),
  name: z.string(),
  nameAr: z.string().nullish(),
  healthLicense: z.string().nullish(),
});
