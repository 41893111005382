import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useSubscriptionPlansAutocompleteQuery } from "./gql";
import { ISubscriptionPlanAutocomplete } from "./schema";

export const createSubscriptionPlanAutocompleteOption = (payer: ISubscriptionPlanAutocomplete) => {
  return createAutocompleteOption({ id: payer?.id!, name: payer?.name! }, "id", "name");
};

export const getSubscriptionPlansAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "plans",
    query: useSubscriptionPlansAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
