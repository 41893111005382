import { z } from "zod";

export const healthProgramMembershipRequestRejectFormSchema = z.object({
  reason: z.string(),
});

export type IHealthProgramMembershipRequestRejectForm = z.infer<typeof healthProgramMembershipRequestRejectFormSchema>;

export const healthProgramMembershipRequestRejectFormDefaultValues: Partial<IHealthProgramMembershipRequestRejectForm> = {
  reason: undefined,
};
