import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ChatFlowFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.ChatFlowSortingInput>;
}>;


export type ChatFlowsQuery = { __typename?: 'Query', chatFlows?: { __typename?: 'ChatFlowConnection', edges: Array<{ __typename?: 'ChatFlowEdge', node: { __typename?: 'ChatFlow', code: string, conditionsAndRules?: string | null, createdAt: any, description?: string | null, id: string, name?: string | null, updatedAt: any, externalId?: string | null, contributors?: Array<{ __typename?: 'User', id: string, fullName?: string | null }> | null, documents?: Array<{ __typename?: 'ChatFlowDocument', id: string }> | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const ChatFlowsDocument = gql`
    query ChatFlows($after: String, $before: String, $filter: ChatFlowFilterInput, $first: Int, $last: Int, $sortBy: ChatFlowSortingInput) {
  chatFlows(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        code
        conditionsAndRules
        createdAt
        description
        id
        name
        updatedAt
        externalId
        contributors {
          id
          fullName
        }
        documents {
          id
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useChatFlowsQuery__
 *
 * To run a query within a React component, call `useChatFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatFlowsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useChatFlowsQuery(baseOptions?: Apollo.QueryHookOptions<ChatFlowsQuery, ChatFlowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatFlowsQuery, ChatFlowsQueryVariables>(ChatFlowsDocument, options);
      }
export function useChatFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatFlowsQuery, ChatFlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatFlowsQuery, ChatFlowsQueryVariables>(ChatFlowsDocument, options);
        }
export function useChatFlowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChatFlowsQuery, ChatFlowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatFlowsQuery, ChatFlowsQueryVariables>(ChatFlowsDocument, options);
        }
export type ChatFlowsQueryHookResult = ReturnType<typeof useChatFlowsQuery>;
export type ChatFlowsLazyQueryHookResult = ReturnType<typeof useChatFlowsLazyQuery>;
export type ChatFlowsSuspenseQueryHookResult = ReturnType<typeof useChatFlowsSuspenseQuery>;
export type ChatFlowsQueryResult = Apollo.QueryResult<ChatFlowsQuery, ChatFlowsQueryVariables>;