import { useCustomFormContext } from "@toolkit/core";
import { Controller } from "react-hook-form";
import React, { FC, FocusEvent } from "react";
import { TextField, TextFieldProps } from "../../../base/mui";

type FormTextFieldProps = Omit<TextFieldProps, "defaultValue" | "error" | "helperText"> & {
  name: string;
};

export const FormTextField: FC<FormTextFieldProps> = props => {
  const { name, variant = "filled", size = "small", fullWidth = true, disabled, ...rest } = props;

  const { control, isFormDisabled } = useCustomFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const onTextFieldBlur = (event: FocusEvent<HTMLInputElement>) => {
          field.onChange(event.target.value.trim());
        };

        return (
          <TextField
            {...rest}
            {...field}
            size={size}
            fullWidth={fullWidth}
            variant={variant}
            error={!!fieldState?.error?.message}
            helperText={fieldState?.error?.message}
            disabled={isFormDisabled || disabled}
            InputLabelProps={{ shrink: field?.value ? true : undefined }}
            onBlur={onTextFieldBlur}
          />
        );
      }}
    />
  );
};
