import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const BlobErrorIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='76' height='76.965' viewBox='0 0 76 76.965' {...props}>
      <g id='Group_12189' data-name='Group 12189' transform='translate(-183 -333.457)'>
        <g id='Group_10686' data-name='Group 10686' transform='translate(183 333.457)'>
          <path
            id='Path_12745'
            data-name='Path 12745'
            d='M48.805,76.183C39.85,77.447,31.29,77.33,22.43,74.69c-8.782-2.651-17.849-7.747-20.989-15.3-3.062-7.562-.228-17.533,3.75-26.032A67.488,67.488,0,0,1,20.986,11.413C27.84,4.97,36.706-.9,44.679.113c7.939,1.054,14.985,8.98,20.214,16.818A63.072,63.072,0,0,1,75.232,41.169c1.634,8.739,1.412,18.479-3.39,24.7-4.756,6.25-14.093,8.976-23.037,10.319'
            transform='translate(-0.149 0)'
            fill='#cf3d3d'
            opacity='0.05'
          />
          <path
            id='Path_12746'
            data-name='Path 12746'
            d='M37.04,75.472c-8.524-1.925-16.217-4.964-23.316-10.383C6.7,59.687.253,51.98,0,44.087c-.174-7.876,5.8-15.91,12.31-22.222A65.206,65.206,0,0,1,34.1,7.459C42.5,3.987,52.522,1.729,59.38,5.37c6.812,3.671,10.461,13.242,12.5,22.11a60.908,60.908,0,0,1,1.035,25.427c-1.519,8.451-5.057,17.169-11.525,21.138-6.44,4.014-15.809,3.277-24.348,1.427'
            transform='translate(1.15 -0.062)'
            fill='#f7e0e0'
          />
        </g>
        <g id='icons8-alert_3_' data-name='icons8-alert (3)' transform='translate(207.554 356.892)'>
          <path
            id='Path_12990'
            data-name='Path 12990'
            d='M31.088,21.274a1.2,1.2,0,0,1-1.046-.614l-7.018-12.6-.015-.027a1.13,1.13,0,0,0-1-.615,1.261,1.261,0,0,0-1.124.647l-4.541,8.206a1.2,1.2,0,1,1-2.093-1.158L18.8,6.9l.008-.014a3.669,3.669,0,0,1,3.249-1.858A3.5,3.5,0,0,1,25.12,6.908L32.132,19.5a1.2,1.2,0,0,1-1.044,1.779Z'
            transform='translate(-6.057 -5.025)'
            fill='#cb3433'
          />
          <path
            id='Path_12991'
            data-name='Path 12991'
            d='M32.314,38.071H7.592a3.531,3.531,0,0,1-3.065-1.752A3.61,3.61,0,0,1,4.47,32.71l0,0,5.263-9.49a1.2,1.2,0,0,1,2.092,1.16L6.565,33.865A1.239,1.239,0,0,0,6.586,35.1a1.152,1.152,0,0,0,1.005.577H32.314a1.206,1.206,0,0,0,1.06-.62,1.128,1.128,0,0,0-.013-1.157l-.022-.038-3.03-5.5a1.2,1.2,0,0,1,2.1-1.154l3.021,5.485a3.5,3.5,0,0,1,.031,3.545A3.618,3.618,0,0,1,32.314,38.071Z'
            transform='translate(-4.014 -8.584)'
            fill='#cb3433'
          />
          <path
            id='Line_1589'
            data-name='Line 1589'
            d='M-.3,8.867a1.2,1.2,0,0,1-1.2-1.2V-.3A1.2,1.2,0,0,1-.3-1.5,1.2,1.2,0,0,1,.892-.3V7.671A1.2,1.2,0,0,1-.3,8.867Z'
            transform='translate(16.242 10.252)'
            fill='#cb3433'
          />
          <circle
            id='Ellipse_2935'
            data-name='Ellipse 2935'
            cx='1.595'
            cy='1.595'
            r='1.595'
            transform='translate(14.343 21.512)'
            fill='#cb3433'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
