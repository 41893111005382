import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { HealthParameterUnitsAutocompleteQuery } from "./gql";

export type IHealthParameterUnitAutocomplete = Omit<
  NonNullable<NonNullable<HealthParameterUnitsAutocompleteQuery["getHealthParameterUnits"]>[number]>,
  "__typename"
>;

export const healthParameterUnitSchema = createZodAutocompleteFromType<IHealthParameterUnitAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  arabicDisplay: z.string().nullish(),
});
