import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { convertInsuranceBenefitApprovalLimitFormValuesToBackEndValues } from "../../others";

import { useInsuranceBenefitApprovalLimitCreateMutation } from "../../gql/mutations/__generated__";
import { insuranceBenefitApprovalLimitsPaths } from "../../constants/InsuranceBenefitApprovalLimitPaths";
import {
  IInsuranceBenefitApprovalLimitUpsertFormEvent,
  InsuranceBenefitApprovalLimitUpsertForm,
} from "../../forms/InsuranceBenefitApprovalLimitUpsert/InsuranceBenefitApprovalLimitUpsertForm";
import { useSetInsuranceBenefitApprovalLimitsBreadcrumbs } from "../../hooks/useSetInsuranceBenefitApprovalLimitsBreadcrumbs";

export const InsuranceBenefitApprovalLimitCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createInsuranceBenefitApprovalLimit, { loading: isSubmitting }] = useInsuranceBenefitApprovalLimitCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.createInsuranceBenefitApprovalLimit?.id) {
        succeeded(t("Insurance Benefit Approval Limits created successfully"));
        navigate(insuranceBenefitApprovalLimitsPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onInsuranceBenefitApprovalLimitUpsertFormChange = (event: IInsuranceBenefitApprovalLimitUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertInsuranceBenefitApprovalLimitFormValuesToBackEndValues(event.payload.values);

      createInsuranceBenefitApprovalLimit({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetInsuranceBenefitApprovalLimitsBreadcrumbs("CREATE");

  return (
    <InsuranceBenefitApprovalLimitUpsertForm
      buttonLabel={t("Create")}
      isSubmitting={isSubmitting}
      onChange={onInsuranceBenefitApprovalLimitUpsertFormChange}
    />
  );
};
