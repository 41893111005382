import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { DecisionActionFieldListCode } from "../../types";
import {
  DecisionChronicTemplatesAutocomplete,
  DecisionHealthParametersAutocomplete,
  DecisionOptimaEditsAutocomplete,
  DecisionRecommendationsAutocomplete,
  DecisionRiskFactorTemplatesAutocomplete,
  DecisionTaskDefinitionsAutocomplete,
} from "../../components";

type DecisionActionAutocompletesFormProps = {
  name: string;
  display: string;
  code: DecisionActionFieldListCode;
};

export const DecisionActionAutocompletesForm: FC<DecisionActionAutocompletesFormProps> = props => {
  const { name, display, code } = props;

  const { t } = useTranslation("domains");

  if (code === DecisionActionFieldListCode.RiskFactorTemplate) {
    return <DecisionRiskFactorTemplatesAutocomplete name={name} label={display} />;
  } else if (code === DecisionActionFieldListCode.ChronicTemplate) {
    return <DecisionChronicTemplatesAutocomplete name={name} label={display} />;
  } else if (code === DecisionActionFieldListCode.TaskDefinition) {
    return <DecisionTaskDefinitionsAutocomplete name={name} label={display} />;
  } else if (code === DecisionActionFieldListCode.Recommendation) {
    return <DecisionRecommendationsAutocomplete name={name} label={display} />;
  } else if (code === DecisionActionFieldListCode.OptimaEdit) {
    return <DecisionOptimaEditsAutocomplete name={name} label={display} />;
  } else if (code === DecisionActionFieldListCode.RiskParameter) {
    return <DecisionHealthParametersAutocomplete name={name} label={display} />;
  } else {
    return <> {t("Not Supported List")} </>;
  }
};
