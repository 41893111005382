import { i18n } from "@toolkit/i18n";
import { InsuranceSectorEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const insuranceSectorEnumOptionsMap: IEnumToOptionsMap<InsuranceSectorEnum> = {
  [InsuranceSectorEnum.General]: {
    key: InsuranceSectorEnum.General,
    get label() {
      return i18n.t("General", { ns: "domains" });
    },
    value: InsuranceSectorEnum.General,
  },
  [InsuranceSectorEnum.Health]: {
    key: InsuranceSectorEnum.Health,
    get label() {
      return i18n.t("Health", { ns: "domains" });
    },
    value: InsuranceSectorEnum.Health,
  },
  [InsuranceSectorEnum.Motor]: {
    key: InsuranceSectorEnum.Motor,
    get label() {
      return i18n.t("Motor", { ns: "domains" });
    },
    value: InsuranceSectorEnum.Motor,
  },
  [InsuranceSectorEnum.Other]: {
    key: InsuranceSectorEnum.Other,
    get label() {
      return i18n.t("Other", { ns: "domains" });
    },
    value: InsuranceSectorEnum.Other,
  },
};

export const insuranceSectorEnumOptions = Object.values(insuranceSectorEnumOptionsMap);
