import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemCodeMaximumQuantityAllowedCreateMutationVariables = Types.Exact<{
  input: Types.ItemCodeMaximumQuantityAllowedInput;
}>;


export type ItemCodeMaximumQuantityAllowedCreateMutation = { __typename?: 'Mutation', createItemCodeMaximumQuantityAllowed?: { __typename?: 'ItemCodeMaximumQuantityAllowed', id: string, itemCode: string, maximumAllowed: number } | null };


export const ItemCodeMaximumQuantityAllowedCreateDocument = gql`
    mutation ItemCodeMaximumQuantityAllowedCreate($input: ItemCodeMaximumQuantityAllowedInput!) {
  createItemCodeMaximumQuantityAllowed(input: $input) {
    id
    itemCode
    maximumAllowed
  }
}
    `;
export type ItemCodeMaximumQuantityAllowedCreateMutationFn = Apollo.MutationFunction<ItemCodeMaximumQuantityAllowedCreateMutation, ItemCodeMaximumQuantityAllowedCreateMutationVariables>;

/**
 * __useItemCodeMaximumQuantityAllowedCreateMutation__
 *
 * To run a mutation, you first call `useItemCodeMaximumQuantityAllowedCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useItemCodeMaximumQuantityAllowedCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [itemCodeMaximumQuantityAllowedCreateMutation, { data, loading, error }] = useItemCodeMaximumQuantityAllowedCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemCodeMaximumQuantityAllowedCreateMutation(baseOptions?: Apollo.MutationHookOptions<ItemCodeMaximumQuantityAllowedCreateMutation, ItemCodeMaximumQuantityAllowedCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ItemCodeMaximumQuantityAllowedCreateMutation, ItemCodeMaximumQuantityAllowedCreateMutationVariables>(ItemCodeMaximumQuantityAllowedCreateDocument, options);
      }
export type ItemCodeMaximumQuantityAllowedCreateMutationHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedCreateMutation>;
export type ItemCodeMaximumQuantityAllowedCreateMutationResult = Apollo.MutationResult<ItemCodeMaximumQuantityAllowedCreateMutation>;
export type ItemCodeMaximumQuantityAllowedCreateMutationOptions = Apollo.BaseMutationOptions<ItemCodeMaximumQuantityAllowedCreateMutation, ItemCodeMaximumQuantityAllowedCreateMutationVariables>;