import { i18n } from "@toolkit/i18n";
import { HealthProgramOptionType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const healthProgramOptionTypeOptionsMap: IEnumToOptionsMap<HealthProgramOptionType> = {
  [HealthProgramOptionType.Include]: {
    key: HealthProgramOptionType.Include,
    get label() {
      return i18n.t("Include", { ns: "domains" });
    },
    value: HealthProgramOptionType.Include,
  },
  [HealthProgramOptionType.Limitation]: {
    key: HealthProgramOptionType.Limitation,
    get label() {
      return i18n.t("Limitation", { ns: "domains" });
    },
    value: HealthProgramOptionType.Limitation,
  },
};

export const healthProgramOptionTypeOptions = Object.values(healthProgramOptionTypeOptionsMap);
