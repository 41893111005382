import { makeStyles } from "@toolkit/ui";

export const useBranchLicenseIdStyle = makeStyles()(() => ({
  root: {
    display: "flex",
  },
  button: {
    paddingBlock: 24,
  },
}));
