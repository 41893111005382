import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramNetworkProviderFilterInput>;
}>;


export type HealthProgramNetworkProviderListQuery = { __typename?: 'Query', healthProgramNetworkProviders?: { __typename?: 'HealthProgramNetworkProviderCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramNetworkProviderCountableEdge', node: { __typename?: 'HealthProgramNetworkProvider', id: string, services?: Array<string | null> | null, isActive?: boolean | null, virtualCallEnabled?: boolean | null, created?: any | null, provider?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, taxLicenseNumber: string, contactPhoneNumber?: string | null, contactMobileNumber?: string | null, address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null } } | null } | null } }> } | null };


export const HealthProgramNetworkProviderListDocument = gql`
    query HealthProgramNetworkProviderList($first: Int, $after: String, $last: Int, $before: String, $filter: HealthProgramNetworkProviderFilterInput) {
  healthProgramNetworkProviders(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        services
        isActive
        virtualCallEnabled
        created
        provider {
          id
          name
          nameAr
          taxLicenseNumber
          contactPhoneNumber
          contactMobileNumber
          address {
            id
            streetAddress1
            streetAddress1Ar
            streetAddress2
            streetAddress2Ar
            city {
              id
              name
              nameAr
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkProviderListQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkProviderListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>(HealthProgramNetworkProviderListDocument, options);
      }
export function useHealthProgramNetworkProviderListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>(HealthProgramNetworkProviderListDocument, options);
        }
export function useHealthProgramNetworkProviderListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>(HealthProgramNetworkProviderListDocument, options);
        }
export type HealthProgramNetworkProviderListQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderListQuery>;
export type HealthProgramNetworkProviderListLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderListLazyQuery>;
export type HealthProgramNetworkProviderListSuspenseQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderListSuspenseQuery>;
export type HealthProgramNetworkProviderListQueryResult = Apollo.QueryResult<HealthProgramNetworkProviderListQuery, HealthProgramNetworkProviderListQueryVariables>;