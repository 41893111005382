import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { benefitMapsRoutes } from "../BenefitMaps/constants";
import { cptHcpcPrimaryProcedureRoutes } from "../CptHcpcPrimaryProcedure/constants/CptHcpcPrimaryProcedureRoutes";
import { diagnosisValidProceduresRoutes } from "../DiagnosisValidProcedure/constants";
import { insuranceBenefitApprovalLimitsRoutes } from "../InsuranceBenefitApprovalLimit/constants/InsuranceBenefitApprovalLimitRoutes";
import { itemCodeMaximumQuantityAllowedsRoutes } from "../ItemCodeMaximumQuantityAllowed/constants/ItemCodeMaximumQuantityAllowedRoutes";
import { procedureAgesRoutes } from "../ProcedureAge/constants/ProcedureAgeRoutes";
import { optimaMedicalNecessityPath } from "./OptimaMedicalNecessityPaths";

export const optimaMedicalNecessityRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-medical-necessity-routes",
    text: t("Optima Medical Necessity", { ns: "admin" }),
    hidden: !hasPermission(PermissionEnum.ManageMedicalNecessity),
    isProhibited: !hasPermission(PermissionEnum.ManageMedicalNecessity),
    route: optimaMedicalNecessityPath,
    subItems: [
      benefitMapsRoutes(navigate),
      cptHcpcPrimaryProcedureRoutes(navigate),
      diagnosisValidProceduresRoutes(navigate),
      insuranceBenefitApprovalLimitsRoutes(navigate),
      itemCodeMaximumQuantityAllowedsRoutes(navigate),
      procedureAgesRoutes(navigate),
    ],
  };
};
