import { ProcessingStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomAlert } from "@toolkit/ui";
import { FC } from "react";

type StatusAlertsPanelProps = {
  processingStatus: string | undefined | null;
  versionNumber?: number | null | undefined;
  userName?: string;
};

const StatusAlertsPanel: FC<StatusAlertsPanelProps> = ({ processingStatus, versionNumber, userName }) => {
  const { t } = useTranslation("domains");
  return (
    <>
      {processingStatus === ProcessingStatus.Approved && (
        <CustomAlert
          level={"module"}
          appearance='success'
          title={t("Ready To Publish")}
          description={t("The data reviewed successfully & get approval by") + " " + `${userName}`}
          disableClose={true}
        />
      )}
      {processingStatus === ProcessingStatus.UnderReview && (
        <CustomAlert
          level={"module"}
          appearance='warning'
          title={t("Waiting for your review.")}
          description={t("Your action is required to review and approve/reject.")}
          disableClose={true}
        />
      )}
      {processingStatus === ProcessingStatus.Draft && (
        <CustomAlert
          level={"module"}
          appearance='error'
          title={t("Be Aware! ")}
          description={t(`You are working and viewing a draft version`)}
          disableClose={true}
        />
      )}
      {processingStatus === ProcessingStatus.Expired && (
        <CustomAlert
          level={"module"}
          appearance='error'
          title={t("Be Aware! ")}
          description={t("You are working and viewing an expired version number") + ": " + `${versionNumber}`}
          disableClose={true}
        />
      )}
    </>
  );
};

export default StatusAlertsPanel;

StatusAlertsPanel.defaultProps = {
  versionNumber: 0,
  userName: "",
};
