import { FormAutocomplete, FormChipsInput, FormNumberField, FormPhoneInput, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { LanguagesAutocomplete, SystemCodeAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { CodeSystemCode } from "@/schema/types";
import { doctorSeniorityOptions } from "@health/enum-options";

export const UserProviderDoctorDetailsInformationForm = () => {
  const { t } = useTranslation("domains");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SystemCodeAutocomplete
          name={"doctorInfo.specializations"}
          label={t("Specializations")}
          multiple
          isCodeHidden
          filter={{ codeSystemCode: CodeSystemCode.Speciality }}
        />
      </Grid>

      <Grid item xs={4}>
        <LanguagesAutocomplete name={"doctorInfo.languages"} multiple />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"doctorInfo.seniority"} label={t("Seniority")} options={doctorSeniorityOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormNumberField name={"doctorInfo.yearsOfExperience"} label={t("Years of Experience")} placeholder={t("Years of Experience")} />
      </Grid>

      <Grid item xs={4}>
        <FormPhoneInput name={"doctorInfo.secondMobileNumber"} label={t("Second Mobile Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"doctorInfo.notificationEmail"} label={t("Notification Email")} placeholder={t("Notification Email")} />
      </Grid>

      <Grid item xs={12}>
        <FormChipsInput name={"doctorInfo.socialLinks"} placeholder={t("Social Links")} />
      </Grid>

      <Grid item xs={12}>
        <FormTextField name={"doctorInfo.bio"} label={t("Bio")} placeholder={t("Bio")} multiline rows={3} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isNationalIdPublic"} label={t("Is National Id Public")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isLanguagesPublic"} label={t("Is Languages Public")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isYearsOfExperiencePublic"} label={t("Is Years Of Experience Public")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isMobileNumberPublic"} label={t("Is Mobile Number Public")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isSecondMobileNumberPublic"} label={t("Is Second Mobile Number Public")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isDateOfBirthPublic"} label={t("Is Date Of Birth Public")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"doctorInfo.isSocialLinksPublic"} label={t("Is Social Links Public")} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
