import { makeStyles } from "tss-react/mui";
import { pickLocalizedValue } from "@toolkit/i18n";

export const useOptimaEditActionsStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  card: {
    padding: 12,
  },
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    marginBottom: 8,
  },
  summaryWrapper: {},
  summary: {
    fontSize: theme.mixins.fonts.fontSize.sm,
    color: theme.palette.gray.main,
    textAlign: pickLocalizedValue("left", "right"),
  },
}));
