import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminPromotionsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.AdminPromotionFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.AdminPromotionSortingInput>;
}>;


export type AdminPromotionsQuery = { __typename?: 'Query', adminPromotions?: { __typename?: 'AdminPromotionConnection', edges: Array<{ __typename?: 'AdminPromotionEdge', cursor?: string | null, node: { __typename?: 'AdminPromotion', content?: string | null, contentAr?: string | null, description?: string | null, descriptionAr?: string | null, endDate?: any | null, id: string, isPublished: boolean, name?: string | null, nameAr?: string | null, photo?: string | null, startDate?: any | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } | null };


export const AdminPromotionsDocument = gql`
    query AdminPromotions($after: String, $before: String, $filter: AdminPromotionFilterInput, $first: Int, $last: Int, $sortBy: AdminPromotionSortingInput) {
  adminPromotions(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      cursor
      node {
        content
        contentAr
        description
        descriptionAr
        endDate
        id
        isPublished
        name
        nameAr
        photo
        startDate
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useAdminPromotionsQuery__
 *
 * To run a query within a React component, call `useAdminPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPromotionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAdminPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminPromotionsQuery, AdminPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminPromotionsQuery, AdminPromotionsQueryVariables>(AdminPromotionsDocument, options);
      }
export function useAdminPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPromotionsQuery, AdminPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminPromotionsQuery, AdminPromotionsQueryVariables>(AdminPromotionsDocument, options);
        }
export function useAdminPromotionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminPromotionsQuery, AdminPromotionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminPromotionsQuery, AdminPromotionsQueryVariables>(AdminPromotionsDocument, options);
        }
export type AdminPromotionsQueryHookResult = ReturnType<typeof useAdminPromotionsQuery>;
export type AdminPromotionsLazyQueryHookResult = ReturnType<typeof useAdminPromotionsLazyQuery>;
export type AdminPromotionsSuspenseQueryHookResult = ReturnType<typeof useAdminPromotionsSuspenseQuery>;
export type AdminPromotionsQueryResult = Apollo.QueryResult<AdminPromotionsQuery, AdminPromotionsQueryVariables>;