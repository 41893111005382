import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { OptimaClinicianFilter } from "../../schema/types";
import { useOptimaCliniciansAutocompleteQuery } from "./gql";

type OptimaCliniciansAutocompleteProps = IAutocompleteProps<OptimaClinicianFilter>;

export const OptimaCliniciansAutocomplete: FC<OptimaCliniciansAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useOptimaCliniciansAutocompleteQuery,
    variables: { filter },
    searchKey: "clinicianNameContains",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "clinicianName");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Clinicians") : t("Clinician"))}
    />
  );
};
