import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistItemUpdateMutationVariables = Types.Exact<{
  subListItem: Types.SubListItemInput;
}>;


export type SublistItemUpdateMutation = { __typename?: 'Mutation', updateSubListItem?: { __typename?: 'SubListItem', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const SublistItemUpdateDocument = gql`
    mutation SublistItemUpdate($subListItem: SubListItemInput!) {
  updateSubListItem(subListItem: $subListItem) {
    errors {
      field
      message
    }
  }
}
    `;
export type SublistItemUpdateMutationFn = Apollo.MutationFunction<SublistItemUpdateMutation, SublistItemUpdateMutationVariables>;

/**
 * __useSublistItemUpdateMutation__
 *
 * To run a mutation, you first call `useSublistItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistItemUpdateMutation, { data, loading, error }] = useSublistItemUpdateMutation({
 *   variables: {
 *      subListItem: // value for 'subListItem'
 *   },
 * });
 */
export function useSublistItemUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SublistItemUpdateMutation, SublistItemUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SublistItemUpdateMutation, SublistItemUpdateMutationVariables>(SublistItemUpdateDocument, options);
      }
export type SublistItemUpdateMutationHookResult = ReturnType<typeof useSublistItemUpdateMutation>;
export type SublistItemUpdateMutationResult = Apollo.MutationResult<SublistItemUpdateMutation>;
export type SublistItemUpdateMutationOptions = Apollo.BaseMutationOptions<SublistItemUpdateMutation, SublistItemUpdateMutationVariables>;