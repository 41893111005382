import { IAutocompleteProps } from "@/types";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { useGuidedCareTeamMembersUsersQuery } from "./gql";

type IGuidedCareTeamUsersAutocompleteFilter = {
  teamId: string;
};

type GuidedCareTeamUsersAutocompleteProps = IAutocompleteProps<IGuidedCareTeamUsersAutocompleteFilter>;

export const GuidedCareTeamUsersAutocomplete: FC<GuidedCareTeamUsersAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, loading } = useGuidedCareTeamMembersUsersQuery({
    variables: {
      id: filter?.teamId!,
    },
    skip: props.disabled || skip,
  });

  const _data =
    data?.providerGuidedCareHealthProgramTeam?.teamMembers?.map(item => ({
      id: item?.id!,
      user: item?.user!,
    })) || [];

  const options = mapToAutocompleteOptions(_data, "id", item => item?.user?.fullName!);

  return <FormAutocomplete {...rest} loading={loading} options={options} label={label || (props.multiple ? t("Users") : t("User"))} />;
};
