import { FormPhoneInput, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const ProviderManagersInformationForm = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"generalManagerEmail"} label={t("General Manager Email")} placeholder={t("General Manager Email")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"financialManagerName"} label={t("Financial Manager Name")} placeholder={t("Financial Manager Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"financialManagerEmail"} label={t("Financial Manager Email")} placeholder={t("Financial Manager Email")} />
      </Grid>

      <Grid item xs={4}>
        <FormPhoneInput name={"financialManagerMobileNumber"} label={t("Financial Manager Mobile Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"purchasingManagerName"} label={t("Purchasing Manager Name")} placeholder={t("Purchasing Manager Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"purchasingManagerEmail"} label={t("Purchasing Manager Email")} placeholder={t("Purchasing Manager Email")} />
      </Grid>

      <Grid item xs={4}>
        <FormPhoneInput name={"purchasingManagerMobileNumber"} label={t("Purchasing Manager Mobile Number")} />
      </Grid>
    </Grid>
  );
};
