import { Plan, Vendor, VendorAdminInput, VendorUpdateAdminInput } from "@/schema/types";
import { createSubscriptionPlanAutocompleteOption } from "@health/autocompletes";
import { vendorTypeOptionsMap } from "@health/enum-options";
import {
  convertAddressFormValuesToBackEndValues,
  convertAddressToFormValues,
  convertDaysTimesRangesFormValuesToBackEndValues,
} from "@health/domains";
import { IProviderUpsertForm } from "../forms/ProviderUpsert/ProviderUpsertFormSchema";

export const convertProviderToFormValues = (provider: Vendor) => {
  const providerSubscription = provider?.subscriptions?.edges?.[0]?.node;

  return {
    name: provider?.name,
    nameAr: provider?.nameAr!,
    ownerName: provider?.ownerName,
    nationalId: provider?.nationalId,
    email: provider?.contactEmail!,
    tradeName: provider?.tradeName!,
    commercialRegistrationNumber: provider?.commercialRegistrationNumber,
    taxLicenseNumber: provider?.taxLicenseNumber,
    deliveryPrice: provider?.deliveryPrice,
    type: vendorTypeOptionsMap[provider?.type!],
    phoneNumber: provider?.contactPhoneNumber!,
    description: provider?.description!,
    isActive: provider?.isActive,
    hasMultipleBranches: provider?.hasMultipleBranches,
    hasOwnDrivers: provider?.hasOwnDrivers,
    supportOutpatientJourney: provider?.supportOutpatientJourney,
    isIntegrated: provider?.isIntegrated,
    enableVisitDetails: provider?.enableVisitDetails,
    editLikeEnabled: provider?.editLikeEnabled,
    hasOwnPaymentGateway: provider?.hasOwnPaymentGateway,
    notifyByEmail: provider?.notifyByEmail,
    logo: provider?.logo!,
    backgroundImage: provider?.backGroundImage!,
    subscriptionPlan: createSubscriptionPlanAutocompleteOption(providerSubscription?.plan!),
    subscriptionFixedCostAmount: providerSubscription?.fixedCostAmount || 0,
    subscriptionFixedOrderAmount: providerSubscription?.fixedOrderCostAmount || 0,
    subscriptionFixedOrderPercentage: providerSubscription?.fixedOrderPercentage || 0,
    generalManagerEmail: provider?.managersContactInfo?.generalManagerEmail,
    financialManagerName: provider?.managersContactInfo?.financialManagerName,
    financialManagerEmail: provider?.managersContactInfo?.financialManagerEmail,
    financialManagerMobileNumber: provider?.managersContactInfo?.financialManagerMobileNumber,
    purchasingManagerName: provider?.managersContactInfo?.purchasingManagerName,
    purchasingManagerEmail: provider?.managersContactInfo?.purchasingManagerEmail,
    purchasingManagerMobileNumber: provider?.managersContactInfo?.purchasingManagerMobileNumber,
    ...convertAddressToFormValues(provider?.address!),
  };
};

export const convertProviderFormValuesToBackEndValues = (values: IProviderUpsertForm) => {
  return {
    name: values?.name,
    nameAr: values?.nameAr,
    ownerName: values?.ownerName,
    nationalId: values?.nationalId,
    tradeName: values?.tradeName,
    commercialRegistrationNumber: values?.commercialRegistrationNumber,
    taxLicenseNumber: values?.taxLicenseNumber,
    deliveryPrice: values?.deliveryPrice,
    type: values?.type?.value,
    contactPhoneNumber: values?.phoneNumber,
    description: values?.description,
    isActive: values?.isActive,
    isIntegrated: values?.isIntegrated,
    hasMultipleBranches: values?.hasMultipleBranches,
    editLikeEnabled: values?.editLikeEnabled,
    enableVisitDetails: values?.enableVisitDetails,
    hasOwnDrivers: values?.hasOwnDrivers,
    hasOwnPaymentGateway: values?.hasOwnPaymentGateway,
    supportOutpatientJourney: values?.supportOutpatientJourney,
    notifyByEmail: values?.notifyByEmail,
    healthLicenseNumber: values?.healthLicenseNumber,
    healthLicenseStartDate: values?.healthLicenseStartDate,
    healthLicenseEndDate: values?.healthLicenseEndDate,
    logo: values?.logo,
    backGroundImage: values?.backgroundImage,
    managersContactInfo: {
      financialManagerEmail: values?.financialManagerEmail,
      financialManagerMobileNumber: values?.financialManagerMobileNumber,
      financialManagerName: values?.financialManagerName,
      generalManagerEmail: values?.generalManagerEmail,
      purchasingManagerEmail: values?.purchasingManagerEmail,
      purchasingManagerMobileNumber: values?.purchasingManagerMobileNumber,
      purchasingManagerName: values?.purchasingManagerName,
    },
    addressInput: convertAddressFormValuesToBackEndValues(values),
    subscription: {
      plan: values?.subscriptionPlan?.value?.id,
      fixedCostAmount: values?.subscriptionFixedCostAmount || undefined,
      fixedOrderCostAmount: values?.subscriptionFixedOrderAmount || undefined,
      fixedOrderPercentage: values?.subscriptionFixedOrderPercentage || undefined,
    },
  };
};

export const convertProviderCreateFormValuesToBackEndValues = (values: IProviderUpsertForm): VendorAdminInput => {
  return {
    ...convertProviderFormValuesToBackEndValues(values),
    email: values?.email!,
    mobileNumber: values?.mobileNumber!,
    acceptsDelivery: values?.acceptsDelivery,
    branchWorkingHours: values?.hasMultipleBranches ? [] : convertDaysTimesRangesFormValuesToBackEndValues(values?.workingHours!),
  };
};

export const convertProviderUpdateFormValuesToBackEndValues = (values: IProviderUpsertForm): VendorUpdateAdminInput => {
  return convertProviderFormValuesToBackEndValues(values);
};

export const convertProviderSubscriptionToFormValues = (subscriptionPlan: Plan) => {
  return {
    fixedCostAmount: subscriptionPlan?.fixedCostAmount,
    fixedOrderAmount: subscriptionPlan?.fixedOrderCostAmount,
    fixedOrderPercentage: subscriptionPlan?.fixedOrderPercentage,
  };
};
