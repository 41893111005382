import { i18n, useTranslation } from "@toolkit/i18n";
import arSA from "date-fns/locale/ar-SA";
import enAU from "date-fns/locale/en-AU";
import { produce } from "immer";
import moment, { Moment } from "moment-timezone";
import React, { memo, useEffect, useState } from "react";
import {
  AdapterDateFns,
  Box,
  IconButton,
  InputAdornment,
  LocalizationProvider,
  TextField,
  MuiTimePicker,
  Typography,
} from "../../base/mui";
import { MuiAccessTimeIcon } from "../../base/mui-icons";
import { CustomIcon } from "../CustomIcon";
import { useWorkingHoursStyle } from "./styles";
import { IWorkingHoursProps } from "./types";
import { getHelperText } from "./utils";

const WorkingHours: React.FC<IWorkingHoursProps> = ({
  onTimeChange: handleTimeChange,
  closeTime,
  openTime,
  dayName,
  onRemoveTime: handleRemoveTime,
  onAddTime: handleAddTime,
  min,
  max,
  isLastItem,
  setDaysErrors,
  daysErrors,
}) => {
  const { classes, theme } = useWorkingHoursStyle();
  const openTimeHelperText = getHelperText(min!, openTime, closeTime, "openTime");
  const closeTimeHelperText = getHelperText(moment(openTime, "HH:mm:ss"), closeTime, openTime, "closeTime", max);
  const [isOpeningTimePickerOpen, setIsOpeningTimePickerOpen] = useState(false);
  const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);
  const isDisableAdd = openTime && closeTime && !closeTimeHelperText && !openTimeHelperText;
  const dayError = closeTimeHelperText || openTimeHelperText;
  const locale = {
    ar: arSA,
    en: enAU,
  };
  useEffect(() => {
    setDaysErrors?.(
      produce(daysErrors || {}, draft => {
        draft[dayName] = dayError;
      })
    );
  }, [dayError]);
  const handleToggleOpeningTimePicker = () => {
    setIsOpeningTimePickerOpen(!isOpeningTimePickerOpen);
  };
  const handleToggleEndTimePicker = () => {
    setIsEndTimePickerOpen(!isEndTimePickerOpen);
  };
  const handleTimePickerChange = function (date: Moment | null, type: string, keyboardValue?: string) {
    const dateMoment = moment(date);
    const keyboardMoment = keyboardValue ? moment(keyboardValue.replace(/ص/g, "am").replace(/م/g, "pm"), "hh:mm a", true) : null;
    const validMoment = dateMoment.isValid() ? dateMoment : keyboardMoment;
    handleTimeChange(validMoment!, type);
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "calc(100% - 8px)" }} className={classes.container}>
      <LocalizationProvider locale={locale[i18n.language as "ar" | "en"]} dateAdapter={AdapterDateFns}>
        <Typography component='span' className={classes.dayName}>
          {dayName}
        </Typography>
        <MuiTimePicker
          toolbarTitle={t("Select Time")}
          showToolbar
          showTodayButton
          value={openTime ? moment(openTime, "HH:mm") : null}
          open={isOpeningTimePickerOpen}
          onOpen={handleToggleOpeningTimePicker}
          onClose={handleToggleOpeningTimePicker}
          onChange={(date, key) => handleTimePickerChange(date, "openTime", key)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <MuiAccessTimeIcon />
              </InputAdornment>
            ),
          }}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              inputProps={{
                ...inputProps,
                placeholder: t("HH:MM (AM|PM)"),
              }}
              hiddenLabel
              helperText={openTimeHelperText}
              error={Boolean(openTimeHelperText)}
              sx={{
                width: "211px",
              }}
            />
          )}
        />
        <div className={classes.dash}>-</div>
        <MuiTimePicker
          toolbarTitle={t("Select Time")}
          showToolbar
          showTodayButton
          open={isEndTimePickerOpen}
          onOpen={handleToggleEndTimePicker}
          onClose={handleToggleEndTimePicker}
          value={closeTime ? moment(closeTime, "HH:mm:ss") : null}
          onChange={(date, key) => handleTimePickerChange(date, "closeTime", key)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <MuiAccessTimeIcon />
              </InputAdornment>
            ),
          }}
          renderInput={({ inputProps, ...params }) => (
            <TextField
              {...params}
              inputProps={{
                ...inputProps,
                placeholder: t("HH:MM (AM|PM)"),
              }}
              hiddenLabel
              helperText={closeTimeHelperText}
              error={Boolean(closeTimeHelperText)}
              sx={{
                width: "211px",
              }}
            />
          )}
        />
        <span className={classes.ButtonGroup}>
          {isLastItem && (
            <IconButton onClick={handleAddTime} className={classes.iconButton} disabled={!isDisableAdd}>
              <CustomIcon icon='add' color={theme.palette.primary.main} width={31} height={31} viewBox='2 0 23 32' />
            </IconButton>
          )}
          <IconButton onClick={handleRemoveTime} className={classes.iconButton}>
            <CustomIcon icon='close' margin='10px 4px' color={theme.palette.error.main} width={32} height={32} viewBox='-3 -2 32 32' />
          </IconButton>
        </span>
      </LocalizationProvider>
    </Box>
  );
};

export default memo(WorkingHours);
