import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { produce } from "immer";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { categoriesBreadcrumb } from "../../constants";
import { CategoryUpsertForm, ICategoryUpsertFormValues } from "../../forms";
import { MarketplaceProductCategoryCreateMutation, useMarketplaceProductCategoryCreateMutation } from "../../gql";
import { convertCategoryFormValuesToBackEndValues } from "../../utils";

type CategoryCreateContainerProps = {
  hasParent?: boolean;
};

export const CategoryCreateContainer: React.FC<CategoryCreateContainerProps> = props => {
  const { hasParent } = props;
  const { t } = useTranslation("admin");
  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchCategoryCreateMutation, { loading: isSubmitting }] = useMarketplaceProductCategoryCreateMutation({
    onCompleted: (data: MarketplaceProductCategoryCreateMutation) => {
      if (data?.marketplaceProductCategoryCreate?.id) {
        succeeded(t("Category created successfully!"));
        navigate(-1);
        // hasParent
        //   ? navigate(categoriesPaths.productCategoryCategories.fullPathWithParams({ categoryId: categoryId! }))
        //   : navigate(categoriesPaths.main.fullPath);
      } else {
        failed(t("Category creation failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onCategoryUpsertFormChange = (values: ICategoryUpsertFormValues) => {
    const _values = produce(convertCategoryFormValuesToBackEndValues(values), draft => {
      if (hasParent) {
        draft.parentId = categoryId;
      }
    });

    fetchCategoryCreateMutation({
      variables: {
        input: _values,
      },
    });
  };

  useEffect(() => {
    const { title, main, create } = categoriesBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);

  return (
    <CategoryUpsertForm
      hasParent={hasParent}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Create")}
      onChange={onCategoryUpsertFormChange}
    />
  );
};
