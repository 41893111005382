import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { UserFragmentFragmentDoc } from '../../fragments/__generated__/UserFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UserUpdateInput;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', userUpdate?: { __typename?: 'UserUpdate', accountErrors: Array<{ __typename?: 'AccountError', field?: string | null, message?: string | null, code: Types.AccountErrorCode }>, user?: { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, note?: string | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean, vendor?: { __typename?: 'Vendor', id: string, name: string } | null, branches?: Array<{ __typename?: 'Branch', id: string, name: string } | null> | null, permissionGroups?: Array<{ __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', name: string, code?: Types.PermissionEnum | null } | null> | null } | null> | null } | null } | null };


export const UserUpdateDocument = gql`
    mutation userUpdate($id: ID!, $input: UserUpdateInput!) {
  userUpdate(id: $id, input: $input) {
    accountErrors {
      field
      message
      code
    }
    user {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;