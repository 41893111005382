import { Payer } from "@/schema/types";
import { combineErrors, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { PayersQuery, usePayersQuery } from "gql/queries";
import { useFormContext } from "react-hook-form";

type IPayers<T> = {
  payer: Payer;
  errors: T[] | undefined;
  isRequired?: boolean;
};
export const Payers = <T,>({ errors, payer, isRequired = false }: IPayers<T>) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors: reactErrors },
  } = useFormContext();

  const { data, fetchMore } = usePayersQuery({
    variables: {
      first: 10,
      filter: {
        isActive: true,
      },
    },
  });

  const hasMore = data?.payers?.pageInfo?.hasNextPage;
  const fetchMoreData = () => {
    if (hasMore) {
      fetchMore({
        variables: {
          first: 10,
          after: data?.payers?.pageInfo?.endCursor,
        },
        updateQuery: (prev: PayersQuery, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            payers: {
              ...fetchMoreResult.payers,
              edges: [...(prev?.payers?.edges || []), ...(fetchMoreResult?.payers?.edges || [])],
            },
          });
        },
      });
    }
  };
  const formErrors = combineErrors(reactErrors, errors);
  const payers = data?.payers?.edges?.map(item => item?.node);

  return (
    <AutocompleteController
      ControllerProps={{
        control: control,
        name: "payerId",
        defaultValue: payer || null,
        rules: { required: getRequiredValidation(t, isRequired) },
      }}
      TextFieldProps={{
        label: t("Payers"),
        helperText: t(formErrors.payerId?.message),
        error: Boolean(formErrors.payerId?.message),
      }}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
      getOptionLabel={(o: { name?: string }) => o?.name!}
      options={payers || []}
    />
  );
};

Payers.defaultProps = {
  isRequired: false,
};
