import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowQueryVariables = Types.Exact<{
  chatFlowId: Types.Scalars['ID']['input'];
}>;


export type ChatFlowQuery = { __typename?: 'Query', chatFlow?: { __typename?: 'ChatFlow', code: string, conditionsAndRules?: string | null, createdAt: any, description?: string | null, id: string, name?: string | null, updatedAt: any } | null };


export const ChatFlowDocument = gql`
    query ChatFlow($chatFlowId: ID!) {
  chatFlow(id: $chatFlowId) {
    code
    conditionsAndRules
    createdAt
    description
    id
    name
    updatedAt
  }
}
    `;

/**
 * __useChatFlowQuery__
 *
 * To run a query within a React component, call `useChatFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatFlowQuery({
 *   variables: {
 *      chatFlowId: // value for 'chatFlowId'
 *   },
 * });
 */
export function useChatFlowQuery(baseOptions: Apollo.QueryHookOptions<ChatFlowQuery, ChatFlowQueryVariables> & ({ variables: ChatFlowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatFlowQuery, ChatFlowQueryVariables>(ChatFlowDocument, options);
      }
export function useChatFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatFlowQuery, ChatFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatFlowQuery, ChatFlowQueryVariables>(ChatFlowDocument, options);
        }
export function useChatFlowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChatFlowQuery, ChatFlowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChatFlowQuery, ChatFlowQueryVariables>(ChatFlowDocument, options);
        }
export type ChatFlowQueryHookResult = ReturnType<typeof useChatFlowQuery>;
export type ChatFlowLazyQueryHookResult = ReturnType<typeof useChatFlowLazyQuery>;
export type ChatFlowSuspenseQueryHookResult = ReturnType<typeof useChatFlowSuspenseQuery>;
export type ChatFlowQueryResult = Apollo.QueryResult<ChatFlowQuery, ChatFlowQueryVariables>;