import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { VendorDepartmentsAutocompleteQuery } from "./gql";

export type IVendorDepartmentAutocomplete = ExtractNodeType<VendorDepartmentsAutocompleteQuery>;

export const vendorDepartmentSchema = createZodAutocompleteFromType<IVendorDepartmentAutocomplete>({
  id: z.string(),
  name: z.string(),
});
