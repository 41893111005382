import { CodeSystemCode, HealthProgramSortingField } from "@/schema/types";
import {
  getHealthProgramBenefitsGroupsAutocompleteFilter,
  getHealthProgramMemberListsAutocompleteFilter,
  getHealthProgramNetworksAutocompleteFilter,
  getSystemCodeAutocompleteFilter,
} from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramActivation } from "../../components/HealthProgramActivation/HealthProgramActivation";
import { healthProgramsPaths } from "../../constants";
import { HealthProgramMembersListsModal } from "../../modals/HealthProgramMembersLists/HealthProgramMembersListsModal";
import { HealthProgramNetworksModal } from "../../modals/HealthProgramNetworks/HealthProgramNetworksModal";
import { IHealthProgramListNode } from "../../types";

export const useHealthProgramListContainerColumns = (): CustomTableColumnProps<IHealthProgramListNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowParametersClick = useCallback(
    (healthProgramId: string) => {
      navigate(healthProgramsPaths.parameters.fullPathWithParams({ healthProgramId }));
    },
    [navigate]
  );

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        sort: {
          columnEnum: HealthProgramSortingField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "code",
        header: t("Code"),
        accessor: "code",
        sort: {
          columnEnum: HealthProgramSortingField.Code,
        },
        filter: {
          type: "string",
          name: "code",
        },
      },
      {
        key: "description",
        header: t("Description"),
        showOnlyForFilterField: true,
        filter: { type: "string", name: "description_Icontains" },
      },
      {
        key: "networks",
        header: t("Networks"),
        accessor: ({ id, healthProgramNetworks }) => (
          <HealthProgramNetworksModal id={id} networksCount={healthProgramNetworks?.totalCount!} />
        ),
        filter: getHealthProgramNetworksAutocompleteFilter({ name: "healthProgramNetworks", multiple: true }),
      },
      {
        key: "membersLists",
        header: t("Members Lists"),
        accessor: ({ id, healthProgramMemberLists }) => (
          <HealthProgramMembersListsModal id={id} membersListsCount={healthProgramMemberLists?.totalCount!} />
        ),
        filter: getHealthProgramMemberListsAutocompleteFilter({ name: "healthProgramMemberLists", multiple: true }),
      },
      {
        key: "parameters",
        header: t("Parameters"),
        type: "redirect",
        redirectCellOptions: {
          onClick: ({ id }) => onShowParametersClick(id),
          tooltipMessage: row => t("show {{name}} parameters", { name: row?.name }),
        },
      },
      {
        key: "specializations",
        header: t("Specializations"),
        showOnlyForFilterField: true,
        filter: getSystemCodeAutocompleteFilter({
          name: "specializations",
          multiple: true,
          isCodeHidden: true,
          queryVariables: { codeSystemCode: CodeSystemCode.Speciality },
        }),
      },
      {
        key: "benefitGroups",
        header: t("Benefit Groups"),
        showOnlyForFilterField: true,
        filter: getHealthProgramBenefitsGroupsAutocompleteFilter({ name: "benefitGroups", multiple: true }),
      },
      {
        key: "created",
        header: t("Created"),
        sort: {
          columnEnum: HealthProgramSortingField.Created,
        },
        type: "datetime",
        accessor: "created",
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthProgramActivation id={id} isActive={!!isActive} />,
        sort: {
          columnEnum: HealthProgramSortingField.IsActive,
        },
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    [onShowParametersClick, t]
  );
};
