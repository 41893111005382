import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.DecisionFilterInput>;
  sortBy?: Types.InputMaybe<Types.DecisionSortingInput>;
}>;


export type DecisionListQuery = { __typename?: 'Query', getDecisions?: { __typename?: 'DecisionConnection', pageInfo?: { __typename?: 'PageInfo', startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean } | null, edges?: Array<{ __typename?: 'DecisionEdge', node?: { __typename?: 'Decision', id?: string | null, name?: string | null, isActive?: boolean | null, isScript?: boolean | null, decisionPlan?: { __typename?: 'DecisionPlan', code?: string | null, display?: string | null } | null } | null } | null> | null } | null };


export const DecisionListDocument = gql`
    query DecisionList($first: Int, $last: Int, $before: String, $after: String, $filter: DecisionFilterInput, $sortBy: DecisionSortingInput) {
  getDecisions(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        name
        isActive
        isScript
        decisionPlan {
          code
          display
        }
      }
    }
  }
}
    `;

/**
 * __useDecisionListQuery__
 *
 * To run a query within a React component, call `useDecisionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDecisionListQuery(baseOptions?: Apollo.QueryHookOptions<DecisionListQuery, DecisionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionListQuery, DecisionListQueryVariables>(DecisionListDocument, options);
      }
export function useDecisionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionListQuery, DecisionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionListQuery, DecisionListQueryVariables>(DecisionListDocument, options);
        }
export function useDecisionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionListQuery, DecisionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionListQuery, DecisionListQueryVariables>(DecisionListDocument, options);
        }
export type DecisionListQueryHookResult = ReturnType<typeof useDecisionListQuery>;
export type DecisionListLazyQueryHookResult = ReturnType<typeof useDecisionListLazyQuery>;
export type DecisionListSuspenseQueryHookResult = ReturnType<typeof useDecisionListSuspenseQuery>;
export type DecisionListQueryResult = Apollo.QueryResult<DecisionListQuery, DecisionListQueryVariables>;