import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { IBenefitMapUpsertFormEvent, BenefitMapUpsertForm } from "../../forms/BenefitMapUpsert/BenefitMapUpsertForm";
import { useBenefitMapCreateMutation } from "../../gql";
import { convertBenefitMapFormValuesToBackEndValues } from "../../others";
import { benefitMapsPaths } from "../../constants";
import { useSetBenefitMapsBreadcrumbs } from "../../hooks/useSetBenefitMapsBreadcrumbs";

export const BenefitMapCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createBenefitMap, { loading: isSubmitting }] = useBenefitMapCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.createBenefitMap?.id) {
        succeeded(t("Benefit Map created successfully"));
        navigate(benefitMapsPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onBenefitMapUpsertFormChange = (event: IBenefitMapUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertBenefitMapFormValuesToBackEndValues(event.payload.values);

      createBenefitMap({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetBenefitMapsBreadcrumbs("CREATE");

  return <BenefitMapUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onBenefitMapUpsertFormChange} />;
};
