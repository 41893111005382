import { Decision, DecisionPlanCategory } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { DecisionUpsertForm, IDecisionUpsertFormEvent } from "../../forms/DecisionUpsert/DecisionUpsertForm";
import { useDecisionGetQuery, useDecisionUpdateMutation } from "../../gql";
import { convertDecisionFormValuesToBackEndValues } from "../../others";

export type IDecisionUpdateEvent = {
  type: "UPDATE";
};

type DecisionUpdateProps = {
  decisionId: string;
  category: DecisionPlanCategory;
  healthParameterCode?: string;
  guidedCareTemplateCode?: string;
  ioAgentCode?: string;
  onChange: (event: IDecisionUpdateEvent) => void;
};

export const DecisionUpdate: FC<DecisionUpdateProps> = props => {
  const { decisionId, category, healthParameterCode, guidedCareTemplateCode, ioAgentCode, onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const { data, loading } = useDecisionGetQuery({
    variables: { id: decisionId },
    skip: !decisionId,
    fetchPolicy: "no-cache",
  });

  const decision = data?.getDecision as Decision;

  const [updateDecision, { loading: isSubmitting }] = useDecisionUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.updateDecision?.errors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Decision updated successfully"));

        onChange({
          type: "UPDATE",
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onDecisionUpsertFormChange = (event: IDecisionUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertDecisionFormValuesToBackEndValues(
        event.payload.values,
        event.payload.decisionPlan,
        guidedCareTemplateCode,
        healthParameterCode,
        ioAgentCode
      );

      updateDecision({
        variables: {
          decision: {
            ...values,
            id: decisionId,
          },
        },
      });
    }
  };

  return (
    <DecisionUpsertForm
      buttonLabel={t("Update")}
      decision={decision}
      category={category}
      healthParameterCode={healthParameterCode}
      guidedCareTemplateCode={guidedCareTemplateCode}
      ioAgentCode={ioAgentCode}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onDecisionUpsertFormChange}
    />
  );
};
