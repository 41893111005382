import { ProviderGuidedCareHealthProgramTeam } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useCaseManagementPatientTeamsColumns = (): CustomTableColumnProps<ProviderGuidedCareHealthProgramTeam>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "id",
        header: t("ID"),
        accessor: ({ guidedCareHealthProgram }) => guidedCareHealthProgram?.guidedCareHealthProgramTemplate?.id,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ guidedCareHealthProgram }) => guidedCareHealthProgram?.guidedCareHealthProgramTemplate?.name,
      },
    ],
    [t]
  );
};
