import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { OptimaPayersAutocompleteQuery } from "./gql";

export type IOptimaPayerAutocomplete = ExtractNodeType<OptimaPayersAutocompleteQuery>;

export const optimaPayerSchema = createZodAutocompleteFromType<IOptimaPayerAutocomplete>({
  id: z.string(),
  name: z.string(),
  licenseNumber: z.string(),
});
