import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";
import { Benefit } from "../../schema/types";

export const patientEligibilityBenefitsEnumOptionsMap: IEnumToOptionsMap<Benefit> = {
  [Benefit.Inpatient]: {
    key: Benefit.Inpatient,
    get label() {
      return i18n.t("Inpatient", { ns: "domains" });
    },
    value: Benefit.Inpatient,
  },
  [Benefit.Outpatient]: {
    key: Benefit.Outpatient,
    get label() {
      return i18n.t("Outpatient", { ns: "domains" });
    },
    value: Benefit.Outpatient,
  },
  [Benefit.Maternity]: {
    key: Benefit.Maternity,
    get label() {
      return i18n.t("Maternity", { ns: "domains" });
    },
    value: Benefit.Maternity,
  },
  [Benefit.Dental]: {
    key: Benefit.Dental,
    get label() {
      return i18n.t("Dental", { ns: "domains" });
    },
    value: Benefit.Dental,
  },
  [Benefit.Emergency]: {
    key: Benefit.Emergency,
    get label() {
      return i18n.t("Emergency", { ns: "domains" });
    },
    value: Benefit.Emergency,
  },
  [Benefit.Optical]: {
    key: Benefit.Optical,
    get label() {
      return i18n.t("Optical", { ns: "domains" });
    },
    value: Benefit.Optical,
  },
  [Benefit.Pharmacy]: {
    key: Benefit.Pharmacy,
    get label() {
      return i18n.t("Pharmacy", { ns: "domains" });
    },
    value: Benefit.Pharmacy,
  },
  [Benefit.Laboratory]: {
    key: Benefit.Laboratory,
    get label() {
      return i18n.t("Laboratory", { ns: "domains" });
    },
    value: Benefit.Laboratory,
  },
  [Benefit.Diagnostic]: {
    key: Benefit.Diagnostic,
    get label() {
      return i18n.t("Diagnostic", { ns: "domains" });
    },
    value: Benefit.Diagnostic,
  },
  [Benefit.Radiology]: {
    key: Benefit.Radiology,
    get label() {
      return i18n.t("Radiology", { ns: "domains" });
    },
    value: Benefit.Radiology,
  },
  [Benefit.Psychiatry]: {
    key: Benefit.Psychiatry,
    get label() {
      return i18n.t("Psychiatry", { ns: "domains" });
    },
    value: Benefit.Psychiatry,
  },
  [Benefit.ChronicOut]: {
    key: Benefit.ChronicOut,
    get label() {
      return i18n.t("Chronic Out", { ns: "domains" });
    },
    value: Benefit.ChronicOut,
  },
  [Benefit.Daycase]: {
    key: Benefit.Daycase,
    get label() {
      return i18n.t("Day Case", { ns: "domains" });
    },
    value: Benefit.Daycase,
  },
  [Benefit.EmergencyRoomServices]: {
    key: Benefit.EmergencyRoomServices,
    get label() {
      return i18n.t("Emergence Room Services", { ns: "domains" });
    },
    value: Benefit.EmergencyRoomServices,
  },
  [Benefit.Life]: {
    key: Benefit.Life,
    get label() {
      return i18n.t("Life", { ns: "domains" });
    },
    value: Benefit.Life,
  },

  [Benefit.TravelInsurance]: {
    key: Benefit.TravelInsurance,
    get label() {
      return i18n.t("Travel Insurance", { ns: "domains" });
    },
    value: Benefit.TravelInsurance,
  },

  [Benefit.Wellness]: {
    key: Benefit.Wellness,
    get label() {
      return i18n.t("Wellness", { ns: "domains" });
    },
    value: Benefit.Wellness,
  },
};

export const patientEligibilityBenefitsEnumOptions = Object.values(patientEligibilityBenefitsEnumOptionsMap);
