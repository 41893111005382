import { Maybe } from "@/schema/types";
import { InfoItems } from "@toolkit/ui";

export const getDiscrptionsInfoItems = (descrption?: Maybe<string>): InfoItems => {
  return descrption
    ? [
        {
          value: descrption,
          valueDisplayMode: "multiple-line-string",
        },
      ]
    : undefined;
};
