import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.GuidedCareHealthProgramFilter>;
}>;


export type GuidedCareProgramsAutocompleteQuery = { __typename?: 'Query', guidedCareHealthPrograms?: { __typename?: 'GuidedCareHealthProgramCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'GuidedCareHealthProgramCountableEdge', node: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } }> } | null };


export const GuidedCareProgramsAutocompleteDocument = gql`
    query GuidedCareProgramsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: GuidedCareHealthProgramFilter) {
  guidedCareHealthPrograms(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useGuidedCareProgramsAutocompleteQuery__
 *
 * To run a query within a React component, call `useGuidedCareProgramsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareProgramsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGuidedCareProgramsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>(GuidedCareProgramsAutocompleteDocument, options);
      }
export function useGuidedCareProgramsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>(GuidedCareProgramsAutocompleteDocument, options);
        }
export function useGuidedCareProgramsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>(GuidedCareProgramsAutocompleteDocument, options);
        }
export type GuidedCareProgramsAutocompleteQueryHookResult = ReturnType<typeof useGuidedCareProgramsAutocompleteQuery>;
export type GuidedCareProgramsAutocompleteLazyQueryHookResult = ReturnType<typeof useGuidedCareProgramsAutocompleteLazyQuery>;
export type GuidedCareProgramsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useGuidedCareProgramsAutocompleteSuspenseQuery>;
export type GuidedCareProgramsAutocompleteQueryResult = Apollo.QueryResult<GuidedCareProgramsAutocompleteQuery, GuidedCareProgramsAutocompleteQueryVariables>;