import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LanguagesAutocompleteQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.LanguageFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type LanguagesAutocompleteQuery = { __typename?: 'Query', languages?: { __typename?: 'LanguageCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'LanguageCountableEdge', node: { __typename?: 'Language', id: string, code: string, display: string, displayAr?: string | null } }> } | null };


export const LanguagesAutocompleteDocument = gql`
    query LanguagesAutocomplete($filter: LanguageFilterInput, $before: String, $after: String, $first: Int, $last: Int) {
  languages(
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        code
        display
        displayAr
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useLanguagesAutocompleteQuery__
 *
 * To run a query within a React component, call `useLanguagesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useLanguagesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>(LanguagesAutocompleteDocument, options);
      }
export function useLanguagesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>(LanguagesAutocompleteDocument, options);
        }
export function useLanguagesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>(LanguagesAutocompleteDocument, options);
        }
export type LanguagesAutocompleteQueryHookResult = ReturnType<typeof useLanguagesAutocompleteQuery>;
export type LanguagesAutocompleteLazyQueryHookResult = ReturnType<typeof useLanguagesAutocompleteLazyQuery>;
export type LanguagesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useLanguagesAutocompleteSuspenseQuery>;
export type LanguagesAutocompleteQueryResult = Apollo.QueryResult<LanguagesAutocompleteQuery, LanguagesAutocompleteQueryVariables>;