import { pickLocalizedValue } from "@toolkit/i18n";
import { makeStyles } from "@toolkit/ui";

export const useSurveyQuestionFormStyles = makeStyles()(theme => ({
  root: {
    width: "100%",
    paddingRight: pickLocalizedValue(0, 16),
    paddingLeft: pickLocalizedValue(16, 0),
  },
  header: {
    boxShadow: theme.mixins.shadows.md,
    padding: 8,
    paddingRight: pickLocalizedValue(0, 16),
    paddingLeft: pickLocalizedValue(16, 0),
  },
  requiredLabelContainer: {
    "& label": {
      direction: pickLocalizedValue("rtl", "rtl"),
      margin: 0,
    },
  },
  icon: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: 400,
    margin: "0 4px",
  },
  questionTypeItem: {
    borderRadius: 0,
    paddingLeft: 12,
  },
}));
