import { i18n } from "@toolkit/i18n";
import { MedicalFormCategory } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const medicalFormCategoryOptionsMap: IEnumToOptionsMap<MedicalFormCategory> = {
  [MedicalFormCategory.Condition]: {
    key: MedicalFormCategory.Condition,
    get label() {
      return i18n.t("Condition", { ns: "domains" });
    },
    value: MedicalFormCategory.Condition,
  },
  [MedicalFormCategory.Disease]: {
    key: MedicalFormCategory.Disease,
    get label() {
      return i18n.t("Disease", { ns: "domains" });
    },
    value: MedicalFormCategory.Disease,
  },
  [MedicalFormCategory.General]: {
    key: MedicalFormCategory.General,
    get label() {
      return i18n.t("General", { ns: "domains" });
    },
    value: MedicalFormCategory.General,
  },
  [MedicalFormCategory.Procedure]: {
    key: MedicalFormCategory.Procedure,
    get label() {
      return i18n.t("Procedure", { ns: "domains" });
    },
    value: MedicalFormCategory.Procedure,
  },
};

export const medicalFormCategoryOptions = Object.values(medicalFormCategoryOptionsMap);
