import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalFormsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MedicalFormFilter>;
}>;


export type MedicalFormsAutocompleteQuery = { __typename?: 'Query', medicalForms?: { __typename?: 'MedicalFormConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'MedicalFormEdge', node?: { __typename?: 'MedicalForm', id: string, code?: string | null, name?: string | null, nameAr?: string | null } | null } | null> | null } | null };


export const MedicalFormsAutocompleteDocument = gql`
    query MedicalFormsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: MedicalFormFilter) {
  medicalForms(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        code
        name
        nameAr
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useMedicalFormsAutocompleteQuery__
 *
 * To run a query within a React component, call `useMedicalFormsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalFormsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalFormsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMedicalFormsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>(MedicalFormsAutocompleteDocument, options);
      }
export function useMedicalFormsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>(MedicalFormsAutocompleteDocument, options);
        }
export function useMedicalFormsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>(MedicalFormsAutocompleteDocument, options);
        }
export type MedicalFormsAutocompleteQueryHookResult = ReturnType<typeof useMedicalFormsAutocompleteQuery>;
export type MedicalFormsAutocompleteLazyQueryHookResult = ReturnType<typeof useMedicalFormsAutocompleteLazyQuery>;
export type MedicalFormsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useMedicalFormsAutocompleteSuspenseQuery>;
export type MedicalFormsAutocompleteQueryResult = Apollo.QueryResult<MedicalFormsAutocompleteQuery, MedicalFormsAutocompleteQueryVariables>;