import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { DoctorsAutocompleteQuery } from "./gql";

export type IDoctorAutocomplete = ExtractNodeType<DoctorsAutocompleteQuery>;

export const doctorSchema = createZodAutocompleteFromType<IDoctorAutocomplete>({
  id: z.string(),
  user: z
    .object({
      id: z.string(),
      fullName: z.string().nullish(),
    })
    .nullish(),
});
