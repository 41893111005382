import { ActivityTrackerRoute } from "@/pages/ActivityTracker/constants";
import { replacePathParams } from "utils";

export const supplementTypesRoute = ActivityTrackerRoute + "/supplement-types";
const supplementTypesNewRoute = "new";
const supplementTypesEditRoute = "edit/:id";

export const supplementTypesPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${supplementTypesRoute}${this.route}`;
    },
  },
  newPath: {
    route: supplementTypesNewRoute,
    get fullPath() {
      return `${supplementTypesRoute}/${this.route}`;
    },
  },
  editPath: {
    route: supplementTypesEditRoute,
    get fullPath() {
      return `${supplementTypesRoute}/${this.route}`;
    },
    fullPathWithParams: function (id: string) {
      return replacePathParams(this.fullPath, { id });
    },
  },
};
