import { HealthProgramMemberList, HealthProgramMemberListInput } from "@/schema/types";
import { createPayerAutocompleteOption } from "@health/autocompletes";
import { IHealthProgramMemberListUpsertFormValues } from "../forms/HealthProgramMemberListUpsert/HealthProgramMemberListUpsertFormSchema";

export const convertHealthProgramMemberListToFormValues = (
  healthProgramMemberList: HealthProgramMemberList
): IHealthProgramMemberListUpsertFormValues => {
  return {
    name: healthProgramMemberList?.name!,
    payer: createPayerAutocompleteOption(healthProgramMemberList?.payer!),
    description: healthProgramMemberList?.description!,
    isActive: !!healthProgramMemberList?.isActive,
  };
};

export const convertHealthProgramMemberListFormValuesToBackEndValues = (
  values: IHealthProgramMemberListUpsertFormValues
): HealthProgramMemberListInput => {
  return {
    name: values?.name,
    payerId: values?.payer?.value?.id,
    description: values?.description,
    isActive: values?.isActive,
  };
};
