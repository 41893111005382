import { Box, TruncateTypography, Typography } from "@toolkit/ui";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { usePatientContactInformationStyle } from "./usePatientContactInformationStyle";

type PatientContactInformationProps = {
  contactNumber: string;
  email: string;
};

export const PatientContactInformation: FC<PatientContactInformationProps> = props => {
  const { contactNumber, email } = props;

  const { t } = useTranslation("admin");

  const { classes } = usePatientContactInformationStyle();

  if (email && contactNumber) {
    return <>-</>;
  }

  return (
    <Box className={classes.wrapper}>
      {contactNumber && (
        <Typography className={classes.content}>
          {t("Number")}: <TruncateTypography dir='ltr' text={contactNumber} />
        </Typography>
      )}

      {email && (
        <Typography className={classes.content}>
          {t("Email")}: <TruncateTypography text={email} />
        </Typography>
      )}
    </Box>
  );
};
