import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { RiskFactorTemplatesAutocompleteQuery } from "./gql";

export type IRiskFactorTemplateAutocomplete = ExtractNodeType<RiskFactorTemplatesAutocompleteQuery>;

export const riskFactorTemplateSchema = createZodAutocompleteFromType<IRiskFactorTemplateAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  arabicDisplay: z.string().nullish(),
});
