import { makeStyles } from "@toolkit/ui";

export const useScreenshotCardStyles = makeStyles()({
  screenShotCard: {
    padding: 16,
    borderRadius: 10,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    alignContent: "center",
    justifyContent: "center",
    overflow: "scroll",
    maxWidth: "100%",
  },
  snapshotsWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    scrollBehavior: "smooth",
  },
  imageDisplayContainer: {
    flex: "0 0 auto",
    scrollSnapAlign: "center",
  },
});
