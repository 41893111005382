/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { IUserProviderUpsertForm } from "@/pages/Users/forms/UserProviderUpsert/UserProviderUpsertFormSchema";
import {
  AdminUserCreateInput,
  AppointmentType,
  AppointmentTypeEnum,
  AppRoleTypes,
  AppTypes,
  DoctorInput,
  PersonGenderEnum,
  UserUpdateInput,
  VendorUserTypes,
} from "@/schema/types";
import { isAppointmentTypePresent } from "@/pages/Users/others/utils";
import { convertDaysTimesRangesFormValuesToBackEndValues } from "../../../utils";

export const convertUserProviderFormValuesToBackEndValues = (values: IUserProviderUpsertForm) => {
  const userType = values?.vendorUserType?.value;

  const isUserTypeDoctor = userType === VendorUserTypes.Doctor;
  const isUserTypeManager = userType === VendorUserTypes.Manager;
  const isUserTypeRcm = userType === VendorUserTypes.Rcm;

  const appRole = values?.appRole?.value!;
  const isAppRoleUser = values?.appRole?.value === AppRoleTypes.User;

  return {
    firstName: values?.firstName,
    lastName: values?.lastName,
    mobile: values?.mobile,
    appRole: isUserTypeManager ? AppRoleTypes.Admin : isUserTypeRcm ? AppRoleTypes.User : appRole,
    dateOfBirth: values?.dateOfBirth,
    gender: values?.gender?.value as unknown as PersonGenderEnum,
    isActive: values?.isActive,
    branches: isAppRoleUser ? values?.branches?.map(item => item?.value?.id) : undefined,
    defaultBranch: isAppRoleUser
      ? values?.branches?.length > 1
        ? values?.defaultBranch?.value?.id
        : values?.branches?.[0]?.value?.id
      : values?.defaultBranch?.value?.id,
    departments: isUserTypeDoctor && values?.departments?.length ? values.departments.map(item => item?.value?.id) : undefined,
    ...convertUserProviderUserTypeFormValuesToBackEndValues(values),
  };
};

export const convertUserProviderCreateFormValuesToBackEndValues = (
  values: IUserProviderUpsertForm,
  vendorId?: string
): AdminUserCreateInput => {
  return {
    ...convertUserProviderFormValuesToBackEndValues(values),
    nationalId: values?.nationalId,
    email: values?.email,
    password: values?.password!,
    appType: AppTypes.Vendor,
    vendorUserType: values?.vendorUserType?.value,
    vendor: vendorId || values?.provider?.value?.id,
  };
};

export const convertUserProviderUpdateFormValuesToBackEndValues = (values: IUserProviderUpsertForm): UserUpdateInput => {
  return convertUserProviderFormValuesToBackEndValues(values);
};

export const convertUserProviderUserTypeFormValuesToBackEndValues = (values: IUserProviderUpsertForm) => {
  const vendorUserType = values?.vendorUserType?.value;

  const healthLicense = {
    healthLicenseNumber: values?.healthLicenseNumber,
    healthLicenseStartDate: values?.healthLicenseStartDate,
    healthLicenseEndDate: values?.healthLicenseEndDate,
  };

  if (vendorUserType === VendorUserTypes.DentalHygienist) {
    return {
      dentalHygienistInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.DiabetesEducator) {
    return {
      diabetesEducatorInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.Doctor) {
    return {
      doctorInfo: {
        ...convertUserProviderUserTypeDoctorFormValuesToBackEndValues(values),
        ...healthLicense,
      },
    };
  } else if (vendorUserType === VendorUserTypes.FitnessCoach) {
    return {
      fitnessCoachInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.Nurse) {
    return {
      nurseInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.Nutritionist) {
    return {
      nutritionistInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.Optometrist) {
    return {
      optometristInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.Pharmacist) {
    return {
      pharmacistInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.PodiatricMedicalAssistant) {
    return {
      podiatricMedicalAssistantInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.Psychologist) {
    return {
      psychologistInfo: healthLicense,
    };
  } else if (vendorUserType === VendorUserTypes.SocialWorker) {
    return {
      socialWorkerInfo: healthLicense,
    };
  }
};

export const convertUserProviderUserTypeDoctorFormValuesToBackEndValues = (values: IUserProviderUpsertForm): DoctorInput => {
  const doctorInfo = values?.doctorInfo;

  return {
    specializations: doctorInfo?.specializations?.length ? doctorInfo?.specializations?.map(item => item?.value?.code!) : [],
    languages: doctorInfo?.languages?.length ? doctorInfo?.languages?.map(item => item?.value?.code) : [],
    seniority: doctorInfo?.seniority?.value,
    notificationEmail: doctorInfo?.notificationEmail,
    yearsOfExperience: doctorInfo?.yearsOfExperience,
    secondMobileNumber: doctorInfo?.secondMobileNumber,
    socialLinks: doctorInfo?.socialLinks,
    bio: doctorInfo?.bio,
    appointmentSlotTimePeriod: doctorInfo?.appointmentSlotTimePeriod ? Number(doctorInfo?.appointmentSlotTimePeriod?.value) : undefined,
    appointmentTypes: doctorInfo?.appointmentTypes?.length
      ? doctorInfo?.appointmentTypes?.map(item => item?.value as unknown as AppointmentTypeEnum)
      : [],
    onlineVisitPrice: isAppointmentTypePresent(doctorInfo?.appointmentTypes!, AppointmentType.Online)
      ? doctorInfo?.onlineVisitPrice
      : undefined,
    onsiteVisitPrice: isAppointmentTypePresent(doctorInfo?.appointmentTypes!, AppointmentType.Onsite)
      ? doctorInfo?.onsiteVisitPrice
      : undefined,
    atHomeVisitPrice: isAppointmentTypePresent(doctorInfo?.appointmentTypes!, AppointmentType.AtHome)
      ? doctorInfo?.atHomeVisitPrice
      : undefined,
    isMobileNumberPublic: doctorInfo?.isMobileNumberPublic,
    isSecondMobileNumberPublic: doctorInfo?.isSecondMobileNumberPublic,
    isNationalIdPublic: doctorInfo?.isNationalIdPublic,
    isLanguagesPublic: doctorInfo?.isLanguagesPublic,
    isYearsOfExperiencePublic: doctorInfo?.isYearsOfExperiencePublic,
    isDateOfBirthPublic: doctorInfo?.isDateOfBirthPublic,
    isSocialLinksPublic: doctorInfo?.isSocialLinksPublic,
    isLicenseNumberPublic: values?.doctorInfo?.isHealthLicenseNumberPublic,
    qualifications: doctorInfo?.qualifications
      ? [
          {
            code: doctorInfo?.qualifications?.code!,
            issuer: doctorInfo?.qualifications?.issuer,
            fromDate: doctorInfo?.qualifications?.fromDate,
            toDate: doctorInfo?.qualifications?.toDate,
          },
        ]
      : undefined,
    workingHours: convertDaysTimesRangesFormValuesToBackEndValues(doctorInfo?.workingHours!),
  };
};
