import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, MenuItem, Typography } from "@toolkit/ui";
import { FC } from "react";
import { AMLAssignReviewerDialog } from "../../../shared/AMLAssignReviewerDialog";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const AssignButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { code, reviewerUser, editorUser } = useAdminManagedListDetailsPageContext();
  const { t } = useTranslation("domains");
  return (
    <AMLAssignReviewerDialog
      code={code!}
      reviewerUser={reviewerUser}
      editorUser={editorUser}
      renderButton={openDialog => {
        const handleClick = () => {
          openDialog();
          onClick();
        };
        return (
          <MenuItem onClick={handleClick}>
            <CustomIcon icon='correct' viewBox='0 0 20 20' />
            <Typography marginLeft='2px'> {editorUser?.id || reviewerUser?.id ? t("Reassign") : t("Assign")}</Typography>
          </MenuItem>
        );
      }}
    />
  );
};
