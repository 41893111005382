import { HealthProgramNetworkProvider } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import {
  HealthProgramNetworkProviderUpsertForm,
  IHealthProgramNetworkProviderUpsertFormEvent,
} from "../../forms/HealthProgramNetworkProviderUpsert/HealthProgramNetworkProviderUpsertForm";
import { useHealthProgramNetworkProviderGetQuery, useHealthProgramNetworkProviderUpdateMutation } from "../../gql";
import { useSetHealthProgramNetworksProvidersBreadcrumbs } from "../../hooks";
import { convertHealthProgramNetworkProviderFormValuesToBackEndValues } from "../../others";
import { IHealthProgramNetworkProviderUpdateContainerParams } from "../../types";

export const HealthProgramNetworkProviderUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { networkId, providerId } = useParams<IHealthProgramNetworkProviderUpdateContainerParams>();

  const { data, loading } = useHealthProgramNetworkProviderGetQuery({
    variables: {
      filter: {
        ids: [providerId!],
      },
    },
    skip: !providerId,
    fetchPolicy: "no-cache",
  });

  const healthProgramNetworkProvider = data?.healthProgramNetworkProviders?.edges?.[0]?.node as HealthProgramNetworkProvider;

  const [updateHealthProgramNetworkProvider, { loading: isSubmitting }] = useHealthProgramNetworkProviderUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramNetworkProviderUpdate?.entity?.id) {
        succeeded(t("Network Provider updated successfully"));
        navigate(-1);
      } else {
        failed(t("Network Provider update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramNetworkProviderUpsertFormChange = (event: IHealthProgramNetworkProviderUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramNetworkProviderFormValuesToBackEndValues(event.payload.values);

      updateHealthProgramNetworkProvider({
        variables: {
          id: providerId!,
          input: { ...values, healthProgramNetwork: networkId },
        },
      });
    }
  };

  useSetHealthProgramNetworksProvidersBreadcrumbs("UPDATE", {
    networkId: networkId!,
    providerId: providerId!,
  });

  return (
    <HealthProgramNetworkProviderUpsertForm
      buttonLabel={t("Update")}
      healthProgramNetworkProvider={healthProgramNetworkProvider}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramNetworkProviderUpsertFormChange}
    />
  );
};
