import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PatientEnrollmentRequestsListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.PatientEnrollmentRequestFilterInput>;
  sortBy?: Types.InputMaybe<Types.PatientEnrollmentRequestSortingInput>;
}>;


export type PatientEnrollmentRequestsListQuery = { __typename?: 'Query', patientEnrollmentRequests?: { __typename?: 'PatientEnrollmentRequestCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'PatientEnrollmentRequestCountableEdge', node: { __typename?: 'PatientEnrollmentRequest', id: string, status?: Types.PatientEnrollmentRequestStatus | null, error?: string | null, createdDate?: any | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null } | null, team?: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null, provider: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null }, guidedCareHealthProgram: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null } } | null } }> } | null };


export const PatientEnrollmentRequestsListDocument = gql`
    query PatientEnrollmentRequestsList($first: Int, $after: String, $before: String, $last: Int, $filter: PatientEnrollmentRequestFilterInput, $sortBy: PatientEnrollmentRequestSortingInput) {
  patientEnrollmentRequests(
    first: $first
    after: $after
    before: $before
    last: $last
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        status
        error
        createdDate
        patient {
          id
          firstName
          lastName
        }
        team {
          id
          name
          provider {
            id
            name
            nameAr
          }
          guidedCareHealthProgram {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePatientEnrollmentRequestsListQuery__
 *
 * To run a query within a React component, call `usePatientEnrollmentRequestsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientEnrollmentRequestsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientEnrollmentRequestsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePatientEnrollmentRequestsListQuery(baseOptions?: Apollo.QueryHookOptions<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>(PatientEnrollmentRequestsListDocument, options);
      }
export function usePatientEnrollmentRequestsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>(PatientEnrollmentRequestsListDocument, options);
        }
export function usePatientEnrollmentRequestsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>(PatientEnrollmentRequestsListDocument, options);
        }
export type PatientEnrollmentRequestsListQueryHookResult = ReturnType<typeof usePatientEnrollmentRequestsListQuery>;
export type PatientEnrollmentRequestsListLazyQueryHookResult = ReturnType<typeof usePatientEnrollmentRequestsListLazyQuery>;
export type PatientEnrollmentRequestsListSuspenseQueryHookResult = ReturnType<typeof usePatientEnrollmentRequestsListSuspenseQuery>;
export type PatientEnrollmentRequestsListQueryResult = Apollo.QueryResult<PatientEnrollmentRequestsListQuery, PatientEnrollmentRequestsListQueryVariables>;