import { Typography } from "@toolkit/ui";
import { FC } from "react";

type GridCellTextProps = {
  text?: string | null;
};

export const GridCellText: FC<GridCellTextProps> = ({ text }) => {
  return (
    <Typography
      sx={{
        width: "max-content",
      }}
    >
      {text || "-"}
    </Typography>
  );
};
