import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { MarketplaceHealthPackageFilterInput } from "../../schema/types";
import { useMarketplaceHealthPackagesAutocompleteQuery } from "./gql";

type MarketplaceHealthPackagesAutocompleteProps = IAutocompleteProps<MarketplaceHealthPackageFilterInput>;

export const MarketplaceHealthPackagesAutocomplete: FC<MarketplaceHealthPackagesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useMarketplaceHealthPackagesAutocompleteQuery,
    variables: { filter },
    searchKey: "nameContains",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.name!, item?.nameAr!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Health Packages") : t("Health Package"))}
    />
  );
};
