import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancellationReasonsAutocompleteQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  searchParameters?: Types.InputMaybe<Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>>;
  showOnlyVisibleProperties?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  codeSystemCode?: Types.InputMaybe<Types.CodeSystemCode>;
}>;


export type CancellationReasonsAutocompleteQuery = { __typename?: 'Query', getCodeSystemConceptsByCode?: { __typename?: 'CodeSystemConceptConnection', edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', display?: string | null, code?: string | null, id?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', id?: string | null, fieldType?: Types.Type | null, valueString?: string | null, code?: string | null } | null> | null } | null } | null> | null } | null };


export const CancellationReasonsAutocompleteDocument = gql`
    query CancellationReasonsAutocomplete($after: String, $before: String, $first: Int, $last: Int, $searchParameters: [SearchParameter], $showOnlyVisibleProperties: Boolean, $codeSystemCode: CodeSystemCode) {
  getCodeSystemConceptsByCode(
    after: $after
    before: $before
    first: $first
    last: $last
    searchParameters: $searchParameters
    showOnlyVisibleProperties: $showOnlyVisibleProperties
    codeSystemCode: $codeSystemCode
  ) {
    edges {
      node {
        display
        code
        id
        properties {
          id
          fieldType
          valueString
          code
        }
      }
    }
  }
}
    `;

/**
 * __useCancellationReasonsAutocompleteQuery__
 *
 * To run a query within a React component, call `useCancellationReasonsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancellationReasonsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancellationReasonsAutocompleteQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchParameters: // value for 'searchParameters'
 *      showOnlyVisibleProperties: // value for 'showOnlyVisibleProperties'
 *      codeSystemCode: // value for 'codeSystemCode'
 *   },
 * });
 */
export function useCancellationReasonsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>(CancellationReasonsAutocompleteDocument, options);
      }
export function useCancellationReasonsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>(CancellationReasonsAutocompleteDocument, options);
        }
export function useCancellationReasonsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>(CancellationReasonsAutocompleteDocument, options);
        }
export type CancellationReasonsAutocompleteQueryHookResult = ReturnType<typeof useCancellationReasonsAutocompleteQuery>;
export type CancellationReasonsAutocompleteLazyQueryHookResult = ReturnType<typeof useCancellationReasonsAutocompleteLazyQuery>;
export type CancellationReasonsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useCancellationReasonsAutocompleteSuspenseQuery>;
export type CancellationReasonsAutocompleteQueryResult = Apollo.QueryResult<CancellationReasonsAutocompleteQuery, CancellationReasonsAutocompleteQueryVariables>;