import { City } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { CityUpsertForm, ICityUpsertFormEvent } from "../../forms/CityUpsert/CityUpsertForm";
import { useCityGetQuery, useCityUpdateMutation } from "../../gql";
import { useSetCitiesBreadcrumbs } from "../../hooks";
import { convertCityFormValuesToBackEndValues } from "../../others";
import { ICityUpdateContainerParams } from "../../types";
export const CityUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { cityId } = useParams<ICityUpdateContainerParams>();

  const { data, loading } = useCityGetQuery({
    variables: { id: cityId! },
    skip: !cityId,
    fetchPolicy: "no-cache",
  });

  const city = data?.city as City;

  const [updateCity, { loading: isSubmitting }] = useCityUpdateMutation({
    onCompleted: cityUpdateMutationData => {
      if (cityUpdateMutationData?.cityUpdate?.cityErrors?.length! > 0) {
        const error = t(formatMessageErrors(cityUpdateMutationData?.cityUpdate?.cityErrors));
        failed(error);
      } else {
        succeeded(t("City updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onCityUpsertFormChange = (event: ICityUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertCityFormValuesToBackEndValues(event.payload.values);

      updateCity({
        variables: {
          id: cityId!,
          input: values,
        },
      });
    }
  };

  useSetCitiesBreadcrumbs("UPDATE", pickLocalizedValue(city?.name, city?.nameAr!));

  return (
    <CityUpsertForm
      buttonLabel={t("Update")}
      city={city}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onCityUpsertFormChange}
    />
  );
};
