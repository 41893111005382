import { TemplateFieldType } from "@/schema/types";
import { zodEnumSchema } from "@health/enum-options";
import { createZodAutocomplete } from "@toolkit/core";
import { i18n } from "@toolkit/i18n";
import { isNil } from "lodash";
import { z } from "zod";

export const patientGuidedCareProgramEvaluationFieldsFormSchema = z
  .object({
    id: z.string(),
    code: z.string(),
    display: z.string(),
    arabicDisplay: z.string().nullish(),
    type: z.nativeEnum(TemplateFieldType),
    allowedValues: z.array(z.string()),
    unit: z.object({ display: z.string().nullish(), arabicDisplay: z.string().nullish() }).nullish(),
    defaultValue: z.string().nullish(),
    isMandatory: z.boolean(),
    minNormalRangeValue: z.number().nullish(),
    maxNormalRangeValue: z.number().nullish(),
    valueNumber: z.number().nullish(),
    valueDate: z.string().nullish(),
    valueString: z.string().nullish(),
    valueStringList: createZodAutocomplete().nullish(),
    valueBoolean: zodEnumSchema.yesNo.nullish(),
  })
  .superRefine((data, ctx) => {
    if (data.type === TemplateFieldType.Number && data.isMandatory && isNil(data.valueNumber)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "provider" }),
        path: ["valueNumber"],
      });
    } else if (data.type === TemplateFieldType.Date && data.isMandatory && !data.valueDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "provider" }),
        path: ["valueDate"],
      });
    } else if (data.type === TemplateFieldType.Boolean && data.isMandatory && isNil(data.valueBoolean)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "provider" }),
        path: ["valueBoolean"],
      });
    } else if (data.type === TemplateFieldType.String && data.isMandatory) {
      if (data.allowedValues?.length > 0 && !data.valueStringList) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "provider" }),
          path: ["valueStringList"],
        });
      } else if (!data?.valueString) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Required", { ns: "provider" }),
          path: ["valueString"],
        });
      }
    }
  });

export type IPatientGuidedCareProgramEvaluationFieldsForm = z.infer<typeof patientGuidedCareProgramEvaluationFieldsFormSchema>;
