import { makeStyles } from "@toolkit/ui";

export const usePatientContactInformationStyle = makeStyles()(() => ({
  wrapper: {
    display: "flex",
    gap: 4,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
}));
