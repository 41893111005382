import { Box, Button, CircularProgress, TextField, useAddToast } from "@toolkit/ui";
import React, { useState, ChangeEvent } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useCustomFormContext } from "@toolkit/core";
import { useBranchLicenseIdGetLazyQuery } from "../../gql";
import { convertCodeSystemConceptPropertiesToFormValues } from "../../others";
import { IBranchUpsertForm } from "../BranchUpsert/BranchUpsertFormSchema";
import { useBranchLicenseIdStyle } from "./useBranchLicenseIdStyle";

export const BranchLicenseIdForm = () => {
  const [licenseId, setLicenseId] = useState("");

  const { t } = useTranslation("domains");

  const { failed } = useAddToast();

  const { classes } = useBranchLicenseIdStyle();

  const form = useCustomFormContext<IBranchUpsertForm>();

  const { setValues, reset } = form;

  const [getBranchLicenseId, { loading }] = useBranchLicenseIdGetLazyQuery({
    onCompleted: data => {
      const branchLicenseIdProperties = data?.getCodeSystemConceptsByCode?.edges?.[0]?.node?.properties;

      if (!branchLicenseIdProperties || !branchLicenseIdProperties?.length) {
        return failed("Data Not Found");
      }

      const values = convertCodeSystemConceptPropertiesToFormValues(branchLicenseIdProperties);

      reset();
      setValues(values);
    },
  });

  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLicenseId(event.target.value);
  };

  const onSearchClick = () => {
    if (!licenseId) return;

    getBranchLicenseId({
      variables: {
        value: licenseId,
      },
    });
  };

  return (
    <Box className={classes.root}>
      <TextField value={licenseId} label={t("Search")} placeholder={t("Search")} fullWidth onChange={onTextFieldChange} />

      <Button
        disabled={!licenseId || loading}
        className={classes.button}
        endIcon={loading ? <CircularProgress size={20} /> : null}
        onClick={onSearchClick}
      >
        {t("Search")}
      </Button>
    </Box>
  );
};
