import { InsuranceBenefitApprovalLimit } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertInsuranceBenefitApprovalLimitToFormValues } from "../../others";
import {
  insuranceBenefitApprovalLimitUpsertFormDefaultValues,
  insuranceBenefitApprovalLimitUpsertFormSchema,
  IInsuranceBenefitApprovalLimitUpsertFormValues,
} from "./insuranceBenefitApprovalLimitUpsertFormSchema";
import { InsuranceBenefitApprovalLimitInformationForm } from "../InsuranceBenefitApprovalLimitInformation/InsuranceBenefitApprovalLimitInformationForm";

export type IInsuranceBenefitApprovalLimitUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IInsuranceBenefitApprovalLimitUpsertFormValues;
  };
};

type InsuranceBenefitApprovalLimitUpsertFormProps = {
  insuranceBenefitApprovalLimit?: InsuranceBenefitApprovalLimit;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IInsuranceBenefitApprovalLimitUpsertFormEvent) => void;
};

export const InsuranceBenefitApprovalLimitUpsertForm: FC<InsuranceBenefitApprovalLimitUpsertFormProps> = props => {
  const { buttonLabel, insuranceBenefitApprovalLimit, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IInsuranceBenefitApprovalLimitUpsertFormValues>({
    defaultValues: insuranceBenefitApprovalLimitUpsertFormDefaultValues,
    schema: insuranceBenefitApprovalLimitUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IInsuranceBenefitApprovalLimitUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (insuranceBenefitApprovalLimit) {
      const _insuranceBenefitApprovalLimit = convertInsuranceBenefitApprovalLimitToFormValues(insuranceBenefitApprovalLimit);
      setValues(_insuranceBenefitApprovalLimit);
    }
  }, [insuranceBenefitApprovalLimit, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Insurance Benefit Approval Limits Information")} loading={isLoading} doYouHaveData>
                <InsuranceBenefitApprovalLimitInformationForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
