import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramNetworksAutocompleteQuery } from "./gql";
import { IHealthProgramNetworkAutocomplete } from "./schema";

export const createHealthProgramNetworkAutocompleteOption = (healthProgramNetwork: IHealthProgramNetworkAutocomplete) => {
  return createAutocompleteOption({ id: healthProgramNetwork?.id!, name: healthProgramNetwork?.name! }, "id", "name");
};

export const getHealthProgramNetworksAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "healthProgramNetworks",
    query: useHealthProgramNetworksAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
