import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayerListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PayerFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.PayerOrder>;
}>;


export type PayerListQuery = { __typename?: 'Query', payers?: { __typename?: 'PayerCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'PayerCountableEdge', node: { __typename?: 'Payer', id: string, logo?: string | null, name: string, nameAr?: string | null, contactName?: string | null, contactEmail?: string | null, contactMobileNumber?: string | null, contactPhoneNumber?: string | null, licenseNumber: string, tpo: Types.PayerTpo, type: Types.PayerType, isActive: boolean, created: any } }> } | null };


export const PayerListDocument = gql`
    query PayerList($filter: PayerFilterInput, $before: String, $after: String, $first: Int, $last: Int, $sortBy: PayerOrder) {
  payers(
    filter: $filter
    before: $before
    after: $after
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        logo
        name
        nameAr
        contactName
        contactEmail
        contactMobileNumber
        contactPhoneNumber
        licenseNumber
        tpo
        type
        isActive
        created
      }
    }
  }
}
    `;

/**
 * __usePayerListQuery__
 *
 * To run a query within a React component, call `usePayerListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayerListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePayerListQuery(baseOptions?: Apollo.QueryHookOptions<PayerListQuery, PayerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayerListQuery, PayerListQueryVariables>(PayerListDocument, options);
      }
export function usePayerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayerListQuery, PayerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayerListQuery, PayerListQueryVariables>(PayerListDocument, options);
        }
export function usePayerListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PayerListQuery, PayerListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PayerListQuery, PayerListQueryVariables>(PayerListDocument, options);
        }
export type PayerListQueryHookResult = ReturnType<typeof usePayerListQuery>;
export type PayerListLazyQueryHookResult = ReturnType<typeof usePayerListLazyQuery>;
export type PayerListSuspenseQueryHookResult = ReturnType<typeof usePayerListSuspenseQuery>;
export type PayerListQueryResult = Apollo.QueryResult<PayerListQuery, PayerListQueryVariables>;