import { Field, SubListInput } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import {
  SublistItemListDocument,
  useSublistCreateMutation,
  useSublistDownloadLazyQuery,
  useSublistGetLazyQuery,
  useSublistUpdateMutation,
  useSublistUploadMutation,
} from "@/pages/Sublists/gql";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useReportAnError } from "@/shared/hooks";
import { domainsConfig } from "../../../../domainsConfig";

type SublistUpsertFormHook = {
  id: string;
};

export const useSublistUpsertForm = ({ id }: SublistUpsertFormHook) => {
  const { t } = useTranslation("domains");
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const { reportGraphQlErrors, reportError, reportGenericApiError } = useReportAnError();

  const methods = useForm<SubListInput>({
    mode: "all",
  });

  const [fetchSubList, { data, loading }] = useSublistGetLazyQuery({
    variables: {
      id: id,
    },
  });

  const defaults = data?.getSubList;

  const { errors } = methods.formState;

  const [create, { loading: isSubmittingCreate }] = useSublistCreateMutation();
  const [update, { loading: isSubmittingUpdate }] = useSublistUpdateMutation();

  const onSubmit = (input?: SubListInput) => {
    const method = id ? update : create;

    method({
      variables: {
        subList: {
          display: input?.display,
          field: { id: (input?.field as Field)?.id! },
          id: id || null,
        },
      },
      onCompleted: mutationData => {
        const errors = mutationData.updateSubListItem?.errors;
        if (!mutationData.updateSubListItem?.errors?.length) {
          addToast(id ? t("Sublist updated Successfully!") : t("Sublist Added Successfully!"), {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          addToast(t(formatMessageErrors(errors)), {
            appearance: "error",
            autoDismiss: true,
          });
        }
        navigate(-1);
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error saving sublist"))(graphQLErrors),
    });
  };

  const handleSubmit = methods.handleSubmit(onSubmit);
  const [download] = useSublistDownloadLazyQuery();
  const [fetchImportSubList, { data: importData, loading: isSubmittingList }] = useSublistUploadMutation();

  const [isUploadDialogOpened, setIsUploadDialogOpened] = useState(false);

  const handleDownloadClicked = () => {
    download({
      variables: {
        subListId: id!,
      },
    })
      .then(e => {
        if (e.data?.downloadSubList) {
          const baseURL = domainsConfig.get("serverUrl");
          window.open(baseURL + e.data?.downloadSubList, "_self");
        } else {
          reportError(t("Failed to download list"));
        }
      })
      .catch(reportGenericApiError(t("Failed to download list")));
  };

  const handleOpenImport = () => {
    setIsUploadDialogOpened(true);
  };

  const handleOnCloseImportDialog = () => {
    setIsUploadDialogOpened(false);
  };

  const handleOnUpload = file => {
    fetchImportSubList({
      refetchQueries: [SublistItemListDocument],
      variables: {
        file,
        subListId: id!,
      },
      onCompleted: e => {
        if (e.uploadSubList?.errors?.length) {
          addToast(t("Failed to import list") + "\n" + formatMessageErrors(e.uploadSubList?.errors), {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        handleOnCloseImportDialog();
        addToast(t("Imported Successfully"), { autoDismiss: true, appearance: "success" });
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Error import list"))(graphQLErrors),
    });
  };

  useEffect(() => {
    if (!id) return;
    fetchSubList({
      variables: {
        id,
      },
    });
  }, [id]);

  return {
    defaults,
    methods,
    errors,
    isSubmitting: isSubmittingUpdate || isSubmittingCreate,
    isDataLoading: loading,
    importData,
    isSubmittingList,
    isUploadDialogOpened,
    handleDownloadClicked,
    handleOpenImport,
    handleOnCloseImportDialog,
    handleOnUpload,
    handleSubmit,
    onSubmit,
  };
};
