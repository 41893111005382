import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SystemCodeAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  keyword?: Types.Scalars['String']['input'];
  codeSystemCode?: Types.InputMaybe<Types.CodeSystemCode>;
  codeSystemId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  keywordSearch?: Types.InputMaybe<Types.KeywordSearch>;
  searchParameters?: Types.InputMaybe<Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>>;
}>;


export type SystemCodeAutocompleteQuery = { __typename?: 'Query', codeSystemConceptSearch?: { __typename?: 'CodeSystemConceptConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', code?: string | null, display?: string | null } | null } | null> | null } | null };


export const SystemCodeAutocompleteDocument = gql`
    query SystemCodeAutocomplete($first: Int, $last: Int, $after: String, $before: String, $keyword: String! = "", $codeSystemCode: CodeSystemCode, $codeSystemId: ID, $keywordSearch: KeywordSearch, $searchParameters: [SearchParameter]) {
  codeSystemConceptSearch(
    keyword: $keyword
    after: $after
    before: $before
    codeSystemCode: $codeSystemCode
    codeSystemId: $codeSystemId
    first: $first
    keywordSearch: $keywordSearch
    last: $last
    searchParameters: $searchParameters
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        code
        display
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useSystemCodeAutocompleteQuery__
 *
 * To run a query within a React component, call `useSystemCodeAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemCodeAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemCodeAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      keyword: // value for 'keyword'
 *      codeSystemCode: // value for 'codeSystemCode'
 *      codeSystemId: // value for 'codeSystemId'
 *      keywordSearch: // value for 'keywordSearch'
 *      searchParameters: // value for 'searchParameters'
 *   },
 * });
 */
export function useSystemCodeAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>(SystemCodeAutocompleteDocument, options);
      }
export function useSystemCodeAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>(SystemCodeAutocompleteDocument, options);
        }
export function useSystemCodeAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>(SystemCodeAutocompleteDocument, options);
        }
export type SystemCodeAutocompleteQueryHookResult = ReturnType<typeof useSystemCodeAutocompleteQuery>;
export type SystemCodeAutocompleteLazyQueryHookResult = ReturnType<typeof useSystemCodeAutocompleteLazyQuery>;
export type SystemCodeAutocompleteSuspenseQueryHookResult = ReturnType<typeof useSystemCodeAutocompleteSuspenseQuery>;
export type SystemCodeAutocompleteQueryResult = Apollo.QueryResult<SystemCodeAutocompleteQuery, SystemCodeAutocompleteQueryVariables>;