/* eslint-disable max-lines */
import { ProcessingStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CircularProgress, CustomDialog, Divider, ExcelIcon, Grid, Typography, useTheme } from "@toolkit/ui";
import { first, last } from "lodash";
import moment from "moment";
import { FC, useState } from "react";
import { getCodeSystemProcessingStatusColor, getCodeSystemProcessingStatusText } from "../../../shared/utils";
import { VersionRowProps } from "./VersionsList.types";

export const VersionRow: FC<VersionRowProps> = ({
  versionParamId,
  version: versionItemDetails,
  isLoading,
  latestApprovedVersionNumber,
  onVersionOpenClick,
  onVersionActiveChange,
  onDownload,
}) => {
  const { t } = useTranslation("domains");
  const theme = useTheme();
  const [isActivateOpen, setIsActivateOpen] = useState(false);
  const { id, isActive, version, codeSystemHistory, processingStatus } = versionItemDetails || {};
  const lasteUpdatedVersion = last(codeSystemHistory);
  const firstVersion = first(codeSystemHistory);
  const uploadDate = firstVersion?.updateDate ? moment(firstVersion?.updateDate).format("DD MMMM YYYY") : "";
  const updateDate = lasteUpdatedVersion?.updateDate ? moment(lasteUpdatedVersion?.updateDate).format("DD MMMM YYYY") : "";

  const activeColor = isActive ? theme.palette.success.main : theme.palette.primary.main;

  const handleVersionOpenClick = () => {
    onVersionOpenClick?.(id!, version);
  };

  const handleDownloadClick = () => {
    onDownload?.(id);
  };

  const handleToggleActivate = () => {
    setIsActivateOpen(!isActivateOpen);
  };

  const handleSwitchChange = event => {
    const value = event.target.checked;
    onVersionActiveChange?.(id, value);
    setIsActivateOpen(false);
  };
  return (
    <>
      <Grid container direction={"row"} alignItems='center'>
        <Grid item container flex={1}>
          <Box
            display={"flex"}
            width={40}
            height={40}
            bgcolor={activeColor}
            borderRadius={40}
            justifyContent='center'
            alignItems='center'
            marginRight={1}
          >
            <Typography
              color={theme.palette.common.white}
              fontSize={version! < 1000 ? theme.mixins.fonts.fontSize.lg : theme.mixins.fonts.fontSize.sm}
              fontWeight={"bold"}
            >
              {version}
            </Typography>
          </Box>
          <Grid item>
            <Typography
              color={activeColor}
              fontWeight={"bold"}
              fontSize={theme.mixins.fonts.fontSize.sm}
              paddingTop={isActive ? "" : "10px"}
            >
              {t("Version")} {version}
            </Typography>
            {isActive && (
              <Typography color={activeColor} fontSize={theme.mixins.fonts.fontSize.sm}>
                {t("Current Version")}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item flex={1} container direction={"column"} alignItems={"center"}>
          <Typography color={getCodeSystemProcessingStatusColor(processingStatus, theme.palette)} fontSize={theme.mixins.fonts.fontSize.sm}>
            {getCodeSystemProcessingStatusText(t, processingStatus as ProcessingStatus)}
          </Typography>
        </Grid>

        <Grid item container flex={1} alignItems={"center"}>
          <Grid item>
            <Typography color={theme.palette.primary.main} fontSize={theme.mixins.fonts.fontSize.sm}>
              {uploadDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container flex={1} alignItems={"center"}>
          <Grid item>
            <Typography color={theme.palette.primary.main} fontSize={theme.mixins.fonts.fontSize.sm}>
              {updateDate}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container flex={1} alignItems={"center"}>
          <Button onClick={handleDownloadClick} variant='text' startIcon={<ExcelIcon fill={theme.palette.primary.main} />}>
            {t("Download")}
          </Button>
        </Grid>

        <Grid item flex={1} alignItems={"center"}>
          {processingStatus == ProcessingStatus.Approved && (
            <CustomDialog
              type='base'
              maxWidth='xs'
              open={isActivateOpen}
              DialogTitleProps={{
                onClose: handleToggleActivate,
              }}
              button={
                <Button
                  color={"success"}
                  disabled={!!isActive || isLoading || latestApprovedVersionNumber !== version}
                  onClick={handleToggleActivate}
                  startIcon={isLoading && <CircularProgress size={15} />}
                >
                  {!isActive ? t("Publish") : t("Activated")}
                </Button>
              }
              DialogActionsProps={{
                children: (
                  <>
                    <Button color={"success"} onClick={handleSwitchChange}>
                      {t("Publish")}
                    </Button>
                    <Button color='error' size='medium' onClick={handleToggleActivate}>
                      {t("Cancel")}
                    </Button>
                  </>
                ),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={theme.mixins.fonts.fontSize.xl} marginBottom='16px' color={theme.palette.primary.main}>
                  {t("Are you sure you want to publish this version?")}
                </Typography>

                <Typography>{t("If you publish this version, all items in the list will be updated.")}</Typography>
              </Box>
            </CustomDialog>
          )}
        </Grid>
        <Grid item>
          <Box width={100}>{versionParamId != id && <Button onClick={handleVersionOpenClick}>{t("Open")}</Button>}</Box>
        </Grid>
      </Grid>
      <Box marginTop={2} marginBottom={2}>
        <Divider
          sx={{
            borderBottomWidth: isActive ? "medium" : "light",
          }}
        />
      </Box>
    </>
  );
};
