import { FormAutocomplete, FormNumberField, Grid, mapStringsToAutocompleteOptions } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { appointmentTypeOptions } from "@health/enum-options";
import { useCustomFormContext } from "@toolkit/core";
import { AppointmentType } from "@/schema/types";
import { IUserProviderUpsertForm } from "../UserProviderUpsert/UserProviderUpsertFormSchema";
import { isAppointmentTypePresent } from "../../others";

export const UserProviderDoctorDetailsAppointmentInformationForm = () => {
  const { t } = useTranslation("domains");

  const form = useCustomFormContext<IUserProviderUpsertForm>();

  const appointmentTypes = form.watch("doctorInfo.appointmentTypes");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormAutocomplete
          name={"doctorInfo.appointmentSlotTimePeriod"}
          label={t("Appointment Slot Time Period")}
          options={mapStringsToAutocompleteOptions(["15", "20", "30", "45"])}
        />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"doctorInfo.appointmentTypes"} label={t("Appointment Types")} multiple options={appointmentTypeOptions} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormNumberField
              name={"doctorInfo.atHomeVisitPrice"}
              isFloatAllowed
              label={t("At Home Visit Price")}
              placeholder={t("At Home Visit Price")}
              disabled={!isAppointmentTypePresent(appointmentTypes, AppointmentType.AtHome)}
            />
          </Grid>

          <Grid item xs={4}>
            <FormNumberField
              name={"doctorInfo.onsiteVisitPrice"}
              isFloatAllowed
              label={t("In Person Visit Price")}
              placeholder={t("In Person Visit Price")}
              disabled={!isAppointmentTypePresent(appointmentTypes, AppointmentType.Onsite)}
            />
          </Grid>

          <Grid item xs={4}>
            <FormNumberField
              name={"doctorInfo.onlineVisitPrice"}
              isFloatAllowed
              label={t("Online Visit Price")}
              placeholder={t("Online Visit Price")}
              disabled={!isAppointmentTypePresent(appointmentTypes, AppointmentType.Online)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
