import { z } from "zod";

export const cityUpsertFormSchema = z.object({
  name: z.string().min(1).max(255),
  nameAr: z.string().min(1).max(255),
  area: z.string().min(1).max(255),
  code: z.string().min(1).max(255),
});

export type ICityUpsertFormValues = z.infer<typeof cityUpsertFormSchema>;

export const cityUpsertFormDefaultValues: Partial<ICityUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  area: undefined,
  code: undefined,
};
