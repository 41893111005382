import { isNil } from "lodash";
import { isEqual } from "lodash-es";

export const isValueModified = (oldValue?: string | number | boolean | null, newValue?: string | number | boolean | null) => {
  if (isNil(newValue) && isNil(oldValue)) return false;

  if (isNil(newValue) || isNil(oldValue)) return true;

  return !isEqual(newValue, oldValue);
};
