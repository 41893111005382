import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { CaseManagementPatientDigitalTwin } from "../../components/CaseManagementPatientDigitalTwin/CaseManagementPatientDigitalTwin";

type CaseManagementPatientDigitalTwinModalProps = {
  nationalId: string;
};

export const CaseManagementPatientDigitalTwinModal: FC<CaseManagementPatientDigitalTwinModalProps> = props => {
  const { nationalId } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!nationalId} />} title={t("Patient Digital Twin")}>
      <CaseManagementPatientDigitalTwin nationalId={nationalId} />
    </CustomDialog>
  );
};
