import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVendorLikeDislikeEnabledQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetVendorLikeDislikeEnabledQuery = { __typename?: 'Query', me?: { __typename?: 'User', vendor?: { __typename?: 'Vendor', editLikeEnabled: boolean } | null } | null };


export const GetVendorLikeDislikeEnabledDocument = gql`
    query getVendorLikeDislikeEnabled {
  me {
    vendor {
      editLikeEnabled
    }
  }
}
    `;

/**
 * __useGetVendorLikeDislikeEnabledQuery__
 *
 * To run a query within a React component, call `useGetVendorLikeDislikeEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorLikeDislikeEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorLikeDislikeEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVendorLikeDislikeEnabledQuery(baseOptions?: Apollo.QueryHookOptions<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>(GetVendorLikeDislikeEnabledDocument, options);
      }
export function useGetVendorLikeDislikeEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>(GetVendorLikeDislikeEnabledDocument, options);
        }
export function useGetVendorLikeDislikeEnabledSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>(GetVendorLikeDislikeEnabledDocument, options);
        }
export type GetVendorLikeDislikeEnabledQueryHookResult = ReturnType<typeof useGetVendorLikeDislikeEnabledQuery>;
export type GetVendorLikeDislikeEnabledLazyQueryHookResult = ReturnType<typeof useGetVendorLikeDislikeEnabledLazyQuery>;
export type GetVendorLikeDislikeEnabledSuspenseQueryHookResult = ReturnType<typeof useGetVendorLikeDislikeEnabledSuspenseQuery>;
export type GetVendorLikeDislikeEnabledQueryResult = Apollo.QueryResult<GetVendorLikeDislikeEnabledQuery, GetVendorLikeDislikeEnabledQueryVariables>;