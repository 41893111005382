import { SubListItem } from "@/schema/types";
import { TableGrid } from "@toolkit/ui";
import { useSublistItemDeleteMutation, useSublistItemListQuery } from "@/pages/Sublists/gql";
import { FC } from "react";
import { GridProvider } from "@/shared/components";
import { useSublistItemListColumnsContainer } from "./useSublistItemListColumnsContainer";

type SublistItemListProps = {
  id: string;
  onEditItemRowClick: (item: SubListItem) => void;
};

export const SublistItemList: FC<SublistItemListProps> = props => {
  const { id, onEditItemRowClick } = props;

  return (
    <GridProvider
      hasTableSetting
      gridName='sublistItemList'
      columns={useSublistItemListColumnsContainer()}
      query={useSublistItemListQuery}
      variables={{ id }}
      tableAction={{
        isEditVisible: true,
        isDeleteVisible: true,
        onEditRow: onEditItemRowClick,
      }}
      deleteItemProps={{
        useDeleteMutation: useSublistItemDeleteMutation,
        entityTypeName: "SubListItem",
        name: "SubList",
      }}
    >
      <TableGrid />
    </GridProvider>
  );
};
