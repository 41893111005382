import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareTeamsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ProviderGuidedCareHealthProgramTeamFilterInput>;
}>;


export type GuidedCareTeamsAutocompleteQuery = { __typename?: 'Query', providerGuidedCareHealthProgramTeams?: { __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge', node: { __typename?: 'ProviderGuidedCareHealthProgramTeam', id: string, name?: string | null } }> } | null };


export const GuidedCareTeamsAutocompleteDocument = gql`
    query GuidedCareTeamsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: ProviderGuidedCareHealthProgramTeamFilterInput) {
  providerGuidedCareHealthProgramTeams(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useGuidedCareTeamsAutocompleteQuery__
 *
 * To run a query within a React component, call `useGuidedCareTeamsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareTeamsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareTeamsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGuidedCareTeamsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>(GuidedCareTeamsAutocompleteDocument, options);
      }
export function useGuidedCareTeamsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>(GuidedCareTeamsAutocompleteDocument, options);
        }
export function useGuidedCareTeamsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>(GuidedCareTeamsAutocompleteDocument, options);
        }
export type GuidedCareTeamsAutocompleteQueryHookResult = ReturnType<typeof useGuidedCareTeamsAutocompleteQuery>;
export type GuidedCareTeamsAutocompleteLazyQueryHookResult = ReturnType<typeof useGuidedCareTeamsAutocompleteLazyQuery>;
export type GuidedCareTeamsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useGuidedCareTeamsAutocompleteSuspenseQuery>;
export type GuidedCareTeamsAutocompleteQueryResult = Apollo.QueryResult<GuidedCareTeamsAutocompleteQuery, GuidedCareTeamsAutocompleteQueryVariables>;