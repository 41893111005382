import { i18n } from "@toolkit/i18n";
import { isValid } from "date-fns";
import { get, isFunction } from "lodash";
import React from "react";
import { format } from "../FormatDate";
import { TableCellRendererProps } from "./components/TableCellRenderer/TableCellRenderer";
import { DEFAULT_CELL_FALLBACK, tableDateTypesFormatsMapper } from "./constants";
import { CustomTableColumnProps, SortDirection } from "./types";

export const nextSortDirection = (direction: SortDirection | undefined): SortDirection | undefined => {
  return direction === "ASC" ? "DESC" : "ASC";
};

export const getNextSort = (direction: SortDirection | undefined): SortDirection | undefined => {
  if (direction === "ASC") return "DESC";
  if (direction === "DESC") return "ASC";
  return "DESC";
};

export const handleSortGeneric =
  <T extends object>(configs: {
    setColumns: (value: React.SetStateAction<CustomTableColumnProps<T>[]>) => void;
    doSort: (sortColumnEnum: string) => void;
  }) =>
  (column: CustomTableColumnProps<T>): void => {
    const { setColumns, doSort } = configs;
    setColumns(c =>
      c.map(_c => {
        if (_c.key === column.key && _c.sort) {
          _c.sort.direction = getNextSort(_c.sort?.direction);
        } else if (_c.sort) {
          _c.sort.direction = undefined;
        }
        return _c;
      })
    );
    doSort(column?.sort?.columnEnum!);
  };

export default { nextSortDirection };

export const getRowValue = <RowType extends object>(props: TableCellRendererProps<RowType>): React.ReactNode => {
  const { row, column, index } = props;
  let rawValue;

  if (isFunction(column.accessor)) {
    rawValue = column.accessor(row, index);
  } else if (column.accessor) {
    rawValue = get(row, column.accessor);
  } else {
    rawValue = null;
  }

  const isDate = column.type && ["date", "datetime", "time"].includes(column.type);
  if (column.type && isDate) {
    const dateValue = String(rawValue);
    if (isValid(new Date(dateValue))) {
      rawValue = format(dateValue, tableDateTypesFormatsMapper[column.type], i18n.language);
    }
  }

  if (rawValue === null || rawValue === undefined || rawValue === "") {
    return DEFAULT_CELL_FALLBACK;
  }
  return rawValue;
};
