import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowDocumentDeleteMutationVariables = Types.Exact<{
  chatFlowDocumentDeleteId: Types.Scalars['ID']['input'];
}>;


export type ChatFlowDocumentDeleteMutation = { __typename?: 'Mutation', chatFlowDocumentDelete?: { __typename?: 'ChatFlowDocument', id: string } | null };


export const ChatFlowDocumentDeleteDocument = gql`
    mutation ChatFlowDocumentDelete($chatFlowDocumentDeleteId: ID!) {
  chatFlowDocumentDelete(id: $chatFlowDocumentDeleteId) {
    id
  }
}
    `;
export type ChatFlowDocumentDeleteMutationFn = Apollo.MutationFunction<ChatFlowDocumentDeleteMutation, ChatFlowDocumentDeleteMutationVariables>;

/**
 * __useChatFlowDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useChatFlowDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowDocumentDeleteMutation, { data, loading, error }] = useChatFlowDocumentDeleteMutation({
 *   variables: {
 *      chatFlowDocumentDeleteId: // value for 'chatFlowDocumentDeleteId'
 *   },
 * });
 */
export function useChatFlowDocumentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ChatFlowDocumentDeleteMutation, ChatFlowDocumentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatFlowDocumentDeleteMutation, ChatFlowDocumentDeleteMutationVariables>(ChatFlowDocumentDeleteDocument, options);
      }
export type ChatFlowDocumentDeleteMutationHookResult = ReturnType<typeof useChatFlowDocumentDeleteMutation>;
export type ChatFlowDocumentDeleteMutationResult = Apollo.MutationResult<ChatFlowDocumentDeleteMutation>;
export type ChatFlowDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<ChatFlowDocumentDeleteMutation, ChatFlowDocumentDeleteMutationVariables>;