import { GoogleMap, InfoWindow, MarkerF } from "@react-google-maps/api";
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography, paths } from "@toolkit/ui";
import _, { isEmpty } from "lodash";
import { FC, useCallback } from "react";
import GoogleMapSearchComponent from "../GoogleMapSearch/GoogleMapSearchComponent";
import { useLocationPickerStyles } from "./styles";
import { ILocationPickerProps } from "./types";
import { useLocationPickerHook } from "./useLocationPickerHook";

const LocationPicker: FC<ILocationPickerProps> = props => {
  const { classes: _classes, searchOptions } = props;
  const { classes, theme, cx } = useLocationPickerStyles();
  const {
    containerStyle,
    mapContainerStyle,
    searchContainerStyle,
    searchTitle,
    location,
    locations,
    addressError,
    addressRef,
    hasSearch,
    isLoaded,
    loadError,
    icon,
    addressName,
    hasLocationLngLat,
    center,
    defaultZoom,
    isChecked,
    containerClassName,
    t,
    handlePickLocation,
    handleIsChecked,
    handleLocationChanged,
    handleSearchLocation,
  } = useLocationPickerHook(props);

  const getMarkerIcon = useCallback(
    () => ({
      path: _.get(paths, icon!) || paths?.["markerMap"],
      fillColor: theme.palette.secondary.main,
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 15),
    }),
    [theme.palette.secondary.main, icon]
  );
  if (!isLoaded || loadError) {
    if (loadError) {
      <Typography color='error'>{loadError.message}</Typography>;
    }
    return <Box>Loading maps...</Box>;
  }

  return (
    <Grid container item sx={{ ...containerStyle }} className={cx(classes.root, _classes?.root)}>
      <Grid item xs={12} mb='15px' sx={{ ...searchContainerStyle }}>
        {searchTitle && (
          <Typography fontWeight={theme.mixins.fonts.fontWeight.medium} fontSize={theme.mixins.fonts.fontSize.md}>
            {searchTitle}
          </Typography>
        )}
        {hasSearch && (
          <GoogleMapSearchComponent
            classes={searchOptions?.classes}
            placeholder={searchOptions?.placeholder}
            onLocationChange={handleSearchLocation}
          />
        )}
      </Grid>
      <Grid ref={addressRef} item xs={12} sx={{ ...mapContainerStyle }}>
        {addressError && (
          <Typography color='error' mt='5px'>
            {addressError}
          </Typography>
        )}
        {hasLocationLngLat && (
          <Box m='20px 0'>
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleIsChecked} />}
              label={t("Show Longitude and Latitude Inputs")}
            />
            {isChecked && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    name='latitude'
                    value={location?.lat || ""}
                    onChange={handleLocationChanged("lat")}
                    placeholder={t("Latitude")}
                    label={t("Latitude")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    name='longitude'
                    value={location?.lng || ""}
                    onChange={handleLocationChanged("lng")}
                    placeholder={t("Longitude")}
                    label={t("Longitude")}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <GoogleMap
          mapContainerClassName={cx(classes.containerMapStyle, containerClassName, _classes?.containerMapStyle)}
          center={center}
          zoom={defaultZoom}
          onClick={handlePickLocation}
          {...props.googleMapProps}
        >
          {location?.lat && location?.lng && (
            <MarkerF clickable position={location} icon={getMarkerIcon()}>
              {addressName && (
                <InfoWindow>
                  <Typography>{addressName}</Typography>
                </InfoWindow>
              )}
            </MarkerF>
          )}
          {!isEmpty(locations) &&
            locations?.map(locationItem => (
              <MarkerF key={locationItem?.id} clickable position={locationItem?.coordinates} icon={getMarkerIcon()} />
            ))}
        </GoogleMap>
      </Grid>
    </Grid>
  );
};

LocationPicker.defaultProps = {
  icon: "markerMap",
  hasSearch: false,
  hasLocationLngLat: false,
  defaultCenter: { lat: 24.750643, lng: 46.707766 },
  location: undefined,
  containerClassName: undefined,
  defaultZoom: 13,
};

export default LocationPicker;
