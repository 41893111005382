import { HealthProgramNetworkProviderProfessional } from "@/schema/types";
import { CustomTable } from "@toolkit/ui";
import { FC } from "react";
import { useHealthProgramNetworkProviderProfessionalsColumns } from "./useHealthProgramNetworkProviderProfessionalsColumns";

type HealthProgramNetworkProviderProfessionalsProps = {
  professionals: HealthProgramNetworkProviderProfessional[];
};

export const HealthProgramNetworkProviderProfessionals: FC<HealthProgramNetworkProviderProfessionalsProps> = props => {
  const { professionals } = props;

  return <CustomTable data={professionals} columns={useHealthProgramNetworkProviderProfessionalsColumns()} scrollable hasFooter={false} />;
};
