import { PermissionEnum } from "@/schema/types";
import { hasAnyPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { PrescriptionIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { prescriptionsOrdersTrackingPaths, prescriptionsTrackingOrdersRoute } from "./constants/PrescriptionsTrackingPaths";
import { AdminPrescriptionsOrdersTrackingContainer } from "./List/PrescriptionsOrdersTrackingContainer";

export const prescriptionsOrdersTrackingRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({
  navigate,
  t,
}): RouteItem => {
  const canTrackOrders = hasAnyPermission([PermissionEnum.ViewOrders, PermissionEnum.ManageOrders]);

  return {
    id: "prescriptions-order-tracking-routes",
    text: t("Prescriptions Orders Tracking", { ns: "admin" }),
    route: prescriptionsTrackingOrdersRoute,
    fullPath: prescriptionsOrdersTrackingPaths.mainPath.fullPath,
    icon: <PrescriptionIcon />,
    element: <AdminPrescriptionsOrdersTrackingContainer />,
    isProhibited: !canTrackOrders,
    onClick: (route: string) => navigate(route),
  };
};
