import { TFunction } from "@toolkit/i18n";
import { adminManagedListsPaths } from "./paths";

export const adminManageListsTitleBreadcrumb = (t: TFunction) => {
  return t("Admin Managed Lists", { ns: "domains" });
};

export const adminManageListBreadcrumb = (t: TFunction) => {
  return {
    id: "admin-managed-list",
    name: t("Admin Managed Lists", { ns: "domains" }),
    route: adminManagedListsPaths.listPath.fullPath,
  };
};

export const adminManageListFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "admin-managed-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
