import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { ArticleCategoryFilterInput } from "../../schema/types";
import { useArticleCategoriesAutocompleteQuery } from "./gql";

type ArticleCategoriesAutocompleteProps = IAutocompleteProps<ArticleCategoryFilterInput>;

export const ArticleCategoriesAutocomplete: FC<ArticleCategoriesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useArticleCategoriesAutocompleteQuery,
    variables: { filter },
    searchKey: "display",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.display!, item?.displayAr!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Categories") : t("Category"))}
    />
  );
};
