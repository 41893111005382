import { i18n } from "@toolkit/i18n";
import { GuidedCareJourneyItemSource } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const guidedCareJourneyItemSourceOptionsMap: IEnumToOptionsMap<GuidedCareJourneyItemSource> = {
  [GuidedCareJourneyItemSource.Program]: {
    key: GuidedCareJourneyItemSource.Program,
    get label() {
      return i18n.t("Program", { ns: "domains" });
    },
    value: GuidedCareJourneyItemSource.Program,
  },
  [GuidedCareJourneyItemSource.Personalized]: {
    key: GuidedCareJourneyItemSource.Personalized,
    get label() {
      return i18n.t("Personalized", { ns: "domains" });
    },
    value: GuidedCareJourneyItemSource.Personalized,
  },
};

export const guidedCareJourneyItemSourceOptions = Object.values(guidedCareJourneyItemSourceOptionsMap);
