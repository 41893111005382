import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionDivisionsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  keyword?: Types.Scalars['String']['input'];
  codeSystemId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  keywordSearch?: Types.InputMaybe<Types.KeywordSearch>;
  searchParameters?: Types.InputMaybe<Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>>;
}>;


export type DecisionDivisionsAutocompleteQuery = { __typename?: 'Query', codeSystemConceptSearch?: { __typename?: 'CodeSystemConceptConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', id?: string | null, name?: string | null } | null } | null> | null } | null };


export const DecisionDivisionsAutocompleteDocument = gql`
    query DecisionDivisionsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $keyword: String! = "", $codeSystemId: ID, $keywordSearch: KeywordSearch, $searchParameters: [SearchParameter]) {
  codeSystemConceptSearch(
    keyword: $keyword
    after: $after
    before: $before
    codeSystemCode: DIVISION
    codeSystemId: $codeSystemId
    first: $first
    keywordSearch: $keywordSearch
    last: $last
    searchParameters: $searchParameters
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name: display
      }
    }
  }
}
    `;

/**
 * __useDecisionDivisionsAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionDivisionsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionDivisionsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionDivisionsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      keyword: // value for 'keyword'
 *      codeSystemId: // value for 'codeSystemId'
 *      keywordSearch: // value for 'keywordSearch'
 *      searchParameters: // value for 'searchParameters'
 *   },
 * });
 */
export function useDecisionDivisionsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>(DecisionDivisionsAutocompleteDocument, options);
      }
export function useDecisionDivisionsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>(DecisionDivisionsAutocompleteDocument, options);
        }
export function useDecisionDivisionsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>(DecisionDivisionsAutocompleteDocument, options);
        }
export type DecisionDivisionsAutocompleteQueryHookResult = ReturnType<typeof useDecisionDivisionsAutocompleteQuery>;
export type DecisionDivisionsAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionDivisionsAutocompleteLazyQuery>;
export type DecisionDivisionsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionDivisionsAutocompleteSuspenseQuery>;
export type DecisionDivisionsAutocompleteQueryResult = Apollo.QueryResult<DecisionDivisionsAutocompleteQuery, DecisionDivisionsAutocompleteQueryVariables>;