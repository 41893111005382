import { AdminPromotionSortingField, MarketplaceSortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { useAdminPromotionsQuery } from "pages/MarketplacePromotions/gql";
import { AdminPromotionsQueryNode } from "pages/MarketplacePromotions/types";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { promotionsBreadcrumb } from "../../constants/PromotionsBreadcrumbs";
import { promotionsPaths } from "../../constants/PromotionsPaths";
import { usePromotionsListTableColumns } from "./usePromotionsListTableColumns";

export const PromotionsList: FC = () => {
  const { setBreadCrumb } = useBreadCrumbs();
  const navigate = useNavigate();
  const onEditRowClick = (item: AdminPromotionsQueryNode) => {
    navigate(promotionsPaths.update.fullPathWithParams({ promotionId: item?.id }));
  };
  const onAddNewItemClick = () => {
    navigate(promotionsPaths.new.fullPath);
  };
  useEffect(() => {
    setBreadCrumb({
      title: promotionsBreadcrumb().title,
    });
  }, []);

  return (
    <GridProvider
      gridName='admin-promotions'
      query={useAdminPromotionsQuery}
      columns={usePromotionsListTableColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          field: AdminPromotionSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
