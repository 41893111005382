import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { IHealthProgramMembershipRequestRejectFormEvent } from "../../forms/HealthProgramMembershipRequestReject/HealthProgramMembershipRequestRejectForm";
import {
  HealthProgramMembershipRequestRejectModal,
  HealthProgramMembershipRequestRejectModalApi,
} from "../../modals/HealthProgramMembershipRequestReject/HealthProgramMembershipRequestRejectModal";
import { HealthProgramMembershipRequestListDocument, useHealthProgramMembershipRequestRejectMutation } from "../../gql";

export const HealthProgramMembershipRequestReject = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const [rejectHealthProgramMembershipRequest, { loading }] = useHealthProgramMembershipRequestRejectMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.healthProgramMembershipRequestReject?.healthProgramMembershipRequestErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Insurance Request Rejected"));
        HealthProgramMembershipRequestRejectModalApi.close();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: HealthProgramMembershipRequestListDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const onHealthProgramMembershipRequestRejectModalChange = (event: IHealthProgramMembershipRequestRejectFormEvent) => {
    if (event.type === "SUBMIT") {
      rejectHealthProgramMembershipRequest({
        variables: {
          id: event.payload.healthProgramMembershipRequestId,
          rejectionReason: event.payload.values?.reason,
        },
      });
    }
  };

  return <HealthProgramMembershipRequestRejectModal isLoading={loading} onChange={onHealthProgramMembershipRequestRejectModalChange} />;
};
