import { OperationVariables } from "@apollo/client";
import { merge } from "lodash";
import { useMemo } from "react";
import { GridContext } from "./GridContext";
import { useDataGridState } from "./hooks/useDataGridState";
import { useDeleteTableRow } from "./hooks/useDeleteTableRow";
import { useGridFilter } from "./hooks/useGridFilter";
import { IGridProviderBase } from "./types";

const useDefaultDeleteTableRow = () => {
  return {
    handleDeleteItem: (row: unknown) => {
      console.log("handleDeleteItem is not implemented", row);
    },
  };
};
export const GridProviderBase = <RowType extends object = any, TData = any, TVar extends OperationVariables = Record<string, any>>(
  props: IGridProviderBase<RowType, TData, TVar>
) => {
  const {
    children,
    query,
    columns: defaultColumns,
    deleteItemProps,
    dataAccessor,
    extractCountableConnectionData,
    tableAction,
    variables: inputVariables,
    onCompleted,
    onFiltersChanged,
    onError,
    pageSize = 10,
    context,
    skipCall = false,
    fetchPolicy,
    nextFetchPolicy,
    filterInput, // TODO - delete
    hideFilterInput = true,
    isCardView,
    selectionProps,
    skipUrlState = true,
    isColumnsLoading,
    onColumnsChanged,
    reOrderColumns,
  } = props;

  const input = useMemo(() => {
    const x = merge({}, inputVariables?.filter);
    return {
      first: pageSize,
      ...inputVariables,
      filter: merge(x, filterInput),
    } as any; // TODO Check
  }, [JSON.stringify(inputVariables), pageSize]);

  const {
    customTableProps,
    data: queryResponse,
    handleFilter,
    refetch,
    isLoading,
    selectedRows,
    onRemoveSelectedRow,
    onResetSelectedRows,
  } = useDataGridState({
    onCompleted: onCompleted,
    useCustomQuery: query,
    skipUrlState,
    onError: onError,
    dataAccessor: dataAccessor,
    extractCountableConnectionData,
    columns: defaultColumns,
    tablePreference: {
      isColumnsLoading,
      onColumnsChanged,
      reOrderColumns,
    },
    skip: skipCall,
    fetchPolicy,
    nextFetchPolicy,
    size: pageSize,
    tableAction,
    context,
    selectionProps,
    input,
  });

  const fields = defaultColumns?.filter(item => item?.filter);
  const { staticFiltersProps, activeFiltersProps } = useGridFilter({
    filterInput: input?.filter!,
    hideFilterInput,
    skipUrlState,
    doFilter: handleFilter,
    onFiltersChanged,
  });

  const { handleDeleteItem } = deleteItemProps ? useDeleteTableRow(deleteItemProps) : useDefaultDeleteTableRow();

  const tableProps = {
    ...customTableProps,
    isCardView,
    onDeleteRow: deleteItemProps ? handleDeleteItem : undefined,
  };

  return (
    <GridContext.Provider
      value={{
        customTableProps: tableProps as any,
        staticFiltersProps,
        activeFiltersProps,
        tablePreference: {
          isColumnsLoading: !!isColumnsLoading,
          onColumnsChanged: onColumnsChanged!,
          reOrderColumns: reOrderColumns,
        },
        fields,
        refetch,
        queryResponse,
        isLoading,
        rowId: selectionProps?.rowId,
        selectedRows,
        onRemoveSelectedRow,
        onResetSelectedRows,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
