import { makeStyles } from "tss-react/mui";

export const useTitleTextStyle = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  title: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
