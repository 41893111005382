import { z } from "zod";
import { surveyQuestionFormSchema } from "../SurveyQuestions/SurveyQuestionFormSchema";

export const surveyUpsertFormSchema = z.object({
  label: z.string().min(3),
  notification: z.string().min(3),
  description: z.string().min(3),
  questions: z.array(surveyQuestionFormSchema),
});
export type ISurveyUpsertFormValues = z.infer<typeof surveyUpsertFormSchema>;

export const surveyUpsertFormDefaultValues: Partial<ISurveyUpsertFormValues> = {
  label: "",
  notification: "",
  description: "",
  questions: [],
};
