import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { SystemCodeAutocompleteQuery } from "./gql";

export type ISystemCodeAutocomplete = ExtractNodeType<SystemCodeAutocompleteQuery>;

export const systemCodeSchema = createZodAutocompleteFromType<ISystemCodeAutocomplete>({
  code: z.string().nullish(),
  display: z.string().nullish(),
});
