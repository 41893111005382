import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import moment, { Moment } from "moment";
import MomentUtils from "@mui/lab/AdapterMoment";
import { BaseTimePickerProps, LocalizationProvider, MuiTimePicker, TextField } from "../../../../base/mui";
import { getTimeFormat } from "../others";

export type TimePickerProps = Partial<Omit<BaseTimePickerProps<Moment>, "value" | "onChange" | "onAccept">> & {
  error?: string;
  value?: string;
  onChange?: (value?: string) => void;
  onAccept?: (value?: string) => void;
};

export const TimePicker: FC<TimePickerProps> = props => {
  const { value, onChange, onAccept, error, ampm, ...rest } = props;

  const { t } = useTranslation();

  const onMuiTimePickerChange = (selectedValue: Moment | null) => {
    if (onChange) {
      onChange(selectedValue ? selectedValue.format() : undefined);
    }
  };

  const onMuiTimePickerAccept = (selectedValue: Moment | null) => {
    if (onAccept) {
      onAccept(selectedValue ? selectedValue.format() : undefined);
    }
  };

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <MuiTimePicker
        toolbarTitle={t("Select Time")}
        value={value ? moment(value) : null}
        ampm={ampm}
        inputFormat={getTimeFormat(ampm)}
        onChange={onMuiTimePickerChange}
        onAccept={onMuiTimePickerAccept}
        renderInput={({ inputProps, ...params }) => (
          <TextField
            {...params}
            fullWidth
            inputProps={{
              ...inputProps,
              readOnly: true,
            }}
            error={!!error}
            helperText={error}
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};
