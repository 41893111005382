import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowUpdateMutationVariables = Types.Exact<{
  chatFlowUpdateId: Types.Scalars['ID']['input'];
  input?: Types.InputMaybe<Types.ChatFlowUpdateInput>;
}>;


export type ChatFlowUpdateMutation = { __typename?: 'Mutation', chatFlowUpdate?: { __typename?: 'ChatFlow', id: string } | null };


export const ChatFlowUpdateDocument = gql`
    mutation ChatFlowUpdate($chatFlowUpdateId: ID!, $input: ChatFlowUpdateInput) {
  chatFlowUpdate(id: $chatFlowUpdateId, input: $input) {
    id
  }
}
    `;
export type ChatFlowUpdateMutationFn = Apollo.MutationFunction<ChatFlowUpdateMutation, ChatFlowUpdateMutationVariables>;

/**
 * __useChatFlowUpdateMutation__
 *
 * To run a mutation, you first call `useChatFlowUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowUpdateMutation, { data, loading, error }] = useChatFlowUpdateMutation({
 *   variables: {
 *      chatFlowUpdateId: // value for 'chatFlowUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatFlowUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ChatFlowUpdateMutation, ChatFlowUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatFlowUpdateMutation, ChatFlowUpdateMutationVariables>(ChatFlowUpdateDocument, options);
      }
export type ChatFlowUpdateMutationHookResult = ReturnType<typeof useChatFlowUpdateMutation>;
export type ChatFlowUpdateMutationResult = Apollo.MutationResult<ChatFlowUpdateMutation>;
export type ChatFlowUpdateMutationOptions = Apollo.BaseMutationOptions<ChatFlowUpdateMutation, ChatFlowUpdateMutationVariables>;