import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { ISystemCodeAutocompleteQueryFilter } from "./SystemCode";
import { useSystemCodeAutocompleteQuery } from "./gql";
import { ISystemCodeAutocomplete } from "./schema";

type SystemCodeAutocompleteFilter = IAutocompleteFilter & {
  queryFilter?: ISystemCodeAutocompleteQueryFilter;
  queryVariables: ISystemCodeAutocompleteQueryFilter;
  isCodeHidden?: boolean;
};

export const createSystemCodeAutocompleteOption = (systemCode: ISystemCodeAutocomplete, isCodeHidden = false) => {
  return createAutocompleteOption(
    {
      code: systemCode?.code!,
      display: systemCode?.display!,
    },
    "code",
    item => (isCodeHidden ? item?.display! : `${item?.code} - ${item.display}`)
  );
};

export const getSystemCodeAutocompleteFilter = ({
  name,
  isCodeHidden,
  multiple,
  queryFilter,
  queryVariables,
}: SystemCodeAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "codeSystemConceptSearch",
    query: useSystemCodeAutocompleteQuery,
    labelBy: isCodeHidden ? "display" : option => `${option?.code} - ${option?.display}`,
    backendAccessor: "code",
    isFilterSearchKeyAQueryVariable: true,
    filterSearchKey: "keyword",
    filtersForQuery: queryFilter,
    queryVariables,
  });
};
