import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSubListItemMutationVariables = Types.Exact<{
  subListItem: Types.SubListItemInput;
}>;


export type UpdateSubListItemMutation = { __typename?: 'Mutation', updateSubListItem?: { __typename: 'SubListItem', id?: string | null, code: string, display: string, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const UpdateSubListItemDocument = gql`
    mutation updateSubListItem($subListItem: SubListItemInput!) {
  updateSubListItem(subListItem: $subListItem) {
    id
    code
    display
    errors {
      field
      code
      message
    }
    __typename
  }
}
    `;
export type UpdateSubListItemMutationFn = Apollo.MutationFunction<UpdateSubListItemMutation, UpdateSubListItemMutationVariables>;

/**
 * __useUpdateSubListItemMutation__
 *
 * To run a mutation, you first call `useUpdateSubListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubListItemMutation, { data, loading, error }] = useUpdateSubListItemMutation({
 *   variables: {
 *      subListItem: // value for 'subListItem'
 *   },
 * });
 */
export function useUpdateSubListItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubListItemMutation, UpdateSubListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubListItemMutation, UpdateSubListItemMutationVariables>(UpdateSubListItemDocument, options);
      }
export type UpdateSubListItemMutationHookResult = ReturnType<typeof useUpdateSubListItemMutation>;
export type UpdateSubListItemMutationResult = Apollo.MutationResult<UpdateSubListItemMutation>;
export type UpdateSubListItemMutationOptions = Apollo.BaseMutationOptions<UpdateSubListItemMutation, UpdateSubListItemMutationVariables>;