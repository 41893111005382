import { PayerOrderField } from "@/schema/types";
import { getAutocompleteEnumFilter, payerTpoOptionsMap, payerTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, ActivationSwitch, CustomTableColumnProps } from "@toolkit/ui";
import { IPayerListNode } from "../../types";
import { usePayerActivationMutation } from "../../gql";
import { useMemo } from "react";

export const usePayerListContainerColumns = (): CustomTableColumnProps<IPayerListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "logo",
        header: t("Logo"),
        accessor: ({ logo }) => <Avatar src={logo!} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        sort: {
          columnEnum: PayerOrderField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "contactName",
        header: t("Contact Name"),
        accessor: "contactName",
      },
      {
        key: "contactEmail",
        header: t("Contact Email"),
        accessor: "contactEmail",
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: ({ contactPhoneNumber, contactMobileNumber }) => contactPhoneNumber || contactMobileNumber,
        type: "mobile",
      },
      {
        key: "licenseNumber",
        header: t("License Number"),
        accessor: "licenseNumber",
        filter: {
          type: "string",
          name: "licenseNumber",
        },
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => payerTypeOptionsMap[type!]?.label,
      },
      {
        key: "tpo",
        header: t("TPO"),
        accessor: ({ tpo }) => payerTpoOptionsMap[tpo!]?.label,
        sort: {
          columnEnum: PayerOrderField.Tpo,
        },
      },
      {
        key: "created",
        header: t("Created Date"),
        type: "date",
        accessor: "created",
        sort: {
          columnEnum: PayerOrderField.Created,
        },
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <ActivationSwitch id={id} isActive={isActive} mutation={usePayerActivationMutation} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    [t]
  );
};
