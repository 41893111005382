import { InsuranceNetwork, InsuranceSortField } from "@/schema/types";
import { i18n } from "@toolkit/i18n";
import { CustomTableColumnProps, makeStyles } from "@toolkit/ui";
import { FC } from "react";
import ActiveToggleSwitch from "./ActiveToggle";
import { FacilitiesInfo } from "./FacilitiesInfo";

const useStyle = makeStyles()(theme => ({
  textStyle: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
}));

const TextHeader: FC<{ label: string }> = ({ label }) => {
  const { classes } = useStyle();
  return <div className={classes.textStyle}>{label}</div>;
};

export const NetworkInsuranceColumns = (): CustomTableColumnProps<InsuranceNetwork>[] => [
  {
    key: "name",
    header: <TextHeader label={i18n.t("name", { ns: "admin", defaultValue: "Name" })} />,
    accessor: "name",
    isHidden: false,
    sort: {
      columnEnum: InsuranceSortField.Name,
    },
  },
  {
    key: "facilities",
    header: <TextHeader label={i18n.t("facilities", { ns: "admin", defaultValue: "Facilities" })} />,
    accessor: row => {
      return <FacilitiesInfo row={row} />;
    },
  },
  {
    key: "isActive",
    header: <TextHeader label={i18n.t("isActive", { ns: "admin", defaultValue: "isActive" })} />,
    type: "string",
    accessor: row => {
      return <ActiveToggleSwitch row={row} />;
    },
  },
  {
    key: "created",
    header: <TextHeader label={i18n.t("SuperAdmin:creationDate", { ns: "admin", defaultValue: "Creation Date" })} />,
    type: "date",
    accessor: "created",
    isHidden: false,
    sort: {
      columnEnum: InsuranceSortField.CreationDate,
    },
  },
];
