import { makeStyles } from "@toolkit/ui";

export const useStyle = makeStyles()(theme => ({
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 10,
    border: `1px solid ${theme.palette.gray.light}`,
    borderRadius: "8px",
    textAlign: "center",
  },
}));
