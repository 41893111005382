/* eslint-disable max-lines */
import { DecisionPlanCategory, PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { BusinessRulesIcon } from "@toolkit/ui";
import { subListsRoutes } from "pages/Sublists/constants";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { AdminDecisionCreateContainer, AdminDecisionListContainer, AdminDecisionUpdateContainer } from "../containers";
import {
  healthConditionRoutes,
  optimaRoutes,
  patientEligibilityRoutes,
  riskStratificationRoutes,
  userRequiredActionRoutes,
} from "./DecisionsPaths";

export const decisionsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "decision-routes",
    text: t("System Rules", { ns: "admin" }),
    icon: <BusinessRulesIcon />,
    isProhibited: !hasPermission(PermissionEnum.ManageRuleEngineRules),
    subItems: [
      {
        id: "patient-eligibility-routes",
        text: t("Patient Eligibility", { ns: "admin" }),
        route: patientEligibilityRoutes.route,
        subItems: [
          {
            id: "patient-eligibility-list-route",
            text: "",
            route: patientEligibilityRoutes.paths.main.route,
            fullPath: patientEligibilityRoutes.paths.main.fullPath,
            element: <AdminDecisionListContainer category={DecisionPlanCategory.PatientEligibility} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "patient-eligibility-new-route",
            text: "",
            route: patientEligibilityRoutes.paths.new.route,
            fullPath: patientEligibilityRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.PatientEligibility} />,
          },
          {
            id: "patient-eligibility-edit-route",
            text: "",
            route: patientEligibilityRoutes.paths.edit.route,
            fullPath: patientEligibilityRoutes.paths.edit.fullPath,
            hidden: true,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.PatientEligibility} />,
          },
        ],
      },
      {
        id: "risk-stratification-routes",
        text: t("Risk Stratification", { ns: "admin" }),
        route: riskStratificationRoutes.route,
        subItems: [
          {
            id: "risk-stratification-main-route",
            text: "",
            route: riskStratificationRoutes.paths.main.route,
            fullPath: riskStratificationRoutes.paths.main.fullPath,
            element: <AdminDecisionListContainer category={DecisionPlanCategory.RiskStratification} />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "risk-stratification-new-route",
            text: "",
            route: riskStratificationRoutes.paths.new.route,
            fullPath: riskStratificationRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.RiskStratification} />,
          },
          {
            id: "risk-stratification-edit-route",
            text: "",
            route: riskStratificationRoutes.paths.edit.route,
            fullPath: riskStratificationRoutes.paths.edit.fullPath,
            hidden: true,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.RiskStratification} />,
          },
        ],
      },
      {
        id: "health-condition-routes",
        text: t("Health Condition", { ns: "admin" }),
        route: healthConditionRoutes.route,
        subItems: [
          {
            id: "health-condition-list-route",
            text: "",
            route: healthConditionRoutes.paths.main.route,
            fullPath: healthConditionRoutes.paths.main.fullPath,
            onClick: (route: string) => navigate(route),
            element: <AdminDecisionListContainer category={DecisionPlanCategory.HealthCondition} />,
          },
          {
            id: "health-condition-new-route",
            text: "",
            route: healthConditionRoutes.paths.new.route,
            fullPath: healthConditionRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.HealthCondition} />,
          },
          {
            id: "health-condition-edit-route",
            text: "",
            route: healthConditionRoutes.paths.edit.route,
            fullPath: healthConditionRoutes.paths.edit.fullPath,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.HealthCondition} />,
            hidden: true,
          },
        ],
      },
      {
        id: "optima-routes",
        text: t("Optima", { ns: "admin" }),
        route: optimaRoutes.route,
        subItems: [
          {
            id: "optima-list-route",
            text: "",
            route: optimaRoutes.paths.main.route,
            fullPath: optimaRoutes.paths.main.fullPath,
            onClick: (route: string) => navigate(route),
            element: <AdminDecisionListContainer category={DecisionPlanCategory.Optima} />,
          },
          {
            id: "optima-new-route",
            text: "",
            route: optimaRoutes.paths.new.route,
            fullPath: optimaRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.Optima} />,
          },
          {
            id: "optima-edit-route",
            text: "",
            route: optimaRoutes.paths.edit.route,
            fullPath: optimaRoutes.paths.edit.fullPath,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.Optima} />,
            hidden: true,
          },
        ],
      },
      {
        id: "user-required-action-routes",
        text: t("User Required Action", { ns: "admin" }),
        route: userRequiredActionRoutes.route,
        subItems: [
          {
            id: "user-required-action-route",
            text: "",
            route: userRequiredActionRoutes.paths.main.route,
            fullPath: userRequiredActionRoutes.paths.main.fullPath,
            onClick: (route: string) => navigate(route),
            element: <AdminDecisionListContainer category={DecisionPlanCategory.UserRequiredAction} />,
          },
          {
            id: "user-required-action-new-route",
            text: "",
            route: userRequiredActionRoutes.paths.new.route,
            fullPath: userRequiredActionRoutes.paths.new.fullPath,
            hidden: true,
            element: <AdminDecisionCreateContainer category={DecisionPlanCategory.UserRequiredAction} />,
          },
          {
            id: "user-required-action-edit-route",
            text: "",
            route: userRequiredActionRoutes.paths.edit.route,
            fullPath: userRequiredActionRoutes.paths.edit.fullPath,
            element: <AdminDecisionUpdateContainer category={DecisionPlanCategory.UserRequiredAction} />,
            hidden: true,
          },
        ],
      },
      subListsRoutes({ navigate, t }),
    ],
  };
};
