import { CodeSystemSummery } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { adminManageListsTitleBreadcrumb, adminManagedListsPaths } from "../constants";
import { useGetCodeSystemDefinitionsSummeryQuery } from "../gql";

export const useAdminManagedListsIndexPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("domains");
  const { setBreadCrumb } = useBreadCrumbs();

  const { data, networkStatus: fetchAdminManagedListNetworkStatus } = useGetCodeSystemDefinitionsSummeryQuery({
    notifyOnNetworkStatusChange: true,
  });

  const adminManagedLists = data?.getCodeSystemDefinitionsSummery as CodeSystemSummery[];

  const onDetailsClick = (code: string, id = "new") => {
    navigate(adminManagedListsPaths.detailsPath.fullPathWithParams(code, id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: adminManageListsTitleBreadcrumb(t),
    });
  }, []);

  return {
    adminManagedLists,
    isLoading: NetworkStatus.loading === fetchAdminManagedListNetworkStatus,
    onDetailsClick,
  };
};
