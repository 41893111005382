import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionsAutocompleteQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PermissionsAutocompleteQuery = { __typename?: 'Query', permissions: Array<{ __typename?: 'Permission', id: string, code?: Types.PermissionEnum | null, name: string } | null> };


export const PermissionsAutocompleteDocument = gql`
    query PermissionsAutocomplete {
  permissions {
    id
    code
    name
  }
}
    `;

/**
 * __usePermissionsAutocompleteQuery__
 *
 * To run a query within a React component, call `usePermissionsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>(PermissionsAutocompleteDocument, options);
      }
export function usePermissionsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>(PermissionsAutocompleteDocument, options);
        }
export function usePermissionsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>(PermissionsAutocompleteDocument, options);
        }
export type PermissionsAutocompleteQueryHookResult = ReturnType<typeof usePermissionsAutocompleteQuery>;
export type PermissionsAutocompleteLazyQueryHookResult = ReturnType<typeof usePermissionsAutocompleteLazyQuery>;
export type PermissionsAutocompleteSuspenseQueryHookResult = ReturnType<typeof usePermissionsAutocompleteSuspenseQuery>;
export type PermissionsAutocompleteQueryResult = Apollo.QueryResult<PermissionsAutocompleteQuery, PermissionsAutocompleteQueryVariables>;