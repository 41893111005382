/* eslint-disable react/forbid-component-props */
/* eslint-disable react/jsx-handler-names */
import { useOpenState } from "@toolkit/core";
import React, { useEffect, useRef, useState } from "react";
import { CountryIso2, FlagImage as Flag, defaultCountries, parseCountry, usePhoneInput } from "react-international-phone";
import { Box, InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography } from "../../../base/mui";
import { usePhoneNumberStyles } from "./PhoneNumber.styles";
import { pickLocalizedValue } from "@toolkit/i18n";

export type PhoneInputProps = Partial<TextFieldProps> & {
  name: string;
  onChangeCountryCode?: (value: string) => void;
  onChange: (value: string) => void;
};

export const PhoneInput: React.FC<PhoneInputProps> = props => {
  const { value, variant = "filled", onChange, onChangeCountryCode, ...rest } = props;

  const BoxRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState(0);
  const { open, handleClose, handleOpen } = useOpenState();

  const { handlePhoneValueChange, inputValue, setCountry, country } = usePhoneInput({
    defaultCountry: "ae",
    preferredCountries: ["ae", "sa", "ps"],
    countries: defaultCountries,
    value: value ? value.toString() : "",
    defaultMask: ".........",
    onChange: data => {
      if (data?.country) {
        const nationalNumber = data?.phone?.replace(`+${data?.country?.dialCode}`, "").trim();
        onChangeCountryCode?.(data?.country?.dialCode);
        onChange(nationalNumber ? data?.phone : "");
      }
    },
  });

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      setWidth(entries[0].contentRect.width);
    });
    observer.observe(BoxRef.current!);
    return () => BoxRef.current && observer.unobserve(BoxRef.current);
  }, []);

  const { classes, cx } = usePhoneNumberStyles({ inputWidth: width });

  return (
    <Box ref={BoxRef} sx={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
      <TextField
        sx={{
          width: "100%",
          "& .MuiFilledInput-root": {
            flexDirection: pickLocalizedValue("row", "row-reverse"),
          },
          "& .MuiFilledInput-input.MuiInputBase-inputAdornedStart": {
            direction: "initial",
            textAlign: "initial",
          },
        }}
        {...rest}
        className={cx(open && classes.focusedTextField)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePhoneValueChange(e)}
        value={inputValue}
        variant={variant}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ width: "fit-content", height: "fit-content", direction: "ltr" }} position='start'>
              <Select
                sx={{
                  fieldset: { display: "none" },
                  svg: {
                    right: 0,
                    top: 14,
                  },
                  "& .MuiSelect-select": { position: "relative", bottom: 4 },
                  direction: "ltr",
                }}
                MenuProps={{
                  classes: { paper: classes.paper, list: classes.list },
                  anchorEl: BoxRef.current,
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  onClose: handleClose,
                }}
                value={country}
                onChange={e => {
                  setCountry(e.target.value as CountryIso2);
                  handleClose();
                }}
                renderValue={value => <Flag iso2={value.iso2} style={{ display: "flex", height: 30, margin: 0 }} />}
                open={open}
                onOpen={handleOpen}
              >
                {defaultCountries.map(c => {
                  const currentCountry = parseCountry(c);
                  return (
                    <MenuItem key={currentCountry.iso2} sx={{ direction: "ltr" }} value={currentCountry.iso2}>
                      <Flag iso2={currentCountry.iso2} className={classes.marginStart} style={{ width: 30, marginTop: 0 }} />
                      <Typography className={classes.marginStart}>{currentCountry.name}</Typography>
                      <Typography className={classes.marginStart}>+{currentCountry.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
