import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useProviderCreateMutation } from "../../gql";
import { convertProviderCreateFormValuesToBackEndValues } from "../../others";
import { useSetProvidersBreadcrumbs } from "../../hooks";
import { ProviderUpsertForm } from "../../forms/ProviderUpsert/ProviderUpsertForm";
import { IProviderUpsertForm } from "../../forms/ProviderUpsert/ProviderUpsertFormSchema";
import { providersPaths } from "@/pages/Providers/constants";

export const ProviderCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createProvider, { loading: isSubmitting }] = useProviderCreateMutation({
    onCompleted: mutationData => {
      const vendor = mutationData?.vendorCreate?.vendor;
      const errors = mutationData?.vendorCreate?.vendorErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Provider created successfully"));

        if (vendor?.hasMultipleBranches) {
          navigate(providersPaths.branchCreate.fullPathWithParams({ providerId: vendor?.id }));
        } else {
          navigate(-1);
        }
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onProviderUpsertFormChange = (values: IProviderUpsertForm) => {
    createProvider({
      variables: {
        input: convertProviderCreateFormValuesToBackEndValues(values),
      },
    });
  };

  useSetProvidersBreadcrumbs("CREATE");

  return <ProviderUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onProviderUpsertFormChange} />;
};
