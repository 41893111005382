import React, { ChangeEvent } from "react";
import { useTranslation } from "@toolkit/i18n";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "../../../../base/mui";
import { SearchIcon } from "../../../../icons";
import { TruncateTypography } from "../../../TruncateTypography";
import { ITransferListOption } from "../TransferList/TransferList";
import { useTransferListCardListStyle } from "./useTransferListCardListStyle";

export type ITransferListCardListEvent<T> =
  | {
      type: "SEARCH";
      payload: {
        value: string;
      };
    }
  | {
      type: "CHECKBOX_CLICK";
      payload: {
        item: ITransferListOption<T>;
      };
    };

type TransferListCardListProps<T> = {
  title: string;
  options: ITransferListOption<T>[];
  checkedOptions: ITransferListOption<T>[];
  searchValue: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (event: ITransferListCardListEvent<T>) => void;
};

export const TransferListCardList = <T,>(props: TransferListCardListProps<T>) => {
  const { title, options, checkedOptions, searchValue, isDisabled, isLoading, onChange } = props;

  const { t } = useTranslation();

  const { classes } = useTransferListCardListStyle();

  const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange({
      type: "SEARCH",
      payload: { value: event.target.value },
    });
  };

  const onCheckboxClick = (item: ITransferListOption<T>) => {
    onChange({
      type: "CHECKBOX_CLICK",
      payload: { item },
    });
  };

  return (
    <Card>
      <CardHeader title={<Typography className={classes.title}> {title} </Typography>} />

      <Divider className={classes.divider} />

      <CardContent>
        <Grid container spacing={2} direction={"column"}>
          <List className={classes.list} sx={{ display: isLoading ? "flex" : "block" }}>
            {isLoading ? (
              <Box className={classes.loadingWrapper}>
                <CircularProgress size={30} />
              </Box>
            ) : (
              <>
                <ListItem>
                  <TextField
                    className={classes.searchField}
                    hiddenLabel
                    fullWidth
                    value={searchValue}
                    onChange={onTextFieldChange}
                    placeholder={t("Search")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position={"end"}>
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItem>

                {options?.map(item => (
                  <ListItemButton key={item?.key} disabled={isDisabled} className={classes.listItem} onClick={() => onCheckboxClick(item)}>
                    <Checkbox checked={checkedOptions?.includes(item)} tabIndex={-1} disableRipple />
                    <ListItemText primary={<TruncateTypography text={item?.label} />} />
                  </ListItemButton>
                ))}
              </>
            )}
          </List>
        </Grid>
      </CardContent>
    </Card>
  );
};
