import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";
import { User } from "@/schema/types";
import { useUserUpdateMutation } from "../../gql";
import { convertUserProviderUpdateFormValuesToBackEndValues } from "../../others";
import { UserProviderUpsertForm, IUserProviderUpsertFormEvent } from "../../forms/UserProviderUpsert/UserProviderUpsertForm";
import { FC } from "react";

type UserProviderUpdateContainerProps = {
  user: User;
  isAdmin?: boolean;
  isLoading?: boolean;
  onChange: () => void;
};

export const UserProviderUpdateContainer: FC<UserProviderUpdateContainerProps> = props => {
  const { user, isAdmin, isLoading, onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [updateUser, { loading: isSubmitting }] = useUserUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.userUpdate?.accountErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("User created successfully"));
        onChange();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onUserVendorUpsertFormChange = (event: IUserProviderUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertUserProviderUpdateFormValuesToBackEndValues(event.payload.values);

      updateUser({
        variables: {
          id: user?.id!,
          input: values,
        },
      });
    }
  };

  return (
    <UserProviderUpsertForm
      buttonLabel={t("Update")}
      user={user}
      isAdmin={isAdmin}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      onChange={onUserVendorUpsertFormChange}
    />
  );
};
