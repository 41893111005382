import { AuditHistoryDrawer, AuditHistoryHeader, BaseOrder, Box, OrdersEventsContainer } from "@toolkit/ui";
import { FC } from "react";
import { useLifeCycleHooks } from "./LifeCycle.hook";
import { OrdersEvents } from "./OrdersEvents.components";
import { LifeCycleModelProps } from "./types";

export const LifeCycleModel: FC<LifeCycleModelProps> = ({ prescriptionID, number, referenceNumber, dispenseStatus }) => {
  const { open, loading, ordersData, pageInfo, value, handleChange, handleSearch, fetchMoreData, handleClickOpen, handleClickClose } =
    useLifeCycleHooks(prescriptionID, number);

  return (
    <AuditHistoryDrawer open={open} onClickOpen={handleClickOpen} onClickClose={handleClickClose}>
      <Box padding='14px' height='100vh' overflow='auto' id='scrollableDiv'>
        <AuditHistoryHeader
          referenceNumber={referenceNumber}
          value={value}
          onChange={handleChange}
          onSearch={handleSearch}
          ordersData={ordersData as BaseOrder[]}
        />

        <OrdersEventsContainer
          loading={loading}
          pageInfo={{
            endCursor: pageInfo?.endCursor,
            hasNextPage: pageInfo?.hasNextPage!,
            hasPreviousPage: pageInfo?.hasPreviousPage!,
            startCursor: pageInfo?.startCursor,
          }}
          ordersData={ordersData!}
          fetchMoreData={fetchMoreData}
          // dispenseStatus={dispenseStatus} // ToDo : fix this as the component is not accepting this props in typing
          renderItem={({ item }) => (
            <OrdersEvents
              dispenseStatus={dispenseStatus}
              events={item?.events}
              status={item?.status!}
              failReason={item?.failReason!}
              helpDiskTicketId={item?.helpDiskTicketId!}
            />
          )}
        />
      </Box>
    </AuditHistoryDrawer>
  );
};
