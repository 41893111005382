import { dashboardPaths } from "@/pages/Dashboard/constants/dashboard-paths";
import { findFirstAccessibleRoute } from "@toolkit/core";
import { BreadcrumbContextProvider, FallbackComponent } from "@toolkit/ui";
import React, { Suspense, useMemo } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LayoutComponent } from "./LayoutComponent";
import { NoMatch } from "./NoMatch";
import { useAppRoutes } from "./useAppRoutes";

export function LoggedInRoutes(): React.ReactElement {
  const navigate = useNavigate();
  const routes = useAppRoutes();

  const getRoutes = _routers => {
    return _routers
      .map(
        route =>
          !route?.isProhibited && (
            <Route key={route.id || route.key} path={route.route} element={route?.element}>
              {!!route?.subItems?.length && getRoutes(route.subItems)}
            </Route>
          )
      )
      .filter(Boolean);
  };

  const indexRoute = useMemo(() => findFirstAccessibleRoute(routes, dashboardPaths.mainPath.fullPath), [routes]);

  return (
    <Suspense fallback={<FallbackComponent />}>
      <Routes>
        <Route
          element={
            <BreadcrumbContextProvider navigate={navigate}>
              <LayoutComponent routes={routes} />
            </BreadcrumbContextProvider>
          }
        >
          {getRoutes(routes)}
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
              routes for.
          */}
          <Route index element={<Navigate to={indexRoute} replace={true} />} />
          <Route path='*' element={<NoMatch />} />
        </Route>
      </Routes>
    </Suspense>
  );
}
