import { HealthProgramMembershipRequest, HealthProgramMembershipRequestApproveInput, RequestStatus } from "@/schema/types";
import { IHealthProgramMembershipRequestApproveForm } from "../forms/HealthProgramMembershipRequestApprove/HealthProgramMembershipRequestApproveFormSchema";

export const convertHealthProgramMembershipRequestToFormValues = (
  healthProgramMembershipRequest: HealthProgramMembershipRequest
): Partial<IHealthProgramMembershipRequestApproveForm> => {
  return {
    insuranceClass: healthProgramMembershipRequest?.healthProgramMember?.insuranceClass,
    insurancePolicyNumber: healthProgramMembershipRequest?.healthProgramMember?.insurancePolicyNumber,
    insuranceCategory: healthProgramMembershipRequest?.healthProgramMember?.insuranceCategory,
    insuranceCategoryCode: healthProgramMembershipRequest?.healthProgramMember?.categoryCode,
  };
};

export const convertHealthProgramMembershipRequestFormValuesToBackEndValues = (
  values: IHealthProgramMembershipRequestApproveForm
): HealthProgramMembershipRequestApproveInput => {
  return {
    coverageBy: values?.coverageBy?.value,
    membershipStart: values?.membershipStartDate,
    membershipEnd: values?.membershipEndDate,
    insuranceCategory: values?.insuranceCategory,
    insuranceCategoryCode: values?.insuranceCategoryCode,
    insuranceClass: values?.insuranceClass,
    insurancePolicyNumber: values?.insurancePolicyNumber,
  };
};

export const isHealthProgramMembershipRequestAlreadyApprovedOrRejected = (status: RequestStatus) => {
  return status === RequestStatus.Approved || status === RequestStatus.Rejected;
};
