import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { AdminPromotionCreateMutation, useAdminPromotionCreateMutation } from "pages/MarketplacePromotions/gql";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { promotionsBreadcrumb, promotionsPaths } from "../../constants";
import { IPromotionUpsertFormValues, PromotionUpsertForm } from "../../forms";
import { convertPromotionFormValuesToBackEndValues } from "../../utils";

export const PromotionCreateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchPromotionCreateMutation, { loading: isSubmitting }] = useAdminPromotionCreateMutation({
    onCompleted: (data: AdminPromotionCreateMutation) => {
      if (data?.adminPromotionCreate?.id) {
        succeeded(t("Promotion created successfully!"));
        navigate(promotionsPaths.main.fullPath);
      } else {
        failed(t("Promotion creation failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onPromotionUpsertFormChange = (values: IPromotionUpsertFormValues) => {
    const _values = convertPromotionFormValuesToBackEndValues(values);

    fetchPromotionCreateMutation({
      variables: {
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, create } = promotionsBreadcrumb();
    setBreadCrumb({ title, values: [main, create] });
  }, [setBreadCrumb, t]);
  return <PromotionUpsertForm isSubmitting={isSubmitting} submitButtonLabel={t("Create")} onChange={onPromotionUpsertFormChange} />;
};
