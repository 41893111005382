import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaPriorAuthorizationActivitiesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TransactionPriorAuthorizationActivityFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type OptimaPriorAuthorizationActivitiesQuery = { __typename?: 'Query', optimaPriorAuthorizationActivities?: { __typename?: 'TransactionPriorAuthorizationActivityConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'TransactionPriorAuthorizationActivityEdge', node?: { __typename?: 'TransactionPriorAuthorizationActivity', activityId?: string | null, clinician?: string | null, code: string, denialCode?: string | null, duration?: number | null, net?: number | null, patientShare?: number | null, paymentAmount?: number | null, priorAuthorizationID?: string | null, start?: string | null, quantity?: number | null, type?: number | null, claimed?: boolean | null, denialLookup?: { __typename?: 'TransactionDenialLookup', name?: string | null, id: string } | null, typeLookup?: { __typename?: 'TransactionActivityTypeLookup', type?: number | null, name?: string | null } | null, codeLookup?: { __typename?: 'TransactionActivityCodeLookup', name?: string | null } | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const OptimaPriorAuthorizationActivitiesDocument = gql`
    query OptimaPriorAuthorizationActivities($filter: TransactionPriorAuthorizationActivityFilterInput, $after: String, $before: String, $first: Int, $last: Int) {
  optimaPriorAuthorizationActivities(
    filter: $filter
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        activityId
        clinician
        code
        denialCode
        duration
        net
        patientShare
        paymentAmount
        priorAuthorizationID
        start
        quantity
        type
        denialLookup {
          name
          id
        }
        typeLookup {
          type
          name
        }
        claimed
        codeLookup {
          name
        }
      }
    }
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOptimaPriorAuthorizationActivitiesQuery__
 *
 * To run a query within a React component, call `useOptimaPriorAuthorizationActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaPriorAuthorizationActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaPriorAuthorizationActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOptimaPriorAuthorizationActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>(OptimaPriorAuthorizationActivitiesDocument, options);
      }
export function useOptimaPriorAuthorizationActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>(OptimaPriorAuthorizationActivitiesDocument, options);
        }
export function useOptimaPriorAuthorizationActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>(OptimaPriorAuthorizationActivitiesDocument, options);
        }
export type OptimaPriorAuthorizationActivitiesQueryHookResult = ReturnType<typeof useOptimaPriorAuthorizationActivitiesQuery>;
export type OptimaPriorAuthorizationActivitiesLazyQueryHookResult = ReturnType<typeof useOptimaPriorAuthorizationActivitiesLazyQuery>;
export type OptimaPriorAuthorizationActivitiesSuspenseQueryHookResult = ReturnType<typeof useOptimaPriorAuthorizationActivitiesSuspenseQuery>;
export type OptimaPriorAuthorizationActivitiesQueryResult = Apollo.QueryResult<OptimaPriorAuthorizationActivitiesQuery, OptimaPriorAuthorizationActivitiesQueryVariables>;