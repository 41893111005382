/* eslint-disable react/jsx-handler-names */
import React from "react";
import { useCustomFormContext } from "@toolkit/core";
import { TransferList, TransferListProps } from "../TransferList/TransferList";
import { Controller } from "react-hook-form";

type FormTransferListProps<T> = Omit<TransferListProps<T>, "value" | "onChange"> & {
  name: string;
};

export const FormTransferList = <T,>(props: FormTransferListProps<T>) => {
  const { name, isDisabled, ...rest } = props;

  const { control, isFormDisabled } = useCustomFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TransferList
          {...rest}
          value={field?.value}
          isDisabled={isFormDisabled || isDisabled}
          error={fieldState?.error?.message}
          onChange={field?.onChange}
        />
      )}
    />
  );
};
