import { i18n } from "@toolkit/i18n";
import { TaskDefinitionStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const taskDefinitionStatusOptionsMap: IEnumToOptionsMap<TaskDefinitionStatus> = {
  [TaskDefinitionStatus.Cancelled]: {
    key: TaskDefinitionStatus.Cancelled,
    get label() {
      return i18n.t("Cancelled", { ns: "domains" });
    },
    value: TaskDefinitionStatus.Cancelled,
  },
  [TaskDefinitionStatus.Done]: {
    key: TaskDefinitionStatus.Done,
    get label() {
      return i18n.t("Done", { ns: "domains" });
    },
    value: TaskDefinitionStatus.Done,
  },
  [TaskDefinitionStatus.Idle]: {
    key: TaskDefinitionStatus.Idle,
    get label() {
      return i18n.t("Idle", { ns: "domains" });
    },
    value: TaskDefinitionStatus.Idle,
  },
  [TaskDefinitionStatus.InProgress]: {
    key: TaskDefinitionStatus.InProgress,
    get label() {
      return i18n.t("In Progress", { ns: "domains" });
    },
    value: TaskDefinitionStatus.InProgress,
  },
  [TaskDefinitionStatus.Missed]: {
    key: TaskDefinitionStatus.Missed,
    get label() {
      return i18n.t("Missed", { ns: "domains" });
    },
    value: TaskDefinitionStatus.Missed,
  },
  [TaskDefinitionStatus.Pending]: {
    key: TaskDefinitionStatus.Pending,
    get label() {
      return i18n.t("Pending", { ns: "domains" });
    },
    value: TaskDefinitionStatus.Pending,
  },
};

export const taskDefinitionStatusOptions = Object.values(taskDefinitionStatusOptionsMap)?.filter(
  item => item?.key !== TaskDefinitionStatus.Idle
);
