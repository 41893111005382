import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchCreateMutationVariables = Types.Exact<{
  providerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  input: Types.BranchInput;
}>;


export type BranchCreateMutation = { __typename?: 'Mutation', branchCreate?: { __typename?: 'BranchCreate', branchErrors: Array<{ __typename?: 'BranchError', field?: string | null, message?: string | null }> } | null };


export const BranchCreateDocument = gql`
    mutation BranchCreate($providerId: ID, $input: BranchInput!) {
  branchCreate(input: $input, vendorId: $providerId) {
    branchErrors {
      field
      message
    }
  }
}
    `;
export type BranchCreateMutationFn = Apollo.MutationFunction<BranchCreateMutation, BranchCreateMutationVariables>;

/**
 * __useBranchCreateMutation__
 *
 * To run a mutation, you first call `useBranchCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBranchCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [branchCreateMutation, { data, loading, error }] = useBranchCreateMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBranchCreateMutation(baseOptions?: Apollo.MutationHookOptions<BranchCreateMutation, BranchCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BranchCreateMutation, BranchCreateMutationVariables>(BranchCreateDocument, options);
      }
export type BranchCreateMutationHookResult = ReturnType<typeof useBranchCreateMutation>;
export type BranchCreateMutationResult = Apollo.MutationResult<BranchCreateMutation>;
export type BranchCreateMutationOptions = Apollo.BaseMutationOptions<BranchCreateMutation, BranchCreateMutationVariables>;