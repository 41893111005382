import { i18n } from "@toolkit/i18n";
import { Priority } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const priorityOptionsMap: IEnumToOptionsMap<Priority> = {
  [Priority.Low]: {
    key: Priority.Low,
    get label() {
      return i18n.t("Low", { ns: "domains" });
    },
    value: Priority.Low,
  },
  [Priority.Medium]: {
    key: Priority.Medium,
    get label() {
      return i18n.t("Medium", { ns: "domains" });
    },
    value: Priority.Medium,
  },
  [Priority.High]: {
    key: Priority.High,
    get label() {
      return i18n.t("High", { ns: "domains" });
    },
    value: Priority.High,
  },
};

export const priorityOptions = Object.values(priorityOptionsMap);
