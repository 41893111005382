import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { DecisionPlansAutocompleteQuery } from "./gql";

export type IDecisionPlanAutocomplete = ExtractNodeType<DecisionPlansAutocompleteQuery>;

export const decisionPlanSchema = createZodAutocompleteFromType<IDecisionPlanAutocomplete>({
  id: z.string().nullish(),
  code: z.string().nullish(),
  display: z.string().nullish(),
});
