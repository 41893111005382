/* eslint-disable react/jsx-handler-names */
import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { AutocompleteController, SmartAutocompleteController } from "../AutocompleteController";
import { CustomTableColumnProps, SmartAutocompleteFilter, StaticOptionsAutocompleteFilter } from "../GridProvider";
import { isOptionEqualToValue } from "./utils";

export const AutoCompleteFilter: FC<{
  field: CustomTableColumnProps<any>;
  filter: SmartAutocompleteFilter | StaticOptionsAutocompleteFilter;
}> = ({ field, filter }) => {
  const { control } = useFormContext();

  return "query" in filter ? (
    <SmartAutocompleteController
      multiple={filter?.multiple}
      onChange={filter?.getValueForBackend}
      getOptionLabel={filter?.getOptionLabel}
      queryVariables={filter?.queryVariables}
      filters={filter?.filtersForQuery!}
      useAutocompleteControllerQuery={filter?.query!}
      dataAccessor={filter?.accessor!}
      filterSearchKey={filter?.filterSearchKey}
      isFilterSearchKeyAQueryVariable={filter?.isFilterSearchKeyAQueryVariable}
      isOptionEqualToValue={isOptionEqualToValue}
      ControllerProps={{
        control,
        name: filter?.name,
        rules: {
          required: false,
        },
      }}
      TextFieldProps={{
        label: field?.header,
      }}
    />
  ) : (
    <AutocompleteController
      options={filter?.options || []}
      onChange={(_, v) => filter?.getValueForBackend?.(v)}
      getOptionLabel={filter?.getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      multiple={filter?.multiple}
      ControllerProps={{
        control,
        name: filter?.name,
        rules: {
          required: false,
        },
      }}
      TextFieldProps={{
        label: field?.header,
      }}
    />
  );
};
