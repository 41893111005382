import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { DoctorPatientsAutocompleteQuery } from "./gql";

export type IDoctorPatientAutocomplete = Omit<NonNullable<ExtractNodeType<DoctorPatientsAutocompleteQuery>["user"]>, "__typename">;

export const doctorPatientSchema = createZodAutocompleteFromType<IDoctorPatientAutocomplete>({
  id: z.string(),
  fullName: z.string().nullish(),
});
