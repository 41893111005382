import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetClinicianCredentialsQueryVariables = Types.Exact<{
  destinationType: Types.DestinationType;
  user: Types.Scalars['ID']['input'];
}>;


export type GetClinicianCredentialsQuery = { __typename?: 'Query', getClinicianCredentials?: { __typename?: 'ClinicianCredentials', license?: string | null, userName?: string | null } | null };


export const GetClinicianCredentialsDocument = gql`
    query GetClinicianCredentials($destinationType: DestinationType!, $user: ID!) {
  getClinicianCredentials(destinationType: $destinationType, user: $user) {
    license
    userName
  }
}
    `;

/**
 * __useGetClinicianCredentialsQuery__
 *
 * To run a query within a React component, call `useGetClinicianCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicianCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicianCredentialsQuery({
 *   variables: {
 *      destinationType: // value for 'destinationType'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetClinicianCredentialsQuery(baseOptions: Apollo.QueryHookOptions<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables> & ({ variables: GetClinicianCredentialsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables>(GetClinicianCredentialsDocument, options);
      }
export function useGetClinicianCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables>(GetClinicianCredentialsDocument, options);
        }
export function useGetClinicianCredentialsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables>(GetClinicianCredentialsDocument, options);
        }
export type GetClinicianCredentialsQueryHookResult = ReturnType<typeof useGetClinicianCredentialsQuery>;
export type GetClinicianCredentialsLazyQueryHookResult = ReturnType<typeof useGetClinicianCredentialsLazyQuery>;
export type GetClinicianCredentialsSuspenseQueryHookResult = ReturnType<typeof useGetClinicianCredentialsSuspenseQuery>;
export type GetClinicianCredentialsQueryResult = Apollo.QueryResult<GetClinicianCredentialsQuery, GetClinicianCredentialsQueryVariables>;