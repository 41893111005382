import { i18n } from "@toolkit/i18n";
import { NotificationFrequency } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const notificationFrequencyOptionsMap: IEnumToOptionsMap<NotificationFrequency> = {
  [NotificationFrequency.AllNotifications]: {
    key: NotificationFrequency.AllNotifications,
    get label() {
      return i18n.t("All Notifications", { ns: "domains" });
    },
    value: NotificationFrequency.AllNotifications,
  },
  [NotificationFrequency.EssentialOnly]: {
    key: NotificationFrequency.EssentialOnly,
    get label() {
      return i18n.t("Essential Only", { ns: "domains" });
    },
    value: NotificationFrequency.EssentialOnly,
  },
  [NotificationFrequency.RegularUpdates]: {
    key: NotificationFrequency.RegularUpdates,
    get label() {
      return i18n.t("Regular Updates", { ns: "domains" });
    },
    value: NotificationFrequency.RegularUpdates,
  },
};

export const notificationFrequencyOptions = Object.values(notificationFrequencyOptionsMap);
