import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaEditUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.OptimaEditInput;
}>;


export type OptimaEditUpdateMutation = { __typename?: 'Mutation', optimaEditUpdate?: { __typename?: 'OptimaEdit', code?: string | null } | null };


export const OptimaEditUpdateDocument = gql`
    mutation OptimaEditUpdate($id: ID!, $input: OptimaEditInput!) {
  optimaEditUpdate(id: $id, input: $input) {
    code
  }
}
    `;
export type OptimaEditUpdateMutationFn = Apollo.MutationFunction<OptimaEditUpdateMutation, OptimaEditUpdateMutationVariables>;

/**
 * __useOptimaEditUpdateMutation__
 *
 * To run a mutation, you first call `useOptimaEditUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaEditUpdateMutation, { data, loading, error }] = useOptimaEditUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptimaEditUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OptimaEditUpdateMutation, OptimaEditUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaEditUpdateMutation, OptimaEditUpdateMutationVariables>(OptimaEditUpdateDocument, options);
      }
export type OptimaEditUpdateMutationHookResult = ReturnType<typeof useOptimaEditUpdateMutation>;
export type OptimaEditUpdateMutationResult = Apollo.MutationResult<OptimaEditUpdateMutation>;
export type OptimaEditUpdateMutationOptions = Apollo.BaseMutationOptions<OptimaEditUpdateMutation, OptimaEditUpdateMutationVariables>;