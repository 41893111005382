import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";
import { zodSchema } from "@health/autocompletes";
import { i18n } from "@toolkit/i18n";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";

export const userAdminUpsertFormSchema = (isUpdateMode: boolean) =>
  z
    .object({
      firstName: z.string().min(3).max(30),
      lastName: z.string().min(3).max(30),
      nationalId: z.string().min(1),
      email: z.string().email(),
      password: isUpdateMode
        ? z.string().optional()
        : z
            .string({
              required_error: i18n.t("Invalid password"),
            })
            .min(8, { message: i18n.t("Password must be at least 8 characters") })
            .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-])[A-Za-z\d!@#$%&*-]{8,}$/, {
              message: i18n.t(
                "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character"
              ),
            }),
      mobile: z.string(),
      appRole: zodEnumSchema.appRoleType,
      permissionsGroups: z.array(zodSchema.permissionGroup),
      isActive: z.boolean(),
    })
    .superRefine((data, ctx) => {
      if (!data.mobile && !isValidPhoneNumber(String(data.mobile))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t("Invalid mobile number"),
          path: ["mobile"],
        });
      }
    });

export type IUserAdminUpsertFormValues = z.infer<ReturnType<typeof userAdminUpsertFormSchema>>;

export const userAdminUpsertFormDefaultValues: Partial<IUserAdminUpsertFormValues> = {
  firstName: undefined,
  lastName: undefined,
  nationalId: undefined,
  mobile: undefined,
  email: undefined,
  password: undefined,
  appRole: undefined,
  permissionsGroups: [],
  isActive: true,
};
