import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { DecisionConditionFieldListCode } from "../../types";
import { DecisionCitiesAutocomplete, DecisionDivisionsAutocomplete } from "../../components";

type DecisionConditionAutocompletesFormProps = {
  name: string;
  code: DecisionConditionFieldListCode;
};

export const DecisionConditionAutocompletesForm: FC<DecisionConditionAutocompletesFormProps> = props => {
  const { name, code } = props;

  const { t } = useTranslation("domains");

  if (code === DecisionConditionFieldListCode.City) {
    return <DecisionCitiesAutocomplete name={name} />;
  } else if (code === DecisionConditionFieldListCode.Division) {
    return <DecisionDivisionsAutocomplete name={name} />;
  } else {
    return <> {t("Not Supported List")} </>;
  }
};
