import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAdminGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UserAdminGetQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, mobile?: string | null, nationalId?: string | null, appRole?: Types.AppRoleTypes | null, isActive: boolean, permissionGroups?: Array<{ __typename?: 'Group', id: string, name: string } | null> | null } | null };


export const UserAdminGetDocument = gql`
    query UserAdminGet($id: ID!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    mobile
    nationalId
    appRole
    isActive
    permissionGroups {
      id
      name
    }
  }
}
    `;

/**
 * __useUserAdminGetQuery__
 *
 * To run a query within a React component, call `useUserAdminGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdminGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdminGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAdminGetQuery(baseOptions: Apollo.QueryHookOptions<UserAdminGetQuery, UserAdminGetQueryVariables> & ({ variables: UserAdminGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAdminGetQuery, UserAdminGetQueryVariables>(UserAdminGetDocument, options);
      }
export function useUserAdminGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAdminGetQuery, UserAdminGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAdminGetQuery, UserAdminGetQueryVariables>(UserAdminGetDocument, options);
        }
export function useUserAdminGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserAdminGetQuery, UserAdminGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserAdminGetQuery, UserAdminGetQueryVariables>(UserAdminGetDocument, options);
        }
export type UserAdminGetQueryHookResult = ReturnType<typeof useUserAdminGetQuery>;
export type UserAdminGetLazyQueryHookResult = ReturnType<typeof useUserAdminGetLazyQuery>;
export type UserAdminGetSuspenseQueryHookResult = ReturnType<typeof useUserAdminGetSuspenseQuery>;
export type UserAdminGetQueryResult = Apollo.QueryResult<UserAdminGetQuery, UserAdminGetQueryVariables>;