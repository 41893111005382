import { useTranslation } from "@toolkit/i18n";
import { MenuItem, Typography, UploadIcon, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { usePublishCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const PublishButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation("domains");
  const { failed, succeeded } = useAddToast();
  const { refetchCodeSystem, versionParamId } = useAdminManagedListDetailsPageContext();
  const [publishCodeSystem, { loading }] = usePublishCodeSystemMutation({
    onCompleted: data => {
      const message = data?.publishCodeSystem?.errors;
      if (message?.length) {
        failed(message[0]?.message as string);
      } else {
        succeeded(t("Data published successfully"));
      }
    },
    onError: error => {
      failed(t(error?.message));
    },
  });
  const handleClick = () => {
    onClick();
    publishCodeSystem({
      variables: {
        codeSystemId: versionParamId as string,
      },
    }).then(() => {
      refetchCodeSystem?.();
    });
  };

  return (
    <MenuItem onClick={handleClick} disabled={loading}>
      <UploadIcon color={"primary"} />
      <Typography marginLeft='5px'> {t("Publish")}</Typography>
    </MenuItem>
  );
};
