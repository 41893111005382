import { ConditionOperation, Field } from "@/schema/types";
import { conditionOperationOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomIcon, Grid, IconButton, Typography } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DecisionConditionsUpsertForm } from "../DecisionConditionUpsert/DecisionConditionsUpsertForm";
import { IDecisionUpsertFormValues } from "../DecisionUpsert/DecisionUpsertFormSchema";
import { convertDecisionPlanConditionToFormValues } from "../../others";
import { IDecisionConditionGroupConditionsPath, IDecisionConditionGroupPath } from "../../types";
import { DecisionConditionRemove } from "../../components/DecisionConditionRemove/DecisionConditionRemove";
import { DecisionConditionInnerOperation } from "../../components/DecisionConditionInnerOperation/DecisionConditionInnerOperation";
import { useDecisionConditionsFormStyle } from "./useDecisionConditionsFormStyle";

type DecisionConditionFormProps = {
  index: number;
  decisionPlanFields: Field[];
  onRemoveConditionGroup: (index: number) => void;
};

export const DecisionConditionsForm: FC<DecisionConditionFormProps> = props => {
  const { index, decisionPlanFields, onRemoveConditionGroup } = props;

  const { t } = useTranslation("domains");

  const form = useFormContext<IDecisionUpsertFormValues>();

  const { control, watch, setValue } = form;

  const conditionGroupPath = `conditionsGroups.${index}` as IDecisionConditionGroupPath;

  const innerConditionOperation = watch(`${conditionGroupPath}.innerConditionOperation`);

  const { fields, append, remove } = useFieldArray<IDecisionUpsertFormValues, IDecisionConditionGroupConditionsPath>({
    control,
    name: `${conditionGroupPath}.conditions`,
  });

  const isFieldsMoreThanOne = fields?.length > 1;

  const { classes, theme, cx } = useDecisionConditionsFormStyle({ isFieldsMoreThanOne });

  const onAddNewCondition = () => {
    const condition = convertDecisionPlanConditionToFormValues(decisionPlanFields);
    append(condition);
  };

  const onRemoveCondition = (conditionIndex: number) => {
    remove(conditionIndex);
  };

  useEffect(() => {
    if (!innerConditionOperation && fields?.length > 1) {
      setValue(
        `${conditionGroupPath}.innerConditionOperation`,
        fields[1]?.operator?.value
          ? conditionOperationOptionsMap[fields[1]?.operator?.value]
          : conditionOperationOptionsMap[ConditionOperation.And]
      );
    } else if (fields?.length == 1) {
      setValue(`${conditionGroupPath}.innerConditionOperation`, undefined);
    }
  }, [fields, innerConditionOperation, conditionGroupPath, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container>
          <Grid xs={8} lg={10}>
            <Typography className={classes.title}> {t("Condition {{value}}", { value: index + 1 })} </Typography>
          </Grid>

          <Grid xs={4} lg={2}>
            <Box className={classes.removeWrapper}>
              <IconButton onClick={() => onRemoveConditionGroup(index)}>
                <CustomIcon color={theme.palette.secondary.main} icon={"delete"} />
              </IconButton>
            </Box>
          </Grid>

          <Grid xs={12} className={classes.wrapper}>
            <Box className={classes.fieldsWrapper}>
              {isFieldsMoreThanOne && <DecisionConditionInnerOperation conditionGroupPath={conditionGroupPath} />}

              <Grid container>
                {fields?.map((item, idx) => {
                  return (
                    <Grid key={item?.id} container spacing={2} sx={{ position: "relative" }}>
                      <Grid
                        container
                        spacing={2}
                        className={cx(classes.conditionInfoGrid, { [classes.dashedConditionInfoGrid]: idx !== 0 })}
                      >
                        {isFieldsMoreThanOne && <DecisionConditionRemove onRemoveCondition={() => onRemoveCondition(idx)} />}

                        <DecisionConditionsUpsertForm
                          isFieldsMoreThanOne={isFieldsMoreThanOne}
                          decisionPlanFields={decisionPlanFields}
                          conditionPath={`${conditionGroupPath}.conditions.${idx}`}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Grid container spacing={2} marginTop={"10px"}>
              <Grid item xs={12}>
                <Button startIcon={<CustomIcon icon={"add"} color={"inherit"} />} onClick={onAddNewCondition}>
                  {t("Add New Condition")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
