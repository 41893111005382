import { useSetPayersBreadcrumbs } from "@/pages/Payers/hooks";
import { Payer } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { IPayerUpsertFormEvent, PayerUpsertForm } from "../../forms/PayerUpsert/PayerUpsertForm";
import { usePayerGetQuery, usePayerUpdateMutation } from "../../gql";
import { convertPayerFormValuesToBackEndValues } from "../../others";
import { IPayerUpdateContainerParams } from "../../types";

export const PayerUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { payerId } = useParams<IPayerUpdateContainerParams>();

  const { data, loading } = usePayerGetQuery({
    variables: { id: payerId! },
    skip: !payerId,
    fetchPolicy: "no-cache",
  });

  const payer = data?.payer as Payer;

  const [updatePayer, { loading: isSubmitting }] = usePayerUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.payerUpdate?.payerErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Payer updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onPayerUpsertFormChange = (event: IPayerUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertPayerFormValuesToBackEndValues(event.payload.values);

      updatePayer({
        variables: {
          id: payerId!,
          input: values,
        },
      });
    }
  };

  useSetPayersBreadcrumbs("UPDATE", pickLocalizedValue(payer?.name, payer?.nameAr!));

  return (
    <PayerUpsertForm
      buttonLabel={t("Update")}
      payer={payer}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onPayerUpsertFormChange}
    />
  );
};
