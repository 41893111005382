import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackerTradeDrugTypeCreateMutationVariables = Types.Exact<{
  input: Types.TrackerTradeDrugTypeInput;
}>;


export type TrackerTradeDrugTypeCreateMutation = { __typename?: 'Mutation', trackerTradeDrugTypeCreate?: { __typename?: 'TrackerTradeDrugType', id: string, name: string, photo?: string | null } | null };


export const TrackerTradeDrugTypeCreateDocument = gql`
    mutation TrackerTradeDrugTypeCreate($input: TrackerTradeDrugTypeInput!) {
  trackerTradeDrugTypeCreate(input: $input) {
    id
    name
    photo
  }
}
    `;
export type TrackerTradeDrugTypeCreateMutationFn = Apollo.MutationFunction<TrackerTradeDrugTypeCreateMutation, TrackerTradeDrugTypeCreateMutationVariables>;

/**
 * __useTrackerTradeDrugTypeCreateMutation__
 *
 * To run a mutation, you first call `useTrackerTradeDrugTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackerTradeDrugTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackerTradeDrugTypeCreateMutation, { data, loading, error }] = useTrackerTradeDrugTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackerTradeDrugTypeCreateMutation(baseOptions?: Apollo.MutationHookOptions<TrackerTradeDrugTypeCreateMutation, TrackerTradeDrugTypeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackerTradeDrugTypeCreateMutation, TrackerTradeDrugTypeCreateMutationVariables>(TrackerTradeDrugTypeCreateDocument, options);
      }
export type TrackerTradeDrugTypeCreateMutationHookResult = ReturnType<typeof useTrackerTradeDrugTypeCreateMutation>;
export type TrackerTradeDrugTypeCreateMutationResult = Apollo.MutationResult<TrackerTradeDrugTypeCreateMutation>;
export type TrackerTradeDrugTypeCreateMutationOptions = Apollo.BaseMutationOptions<TrackerTradeDrugTypeCreateMutation, TrackerTradeDrugTypeCreateMutationVariables>;