import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { OptimaActionsAutocompleteQuery } from "./gql";

export type IOptimaActionsAutocomplete = ExtractNodeType<OptimaActionsAutocompleteQuery>;

export const optimaActionSchema = createZodAutocompleteFromType<IOptimaActionsAutocomplete>({
  id: z.string(),
  action: z.string().nullish(),
});
