import React, { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";
export const PaperPinIcon: FC<SvgIconProps> = props => {
  const theme = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='13.38' height='11.823' viewBox='0 0 13.38 11.823' {...props}>
      <path
        id='Path_29009'
        data-name='Path 29009'
        d='M6.271,11.652a.541.541,0,0,0,.758,0l5.238-5.238A3.766,3.766,0,0,0,11.992.83a3.9,3.9,0,0,0-5.17.345l-6,6a2.69,2.69,0,0,0,.207,4.067,2.857,2.857,0,0,0,3.791-.276L9.855,5.931A1.708,1.708,0,1,0,7.374,3.587l-4.2,4.2a.536.536,0,0,0,.758.758L8.2,4.276a.682.682,0,0,1,.965.965L4,10.342A1.708,1.708,0,1,1,1.653,7.861l6-6A2.739,2.739,0,0,1,11.716,2a2.857,2.857,0,0,1-.276,3.791l-5.17,5.17A.459.459,0,0,0,6.271,11.652Z'
        transform='translate(0.004 -0.009)'
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
};
