import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockCreateMutationVariables = Types.Exact<{
  input: Types.BlockInput;
}>;


export type BlockCreateMutation = { __typename?: 'Mutation', blockCreate?: { __typename?: 'BlockCreate', block?: { __typename?: 'Block', id: string } | null, blockErrors: Array<{ __typename?: 'BlockError', message?: string | null, field?: string | null, code: Types.BlockErrorCode }> } | null };


export const BlockCreateDocument = gql`
    mutation BlockCreate($input: BlockInput!) {
  blockCreate(input: $input) {
    block {
      id
    }
    blockErrors {
      message
      field
      code
    }
  }
}
    `;
export type BlockCreateMutationFn = Apollo.MutationFunction<BlockCreateMutation, BlockCreateMutationVariables>;

/**
 * __useBlockCreateMutation__
 *
 * To run a mutation, you first call `useBlockCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockCreateMutation, { data, loading, error }] = useBlockCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockCreateMutation(baseOptions?: Apollo.MutationHookOptions<BlockCreateMutation, BlockCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockCreateMutation, BlockCreateMutationVariables>(BlockCreateDocument, options);
      }
export type BlockCreateMutationHookResult = ReturnType<typeof useBlockCreateMutation>;
export type BlockCreateMutationResult = Apollo.MutationResult<BlockCreateMutation>;
export type BlockCreateMutationOptions = Apollo.BaseMutationOptions<BlockCreateMutation, BlockCreateMutationVariables>;