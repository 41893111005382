import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChatFlowAddContributorsMutationVariables = Types.Exact<{
  chatFlowId: Types.Scalars['ID']['input'];
  contributors: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type ChatFlowAddContributorsMutation = { __typename?: 'Mutation', chatFlowAddContributors?: { __typename?: 'ChatFlow', id: string, updatedAt: any, contributors?: Array<{ __typename?: 'User', fullName?: string | null, id: string }> | null } | null };


export const ChatFlowAddContributorsDocument = gql`
    mutation ChatFlowAddContributors($chatFlowId: ID!, $contributors: [ID!]!) {
  chatFlowAddContributors(chatFlowId: $chatFlowId, contributors: $contributors) {
    id
    contributors {
      fullName
      id
    }
    updatedAt
  }
}
    `;
export type ChatFlowAddContributorsMutationFn = Apollo.MutationFunction<ChatFlowAddContributorsMutation, ChatFlowAddContributorsMutationVariables>;

/**
 * __useChatFlowAddContributorsMutation__
 *
 * To run a mutation, you first call `useChatFlowAddContributorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatFlowAddContributorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatFlowAddContributorsMutation, { data, loading, error }] = useChatFlowAddContributorsMutation({
 *   variables: {
 *      chatFlowId: // value for 'chatFlowId'
 *      contributors: // value for 'contributors'
 *   },
 * });
 */
export function useChatFlowAddContributorsMutation(baseOptions?: Apollo.MutationHookOptions<ChatFlowAddContributorsMutation, ChatFlowAddContributorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatFlowAddContributorsMutation, ChatFlowAddContributorsMutationVariables>(ChatFlowAddContributorsDocument, options);
      }
export type ChatFlowAddContributorsMutationHookResult = ReturnType<typeof useChatFlowAddContributorsMutation>;
export type ChatFlowAddContributorsMutationResult = Apollo.MutationResult<ChatFlowAddContributorsMutation>;
export type ChatFlowAddContributorsMutationOptions = Apollo.BaseMutationOptions<ChatFlowAddContributorsMutation, ChatFlowAddContributorsMutationVariables>;