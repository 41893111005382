/* eslint-disable max-lines */
import { PublishCriteriaInput } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { FetchSurveysDocument } from "pages/Surveys/gql";
import {
  CancelSurveyMutation,
  CloneSurveyMutation,
  PublishSurveyMutation,
  UnPublishSurveyMutation,
  useCancelSurveyMutation,
  useCloneSurveyMutation,
  usePublishSurveyMutation,
  useUnPublishSurveyMutation,
} from "pages/Surveys/gql/mutations";
import { useNavigate } from "react-router-dom";
import { surveysPaths } from "../../constants";

export const useMutations = ({ survey, onCloneCompleted }) => {
  const navigate = useNavigate();

  const { failed, succeeded } = useAddToast();

  const { t } = useTranslation("admin");

  const handlePublish = (input: PublishCriteriaInput) => {
    publishSurveyMutation({ variables: { publishSurveyId: survey?.id!, input } });
  };

  const [publishSurveyMutation, { loading: isPublishLoading }] = usePublishSurveyMutation({
    onCompleted: (mutationData: PublishSurveyMutation) => {
      const errors = mutationData?.publishSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey published successfully"));
        navigate(surveysPaths.list.fullPath);
      }
    },
    onError: error => {
      failed(t(error.message ?? "Failed publishing survey"));
    },
    refetchQueries: [FetchSurveysDocument],
  });

  const [unPublishSurveyMutation, { loading: isUnPublishLoading }] = useUnPublishSurveyMutation({
    onCompleted: (mutationData: UnPublishSurveyMutation) => {
      const errors = mutationData?.unPublishSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey unpublished successfully"));
        navigate(surveysPaths.list.fullPath);
      }
    },
    onError: error => {
      failed(t(error.message ?? "Failed unpublish survey"));
    },
    refetchQueries: [FetchSurveysDocument],
  });

  const handleUnPublish = () => {
    unPublishSurveyMutation({ variables: { unPublishSurveyId: survey?.id } });
  };

  const [cloneSurveyMutation, { loading: isCloneLoading }] = useCloneSurveyMutation({
    onCompleted: (mutationData: CloneSurveyMutation) => {
      const errors = mutationData?.cloneSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey cloned successfully"));
        onCloneCompleted();
        navigate(surveysPaths.update.fullPathWithParams({ surveyId: mutationData.cloneSurvey?.id! }));
      }
    },
    onError: ({ graphQLErrors }) => {
      const formattedError = formatGraphQLError(graphQLErrors);
      const message = formattedError || t("Failed cloning survey");
      failed(t(message));
    },
  });

  const [cancelSurveyMutation, { loading: isCancelLoading }] = useCancelSurveyMutation({
    onCompleted: (res: CancelSurveyMutation) => {
      if (res?.cancelSurvey?.id) {
        succeeded(t("Succeeded in cancelling survey"));
        navigate(surveysPaths.list.fullPath);
      }
    },
    onError: () => {
      failed(t("Failed in cancelling survey"));
    },
    refetchQueries: [FetchSurveysDocument],
  });

  return {
    isPublishLoading,
    isCancelLoading,
    isUnPublishLoading,
    isCloneLoading,
    cloneSurveyMutation,
    handlePublish,
    handleUnPublish,
    publishSurveyMutation,
    cancelSurveyMutation,
  };
};
