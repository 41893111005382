import { OptimaAction } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  IOptimaActionUpsertFormEvent,
  OptimaActionUpsertForm,
} from "@/pages/OptimaActions/forms/OptimaActionUpsert/OptimaActionUpsertForm";
import { OptimaActionListDocument, useOptimaActionGetQuery, useOptimaActionUpdateMutation } from "@/pages/OptimaActions/gql";
import { convertOptimaActionFormValuesToBackEndValues } from "@/pages/OptimaActions/others";
import { IOptimaActionUpdateContainerParams } from "@/pages/OptimaActions/types";
import { FC } from "react";
import { useParams } from "react-router-dom";

export type IOptimaActionUpdateContainerEvent = {
  type: "SUBMIT_SUCCESS";
};

type OptimaActionUpdateContainerProps = {
  onChange: (event: IOptimaActionUpdateContainerEvent) => void;
};

export const OptimaActionUpdateContainer: FC<OptimaActionUpdateContainerProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const { actionId } = useParams<IOptimaActionUpdateContainerParams>();

  const { data, loading } = useOptimaActionGetQuery({
    variables: { id: actionId! },
    skip: !actionId,
    fetchPolicy: "no-cache",
  });

  const optimaAction = data?.optimaAction as OptimaAction;

  const [updateOptimaAction, { loading: isSubmitting }] = useOptimaActionUpdateMutation({
    onCompleted: () => {
      succeeded(t("Optima Action updated successfully"));

      onChange({
        type: "SUBMIT_SUCCESS",
      });
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [OptimaActionListDocument],
  });

  const onOptimaActionUpsertFormChange = (event: IOptimaActionUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertOptimaActionFormValuesToBackEndValues(event.payload.values);

      updateOptimaAction({
        variables: {
          id: actionId!,
          input: values,
        },
      });
    }
  };

  return (
    <OptimaActionUpsertForm
      buttonLabel={t("Update")}
      optimaAction={optimaAction}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      onChange={onOptimaActionUpsertFormChange}
    />
  );
};
