import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { BranchesAutocompleteQuery } from "./gql";

export type IBranchAutocomplete = ExtractNodeType<BranchesAutocompleteQuery>;

export const branchSchema = createZodAutocompleteFromType<IBranchAutocomplete>({
  id: z.string(),
  name: z.string(),
  nameAr: z.string().nullish(),
  healthLicense: z.string().nullish(),
});
