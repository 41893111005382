import { Branch } from "@/schema/types";
import { MainListActions, useGridContext } from "@toolkit/ui";
import React, { FC } from "react";

type BranchListMainActionsProps = {
  vendorHasMultipleBranches: boolean;
  onAddNewItemClick: VoidFunction;
};

export const BranchListMainActions: FC<BranchListMainActionsProps> = props => {
  const { vendorHasMultipleBranches, onAddNewItemClick } = props;

  const { customTableProps } = useGridContext<Branch>();
  const canAddNewBranch = vendorHasMultipleBranches || (customTableProps?.data?.length === 0 && !customTableProps?.isLoading);
  return <MainListActions hasAddNew disabled={!canAddNewBranch} onAddNewItem={onAddNewItemClick} />;
};
