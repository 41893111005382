import { HypertensionPrognosis, Maybe } from "@/schema/types";
import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Grid } from "@toolkit/ui";
import { CaseManagementPatientDigitalTwinPrognosticRiskScore } from "../CaseManagementPatientDigitalTwinPrognosticRiskScore/CaseManagementPatientDigitalTwinPrognosticRiskScore";
import { CaseManagementPatientDigitalTwinLinearProgress } from "../CaseManagementPatientDigitalTwinLinearProgress/CaseManagementPatientDigitalTwinLinearProgress";

type CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertensionProps = {
  category: string;
  value: number;
  complications?: Maybe<HypertensionPrognosis>;
};

export const CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertension: FC<
  CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertensionProps
> = props => {
  const { category, value, complications } = props;

  const { t } = useTranslation("admin");

  return (
    <CaseManagementPatientDigitalTwinPrognosticRiskScore name={t("Hypertension")} category={category} value={value}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Nephropathy")}
            category={complications?.categoryNephropathy!}
            value={complications?.riskOfNephropathyComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Retinopathy")}
            category={complications?.categoryRetinopathy!}
            value={complications?.riskOfRetinopathyComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Heart Failure")}
            category={complications?.categoryHeartFailure!}
            value={complications?.riskOfHeartFailureComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("Cerebrovascular")}
            category={complications?.categoryCerebrovascular!}
            value={complications?.riskOfCerebrovascularComplications!}
          />
        </Grid>

        <Grid item xs={12}>
          <CaseManagementPatientDigitalTwinLinearProgress
            name={t("IHD")}
            category={complications?.categoryIschemicHeartDisease!}
            value={complications?.riskOfIschemicHeartDiseaseComplications!}
          />
        </Grid>
      </Grid>
    </CaseManagementPatientDigitalTwinPrognosticRiskScore>
  );
};
