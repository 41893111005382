import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { GuidedCareTeamMembersUsersQuery } from "./gql";

export type IGuidedCareTeamUserAutocomplete = Omit<
  NonNullable<NonNullable<NonNullable<GuidedCareTeamMembersUsersQuery["providerGuidedCareHealthProgramTeam"]>["teamMembers"]>[number]>,
  "__typename"
>;

export const guidedCareTeamUserSchema = createZodAutocompleteFromType<IGuidedCareTeamUserAutocomplete>({
  id: z.string(),
  user: z
    .object({
      id: z.string(),
      fullName: z.string(),
    })
    .nullish(),
});
