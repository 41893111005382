import { MarketplaceHealthPackage, MarketplaceHealthPackageSortingField } from "@/schema/types";
import { getMarketplaceHealthPackageCategoriesAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { startCase } from "lodash";
import { useMemo } from "react";
import { ellipseName } from "utils";
import { ApproveHealthPackage } from "./ApproveHealthPackage";
import { PreviewHealthPackages } from "./PreviewHealthPackages";
import RejectHealthPackage from "./RejectHealthPackage";

export const useHealthPackageColumns = (): CustomTableColumnProps<MarketplaceHealthPackage>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image "),
        accessor: ({ mainImageUrl }) => (
          <GridImageModal image={mainImageUrl!} title={t("Health Package Image")} altText={t("Health Package")} />
        ),
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => ellipseName(pickLocalizedValue(name, nameAr), 20)!,
        sort: {
          columnEnum: MarketplaceHealthPackageSortingField.Name,
        },
        filter: {
          type: "string",
          name: "nameContains",
        },
      },
      {
        key: "vendor",
        header: t("Health Provider"),
        accessor: ({ vendor }) => ellipseName(pickLocalizedValue(vendor?.name, vendor?.nameAr), 20),
        filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
      },
      {
        key: "status",
        header: t("Approval Status"),
        accessor: row => t(startCase(row?.approvalStatus!.toLowerCase())),
        filter: getAutocompleteEnumFilter("MarketplaceApprovalStatusNoDraft", "approvalStatuses", { multiple: true }),
      },
      {
        key: "category",
        header: t("Category"),
        showOnlyForFilterField: true,
        filter: getMarketplaceHealthPackageCategoriesAutocompleteFilter({ name: "categoryId" }),
      },
      {
        key: "targetGender",
        header: t("Gender", { ns: "admin" }),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("MarketplaceGender", "targetGender"),
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: row => <PreviewHealthPackages row={row} />,
      },

      {
        key: "isPublished",
        header: t("Is Published"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
      },
      {
        key: "targetAge",
        header: t("Target Age"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "targetAge",
        },
        isHidden: true,
        accessor: row => row.targetFromAge,
      },
      {
        key: "targetToAge",
        header: t("To Age", { ns: "provider" }),
        showOnlyForFilterField: true,
        isHidden: true,
        accessor: row => row.targetToAge,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: row => (
          <Box display='flex' flexDirection='row' gap={2}>
            <ApproveHealthPackage row={row} />
            <RejectHealthPackage row={row} />
          </Box>
        ),
      },
    ];
  }, []);
};
