import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListFieldsAutocompleteQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListFieldsAutocompleteQuery = { __typename?: 'Query', getAllListFields?: Array<{ __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null } | null> | null };


export const ListFieldsAutocompleteDocument = gql`
    query ListFieldsAutocomplete {
  getAllListFields {
    id
    code
    display
  }
}
    `;

/**
 * __useListFieldsAutocompleteQuery__
 *
 * To run a query within a React component, call `useListFieldsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFieldsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFieldsAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFieldsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>(ListFieldsAutocompleteDocument, options);
      }
export function useListFieldsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>(ListFieldsAutocompleteDocument, options);
        }
export function useListFieldsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>(ListFieldsAutocompleteDocument, options);
        }
export type ListFieldsAutocompleteQueryHookResult = ReturnType<typeof useListFieldsAutocompleteQuery>;
export type ListFieldsAutocompleteLazyQueryHookResult = ReturnType<typeof useListFieldsAutocompleteLazyQuery>;
export type ListFieldsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useListFieldsAutocompleteSuspenseQuery>;
export type ListFieldsAutocompleteQueryResult = Apollo.QueryResult<ListFieldsAutocompleteQuery, ListFieldsAutocompleteQueryVariables>;