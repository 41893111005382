import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useSetSurveysBreadcrumbs } from "../../hooks";
import { ISurveyUpsertFormEvent, SurveyUpsertForm } from "../../forms/SurveyUpsert/surveyUpsertForm";
import { FetchSurveysDocument, useCreateSurveyMutation } from "../../gql";
import { surveysPaths } from "../../constants";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { convertSurveyFormValuesToBackEndValues } from "../../others";

export const SurveyCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createSurveyMutation, { loading: isCreateLoading }] = useCreateSurveyMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.createSurvey?.errors;
      if (errors && errors?.length > 0) {
        failed(t(formatMessageErrors(errors)));
      } else {
        succeeded(t("Survey created successfully"));
        navigate(surveysPaths.list.fullPath);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [FetchSurveysDocument],
  });

  const onCityUpsertFormChange = (event: ISurveyUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertSurveyFormValuesToBackEndValues(event.payload.values);

      createSurveyMutation({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetSurveysBreadcrumbs("NEW");

  return <SurveyUpsertForm buttonLabel={t("Create")} isSubmitting={isCreateLoading} onChange={onCityUpsertFormChange} />;
};
