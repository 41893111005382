import { makeStyles } from "@toolkit/ui";

export const useMedicalFormQuestionUpsertFormStyle = makeStyles()(() => ({
  questionTypeOptionWrapper: {
    paddingInline: 12,
  },
  questionTypeOptionValue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8,
  },
  divider: {
    width: "100%",
  },
}));
