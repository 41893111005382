import { MarketplaceDefaultSortingField, MarketplaceSortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { Box, FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import ParentCategoryInfoCard from "pages/MarketplaceHealthPackageCategories/components/ParentCategoryInfoCard.component";
import { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { healthPackageCategoriesBreadcrumb } from "../../constants/CategoriesBreadcrumbs";
import { healthPackageCategoriesPaths } from "../../constants/CategoriesPaths";
import { useMarketplaceHealthPackageCategoriesQuery } from "../../gql";
import { HealthPackageCategoriesQueryNode } from "../../types";
import { useCategoriesListTableColumns } from "./useCategoriesListTableColumns";

type CategoriesListContainerProps = {
  hasParent?: boolean;
};
export const CategoriesListContainer: FC<CategoriesListContainerProps> = ({ hasParent }) => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { categoryId: parentId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const onEditRowClick = (item: HealthPackageCategoriesQueryNode) => {
    navigate(healthPackageCategoriesPaths.update.fullPathWithParams({ categoryId: item?.id }));
  };
  const { pathname } = useLocation();
  const filter = hasParent
    ? {
        parentId: parentId,
      }
    : {
        parent: true,
      };
  const onAddNewItemClick = () => {
    hasParent
      ? navigate(healthPackageCategoriesPaths.newSubcategory.fullPathWithParams({ categoryId: parentId! }))
      : navigate(healthPackageCategoriesPaths.new.fullPath);
  };
  useEffect(() => {
    !hasParent &&
      setBreadCrumb({
        title: healthPackageCategoriesBreadcrumb().title,
        values: [],
      });
  }, [pathname, hasParent, setBreadCrumb]);

  return (
    <GridProvider
      gridName='marketplace-healthPackage-healthPackageCategories'
      query={useMarketplaceHealthPackageCategoriesQuery}
      columns={useCategoriesListTableColumns()}
      hasTableSetting
      variables={{
        sortBy: {
          field: MarketplaceDefaultSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
        filter: filter,
      }}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        {hasParent && <ParentCategoryInfoCard hasParent={hasParent} />}
        <Box marginTop={2}>
          <TableGrid />
        </Box>
      </PageWrapper>
    </GridProvider>
  );
};
