import { HealthProgramBenefitsForm } from "@/pages/HealthPrograms/forms/HealthProgramBenefits/HealthProgramBenefitsForm";
import { HealthProgram } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertHealthProgramToFormValues } from "../../others";
import { HealthProgramDetailsForm } from "../HealthProgramDetails/HealthProgramDetailsForm";
import { HealthProgramImagesForm } from "../HealthProgramImages/HealthProgramImagesForm";
import { HealthProgramInformationForm } from "../HealthProgramInformation/HealthProgramInformationForm";
import { HealthProgramParametersForm } from "../HealthProgramParameters/HealthProgramParametersForm";
import {
  healthProgramUpsertFormDefaultValues,
  healthProgramUpsertFormSchema,
  IHealthProgramUpsertFormValues,
} from "./HealthProgramUpsertFormSchema";

export type IHealthProgramUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthProgramUpsertFormValues;
  };
};

type HealthProgramUpsertFormProps = {
  healthProgram?: HealthProgram;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IHealthProgramUpsertFormEvent) => void;
};

export const HealthProgramUpsertForm: FC<HealthProgramUpsertFormProps> = props => {
  const { healthProgram, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IHealthProgramUpsertFormValues>({
    defaultValues: healthProgramUpsertFormDefaultValues,
    schema: healthProgramUpsertFormSchema,
  });

  const { watch, handleSubmit, setValues } = form;

  const payPerCall = watch("payPerCall");

  const onSubmit = (values: IHealthProgramUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (healthProgram) {
      const _healthProgram = convertHealthProgramToFormValues(healthProgram);
      setValues(_healthProgram);
    }
  }, [healthProgram, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Program Information")} loading={isLoading} doYouHaveData>
                <HealthProgramInformationForm isUpdateMode={!!healthProgram} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Program Details")} loading={isLoading} doYouHaveData>
                <HealthProgramDetailsForm isUpdateMode={!!healthProgram} />
              </FormCard>
            </Grid>

            {!payPerCall && (
              <Grid item xs={12}>
                <FormCard title={t("Program Benefits")} loading={isLoading} doYouHaveData>
                  <HealthProgramBenefitsForm healthProgramBenefits={healthProgram?.benefitGroups} />
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Program Points Factors")} loading={isLoading} doYouHaveData>
                <HealthProgramParametersForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Program Images")} loading={isLoading} doYouHaveData>
                <HealthProgramImagesForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
