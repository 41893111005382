import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import { FC } from "react";

export const DiagnosisValidProcedureInformationForm: FC = () => {
  const { t } = useTranslation("admin");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormTextField name={"procedureCode"} label={t("Procedure Code")} />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormTextField name={"diagnosisCode"} label={t("Diagnosis Code")} />
        </Grid>
      </Grid>
      <Grid item xs={6} mt={2}>
        <FormTextField name='description' label={t("Description")} multiline maxRows={3} minRows={3} />
      </Grid>
    </>
  );
};
