/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { pick } from "lodash";
import { GuidedCareActivityType, TeamMemberPosition } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const guidedCareActivityTypeOptionsMap: IEnumToOptionsMap<GuidedCareActivityType> = {
  [GuidedCareActivityType.FacilityVisit]: {
    key: GuidedCareActivityType.FacilityVisit,
    get label() {
      return i18n.t("Appointments - Facility", { ns: "domains" });
    },
    value: GuidedCareActivityType.FacilityVisit,
  },
  [GuidedCareActivityType.HomeVisit]: {
    key: GuidedCareActivityType.HomeVisit,
    get label() {
      return i18n.t("Appointments - Home", { ns: "domains" });
    },
    value: GuidedCareActivityType.HomeVisit,
  },
  [GuidedCareActivityType.OnlineVisit]: {
    key: GuidedCareActivityType.OnlineVisit,
    get label() {
      return i18n.t("Appointments - Online", { ns: "domains" });
    },
    value: GuidedCareActivityType.OnlineVisit,
  },
  [GuidedCareActivityType.MedicalForm]: {
    key: GuidedCareActivityType.MedicalForm,
    get label() {
      return i18n.t("Medical Form", { ns: "domains" });
    },
    value: GuidedCareActivityType.MedicalForm,
  },
  [GuidedCareActivityType.MedicalMessageGeneral]: {
    key: GuidedCareActivityType.MedicalMessageGeneral,
    get label() {
      return i18n.t("Messages - General", { ns: "domains" });
    },
    value: GuidedCareActivityType.MedicalMessageGeneral,
  },
  [GuidedCareActivityType.MedicalMessageInstruction]: {
    key: GuidedCareActivityType.MedicalMessageInstruction,
    get label() {
      return i18n.t("Messages - Instruction", { ns: "domains" });
    },
    value: GuidedCareActivityType.MedicalMessageInstruction,
  },
  [GuidedCareActivityType.MedicalMessageMedical]: {
    key: GuidedCareActivityType.MedicalMessageMedical,
    get label() {
      return i18n.t("Messages - Medical", { ns: "domains" });
    },
    value: GuidedCareActivityType.MedicalMessageMedical,
  },
  [GuidedCareActivityType.MedicalMessageReminder]: {
    key: GuidedCareActivityType.MedicalMessageReminder,
    get label() {
      return i18n.t("Messages - Reminder", { ns: "domains" });
    },
    value: GuidedCareActivityType.MedicalMessageReminder,
  },
  [GuidedCareActivityType.MedicalMessageMonitoring]: {
    key: GuidedCareActivityType.MedicalMessageMonitoring,
    get label() {
      return i18n.t("Messages - Monitoring", { ns: "domains" });
    },
    value: GuidedCareActivityType.MedicalMessageMonitoring,
  },
  [GuidedCareActivityType.Lab]: {
    key: GuidedCareActivityType.Lab,
    get label() {
      return i18n.t("Medical Laboratory", { ns: "domains" });
    },
    value: GuidedCareActivityType.Lab,
  },
  [GuidedCareActivityType.Rad]: {
    key: GuidedCareActivityType.Rad,
    get label() {
      return i18n.t("Medical Radiology", { ns: "domains" });
    },
    value: GuidedCareActivityType.Rad,
  },
};

export const guidedCareActivityTypeOptions = Object.values(guidedCareActivityTypeOptionsMap);

export const guidedCareActivityTypeMedicalMessageOptions = Object.values(
  pick(guidedCareActivityTypeOptionsMap, [
    GuidedCareActivityType.MedicalMessageInstruction,
    GuidedCareActivityType.MedicalMessageGeneral,
    GuidedCareActivityType.MedicalMessageReminder,
    GuidedCareActivityType.MedicalMessageMedical,
    GuidedCareActivityType.MedicalMessageMonitoring,
  ])
);

export const guidedCareActivityTypeNonMedicalMessageOptions = Object.values(
  pick(guidedCareActivityTypeOptionsMap, [
    GuidedCareActivityType.FacilityVisit,
    GuidedCareActivityType.HomeVisit,
    GuidedCareActivityType.OnlineVisit,
    GuidedCareActivityType.MedicalForm,
    GuidedCareActivityType.Lab,
    GuidedCareActivityType.Rad,
  ])
);

export const guidedCareActivityTypeAppointmentOptions = Object.values(
  pick(guidedCareActivityTypeOptionsMap, [
    GuidedCareActivityType.FacilityVisit,
    GuidedCareActivityType.HomeVisit,
    GuidedCareActivityType.OnlineVisit,
  ])
);

export const guidedCareActivityTypeTemplateOptions = guidedCareActivityTypeOptions.filter(
  item => item?.key !== GuidedCareActivityType.MedicalMessageInstruction
);

export const guidedCareActivityTypeProgramOptions = Object.values(
  pick(guidedCareActivityTypeOptionsMap, [
    GuidedCareActivityType.FacilityVisit,
    GuidedCareActivityType.HomeVisit,
    GuidedCareActivityType.OnlineVisit,
    GuidedCareActivityType.Lab,
    GuidedCareActivityType.Rad,
  ])
);

export const guidedCareActivityTypeToTeamMemberPositionMap: Record<GuidedCareActivityType, TeamMemberPosition[]> = {
  [GuidedCareActivityType.FacilityVisit]: [
    TeamMemberPosition.TeamLeader,
    TeamMemberPosition.Physician,
    TeamMemberPosition.Nurse,
    TeamMemberPosition.DentalHygienist,
    TeamMemberPosition.DiabetesEducator,
    TeamMemberPosition.FitnessCoach,
    TeamMemberPosition.Nutritionist,
    TeamMemberPosition.Optometrist,
    TeamMemberPosition.PodiatricMedicalAssistant,
    TeamMemberPosition.Psychologist,
    TeamMemberPosition.SocialWorker,
  ],
  [GuidedCareActivityType.HomeVisit]: [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician, TeamMemberPosition.Nurse],
  [GuidedCareActivityType.OnlineVisit]: [
    TeamMemberPosition.TeamLeader,
    TeamMemberPosition.TeamAdmin,
    TeamMemberPosition.Physician,
    TeamMemberPosition.Nurse,
    TeamMemberPosition.DentalHygienist,
    TeamMemberPosition.DiabetesEducator,
    TeamMemberPosition.FitnessCoach,
    TeamMemberPosition.Nutritionist,
    TeamMemberPosition.Optometrist,
    TeamMemberPosition.PodiatricMedicalAssistant,
    TeamMemberPosition.Psychologist,
    TeamMemberPosition.SocialWorker,
  ],
  [GuidedCareActivityType.MedicalForm]: [
    TeamMemberPosition.TeamLeader,
    TeamMemberPosition.TeamAdmin,
    TeamMemberPosition.Physician,
    TeamMemberPosition.Nurse,
  ],
  [GuidedCareActivityType.MedicalMessageInstruction]: [
    TeamMemberPosition.TeamLeader,
    TeamMemberPosition.Physician,
    TeamMemberPosition.Nurse,
  ],
  [GuidedCareActivityType.MedicalMessageGeneral]: [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician, TeamMemberPosition.Nurse],
  [GuidedCareActivityType.MedicalMessageReminder]: [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician, TeamMemberPosition.Nurse],
  [GuidedCareActivityType.MedicalMessageMedical]: [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician, TeamMemberPosition.Nurse],
  [GuidedCareActivityType.MedicalMessageMonitoring]: [
    TeamMemberPosition.TeamLeader,
    TeamMemberPosition.Physician,
    TeamMemberPosition.Nurse,
  ],
  [GuidedCareActivityType.Lab]: [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician, TeamMemberPosition.Nurse],
  [GuidedCareActivityType.Rad]: [TeamMemberPosition.TeamLeader, TeamMemberPosition.Physician, TeamMemberPosition.Nurse],
};
