import { QuestionType } from "@/schema/types";
import { questionTypeOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Divider, FormAutocomplete, FormSwitch, FormTextField, Grid, ImageUploadController } from "@toolkit/ui";
import { MedicalFormQuestionOptionUpsertForm } from "@/pages/MedicalForms/forms/MedicalFormQuestionOptionUpsert/MedicalFormQuestionOptionUpsertForm";
import { createDefaultQuestionOptions, getOptionIcon, shouldQuestionHaveOptions } from "@/pages/MedicalForms/others";
import { IMedicalFormQuestionUpsertFormEvent } from "@/pages/MedicalForms/types";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import {
  IMedicalFormQuestionUpsertFormValues,
  medicalFormQuestionUpsertFormDefaultValues,
  medicalFormQuestionUpsertFormSchema,
} from "./MedicalFormQuestionUpsertFormSchema";
import { useMedicalFormQuestionUpsertFormStyle } from "./useMedicalFormQuestionUpsertFormStyle";

type MedicalFormQuestionsFormProps = {
  question: IMedicalFormQuestionUpsertFormValues;
  onChange: (event: IMedicalFormQuestionUpsertFormEvent) => void;
};

export type MedicalFormQuestionUpsertFormRef = {
  getForm: () => UseFormReturn<IMedicalFormQuestionUpsertFormValues>;
  submit: () => void;
};

const MedicalFormQuestionUpsertFormForwardRef: ForwardRefRenderFunction<MedicalFormQuestionUpsertFormRef, MedicalFormQuestionsFormProps> = (
  props,
  ref
) => {
  const { question, onChange } = props;

  const { t } = useTranslation("domains");

  const { accessToken } = useOidcUserProfile();

  const { classes, theme } = useMedicalFormQuestionUpsertFormStyle();

  const form = useCustomForm<IMedicalFormQuestionUpsertFormValues>({
    defaultValues: medicalFormQuestionUpsertFormDefaultValues,
    schema: medicalFormQuestionUpsertFormSchema,
  });

  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = form;
  const questionType = watch("questionType")?.value;

  const onSubmit = (values: IMedicalFormQuestionUpsertFormValues) => {
    if (question) {
      onChange({
        type: "UPDATE",
        payload: { values },
      });
    } else {
      onChange({
        type: "CREATE",
        payload: { values },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (question) {
      setValue("question", question.question);
      setValue("questionType", question.questionType);
      setValue("required", question.required);
      setValue("image", question.image);
    }
  }, [question, setValue]);

  useEffect(() => {
    if (questionType === QuestionType.String) {
      setValue("options", []);
    } else if (question && question.questionType?.key === questionType) {
      setValue("options", question.options);
    } else {
      setValue("options", createDefaultQuestionOptions(questionType));
    }
  }, [question, questionType]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <FormTextField name={"question"} label={t("Question")} />
          </Grid>

          <Grid item xs={5}>
            <FormAutocomplete
              name={"questionType"}
              label={t("Question Type")}
              options={questionTypeOptions}
              renderOption={(_, option) => {
                return (
                  <Box component={"li"} className={classes.questionTypeOptionWrapper} {..._}>
                    <Box className={classes.questionTypeOptionValue}>{getOptionIcon(option?.value, theme.palette.primary.main)}</Box>
                    <Box>{option?.label}</Box>
                  </Box>
                );
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormSwitch name={"required"} label={t("Required")} />
          </Grid>

          {shouldQuestionHaveOptions(questionType) && (
            <Grid item xs={12}>
              <MedicalFormQuestionOptionUpsertForm />
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <ImageUploadController
              control={form.control}
              name={"image"}
              label={t("Image")}
              validationRation
              error={!!errors?.image}
              helperText={errors?.image?.message}
              token={accessToken!}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const MedicalFormQuestionUpsertForm = forwardRef(MedicalFormQuestionUpsertFormForwardRef);
