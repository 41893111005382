import { createPathDefinition } from "@toolkit/core";

export const healthProgramsRoute = "/health-programs/profile";

export const healthProgramsPaths = createPathDefinition(healthProgramsRoute, {
  list: "",
  create: "new",
  update: ":healthProgramId",
  parameters: ":healthProgramId/parameters",
});
