import { CallbackRequestUpdateHistory } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useState } from "react";
import { useGetCallbackDetailsQuery } from "../../../../../gql";
import { CallbackRequestUpdateHistoryQueryNodeType } from "./types";
import { useCallbackHistoryColumns } from "./useCallbackHistoryColumns";

type InputState = {
  first: number | null;
  after: string | null;
  before: string | null;
  last: number | null;
  callbackRequestId: string;
};

export const useRequestProcessingLog = (id?: CallbackRequestUpdateHistory["id"]) => {
  const { t } = useTranslation("admin");
  const columns = useCallbackHistoryColumns();
  const pageSize = 3;
  const [input, setInput] = useState<InputState>({
    first: pageSize,
    after: null,
    before: null,
    last: null,
    callbackRequestId: id as string,
  });
  const { data: callbackData, loading: isLoading } = useGetCallbackDetailsQuery({
    variables: {
      ...input,
    },
    fetchPolicy: "no-cache",
  });
  const pageInfo = callbackData?.callbackRequestUpdateHistory?.pageInfo;
  const tableData = callbackData?.callbackRequestUpdateHistory?.edges?.map(e => e.node) as CallbackRequestUpdateHistoryQueryNodeType[];
  const hasNextPage = pageInfo?.hasNextPage;
  const hasPreviousPage = pageInfo?.hasPreviousPage;
  const handleGotoNext = () => {
    setInput(prev => ({
      ...prev,
      first: pageSize,
      after: pageInfo?.endCursor || null,
      last: null,
      before: null,
    }));
  };

  const handleGoToPrevious = () => {
    setInput(prev => ({
      ...prev,
      last: pageSize,
      before: pageInfo?.startCursor || null,
      first: null,
      after: null,
    }));
  };

  return {
    t,
    tableData,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    handleGotoNext,
    handleGoToPrevious,
    columns,
    pageSize,
  };
};
