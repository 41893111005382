import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { PatientsAutocompleteQuery } from "./gql";

export type IPatientAutocomplete = ExtractNodeType<PatientsAutocompleteQuery>;

export const patientSchema = createZodAutocompleteFromType<IPatientAutocomplete>({
  id: z.string(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
});
