import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CitiesAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.CityFilterInput>;
}>;


export type CitiesAutocompleteQuery = { __typename?: 'Query', cities?: { __typename?: 'CityCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'CityCountableEdge', node: { __typename?: 'City', id: string, name: string, nameAr?: string | null } }> } | null };


export const CitiesAutocompleteDocument = gql`
    query CitiesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: CityFilterInput) {
  cities(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useCitiesAutocompleteQuery__
 *
 * To run a query within a React component, call `useCitiesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCitiesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>(CitiesAutocompleteDocument, options);
      }
export function useCitiesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>(CitiesAutocompleteDocument, options);
        }
export function useCitiesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>(CitiesAutocompleteDocument, options);
        }
export type CitiesAutocompleteQueryHookResult = ReturnType<typeof useCitiesAutocompleteQuery>;
export type CitiesAutocompleteLazyQueryHookResult = ReturnType<typeof useCitiesAutocompleteLazyQuery>;
export type CitiesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useCitiesAutocompleteSuspenseQuery>;
export type CitiesAutocompleteQueryResult = Apollo.QueryResult<CitiesAutocompleteQuery, CitiesAutocompleteQueryVariables>;