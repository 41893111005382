import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramBenefitsGroupsAutocompleteQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BenefitFilterInput>;
}>;


export type HealthProgramBenefitsGroupsAutocompleteQuery = { __typename?: 'Query', healthProgramBenefitGroups?: Array<{ __typename?: 'BenefitGroup', id: string, name?: string | null } | null> | null };


export const HealthProgramBenefitsGroupsAutocompleteDocument = gql`
    query HealthProgramBenefitsGroupsAutocomplete($filter: BenefitFilterInput) {
  healthProgramBenefitGroups(filter: $filter) {
    id
    name
  }
}
    `;

/**
 * __useHealthProgramBenefitsGroupsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitsGroupsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitsGroupsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitsGroupsAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramBenefitsGroupsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>(HealthProgramBenefitsGroupsAutocompleteDocument, options);
      }
export function useHealthProgramBenefitsGroupsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>(HealthProgramBenefitsGroupsAutocompleteDocument, options);
        }
export function useHealthProgramBenefitsGroupsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>(HealthProgramBenefitsGroupsAutocompleteDocument, options);
        }
export type HealthProgramBenefitsGroupsAutocompleteQueryHookResult = ReturnType<typeof useHealthProgramBenefitsGroupsAutocompleteQuery>;
export type HealthProgramBenefitsGroupsAutocompleteLazyQueryHookResult = ReturnType<typeof useHealthProgramBenefitsGroupsAutocompleteLazyQuery>;
export type HealthProgramBenefitsGroupsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useHealthProgramBenefitsGroupsAutocompleteSuspenseQuery>;
export type HealthProgramBenefitsGroupsAutocompleteQueryResult = Apollo.QueryResult<HealthProgramBenefitsGroupsAutocompleteQuery, HealthProgramBenefitsGroupsAutocompleteQueryVariables>;