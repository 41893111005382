import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParametersAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.HealthParameterSortingInput>;
  filter?: Types.InputMaybe<Types.HealthParameterFilterInput>;
}>;


export type HealthParametersAutocompleteQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null> | null } | null };


export const HealthParametersAutocompleteDocument = gql`
    query HealthParametersAutocomplete($first: Int, $last: Int, $after: String, $before: String, $sortBy: HealthParameterSortingInput, $filter: HealthParameterFilterInput) {
  getHealthParameters(
    after: $after
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        code
        display
        arabicDisplay
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useHealthParametersAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthParametersAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParametersAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParametersAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthParametersAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>(HealthParametersAutocompleteDocument, options);
      }
export function useHealthParametersAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>(HealthParametersAutocompleteDocument, options);
        }
export function useHealthParametersAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>(HealthParametersAutocompleteDocument, options);
        }
export type HealthParametersAutocompleteQueryHookResult = ReturnType<typeof useHealthParametersAutocompleteQuery>;
export type HealthParametersAutocompleteLazyQueryHookResult = ReturnType<typeof useHealthParametersAutocompleteLazyQuery>;
export type HealthParametersAutocompleteSuspenseQueryHookResult = ReturnType<typeof useHealthParametersAutocompleteSuspenseQuery>;
export type HealthParametersAutocompleteQueryResult = Apollo.QueryResult<HealthParametersAutocompleteQuery, HealthParametersAutocompleteQueryVariables>;