import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";

import { FC } from "react";

export const SurveyInformationForm: FC = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid container item direction='column' spacing={2}>
        <Grid item>
          <FormTextField name='label' label={t("Survey Title")} fullWidth size='small' />
        </Grid>
        <Grid item>
          <FormTextField name='notification' label={t("Survey Notification")} fullWidth size='small' />
        </Grid>
        <Grid item>
          <FormTextField
            name='description'
            label={t("Description")}
            fullWidth
            size='small'
            multiline
            rows={5}
            placeholder={t("Survey Description")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
