import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddSubListMutationVariables = Types.Exact<{
  subList?: Types.InputMaybe<Types.SubListInput>;
}>;


export type AddSubListMutation = { __typename?: 'Mutation', response?: { __typename: 'SubList', id?: string | null, display?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const AddSubListDocument = gql`
    mutation addSubList($subList: SubListInput) {
  response: addSubList(subList: $subList) {
    id
    display
    __typename
    errors {
      field
      code
      message
    }
  }
}
    `;
export type AddSubListMutationFn = Apollo.MutationFunction<AddSubListMutation, AddSubListMutationVariables>;

/**
 * __useAddSubListMutation__
 *
 * To run a mutation, you first call `useAddSubListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubListMutation, { data, loading, error }] = useAddSubListMutation({
 *   variables: {
 *      subList: // value for 'subList'
 *   },
 * });
 */
export function useAddSubListMutation(baseOptions?: Apollo.MutationHookOptions<AddSubListMutation, AddSubListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubListMutation, AddSubListMutationVariables>(AddSubListDocument, options);
      }
export type AddSubListMutationHookResult = ReturnType<typeof useAddSubListMutation>;
export type AddSubListMutationResult = Apollo.MutationResult<AddSubListMutation>;
export type AddSubListMutationOptions = Apollo.BaseMutationOptions<AddSubListMutation, AddSubListMutationVariables>;