import { FormDatePickerField, FormTextField, Grid } from "@toolkit/ui";
import { BACKEND_DATE_FORMAT, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import moment from "moment";
import { IProviderUpsertForm } from "@/pages/Providers/forms/ProviderUpsert/ProviderUpsertFormSchema";

export const ProviderHealthLicenseForm = () => {
  const { t } = useTranslation("admin");

  const form = useCustomFormContext<IProviderUpsertForm>();

  const healthLicenseStartDate = form.watch("healthLicenseStartDate");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"healthLicenseNumber"} label={t("Health License Number")} placeholder={t("Health License Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"healthLicenseStartDate"}
          label={t("Health License Start Date")}
          placeholder={t("Health License Start Date")}
          format={BACKEND_DATE_FORMAT}
        />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"healthLicenseEndDate"}
          label={t("Health License End Date")}
          placeholder={t("Health License End Date")}
          format={BACKEND_DATE_FORMAT}
          datePickerProps={{
            minDate: healthLicenseStartDate ? moment(healthLicenseStartDate, BACKEND_DATE_FORMAT).add(1, "days") : undefined,
          }}
        />
      </Grid>
    </Grid>
  );
};
