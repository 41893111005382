import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CaseManagementPatientGetQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type CaseManagementPatientGetQuery = { __typename?: 'Query', patient?: { __typename?: 'Patient', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null };


export const CaseManagementPatientGetDocument = gql`
    query CaseManagementPatientGet($id: ID) {
  patient(id: $id) {
    id
    user {
      id
      fullName
    }
  }
}
    `;

/**
 * __useCaseManagementPatientGetQuery__
 *
 * To run a query within a React component, call `useCaseManagementPatientGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementPatientGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagementPatientGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseManagementPatientGetQuery(baseOptions?: Apollo.QueryHookOptions<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>(CaseManagementPatientGetDocument, options);
      }
export function useCaseManagementPatientGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>(CaseManagementPatientGetDocument, options);
        }
export function useCaseManagementPatientGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>(CaseManagementPatientGetDocument, options);
        }
export type CaseManagementPatientGetQueryHookResult = ReturnType<typeof useCaseManagementPatientGetQuery>;
export type CaseManagementPatientGetLazyQueryHookResult = ReturnType<typeof useCaseManagementPatientGetLazyQuery>;
export type CaseManagementPatientGetSuspenseQueryHookResult = ReturnType<typeof useCaseManagementPatientGetSuspenseQuery>;
export type CaseManagementPatientGetQueryResult = Apollo.QueryResult<CaseManagementPatientGetQuery, CaseManagementPatientGetQueryVariables>;