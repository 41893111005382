import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const BlobInfoIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='76' height='76.965' viewBox='0 0 76 76.965' {...props}>
      <g id='Group_11343' data-name='Group 11343' transform='translate(-183 -333.457)'>
        <g id='Group_10686' data-name='Group 10686' transform='translate(183 333.457)'>
          <path
            id='Path_12745'
            data-name='Path 12745'
            d='M48.805,76.183C39.85,77.447,31.29,77.33,22.43,74.69c-8.782-2.651-17.849-7.747-20.989-15.3-3.062-7.562-.228-17.533,3.75-26.032A67.488,67.488,0,0,1,20.986,11.413C27.84,4.97,36.706-.9,44.679.113c7.939,1.054,14.985,8.98,20.214,16.818A63.072,63.072,0,0,1,75.232,41.169c1.634,8.739,1.412,18.479-3.39,24.7-4.756,6.25-14.093,8.976-23.037,10.319'
            transform='translate(-0.149 0)'
            fill='#25287f'
            opacity='0.05'
          />
          <path
            id='Path_12746'
            data-name='Path 12746'
            d='M37.04,75.472c-8.524-1.925-16.217-4.964-23.316-10.383C6.7,59.687.253,51.98,0,44.087c-.174-7.876,5.8-15.91,12.31-22.222A65.206,65.206,0,0,1,34.1,7.459C42.5,3.987,52.522,1.729,59.38,5.37c6.812,3.671,10.461,13.242,12.5,22.11a60.908,60.908,0,0,1,1.035,25.427c-1.519,8.451-5.057,17.169-11.525,21.138-6.44,4.014-15.809,3.277-24.348,1.427'
            transform='translate(1.15 -0.062)'
            fill='#25287f'
            opacity='0.1'
          />
        </g>
        <g id='icons8-info' transform='translate(206.969 358.061)'>
          <path
            id='Path_12990'
            data-name='Path 12990'
            d='M20.85,38.755A16.875,16.875,0,0,1,5.024,16.091a17.069,17.069,0,0,1,2.8-4.924,1.264,1.264,0,0,1,1.962,1.594,14.548,14.548,0,0,0-2.384,4.2A14.382,14.382,0,0,0,26.581,35.069a1.264,1.264,0,1,1,1.008,2.318A16.792,16.792,0,0,1,20.85,38.755Z'
            transform='translate(-4 -5.055)'
            fill='#252880'
          />
          <path
            id='Path_12991'
            data-name='Path 12991'
            d='M35.272,31.129a1.264,1.264,0,0,1-1.034-1.989A14.33,14.33,0,0,0,22.551,6.527a14.041,14.041,0,0,0-5.942,1.309,1.264,1.264,0,1,1-1.09-2.28A16.386,16.386,0,0,1,22.551,4,16.857,16.857,0,0,1,36.308,30.589,1.262,1.262,0,0,1,35.272,31.129Z'
            transform='translate(-5.701 -4)'
            fill='#252880'
          />
          <circle
            id='Ellipse_2937'
            data-name='Ellipse 2937'
            cx='1.685'
            cy='1.685'
            r='1.685'
            transform='translate(15.165 8.425)'
            fill='#25287f'
          />
          <path
            id='Line_1589'
            data-name='Line 1589'
            d='M-.236,10.295A1.264,1.264,0,0,1-1.5,9.031V-.236A1.264,1.264,0,0,1-.236-1.5,1.264,1.264,0,0,1,1.027-.236V9.031A1.264,1.264,0,0,1-.236,10.295Z'
            transform='translate(17.086 15.822)'
            fill='#252880'
          />
        </g>
      </g>
    </SvgIcon>
  );
};
