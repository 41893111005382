import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnosisValidProcedureDeleteMutationVariables = Types.Exact<{
  deleteDiagnosisValidProcedureId: Types.Scalars['ID']['input'];
}>;


export type DiagnosisValidProcedureDeleteMutation = { __typename?: 'Mutation', deleteDiagnosisValidProcedure?: { __typename?: 'DiagnosisValidProcedure', id: string } | null };


export const DiagnosisValidProcedureDeleteDocument = gql`
    mutation DiagnosisValidProcedureDelete($deleteDiagnosisValidProcedureId: ID!) {
  deleteDiagnosisValidProcedure(id: $deleteDiagnosisValidProcedureId) {
    id
  }
}
    `;
export type DiagnosisValidProcedureDeleteMutationFn = Apollo.MutationFunction<DiagnosisValidProcedureDeleteMutation, DiagnosisValidProcedureDeleteMutationVariables>;

/**
 * __useDiagnosisValidProcedureDeleteMutation__
 *
 * To run a mutation, you first call `useDiagnosisValidProcedureDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisValidProcedureDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [diagnosisValidProcedureDeleteMutation, { data, loading, error }] = useDiagnosisValidProcedureDeleteMutation({
 *   variables: {
 *      deleteDiagnosisValidProcedureId: // value for 'deleteDiagnosisValidProcedureId'
 *   },
 * });
 */
export function useDiagnosisValidProcedureDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DiagnosisValidProcedureDeleteMutation, DiagnosisValidProcedureDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DiagnosisValidProcedureDeleteMutation, DiagnosisValidProcedureDeleteMutationVariables>(DiagnosisValidProcedureDeleteDocument, options);
      }
export type DiagnosisValidProcedureDeleteMutationHookResult = ReturnType<typeof useDiagnosisValidProcedureDeleteMutation>;
export type DiagnosisValidProcedureDeleteMutationResult = Apollo.MutationResult<DiagnosisValidProcedureDeleteMutation>;
export type DiagnosisValidProcedureDeleteMutationOptions = Apollo.BaseMutationOptions<DiagnosisValidProcedureDeleteMutation, DiagnosisValidProcedureDeleteMutationVariables>;