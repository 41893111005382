import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersEmailsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.UserFilterInput>;
}>;


export type UsersEmailsAutocompleteQuery = { __typename?: 'Query', users?: { __typename?: 'UserCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'UserCountableEdge', node: { __typename?: 'User', id: string, email?: string | null } }> } | null };


export const UsersEmailsAutocompleteDocument = gql`
    query UsersEmailsAutocomplete($first: Int, $last: Int, $before: String, $after: String, $filter: UserFilterInput) {
  users(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        email
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useUsersEmailsAutocompleteQuery__
 *
 * To run a query within a React component, call `useUsersEmailsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersEmailsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersEmailsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersEmailsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>(UsersEmailsAutocompleteDocument, options);
      }
export function useUsersEmailsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>(UsersEmailsAutocompleteDocument, options);
        }
export function useUsersEmailsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>(UsersEmailsAutocompleteDocument, options);
        }
export type UsersEmailsAutocompleteQueryHookResult = ReturnType<typeof useUsersEmailsAutocompleteQuery>;
export type UsersEmailsAutocompleteLazyQueryHookResult = ReturnType<typeof useUsersEmailsAutocompleteLazyQuery>;
export type UsersEmailsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useUsersEmailsAutocompleteSuspenseQuery>;
export type UsersEmailsAutocompleteQueryResult = Apollo.QueryResult<UsersEmailsAutocompleteQuery, UsersEmailsAutocompleteQueryVariables>;