import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { healthPackageCategoriesBreadcrumb } from "../../constants";
import { CategoryUpsertForm } from "../../forms";
import { ICategoryUpsertFormValues } from "../../forms/CategoryFormUpsert/CategoryUpsertFormSchema";
import {
  MarketplaceHealthPackageCategoryUpdateMutation,
  useMarketplaceHealthPackageCategoryQuery,
  useMarketplaceHealthPackageCategoryUpdateMutation,
} from "../../gql";
import { ICategoryUpdateContainerParams } from "../../types";
import { convertCategoryFormValuesToBackEndValues } from "../../utils";

export const CategoryUpdateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const { categoryId } = useParams<ICategoryUpdateContainerParams>();

  const { data, loading } = useMarketplaceHealthPackageCategoryQuery({
    variables: { marketplaceHealthPackageCategoryId: categoryId! },
    skip: !categoryId,
  });
  const categoryData = data?.marketplaceHealthPackageCategory;
  const parent = categoryData?.parent;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchCategoryUpdateMutation, { loading: isSubmitting }] = useMarketplaceHealthPackageCategoryUpdateMutation({
    onCompleted: (data: MarketplaceHealthPackageCategoryUpdateMutation) => {
      if (data?.marketplaceHealthPackageCategoryUpdate?.id) {
        succeeded(t("Category updated successfully!"));
        navigate(-1);
        // !parent?.id
        //   ? navigate(healthPackageCategoriesPaths.main.fullPath)
        //   : navigate(healthPackageCategoriesPaths.healthPackageCategoryCategories.fullPathWithParams({ categoryId: parent?.id }));
      } else {
        failed(t("Category update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onCategoryUpsertFormChange = (values: ICategoryUpsertFormValues) => {
    const _values = convertCategoryFormValuesToBackEndValues(values);

    fetchCategoryUpdateMutation({
      variables: {
        marketplaceHealthPackageCategoryUpdateId: categoryId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, category } = healthPackageCategoriesBreadcrumb();
    setBreadCrumb({
      title,
      values: [
        parent?.id ? category(pickLocalizedValue(parent?.name!, parent?.nameAr!), parent?.id!) : main,
        category(pickLocalizedValue(categoryData?.name!, categoryData?.nameAr!), categoryData?.id!),
      ],
    });
  }, [setBreadCrumb, categoryData, t]);
  return (
    <CategoryUpsertForm
      isLoading={loading}
      category={categoryData!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onCategoryUpsertFormChange}
    />
  );
};
