import { makeStyles } from "@toolkit/ui";

export const useOptimaValidationResultItemStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBlock: "0px",
    paddingInlineEnd: "10px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  collapseIcon: {},
  contentContainer: {
    padding: theme.spacing(0),
  },
  divider: {
    color: theme.palette.divider,
    "&:last-child": {
      display: "none",
    },
    marginTop: theme.spacing(1.25),
  },
}));
