/* eslint-disable max-statements */
/* eslint-disable sonarjs/cognitive-complexity */
import { i18n } from "@toolkit/i18n";
import moment, { Moment } from "moment";
import { IDaysTimesRanges } from "../DaysTimesRanges/DaysTimesRanges";

export const validateDaysTimesRanges = (daysTimesRanges: IDaysTimesRanges[], format: string) => {
  return daysTimesRanges
    .map(dayData => {
      const dayErrors: { startTime?: string; endTime?: string }[] = [];

      dayData?.timeRanges.forEach((timeRange, index) => {
        const { startTime, endTime } = timeRange;
        const timesErrors: { startTime?: string; endTime?: string } = {};

        const isStartTimeValid = startTime && moment(startTime).isValid();
        const isEndTimeValid = endTime && moment(endTime).isValid();
        const isEndTimeAfterStartTime = startTime && endTime && moment(endTime).isSameOrAfter(moment(startTime));

        if (!isStartTimeValid) {
          timesErrors.startTime = startTime ? i18n.t("Invalid time") : i18n.t("Required");
        }

        if (!isEndTimeValid) {
          timesErrors.endTime = endTime ? i18n.t("Invalid time") : i18n.t("Required");
        }

        if (isStartTimeValid && isEndTimeValid && !isEndTimeAfterStartTime) {
          timesErrors.endTime = i18n.t("End time must be after ({{time}})", { time: moment(startTime).format(format) });
        }

        if (index > 0) {
          const previousEndTime = dayData.timeRanges[index - 1]?.endTime;
          const isStartTimeAfterPreviousEndTime = moment(startTime).isSameOrAfter(moment(previousEndTime));

          if (startTime && previousEndTime && !isStartTimeAfterPreviousEndTime) {
            timesErrors.startTime = i18n.t("Start time must be after ({{time}})", { time: moment(previousEndTime).format(format) });
          }
        }

        dayErrors.push(timesErrors);
      });

      return dayErrors.length > 0 ? dayErrors : null;
    })
    .filter(dayErrors => dayErrors !== null);
};

export const calculateMinTimes = (dayIndex: number, timeRangeIndex: number, daysTimesRanges: IDaysTimesRanges[]) => {
  const timeRange = daysTimesRanges[dayIndex].timeRanges[timeRangeIndex];

  let minStartTime: Moment | undefined;
  let minEndTime: Moment | undefined;

  if (timeRangeIndex === 0) {
    if (timeRange.startTime) {
      minStartTime = undefined;
    }

    if (timeRange.endTime && timeRange.startTime) {
      minEndTime = moment(timeRange.startTime).clone().add(5, "minutes");
    }
    return { minStartTime, minEndTime };
  }

  const previousTimeRange = daysTimesRanges[dayIndex].timeRanges[timeRangeIndex - 1];

  if (previousTimeRange.endTime) {
    minStartTime = moment(previousTimeRange.endTime).clone().add(5, "minutes");
  }

  if (timeRange.startTime) {
    minEndTime = moment(timeRange.startTime).clone().add(5, "minutes");
  }

  return { minStartTime, minEndTime };
};

export const isAddButtonDisabled = (
  dayIndex: number,
  daysTimesRanges: IDaysTimesRanges[],
  errors: Array<Array<{ startTime?: string; endTime?: string }>>
) => {
  const { timeRanges } = daysTimesRanges[dayIndex];

  const hasErrors = errors?.[dayIndex] && Object.values(errors[dayIndex]).some(error => error.startTime || error.endTime);

  const hasIncompleteRanges = timeRanges?.some(timeRange => !timeRange?.startTime || !timeRange?.endTime);

  const lastEndTime = timeRanges.length > 0 ? timeRanges[timeRanges.length - 1]?.endTime : null;
  const endOfDay = moment().endOf("day");
  const diffInMinutes = endOfDay.diff(moment(lastEndTime), "minutes") <= 60;

  return hasErrors || hasIncompleteRanges || diffInMinutes;
};
