import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  HealthProgramMembershipRequestApproveModal,
  HealthProgramMembershipRequestApproveModalApi,
} from "../../modals/HealthProgramMembershipRequestApprove/HealthProgramMembershipRequestApproveModal";
import { IHealthProgramMembershipRequestApproveFormEvent } from "../../forms/HealthProgramMembershipRequestApprove/HealthProgramMembershipRequestApproveForm";
import { HealthProgramMembershipRequestListDocument, useHealthProgramMembershipRequestApproveMutation } from "../../gql";
import { convertHealthProgramMembershipRequestFormValuesToBackEndValues } from "../../others";

export const HealthProgramMembershipRequestApprove = () => {
  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const [approveHealthProgramMembershipRequest, { loading }] = useHealthProgramMembershipRequestApproveMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.healthProgramMembershipRequestApprove?.healthProgramMembershipRequestErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Insurance Request Approved"));
        HealthProgramMembershipRequestApproveModalApi.close();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [
      {
        query: HealthProgramMembershipRequestListDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const onHealthProgramMembershipRequestApproveModalChange = (event: IHealthProgramMembershipRequestApproveFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramMembershipRequestFormValuesToBackEndValues(event.payload.values);

      approveHealthProgramMembershipRequest({
        variables: {
          id: event.payload.healthProgramMembershipRequestId,
          input: values,
        },
      });
    }
  };

  return <HealthProgramMembershipRequestApproveModal isLoading={loading} onChange={onHealthProgramMembershipRequestApproveModalChange} />;
};
