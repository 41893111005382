import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrackerSupplementTypeUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.TrackerSupplementTypeInput;
}>;


export type TrackerSupplementTypeUpdateMutation = { __typename?: 'Mutation', trackerSupplementTypeUpdate?: { __typename?: 'TrackerSupplementType', id: string, name: string, photo?: string | null } | null };


export const TrackerSupplementTypeUpdateDocument = gql`
    mutation TrackerSupplementTypeUpdate($id: ID!, $input: TrackerSupplementTypeInput!) {
  trackerSupplementTypeUpdate(id: $id, input: $input) {
    id
    name
    photo
  }
}
    `;
export type TrackerSupplementTypeUpdateMutationFn = Apollo.MutationFunction<TrackerSupplementTypeUpdateMutation, TrackerSupplementTypeUpdateMutationVariables>;

/**
 * __useTrackerSupplementTypeUpdateMutation__
 *
 * To run a mutation, you first call `useTrackerSupplementTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackerSupplementTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackerSupplementTypeUpdateMutation, { data, loading, error }] = useTrackerSupplementTypeUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackerSupplementTypeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TrackerSupplementTypeUpdateMutation, TrackerSupplementTypeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackerSupplementTypeUpdateMutation, TrackerSupplementTypeUpdateMutationVariables>(TrackerSupplementTypeUpdateDocument, options);
      }
export type TrackerSupplementTypeUpdateMutationHookResult = ReturnType<typeof useTrackerSupplementTypeUpdateMutation>;
export type TrackerSupplementTypeUpdateMutationResult = Apollo.MutationResult<TrackerSupplementTypeUpdateMutation>;
export type TrackerSupplementTypeUpdateMutationOptions = Apollo.BaseMutationOptions<TrackerSupplementTypeUpdateMutation, TrackerSupplementTypeUpdateMutationVariables>;