import { makeStyles } from "@toolkit/ui";

export const useMedicalFormUpsertFormStyle = makeStyles()(() => ({
  formActionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginBlock: 10,
    button: {
      padding: 15,
    },
  },
  resetFieldsButton: {
    width: 120,
  },
}));
