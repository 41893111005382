import { Box, IconButton, MuiGroupAddIcon, MuiNotificationAddIcon } from "@toolkit/ui";
import React, { FC } from "react";
import { Patient, SendBulkMessagesMethodEnum } from "@/schema/types";
import { PatientGuidedCareProgramMemberAddModal, PatientMessageSend } from "@health/domains";

type CaseManagementPatientActionsProps = {
  patient: Patient;
};

export const CaseManagementPatientActions: FC<CaseManagementPatientActionsProps> = props => {
  const { patient } = props;

  return (
    <Box display={"flex"}>
      <PatientMessageSend
        patientsUsersIds={[patient?.user?.id!]}
        method={SendBulkMessagesMethodEnum.Mobile}
        button={
          <IconButton disabled={!patient?.user?.id}>
            <MuiGroupAddIcon color={"primary"} />
          </IconButton>
        }
      />

      <IconButton disabled>
        <MuiNotificationAddIcon color={"primary"} />
      </IconButton>

      <PatientGuidedCareProgramMemberAddModal
        patient={patient}
        button={
          <IconButton disabled={!patient?.activeHealthProgramMembers || !patient?.activeHealthProgramMembers?.length}>
            <MuiGroupAddIcon color={"primary"} />
          </IconButton>
        }
      />
    </Box>
  );
};
