import { z } from "zod";

export const diagnosisValidProcedureUpsertFormSchema = z.object({
  procedureCode: z.string().min(1).max(255),
  diagnosisCode: z.string().min(1).max(255),
  description: z.string().min(3).optional(),
});
export type IDiagnosisValidProcedureUpsertFormValues = z.infer<typeof diagnosisValidProcedureUpsertFormSchema>;

export const diagnosisValidProcedureUpsertFormDefaultValues: Partial<IDiagnosisValidProcedureUpsertFormValues> = {
  procedureCode: "",
  diagnosisCode: "",
  description: "",
};
