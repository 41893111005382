import { makeStyles } from "@toolkit/ui";

export const useDecisionConditionRemoveStyle = makeStyles()(() => ({
  root: {
    ["@media (min-width:1200px)"]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  container: {
    borderRight: "none!important",
    borderBottom: "none!important",
    ["@media (min-width:1200px)"]: {
      border: "dashed 2px #CBD5E1",
    },
  },
}));
