import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { useDoctorPatientsAutocompleteQuery } from "./gql";

export type IDoctorPatientsAutocompleteFilter = {
  doctorId?: string;
  teamId?: string;
};

type DoctorPatientsAutocompleteProps = IAutocompleteProps<IDoctorPatientsAutocompleteFilter>;

export const DoctorPatientsAutocomplete: FC<DoctorPatientsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDoctorPatientsAutocompleteQuery,
    variables: { ...filter },
    skip: props.disabled || skip,
  });

  const _data = data?.map(item => item?.user!) || [];

  const options = mapToAutocompleteOptions(_data, "id", "fullName");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Members") : t("Member"))}
    />
  );
};
