/* eslint-disable @typescript-eslint/no-unused-vars */
import { useOpenState } from "@toolkit/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { safeGetFiltersSearchParams, safeSetFiltersSearchParams } from "../utils/urlStateUtils";

type UseGridFilterProps = {
  filterInput: Record<string, any>;
  hideFilterInput?: boolean;
  skipUrlState?: boolean;
  doFilter: (finalFilterValuesForBackend: Record<string, any>) => void;
  onFiltersChanged?: (
    valuesForBackend: Record<string, any>,
    finalFilterValuesForBackend: Record<string, any>,
    filtersValuesForDisplay: Record<string, any>,
    filtersFormData: Record<string, any>
  ) => void;
};

export const useGridFilter = ({
  skipUrlState = true,
  filterInput,
  hideFilterInput = true,
  doFilter,
  onFiltersChanged,
}: UseGridFilterProps) => {
  // activeFiltersDisplayValues: is used to hold the formatted filters values
  // in the shape of {...,[getItemFieldDisplayName(item)]: prepareDataToShow(item?.filter, filterFormData)}
  // , to be displayed in the active filters section.
  const [activeFiltersDisplayValues, setActiveFiltersDisplayValues] = useState<Record<string, any>>({});
  // filter values but formatted and normalized to be sent to backend as is.
  const [requestedFilters, setRequestedFilters] = useState<Record<string, any>>({});
  // filtersFormData: is used to hold the value that user entered in the form fields, to be filled again to the form when dialog open again.
  const [filtersFormData, setFiltersFormData] = useState<Record<string, any>>({});

  const { open: isOpen, handleToggle } = useOpenState();
  const navigate = useNavigate();

  const handleApplyFilters = (
    valuesForBackend: Record<string, any>,
    filtersValuesForDisplay: Record<string, any>,
    _filtersFormData: Record<string, any>
  ) => {
    const finalFilterValuesForBackend = { ...filterInput, ...valuesForBackend };
    const formattedActiveFilters = { ...filtersValuesForDisplay, ...(hideFilterInput ? {} : filterInput) };
    const formattedFilterFormData = { ..._filtersFormData };

    if (!skipUrlState) {
      const params = safeSetFiltersSearchParams({
        requested: valuesForBackend,
        form: formattedFilterFormData,
        active: formattedActiveFilters,
      });
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    }

    setRequestedFilters(finalFilterValuesForBackend);
    setFiltersFormData(formattedFilterFormData);
    setActiveFiltersDisplayValues(formattedActiveFilters);
    doFilter(finalFilterValuesForBackend);
    onFiltersChanged?.(valuesForBackend, finalFilterValuesForBackend, filtersValuesForDisplay, _filtersFormData);
  };

  const handleDismissFilters = () => {
    setActiveFiltersDisplayValues({});
  };

  const handleToggleFilters = () => {
    handleToggle();
  };

  useEffect(() => {
    !hideFilterInput && setActiveFiltersDisplayValues(hideFilterInput ? {} : filterInput);
  }, [hideFilterInput, JSON.stringify(filterInput)]);

  useEffect(() => {
    if (skipUrlState) return;
    const filters = safeGetFiltersSearchParams();
    setRequestedFilters({ ...filterInput, ...(filters?.requested || {}) });
    setActiveFiltersDisplayValues(filters?.active || {});
    setFiltersFormData(filters?.form || {});
  }, []);

  const staticFiltersProps = {
    isOpen: !!isOpen,
    skipUrlState,
    activeFilters: activeFiltersDisplayValues,
    setFiltersFormData,
    onToggleDialog: handleToggleFilters,
    onApplyFilters: handleApplyFilters,
    onDismissFilters: handleDismissFilters,
  };

  const activeFiltersProps = {
    filters: filtersFormData,
    requestedFilters,
    activeFilters: activeFiltersDisplayValues || [],
    onOpenFilters: handleToggleFilters,
  };

  return {
    staticFiltersProps,
    activeFiltersProps,
  };
};
