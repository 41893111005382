import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaRemittanceGetQueryVariables = Types.Exact<{
  optimaRemittanceId: Types.Scalars['ID']['input'];
}>;


export type OptimaRemittanceGetQuery = { __typename?: 'Query', optimaRemittance?: { __typename?: 'TransactionRemittance', id: string, dateSettlement?: string | null, status?: Types.TransactionTransactionStatus | null, source: Types.TransactionSource, paymentReference?: string | null } | null };


export const OptimaRemittanceGetDocument = gql`
    query OptimaRemittanceGet($optimaRemittanceId: ID!) {
  optimaRemittance(id: $optimaRemittanceId) {
    id
    dateSettlement
    status
    source
    paymentReference
  }
}
    `;

/**
 * __useOptimaRemittanceGetQuery__
 *
 * To run a query within a React component, call `useOptimaRemittanceGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaRemittanceGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaRemittanceGetQuery({
 *   variables: {
 *      optimaRemittanceId: // value for 'optimaRemittanceId'
 *   },
 * });
 */
export function useOptimaRemittanceGetQuery(baseOptions: Apollo.QueryHookOptions<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables> & ({ variables: OptimaRemittanceGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables>(OptimaRemittanceGetDocument, options);
      }
export function useOptimaRemittanceGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables>(OptimaRemittanceGetDocument, options);
        }
export function useOptimaRemittanceGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables>(OptimaRemittanceGetDocument, options);
        }
export type OptimaRemittanceGetQueryHookResult = ReturnType<typeof useOptimaRemittanceGetQuery>;
export type OptimaRemittanceGetLazyQueryHookResult = ReturnType<typeof useOptimaRemittanceGetLazyQuery>;
export type OptimaRemittanceGetSuspenseQueryHookResult = ReturnType<typeof useOptimaRemittanceGetSuspenseQuery>;
export type OptimaRemittanceGetQueryResult = Apollo.QueryResult<OptimaRemittanceGetQuery, OptimaRemittanceGetQueryVariables>;