import { DiagnosisValidProcedure, DiagnosisValidProcedureInput } from "@/schema/types";
import { IDiagnosisValidProcedureUpsertFormValues } from "../forms/DiagnosisValidProcedureUpsert/diagnosisValidProcedureUpsertFormSchema";

export const convertDiagnosisValidProcedureToFormValues = (
  diagnosisValidProcedure: DiagnosisValidProcedure
): IDiagnosisValidProcedureUpsertFormValues => {
  return {
    procedureCode: diagnosisValidProcedure.procedureCode,
    diagnosisCode: diagnosisValidProcedure.diagnosisCode,
    description: diagnosisValidProcedure.description ?? undefined,
  };
};

export const convertDiagnosisValidProcedureFormValuesToBackEndValues = (
  values: IDiagnosisValidProcedureUpsertFormValues
): DiagnosisValidProcedureInput => {
  return {
    procedureCode: values.procedureCode,
    diagnosisCode: values.diagnosisCode,
    description: values.description,
  };
};
