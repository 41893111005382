import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaClaimActivitiesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.TransactionClaimActivityFilterInput>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type OptimaClaimActivitiesQuery = { __typename?: 'Query', optimaClaimActivities?: { __typename?: 'TransactionClaimActivityConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'TransactionClaimActivityEdge', node?: { __typename?: 'TransactionClaimActivity', activityId: string, clinician: string, code: string, duration?: number | null, id: string, net: number, patientShare?: number | null, paymentAmount?: number | null, quantity?: number | null, start: string, priorAuthorizationID?: string | null, typeLookup?: { __typename?: 'TransactionActivityTypeLookup', name?: string | null, type?: number | null } | null, codeLookup?: { __typename?: 'TransactionActivityCodeLookup', name?: string | null } | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const OptimaClaimActivitiesDocument = gql`
    query OptimaClaimActivities($filter: TransactionClaimActivityFilterInput, $after: String, $before: String, $first: Int, $last: Int) {
  optimaClaimActivities(
    filter: $filter
    after: $after
    before: $before
    first: $first
    last: $last
  ) {
    edges {
      node {
        activityId
        clinician
        code
        duration
        id
        net
        patientShare
        paymentAmount
        quantity
        start
        priorAuthorizationID
        typeLookup {
          name
          type
        }
        codeLookup {
          name
        }
      }
    }
    totalCount
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useOptimaClaimActivitiesQuery__
 *
 * To run a query within a React component, call `useOptimaClaimActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaClaimActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaClaimActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOptimaClaimActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>(OptimaClaimActivitiesDocument, options);
      }
export function useOptimaClaimActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>(OptimaClaimActivitiesDocument, options);
        }
export function useOptimaClaimActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>(OptimaClaimActivitiesDocument, options);
        }
export type OptimaClaimActivitiesQueryHookResult = ReturnType<typeof useOptimaClaimActivitiesQuery>;
export type OptimaClaimActivitiesLazyQueryHookResult = ReturnType<typeof useOptimaClaimActivitiesLazyQuery>;
export type OptimaClaimActivitiesSuspenseQueryHookResult = ReturnType<typeof useOptimaClaimActivitiesSuspenseQuery>;
export type OptimaClaimActivitiesQueryResult = Apollo.QueryResult<OptimaClaimActivitiesQuery, OptimaClaimActivitiesQueryVariables>;