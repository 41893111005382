import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type OptimaPriorRequestNodeFragmentFragment = { __typename?: 'TransactionPriorRequest', id: string, transactionId: string, transactionDate: string, transactionType?: string | null, encounterStart?: string | null, encounterStartType?: number | null, encounterEnd?: string | null, encounterType?: string | null, patientId?: string | null, memberId?: string | null, emiratesIDNumber?: string | null, facilityId?: string | null, payerId?: string | null, receiverId?: string | null, gross?: number | null, net?: number | null, source: Types.TransactionSource, result?: Types.TransactionAuthorizationResult | null, Branch?: { __typename?: 'Branch', nameAr?: string | null, name: string } | null, priorAuthorization?: { __typename?: 'TransactionPriorAuthorization', id: string, hasClaimActivities?: boolean | null, status?: Types.TransactionTransactionStatus | null, comments?: string | null, denialCode?: string | null, end: string, start: string, idPayer?: string | null, limit?: string | null, result?: string | null } | null, sender?: { __typename?: 'TransactionProviderLookup', name?: string | null, id: string } | null, encounterTypeLookup?: { __typename?: 'TransactionEncounterTypeLookup', name?: string | null, type?: number | null } | null, facility?: { __typename?: 'TransactionFacilitylookup', id: string, name?: string | null } | null, payer?: { __typename?: 'TransactionPayerLookup', id: string, name?: string | null } | null, receiver?: { __typename?: 'TransactionPayerLookup', id: string, name?: string | null } | null };

export const OptimaPriorRequestNodeFragmentFragmentDoc = gql`
    fragment OptimaPriorRequestNodeFragment on TransactionPriorRequest {
  id
  transactionId
  transactionDate
  transactionType
  encounterStart
  encounterStartType
  encounterEnd
  encounterType
  patientId
  memberId
  emiratesIDNumber
  facilityId
  payerId
  receiverId
  gross
  net
  source
  Branch {
    nameAr
    name
  }
  priorAuthorization {
    id
    hasClaimActivities
    status
    comments
    denialCode
    end
    start
    idPayer
    limit
    result
  }
  sender {
    name
    id
  }
  encounterTypeLookup {
    name
    type
  }
  facility {
    id
    name
  }
  payer {
    id
    name
  }
  receiver {
    id
    name
  }
  result
}
    `;