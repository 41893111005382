import { FC } from "react";
import { SvgIcon, SvgIconProps, useTheme } from "../../base/mui";

export const Provider: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='20.278' height='19.434' viewBox='0 0 20.278 19.434' {...props}>
      <path
        id='icons8-out-patient-department_1_'
        d='M11.124,1a.423.423,0,0,0-.246.09L2.445,7.69l-1.284,1a.423.423,0,1,0,.521.665L2.267,8.9V20.01a.422.422,0,0,0,.422.422h16.9a.422.422,0,0,0,.422-.422V8.9l.584.457a.423.423,0,0,0,.521-.665l-1.261-.986L11.4,1.09A.423.423,0,0,0,11.124,1Zm.015.959L19.166,8.24V19.588H3.112V8.24ZM8.6,6.069a.845.845,0,0,0-.73.422H7.8a1.305,1.305,0,0,0-1.308,1.29v2.15a3.31,3.31,0,0,0,2.957,3.235V15.3a2.958,2.958,0,1,0,5.914,0q0-.01,0-.02a1.621,1.621,0,0,0,.845-.452,1.779,1.779,0,0,0,0-2.3,1.76,1.76,0,0,0-2.533,0,1.779,1.779,0,0,0,0,2.3,1.621,1.621,0,0,0,.845.452q0,.01,0,.02a2.113,2.113,0,1,1-4.225,0v-2.13a3.31,3.31,0,0,0,2.957-3.235V7.781a1.305,1.305,0,0,0-1.308-1.29h-.073a.846.846,0,1,0,0,.845h.074a.448.448,0,0,1,.463.445v2.15A2.5,2.5,0,0,1,9.895,12.4H9.848a2.5,2.5,0,0,1-2.511-2.47V7.781A.448.448,0,0,1,7.8,7.337h.073A.845.845,0,1,0,8.6,6.069Zm6.337,6.759a.778.778,0,0,1,.635.254.935.935,0,0,1,0,1.182.919.919,0,0,1-1.269,0,.935.935,0,0,1,0-1.182A.778.778,0,0,1,14.941,12.829Z'
        transform='translate(-1 -0.999)'
        fill={props.fill || palette.primary.main}
      />
    </SvgIcon>
  );
};
