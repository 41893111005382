import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayerActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type PayerActivationMutation = { __typename?: 'Mutation', payerActiveStatusUpdate?: { __typename?: 'PayerActiveStatusUpdate', payerErrors: Array<{ __typename?: 'PayerError', field?: string | null, message?: string | null }> } | null };


export const PayerActivationDocument = gql`
    mutation PayerActivation($id: ID!) {
  payerActiveStatusUpdate(id: $id) {
    payerErrors {
      field
      message
    }
  }
}
    `;
export type PayerActivationMutationFn = Apollo.MutationFunction<PayerActivationMutation, PayerActivationMutationVariables>;

/**
 * __usePayerActivationMutation__
 *
 * To run a mutation, you first call `usePayerActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayerActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payerActivationMutation, { data, loading, error }] = usePayerActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayerActivationMutation(baseOptions?: Apollo.MutationHookOptions<PayerActivationMutation, PayerActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayerActivationMutation, PayerActivationMutationVariables>(PayerActivationDocument, options);
      }
export type PayerActivationMutationHookResult = ReturnType<typeof usePayerActivationMutation>;
export type PayerActivationMutationResult = Apollo.MutationResult<PayerActivationMutation>;
export type PayerActivationMutationOptions = Apollo.BaseMutationOptions<PayerActivationMutation, PayerActivationMutationVariables>;