import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { patientGuidedCareProgramEvaluationFieldsFormSchema } from "pages/Patients/forms/PatientGuidedCareProgramEvaluationFields/PatientGuidedCareProgramEvaluationFieldsFormSchema";
import { createZodAutocomplete } from "@toolkit/core";

export const patientGuidedCareProgramMemberAddFormSchema = z.object({
  program: zodSchema.guidedCareProgram,
  riskFactorTemplateFields: z.array(patientGuidedCareProgramEvaluationFieldsFormSchema),
  suggestedProgram: createZodAutocomplete(
    z.object({
      id: z.string(),
      name: z.string().nullish(),
      allowDoctorDirectEnrollment: z.boolean(),
    })
  ).nullish(),
  team: zodSchema.guidedCareTeam.nullish(),
});

export type IPatientGuidedCareProgramMemberAddForm = z.infer<typeof patientGuidedCareProgramMemberAddFormSchema>;

export const patientGuidedCareProgramMemberAddFormDefaultValues: Partial<IPatientGuidedCareProgramMemberAddForm> = {
  program: undefined,
  riskFactorTemplateFields: [],
  suggestedProgram: undefined,
  team: undefined,
};
