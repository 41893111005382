import { OrderDirection, PatientSortField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { CaseManagementPatientNotesDrawer } from "../../components/CaseManagementPatientNotesDrawer/CaseManagementPatientNotesDrawer";
import { useCaseManagementPatientListQuery } from "../../gql";
import { useSetCaseManagementsBreadcrumbs } from "../../hooks";
import { useCaseManagementPatientListColumns } from "./useCaseManagementPatientListColumns";

export const CaseManagementPatientListContainer = () => {
  useSetCaseManagementsBreadcrumbs("LIST");

  return (
    <>
      <CaseManagementPatientNotesDrawer />

      <GridProvider
        gridName={"caseManagementPatientList"}
        query={useCaseManagementPatientListQuery}
        columns={useCaseManagementPatientListColumns()}
        hasTableSetting
        variables={{
          sortBy: {
            direction: OrderDirection.Desc,
            field: PatientSortField.CreatedDate,
          },
        }}
        skipUrlState={false}
      >
        <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
          <TableGrid />
        </PageWrapper>
      </GridProvider>
    </>
  );
};
