import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerSupportErxOrderStatusUpdateMutationVariables = Types.Exact<{
  customerSupportErxOrderStatusUpdateId: Types.Scalars['ID']['input'];
  status: Types.CustomerSupportOrderStatusEnum;
}>;


export type CustomerSupportErxOrderStatusUpdateMutation = { __typename?: 'Mutation', customerSupportErxOrderStatusUpdate?: { __typename?: 'Order', id: string, status: Types.OrderStatus } | null };


export const CustomerSupportErxOrderStatusUpdateDocument = gql`
    mutation CustomerSupportErxOrderStatusUpdate($customerSupportErxOrderStatusUpdateId: ID!, $status: CustomerSupportOrderStatusEnum!) {
  customerSupportErxOrderStatusUpdate(
    orderId: $customerSupportErxOrderStatusUpdateId
    status: $status
  ) {
    id
    status
  }
}
    `;
export type CustomerSupportErxOrderStatusUpdateMutationFn = Apollo.MutationFunction<CustomerSupportErxOrderStatusUpdateMutation, CustomerSupportErxOrderStatusUpdateMutationVariables>;

/**
 * __useCustomerSupportErxOrderStatusUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerSupportErxOrderStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSupportErxOrderStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSupportErxOrderStatusUpdateMutation, { data, loading, error }] = useCustomerSupportErxOrderStatusUpdateMutation({
 *   variables: {
 *      customerSupportErxOrderStatusUpdateId: // value for 'customerSupportErxOrderStatusUpdateId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCustomerSupportErxOrderStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CustomerSupportErxOrderStatusUpdateMutation, CustomerSupportErxOrderStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerSupportErxOrderStatusUpdateMutation, CustomerSupportErxOrderStatusUpdateMutationVariables>(CustomerSupportErxOrderStatusUpdateDocument, options);
      }
export type CustomerSupportErxOrderStatusUpdateMutationHookResult = ReturnType<typeof useCustomerSupportErxOrderStatusUpdateMutation>;
export type CustomerSupportErxOrderStatusUpdateMutationResult = Apollo.MutationResult<CustomerSupportErxOrderStatusUpdateMutation>;
export type CustomerSupportErxOrderStatusUpdateMutationOptions = Apollo.BaseMutationOptions<CustomerSupportErxOrderStatusUpdateMutation, CustomerSupportErxOrderStatusUpdateMutationVariables>;