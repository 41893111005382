import { Type } from "@/schema/types";
import { FieldUIComponent } from "../types";

export const getConceptPropertyValue = <
  T extends {
    fieldType?: null | Type;
    valueString?: string | null;
    valueBoolean?: boolean | null;
    valueFloat?: number | null;
    valueInteger?: number | null;
    valueDate?: any | null;
  }
>(
  item?: null | T
) => {
  switch (item?.fieldType) {
    case Type.String:
      return item?.valueString;
    case Type.Boolean:
      return item?.valueBoolean ? "Yes" : "No";
    case Type.Float:
      return item?.valueFloat;
    case Type.Integer:
      return item?.valueInteger;
    case Type.Date:
      return item?.valueDate;
    default:
      return "-";
  }
};

export const getInputType = <T extends { fieldType?: Type | null }>(item?: T | null) => {
  switch (item?.fieldType) {
    case Type.String:
      return "string";
    case Type.Boolean:
      return "autocomplete";

    case Type.Float:
    case Type.Integer:
      return "number";

    case Type.Date:
      return "date";

    default:
      return "string";
  }
};

export const getFieldType = <T extends { fieldType?: Type | null }>(item?: T | null): FieldUIComponent => {
  switch (item?.fieldType) {
    case Type.String:
      return FieldUIComponent.input;
    case Type.Boolean:
      return FieldUIComponent.autocomplete;
    case Type.Date:
      return FieldUIComponent.date;
    default:
      return FieldUIComponent.input;
  }
};

export const getFilterValuePropertyName = (fieldType?: Type | null) => {
  switch (fieldType) {
    case Type.String:
      return "valueString";
    case Type.Boolean:
      return "valueBoolean";
    case Type.Float:
      return "valueFloat";
    case Type.Integer:
      return "valueInteger";
    case Type.Date:
      return "valueDate";
    default:
      return "valueString";
  }
};
