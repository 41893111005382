import { i18n } from "@toolkit/i18n";
import { MarketplaceOrderType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const marketplaceOrderTypeOptionsMap: IEnumToOptionsMap<MarketplaceOrderType> = {
  [MarketplaceOrderType.HealthPackage]: {
    key: MarketplaceOrderType.HealthPackage,
    get label() {
      return i18n.t("Health Package", { ns: "domains" });
    },
    value: MarketplaceOrderType.HealthPackage,
  },
  [MarketplaceOrderType.Product]: {
    key: MarketplaceOrderType.Product,
    get label() {
      return i18n.t("Health Product", { ns: "domains" });
    },
    value: MarketplaceOrderType.Product,
  },
};

export const marketplaceOrderTypeOptions = Object.values(marketplaceOrderTypeOptionsMap);
