import { Decision, DecisionPlan, DecisionPlanCategory, Field } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { FC, FormEvent, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { createDecisionPlanAutocompleteOption } from "@health/autocompletes";
import { DecisionEffectsForm } from "@/pages/Decisions/forms/DecisionEffects/DecisionEffectsForm";
import { DecisionConditionsGroupsForm } from "../DecisionConditionsGroups/DecisionConditionsGroupsForm";
import { DecisionInformationForm } from "../DecisionInformation/DecisionInformationForm";
import { useDecisionHealthParameterRiskParameterGetQuery, useDecisionPlanGetQuery } from "../../gql";
import { convertDecisionPlanToFormValues, convertDecisionToFormValues } from "../../others/utils";
import { decisionUpsertFormSchema, IDecisionUpsertFormValues, decisionUpsertFormSchemaDefaultValues } from "./DecisionUpsertFormSchema";

export type IDecisionUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IDecisionUpsertFormValues;
    decisionPlan: DecisionPlan;
  };
};

type DecisionUpsertFormProps = {
  buttonLabel: string;
  category: DecisionPlanCategory;
  decision?: Decision;
  guidedCareTemplateCode?: string;
  healthParameterCode?: string;
  ioAgentCode?: string;
  selectedDecisionPlan?: DecisionPlan;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  onChange: (event: IDecisionUpsertFormEvent) => void;
};

export const DecisionUpsertForm: FC<DecisionUpsertFormProps> = props => {
  const {
    buttonLabel,
    decision,
    category,
    guidedCareTemplateCode,
    healthParameterCode,
    ioAgentCode,
    selectedDecisionPlan,
    isLoading,
    isButtonDisabled,
    onChange,
  } = props;

  const { t } = useTranslation("domains");

  const form = useCustomForm<IDecisionUpsertFormValues>({
    defaultValues: decisionUpsertFormSchemaDefaultValues,
    schema: decisionUpsertFormSchema,
  });

  const { watch, setValue, setValues, clearErrors, handleSubmit } = form;

  const selectedDecisionPlanId = watch("decisionPlan")?.value?.id!;
  const isScript = watch("isScript");

  const { data, loading: isDecisionPlanLoading } = useDecisionPlanGetQuery({
    variables: {
      getPlanId: selectedDecisionPlanId,
    },
    fetchPolicy: "no-cache",
    skip: !selectedDecisionPlanId,
  });

  const decisionPlan = data?.getPlan as DecisionPlan;

  const { data: decisionHealthParameterRiskParameterData, loading: isDecisionHealthParameterRiskParameterLoading } =
    useDecisionHealthParameterRiskParameterGetQuery({
      variables: {
        filter: {
          code: [healthParameterCode!],
        },
      },
      fetchPolicy: "no-cache",
      skip: !healthParameterCode || !guidedCareTemplateCode,
    });

  const healthRiskParameter = decisionHealthParameterRiskParameterData?.getHealthParameters?.edges?.[0]?.node?.riskParameter;

  const isQueriesLoading = isDecisionPlanLoading || isDecisionHealthParameterRiskParameterLoading;

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    handleSubmit(onSubmit)(event);
  };

  const onSubmit = (values: IDecisionUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values, decisionPlan },
    });
  };

  useEffect(() => {
    if (selectedDecisionPlan) {
      const _selectedDecisionPlan = createDecisionPlanAutocompleteOption(selectedDecisionPlan);
      setValue("decisionPlan", _selectedDecisionPlan);
    }
  }, [selectedDecisionPlan]);

  useEffect(() => {
    if (!decision && decisionPlan) {
      clearErrors();

      const _decisionPlan = convertDecisionPlanToFormValues(decisionPlan, healthParameterCode, healthRiskParameter);
      setValues({ ..._decisionPlan, name: watch("name") || _decisionPlan.name });
    }
  }, [decision, decisionPlan, healthParameterCode, healthRiskParameter]);

  useEffect(() => {
    if (decision) {
      const _decision = convertDecisionToFormValues(decision, guidedCareTemplateCode, healthParameterCode, ioAgentCode);
      setValues(_decision);
    }
  }, [decision, guidedCareTemplateCode, healthParameterCode, ioAgentCode]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleFormSubmit}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel={!(guidedCareTemplateCode || healthParameterCode || ioAgentCode)}
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={2} paddingBottom={"100px"}>
            <Grid item xs={12}>
              <FormCard title={t("Rule Information")} loading={isLoading} doYouHaveData>
                <DecisionInformationForm category={category} isScript={isScript} isUpdateMode={!!decision} />
              </FormCard>
            </Grid>

            {!decisionPlan || isQueriesLoading ? (
              <Grid item xs={12}>
                <FormCard title={t("Rule Conditions and Effects")} loading={isQueriesLoading} doYouHaveData>
                  <Typography> {t("Select a plan to create a rule")} </Typography>
                </FormCard>
              </Grid>
            ) : (
              <>
                {!isScript && (
                  <Grid item xs={12}>
                    <DecisionConditionsGroupsForm decisionPlanFields={decisionPlan?.field as Field[]} />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <FormCard title={t("Rule Effects")} loading={isLoading || isQueriesLoading} doYouHaveData>
                    <DecisionEffectsForm />
                  </FormCard>
                </Grid>
              </>
            )}
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
