import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { MedicalFormFilter } from "../../schema/types";
import { useMedicalFormsClassificationsAutocompleteQuery } from "./gql";

type MedicalFormsClassificationsAutocompleteProps = IAutocompleteProps<MedicalFormFilter>;

export const MedicalFormsClassificationsAutocomplete: FC<MedicalFormsClassificationsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useMedicalFormsClassificationsAutocompleteQuery,
    variables: { filter },
    searchKey: "search",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => `${item?.code} - ${pickLocalizedValue(item?.name!, item?.nameAr!)}`);

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Medical Forms") : t("Medical Form"))}
    />
  );
};
