import { createPathDefinition } from "@toolkit/core";
import { optimaMedicalNecessityPath } from "../../constants/OptimaMedicalNecessityPaths";

export const itemCodeMaximumQuantityAllowedsRoute = optimaMedicalNecessityPath + "/item-code-maximum-quantity-allowed";

export const itemCodeMaximumQuantityAllowedsPaths = createPathDefinition(itemCodeMaximumQuantityAllowedsRoute, {
  list: "",
  create: "new",
  update: ":itemCodeMaximumQuantityAllowedId",
});
