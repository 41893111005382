import { DigitalTwinComplicationCategory } from "@health/enum-options";
import { Theme } from "@toolkit/ui";

export const getDigitalTwinCategoryColor = (complicationCategory: string, theme: Theme) => {
  if (complicationCategory === DigitalTwinComplicationCategory.Diagnosed) {
    return theme.palette.info.main;
  } else if (complicationCategory === DigitalTwinComplicationCategory.HighRisk) {
    return theme.palette.error.main;
  } else if (complicationCategory === DigitalTwinComplicationCategory.MidRisk) {
    return theme.palette.secondary.main;
  } else if (complicationCategory === DigitalTwinComplicationCategory.LowRisk) {
    return theme.palette.success.main;
  }

  return theme.palette.primary.main;
};
