import { IAutocompleteFilter, createAutocompleteOption, getAutocompleteFilter } from "@toolkit/ui";
import { DecisionPlanFilterInput } from "../../schema/types";
import { useDecisionPlansAutocompleteQuery } from "./gql";
import { IDecisionPlanAutocomplete } from "./schema";

export const createDecisionPlanAutocompleteOption = (decisionPlan: IDecisionPlanAutocomplete) => {
  return createAutocompleteOption(
    {
      id: decisionPlan?.id!,
      code: decisionPlan?.code!,
      display: decisionPlan?.display!,
    },
    "id",
    "display"
  );
};

export const getDecisionPlansAutocompleteFilter = ({
  name,
  filter,
  multiple,
}: IAutocompleteFilter & {
  filter: DecisionPlanFilterInput;
}) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "getPlans",
    query: useDecisionPlansAutocompleteQuery,
    labelBy: option => option?.display! || option?.code!,
    backendAccessor: "id",
    filtersForQuery: filter,
    filterSearchKey: "planCode",
  });
};
