import React, { FC } from "react";
import { Box, CircularProgress, Divider, Grid } from "@toolkit/ui";
import { useCaseManagementPatientDigitalTwinGetQuery } from "../../gql";
import { CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetes } from "../CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetes/CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetes";
import { CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertension } from "../CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertension/CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertension";
import { useCaseManagementPatientDigitalTwinStyle } from "./useCaseManagementPatientDigitalTwinStyle";

type CaseManagementPatientDigitalTwinProps = {
  nationalId: string;
};

export const CaseManagementPatientDigitalTwin: FC<CaseManagementPatientDigitalTwinProps> = props => {
  const { nationalId } = props;

  const { classes } = useCaseManagementPatientDigitalTwinStyle();

  const { data, loading } = useCaseManagementPatientDigitalTwinGetQuery({
    variables: {
      patientId: nationalId,
    },
    skip: !nationalId,
  });

  const diagnosticRiskScores = data?.patientDashboard?.diagnosticRiskScores;
  const prognosticRiskScores = data?.patientDashboard?.prognosticRiskScores;

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Grid item xs={12}>
          <Box className={classes.loadingWrapper}>
            <CircularProgress size={40} />
          </Box>
        </Grid>
      ) : (
        <>
          <Grid item xs={5.75}>
            <CaseManagementPatientDigitalTwinPrognosticRiskScoreDiabetes
              category={diagnosticRiskScores?.categoryDiabetes!}
              value={diagnosticRiskScores?.diabetes!}
              complications={prognosticRiskScores?.diabetesComplications}
            />
          </Grid>

          <Grid item xs={0.5} className={classes.grid}>
            <Box className={classes.dividerWrapper}>
              <Divider orientation={"vertical"} />
            </Box>
          </Grid>

          <Grid item xs={5.75} className={classes.grid}>
            <CaseManagementPatientDigitalTwinPrognosticRiskScoreHypertension
              category={diagnosticRiskScores?.categoryHypertension!}
              value={diagnosticRiskScores?.asthma!}
              complications={prognosticRiskScores?.hypertensionComplications}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
