import { HealthProgramMemberList } from "@/schema/types";
import { PayersAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { convertHealthProgramMemberListToFormValues } from "../../others";
import {
  healthProgramMemberListUpsertFormDefaultValues,
  healthProgramMemberListUpsertFormSchema,
  IHealthProgramMemberListUpsertFormValues,
} from "./HealthProgramMemberListUpsertFormSchema";

export type IHealthProgramMemberListUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthProgramMemberListUpsertFormValues;
  };
};

type HealthProgramMemberListUpsertFormProps = {
  healthProgramMemberList?: HealthProgramMemberList;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IHealthProgramMemberListUpsertFormEvent) => void;
};

export const HealthProgramMemberListUpsertForm: FC<HealthProgramMemberListUpsertFormProps> = props => {
  const { healthProgramMemberList, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IHealthProgramMemberListUpsertFormValues>({
    defaultValues: healthProgramMemberListUpsertFormDefaultValues,
    schema: healthProgramMemberListUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IHealthProgramMemberListUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (healthProgramMemberList) {
      const _healthProgramMemberList = convertHealthProgramMemberListToFormValues(healthProgramMemberList);
      setValues(_healthProgramMemberList);
    }
  }, [healthProgramMemberList, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <FormCard title={t("Member List Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
                  </Grid>

                  <Grid item xs={3}>
                    <PayersAutocomplete name={"payer"} filter={{ isActive: true }} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={4} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormSwitch name={"isActive"} label={t("Is Active")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
