import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { cptHcpcPrimaryProcedurePaths } from "../constants";

export const useSetCptHcpcPrimaryProcedureBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("CPT HCPC Primary Procedure");

  const cptHcpcPrimaryProcedureListBreadcrumb = useMemo(() => {
    return {
      name: t("CPT HCPC Primary Procedure"),
      route: cptHcpcPrimaryProcedurePaths.list.fullPath,
    };
  }, [t]);

  const setCptHcpcPrimaryProcedureListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setCptHcpcPrimaryProcedureCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [cptHcpcPrimaryProcedureListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, cptHcpcPrimaryProcedureListBreadcrumb, t]);

  const setCptHcpcPrimaryProcedureUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [cptHcpcPrimaryProcedureListBreadcrumb, { name: name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, cptHcpcPrimaryProcedureListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setCptHcpcPrimaryProcedureListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setCptHcpcPrimaryProcedureCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setCptHcpcPrimaryProcedureUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setCptHcpcPrimaryProcedureListContainerBreadcrumb,
    setCptHcpcPrimaryProcedureCreateContainerBreadcrumb,
    setCptHcpcPrimaryProcedureUpdateContainerBreadcrumb,
  ]);
};
