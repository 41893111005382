import { OperationVariables } from "@apollo/client";
import { merge } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageInfo } from "../utils/countableConnection";
import * as urlStateUtils from "../utils/urlStateUtils";

export const useDataGridVariablesState = <TVar extends OperationVariables = Record<string, any>>({
  input,
  skipUrlState,
}: {
  input?: TVar;
  skipUrlState?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getSearchParamsVariables = useCallback(() => {
    if (skipUrlState)
      return {
        after: undefined,
        filter: undefined,
        sortBy: undefined,
      };
    return urlStateUtils.getSearchParamsVariables();
  }, [location.pathname]);

  const [variables, setVariables] = useState<TVar>(() => {
    if (skipUrlState) return input as TVar;
    const x = merge({}, input);
    return merge(x, { ...getSearchParamsVariables() });
  });

  const updatePaginationParams = useCallback(
    (startCursor: PageInfo["startCursor"]) => {
      if (skipUrlState) return;
      const params = urlStateUtils.safeSetEncodedSearchParam("cursor", startCursor);
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      });
    },
    [navigate, location.pathname, location.search]
  );

  useEffect(() => {
    setVariables(state => {
      if (skipUrlState) return input as TVar;
      const x = merge({}, input);
      return {
        ...merge(state, x),
      };
    });
  }, [JSON.stringify(input)]);

  return {
    variables,
    setVariables,
    updatePaginationParams,
  };
};
