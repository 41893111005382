import { QuestionType, Survey, SurveyInput, SurveyStatus } from "@/schema/types";
import { toPascalCase } from "@toolkit/core";
import { Palette } from "@toolkit/ui";
import moment from "moment";
import { ISurvey } from "../types";
import { ISurveyUpsertFormValues } from "../forms/SurveyUpsert/surveyUpsertFormSchema";
import { ISurveyQuestionFormValues } from "../forms/SurveyQuestions/SurveyQuestionFormSchema";
import { questionTypeOptionsMap } from "@health/enum-options";

export const getSurveyStatusColor = (status: Survey["status"], palette: Palette) => {
  switch (status) {
    case SurveyStatus.Cancelled:
      return palette.warning.main;
    case SurveyStatus.Draft:
      return palette.stale.main;
    case SurveyStatus.Expired:
      return palette.error.main;
    case SurveyStatus.Published:
      return palette.primary.dark;
    case SurveyStatus.Unpublished:
      return palette.primary.main;
    default:
      return palette.primary.main;
  }
};

export const getSurveyStatusText = (status: Survey["status"]) => toPascalCase(status?.toLowerCase() ?? "");

export const getLastPublishedCriteria = (publishCriteria: Survey["publishCriteria"]) => {
  let lastCriteria = publishCriteria?.[0];
  publishCriteria?.forEach(criteria => {
    if (moment(criteria?.createdDate).isAfter(lastCriteria?.createdDate)) {
      lastCriteria = criteria;
    }
  }) || [];
  return lastCriteria;
};

export const shouldQuestionHaveOptions = (questionType: QuestionType): boolean => {
  return questionType !== QuestionType.Boolean && questionType !== QuestionType.String;
};

export const convertQuestionsToFormValues = (questions: ISurvey["questions"] = []): ISurveyQuestionFormValues[] => {
  return (
    questions?.map(question => {
      return {
        id: question.id ?? "",
        question: question.question ?? "",
        questionType: questionTypeOptionsMap[question.questionType ?? QuestionType.String],
        required: question.required ?? false,
        options: question.options?.map(option => ({ value: option ?? "" })) ?? [],
      };
    }) || []
  );
};

export const convertQuestionsFormValuesToBackEndValues = (values: ISurveyQuestionFormValues[]): SurveyInput["questions"] => {
  return values?.map(value => {
    return {
      question: value.question,
      questionType: value?.questionType?.value!,
      required: value.required,
      options: value.options.map(option => option.value),
    };
  });
};

export const convertSurveyToFormValues = (survey: ISurvey): ISurveyUpsertFormValues => {
  return {
    description: survey?.description || "",
    notification: survey?.notification || "",
    label: survey?.label || "",
    questions: convertQuestionsToFormValues(survey?.questions),
  };
};

export const convertSurveyFormValuesToBackEndValues = (values: ISurveyUpsertFormValues): SurveyInput => {
  return {
    description: values.description,
    notification: values.notification,
    label: values.label,
    questions: convertQuestionsFormValuesToBackEndValues(values.questions),
  };
};
