import { Maybe } from "@/schema/types";
import { convertToTitleCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, Grid, Typography, useTheme } from "@toolkit/ui";
import moment from "moment-timezone";
import { PrescriptionsAdminRowData } from "@/pages/OrdersTracking/types";
import { FC } from "react";

export const OrderDetails: FC<{ order?: PrescriptionsAdminRowData }> = ({ order }) => {
  const { t } = useTranslation("domains");
  const userTz = moment.tz.guess();
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Typography component='span' fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.md}>
        {t("Order Details")}
      </Typography>
      <Grid container columnSpacing={{ sx: 4, md: 8 }}>
        <Grid item xs={4}>
          <OrderSection label={t("eRx Number")} value={order?.prescription?.referenceNumber} />
        </Grid>
        <Grid item xs={4}>
          <OrderSection label={t("Order Number")} value={order?.number} />
        </Grid>
        <Grid item xs={4}>
          <OrderSection label={t("Order Status")} value={t(convertToTitleCase(order?.status!))} />
        </Grid>
        <Grid item xs={4}>
          <OrderSection label={t("Order Date")} value={order?.created ? moment(order?.created).format("DD MMM YYYY") : "-"} />
        </Grid>
        <Grid item xs={4}>
          <OrderSection label={t("Order Time")} value={order?.created ? moment(order?.created).tz(userTz).format("hh:mm a") : "-"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export const OrderSection: FC<{ label: string; value?: Maybe<string> }> = ({ label, value = "" }) => {
  const theme = useTheme();
  return (
    <Box marginTop='8px' marginBottom='8px'>
      <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
        {label}:
      </Typography>
      <Typography fontSize={theme.mixins.fonts.fontSize.sm}>{value}</Typography>
    </Box>
  );
};
