import { UserFilterInput } from "@/schema/types";
import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { useUsersEmailsAutocompleteQuery } from "./gql";

type UsersEmailsAutocompleteProps = IAutocompleteProps<UserFilterInput>;

export const UsersEmailsAutocomplete: FC<UsersEmailsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useUsersEmailsAutocompleteQuery,
    variables: { filter },
    searchKey: "search",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "email");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Users") : t("User"))}
    />
  );
};
