import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CallbackRequestsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filter?: Types.InputMaybe<Types.CallbackRequestFilterInput>;
}>;


export type CallbackRequestsQuery = { __typename?: 'Query', callbackRequests: { __typename?: 'CallbackRequestCountableConnection', edges: Array<{ __typename?: 'CallbackRequestCountableEdge', cursor: string, node: { __typename?: 'CallbackRequest', id: string, patientNotes?: string | null, serviceName?: string | null, status?: Types.CallbackRequestStatus | null, comments?: Array<string | null> | null, created?: any | null, patient?: { __typename?: 'Patient', contactNumber?: string | null, email?: string | null, id: string } | null, user?: { __typename?: 'User', fullName?: string | null } | null } }>, pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasPreviousPage: boolean, hasNextPage: boolean, startCursor?: string | null } } };


export const CallbackRequestsDocument = gql`
    query CallbackRequests($after: String, $before: String, $first: Int, $last: Int, $filter: CallbackRequestFilterInput) {
  callbackRequests(
    after: $after
    before: $before
    first: $first
    last: $last
    filter: $filter
  ) {
    edges {
      cursor
      node {
        id
        patient {
          contactNumber
          email
          id
        }
        patientNotes
        serviceName
        status
        user {
          fullName
        }
        comments
        created
      }
    }
    pageInfo {
      endCursor
      hasPreviousPage
      hasNextPage
      startCursor
    }
  }
}
    `;

/**
 * __useCallbackRequestsQuery__
 *
 * To run a query within a React component, call `useCallbackRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallbackRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallbackRequestsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCallbackRequestsQuery(baseOptions?: Apollo.QueryHookOptions<CallbackRequestsQuery, CallbackRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CallbackRequestsQuery, CallbackRequestsQueryVariables>(CallbackRequestsDocument, options);
      }
export function useCallbackRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CallbackRequestsQuery, CallbackRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CallbackRequestsQuery, CallbackRequestsQueryVariables>(CallbackRequestsDocument, options);
        }
export function useCallbackRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CallbackRequestsQuery, CallbackRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CallbackRequestsQuery, CallbackRequestsQueryVariables>(CallbackRequestsDocument, options);
        }
export type CallbackRequestsQueryHookResult = ReturnType<typeof useCallbackRequestsQuery>;
export type CallbackRequestsLazyQueryHookResult = ReturnType<typeof useCallbackRequestsLazyQuery>;
export type CallbackRequestsSuspenseQueryHookResult = ReturnType<typeof useCallbackRequestsSuspenseQuery>;
export type CallbackRequestsQueryResult = Apollo.QueryResult<CallbackRequestsQuery, CallbackRequestsQueryVariables>;