import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { LanguagesAutocompleteQuery } from "./gql";

export type ILanguageAutocomplete = ExtractNodeType<LanguagesAutocompleteQuery>;

export const languageSchema = createZodAutocompleteFromType<ILanguageAutocomplete>({
  id: z.string(),
  code: z.string(),
  display: z.string(),
  displayAr: z.string().nullish(),
});
