import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalFormActivateMutationVariables = Types.Exact<{
  activateMedicalFormId: Types.Scalars['ID']['input'];
}>;


export type MedicalFormActivateMutation = { __typename?: 'Mutation', activateMedicalForm?: { __typename?: 'MedicalForm', active?: boolean | null, errors?: Array<{ __typename?: 'MedicalFormGraphqlError', errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const MedicalFormActivateDocument = gql`
    mutation MedicalFormActivate($activateMedicalFormId: ID!) {
  activateMedicalForm(id: $activateMedicalFormId) {
    active
    errors {
      errorType
      field
      message
    }
  }
}
    `;
export type MedicalFormActivateMutationFn = Apollo.MutationFunction<MedicalFormActivateMutation, MedicalFormActivateMutationVariables>;

/**
 * __useMedicalFormActivateMutation__
 *
 * To run a mutation, you first call `useMedicalFormActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalFormActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalFormActivateMutation, { data, loading, error }] = useMedicalFormActivateMutation({
 *   variables: {
 *      activateMedicalFormId: // value for 'activateMedicalFormId'
 *   },
 * });
 */
export function useMedicalFormActivateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalFormActivateMutation, MedicalFormActivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalFormActivateMutation, MedicalFormActivateMutationVariables>(MedicalFormActivateDocument, options);
      }
export type MedicalFormActivateMutationHookResult = ReturnType<typeof useMedicalFormActivateMutation>;
export type MedicalFormActivateMutationResult = Apollo.MutationResult<MedicalFormActivateMutation>;
export type MedicalFormActivateMutationOptions = Apollo.BaseMutationOptions<MedicalFormActivateMutation, MedicalFormActivateMutationVariables>;