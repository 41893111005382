import { i18n } from "@toolkit/i18n";
import { PayerTpo } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const payerTpoOptionsMap: IEnumToOptionsMap<PayerTpo> = {
  [PayerTpo.Io]: {
    key: PayerTpo.Io,
    get label() {
      return i18n.t("iO", { ns: "domains" });
    },
    value: PayerTpo.Io,
  },
  [PayerTpo.Eclaim]: {
    key: PayerTpo.Eclaim,
    get label() {
      return i18n.t("EClaim", { ns: "domains" });
    },
    value: PayerTpo.Eclaim,
  },
};

export const payerTpoOptions = Object.values(payerTpoOptionsMap);
