import { ProcedureAgeSortingField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IProcedureAgeListNode } from "../../types";
import { getDiscrptionsInfoItems } from "@/pages/OptimaMedicalNecessity/others/cellInfo.utils";

export const useProcedureAgeListContainerColumns = (): CustomTableColumnProps<IProcedureAgeListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "ProcedureCode",
        header: t("Procedure Code"),
        accessor: "procedureCode",
        sort: {
          columnEnum: ProcedureAgeSortingField.ProcedureCode,
        },
      },
      {
        key: "MinAgeDays",
        header: t("Min Age Days"),
        accessor: "minAgeDays",
        sort: {
          columnEnum: ProcedureAgeSortingField.MinAgeDays,
        },
      },
      {
        key: "minAgeDaysMinFilter",
        header: t("Min Age Days From"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "minAgeDaysGTE",
          min: 1,
        },
      },
      {
        key: "minAgeDaysMaxFilter",
        header: t("Min Age Days To"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "minAgeDaysLTE",
          min: 1,
        },
      },
      {
        key: "MaxAgeDays",
        header: t("Max Age Days"),
        accessor: "maxAgeDays",
        sort: {
          columnEnum: ProcedureAgeSortingField.MaxAgeDays,
        },
      },
      {
        key: "maxAgeDaysMinFilter",
        header: t("Max Age Days From"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "maxAgeDaysGTE",
          min: 1,
        },
      },
      {
        key: "maxAgeDaysMaxFilter",
        header: t("Max Age Days To"),
        showOnlyForFilterField: true,
        filter: {
          type: "number",
          name: "maxAgeDaysLTE",
          min: 1,
        },
      },
      {
        key: "procedureCodeFilter",
        header: t("Procedure Code"),
        filter: {
          type: "string",
          name: "procedureCode",
        },
        showOnlyForFilterField: true,
      },
      {
        key: "description",
        header: t("Description"),
        type: "info",
        infoCellOptions: {
          title: t("Description"),
          items: ({ description }) => getDiscrptionsInfoItems(description),
          disableShowButton: ({ description }) => !description?.length,
        },
      },
    ],
    [t]
  );
};
