import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackageCategoryQueryVariables = Types.Exact<{
  marketplaceHealthPackageCategoryId: Types.Scalars['ID']['input'];
}>;


export type MarketplaceHealthPackageCategoryQuery = { __typename?: 'Query', marketplaceHealthPackageCategory?: { __typename?: 'MarketplaceHealthPackageCategory', description?: string | null, descriptionAr?: string | null, id: string, image?: string | null, name?: string | null, nameAr?: string | null, parent?: { __typename?: 'MarketplaceHealthPackageCategory', name?: string | null, nameAr?: string | null, id: string } | null } | null };


export const MarketplaceHealthPackageCategoryDocument = gql`
    query MarketplaceHealthPackageCategory($marketplaceHealthPackageCategoryId: ID!) {
  marketplaceHealthPackageCategory(id: $marketplaceHealthPackageCategoryId) {
    description
    descriptionAr
    id
    image
    name
    nameAr
    parent {
      name
      nameAr
      id
    }
  }
}
    `;

/**
 * __useMarketplaceHealthPackageCategoryQuery__
 *
 * To run a query within a React component, call `useMarketplaceHealthPackageCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackageCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceHealthPackageCategoryQuery({
 *   variables: {
 *      marketplaceHealthPackageCategoryId: // value for 'marketplaceHealthPackageCategoryId'
 *   },
 * });
 */
export function useMarketplaceHealthPackageCategoryQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables> & ({ variables: MarketplaceHealthPackageCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables>(MarketplaceHealthPackageCategoryDocument, options);
      }
export function useMarketplaceHealthPackageCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables>(MarketplaceHealthPackageCategoryDocument, options);
        }
export function useMarketplaceHealthPackageCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables>(MarketplaceHealthPackageCategoryDocument, options);
        }
export type MarketplaceHealthPackageCategoryQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoryQuery>;
export type MarketplaceHealthPackageCategoryLazyQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoryLazyQuery>;
export type MarketplaceHealthPackageCategorySuspenseQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategorySuspenseQuery>;
export type MarketplaceHealthPackageCategoryQueryResult = Apollo.QueryResult<MarketplaceHealthPackageCategoryQuery, MarketplaceHealthPackageCategoryQueryVariables>;