import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnosisValidProcedureListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.DiagnosisValidProcedureFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.DiagnosisValidProcedureSorting>;
}>;


export type DiagnosisValidProcedureListQuery = { __typename?: 'Query', getAllDiagnosisValidProcedure?: { __typename?: 'DiagnosisValidProcedureConnection', edges: Array<{ __typename?: 'DiagnosisValidProcedureEdge', node: { __typename?: 'DiagnosisValidProcedure', id: string, diagnosisCode: string, procedureCode: string, description?: string | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const DiagnosisValidProcedureListDocument = gql`
    query DiagnosisValidProcedureList($after: String, $before: String, $filter: DiagnosisValidProcedureFilter, $first: Int, $last: Int, $sortBy: DiagnosisValidProcedureSorting) {
  getAllDiagnosisValidProcedure(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        diagnosisCode
        procedureCode
        description
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useDiagnosisValidProcedureListQuery__
 *
 * To run a query within a React component, call `useDiagnosisValidProcedureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisValidProcedureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosisValidProcedureListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDiagnosisValidProcedureListQuery(baseOptions?: Apollo.QueryHookOptions<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>(DiagnosisValidProcedureListDocument, options);
      }
export function useDiagnosisValidProcedureListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>(DiagnosisValidProcedureListDocument, options);
        }
export function useDiagnosisValidProcedureListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>(DiagnosisValidProcedureListDocument, options);
        }
export type DiagnosisValidProcedureListQueryHookResult = ReturnType<typeof useDiagnosisValidProcedureListQuery>;
export type DiagnosisValidProcedureListLazyQueryHookResult = ReturnType<typeof useDiagnosisValidProcedureListLazyQuery>;
export type DiagnosisValidProcedureListSuspenseQueryHookResult = ReturnType<typeof useDiagnosisValidProcedureListSuspenseQuery>;
export type DiagnosisValidProcedureListQueryResult = Apollo.QueryResult<DiagnosisValidProcedureListQuery, DiagnosisValidProcedureListQueryVariables>;