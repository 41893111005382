import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionGuidedCareTemplatesAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramTemplateFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramTemplateSortingInput>;
}>;


export type DecisionGuidedCareTemplatesAutocompleteQuery = { __typename?: 'Query', healthProgramTemplates?: { __typename?: 'HealthProgramTemplateCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramTemplateCountableEdge', node: { __typename?: 'HealthProgramTemplate', id?: string | null, display?: string | null } }> } | null };


export const DecisionGuidedCareTemplatesAutocompleteDocument = gql`
    query DecisionGuidedCareTemplatesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramTemplateFilterInput, $sortBy: HealthProgramTemplateSortingInput) {
  healthProgramTemplates(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        display: name
      }
    }
  }
}
    `;

/**
 * __useDecisionGuidedCareTemplatesAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionGuidedCareTemplatesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionGuidedCareTemplatesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionGuidedCareTemplatesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDecisionGuidedCareTemplatesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>(DecisionGuidedCareTemplatesAutocompleteDocument, options);
      }
export function useDecisionGuidedCareTemplatesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>(DecisionGuidedCareTemplatesAutocompleteDocument, options);
        }
export function useDecisionGuidedCareTemplatesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>(DecisionGuidedCareTemplatesAutocompleteDocument, options);
        }
export type DecisionGuidedCareTemplatesAutocompleteQueryHookResult = ReturnType<typeof useDecisionGuidedCareTemplatesAutocompleteQuery>;
export type DecisionGuidedCareTemplatesAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionGuidedCareTemplatesAutocompleteLazyQuery>;
export type DecisionGuidedCareTemplatesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionGuidedCareTemplatesAutocompleteSuspenseQuery>;
export type DecisionGuidedCareTemplatesAutocompleteQueryResult = Apollo.QueryResult<DecisionGuidedCareTemplatesAutocompleteQuery, DecisionGuidedCareTemplatesAutocompleteQueryVariables>;