import { useAddToast } from "@toolkit/ui";
import React, { FC, ReactElement } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useOpenState } from "@toolkit/core";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { usePatientMessageSendMutation } from "../../gql";
import { PatientMessageSendModal } from "pages/Patients/modals/PatientMessageSend/PatientMessageSendModal";
import { IPatientMessageSendForm } from "pages/Patients/forms/PatientMessageSend/PatientMessageSendFormSchema";
import { SendBulkMessagesMethodEnum } from "@health/enum-options/dist/schema/types";

type PatientMessageSendProps = {
  patientsUsersIds: string[];
  button?: ReactElement;
  method?: SendBulkMessagesMethodEnum;
};

export const PatientMessageSend: FC<PatientMessageSendProps> = props => {
  const { patientsUsersIds, button, method } = props;

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();
  const { open, handleToggle } = useOpenState();

  const [sendPatientMessage, { loading: isSubmitting }] = usePatientMessageSendMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.sendBulkMessages?.accountErrors || [];

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Message Sent To Patient Successfully"));
        handleToggle();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onPatientMessageSendModalChange = (values: IPatientMessageSendForm) => {
    sendPatientMessage({
      variables: {
        input: {
          ...values,
          method: values?.method?.value,
          users: patientsUsersIds,
        },
      },
    });
  };

  return (
    <PatientMessageSendModal
      isOpen={open}
      isLoading={isSubmitting}
      method={method}
      button={button}
      handleToggle={handleToggle}
      onChange={onPatientMessageSendModalChange}
    />
  );
};
