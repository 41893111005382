import { InsuranceBenefitApprovalLimit, InsuranceBenefitApprovalLimitCrudInput } from "@/schema/types";
import { IInsuranceBenefitApprovalLimitUpsertFormValues } from "../forms/InsuranceBenefitApprovalLimitUpsert/insuranceBenefitApprovalLimitUpsertFormSchema";
import { optimaEncounterTypeOptionsMap } from "@health/enum-options";

export const convertInsuranceBenefitApprovalLimitToFormValues = (
  insuranceBenefitApprovalLimit: InsuranceBenefitApprovalLimit
): IInsuranceBenefitApprovalLimitUpsertFormValues => {
  return {
    insuranceLicense: insuranceBenefitApprovalLimit.insuranceLicense,
    insurancePolicy: insuranceBenefitApprovalLimit.insurancePolicy,
    encounterType: optimaEncounterTypeOptionsMap[insuranceBenefitApprovalLimit.encounterType],
    source: insuranceBenefitApprovalLimit.source,
    limit: insuranceBenefitApprovalLimit.limit,
    itemCode: insuranceBenefitApprovalLimit.itemCode,
    description: insuranceBenefitApprovalLimit.description ?? undefined,
  };
};

export const convertInsuranceBenefitApprovalLimitFormValuesToBackEndValues = (
  values: IInsuranceBenefitApprovalLimitUpsertFormValues
): InsuranceBenefitApprovalLimitCrudInput => {
  return {
    insuranceLicense: values.insuranceLicense,
    insurancePolicy: values.insurancePolicy,
    encounterType: values.encounterType.value,
    limit: values.limit,
    source: values.source,
    itemCode: values.itemCode,
    description: values.description,
  };
};
