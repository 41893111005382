import { CityOrderField } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, useFormatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { ICityListNode } from "../../types";

export const useCityListContainerColumns = (): CustomTableColumnProps<ICityListNode>[] => {
  const { t } = useTranslation("admin");
  const { formatLocalizedDate } = useFormatDate();

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        type: "truncated-text",
        sort: {
          columnEnum: CityOrderField.Name,
        },
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "area",
        header: t("Area"),
        type: "truncated-text",
        accessor: "area",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        type: "datetime",
        accessor: "created",
        sort: {
          columnEnum: CityOrderField.Created,
        },
      },
    ],
    [formatLocalizedDate, t]
  );
};
