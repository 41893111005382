import { i18n } from "@toolkit/i18n";
import { FieldStandard } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const fieldStandardOptionsMap: IEnumToOptionsMap<FieldStandard> = {
  [FieldStandard.Cpt]: {
    key: FieldStandard.Cpt,
    get label() {
      return i18n.t("CPT", { ns: "domains" });
    },
    value: FieldStandard.Cpt,
  },
  [FieldStandard.Loinc]: {
    key: FieldStandard.Loinc,
    get label() {
      return i18n.t("LOINC", { ns: "domains" });
    },
    value: FieldStandard.Loinc,
  },
};

export const fieldStandardOptions = Object.values(fieldStandardOptionsMap);
