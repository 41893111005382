import { IAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramBenefitsGroupsAutocompleteQuery } from "./gql";
import { IHealthProgramBenefitGroupAutocomplete } from "./schema";

export const createHealthProgramBenefitGroupAutocompleteOption = (group: IHealthProgramBenefitGroupAutocomplete) => {
  return createAutocompleteOption({ id: group?.id!, name: group?.name! }, "id", "name");
};

export const getHealthProgramBenefitsGroupsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return {
    name,
    multiple,
    type: "autocomplete" as const,
    accessor: "healthProgramBenefitGroups",
    query: useHealthProgramBenefitsGroupsAutocompleteQuery,
    getOptionLabel: option => option?.name,
    getValueForBackend: option => option?.map(item => item?.id) || [],
  };
};
