import { IAutocompleteProps } from "@/types";
import { DefaultContext } from "@apollo/client";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { KeywordSearch } from "../../schema/types";
import { SystemCodeAutocompleteQueryVariables, useSystemCodeAutocompleteQuery } from "./gql";

export type ISystemCodeAutocompleteQueryFilter = Pick<
  SystemCodeAutocompleteQueryVariables,
  "codeSystemId" | "codeSystemCode" | "keyword" | "searchParameters"
>;

type SystemCodeAutocompleteProps = IAutocompleteProps<ISystemCodeAutocompleteQueryFilter> & {
  isCodeHidden?: boolean;
  context?: DefaultContext;
};

export const SystemCodeAutocomplete: FC<SystemCodeAutocompleteProps> = props => {
  const { label, filter, skip, isCodeHidden, context, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useSystemCodeAutocompleteQuery,
    variables: { ...filter, keywordSearch: isCodeHidden ? KeywordSearch.Display : KeywordSearch.All },
    searchKey: "keyword",
    skip: props.disabled || skip,
    context,
  });

  const options = mapToAutocompleteOptions(data, "code", item => (isCodeHidden ? item?.display! : `${item?.code} - ${item.display}`));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Codes") : t("Code"))}
    />
  );
};
