import { OptimaAction } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useOptimaEditActionsColumns = (): CustomTableColumnProps<OptimaAction>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
      },
      {
        key: "action",
        header: t("Action"),
        accessor: "action",
      },
    ];
  }, [t]);
};
