import { MarketplaceHealthPackageSortingField } from "@/schema/types";
import { getMarketplaceHealthPackageCategoriesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { healthPackageCategoriesPaths } from "pages/MarketplaceHealthPackageCategories/constants";
import { useMemo } from "react";
import { HealthPackageCategoriesQueryNode } from "../../types";

export const useCategoriesListTableColumns = (): CustomTableColumnProps<HealthPackageCategoriesQueryNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ image }) => <GridImageModal image={image!} title={t("Category Image")} altText={t("Health Package Category")} />,
      },
      {
        key: "name",
        header: t("Name"),
        type: "truncated-text",
        accessor: ({ name, nameAr }) => pickLocalizedValue(name!, nameAr!),
        filter: {
          type: "string",
          name: "nameContains",
        },
        sort: {
          columnEnum: pickLocalizedValue(MarketplaceHealthPackageSortingField.Name, MarketplaceHealthPackageSortingField.NameAr),
        },
      },
      {
        key: "description",
        header: t("Description"),
        type: "truncated-text",
        accessor: ({ description, descriptionAr }) => pickLocalizedValue(description, descriptionAr),
      },
      {
        key: "subcategories",
        header: t("Sub Categories"),
        type: "redirect",
        redirectCellOptions: {
          link: ({ id }) => healthPackageCategoriesPaths.healthPackageCategoryCategories.fullPathWithParams({ categoryId: id }),
          tooltipMessage: row => t("show {{name}} categories", { name: pickLocalizedValue(row?.name, row.nameAr) }),
        },
      },
      {
        key: "isParent",
        header: t("Is Parent"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("YesNo", "parent"),
      },
      {
        key: "parentId",
        header: t("Parent"),
        showOnlyForFilterField: true,
        filter: getMarketplaceHealthPackageCategoriesAutocompleteFilter({ name: "parentId" }),
      },
    ];
  }, []);
};
