import { enumOptionsMapper } from "./mapper";
import { YesNo } from "../enums";

export const getAutocompleteEnumFilter = (
  enumType: keyof typeof enumOptionsMapper,
  name: string,
  properties?: {
    multiple?: boolean;
    reverse?: boolean;
  }
) => {
  return {
    type: "autocomplete" as const,
    name,
    multiple: properties?.multiple,
    options: enumOptionsMapper[enumType],
    getOptionLabel: option => option?.label,
    getValueForBackend: option => {
      if (option) {
        if (enumType === "YesNo") {
          if (properties?.reverse) {
            return option?.value !== YesNo.Yes;
          } else {
            return option?.value === YesNo.Yes;
          }
        } else {
          if (Array.isArray(option)) {
            return option?.map(item => item?.value) || [];
          }
          return option?.value;
        }
      } else {
        return undefined;
      }
    },
  };
};
