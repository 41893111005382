import { makeStyles } from "@toolkit/ui";

export const useKpiListStyles = makeStyles<{ color?: "success" | "warning" | "error" }>()((theme, { color = "success" }) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "64px",
    marginBlock: "10px",
    gap: "8px",
  },
  kpiCard: {
    minWidth: "350px",
    width: "fit-content",
    height: "64px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "12px",
    backgroundColor: theme.palette.background.paper,
    padding: "12px",
    cursor: "pointer",
    transition: "all 0.3s",
  },
  kpiCard__selected: {
    backgroundColor: theme.palette[color][200],
    borderColor: theme.palette[color][400],
  },
  kpiStartSquare: {
    width: "38px",
    height: "38px",
    borderRadius: "8px",
    border: "2px solid",
    borderColor: theme.palette[color].main,
    backgroundColor: theme.palette[color][200],
    marginInlineEnd: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "20px",
      height: "20px",
      fill: theme.palette[color][1500],
    },
    "& path": {
      width: "20px",
      height: "20px",
      fill: theme.palette[color][1500],
    },
  },
  kpiTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  kpiTitle: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  kpiSubtitle: {},
}));
