import { createPathDefinition } from "@toolkit/core";

export const providersRoute = "/health-providers";

export const providersPaths = createPathDefinition(providersRoute, {
  list: "",
  create: "new",
  update: ":providerId",
  branchList: ":providerId/branches",
  branchCreate: ":providerId/branches/new",
  branchUpdate: ":providerId/branches/:branchId",
});
