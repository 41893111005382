import {
  CircularProgress,
  CustomDialog,
  IconButton,
  Tooltip,
  Box,
  PublishedWithChangesSharpIcon,
  UnpublishedSharpIcon,
  XIcon,
} from "@toolkit/ui";
import React from "react";
import { useSurveyListActions } from "./useSurveyListActions";
import { CloneDialog } from "../CloneDialog";
import UnPublishDialog from "../UnPublishDialog";
import { PublishDialog } from "../PublishDialog";
import { ISurvey } from "../../types";
import { CustomIcon } from "@toolkit/ui";
import { useSurveyListActionsStyles } from "./useSurveyListActions.styles";

type SurveyListActionsProps = {
  survey: ISurvey | null;
};

export const SurveyListActions: React.FC<SurveyListActionsProps> = ({ survey }) => {
  const { classes } = useSurveyListActionsStyles();
  const {
    isPublishModalOpen,
    isUnPublishDialogOpen,
    isPublishDisabled,
    isSurveyCancelable,
    canUnPublishSurvey,
    isPublishLoading,
    isUnPublishLoading,
    isCancelModalOpen,
    isCloneLoading,
    isCloneModalOpen,
    canManageSurvey,
    handlePublishModalOpen,
    handlePublish,
    handleUnPublish,
    handleToggleUnPublishModalOpen,
    handleClosePublishModal,
    t,
    handleCloseCancelModal,
    handleToggleCancelModalOpen,
    handleCancelSurvey,
    handleToggleCloneModalOpen,
    handleCloneSurvey,
    isDisabled,
  } = useSurveyListActions({ survey });

  return (
    <>
      <Box display='flex' flexDirection='row' gap={2} padding={0}>
        {survey?.id && canManageSurvey && (
          <>
            <Tooltip title={t("Publish")}>
              <IconButton
                className={classes.publishButton}
                disabled={isPublishDisabled || isPublishLoading}
                onClick={handlePublishModalOpen}
              >
                {isPublishLoading ? <CircularProgress size={15} /> : <PublishedWithChangesSharpIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Clone Survey")}>
              <IconButton className={classes.cloneButton} disabled={isCloneLoading} onClick={handleToggleCloneModalOpen}>
                {isCloneLoading ? <CircularProgress size={15} /> : <CustomIcon icon='clone' />}
              </IconButton>
            </Tooltip>
            <Tooltip title={t("UnPublish")}>
              <IconButton
                className={classes.unpublishButton}
                disabled={isUnPublishLoading || !canUnPublishSurvey}
                onClick={handleToggleUnPublishModalOpen}
              >
                {isUnPublishLoading ? <CircularProgress size={15} /> : <UnpublishedSharpIcon />}
              </IconButton>
            </Tooltip>
            {isSurveyCancelable && (
              <Tooltip title={t("Cancel Survey")}>
                <IconButton
                  className={classes.cancelButton}
                  disabled={isDisabled}
                  onClick={survey?.id ? handleToggleCancelModalOpen : handleCloseCancelModal}
                >
                  <XIcon width='12px' height='12px' />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </Box>
      <PublishDialog isLoading={isPublishLoading} isOpen={isPublishModalOpen} onPublish={handlePublish} onClose={handleClosePublishModal} />
      <UnPublishDialog
        isOpen={isUnPublishDialogOpen}
        onClose={handleToggleUnPublishModalOpen}
        onUnPublish={handleUnPublish}
        isUnPublishLoading={isUnPublishLoading}
      />
      <CustomDialog
        type='delete'
        isOpen={isCancelModalOpen}
        text={{
          title: t("Cancel Survey Confirmation"),
          body: t("Are you sure you want to cancel this survey?"),
        }}
        onClose={handleToggleCancelModalOpen}
        onDelete={handleCancelSurvey}
      />
      <CloneDialog isLoading={isCloneLoading} isOpen={isCloneModalOpen} onClone={handleCloneSurvey} onClose={handleToggleCloneModalOpen} />
    </>
  );
};

export default SurveyListActions;
