import { useNavigate, useParams } from "react-router-dom";
import { User } from "@/schema/types";
import { UserProviderUpdateContainer } from "@health/domains";
import { useAdminUserProviderGetQuery } from "../../gql";
import { IAdminUserProviderUpdateContainerParams } from "../../types";
import { useSetUsersProviderBreadcrumbs } from "../../hooks";

export const AdminUserProviderUpdateContainer = () => {
  const navigate = useNavigate();

  const { userProviderId } = useParams<IAdminUserProviderUpdateContainerParams>();

  const { data, loading } = useAdminUserProviderGetQuery({
    variables: { id: userProviderId! },
    skip: !userProviderId,
    fetchPolicy: "no-cache",
  });

  const user = data?.user as User;

  const onUserVendorUpsertFormChange = () => {
    navigate(-1);
  };

  useSetUsersProviderBreadcrumbs("UPDATE", `${user?.firstName} ${user?.lastName}`);

  return <UserProviderUpdateContainer user={user} isAdmin isLoading={loading} onChange={onUserVendorUpsertFormChange} />;
};
