import { zodEnumSchema } from "@health/enum-options";
import { z } from "zod";

export const insuranceBenefitApprovalLimitUpsertFormSchema = z.object({
  insuranceLicense: z.string().min(1).max(255),
  insurancePolicy: z.string().min(1).max(255),
  encounterType: zodEnumSchema.encounterType,
  source: z.string().min(1).max(255),
  limit: z.number().int().positive(),
  itemCode: z.string().min(1).max(255),
  description: z.string().min(3).optional(),
});
export type IInsuranceBenefitApprovalLimitUpsertFormValues = z.infer<typeof insuranceBenefitApprovalLimitUpsertFormSchema>;

export const insuranceBenefitApprovalLimitUpsertFormDefaultValues: Partial<IInsuranceBenefitApprovalLimitUpsertFormValues> = {
  insuranceLicense: "",
  insurancePolicy: "",
  encounterType: undefined,
  source: "",
  limit: 0,
  itemCode: "",
  description: "",
};
