import { CodeSystem, ProcessingStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useTheme } from "@toolkit/ui";
import { first, last } from "lodash-es";
import { useEffect, useState } from "react";
import { domainsConfig } from "../../../../domainsConfig";
import { getCodeSystemProcessingStatusColor, getCodeSystemProcessingStatusText } from "../../shared/utils";
import { UseManagedListCardHookProps } from "./ManagedListCard.types";
import { formatDate } from "./utils";

export const useManagedListCardHook = ({ data }: UseManagedListCardHookProps) => {
  const { t } = useTranslation("domains");

  const { activeCodeSystem, lastCodeSystem, codeSystemDefinition, iconUrl } = data;
  const { id, codeSystemHistory } = activeCodeSystem || ({} as CodeSystem);
  const { code, display, editorUserId, reviewerUserId } = codeSystemDefinition || {};
  const lastCadeSystemProcessingStatus = lastCodeSystem?.processingStatus as ProcessingStatus;
  const uploadDate = formatDate(first(codeSystemHistory)?.updateDate);
  const updateDate = formatDate(last(codeSystemHistory)?.updateDate);
  const creator = last(lastCodeSystem?.codeSystemHistory)?.user?.firstName;

  const [svgIcon, setSvgIcon] = useState("");

  useEffect(() => {
    const baseURL = domainsConfig.get("serverUrl");
    const url = baseURL + "/" + iconUrl;
    fetch(url)
      .then(res => res.text())
      .then(text => setSvgIcon(text));
  }, []);
  const theme = useTheme();

  const statusColor = getCodeSystemProcessingStatusColor(lastCadeSystemProcessingStatus, theme.palette);
  const statusText = getCodeSystemProcessingStatusText(t, lastCadeSystemProcessingStatus);

  return {
    code,
    creator,
    display,
    editorUserId,
    id,
    lastCadeSystemProcessingStatus,
    lastCodeSystem,
    reviewerUserId,
    statusColor,
    statusText,
    svgIcon,
    updateDate,
    uploadDate,
  };
};
