import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderVirtualCallEnableMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthProgramNetworkProviderVirtualCallEnableMutation = { __typename?: 'Mutation', healthProgramNetworkProviderUpdateVirtualCallEnabled?: { __typename?: 'HealthProgramNetworkProviderCRUD', entity?: { __typename?: 'HealthProgramNetworkProvider', id: string, virtualCallEnabled?: boolean | null } | null } | null };


export const HealthProgramNetworkProviderVirtualCallEnableDocument = gql`
    mutation HealthProgramNetworkProviderVirtualCallEnable($id: ID!) {
  healthProgramNetworkProviderUpdateVirtualCallEnabled(id: $id) {
    entity {
      id
      virtualCallEnabled
    }
  }
}
    `;
export type HealthProgramNetworkProviderVirtualCallEnableMutationFn = Apollo.MutationFunction<HealthProgramNetworkProviderVirtualCallEnableMutation, HealthProgramNetworkProviderVirtualCallEnableMutationVariables>;

/**
 * __useHealthProgramNetworkProviderVirtualCallEnableMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkProviderVirtualCallEnableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderVirtualCallEnableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkProviderVirtualCallEnableMutation, { data, loading, error }] = useHealthProgramNetworkProviderVirtualCallEnableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderVirtualCallEnableMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkProviderVirtualCallEnableMutation, HealthProgramNetworkProviderVirtualCallEnableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkProviderVirtualCallEnableMutation, HealthProgramNetworkProviderVirtualCallEnableMutationVariables>(HealthProgramNetworkProviderVirtualCallEnableDocument, options);
      }
export type HealthProgramNetworkProviderVirtualCallEnableMutationHookResult = ReturnType<typeof useHealthProgramNetworkProviderVirtualCallEnableMutation>;
export type HealthProgramNetworkProviderVirtualCallEnableMutationResult = Apollo.MutationResult<HealthProgramNetworkProviderVirtualCallEnableMutation>;
export type HealthProgramNetworkProviderVirtualCallEnableMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkProviderVirtualCallEnableMutation, HealthProgramNetworkProviderVirtualCallEnableMutationVariables>;