import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { PayersAutocompleteQuery } from "./gql";

export type IPayerAutocomplete = ExtractNodeType<PayersAutocompleteQuery>;

export const payerSchema = createZodAutocompleteFromType<IPayerAutocomplete>({
  id: z.string(),
  name: z.string(),
  nameAr: z.string().nullish(),
});
