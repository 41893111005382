import { PermissionEnum } from "@/schema/types";
import { hasAnyPermission, hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { BookAnAppointmentIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { SupplementTypesTrackerList, SupplementTypeTrackerEditPage, SupplementTypeTrackerNewPage } from "../SupplementTypesTracker";
import { supplementTypesPaths, supplementTypesRoute } from "../SupplementTypesTracker/constants/supplement-types-paths";
import { TradeDrugTypesTrackerList, TradeDrugTypeTrackerEditPage, TradeDrugTypeTrackerNewPage } from "../TradeDrugTypesTracker";
import { tradeDrugTypesPaths, tradeDrugTypesRoute } from "../TradeDrugTypesTracker/constants/trade-drug-types-paths";

export const activityTrackerRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "activity-tracker-routes",
    text: t("Activity Tracker", { ns: "admin" }),
    icon: <BookAnAppointmentIcon />,
    isProhibited: !hasAnyPermission([PermissionEnum.MangeActivityTracker]),
    subItems: [
      {
        id: "supplement-types-tracker-routes",
        text: t("Supplement Types Tracker", { ns: "admin" }),
        route: supplementTypesRoute,
        subItems: [
          {
            id: "supplement-types-list-route",
            text: t("Supplement Types Tracker", { ns: "admin" }),
            route: supplementTypesPaths.listPath.route,
            fullPath: supplementTypesPaths.listPath.fullPath,
            element: <SupplementTypesTrackerList />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "supplement-types-new-route",
            text: t("Supplement Types Tracker New", { ns: "admin" }),
            route: supplementTypesPaths.newPath.route,
            fullPath: supplementTypesPaths.newPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.MangeActivityTracker),
            element: <SupplementTypeTrackerNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "health-program-edit-route",
            text: t("Edit Health Program", { ns: "admin" }),
            route: supplementTypesPaths.editPath.route,
            fullPath: supplementTypesPaths.editPath.fullPath,
            isProhibited: !hasPermission(PermissionEnum.MangeActivityTracker),
            hidden: true,
            element: <SupplementTypeTrackerEditPage />,
            onClick: (route: string) => navigate(route),
          },
        ],
      },
      {
        id: "trade-drug-types-tracker-routes",
        text: t("Trade Drug Types Tracker", { ns: "admin" }),
        route: tradeDrugTypesRoute,
        subItems: [
          {
            id: "trade-drug-types-list-route",
            text: t("Trade Drug Types Tracker", { ns: "admin" }),
            route: tradeDrugTypesPaths.listPath.route,
            fullPath: tradeDrugTypesPaths.listPath.fullPath,
            element: <TradeDrugTypesTrackerList />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "trade-drug-types-new-route",
            text: t("Trade Drug Types Tracker New", { ns: "admin" }),
            route: tradeDrugTypesPaths.newPath.route,
            fullPath: tradeDrugTypesPaths.newPath.fullPath,
            hidden: true,
            isProhibited: !hasPermission(PermissionEnum.MangeActivityTracker),
            element: <TradeDrugTypeTrackerNewPage />,
            onClick: (route: string) => navigate(route),
          },
          {
            id: "trade-drug-types-edit-route",
            text: t("Trade Drug Types Tracker Edit", { ns: "admin" }),
            route: tradeDrugTypesPaths.editPath.route,
            fullPath: tradeDrugTypesPaths.editPath.fullPath,
            isProhibited: !hasPermission(PermissionEnum.MangeActivityTracker),
            hidden: true,
            element: <TradeDrugTypeTrackerEditPage />,
            onClick: (route: string) => navigate(route),
          },
        ],
      },
    ],
  };
};
