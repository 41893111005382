import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { ProviderAddressFragmentFragmentDoc } from '../../fragments/__generated__/ProviderAddress';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProviderGetQuery = { __typename?: 'Query', vendor?: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, ownerName: string, nationalId: string, tradeName: string, commercialRegistrationNumber: string, taxLicenseNumber: string, deliveryPrice: number, type?: Types.VendorType | null, contactPhoneNumber?: string | null, description?: string | null, isActive: boolean, isIntegrated: boolean, hasMultipleBranches: boolean, enableVisitDetails: boolean, editLikeEnabled: boolean, hasOwnDrivers: boolean, hasOwnPaymentGateway: boolean, supportOutpatientJourney: boolean, notifyByEmail: boolean, logo?: string | null, backGroundImage?: string | null, address?: { __typename?: 'Address', id: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, area: string, areaAr: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null, managersContactInfo?: { __typename?: 'VendorManagersContactInfo', id: string, generalManagerEmail?: string | null, purchasingManagerName?: string | null, purchasingManagerMobileNumber?: string | null, purchasingManagerEmail?: string | null, financialManagerName?: string | null, financialManagerEmail?: string | null, financialManagerMobileNumber?: string | null } | null, subscriptions: { __typename?: 'VendorSubscriptionCountableConnection', edges: Array<{ __typename?: 'VendorSubscriptionCountableEdge', node: { __typename?: 'VendorSubscription', id: string, fixedCostAmount: number, fixedOrderCostAmount: number, fixedOrderPercentage: number, plan?: { __typename?: 'Plan', id: string, name: string } | null } }> } } | null };


export const ProviderGetDocument = gql`
    query ProviderGet($id: ID!) {
  vendor(id: $id) {
    id
    name
    nameAr
    ownerName
    nationalId
    tradeName
    commercialRegistrationNumber
    taxLicenseNumber
    deliveryPrice
    type
    contactPhoneNumber
    description
    isActive
    isIntegrated
    hasMultipleBranches
    enableVisitDetails
    editLikeEnabled
    hasOwnDrivers
    hasOwnPaymentGateway
    supportOutpatientJourney
    notifyByEmail
    logo
    backGroundImage
    address {
      ...ProviderAddressFragment
    }
    managersContactInfo {
      id
      generalManagerEmail
      purchasingManagerName
      purchasingManagerMobileNumber
      purchasingManagerEmail
      financialManagerName
      financialManagerEmail
      financialManagerMobileNumber
    }
    subscriptions(first: 1) {
      edges {
        node {
          id
          fixedCostAmount
          fixedOrderCostAmount
          fixedOrderPercentage
          plan {
            id
            name
          }
        }
      }
    }
  }
}
    ${ProviderAddressFragmentFragmentDoc}`;

/**
 * __useProviderGetQuery__
 *
 * To run a query within a React component, call `useProviderGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderGetQuery(baseOptions: Apollo.QueryHookOptions<ProviderGetQuery, ProviderGetQueryVariables> & ({ variables: ProviderGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProviderGetQuery, ProviderGetQueryVariables>(ProviderGetDocument, options);
      }
export function useProviderGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProviderGetQuery, ProviderGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProviderGetQuery, ProviderGetQueryVariables>(ProviderGetDocument, options);
        }
export function useProviderGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ProviderGetQuery, ProviderGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProviderGetQuery, ProviderGetQueryVariables>(ProviderGetDocument, options);
        }
export type ProviderGetQueryHookResult = ReturnType<typeof useProviderGetQuery>;
export type ProviderGetLazyQueryHookResult = ReturnType<typeof useProviderGetLazyQuery>;
export type ProviderGetSuspenseQueryHookResult = ReturnType<typeof useProviderGetSuspenseQuery>;
export type ProviderGetQueryResult = Apollo.QueryResult<ProviderGetQuery, ProviderGetQueryVariables>;