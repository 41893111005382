import { Plan, Vendor } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormDaysTimesRanges, Grid, PageWrapper } from "@toolkit/ui";
import React, { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useProviderPlanGetQuery } from "../../gql";
import { AddressUpsertForm } from "@health/domains";
import { convertProviderSubscriptionToFormValues, convertProviderToFormValues } from "../../others";
import { ProviderInformationForm } from "../ProviderInformation/ProviderInformationForm";
import { ProviderSubscriptionForm } from "../ProviderSubscription/ProviderSubscriptionForm";
import { ProviderImagesForm } from "../ProviderImages/ProviderImagesForm";
import { ProviderHealthLicenseForm } from "../ProviderHealthLicense/ProviderHealthLicenseForm";
import { ProviderManagersInformationForm } from "../ProviderManagersInformation/ProviderManagersInformationForm";
import { ProviderNotificationsSettings } from "../ProviderNotifications/ProviderNotificationsSettings";
import { getProviderUpsertFormSchema, providerUpsertFormDefaultValues, IProviderUpsertForm } from "./ProviderUpsertFormSchema";

type ProviderUpsertFormProps = {
  provider?: Vendor;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (values: IProviderUpsertForm) => void;
};

export const ProviderUpsertForm: FC<ProviderUpsertFormProps> = props => {
  const { buttonLabel, provider, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IProviderUpsertForm>({
    defaultValues: providerUpsertFormDefaultValues,
    schema: getProviderUpsertFormSchema(!provider),
  });

  const { watch, handleSubmit, setValue, setValues } = form;

  const hasMultipleBranches = watch("hasMultipleBranches");
  const subscriptionPlanId = watch("subscriptionPlan")?.value?.id;

  const { data, loading } = useProviderPlanGetQuery({
    variables: {
      id: subscriptionPlanId!,
    },
    skip: !subscriptionPlanId,
  });

  const subscriptionPlan = data?.plan as Plan;

  const onSubmit = (values: IProviderUpsertForm) => {
    onChange(values);
  };

  useEffect(() => {
    if (provider) {
      const _provider = convertProviderToFormValues(provider);
      setValues(_provider);
    }
  }, [provider, setValues]);

  useEffect(() => {
    if (!subscriptionPlan) return;

    const providerSubscription = provider?.subscriptions?.edges?.[0]?.node;

    if (providerSubscription?.plan?.id === subscriptionPlanId) {
      setValue("subscriptionFixedCostAmount", providerSubscription.fixedCostAmount);
      setValue("subscriptionFixedOrderAmount", providerSubscription.fixedOrderCostAmount);
      setValue("subscriptionFixedOrderPercentage", providerSubscription.fixedOrderPercentage);
      return;
    }

    const _subscriptionPlan = convertProviderSubscriptionToFormValues(subscriptionPlan);

    setValue("subscriptionFixedCostAmount", _subscriptionPlan.fixedCostAmount);
    setValue("subscriptionFixedOrderAmount", _subscriptionPlan.fixedOrderAmount);
    setValue("subscriptionFixedOrderPercentage", _subscriptionPlan.fixedOrderPercentage);
  }, [provider, subscriptionPlan, subscriptionPlanId]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton={provider ? true : !hasMultipleBranches}
              hasAddNewButton={!provider && hasMultipleBranches}
              isLoading={isLoading}
              TitleNewButton={t("Create And Add New Branch")}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormCard title={t("Provider Information")} loading={isLoading} doYouHaveData>
                <ProviderInformationForm isUpdateMode={!!provider} />
              </FormCard>
            </Grid>

            {!provider && (
              <Grid item xs={12}>
                <FormCard title={t("Provider Health License")} loading={isLoading} doYouHaveData>
                  <ProviderHealthLicenseForm />
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Provider Managers Information")} loading={isLoading} doYouHaveData>
                <ProviderManagersInformationForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Provider Subscription")} loading={isLoading} doYouHaveData>
                <ProviderSubscriptionForm isLoading={loading} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Provider Notifications Settings")} loading={isLoading} doYouHaveData>
                <ProviderNotificationsSettings />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Provider Images")} loading={isLoading} doYouHaveData>
                <ProviderImagesForm />
              </FormCard>
            </Grid>

            {!provider && !hasMultipleBranches && (
              <Grid item xs={12}>
                <FormCard title={t("Provider Working Hours")} loading={isLoading} doYouHaveData>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <FormDaysTimesRanges name={"workingHours"} startTimeLabel={t("Open Time")} endTimeLabel={t("Close Time")} />
                    </Grid>
                  </Grid>
                </FormCard>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormCard title={t("Provider Address")} loading={isLoading} doYouHaveData>
                <AddressUpsertForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
