import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import {
  IOptimaActionUpsertFormEvent,
  OptimaActionUpsertForm,
} from "@/pages/OptimaActions/forms/OptimaActionUpsert/OptimaActionUpsertForm";
import { OptimaActionListDocument, useOptimaActionCreateMutation } from "@/pages/OptimaActions/gql";
import { convertOptimaActionFormValuesToBackEndValues } from "@/pages/OptimaActions/others";
import { FC } from "react";

export type IOptimaActionCreateContainerEvent = {
  type: "SUBMIT_SUCCESS";
};

type OptimaActionCreateContainerProps = {
  onChange: (event: IOptimaActionCreateContainerEvent) => void;
};

export const OptimaActionCreateContainer: FC<OptimaActionCreateContainerProps> = props => {
  const { onChange } = props;

  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();

  const [createOptimaAction, { loading: isSubmitting }] = useOptimaActionCreateMutation({
    onCompleted: () => {
      succeeded(t("Optima Action created successfully"));

      onChange({
        type: "SUBMIT_SUCCESS",
      });
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    refetchQueries: [OptimaActionListDocument],
  });

  const onOptimaActionUpsertFormChange = (event: IOptimaActionUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertOptimaActionFormValuesToBackEndValues(event.payload.values);

      createOptimaAction({
        variables: {
          input: values,
        },
      });
    }
  };

  return <OptimaActionUpsertForm buttonLabel={t("Create")} isButtonDisabled={isSubmitting} onChange={onOptimaActionUpsertFormChange} />;
};
