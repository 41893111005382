import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, format } from "@toolkit/ui";
import { useMemo } from "react";
import { ISurveyListNode } from "../../types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { SurveyStatusIndicator } from "../../components/SurveyStatusIndicator/SurveyStatusIndicator";
import { GridCellText } from "@health/domains";
import { SurveyAnsweredParticipantsCount } from "../../components/SurveyParticipantsCount/SurveyParticipantsCount";
import SurveyListActions from "../../components/SurveyListActions/SurveyListActions";
import { getLastPublishedCriteria } from "../../others";

export const useSurveyListContainerColumns = (): CustomTableColumnProps<ISurveyListNode>[] => {
  const { t } = useTranslation("admin");

  const getPublishDateRange = (survey: ISurveyListNode) => {
    const publishCriteria = getLastPublishedCriteria(survey?.publishCriteria);
    if (!publishCriteria) {
      return null;
    }

    return `${format(publishCriteria?.createdDate)} - ${format(publishCriteria?.expirationDate)}`;
  };

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Label"),
        accessor: "label",
        type: "truncated-text",
        filter: {
          type: "string",
          name: "label",
        },
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <SurveyStatusIndicator status={status} />,
        filter: getAutocompleteEnumFilter("SurveyStatus", "status"),
      },
      {
        key: "created-date",
        header: t("Created date"),
        type: "date",
        accessor: "createdDate",
      },
      {
        key: "created-by",
        header: t("Created By"),
        accessor: "createdBy.fullName",
        type: "truncated-text",
      },
      {
        key: "publish-date-range",
        header: t("Publish Date Range"),
        accessor: survey => <GridCellText text={getPublishDateRange(survey)} />,
      },
      {
        key: "participants-count",
        header: t("Participants Count"),
        accessor: survey => getLastPublishedCriteria(survey?.publishCriteria)?.participantsCount,
      },
      {
        key: "answered-participants-count",
        header: t("Answered Participants Count"),
        accessor: survey => <SurveyAnsweredParticipantsCount survey={survey} />,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: survey => <SurveyListActions survey={survey} />,
      },
    ],
    [t]
  );
};
