import { BenefitGroup, BenefitGroupComplex, BenefitOption, Maybe } from "@/schema/types";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, CircularProgress, FormHelperText, Grid, Typography } from "@toolkit/ui";
import { isNil } from "lodash";
import { FC, useEffect, useState } from "react";
import {
  HealthProgramBenefitsGroups,
  IHealthProgramBenefitsGroupsEvent,
} from "../../components/HealthProgramBenefitsGroups/HealthProgramBenefitsGroups";
import { useHealthProgramBenefitGroupListQuery, useHealthProgramBenefitOptionListQuery } from "../../gql";
import { convertHealthProgramBenefitsInitialDataToFormValues, convertHealthProgramBenefitsToFormValues } from "../../others";
import { HealthProgramBenefitsOptionsForm } from "../HealthProgramBenefitsOptions/HealthProgramBenefitsOptionsForm";
import { IHealthProgramUpsertFormValues } from "../HealthProgramUpsert/HealthProgramUpsertFormSchema";

type HealthProgramBenefitsFormProps = {
  healthProgramBenefits?: Maybe<Maybe<BenefitGroupComplex>[]>;
};

export const HealthProgramBenefitsForm: FC<HealthProgramBenefitsFormProps> = props => {
  const { healthProgramBenefits } = props;

  const { t } = useTranslation("admin");

  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number | undefined>(undefined);

  const form = useCustomFormContext<IHealthProgramUpsertFormValues>();

  const {
    setValue,
    watch,
    formState: { errors },
  } = form;

  const benefits = watch("benefits");

  const benefitsErrors =
    Array.isArray(errors?.benefits) && errors?.benefits.length
      ? errors?.benefits
          .map((errorsOptions, index) => ({
            errors: errorsOptions,
            group: benefits?.[index]?.group?.name,
          }))
          ?.filter(Boolean)
      : [];

  const { data: healthProgramBenefitGroupList, loading: isHealthProgramBenefitGroupListLoading } = useHealthProgramBenefitGroupListQuery({
    fetchPolicy: "no-cache",
  });

  const { data: healthProgramBenefitOptionList, loading: isHealthProgramBenefitOptionListLoading } = useHealthProgramBenefitOptionListQuery(
    {
      fetchPolicy: "no-cache",
    }
  );

  const benefitsGroups = healthProgramBenefitGroupList?.healthProgramBenefitGroups as BenefitGroup[];
  const benefitsOptions = healthProgramBenefitOptionList?.healthProgramBenefitGroupOptions as BenefitOption[];

  const isLoading = isHealthProgramBenefitGroupListLoading || isHealthProgramBenefitOptionListLoading;

  const onHealthProgramBenefitGroupChange = (event: IHealthProgramBenefitsGroupsEvent) => {
    const { index } = event.payload;

    if (event.type === "CLICK") {
      setSelectedGroupIndex(index);
    } else if (event.type === "CHECK_ALL") {
      setValue(
        `benefits.${index}.options`,
        benefits?.[index]?.options?.map(option => ({
          ...option,
          isAdded: true,
        }))
      );
    } else if (event.type === "UNCHECK_ALL") {
      setValue(
        `benefits.${index}.options`,
        benefits?.[index]?.options?.map(option => ({
          ...option,
          isAdded: false,
          name: undefined,
          value: undefined,
        }))
      );
    }
  };

  useEffect(() => {
    if (benefitsGroups && benefitsOptions) {
      if (healthProgramBenefits) {
        const _benefits = convertHealthProgramBenefitsToFormValues(healthProgramBenefits, benefitsGroups, benefitsOptions);
        setValue("benefits", _benefits);

        const selectedGroup = _benefits?.findIndex(item => item?.options?.some(option => option?.isAdded));
        setSelectedGroupIndex(selectedGroup);
      } else {
        const _benefits = convertHealthProgramBenefitsInitialDataToFormValues(benefitsGroups, benefitsOptions);
        setValue("benefits", _benefits);
      }
    }
  }, [benefitsGroups, benefitsOptions, healthProgramBenefits, setValue]);

  return (
    <Box>
      {isLoading ? (
        <CircularProgress color={"inherit"} size={30} />
      ) : (
        <Grid container spacing={2}>
          {Array.isArray(benefitsErrors) && benefitsErrors.length ? (
            <Grid item xs={12}>
              {benefitsErrors.map(item => (
                <FormHelperText error key={item?.group}>
                  <Typography>{t("{{group}} has required values", { group: item?.group })}</Typography>
                </FormHelperText>
              ))}
            </Grid>
          ) : null}

          <Grid item xs={3}>
            <HealthProgramBenefitsGroups
              benefits={benefits}
              selectedGroupIndex={selectedGroupIndex}
              onChange={onHealthProgramBenefitGroupChange}
            />
          </Grid>

          {!isNil(selectedGroupIndex) && (
            <Grid item xs={9}>
              <HealthProgramBenefitsOptionsForm benefits={benefits} selectedGroupIndex={selectedGroupIndex!} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
