import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.VendorUpdateAdminInput;
}>;


export type ProviderUpdateMutation = { __typename?: 'Mutation', vendorUpdate?: { __typename?: 'VendorUpdate', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null }> } | null };


export const ProviderUpdateDocument = gql`
    mutation ProviderUpdate($id: ID!, $input: VendorUpdateAdminInput!) {
  vendorUpdate(id: $id, input: $input) {
    vendorErrors {
      field
      message
    }
  }
}
    `;
export type ProviderUpdateMutationFn = Apollo.MutationFunction<ProviderUpdateMutation, ProviderUpdateMutationVariables>;

/**
 * __useProviderUpdateMutation__
 *
 * To run a mutation, you first call `useProviderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerUpdateMutation, { data, loading, error }] = useProviderUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProviderUpdateMutation, ProviderUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderUpdateMutation, ProviderUpdateMutationVariables>(ProviderUpdateDocument, options);
      }
export type ProviderUpdateMutationHookResult = ReturnType<typeof useProviderUpdateMutation>;
export type ProviderUpdateMutationResult = Apollo.MutationResult<ProviderUpdateMutation>;
export type ProviderUpdateMutationOptions = Apollo.BaseMutationOptions<ProviderUpdateMutation, ProviderUpdateMutationVariables>;