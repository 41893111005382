import { CodeSystemCode } from "@/schema/types";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { ExcelIcon, MenuItem, Typography, useAddToast, useTheme } from "@toolkit/ui";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UploadDialog } from "@/shared/components";
import { adminManagedListsPaths } from "../../../constants";
import { useUploadCodeSystemFileMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const ImportConceptsDialog: FC<{ onClick: () => void }> = ({ onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("domains");
  const { succeeded, failed } = useAddToast();
  const { code } = useAdminManagedListDetailsPageContext();
  const [dialogLoading, setDialogLoading] = useState(false);
  const [hasUploadFileErrors, setHasUploadFileErrors] = useState(false);
  const [errorUploadMessage, setErrorUploadMessage] = useState("");
  const { open: isOpen, handleClose, handleOpen } = useOpenState();

  const [uploadCodeSystemFile] = useUploadCodeSystemFileMutation({
    onCompleted: data => {
      setDialogLoading(false);
      succeeded(t("Excel file has been imported successfully"));
      onDialogClose();
      if (data.uploadCodeSystemFile?.id) {
        navigate(adminManagedListsPaths.detailsPath.fullPathWithParams(code!, data.uploadCodeSystemFile?.id));
        handleClose();
      }
    },
    onError: error => {
      setDialogLoading(false);
      setHasUploadFileErrors(true);
      setErrorUploadMessage(error?.message);
      failed(t("Import Failed"));
    },
  });

  const handleUploadCodeSystemFile = file => {
    setDialogLoading(true);
    setHasUploadFileErrors(false);
    uploadCodeSystemFile({
      variables: {
        codeSystemCode: code as CodeSystemCode,
        file,
      },
    });
  };
  const onDialogClose = () => {
    handleClose();
    setHasUploadFileErrors(false);
    setErrorUploadMessage("");
  };

  const onResetErrorMessage = () => {
    setHasUploadFileErrors(false);
    setErrorUploadMessage("");
  };
  const handleClick = () => {
    handleOpen();
    onClick();
  };
  const {
    palette: { primary },
  } = useTheme();
  return (
    <>
      <MenuItem onClick={handleClick}>
        <ExcelIcon fill={primary.main} />
        <Typography marginLeft='8px'> {t("Import")} .xlsx</Typography>
      </MenuItem>
      <UploadDialog
        isDialogLoading={dialogLoading}
        isOpen={isOpen}
        onFileUploadedSuccessfully={handleUploadCodeSystemFile}
        handleClose={onDialogClose}
        handleResetError={onResetErrorMessage}
        hasErrors={hasUploadFileErrors}
        errorMessage={errorUploadMessage}
        title={t("Upload list")}
        subTitle={t("Upload Med List You Want To Get")}
      />
    </>
  );
};
