import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworksAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramNetworkFilterInput>;
}>;


export type HealthProgramNetworksAutocompleteQuery = { __typename?: 'Query', healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null } }> } | null };


export const HealthProgramNetworksAutocompleteDocument = gql`
    query HealthProgramNetworksAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramNetworkFilterInput) {
  healthProgramNetworks(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;

/**
 * __useHealthProgramNetworksAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworksAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworksAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworksAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramNetworksAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>(HealthProgramNetworksAutocompleteDocument, options);
      }
export function useHealthProgramNetworksAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>(HealthProgramNetworksAutocompleteDocument, options);
        }
export function useHealthProgramNetworksAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>(HealthProgramNetworksAutocompleteDocument, options);
        }
export type HealthProgramNetworksAutocompleteQueryHookResult = ReturnType<typeof useHealthProgramNetworksAutocompleteQuery>;
export type HealthProgramNetworksAutocompleteLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworksAutocompleteLazyQuery>;
export type HealthProgramNetworksAutocompleteSuspenseQueryHookResult = ReturnType<typeof useHealthProgramNetworksAutocompleteSuspenseQuery>;
export type HealthProgramNetworksAutocompleteQueryResult = Apollo.QueryResult<HealthProgramNetworksAutocompleteQuery, HealthProgramNetworksAutocompleteQueryVariables>;