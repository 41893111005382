import { CptHcpcPrimaryProcedure, CptHcpcPrimaryProcedureInput } from "@/schema/types";
import { ICptHcpcPrimaryProcedureUpsertFormValues } from "../forms/CptHcpcPrimaryProcedureUpsert/cptHcpcPrimaryProcedureUpsertFormSchema";

export const convertCptHcpcPrimaryProcedureToFormValues = (
  cptHcpcPrimaryProcedure: CptHcpcPrimaryProcedure
): ICptHcpcPrimaryProcedureUpsertFormValues => {
  return {
    primaryProcedure: cptHcpcPrimaryProcedure.primaryProcedure,
    itemCode: cptHcpcPrimaryProcedure.itemCode,
    description: cptHcpcPrimaryProcedure.description ?? undefined,
  };
};

export const convertCptHcpcPrimaryProcedureFormValuesToBackEndValues = (
  values: ICptHcpcPrimaryProcedureUpsertFormValues
): CptHcpcPrimaryProcedureInput => {
  return {
    primaryProcedure: values.primaryProcedure,
    itemCode: values.itemCode,
    description: values.description,
  };
};
