import { Box, Typography } from "@toolkit/ui";
import React, { FC } from "react";

export const CardContent: FC<{ title: string; subTitle?: string; flex?: "row" | "column" }> = ({ title, subTitle, flex = "row" }) => {
  return (
    <Box display={"flex"} flex={1} flexDirection={flex} m={1}>
      <Typography fontWeight={"bold"}>{title} </Typography>
      {subTitle && <Typography variant='regular-sm'>{subTitle}</Typography>}
    </Box>
  );
};
