import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareJourneyListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.GuidedCareJourneyFilterInput>;
  sortBy?: Types.InputMaybe<Types.GuidedCareJourneySortingInput>;
}>;


export type GuidedCareJourneyListQuery = { __typename?: 'Query', guidedCareJourneys?: { __typename?: 'GuidedCareJourneyCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'GuidedCareJourneyCountableEdge', node: { __typename?: 'GuidedCareJourney', id: string, activities?: string | null, adherence?: number | null } }> } | null };


export const GuidedCareJourneyListDocument = gql`
    query GuidedCareJourneyList($first: Int, $last: Int, $after: String, $before: String, $filter: GuidedCareJourneyFilterInput, $sortBy: GuidedCareJourneySortingInput) {
  guidedCareJourneys(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        activities
        adherence
      }
    }
  }
}
    `;

/**
 * __useGuidedCareJourneyListQuery__
 *
 * To run a query within a React component, call `useGuidedCareJourneyListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareJourneyListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareJourneyListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareJourneyListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>(GuidedCareJourneyListDocument, options);
      }
export function useGuidedCareJourneyListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>(GuidedCareJourneyListDocument, options);
        }
export function useGuidedCareJourneyListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>(GuidedCareJourneyListDocument, options);
        }
export type GuidedCareJourneyListQueryHookResult = ReturnType<typeof useGuidedCareJourneyListQuery>;
export type GuidedCareJourneyListLazyQueryHookResult = ReturnType<typeof useGuidedCareJourneyListLazyQuery>;
export type GuidedCareJourneyListSuspenseQueryHookResult = ReturnType<typeof useGuidedCareJourneyListSuspenseQuery>;
export type GuidedCareJourneyListQueryResult = Apollo.QueryResult<GuidedCareJourneyListQuery, GuidedCareJourneyListQueryVariables>;