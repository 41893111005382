import { FC } from "react";
import { LinearProgress, LinearProgressProps } from "../../base/mui";
import { useCustomLinearProgressStyle } from "./useCustomLinearProgressStyle";

type CustomLinearProgressProps = Omit<LinearProgressProps, "value" | "color" | "variant"> & {
  value: number;
  color?: string;
  height?: number;
  borderRadius?: number;
};

export const CustomLinearProgress: FC<CustomLinearProgressProps> = props => {
  const { value, color, height = 15, borderRadius = 10, ...rest } = props;

  const { classes } = useCustomLinearProgressStyle({ color, height, borderRadius });

  return <LinearProgress {...rest} variant={"determinate"} value={value} className={classes.root} />;
};
