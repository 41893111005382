import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionTaskDefinitionsAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.TaskDefinitionFilterInput>;
}>;


export type DecisionTaskDefinitionsAutocompleteQuery = { __typename?: 'Query', taskDefinitions?: { __typename?: 'TaskDefinitionCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'TaskDefinitionCountableEdge', node: { __typename?: 'TaskDefinition', id?: string | null, name?: string | null, nameAr?: string | null } }> } | null };


export const DecisionTaskDefinitionsAutocompleteDocument = gql`
    query DecisionTaskDefinitionsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: TaskDefinitionFilterInput) {
  taskDefinitions(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        name: displayAr
        nameAr: displayEn
      }
    }
  }
}
    `;

/**
 * __useDecisionTaskDefinitionsAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionTaskDefinitionsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionTaskDefinitionsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionTaskDefinitionsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionTaskDefinitionsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>(DecisionTaskDefinitionsAutocompleteDocument, options);
      }
export function useDecisionTaskDefinitionsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>(DecisionTaskDefinitionsAutocompleteDocument, options);
        }
export function useDecisionTaskDefinitionsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>(DecisionTaskDefinitionsAutocompleteDocument, options);
        }
export type DecisionTaskDefinitionsAutocompleteQueryHookResult = ReturnType<typeof useDecisionTaskDefinitionsAutocompleteQuery>;
export type DecisionTaskDefinitionsAutocompleteLazyQueryHookResult = ReturnType<typeof useDecisionTaskDefinitionsAutocompleteLazyQuery>;
export type DecisionTaskDefinitionsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDecisionTaskDefinitionsAutocompleteSuspenseQuery>;
export type DecisionTaskDefinitionsAutocompleteQueryResult = Apollo.QueryResult<DecisionTaskDefinitionsAutocompleteQuery, DecisionTaskDefinitionsAutocompleteQueryVariables>;