import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderLinesQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;


export type GetOrderLinesQuery = { __typename?: 'Query', marketplaceOrder?: { __typename?: 'MarketplaceOrder', id: string, orderLines: Array<{ __typename?: 'MarketplaceOrderLine', quantity: number, unitPriceNetAmount: any, product?: { __typename?: 'MarketplaceProduct', id: string, name?: string | null, nameAr?: string | null, mainImageUrl?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null } | null, healthPackage?: { __typename?: 'MarketplaceHealthPackage', id: string, name?: string | null, nameAr?: string | null, mainImageUrl?: string | null, shortDescription?: string | null, shortDescriptionAr?: string | null } | null }> } | null };


export const GetOrderLinesDocument = gql`
    query getOrderLines($orderId: ID!) {
  marketplaceOrder(id: $orderId) {
    id
    orderLines {
      quantity
      unitPriceNetAmount
      product {
        id
        name
        nameAr
        mainImageUrl
        shortDescription
        shortDescriptionAr
      }
      healthPackage {
        id
        name
        nameAr
        mainImageUrl
        shortDescription
        shortDescriptionAr
      }
    }
  }
}
    `;

/**
 * __useGetOrderLinesQuery__
 *
 * To run a query within a React component, call `useGetOrderLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderLinesQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderLinesQuery(baseOptions: Apollo.QueryHookOptions<GetOrderLinesQuery, GetOrderLinesQueryVariables> & ({ variables: GetOrderLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderLinesQuery, GetOrderLinesQueryVariables>(GetOrderLinesDocument, options);
      }
export function useGetOrderLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderLinesQuery, GetOrderLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderLinesQuery, GetOrderLinesQueryVariables>(GetOrderLinesDocument, options);
        }
export function useGetOrderLinesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrderLinesQuery, GetOrderLinesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrderLinesQuery, GetOrderLinesQueryVariables>(GetOrderLinesDocument, options);
        }
export type GetOrderLinesQueryHookResult = ReturnType<typeof useGetOrderLinesQuery>;
export type GetOrderLinesLazyQueryHookResult = ReturnType<typeof useGetOrderLinesLazyQuery>;
export type GetOrderLinesSuspenseQueryHookResult = ReturnType<typeof useGetOrderLinesSuspenseQuery>;
export type GetOrderLinesQueryResult = Apollo.QueryResult<GetOrderLinesQuery, GetOrderLinesQueryVariables>;