import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import { GraphQLFormattedError } from "graphql";

export const useReportAnError = () => {
  const { t } = useTranslation("domains");
  const { addToast } = useToasts();

  return {
    reportError: (param: { message: string; details?: string; e?: Error } | string) => {
      const message = typeof param === "string" ? param : param.message;
      const details = typeof param === "string" ? "" : param.details;
      console.error({ "@health/domains/error": param });
      addToast(message + details ? `\n ${details}` : "");
    },
    reportGenericApiError: (genericMessage: string) => (e: Error) => {
      console.error({ "@health/domains/error": e });
      addToast(genericMessage + ": " + e.message);
    },
    reportGraphQlErrors: (title: string) => (e: readonly GraphQLFormattedError[]) => {
      console.error({ "@health/domains/error": e });
      const formattedMessage = `${title}${pickLocalizedValue(",", " ،")} \n${t(formatGraphQLError(e))}`;
      addToast(formattedMessage, { appearance: "error", autoDismiss: true });
    },
  };
};
