import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthParameterUnitsAutocompleteQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HealthParameterUnitsAutocompleteQuery = { __typename?: 'Query', getHealthParameterUnits?: Array<{ __typename?: 'FieldUnit', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null> | null };


export const HealthParameterUnitsAutocompleteDocument = gql`
    query HealthParameterUnitsAutocomplete {
  getHealthParameterUnits {
    id
    code
    display
    arabicDisplay
  }
}
    `;

/**
 * __useHealthParameterUnitsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthParameterUnitsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterUnitsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParameterUnitsAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthParameterUnitsAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>(HealthParameterUnitsAutocompleteDocument, options);
      }
export function useHealthParameterUnitsAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>(HealthParameterUnitsAutocompleteDocument, options);
        }
export function useHealthParameterUnitsAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>(HealthParameterUnitsAutocompleteDocument, options);
        }
export type HealthParameterUnitsAutocompleteQueryHookResult = ReturnType<typeof useHealthParameterUnitsAutocompleteQuery>;
export type HealthParameterUnitsAutocompleteLazyQueryHookResult = ReturnType<typeof useHealthParameterUnitsAutocompleteLazyQuery>;
export type HealthParameterUnitsAutocompleteSuspenseQueryHookResult = ReturnType<typeof useHealthParameterUnitsAutocompleteSuspenseQuery>;
export type HealthParameterUnitsAutocompleteQueryResult = Apollo.QueryResult<HealthParameterUnitsAutocompleteQuery, HealthParameterUnitsAutocompleteQueryVariables>;