import { SubListItemInput } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, useToasts } from "@toolkit/ui";
import { SublistItemUpsertForm } from "@/pages/Sublists/forms";
import { SublistItemListDocument, useSublistItemCreateMutation } from "@/pages/Sublists/gql";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useReportAnError } from "@/shared/hooks";

type SublistItemCreateFormProps = {
  id: string;
};

export const SublistItemCreateForm: FC<SublistItemCreateFormProps> = props => {
  const { id } = props;

  const { t } = useTranslation("domains");
  const { addToast } = useToasts();
  const { reportGraphQlErrors } = useReportAnError();

  const [fetchSublistItemCreateMutation, { loading: isSubmitting }] = useSublistItemCreateMutation({
    refetchQueries: [SublistItemListDocument],
  });

  const methods = useForm<SubListItemInput>({
    mode: "all",
  });

  const addItem = (input: SubListItemInput) => {
    fetchSublistItemCreateMutation({
      variables: {
        subListId: id,
        input: {
          code: input.code,
          display: input.display,
        },
      },
      onCompleted: e => {
        if (e.addSubListItem?.errors?.length) {
          addToast(t("Failed to add item") + "\n" + formatMessageErrors(e.addSubListItem?.errors), {
            appearance: "error",
            autoDismiss: true,
          });
          return;
        }
        methods.reset();
        addToast(t("Item added successfully"), {
          appearance: "success",
          autoDismiss: true,
        });
      },
      onError: ({ graphQLErrors }) => reportGraphQlErrors(t("Failed to add sublist item"))(graphQLErrors),
    });
  };

  const handleAddListItem = methods.handleSubmit(addItem);

  return (
    <FormCard loading={false} title={t("Add new item")} doYouHaveData={true}>
      <FormProvider {...methods}>
        <SublistItemUpsertForm isSubmitting={isSubmitting} onHandleSubmit={handleAddListItem} submitButtonText={t("Add")} />
      </FormProvider>
    </FormCard>
  );
};
