import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";
import { usersProvidersPaths, usersProvidersRoute } from "./UsersProvidersPaths";
import { AdminUserProviderListContainer, AdminUserProviderCreateContainer, AdminUserProviderUpdateContainer } from "../containers";

export const usersProvidersRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  const hasViewUsersPermission = hasPermission(PermissionEnum.ViewUsers);
  const hasManageUsersPermission = hasPermission(PermissionEnum.ManageUsers);

  return {
    id: "users-providers-routes",
    text: i18n.t("Providers Users", { ns: "admin" }),
    route: usersProvidersRoute,
    isProhibited: !hasViewUsersPermission && !hasManageUsersPermission,
    subItems: [
      {
        id: "user-provider-list-route",
        route: usersProvidersPaths.list.route,
        fullPath: usersProvidersPaths.list.fullPath,
        element: <AdminUserProviderListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-provider-create-route",
        route: usersProvidersPaths.create.route,
        fullPath: usersProvidersPaths.create.fullPath,
        hidden: true,
        element: <AdminUserProviderCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-provider-update-route",
        route: usersProvidersPaths.update.route,
        fullPath: usersProvidersPaths.update.fullPath,
        hidden: true,
        element: <AdminUserProviderUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
