import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSubListItemsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  code?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.SubListItemSortingInput>;
}>;


export type GetSubListItemsQuery = { __typename?: 'Query', items?: { __typename?: 'SubListItemConnection', totalCount?: number | null, pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null, edges?: Array<{ __typename?: 'SubListItemEdge', node?: { __typename: 'SubListItem', code: string, display: string, id?: string | null } | null } | null> | null } | null };


export const GetSubListItemsDocument = gql`
    query getSubListItems($id: ID!, $after: String, $before: String, $code: String, $first: Int, $last: Int, $sortBy: SubListItemSortingInput) {
  items: getSubListItems(
    subListId: $id
    first: $first
    after: $after
    before: $before
    last: $last
    code: $code
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        __typename
        code
        display
        id
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetSubListItemsQuery__
 *
 * To run a query within a React component, call `useGetSubListItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubListItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubListItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      code: // value for 'code'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetSubListItemsQuery(baseOptions: Apollo.QueryHookOptions<GetSubListItemsQuery, GetSubListItemsQueryVariables> & ({ variables: GetSubListItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubListItemsQuery, GetSubListItemsQueryVariables>(GetSubListItemsDocument, options);
      }
export function useGetSubListItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubListItemsQuery, GetSubListItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubListItemsQuery, GetSubListItemsQueryVariables>(GetSubListItemsDocument, options);
        }
export function useGetSubListItemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubListItemsQuery, GetSubListItemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubListItemsQuery, GetSubListItemsQueryVariables>(GetSubListItemsDocument, options);
        }
export type GetSubListItemsQueryHookResult = ReturnType<typeof useGetSubListItemsQuery>;
export type GetSubListItemsLazyQueryHookResult = ReturnType<typeof useGetSubListItemsLazyQuery>;
export type GetSubListItemsSuspenseQueryHookResult = ReturnType<typeof useGetSubListItemsSuspenseQuery>;
export type GetSubListItemsQueryResult = Apollo.QueryResult<GetSubListItemsQuery, GetSubListItemsQueryVariables>;