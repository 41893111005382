import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useOptimaActionsAutocompleteQuery } from "./gql";
import { IOptimaActionsAutocomplete } from "./schema";

export const createOptimaActionAutocompleteOption = (optimaAction: IOptimaActionsAutocomplete) => {
  return createAutocompleteOption({ id: optimaAction?.id!, action: optimaAction?.action! }, "id", "action");
};

export const getOptimaActionsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "optimaActions",
    query: useOptimaActionsAutocompleteQuery,
    labelBy: "action",
    backendAccessor: "id",
  });
};
