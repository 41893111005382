import { HealthProgramMembershipRequest } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { FormTextField, Grid } from "@toolkit/ui";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { useTranslation } from "@toolkit/i18n";
import { FC, useEffect } from "react";
import {
  healthProgramMembershipRequestRejectFormSchema,
  healthProgramMembershipRequestRejectFormDefaultValues,
  IHealthProgramMembershipRequestRejectForm,
} from "./HealthProgramMembershipRequestRejectFormSchema";

export const HealthProgramMembershipRequestRejectFormApi: {
  submit: () => void;
  getForm: () => UseFormReturn<IHealthProgramMembershipRequestRejectForm> | null;
} = {
  submit: () => {},
  getForm: () => null,
};

export type IHealthProgramMembershipRequestRejectFormEvent = {
  type: "SUBMIT";
  payload: {
    healthProgramMembershipRequestId: string;
    values: IHealthProgramMembershipRequestRejectForm;
  };
};

type HealthProgramMembershipRequestRejectFormProps = {
  healthProgramMembershipRequest: HealthProgramMembershipRequest;
  onChange: (event: IHealthProgramMembershipRequestRejectFormEvent) => void;
};

export const HealthProgramMembershipRequestRejectForm: FC<HealthProgramMembershipRequestRejectFormProps> = props => {
  const { healthProgramMembershipRequest, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IHealthProgramMembershipRequestRejectForm>({
    defaultValues: healthProgramMembershipRequestRejectFormDefaultValues,
    schema: healthProgramMembershipRequestRejectFormSchema,
  });

  const { handleSubmit } = form;

  const onSubmit = (values: IHealthProgramMembershipRequestRejectForm) => {
    onChange({
      type: "SUBMIT",
      payload: {
        healthProgramMembershipRequestId: healthProgramMembershipRequest?.id!,
        values,
      },
    });
  };

  useEffect(() => {
    HealthProgramMembershipRequestRejectFormApi.submit = form.handleSubmit(onSubmit);
    HealthProgramMembershipRequestRejectFormApi.getForm = () => form;

    return () => {
      HealthProgramMembershipRequestRejectFormApi.submit = () => {};
      HealthProgramMembershipRequestRejectFormApi.getForm = () => null;
    };
  }, [form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField name={"reason"} multiline rows={4} label={t("Rejection Reason")} placeholder={t("Rejection Reason")} />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
