import { makeStyles } from "@toolkit/ui";

export const useCaseManagementPatientNotesStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    padding: 8,
    gap: 4,
  },
  noteText: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
  noteInfo: {
    fontSize: theme.mixins.fonts.fontSize.xs,
  },
}));
