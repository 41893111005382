import { Maybe } from "@/schema/types";
import { InfoItems } from "@toolkit/ui";

export const getRejectionReasonInfoItems = (reason?: Maybe<string>): InfoItems => {
  const reasonsList = reason
    ?.split(/\d+-/)
    ?.filter(Boolean)
    ?.map(item => item.replace("/n", "").trim());

  return reason
    ? [
        {
          value: reasonsList,
          valueDisplayMode: "list",
        },
      ]
    : undefined;
};
