import { AppTypes, PermissionEnum, PermissionGroupSortField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { usersPermissionsGroupsPaths } from "../../constants";
import { useUserPermissionGroupDeleteMutation, useUsersPermissionsGroupListQuery } from "../../gql";
import { useSetUsersPermissionsGroupsBreadcrumbs } from "../../hooks";
import { IUserPermissionGroupListNode } from "../../types";
import { useUserPermissionGroupListContainerColumns } from "./useUserPermissionGroupListContainerColumns";

export const UserPermissionGroupListContainer = () => {
  const navigate = useNavigate();

  const hasManagePermissionGroupPermission = hasPermission(PermissionEnum.ManagePermissionGroup);

  const onAddNewItemClick = () => {
    navigate(usersPermissionsGroupsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IUserPermissionGroupListNode) => {
    navigate(usersPermissionsGroupsPaths.update.fullPathWithParams({ permissionGroupId: item?.id }));
  };

  useSetUsersPermissionsGroupsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"userPermissionGroupList"}
      query={useUsersPermissionsGroupListQuery}
      columns={useUserPermissionGroupListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: hasManagePermissionGroupPermission,
        onEditRow: onEditRowClick,
        isDeleteVisible: hasManagePermissionGroupPermission,
      }}
      variables={{
        appType: AppTypes.Admin,
        sortBy: {
          field: PermissionGroupSortField.CreatedDate,
          direction: OrderDirection.Desc,
        },
      }}
      deleteItemProps={{
        name: "Permission group",
        entityTypeName: "Group",
        useDeleteMutation: useUserPermissionGroupDeleteMutation,
      }}
      skipUrlState={false}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />

            {hasManagePermissionGroupPermission && <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
