import { i18n } from "@toolkit/i18n";
import { MarketplaceOrderStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const marketplaceOrderStatusOptionsMap: IEnumToOptionsMap<MarketplaceOrderStatus> = {
  [MarketplaceOrderStatus.New]: {
    key: MarketplaceOrderStatus.New,
    get label() {
      return i18n.t("New", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.New,
  },
  [MarketplaceOrderStatus.Cancelled]: {
    key: MarketplaceOrderStatus.Cancelled,
    get label() {
      return i18n.t("Cancelled", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.Cancelled,
  },
  [MarketplaceOrderStatus.Accepted]: {
    key: MarketplaceOrderStatus.Accepted,
    get label() {
      return i18n.t("Accepted", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.Accepted,
  },
  [MarketplaceOrderStatus.Rejected]: {
    key: MarketplaceOrderStatus.Rejected,
    get label() {
      return i18n.t("Rejected", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.Rejected,
  },
  [MarketplaceOrderStatus.Consumed]: {
    key: MarketplaceOrderStatus.Consumed,
    get label() {
      return i18n.t("Consumed", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.Consumed,
  },
  [MarketplaceOrderStatus.Delivered]: {
    key: MarketplaceOrderStatus.Delivered,
    get label() {
      return i18n.t("Delivered", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.Delivered,
  },
  [MarketplaceOrderStatus.OutForDelivery]: {
    key: MarketplaceOrderStatus.OutForDelivery,
    get label() {
      return i18n.t("Out for delivery", { ns: "domains" });
    },
    value: MarketplaceOrderStatus.OutForDelivery,
  },
};

export const marketplaceOrderStatusOptions = Object.values(marketplaceOrderStatusOptionsMap);
