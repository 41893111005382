import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SublistDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type SublistDeleteMutation = { __typename?: 'Mutation', deleteSubList?: { __typename?: 'SubList', errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const SublistDeleteDocument = gql`
    mutation SublistDelete($id: ID!) {
  deleteSubList(id: $id) {
    errors {
      code
      message
    }
  }
}
    `;
export type SublistDeleteMutationFn = Apollo.MutationFunction<SublistDeleteMutation, SublistDeleteMutationVariables>;

/**
 * __useSublistDeleteMutation__
 *
 * To run a mutation, you first call `useSublistDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSublistDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sublistDeleteMutation, { data, loading, error }] = useSublistDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSublistDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SublistDeleteMutation, SublistDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SublistDeleteMutation, SublistDeleteMutationVariables>(SublistDeleteDocument, options);
      }
export type SublistDeleteMutationHookResult = ReturnType<typeof useSublistDeleteMutation>;
export type SublistDeleteMutationResult = Apollo.MutationResult<SublistDeleteMutation>;
export type SublistDeleteMutationOptions = Apollo.BaseMutationOptions<SublistDeleteMutation, SublistDeleteMutationVariables>;