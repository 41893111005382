import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { HealthParameterCategoryFilterInput } from "../../schema/types";
import { useHealthParameterCategoriesAutocompleteQuery } from "./gql";

type HealthParameterCategoriesAutocompleteProps = IAutocompleteProps<HealthParameterCategoryFilterInput>;

export const HealthParameterCategoriesAutocomplete: FC<HealthParameterCategoriesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useHealthParameterCategoriesAutocompleteQuery,
    variables: { filter },
    searchKey: pickLocalizedValue("display", "arabicDisplay"),
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.display!, item?.arabicDisplay!));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Categories") : t("Category"))}
    />
  );
};
