import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type UserFragmentFragment = { __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, note?: string | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean, vendor?: { __typename?: 'Vendor', id: string, name: string } | null, branches?: Array<{ __typename?: 'Branch', id: string, name: string } | null> | null, permissionGroups?: Array<{ __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', name: string, code?: Types.PermissionEnum | null } | null> | null } | null> | null };

export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  firstName
  lastName
  mobile
  isStaff
  isSuperuser
  isVendorAdmin
  note
  healthLicenseNumber
  healthLicenseStartDate
  healthLicenseEndDate
  appType
  appRole
  isActive
  vendor {
    id
    name
  }
  branches {
    id
    name
  }
  permissionGroups {
    id
    name
    permissions {
      name
      code
    }
  }
}
    `;