import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublishSurveyMutationVariables = Types.Exact<{
  publishSurveyId: Types.Scalars['ID']['input'];
  input: Types.PublishCriteriaInput;
}>;


export type PublishSurveyMutation = { __typename?: 'Mutation', publishSurvey?: { __typename?: 'Survey', id?: string | null, label?: string | null, status?: Types.SurveyStatus | null, errors?: Array<{ __typename?: 'SurveyGraphqlError', code?: Types.SurveyErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null };


export const PublishSurveyDocument = gql`
    mutation PublishSurvey($publishSurveyId: ID!, $input: PublishCriteriaInput!) {
  publishSurvey(id: $publishSurveyId, input: $input) {
    errors {
      code
      errorType
      field
      message
    }
    id
    label
    status
  }
}
    `;
export type PublishSurveyMutationFn = Apollo.MutationFunction<PublishSurveyMutation, PublishSurveyMutationVariables>;

/**
 * __usePublishSurveyMutation__
 *
 * To run a mutation, you first call `usePublishSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSurveyMutation, { data, loading, error }] = usePublishSurveyMutation({
 *   variables: {
 *      publishSurveyId: // value for 'publishSurveyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishSurveyMutation(baseOptions?: Apollo.MutationHookOptions<PublishSurveyMutation, PublishSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishSurveyMutation, PublishSurveyMutationVariables>(PublishSurveyDocument, options);
      }
export type PublishSurveyMutationHookResult = ReturnType<typeof usePublishSurveyMutation>;
export type PublishSurveyMutationResult = Apollo.MutationResult<PublishSurveyMutation>;
export type PublishSurveyMutationOptions = Apollo.BaseMutationOptions<PublishSurveyMutation, PublishSurveyMutationVariables>;