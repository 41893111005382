import { Patient } from "@/schema/types";
import { PatientProfileDrawer } from "@/shared/components/PatientProfileDrawer/PatientProfileDrawer";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal, ShowButton } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { CaseManagementPatientActions } from "../../components/CaseManagementPatientActions/CaseManagementPatientActions";
import { CaseManagementPatientNotesDrawerApi } from "../../components/CaseManagementPatientNotesDrawer/CaseManagementPatientNotesDrawer";
import { CaseManagementPatientDigitalTwinModal } from "../../modals/CaseManagementPatientDigitalTwin/CaseManagementPatientDigitalTwinModal";
import { CaseManagementPatientTeamsEnrolledModal } from "../../modals/CaseManagementPatientTeamsEnrolled/CaseManagementPatientTeamsEnrolledModal";
import { CaseManagementPatientTeamsSuggestedModal } from "../../modals/CaseManagementPatientTeamsSuggested/CaseManagementPatientTeamsSuggestedModal";
import { RenderActivities } from "./RenderActivities";

export const useCaseManagementPatientListColumns = (): CustomTableColumnProps<Patient>[] => {
  const { t } = useTranslation("admin");

  const onShowNotesClick = useCallback((patientId: string) => {
    CaseManagementPatientNotesDrawerApi.open({ patientId });
  }, []);

  return useMemo(
    () => [
      {
        key: "logo",
        header: "",
        accessor: ({ user }) => <GridImageModal image={user?.photo!} title={t("Logo")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ id, user }) => <PatientProfileDrawer patientId={id} patientName={user?.fullName!} />,
        filter: {
          type: "string",
          name: "firstName_Icontains",
        },
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: ({ user }) => user?.nationalId,
        filter: {
          type: "string",
          name: "nationalIdNumber_Icontains",
        },
      },
      {
        key: "contactNumber",
        header: t("Mobile Number"),
        accessor: "contactNumber",
        type: "mobile",
        filter: {
          type: "string",
          name: "contactNumber_Icontains",
        },
      },
      {
        key: "email",
        header: t("Email"),
        accessor: ({ user }) => user?.email,
        filter: {
          type: "string",
          name: "email_Icontains",
        },
      },
      {
        key: "activities",
        header: t("Activities"),
        accessor: ({ user }) => <RenderActivities id={user?.id!} isActivities={true} />,
      },
      {
        key: "adherence",
        header: t("Adherence"),
        accessor: ({ user }) => <RenderActivities id={user?.id!} isActivities={false} />,
      },
      {
        key: "digitalTwin",
        header: t("Digital Twin"),
        accessor: ({ nationalIdNumber }) => <CaseManagementPatientDigitalTwinModal nationalId={nationalIdNumber!} />,
      },
      {
        key: "enrolledJourneys",
        header: t("Enrolled Journeys"),
        accessor: ({ id }) => <CaseManagementPatientTeamsEnrolledModal patientId={id} />,
      },
      {
        key: "suggestedJourneys",
        header: t("Suggested Journeys"),
        accessor: ({ id }) => <CaseManagementPatientTeamsSuggestedModal patientId={id} />,
      },
      {
        key: "notes",
        header: t("Notes"),
        accessor: ({ id }) => <ShowButton onClick={() => onShowNotesClick(id)} />,
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: item => <CaseManagementPatientActions patient={item} />,
      },
    ],
    [onShowNotesClick, t]
  );
};
