import { useState, useCallback } from "react";

interface DownloadConfig {
  url: string;
  headers?: Record<string, string>;
  filename?: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const useFileDownload = () => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadFile = useCallback(
    async (config: DownloadConfig) => {
      if (isDownloading) return;

      setIsDownloading(true);

      try {
        const response = await fetchFile(config);
        await handleFileDownload(response, config);
        setIsDownloading(false);
        config.onSuccess?.();
      } catch (error) {
        handleError(error, config);
      } finally {
        if (isDownloading) {
          setIsDownloading(false);
        }
      }
    },
    [isDownloading]
  );

  const fetchFile = async (config: DownloadConfig) => {
    const response = await fetch(config.url, {
      method: "GET",
      headers: config.headers,
      redirect: "follow",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  };

  const handleFileDownload = async (response: Response, config: DownloadConfig) => {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", config.filename || "download");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleError = (error: unknown, config: DownloadConfig) => {
    const downloadError = error instanceof Error ? error : new Error("Download failed");
    config.onError?.(downloadError);
  };

  return {
    downloadFile,
    isDownloading,
  };
};
