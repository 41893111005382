import { i18n } from "@toolkit/i18n";
import { CustomerSupportOrderStatusEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const customerSupportOrderStatusOptionsMap: IEnumToOptionsMap<CustomerSupportOrderStatusEnum> = {
  [CustomerSupportOrderStatusEnum.Delivered]: {
    key: CustomerSupportOrderStatusEnum.Delivered,
    get label() {
      return i18n.t("Delivered", { ns: "domains" });
    },
    value: CustomerSupportOrderStatusEnum.Delivered,
  },
  [CustomerSupportOrderStatusEnum.Failed]: {
    key: CustomerSupportOrderStatusEnum.Failed,
    get label() {
      return i18n.t("Failed", { ns: "domains" });
    },
    value: CustomerSupportOrderStatusEnum.Failed,
  },
  [CustomerSupportOrderStatusEnum.Returned]: {
    key: CustomerSupportOrderStatusEnum.Returned,
    get label() {
      return i18n.t("Returned", { ns: "domains" });
    },
    value: CustomerSupportOrderStatusEnum.Returned,
  },
};

export const customerSupportOrderStatusOptions = Object.values(customerSupportOrderStatusOptionsMap);
