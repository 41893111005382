import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemConceptQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetCodeSystemConceptQuery = { __typename?: 'Query', getCodeSystemConcept?: { __typename?: 'CodeSystemConcept', id?: string | null, code?: string | null, display?: string | null, properties?: Array<{ __typename?: 'CodeSystemConceptProperty', code?: string | null, fieldType?: Types.Type | null, valueDate?: any | null, valueBoolean?: boolean | null, valueFloat?: number | null, valueInteger?: number | null, valueString?: string | null } | null> | null } | null };


export const GetCodeSystemConceptDocument = gql`
    query getCodeSystemConcept($id: ID!) {
  getCodeSystemConcept(id: $id) {
    id
    code
    display
    properties {
      code
      fieldType
      valueDate
      valueBoolean
      valueFloat
      valueInteger
      valueString
    }
  }
}
    `;

/**
 * __useGetCodeSystemConceptQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemConceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemConceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemConceptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCodeSystemConceptQuery(baseOptions: Apollo.QueryHookOptions<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables> & ({ variables: GetCodeSystemConceptQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables>(GetCodeSystemConceptDocument, options);
      }
export function useGetCodeSystemConceptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables>(GetCodeSystemConceptDocument, options);
        }
export function useGetCodeSystemConceptSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables>(GetCodeSystemConceptDocument, options);
        }
export type GetCodeSystemConceptQueryHookResult = ReturnType<typeof useGetCodeSystemConceptQuery>;
export type GetCodeSystemConceptLazyQueryHookResult = ReturnType<typeof useGetCodeSystemConceptLazyQuery>;
export type GetCodeSystemConceptSuspenseQueryHookResult = ReturnType<typeof useGetCodeSystemConceptSuspenseQuery>;
export type GetCodeSystemConceptQueryResult = Apollo.QueryResult<GetCodeSystemConceptQuery, GetCodeSystemConceptQueryVariables>;