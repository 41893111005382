import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, PlusIcon } from "@toolkit/ui";
import React, { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ISurveyUpsertFormValues } from "../SurveyUpsert/surveyUpsertFormSchema";
import { useQuestionsFormStyles } from "./useQuestionsForm.styles";
import { SurveyQuestionForm } from "../SurveyQuestion/SurveyQuestionForm";
import { newSurveyQuestionDefaultsValue } from "../../constants/SurveyDefaultsValues";

type SurveyQuestionsFormProps = {
  canManageSurvey: boolean;
  isLoadingSurvey?: boolean;
};

export const SurveyQuestionsForm: FC<SurveyQuestionsFormProps> = props => {
  const { canManageSurvey, isLoadingSurvey } = props;
  const { classes } = useQuestionsFormStyles();

  const { t } = useTranslation("admin");

  const form = useFormContext<ISurveyUpsertFormValues>();

  const { control } = form;

  const { fields, append, remove } = useFieldArray<ISurveyUpsertFormValues, "questions">({
    control,
    name: `questions`,
  });

  const onAddNewQuestion = () => {
    append(newSurveyQuestionDefaultsValue);
  };

  const onRemoveQuestion = (conditionIndex: number) => {
    remove(conditionIndex);
  };
  return (
    <Grid container spacing={2}>
      <Grid container item direction='column' spacing={2}>
        {fields.map((question, index) => (
          <Grid item key={question.id}>
            <SurveyQuestionForm
              questionIndex={index}
              key={question.id}
              questionPath={`questions.${index}`}
              onRemoveQuestion={() => onRemoveQuestion(index)}
            />
          </Grid>
        ))}
        {!isLoadingSurvey && canManageSurvey ? (
          <Grid item mb={12}>
            <Button startIcon={<PlusIcon />} className={classes.addQuestionButton} onClick={onAddNewQuestion}>
              {t("Add Question")}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
