import { InsuranceBenefitApprovalLimitSortingField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { IInsuranceBenefitApprovalLimitListNode } from "../../types";
import { getAutocompleteEnumFilter, optimaEncounterTypeOptionsMap } from "@health/enum-options";
import { getDiscrptionsInfoItems } from "@/pages/OptimaMedicalNecessity/others/cellInfo.utils";

export const useInsuranceBenefitApprovalLimitListContainerColumns =
  (): CustomTableColumnProps<IInsuranceBenefitApprovalLimitListNode>[] => {
    const { t } = useTranslation("admin");

    return useMemo(
      () => [
        {
          key: "itemCode",
          header: t("Item Code"),
          accessor: "itemCode",
          sort: {
            columnEnum: InsuranceBenefitApprovalLimitSortingField.ItemCode,
          },
          filter: {
            type: "string",
            name: "itemCode",
          },
        },
        {
          key: "insuranceLicense",
          header: t("Insurance License"),
          accessor: "insuranceLicense",
          sort: {
            columnEnum: InsuranceBenefitApprovalLimitSortingField.InsuranceLicense,
          },
          filter: {
            type: "string",
            name: "insuranceLicense",
          },
        },
        {
          key: "insurancePolicy",
          header: t("Insurance Policy"),
          accessor: "insurancePolicy",
          sort: {
            columnEnum: InsuranceBenefitApprovalLimitSortingField.InsurancePolicy,
          },
          filter: {
            type: "string",
            name: "insurancePolicy",
          },
        },
        {
          key: "encounterType",
          header: t("Encounter Type"),
          accessor: ({ encounterType }) => optimaEncounterTypeOptionsMap[encounterType]?.label,
          sort: {
            columnEnum: InsuranceBenefitApprovalLimitSortingField.EncounterType,
          },
          filter: getAutocompleteEnumFilter("OptimaEncounterType", "encounterType"),
        },
        {
          key: "limit",
          header: t("Limit"),
          accessor: "limit",
          sort: {
            columnEnum: InsuranceBenefitApprovalLimitSortingField.Limit,
          },
        },
        {
          key: "limitGTE",
          header: t("Limit From"),
          showOnlyForFilterField: true,
          filter: {
            type: "number",
            name: "limitGTE",
          },
        },
        {
          key: "limitLTE",
          header: t("Limit To"),
          showOnlyForFilterField: true,
          filter: {
            type: "number",
            name: "limitLTE",
          },
        },
        {
          key: "source",
          header: t("Source"),
          accessor: "source",
          sort: {
            columnEnum: InsuranceBenefitApprovalLimitSortingField.Source,
          },
          filter: {
            type: "string",
            name: "source",
          },
        },
        {
          key: "description",
          header: t("Description"),
          type: "info",
          infoCellOptions: {
            title: t("Description"),
            items: ({ description }) => getDiscrptionsInfoItems(description),
            disableShowButton: ({ description }) => !description?.length,
          },
        },
      ],
      [t]
    );
  };
