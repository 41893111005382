import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";
import { zodSchema } from "@health/autocompletes";
import { createZodAutocomplete } from "@toolkit/core";
import { zodDaysTimesRangesSchema } from "../../../../zodSchemas";
import { getDefaultDaysTimesRangesValues } from "../../../../utils";

export const userProviderDoctorDetailsFormSchema = z.object({
  specializations: z.array(zodSchema.systemCode),
  languages: z.array(zodSchema.language),
  seniority: zodEnumSchema.doctorSeniority.nullish(),
  notificationEmail: z.string().nullish(),
  yearsOfExperience: z.coerce.number().min(0).max(100).nullish(),
  secondMobileNumber: z.string().nullish(),
  socialLinks: z.array(z.string()),
  bio: z.string().nullish(),
  appointmentSlotTimePeriod: createZodAutocomplete().nullish(),
  appointmentTypes: z.array(zodEnumSchema.appointmentType),
  onlineVisitPrice: z.coerce.number().min(0).nullish(),
  onsiteVisitPrice: z.coerce.number().min(0).nullish(),
  atHomeVisitPrice: z.coerce.number().min(0).nullish(),
  qualifications: z
    .object({
      code: z.string().min(1).nullish(),
      issuer: z.string().nullish(),
      fromDate: z.string().nullish(),
      toDate: z.string().nullish(),
    })
    .nullish(),
  workingHours: z.array(zodDaysTimesRangesSchema),
  isMobileNumberPublic: z.boolean(),
  isSecondMobileNumberPublic: z.boolean(),
  isNationalIdPublic: z.boolean(),
  isLanguagesPublic: z.boolean(),
  isYearsOfExperiencePublic: z.boolean(),
  isDateOfBirthPublic: z.boolean(),
  isSocialLinksPublic: z.boolean(),
  isHealthLicenseNumberPublic: z.boolean(),
});

export type IUserProviderDoctorDetailsForm = z.infer<typeof userProviderDoctorDetailsFormSchema>;

export const userProviderDoctorDetailsFormDefaultValues: Partial<IUserProviderDoctorDetailsForm> = {
  specializations: [],
  languages: [],
  seniority: undefined,
  notificationEmail: undefined,
  yearsOfExperience: undefined,
  secondMobileNumber: undefined,
  socialLinks: [],
  bio: undefined,
  appointmentSlotTimePeriod: undefined,
  appointmentTypes: [],
  onlineVisitPrice: undefined,
  onsiteVisitPrice: undefined,
  atHomeVisitPrice: undefined,
  qualifications: undefined,
  workingHours: getDefaultDaysTimesRangesValues(),
  isMobileNumberPublic: false,
  isSecondMobileNumberPublic: false,
  isNationalIdPublic: false,
  isLanguagesPublic: true,
  isYearsOfExperiencePublic: true,
  isDateOfBirthPublic: false,
  isSocialLinksPublic: true,
  isHealthLicenseNumberPublic: true,
};
