import { i18n } from "@toolkit/i18n";
import { pick } from "lodash";
import { MarketplaceApprovalStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const marketplaceApprovalStatusOptionsMap: IEnumToOptionsMap<MarketplaceApprovalStatus> = {
  [MarketplaceApprovalStatus.Draft]: {
    key: MarketplaceApprovalStatus.Draft,
    get label() {
      return i18n.t("Draft", { ns: "domains" });
    },
    value: MarketplaceApprovalStatus.Draft,
  },
  [MarketplaceApprovalStatus.PendingApproval]: {
    key: MarketplaceApprovalStatus.PendingApproval,
    get label() {
      return i18n.t("Pending Approval", { ns: "domains" });
    },
    value: MarketplaceApprovalStatus.PendingApproval,
  },
  [MarketplaceApprovalStatus.Approved]: {
    key: MarketplaceApprovalStatus.Approved,
    get label() {
      return i18n.t("Approved", { ns: "domains" });
    },
    value: MarketplaceApprovalStatus.Approved,
  },
  [MarketplaceApprovalStatus.Rejected]: {
    key: MarketplaceApprovalStatus.Rejected,
    get label() {
      return i18n.t("Rejected", { ns: "domains" });
    },
    value: MarketplaceApprovalStatus.Rejected,
  },
};

export const marketplaceApprovalStatusOptions = Object.values(marketplaceApprovalStatusOptionsMap);

export const marketplaceApprovalStatusNoDraftOptions = Object.values(
  pick(marketplaceApprovalStatusOptionsMap, [
    MarketplaceApprovalStatus.Approved,
    MarketplaceApprovalStatus.PendingApproval,
    MarketplaceApprovalStatus.Rejected,
  ])
);
