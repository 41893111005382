import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSurveyStatisticsQueryVariables = Types.Exact<{
  surveyStatisticsId: Types.Scalars['ID']['input'];
}>;


export type FetchSurveyStatisticsQuery = { __typename?: 'Query', surveyStatistics?: { __typename?: 'SurveyStatistics', questionsStatistics?: Array<{ __typename?: 'questionsStatistics', participantsCount?: number | null, answeredParticipantsCount?: number | null, question?: { __typename?: 'Question', id?: string | null, questionType?: Types.QuestionType | null, options?: Array<string | null> | null, question?: string | null } | null, optionsStatistics?: Array<{ __typename?: 'optionsStatistics', option?: string | null, percentage?: number | null } | null> | null } | null> | null } | null };


export const FetchSurveyStatisticsDocument = gql`
    query fetchSurveyStatistics($surveyStatisticsId: ID!) {
  surveyStatistics(id: $surveyStatisticsId) {
    questionsStatistics {
      participantsCount
      answeredParticipantsCount
      question {
        id
        questionType
        options
        question
      }
      optionsStatistics {
        option
        percentage
      }
    }
  }
}
    `;

/**
 * __useFetchSurveyStatisticsQuery__
 *
 * To run a query within a React component, call `useFetchSurveyStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSurveyStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSurveyStatisticsQuery({
 *   variables: {
 *      surveyStatisticsId: // value for 'surveyStatisticsId'
 *   },
 * });
 */
export function useFetchSurveyStatisticsQuery(baseOptions: Apollo.QueryHookOptions<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables> & ({ variables: FetchSurveyStatisticsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables>(FetchSurveyStatisticsDocument, options);
      }
export function useFetchSurveyStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables>(FetchSurveyStatisticsDocument, options);
        }
export function useFetchSurveyStatisticsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables>(FetchSurveyStatisticsDocument, options);
        }
export type FetchSurveyStatisticsQueryHookResult = ReturnType<typeof useFetchSurveyStatisticsQuery>;
export type FetchSurveyStatisticsLazyQueryHookResult = ReturnType<typeof useFetchSurveyStatisticsLazyQuery>;
export type FetchSurveyStatisticsSuspenseQueryHookResult = ReturnType<typeof useFetchSurveyStatisticsSuspenseQuery>;
export type FetchSurveyStatisticsQueryResult = Apollo.QueryResult<FetchSurveyStatisticsQuery, FetchSurveyStatisticsQueryVariables>;