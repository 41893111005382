import { i18n } from "@toolkit/i18n";
import { CardsIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { healthProgramMembershipRequestsRoute, healthProgramMembershipRequestsPaths } from "./HealthProgramMembershipRequestsPaths";
import { HealthProgramMembershipRequestListContainer } from "../containers";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";

export const healthProgramMembershipRequestsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "health-program-membership-requests-routes",
    text: i18n.t("Insurance Approvals", { ns: "admin" }),
    icon: <CardsIcon />,
    route: healthProgramMembershipRequestsRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageHealthPrograms),
    hidden: !hasPermission(PermissionEnum.ManageHealthPrograms),
    subItems: [
      {
        id: "health-program-membership-request-list-route",
        route: healthProgramMembershipRequestsPaths.list.route,
        fullPath: healthProgramMembershipRequestsPaths.list.fullPath,
        element: <HealthProgramMembershipRequestListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
