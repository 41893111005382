import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteSubListItemMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteSubListItemMutation = { __typename?: 'Mutation', deleteSubListItem?: { __typename: 'SubListItem', id?: string | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const DeleteSubListItemDocument = gql`
    mutation deleteSubListItem($id: ID!) {
  deleteSubListItem(id: $id) {
    id
    __typename
    errors {
      field
      code
      message
    }
  }
}
    `;
export type DeleteSubListItemMutationFn = Apollo.MutationFunction<DeleteSubListItemMutation, DeleteSubListItemMutationVariables>;

/**
 * __useDeleteSubListItemMutation__
 *
 * To run a mutation, you first call `useDeleteSubListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubListItemMutation, { data, loading, error }] = useDeleteSubListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubListItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSubListItemMutation, DeleteSubListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSubListItemMutation, DeleteSubListItemMutationVariables>(DeleteSubListItemDocument, options);
      }
export type DeleteSubListItemMutationHookResult = ReturnType<typeof useDeleteSubListItemMutation>;
export type DeleteSubListItemMutationResult = Apollo.MutationResult<DeleteSubListItemMutation>;
export type DeleteSubListItemMutationOptions = Apollo.BaseMutationOptions<DeleteSubListItemMutation, DeleteSubListItemMutationVariables>;