import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentStoresAutocompleteQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type DocumentStoresAutocompleteQuery = { __typename?: 'Query', documentStores?: { __typename?: 'DocumentStoreConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges: Array<{ __typename?: 'DocumentStoreEdge', node: { __typename?: 'DocumentStore', id: string, name?: string | null } }> } | null };


export const DocumentStoresAutocompleteDocument = gql`
    query DocumentStoresAutocomplete($first: Int, $last: Int, $before: String, $after: String) {
  documentStores(first: $first, last: $last, before: $before, after: $after) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useDocumentStoresAutocompleteQuery__
 *
 * To run a query within a React component, call `useDocumentStoresAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentStoresAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentStoresAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDocumentStoresAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>(DocumentStoresAutocompleteDocument, options);
      }
export function useDocumentStoresAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>(DocumentStoresAutocompleteDocument, options);
        }
export function useDocumentStoresAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>(DocumentStoresAutocompleteDocument, options);
        }
export type DocumentStoresAutocompleteQueryHookResult = ReturnType<typeof useDocumentStoresAutocompleteQuery>;
export type DocumentStoresAutocompleteLazyQueryHookResult = ReturnType<typeof useDocumentStoresAutocompleteLazyQuery>;
export type DocumentStoresAutocompleteSuspenseQueryHookResult = ReturnType<typeof useDocumentStoresAutocompleteSuspenseQuery>;
export type DocumentStoresAutocompleteQueryResult = Apollo.QueryResult<DocumentStoresAutocompleteQuery, DocumentStoresAutocompleteQueryVariables>;