import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { HealthProgramTemplateFilterInput } from "../../schema/types";
import { useGuidedCareTemplatesAutocompleteQuery } from "./gql";

type GuidedCareTemplatesAutocompleteProps = IAutocompleteProps<HealthProgramTemplateFilterInput>;

export const GuidedCareTemplatesAutocomplete: FC<GuidedCareTemplatesAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useGuidedCareTemplatesAutocompleteQuery,
    variables: { filter },
    searchKey: "name_Icontains",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "name");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Templates") : t("Template"))}
    />
  );
};
