import { makeStyles } from "@toolkit/ui";

export const useOptimaPatientProfileCardStyle = makeStyles()(theme => ({
  root: {
    display: "flex",
    gap: 24,
    justifyContent: "center",
    alignItems: "center",
  },
  rootWithPaper: {
    padding: 20,
    borderRadius: 10,
    boxShadow: theme.shadows[1],
    backgroundColor: "inherit",
  },
  iconWrapper: {
    background: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    paddingBlock: 8,
    paddingInline: 12,
    border: `1px solid`,
    borderColor: theme.palette.gray.light,
    height: 74,
    width: 74,
    "& svg": {
      width: 32,
      height: 40,
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    gap: 4,
  },
  name: {
    fontSize: theme.mixins.fonts.fontSize.lg,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textTransform: "capitalize",
  },
  patientId: {
    fontSize: theme.mixins.fonts.fontSize.sm,
  },
  age: {
    color: theme.palette.gray.main,
    textTransform: "capitalize",
  },
}));
