import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "../../types";
import { ApprovalStatus } from "../../schema/types";

export const approvalStatusTypeOptionsMap: IEnumToOptionsMap<ApprovalStatus> = {
  [ApprovalStatus.PartiallyApproved]: {
    key: ApprovalStatus.PartiallyApproved,
    get label() {
      return i18n.t("Partially Approved", { ns: "domains" });
    },
    value: ApprovalStatus.PartiallyApproved,
  },
  [ApprovalStatus.FullyApproved]: {
    key: ApprovalStatus.FullyApproved,
    get label() {
      return i18n.t("Fully Approved", { ns: "domains" });
    },
    value: ApprovalStatus.FullyApproved,
  },
  [ApprovalStatus.Rejected]: {
    key: ApprovalStatus.Rejected,
    get label() {
      return i18n.t("Rejected", { ns: "domains" });
    },
    value: ApprovalStatus.Rejected,
  },
};

export const approvalStatusTypeOptions = Object.values(approvalStatusTypeOptionsMap);
