import { AdminUserCreateInput, AppRoleTypes, AppTypes, Group, Maybe, User, UserUpdateInput } from "@/schema/types";
import { appRoleTypeOptionsMap } from "@health/enum-options";
import { createPermissionGroupAutocompleteOption } from "@health/autocompletes";
import { IUserAdminUpsertFormValues } from "../forms/UserAdminUpsert/UserAdminUpsertFormSchema";

export const convertUserAdminToFormValues = (user: User): IUserAdminUpsertFormValues => {
  return {
    firstName: user?.firstName!,
    lastName: user?.lastName!,
    nationalId: user?.nationalId!,
    mobile: user?.mobile!,
    email: user?.email!,
    password: "••••••••",
    appRole: appRoleTypeOptionsMap[user?.appRole!],
    permissionsGroups: user?.permissionGroups?.length
      ? user?.permissionGroups?.map(item => createPermissionGroupAutocompleteOption(item!))
      : [],
    isActive: user?.isActive,
  };
};

export const convertUserAdminCreateFormValuesToBackEndValues = (values: IUserAdminUpsertFormValues): AdminUserCreateInput => {
  return {
    firstName: values?.firstName,
    lastName: values?.lastName,
    nationalId: values?.nationalId,
    mobile: values?.mobile,
    email: values?.email,
    password: values?.password!,
    appRole: values?.appRole?.value,
    appType: AppTypes.Admin,
    addGroups: values?.appRole?.value === AppRoleTypes.User ? values?.permissionsGroups?.map(item => item?.value?.id) : undefined,
    isActive: values?.isActive,
  };
};

export const convertUserAdminUpdateFormValuesToBackEndValues = (
  values: IUserAdminUpsertFormValues,
  oldPermissionGroups?: Maybe<Maybe<Group>[]>
): UserUpdateInput => {
  const formPermissionsGroups = values?.permissionsGroups?.map(item => item?.value?.id);
  const userPermissionsGroups = oldPermissionGroups?.map(item => item?.id!) || [];
  const removedPermissionsGroups = userPermissionsGroups?.filter(item => !formPermissionsGroups?.includes(item));

  return {
    firstName: values?.firstName,
    lastName: values?.lastName,
    mobile: values?.mobile,
    appRole: values?.appRole?.value,
    addGroups: values?.appRole?.value === AppRoleTypes.User ? formPermissionsGroups : [],
    removeGroups: values?.appRole?.value === AppRoleTypes.User ? removedPermissionsGroups : [],
    isActive: values?.isActive,
  };
};
