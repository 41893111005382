import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { UserFilterInput } from "../../schema/types";
import { useUsersAutocompleteQuery } from "./gql";

type UsersAutocompleteProps = IAutocompleteProps<UserFilterInput>;

export const UsersAutocomplete: FC<UsersAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useUsersAutocompleteQuery,
    variables: { filter },
    searchKey: "search",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", "fullName");

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Users") : t("User"))}
    />
  );
};
