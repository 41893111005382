import { pickLocalizedValue } from "@toolkit/i18n";
import React from "react";
import {
  KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRightIcon,
  MuiArrowBackIcon,
  MuiArrowForwardIcon,
} from "../../../../base/mui-icons";
import { Button } from "../../../../base/mui";
import { ITransferListOption } from "../TransferList/TransferList";
import { useTransferListActionsStyle } from "./useTransferListActionsStyle";

export type ITransferListActionsEvent = {
  type: "ALL_RIGHT_CHECK" | "RIGHT_CHECK" | "LEFT_CHECK" | "ALL_LEFT_CHECK";
};

type TransferListActionsProps<T> = {
  left: ITransferListOption<T>[];
  right: ITransferListOption<T>[];
  leftChecked: ITransferListOption<T>[];
  rightChecked: ITransferListOption<T>[];
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (event: ITransferListActionsEvent) => void;
};

export const TransferListActions = <T,>(props: TransferListActionsProps<T>) => {
  const { left, right, leftChecked, rightChecked, isDisabled, isLoading, onChange } = props;

  const { classes } = useTransferListActionsStyle();

  const isButtonDisabled = isDisabled || isLoading;

  const onAllRightCheck = () => {
    onChange({
      type: "ALL_RIGHT_CHECK",
    });
  };

  const onRightCheck = () => {
    onChange({
      type: "RIGHT_CHECK",
    });
  };

  const onLeftCheck = () => {
    onChange({
      type: "LEFT_CHECK",
    });
  };

  const onAllLeftCheck = () => {
    onChange({
      type: "ALL_LEFT_CHECK",
    });
  };

  return (
    <>
      <Button
        variant={"outlined"}
        size={"small"}
        className={classes.button}
        disabled={left?.length === 0 || isButtonDisabled}
        onClick={onAllRightCheck}
      >
        {pickLocalizedValue(<KeyboardDoubleArrowRightIcon />, <KeyboardDoubleArrowLeftIcon />)}
      </Button>

      <Button
        variant={"outlined"}
        size={"small"}
        className={classes.button}
        disabled={leftChecked?.length === 0 || isButtonDisabled}
        onClick={onRightCheck}
      >
        {pickLocalizedValue(<MuiArrowForwardIcon />, <MuiArrowBackIcon />)}
      </Button>

      <Button
        variant={"outlined"}
        size={"small"}
        className={classes.button}
        disabled={rightChecked?.length === 0 || isButtonDisabled}
        onClick={onLeftCheck}
      >
        {pickLocalizedValue(<MuiArrowBackIcon />, <MuiArrowForwardIcon />)}
      </Button>

      <Button
        variant={"outlined"}
        size={"small"}
        className={classes.button}
        disabled={right?.length === 0 || isButtonDisabled}
        onClick={onAllLeftCheck}
      >
        {pickLocalizedValue(<KeyboardDoubleArrowLeftIcon />, <KeyboardDoubleArrowRightIcon />)}
      </Button>
    </>
  );
};
