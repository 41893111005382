import { pickLocalizedValue } from "@toolkit/i18n";
import { useState } from "react";
import { useOrdersLifeCycleQuery } from "../gql/queries";
import { OrdersEventType } from "./types";
import { useOpenState } from "@toolkit/core";

export const useLifeCycleHooks = (prescriptionID: string, number: string) => {
  const [value, setValue] = useState<string[]>([]);

  const { open, handleOpen, handleClose } = useOpenState();

  const handleChange = (e, v: string[]) => {
    setValue(v);
  };

  const { data, loading, fetchMore, refetch } = useOrdersLifeCycleQuery({
    variables: {
      first: 5,
      filter: {
        numbers: [String(number)],
        prescription: prescriptionID,
      },
    },
    fetchPolicy: "no-cache",
    skip: !open,
  });

  const ordersData = data?.orders?.edges?.map(order => ({
    ...order?.node,
    events: [
      ...(order?.node?.orderEvents?.map(event => ({
        ...event,
        branchName: pickLocalizedValue(order?.node?.branch?.name, order?.node?.branch?.nameAr),
        number: order?.node?.number,
        isParentEvent: true,
      })) || []),
      ...(order?.node?.children?.reduce((acc, child) => {
        // flatten/merge an events array
        child?.orderEvents?.forEach(
          event =>
            event &&
            acc.push({
              ...event,
              number: child?.number,
              branchName: pickLocalizedValue(child?.branch?.name, child?.branch?.nameAr) || "",
              isParentEvent: false,
            })
        );
        return acc;
      }, [] as OrdersEventType[]) || []),
    ],
  }));
  const pageInfo = data?.orders?.pageInfo;

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            ...fetchMoreResult,
            orders: {
              ...fetchMoreResult.orders,
              edges: [...(prev?.orders?.edges || []), ...(fetchMoreResult?.orders?.edges || [])],
            },
          });
        },
      });
    }
  };

  const handleSearch = () => {
    refetch({
      filter: {
        prescription: prescriptionID,
        numbers: value?.length ? [String(value)] : [String(number)],
      },
    });
  };

  return {
    open,
    value,
    loading,
    ordersData,
    pageInfo,
    handleChange,
    handleSearch,
    fetchMoreData,
    handleClickOpen: handleOpen,
    handleClickClose: handleClose,
  };
};
