import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { ArticleCategoriesAutocompleteQuery } from "./gql";

export type IArticleCategoryAutocomplete = ExtractNodeType<ArticleCategoriesAutocompleteQuery>;

export const articleCategorySchema = createZodAutocompleteFromType<IArticleCategoryAutocomplete>({
  id: z.string(),
  code: z.string().nullish(),
  display: z.string().nullish(),
  displayAr: z.string().nullish(),
});
