import { i18n } from "@toolkit/i18n";
import { HealthProgramType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const healthProgramTypeOptionsMap: IEnumToOptionsMap<HealthProgramType> = {
  [HealthProgramType.ImmediateCare]: {
    key: HealthProgramType.ImmediateCare,
    get label() {
      return i18n.t("Immediate Care", { ns: "domains" });
    },
    value: HealthProgramType.ImmediateCare,
  },
  [HealthProgramType.ScheduledCare]: {
    key: HealthProgramType.ScheduledCare,
    get label() {
      return i18n.t("Scheduled Care", { ns: "domains" });
    },
    value: HealthProgramType.ScheduledCare,
  },
};

export const healthProgramTypeOptions = Object.values(healthProgramTypeOptionsMap);
