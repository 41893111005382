import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemCodeMaximumQuantityAllowedGetQueryVariables = Types.Exact<{
  getItemCodeMaximumQuantityAllowedId: Types.Scalars['ID']['input'];
}>;


export type ItemCodeMaximumQuantityAllowedGetQuery = { __typename?: 'Query', getItemCodeMaximumQuantityAllowed?: { __typename?: 'ItemCodeMaximumQuantityAllowed', id: string, itemCode: string, maximumAllowed: number, codeSystem: string, description?: string | null } | null };


export const ItemCodeMaximumQuantityAllowedGetDocument = gql`
    query ItemCodeMaximumQuantityAllowedGet($getItemCodeMaximumQuantityAllowedId: ID!) {
  getItemCodeMaximumQuantityAllowed(id: $getItemCodeMaximumQuantityAllowedId) {
    id
    itemCode
    maximumAllowed
    codeSystem
    description
  }
}
    `;

/**
 * __useItemCodeMaximumQuantityAllowedGetQuery__
 *
 * To run a query within a React component, call `useItemCodeMaximumQuantityAllowedGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCodeMaximumQuantityAllowedGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCodeMaximumQuantityAllowedGetQuery({
 *   variables: {
 *      getItemCodeMaximumQuantityAllowedId: // value for 'getItemCodeMaximumQuantityAllowedId'
 *   },
 * });
 */
export function useItemCodeMaximumQuantityAllowedGetQuery(baseOptions: Apollo.QueryHookOptions<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables> & ({ variables: ItemCodeMaximumQuantityAllowedGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables>(ItemCodeMaximumQuantityAllowedGetDocument, options);
      }
export function useItemCodeMaximumQuantityAllowedGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables>(ItemCodeMaximumQuantityAllowedGetDocument, options);
        }
export function useItemCodeMaximumQuantityAllowedGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables>(ItemCodeMaximumQuantityAllowedGetDocument, options);
        }
export type ItemCodeMaximumQuantityAllowedGetQueryHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedGetQuery>;
export type ItemCodeMaximumQuantityAllowedGetLazyQueryHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedGetLazyQuery>;
export type ItemCodeMaximumQuantityAllowedGetSuspenseQueryHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedGetSuspenseQuery>;
export type ItemCodeMaximumQuantityAllowedGetQueryResult = Apollo.QueryResult<ItemCodeMaximumQuantityAllowedGetQuery, ItemCodeMaximumQuantityAllowedGetQueryVariables>;