import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.HealthProgramFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramSortingInput>;
}>;


export type HealthProgramListQuery = { __typename?: 'Query', healthPrograms?: { __typename?: 'HealthProgramCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramCountableEdge', node: { __typename?: 'HealthProgram', id: string, code?: string | null, name?: string | null, isActive?: boolean | null, created?: any | null, healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', totalCount?: number | null } | null, healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', totalCount?: number | null } | null } }> } | null };


export const HealthProgramListDocument = gql`
    query HealthProgramList($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramFilterInput, $sortBy: HealthProgramSortingInput) {
  healthPrograms(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        code
        name
        isActive
        created
        healthProgramMemberLists {
          totalCount
        }
        healthProgramNetworks {
          totalCount
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramListQuery__
 *
 * To run a query within a React component, call `useHealthProgramListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramListQuery, HealthProgramListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramListQuery, HealthProgramListQueryVariables>(HealthProgramListDocument, options);
      }
export function useHealthProgramListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramListQuery, HealthProgramListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramListQuery, HealthProgramListQueryVariables>(HealthProgramListDocument, options);
        }
export function useHealthProgramListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramListQuery, HealthProgramListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramListQuery, HealthProgramListQueryVariables>(HealthProgramListDocument, options);
        }
export type HealthProgramListQueryHookResult = ReturnType<typeof useHealthProgramListQuery>;
export type HealthProgramListLazyQueryHookResult = ReturnType<typeof useHealthProgramListLazyQuery>;
export type HealthProgramListSuspenseQueryHookResult = ReturnType<typeof useHealthProgramListSuspenseQuery>;
export type HealthProgramListQueryResult = Apollo.QueryResult<HealthProgramListQuery, HealthProgramListQueryVariables>;