import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DecisionHealthParameterRiskParameterGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.HealthParameterFilterInput>;
}>;


export type DecisionHealthParameterRiskParameterGetQuery = { __typename?: 'Query', getHealthParameters?: { __typename?: 'HealthParameterConnection', edges?: Array<{ __typename?: 'HealthParameterEdge', node?: { __typename?: 'HealthParameter', riskParameter?: { __typename?: 'HealthParameter', id?: string | null, name?: string | null, nameAr?: string | null } | null } | null } | null> | null } | null };


export const DecisionHealthParameterRiskParameterGetDocument = gql`
    query DecisionHealthParameterRiskParameterGet($filter: HealthParameterFilterInput) {
  getHealthParameters(first: 1, filter: $filter) {
    edges {
      node {
        riskParameter {
          id: code
          name: display
          nameAr: arabicDisplay
        }
      }
    }
  }
}
    `;

/**
 * __useDecisionHealthParameterRiskParameterGetQuery__
 *
 * To run a query within a React component, call `useDecisionHealthParameterRiskParameterGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionHealthParameterRiskParameterGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionHealthParameterRiskParameterGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionHealthParameterRiskParameterGetQuery(baseOptions?: Apollo.QueryHookOptions<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>(DecisionHealthParameterRiskParameterGetDocument, options);
      }
export function useDecisionHealthParameterRiskParameterGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>(DecisionHealthParameterRiskParameterGetDocument, options);
        }
export function useDecisionHealthParameterRiskParameterGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>(DecisionHealthParameterRiskParameterGetDocument, options);
        }
export type DecisionHealthParameterRiskParameterGetQueryHookResult = ReturnType<typeof useDecisionHealthParameterRiskParameterGetQuery>;
export type DecisionHealthParameterRiskParameterGetLazyQueryHookResult = ReturnType<typeof useDecisionHealthParameterRiskParameterGetLazyQuery>;
export type DecisionHealthParameterRiskParameterGetSuspenseQueryHookResult = ReturnType<typeof useDecisionHealthParameterRiskParameterGetSuspenseQuery>;
export type DecisionHealthParameterRiskParameterGetQueryResult = Apollo.QueryResult<DecisionHealthParameterRiskParameterGetQuery, DecisionHealthParameterRiskParameterGetQueryVariables>;