import { IAutocompleteProps } from "@/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { CodeSystemCode, SearchParameter, Type } from "../../schema/types";
import { useCancellationReasonsAutocompleteQuery } from "./gql";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { get, find } from "lodash";

type CancellationReasonsAutocompleteProps = IAutocompleteProps<SearchParameter[]>;

export const CancellationReasonsAutocomplete: FC<CancellationReasonsAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useCancellationReasonsAutocompleteQuery,
    variables: {
      codeSystemCode: CodeSystemCode.CancellationReason,
      searchParameters:
        filter?.map(filterItem => ({
          code: filterItem.code,
          type: Type.String,
          valueString: filterItem.valueString,
        })) || [],
    },
    skip: props.disabled || skip,
  });

  const formattedData = data?.map(item => ({
    id: item.id ?? null,
    code: item.code ?? null,
    display: item.display ?? null,
    arabicDisplay: get(find(get(item, "properties", []), { code: "arabic_display" }), "valueString") ?? null,
  }));

  const options = mapToAutocompleteOptions(formattedData, "code", item => pickLocalizedValue(item.display!, item?.arabicDisplay));

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Cancellation Reasons") : t("Cancellation Reason"))}
    />
  );
};
