import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { ArticlesAutocompleteQuery } from "./gql";

export type IArticleAutocomplete = ExtractNodeType<ArticlesAutocompleteQuery>;

export const articleSchema = createZodAutocompleteFromType<IArticleAutocomplete>({
  id: z.string(),
  title: z.string().nullish(),
  titleAr: z.string().nullish(),
});
