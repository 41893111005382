import { ConditionOperation, Field } from "@/schema/types";
import { conditionOperationOptions, conditionOperationOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { Button, Container, CustomIcon, FormCard, FormToggleButtonGroup, Grid } from "@toolkit/ui";
import { DecisionConditionsForm } from "../DecisionConditions/DecisionConditionsForm";
import { IDecisionUpsertFormValues } from "../DecisionUpsert/DecisionUpsertFormSchema";
import { FC } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { convertDecisionPlanConditionToFormValues } from "../../others";
import { useDecisionConditionsGroupsFormStyle } from "./useDecisionConditionsGroupsFormStyle";

type DecisionConditionsGroupsFormProps = {
  decisionPlanFields: Field[];
};

export const DecisionConditionsGroupsForm: FC<DecisionConditionsGroupsFormProps> = props => {
  const { decisionPlanFields } = props;

  const { t } = useTranslation("domains");
  const { classes, theme } = useDecisionConditionsGroupsFormStyle();

  const form = useFormContext<IDecisionUpsertFormValues>();

  const { fields, append, remove } = useFieldArray<IDecisionUpsertFormValues, "conditionsGroups">({
    control: form.control,
    name: "conditionsGroups",
  });

  const onAddNewConditionGroup = () => {
    const conditionsGroups = {
      outerConditionOperation: conditionOperationOptionsMap[ConditionOperation.And],
      conditions: convertDecisionPlanConditionToFormValues(decisionPlanFields),
    };

    append(conditionsGroups);
  };

  const onRemoveConditionGroup = (index: number) => {
    remove(index);
  };

  return (
    <>
      {fields?.map((item, index) => {
        return (
          <>
            {fields?.length > 1 && index > 0 && (
              <Grid item lg={1} className={classes.root}>
                <Container className={classes.container}>
                  <FormToggleButtonGroup name={`conditionsGroups.${index}.outerConditionOperation`} options={conditionOperationOptions} />
                </Container>
              </Grid>
            )}

            <Grid item xs={12} key={item?.id}>
              <FormCard loading={false} doYouHaveData>
                <DecisionConditionsForm
                  decisionPlanFields={decisionPlanFields}
                  index={index}
                  onRemoveConditionGroup={onRemoveConditionGroup}
                />
              </FormCard>
            </Grid>
          </>
        );
      })}

      <Grid item xs={12} mt={2}>
        <Button
          onClick={onAddNewConditionGroup}
          className={classes.addGroupButton}
          startIcon={<CustomIcon icon='add' color={theme.palette.common.white} />}
        >
          {t("Add New Group")}
        </Button>
      </Grid>
    </>
  );
};
