import { makeStyles } from "@toolkit/ui";

export const useUploadDialogStyle = makeStyles()(theme => ({
  container: {
    height: "100%",
  },
  backDropStyle: {
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: theme.palette.common.black,
      opacity: 0.6,
    },
  },
  paperStyle: {
    minHeight: 460,
    borderRadius: 15,
  },
  dialogContainerStyle: {
    // boxShadow: theme.mixins.shadows.sm,
  },
  dialogContentContainer: { overflowY: "initial" },
  componentRoot: {
    height: 50,
    borderRadius: 10,
  },
  progressRoot: {
    borderRadius: 10,
    backgroundColor: theme.palette.common.black,
  },
  dragArea: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  box: {
    border: `1px ${theme.palette.stale.main} dashed`,
    borderRadius: 4,
    height: 240,
    marginTop: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 2,
  },
}));
