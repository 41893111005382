import { DecisionPlanCategory } from "@/schema/types";
import { DecisionPlansAutocomplete } from "@health/autocompletes";
import { priorityOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { FC } from "react";

type DecisionInformationFormProps = {
  category: DecisionPlanCategory;
  isScript: boolean;
  isUpdateMode: boolean;
};

export const DecisionInformationForm: FC<DecisionInformationFormProps> = props => {
  const { category, isScript, isUpdateMode } = props;

  const { t } = useTranslation("domains");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <DecisionPlansAutocomplete name={"decisionPlan"} disabled={isUpdateMode} filter={{ categories: [category] }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"priority"} label={t("Priority")} options={priorityOptions} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormSwitch name={"isActive"} label={t("Is Active")} />
          </Grid>

          <Grid item xs={3}>
            <FormSwitch name={"isScript"} label={t("Is Script")} />
          </Grid>
        </Grid>
      </Grid>

      {isScript && (
        <Grid item xs={12}>
          <FormTextField name={"script"} label={t("Script")} placeholder={t("Script")} rows={7} multiline />
        </Grid>
      )}
    </Grid>
  );
};
