import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@/schema/types";
import { usersAdminsPaths, usersAdminsRoute } from "./UsersAdminsPaths";
import { UserAdminListContainer, UserAdminCreateContainer, UserAdminUpdateContainer } from "../containers";

export const usersAdminsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  const hasViewUsersPermission = hasPermission(PermissionEnum.ViewUsers);
  const hasManageUsersPermission = hasPermission(PermissionEnum.ManageUsers);

  return {
    id: "users-admins-routes",
    text: i18n.t("Admin Users", { ns: "admin" }),
    route: usersAdminsRoute,
    isProhibited: !hasViewUsersPermission && !hasManageUsersPermission,
    subItems: [
      {
        id: "user-admin-list-route",
        route: usersAdminsPaths.list.route,
        fullPath: usersAdminsPaths.list.fullPath,
        element: <UserAdminListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-admin-create-route",
        route: usersAdminsPaths.create.route,
        fullPath: usersAdminsPaths.create.fullPath,
        hidden: true,
        element: <UserAdminCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "user-admin-update-route",
        route: usersAdminsPaths.update.route,
        fullPath: usersAdminsPaths.update.fullPath,
        hidden: true,
        element: <UserAdminUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
