import { i18n } from "@toolkit/i18n";
import { HealthProgramBenefitOptionCode } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const healthProgramBenefitOptionCodeOptionsMap: IEnumToOptionsMap<HealthProgramBenefitOptionCode> = {
  [HealthProgramBenefitOptionCode.MaxOfFreeAudioVideoCalls]: {
    key: HealthProgramBenefitOptionCode.MaxOfFreeAudioVideoCalls,
    get label() {
      return i18n.t("Max of Free Audio Video Calls", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.MaxOfFreeAudioVideoCalls,
  },
  [HealthProgramBenefitOptionCode.NumberOfProactiveAudioVideoCalls]: {
    key: HealthProgramBenefitOptionCode.NumberOfProactiveAudioVideoCalls,
    get label() {
      return i18n.t("Number of Proactive Audio Video Calls", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.NumberOfProactiveAudioVideoCalls,
  },
  [HealthProgramBenefitOptionCode.NumberOfReactiveAudioVideoCalls]: {
    key: HealthProgramBenefitOptionCode.NumberOfReactiveAudioVideoCalls,
    get label() {
      return i18n.t("Number of Reactive Audio Video Calls", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.NumberOfReactiveAudioVideoCalls,
  },
  [HealthProgramBenefitOptionCode.PatientSharePercentage]: {
    key: HealthProgramBenefitOptionCode.PatientSharePercentage,
    get label() {
      return i18n.t("Patient Share Percentage", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.PatientSharePercentage,
  },
  [HealthProgramBenefitOptionCode.ProactiveAtHomePlannedHealthServices]: {
    key: HealthProgramBenefitOptionCode.ProactiveAtHomePlannedHealthServices,
    get label() {
      return i18n.t("Proactive at Home Planned Health Services", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.ProactiveAtHomePlannedHealthServices,
  },
  [HealthProgramBenefitOptionCode.ProactiveInPersonPlannedHealthServices]: {
    key: HealthProgramBenefitOptionCode.ProactiveInPersonPlannedHealthServices,
    get label() {
      return i18n.t("Proactive in Person Planned Health Services", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.ProactiveInPersonPlannedHealthServices,
  },
  [HealthProgramBenefitOptionCode.ReactiveAtHomeHealthServices]: {
    key: HealthProgramBenefitOptionCode.ReactiveAtHomeHealthServices,
    get label() {
      return i18n.t("Reactive at Home Health Services", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.ReactiveAtHomeHealthServices,
  },
  [HealthProgramBenefitOptionCode.ReactiveInPersonHealthServices]: {
    key: HealthProgramBenefitOptionCode.ReactiveInPersonHealthServices,
    get label() {
      return i18n.t("Reactive in Person Health Services", { ns: "domains" });
    },
    value: HealthProgramBenefitOptionCode.ReactiveInPersonHealthServices,
  },
};

export const healthProgramBenefitOptionCodeOptions = Object.values(healthProgramBenefitOptionCodeOptionsMap);
