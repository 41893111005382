import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaSaveUserActionMutationVariables = Types.Exact<{
  userAction: Types.OptimaUserAction;
}>;


export type OptimaSaveUserActionMutation = { __typename?: 'Mutation', optimaSaveUserActionEvent?: { __typename?: 'OptimaUserActionEvent', id: string, createdDate: any, userId: string, userName?: string | null, userEmail?: string | null, userType?: string | null, action: Types.OptimaUserAction } | null };


export const OptimaSaveUserActionDocument = gql`
    mutation OptimaSaveUserAction($userAction: OptimaUserAction!) {
  optimaSaveUserActionEvent(userAction: $userAction) {
    id
    createdDate
    userId
    userName
    userEmail
    userType
    action
  }
}
    `;
export type OptimaSaveUserActionMutationFn = Apollo.MutationFunction<OptimaSaveUserActionMutation, OptimaSaveUserActionMutationVariables>;

/**
 * __useOptimaSaveUserActionMutation__
 *
 * To run a mutation, you first call `useOptimaSaveUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaSaveUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaSaveUserActionMutation, { data, loading, error }] = useOptimaSaveUserActionMutation({
 *   variables: {
 *      userAction: // value for 'userAction'
 *   },
 * });
 */
export function useOptimaSaveUserActionMutation(baseOptions?: Apollo.MutationHookOptions<OptimaSaveUserActionMutation, OptimaSaveUserActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaSaveUserActionMutation, OptimaSaveUserActionMutationVariables>(OptimaSaveUserActionDocument, options);
      }
export type OptimaSaveUserActionMutationHookResult = ReturnType<typeof useOptimaSaveUserActionMutation>;
export type OptimaSaveUserActionMutationResult = Apollo.MutationResult<OptimaSaveUserActionMutation>;
export type OptimaSaveUserActionMutationOptions = Apollo.BaseMutationOptions<OptimaSaveUserActionMutation, OptimaSaveUserActionMutationVariables>;