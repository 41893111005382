import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramParameterListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthProgramParameterListQuery = { __typename?: 'Query', healthProgram?: { __typename?: 'HealthProgram', id: string, code?: string | null, name?: string | null, subscriptionPeriod?: Types.HealthProgramSubscriptionPeriod | null, pointsDuringNormalDemand?: number | null, pointsDuringUrgentDemand?: number | null, callQualityRatingFactors?: string | null, baselineAvailabilityHours?: number | null, unattendedCallPenaltyFactor?: number | null, mainSpecializationsRateFactors?: string | null } | null };


export const HealthProgramParameterListDocument = gql`
    query HealthProgramParameterList($id: ID!) {
  healthProgram(id: $id) {
    id
    code
    name
    subscriptionPeriod
    pointsDuringNormalDemand
    pointsDuringUrgentDemand
    callQualityRatingFactors
    baselineAvailabilityHours
    unattendedCallPenaltyFactor
    mainSpecializationsRateFactors
  }
}
    `;

/**
 * __useHealthProgramParameterListQuery__
 *
 * To run a query within a React component, call `useHealthProgramParameterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramParameterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramParameterListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramParameterListQuery(baseOptions: Apollo.QueryHookOptions<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables> & ({ variables: HealthProgramParameterListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables>(HealthProgramParameterListDocument, options);
      }
export function useHealthProgramParameterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables>(HealthProgramParameterListDocument, options);
        }
export function useHealthProgramParameterListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables>(HealthProgramParameterListDocument, options);
        }
export type HealthProgramParameterListQueryHookResult = ReturnType<typeof useHealthProgramParameterListQuery>;
export type HealthProgramParameterListLazyQueryHookResult = ReturnType<typeof useHealthProgramParameterListLazyQuery>;
export type HealthProgramParameterListSuspenseQueryHookResult = ReturnType<typeof useHealthProgramParameterListSuspenseQuery>;
export type HealthProgramParameterListQueryResult = Apollo.QueryResult<HealthProgramParameterListQuery, HealthProgramParameterListQueryVariables>;