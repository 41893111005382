import { i18n } from "@toolkit/i18n";
import { AppointmentType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const appointmentTypeOptionsMap: IEnumToOptionsMap<AppointmentType> = {
  [AppointmentType.Diagnostic]: {
    key: AppointmentType.Diagnostic,
    get label() {
      return i18n.t("Diagnostic", { ns: "domains" });
    },
    value: AppointmentType.Diagnostic,
  },
  [AppointmentType.AtHome]: {
    key: AppointmentType.AtHome,
    get label() {
      return i18n.t("At Home", { ns: "domains" });
    },
    value: AppointmentType.AtHome,
  },
  [AppointmentType.Onsite]: {
    key: AppointmentType.Onsite,
    get label() {
      return i18n.t("In Person", { ns: "domains" });
    },
    value: AppointmentType.Onsite,
  },
  [AppointmentType.Online]: {
    key: AppointmentType.Online,
    get label() {
      return i18n.t("Online", { ns: "domains" });
    },
    value: AppointmentType.Online,
  },
};

export const appointmentTypeOptions = Object.values(appointmentTypeOptionsMap)?.filter(item => item?.value !== AppointmentType.Diagnostic);
