import { makeStyles } from "@toolkit/ui";

export const useCaseManagementPatientDigitalTwinStyle = makeStyles()(() => ({
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  grid: {
    paddingLeft: 0,
  },
  dividerWrapper: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
}));
