import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceBrandUpdateMutationVariables = Types.Exact<{
  marketplaceBrandUpdateId: Types.Scalars['ID']['input'];
  input: Types.MarketplaceBrandInput;
}>;


export type MarketplaceBrandUpdateMutation = { __typename?: 'Mutation', marketplaceBrandUpdate?: { __typename?: 'MarketplaceBrand', id: string, image?: string | null, name?: string | null, nameAr?: string | null } | null };


export const MarketplaceBrandUpdateDocument = gql`
    mutation marketplaceBrandUpdate($marketplaceBrandUpdateId: ID!, $input: MarketplaceBrandInput!) {
  marketplaceBrandUpdate(id: $marketplaceBrandUpdateId, input: $input) {
    id
    image
    name
    nameAr
  }
}
    `;
export type MarketplaceBrandUpdateMutationFn = Apollo.MutationFunction<MarketplaceBrandUpdateMutation, MarketplaceBrandUpdateMutationVariables>;

/**
 * __useMarketplaceBrandUpdateMutation__
 *
 * To run a mutation, you first call `useMarketplaceBrandUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceBrandUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceBrandUpdateMutation, { data, loading, error }] = useMarketplaceBrandUpdateMutation({
 *   variables: {
 *      marketplaceBrandUpdateId: // value for 'marketplaceBrandUpdateId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarketplaceBrandUpdateMutation(baseOptions?: Apollo.MutationHookOptions<MarketplaceBrandUpdateMutation, MarketplaceBrandUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarketplaceBrandUpdateMutation, MarketplaceBrandUpdateMutationVariables>(MarketplaceBrandUpdateDocument, options);
      }
export type MarketplaceBrandUpdateMutationHookResult = ReturnType<typeof useMarketplaceBrandUpdateMutation>;
export type MarketplaceBrandUpdateMutationResult = Apollo.MutationResult<MarketplaceBrandUpdateMutation>;
export type MarketplaceBrandUpdateMutationOptions = Apollo.BaseMutationOptions<MarketplaceBrandUpdateMutation, MarketplaceBrandUpdateMutationVariables>;