import { Box, Button, Grid, TextField, useAddToast } from "@toolkit/ui";
import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useCaseManagementPatientNoteCreateMutation } from "../../gql";
import { useCaseManagementPatientNoteCreateStyle } from "./useCaseManagementPatientNoteCreateStyle";

type CaseManagementPatientNoteCreateProps = {
  patientId: string;
  onChange: () => void;
};

export const CaseManagementPatientNoteCreate: FC<CaseManagementPatientNoteCreateProps> = props => {
  const { patientId, onChange } = props;

  const [note, setNote] = useState("");

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const { classes } = useCaseManagementPatientNoteCreateStyle();

  const onNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const [createCaseManagementPatientNote, { loading }] = useCaseManagementPatientNoteCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.patientCaseManagementNotesCreate?.id) {
        setNote("");
        succeeded(t("Note Created Successfully"));
        onChange();
      } else {
        failed(t("Note Creation Failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onAddNewNoteClick = () => {
    if (!note) return;

    createCaseManagementPatientNote({
      variables: {
        input: { patientId, note },
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField value={note} label={t("Note")} placeholder={t("Note")} fullWidth multiline rows={4} onChange={onNoteChange} />
      </Grid>

      <Grid item xs={12}>
        <Box className={classes.wrapper}>
          <Button disabled={loading || !note} onClick={onAddNewNoteClick}>
            {t("Add Note")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
