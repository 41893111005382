import { CodeSystemCode } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomIcon, MenuItem, Typography, useAddToast } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { adminManagedListsPaths } from "../../../constants";
import { useCloneCodeSystemMutation } from "../../../gql";
import { useAdminManagedListDetailsPageContext } from "../../AdminManagedListDetailsPageContext";

export const CloneButton: FC<{ disabled?: boolean; onClick?: () => void }> = ({ disabled, onClick }) => {
  const { t } = useTranslation("domains");
  const navigate = useNavigate();
  const { failed } = useAddToast();
  const { code } = useAdminManagedListDetailsPageContext();
  const [cloneCodeSystem, { loading }] = useCloneCodeSystemMutation({
    onCompleted: data => {
      const codeId = data?.cloneCodeSystem?.id;
      if (!codeId) return;
      navigate(adminManagedListsPaths.detailsPath.fullPathWithParams(code!, codeId));
    },
    onError: data => {
      failed(t(data?.message));
    },
  });
  const handleCloneClicked = () => {
    onClick?.();
    cloneCodeSystem({
      variables: {
        codeSystemCode: code as CodeSystemCode,
      },
    });
  };
  return (
    <MenuItem onClick={handleCloneClicked} disabled={disabled || loading}>
      <CustomIcon icon='clone' />
      <Typography marginLeft='2px'> {t("Clone")}</Typography>
    </MenuItem>
  );
};

CloneButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};
