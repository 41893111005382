import { PersonGenderEnum, SendBulkMessagesBySearchCriteriaInput, SendBulkMessagesInput } from "@/schema/types";
import { IBulkMessageSenderFormValues } from "../forms/BulkMessageSender/BulkMessageSenderFormSchema";
import { IBulkMessageSenderUsersFormValues } from "../forms/BulkMessageSenderUsers/BulkMessageSenderUsersFormSchema";
import { IBulkMessageSearchCriteriaFormValues } from "../forms/BulkMessagesSearchCriteria/BulkMessagesSearchCriteriaFormSchema";
import { isEmpty } from "lodash-es";

export const convertBulkMessagesFormValuesToBackEndValues = (
  values: IBulkMessageSenderFormValues & IBulkMessageSenderUsersFormValues
): SendBulkMessagesInput => {
  return {
    message: values.message,
    messageAr: values.messageAr,
    method: values.method?.value,
    title: values.title,
    titleAr: values.titleAr,
    users: values.users?.map(user => user.value?.id) ?? [],
  };
};

export const convertBulkMessagesSendByCriteriaFormValuesToBackEndValues = (
  values: IBulkMessageSenderFormValues & IBulkMessageSearchCriteriaFormValues
): SendBulkMessagesBySearchCriteriaInput => {
  return {
    message: values.message,
    messageAr: values.messageAr,
    method: values.method?.value,
    title: values.title,
    titleAr: values.titleAr,
    searchCriteria: {
      fromAge: values.fromAge,
      toAge: values.toAge,
      gender: values.gender?.value as unknown as PersonGenderEnum,
      chronicDiseaseCodes: !isEmpty(values.chronicDiseases) ? values.chronicDiseases?.map(code => code.value?.code!) : undefined,
    },
  };
};
