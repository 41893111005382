import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendBulkMessagesBySearchCriteriaMutationVariables = Types.Exact<{
  input: Types.SendBulkMessagesBySearchCriteriaInput;
}>;


export type SendBulkMessagesBySearchCriteriaMutation = { __typename?: 'Mutation', sendBulkMessagesBySearchCriteria?: { __typename?: 'SendBulkMessagesBySearchCriteria', success?: boolean | null, accountErrors: Array<{ __typename?: 'AccountError', message?: string | null, code: Types.AccountErrorCode, field?: string | null }> } | null };


export const SendBulkMessagesBySearchCriteriaDocument = gql`
    mutation SendBulkMessagesBySearchCriteria($input: SendBulkMessagesBySearchCriteriaInput!) {
  sendBulkMessagesBySearchCriteria(input: $input) {
    success
    accountErrors {
      message
      code
      field
    }
  }
}
    `;
export type SendBulkMessagesBySearchCriteriaMutationFn = Apollo.MutationFunction<SendBulkMessagesBySearchCriteriaMutation, SendBulkMessagesBySearchCriteriaMutationVariables>;

/**
 * __useSendBulkMessagesBySearchCriteriaMutation__
 *
 * To run a mutation, you first call `useSendBulkMessagesBySearchCriteriaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBulkMessagesBySearchCriteriaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBulkMessagesBySearchCriteriaMutation, { data, loading, error }] = useSendBulkMessagesBySearchCriteriaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendBulkMessagesBySearchCriteriaMutation(baseOptions?: Apollo.MutationHookOptions<SendBulkMessagesBySearchCriteriaMutation, SendBulkMessagesBySearchCriteriaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendBulkMessagesBySearchCriteriaMutation, SendBulkMessagesBySearchCriteriaMutationVariables>(SendBulkMessagesBySearchCriteriaDocument, options);
      }
export type SendBulkMessagesBySearchCriteriaMutationHookResult = ReturnType<typeof useSendBulkMessagesBySearchCriteriaMutation>;
export type SendBulkMessagesBySearchCriteriaMutationResult = Apollo.MutationResult<SendBulkMessagesBySearchCriteriaMutation>;
export type SendBulkMessagesBySearchCriteriaMutationOptions = Apollo.BaseMutationOptions<SendBulkMessagesBySearchCriteriaMutation, SendBulkMessagesBySearchCriteriaMutationVariables>;