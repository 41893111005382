import { UserFilterInput, UserStatus } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { FIELD_IS_REQUIRED_MSG, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useAutocompleteCustomHook } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useAssignCodeSystemEditorAndReviewerMutation, useMedlistUsersQuery } from "../../gql";
import { AMLAssignReviewerDialogProps, AssignReviewerFormData, UserOptionItem } from "./AMLAssignReviewerDialog.types";

export const useAMLAssignReviewersHook = (props: AMLAssignReviewerDialogProps) => {
  const { succeeded, failed } = useAddToast();
  const { open: isOpen, handleOpen, handleClose } = useOpenState();
  const { t } = useTranslation("domains");
  const [reviewer, setReviewer] = useState<UserOptionItem | null | undefined>(() => props.reviewerUser);
  const [editor, setEditor] = useState<UserOptionItem | null | undefined>(() => props.editorUser);
  const [errors, setErrors] = useState<AssignReviewerFormData>({
    editorId: undefined,
    reviewerId: undefined,
  });

  useEffect(() => {
    setEditor(props.editorUser);
  }, [props.editorUser]);
  useEffect(() => {
    setReviewer(props.reviewerUser);
  }, [props.reviewerUser]);

  const {
    data: _users,
    isLoading: isLoading,
    onFetchMoreData: onFetchMoreUsers,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useMedlistUsersQuery,
    input: {
      first: 20,
      filter: {
        status: UserStatus.Active,
      } as Partial<UserFilterInput>,
    },
    dataAccessor: "medlistUsers",
  });
  const users = _users?.medlistUsers?.edges.map(item => item.node) as UserOptionItem[];
  const [assignCodeSystemEditorAndReviewer, { loading: isSubmitting }] = useAssignCodeSystemEditorAndReviewerMutation({
    onCompleted: data => {
      if (data?.assignCodeSystemEditorAndReviewer?.reviewerUserId) {
        succeeded(t("list assigned successfully"));
        handleClose();
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleReviewerChange = (_event, value) => {
    setErrors(s => ({ ...s, reviewerId: undefined }));
    setReviewer(value);
  };

  const handleEditorChange = (_event, value) => {
    setErrors(s => ({ ...s, editorId: undefined }));
    setEditor(value);
  };

  const handleAssignClick = () => {
    if (!reviewer?.id) {
      setErrors(s => ({ ...s, reviewerId: t(FIELD_IS_REQUIRED_MSG) }));
    }
    if (!editor?.id) {
      setErrors(s => ({ ...s, editorId: t(FIELD_IS_REQUIRED_MSG) }));
    }
    if (!reviewer?.id || !editor?.id) return;
    assignCodeSystemEditorAndReviewer({
      variables: {
        code: props.code,
        editorUser: editor.id,
        reviewerUser: reviewer.id,
      },
    });
  };

  return {
    t,
    isOpen,
    errors,
    users,
    editor,
    reviewer,
    isLoading,
    isSubmitting,
    onFetchMoreUsers,
    handleOpen,
    handleClose,
    handleReviewerChange,
    handleEditorChange,
    handleAssignClick,
  };
};
