import { makeStyles } from "@toolkit/ui";

export const useManagedListCardStyle = makeStyles()(theme => ({
  dateStyle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "140px",
  },
  imgHeight: {
    height: 70,
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));
