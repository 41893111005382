import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, Typography, useAddToast } from "@toolkit/ui";
import { useProviderSubscriptionTerminationMutation } from "../../gql";
import { FC } from "react";
import { formatMessageErrors } from "@toolkit/apollo";

type ProviderSubscriptionTerminationProps = {
  id: string;
  isActive: boolean;
};

export const ProviderSubscriptionTermination: FC<ProviderSubscriptionTerminationProps> = props => {
  const { id, isActive } = props;

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const [terminateProviderSubscription] = useProviderSubscriptionTerminationMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.terminateVendorSubscription?.vendorErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Provider Subscription Termination Successfully"));
      }
    },
  });

  const onTerminateClick = () => {
    terminateProviderSubscription({
      variables: { id },
    });
  };

  return (
    <CustomDialog
      type={"base"}
      DialogTitleProps={{
        title: t("Provider Subscription Termination"),
      }}
      DialogActionsProps={{
        children: (
          <Button color={"primary"} variant={"contained"} onClick={onTerminateClick}>
            {t("Terminate")}
          </Button>
        ),
      }}
      button={
        <Button size={"small"} disabled={!isActive}>
          {t("Terminate")}
        </Button>
      }
    >
      <Typography variant={"h6"}>{t("Are you sure you want to Terminate Provider Subscription?")}</Typography>
    </CustomDialog>
  );
};
