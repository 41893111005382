import { Block } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { CitiesAutocomplete } from "@health/autocompletes";
import { blockUpsertFormDefaultValues, blockUpsertFormSchema, IBlockUpsertFormValues } from "./BlockUpsertFormSchema";
import { convertBlockToFormValues } from "../../others";

export type IBlockUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IBlockUpsertFormValues;
  };
};

type BlockUpsertFormProps = {
  block?: Block;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IBlockUpsertFormEvent) => void;
};

export const BlockUpsertForm: FC<BlockUpsertFormProps> = props => {
  const { block, buttonLabel, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IBlockUpsertFormValues>({
    defaultValues: blockUpsertFormDefaultValues,
    schema: blockUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IBlockUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useEffect(() => {
    if (block) {
      const _block = convertBlockToFormValues(block);
      setValues(_block);
    }
  }, [block, setValues]);
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
            />
          }
        >
          <FormCard title={t("Block Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CitiesAutocomplete name={"city"} label={t("City")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
