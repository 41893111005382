import { FormAutocomplete, FormNumberField, FormPhoneInput, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { vendorTypeOptions } from "@health/enum-options";
import { FC } from "react";

type ProviderInformationFormProps = {
  isUpdateMode: boolean;
};

export const ProviderInformationForm: FC<ProviderInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nameAr"} label={t("Name (Arabic)")} placeholder={t("Name (Arabic)")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"ownerName"} label={t("Owner Name")} placeholder={t("Owner Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nationalId"} label={t("National Id")} placeholder={t("National Id")} />
      </Grid>

      {!isUpdateMode && (
        <Grid item xs={4}>
          <FormTextField name={"email"} label={t("Email")} placeholder={t("Email")} />
        </Grid>
      )}

      <Grid item xs={4}>
        <FormTextField name={"tradeName"} label={t("Trade Name")} placeholder={t("Trade Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField
          name={"commercialRegistrationNumber"}
          label={t("Commercial Registration Number")}
          placeholder={t("Commercial Registration Number")}
        />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"taxLicenseNumber"} label={t("Tax License Number")} placeholder={t("Tax License Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormNumberField name={"deliveryPrice"} label={t("Delivery Price")} placeholder={t("Delivery Price")} isFloatAllowed />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"type"} label={t("Type")} options={vendorTypeOptions} />
      </Grid>

      {!isUpdateMode && (
        <Grid item xs={4}>
          <FormPhoneInput name={"mobileNumber"} label={t("Mobile Number")} />
        </Grid>
      )}

      <Grid item xs={4}>
        <FormPhoneInput name={"phoneNumber"} label={t("Phone Number")} />
      </Grid>

      <Grid item xs={12}>
        <FormTextField name={"description"} multiline rows={4} label={t("Description")} placeholder={t("Description")} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormSwitch name={"isActive"} label={t("Active")} />
          </Grid>

          <Grid item xs={4}>
            <FormSwitch name={"isIntegrated"} label={t("Integrated")} />
          </Grid>

          <Grid item xs={4}>
            <FormSwitch name={"hasMultipleBranches"} label={t("Multiple Branches")} />
          </Grid>

          <Grid item xs={4}>
            <FormSwitch name={"hasOwnDrivers"} label={t("Own Drivers")} />
          </Grid>

          {!isUpdateMode && (
            <Grid item xs={4}>
              <FormSwitch name={"acceptsDelivery"} label={t("Accepts Delivery")} />
            </Grid>
          )}

          <Grid item xs={4}>
            <FormSwitch name={"enableVisitDetails"} label={t("Visit Details")} />
          </Grid>

          <Grid item xs={4}>
            <FormSwitch name={"editLikeEnabled"} label={t("Edit Like")} />
          </Grid>

          <Grid item xs={4}>
            <FormSwitch name={"supportOutpatientJourney"} label={t("Out Patient Journey")} />
          </Grid>

          <Grid item xs={4}>
            <FormSwitch name={"hasOwnPaymentGateway"} label={t("Own Payment Gateway")} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
