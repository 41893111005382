import { formGirdSpacing, toggleGridBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, FormSwitch, Grid } from "@toolkit/ui";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ISiteSetting } from "../components/types";

export const PaymentSettings: React.FC<ISiteSetting> = ({ loading, siteSettings }) => {
  const { t } = useTranslation("admin");
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("atHomeAppointmentPaymentCollectionEnabled", siteSettings?.atHomeAppointmentPaymentCollectionEnabled);
    setValue("onSiteAppointmentPaymentCollectionEnabled", siteSettings?.onSiteAppointmentPaymentCollectionEnabled);
    setValue("pharmacyCoPaymentPaymentCollectionEnabled", siteSettings?.pharmacyCoPaymentPaymentCollectionEnabled);
  }, [siteSettings, setValue]);

  return (
    <Grid item xs={12}>
      <FormCard title={t("Payment Settings")} loading={loading} doYouHaveData>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...toggleGridBreakPoints}>
            <FormSwitch name='atHomeAppointmentPaymentCollectionEnabled' label={t("At home appointment payment collection enabled")} />
          </Grid>
          <Grid item {...toggleGridBreakPoints}>
            <FormSwitch name='onSiteAppointmentPaymentCollectionEnabled' label={t("On site appointment payment collection enabled")} />
          </Grid>
          <Grid item {...toggleGridBreakPoints}>
            <FormSwitch name='pharmacyCoPaymentPaymentCollectionEnabled' label={t("Pharmacy payment collection enabled")} />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
