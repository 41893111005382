import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { usersAdminsPaths } from "../constants";

export const useSetUsersAdminsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Admin Users");

  const userAdminListBreadcrumb = useMemo(() => {
    return {
      name: t("Admin Users"),
      route: usersAdminsPaths.list.fullPath,
    };
  }, [t]);

  const setUserAdminListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setUserAdminCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [userAdminListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, userAdminListBreadcrumb, t]);

  const setUserAdminUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [userAdminListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, userAdminListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setUserAdminListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setUserAdminCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setUserAdminUpdateContainerBreadcrumb();
    }
  }, [containerType, setUserAdminListContainerBreadcrumb, setUserAdminCreateContainerBreadcrumb, setUserAdminUpdateContainerBreadcrumb]);
};
