import { FormTextField, Grid } from "@toolkit/ui";
import { CitiesAutocomplete } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { IGoogleMapAddress, LocationPicker } from "@toolkit/maps";
import { Controller } from "react-hook-form";
import { useCustomFormContext } from "@toolkit/core";

export const AddressUpsertForm = () => {
  const { t } = useTranslation("domains");

  const form = useCustomFormContext();

  const { control } = form;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormTextField name={"streetAddress1"} label={t("Street Address 1")} placeholder={t("Street Address 1")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"streetAddress1Ar"} label={t("Street Address 1 (Arabic)")} placeholder={t("Street Address 1 (Arabic)")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"streetAddress2"} label={t("Street Address 2")} placeholder={t("Street Address 2")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"streetAddress2Ar"} label={t("Street Address 2 (Arabic)")} placeholder={t("Street Address 2 (Arabic)")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"area"} label={t("Area")} placeholder={t("Area")} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"areaAr"} label={t("Area (Arabic)")} placeholder={t("Area (Arabic)")} />
      </Grid>

      <Grid item xs={6}>
        <CitiesAutocomplete name={"city"} />
      </Grid>

      <Grid item xs={6}>
        <FormTextField name={"postalCode"} label={t("Postal Code")} placeholder={t("Postal Code")} />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name={"coordinates"}
          render={({ field: { onChange, value } }) => {
            const onLocationChange = (mapLocation: IGoogleMapAddress) => {
              onChange(mapLocation?.coordinates);
            };

            return <LocationPicker hasSearch hasLocationLngLat location={value} onLocationChange={onLocationChange} />;
          }}
        />
      </Grid>
    </Grid>
  );
};
