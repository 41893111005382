import React, { FC } from "react";
import { Box, Typography, useTheme } from "../../base/mui";
import { CardContentProps } from "./types";

export const CardContent: FC<CardContentProps> = ({ title, subTitle, price }) => {
  const { mixins } = useTheme();
  return (
    <Box m={1}>
      <Typography fontWeight={mixins.fonts.fontWeight.medium} fontSize={mixins.fonts.fontSize.sm}>
        {title}
      </Typography>
      {subTitle && (
        <Typography fontSize={mixins.fonts.fontSize.sm}>
          {subTitle} <strong> {price}</strong>
        </Typography>
      )}
    </Box>
  );
};
