import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramBenefitOptionListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BenefitFilterInput>;
}>;


export type HealthProgramBenefitOptionListQuery = { __typename?: 'Query', healthProgramBenefitGroupOptions?: Array<{ __typename?: 'BenefitOption', id: string, name?: string | null } | null> | null };


export const HealthProgramBenefitOptionListDocument = gql`
    query HealthProgramBenefitOptionList($filter: BenefitFilterInput) {
  healthProgramBenefitGroupOptions(filter: $filter) {
    id
    name
  }
}
    `;

/**
 * __useHealthProgramBenefitOptionListQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitOptionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitOptionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitOptionListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramBenefitOptionListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>(HealthProgramBenefitOptionListDocument, options);
      }
export function useHealthProgramBenefitOptionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>(HealthProgramBenefitOptionListDocument, options);
        }
export function useHealthProgramBenefitOptionListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>(HealthProgramBenefitOptionListDocument, options);
        }
export type HealthProgramBenefitOptionListQueryHookResult = ReturnType<typeof useHealthProgramBenefitOptionListQuery>;
export type HealthProgramBenefitOptionListLazyQueryHookResult = ReturnType<typeof useHealthProgramBenefitOptionListLazyQuery>;
export type HealthProgramBenefitOptionListSuspenseQueryHookResult = ReturnType<typeof useHealthProgramBenefitOptionListSuspenseQuery>;
export type HealthProgramBenefitOptionListQueryResult = Apollo.QueryResult<HealthProgramBenefitOptionListQuery, HealthProgramBenefitOptionListQueryVariables>;