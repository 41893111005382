import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const bulkMessageSenderUsersFormSchema = z.object({
  users: z.array(zodSchema.user).min(1),
});

export type IBulkMessageSenderUsersFormValues = z.infer<typeof bulkMessageSenderUsersFormSchema>;

export const bulkMessageSenderUsersFormDefaultValues: Partial<IBulkMessageSenderUsersFormValues> = {
  users: [],
};
