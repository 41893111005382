import { DecisionMakerOrderDirection, SubListItem, SubListItemSortField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useSublistItemListColumnsContainer = (): CustomTableColumnProps<SubListItem>[] => {
  const { t } = useTranslation("domains");

  return useMemo(() => {
    return [
      {
        accessor: ({ display }) => display,
        header: t("Display name"),
        key: "displayName",
        sort: {
          columnEnum: SubListItemSortField.Display,
          direction: DecisionMakerOrderDirection?.Asc,
        },
      },
      {
        accessor: ({ code }) => code,
        sort: {
          columnEnum: SubListItemSortField.Code,
        },
        header: t("Code"),
        key: "code",
      },
    ];
  }, []);
};
