import { Grid } from "@toolkit/ui";
import React, { FC } from "react";
import ContentLoader from "react-content-loader";

const EditAdminManagedListFormSkeleton: FC = () => {
  return (
    <Grid container padding={4} spacing={2}>
      {new Array(20).fill(0).map((el, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={key} item xs={12} sm={6} lg={4} xl={3}>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <ContentLoader>
              <rect x='20' y='20' rx='4' ry='4' width='750' height='30' />
            </ContentLoader>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default EditAdminManagedListFormSkeleton;
