import { PayerType } from "@/schema/types";
import { zodSchema } from "@health/autocompletes";
import { zodEnumSchema } from "@health/enum-options";
import { i18n } from "@toolkit/i18n";
import { z } from "zod";

export const payerUpsertFormSchema = z
  .object({
    name: z.string().min(1),
    nameAr: z.string().nullish(),
    contactName: z.string().min(1),
    contactEmail: z.string().email(),
    contactMobileNumber: z.string().nullish(),
    contactPhoneNumber: z.string().nullish(),
    licenseNumber: z.string().min(1),
    tpo: zodEnumSchema.payerTpo,
    type: zodEnumSchema.payerType,
    parent: zodSchema.payer.nullish(),
    logo: z.string().nullish(),
    backgroundImage: z.string().nullish(),
    insuranceCardLogo: z.string().nullish(),
    isActive: z.boolean(),
  })
  .superRefine((data, ctx) => {
    if (data.type?.value === PayerType.Tpa && !data.parent) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "admin" }),
        path: ["parent"],
      });
    }
  });

export type IPayerUpsertFormValues = z.infer<typeof payerUpsertFormSchema>;

export const payerUpsertFormDefaultValues: Partial<IPayerUpsertFormValues> = {
  name: undefined,
  nameAr: undefined,
  contactName: undefined,
  contactEmail: undefined,
  contactMobileNumber: undefined,
  contactPhoneNumber: undefined,
  licenseNumber: undefined,
  tpo: undefined,
  type: undefined,
  parent: undefined,
  logo: undefined,
  backgroundImage: undefined,
  insuranceCardLogo: undefined,
  isActive: false,
};
