import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketplaceHealthPackageCategoriesAutocompleteQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.MarketplaceCategoryFilterInput>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.MarketplaceDefaultSortingInput>;
}>;


export type MarketplaceHealthPackageCategoriesAutocompleteQuery = { __typename?: 'Query', marketplaceHealthPackageCategories?: { __typename?: 'MarketplaceHealthPackageCategoryConnection', pageInfo?: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } | null, edges: Array<{ __typename?: 'MarketplaceHealthPackageCategoryEdge', node: { __typename?: 'MarketplaceHealthPackageCategory', id: string, name?: string | null, nameAr?: string | null } }> } | null };


export const MarketplaceHealthPackageCategoriesAutocompleteDocument = gql`
    query MarketplaceHealthPackageCategoriesAutocomplete($after: String, $filter: MarketplaceCategoryFilterInput, $before: String, $first: Int, $last: Int, $sortBy: MarketplaceDefaultSortingInput) {
  marketplaceHealthPackageCategories(
    after: $after
    filter: $filter
    before: $before
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;

/**
 * __useMarketplaceHealthPackageCategoriesAutocompleteQuery__
 *
 * To run a query within a React component, call `useMarketplaceHealthPackageCategoriesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceHealthPackageCategoriesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceHealthPackageCategoriesAutocompleteQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplaceHealthPackageCategoriesAutocompleteQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>(MarketplaceHealthPackageCategoriesAutocompleteDocument, options);
      }
export function useMarketplaceHealthPackageCategoriesAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>(MarketplaceHealthPackageCategoriesAutocompleteDocument, options);
        }
export function useMarketplaceHealthPackageCategoriesAutocompleteSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>(MarketplaceHealthPackageCategoriesAutocompleteDocument, options);
        }
export type MarketplaceHealthPackageCategoriesAutocompleteQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoriesAutocompleteQuery>;
export type MarketplaceHealthPackageCategoriesAutocompleteLazyQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoriesAutocompleteLazyQuery>;
export type MarketplaceHealthPackageCategoriesAutocompleteSuspenseQueryHookResult = ReturnType<typeof useMarketplaceHealthPackageCategoriesAutocompleteSuspenseQuery>;
export type MarketplaceHealthPackageCategoriesAutocompleteQueryResult = Apollo.QueryResult<MarketplaceHealthPackageCategoriesAutocompleteQuery, MarketplaceHealthPackageCategoriesAutocompleteQueryVariables>;