import { i18n } from "@toolkit/i18n";
import { DoctorSeniorityEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const doctorSeniorityOptionsMap: IEnumToOptionsMap<DoctorSeniorityEnum> = {
  [DoctorSeniorityEnum.Cp]: {
    key: DoctorSeniorityEnum.Cp,
    get label() {
      return i18n.t("Cp", { ns: "domains" });
    },
    value: DoctorSeniorityEnum.Cp,
  },
  [DoctorSeniorityEnum.Gp]: {
    key: DoctorSeniorityEnum.Gp,
    get label() {
      return i18n.t("Gp", { ns: "domains" });
    },
    value: DoctorSeniorityEnum.Gp,
  },
  [DoctorSeniorityEnum.Sp]: {
    key: DoctorSeniorityEnum.Sp,
    get label() {
      return i18n.t("Sp", { ns: "domains" });
    },
    value: DoctorSeniorityEnum.Sp,
  },
};

export const doctorSeniorityOptions = Object.values(doctorSeniorityOptionsMap);
