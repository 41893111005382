import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InsuranceBenefitApprovalLimitUpdateMutationVariables = Types.Exact<{
  updateInsuranceBenefitApprovalLimitId: Types.Scalars['ID']['input'];
  input: Types.InsuranceBenefitApprovalLimitCrudInput;
}>;


export type InsuranceBenefitApprovalLimitUpdateMutation = { __typename?: 'Mutation', updateInsuranceBenefitApprovalLimit?: { __typename?: 'InsuranceBenefitApprovalLimit', id: string } | null };


export const InsuranceBenefitApprovalLimitUpdateDocument = gql`
    mutation InsuranceBenefitApprovalLimitUpdate($updateInsuranceBenefitApprovalLimitId: ID!, $input: InsuranceBenefitApprovalLimitCRUDInput!) {
  updateInsuranceBenefitApprovalLimit(
    id: $updateInsuranceBenefitApprovalLimitId
    input: $input
  ) {
    id
  }
}
    `;
export type InsuranceBenefitApprovalLimitUpdateMutationFn = Apollo.MutationFunction<InsuranceBenefitApprovalLimitUpdateMutation, InsuranceBenefitApprovalLimitUpdateMutationVariables>;

/**
 * __useInsuranceBenefitApprovalLimitUpdateMutation__
 *
 * To run a mutation, you first call `useInsuranceBenefitApprovalLimitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsuranceBenefitApprovalLimitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insuranceBenefitApprovalLimitUpdateMutation, { data, loading, error }] = useInsuranceBenefitApprovalLimitUpdateMutation({
 *   variables: {
 *      updateInsuranceBenefitApprovalLimitId: // value for 'updateInsuranceBenefitApprovalLimitId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInsuranceBenefitApprovalLimitUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InsuranceBenefitApprovalLimitUpdateMutation, InsuranceBenefitApprovalLimitUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsuranceBenefitApprovalLimitUpdateMutation, InsuranceBenefitApprovalLimitUpdateMutationVariables>(InsuranceBenefitApprovalLimitUpdateDocument, options);
      }
export type InsuranceBenefitApprovalLimitUpdateMutationHookResult = ReturnType<typeof useInsuranceBenefitApprovalLimitUpdateMutation>;
export type InsuranceBenefitApprovalLimitUpdateMutationResult = Apollo.MutationResult<InsuranceBenefitApprovalLimitUpdateMutation>;
export type InsuranceBenefitApprovalLimitUpdateMutationOptions = Apollo.BaseMutationOptions<InsuranceBenefitApprovalLimitUpdateMutation, InsuranceBenefitApprovalLimitUpdateMutationVariables>;