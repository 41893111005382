import { replacePathParams } from "./utils";

export const adminManagedListsRoute = "/admin-managed-lists";
const adminManagedListsDetailsRoute = ":code/:id";
const adminManagedListsDetailsEditRoute = `${adminManagedListsDetailsRoute}/:codeSystemConceptId`;

export const adminManagedListsPaths = {
  listPath: {
    route: "",
    get fullPath() {
      return `${adminManagedListsRoute}${this.route}`;
    },
  },
  detailsPath: {
    route: adminManagedListsDetailsRoute,
    get fullPath() {
      return `${adminManagedListsRoute}/${this.route}`;
    },
    fullPathWithParams: function (code: string, id: string) {
      return replacePathParams(this.fullPath, { code, id });
    },
  },
  detailsEditPath: {
    route: adminManagedListsDetailsEditRoute,
    get fullPath() {
      return `${adminManagedListsRoute}/${this.route}`;
    },
    fullPathWithParams: function (code: string, id: string, codeSystemConceptId: string) {
      return replacePathParams(this.fullPath, { code, id, codeSystemConceptId });
    },
  },
};
