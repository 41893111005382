import { FormAutocomplete, FormPhoneInput, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { appRoleTypeOptions } from "@health/enum-options";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";

type UserAdminInformationFormProps = {
  isUpdateMode: boolean;
};

export const UserAdminInformationForm: FC<UserAdminInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"firstName"} label={t("First Name")} placeholder={t("First Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"lastName"} label={t("Last Name")} placeholder={t("Last Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nationalId"} label={t("National Id")} placeholder={t("National Id")} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"email"} label={t("Email")} placeholder={t("Email")} disabled={isUpdateMode} />
      </Grid>

      {!isUpdateMode && (
        <Grid item xs={4}>
          <FormTextField
            name={"password"}
            label={t("Password")}
            placeholder={t("Password")}
            type={"password"}
            autoComplete={"new-password"}
          />
        </Grid>
      )}

      <Grid item xs={4}>
        <FormPhoneInput name={"mobile"} label={t("Mobile Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"appRole"} label={t("App Role")} options={appRoleTypeOptions} />
      </Grid>

      <Grid item xs={12}>
        <FormSwitch name={"isActive"} label={t("Is Active")} />
      </Grid>
    </Grid>
  );
};
