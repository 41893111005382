import { useTranslation } from "@toolkit/i18n";
import { FC } from "react";
import { Button, styled, useTheme, type ButtonProps } from "../../base/mui";
import { Show } from "../../icons/icons";

interface ShowButtonProps extends ButtonProps {}

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.palette.primary.main,
  "&.MuiButton-containedPrimary": {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    "& .MuiButton-startIcon > svg > path": {
      fill: theme.palette.primary.main,
    },
  },
  transition: theme.transitions.create(["background-color", "color"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.complex,
  }),
  "&.Mui-disabled": {
    color: theme.palette.gray[400],
    "& .MuiButton-startIcon > svg > path": {
      fill: theme.palette.gray[400],
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transition: theme.transitions.create(["background-color", "color"], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shortest,
    }),
    "& .MuiButton-startIcon > svg > path": {
      fill: theme.palette.common.white,
    },
  },
}));

export const ShowButton: FC<ShowButtonProps> = props => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledButton variant='text' startIcon={<Show fill={theme.palette.primary.main} />} size='medium' {...props}>
      {t("Show")}
    </StyledButton>
  );
};
