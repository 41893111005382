/* eslint-disable max-lines */
import { i18n } from "@toolkit/i18n";
import { OrderStatusEnum } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const orderStatusOptionsMap: IEnumToOptionsMap<OrderStatusEnum> = {
  [OrderStatusEnum.AcceptedByProvider]: {
    key: OrderStatusEnum.AcceptedByProvider,
    get label() {
      return i18n.t("Accepted By Provider", { ns: "domains" });
    },
    value: OrderStatusEnum.AcceptedByProvider,
  },
  [OrderStatusEnum.Allocated]: {
    key: OrderStatusEnum.Allocated,
    get label() {
      return i18n.t("Allocated", { ns: "domains" });
    },
    value: OrderStatusEnum.Allocated,
  },
  [OrderStatusEnum.CanceledByConsumer]: {
    key: OrderStatusEnum.CanceledByConsumer,
    get label() {
      return i18n.t("Canceled By Consumer", { ns: "domains" });
    },
    value: OrderStatusEnum.CanceledByConsumer,
  },
  [OrderStatusEnum.CanceledByProvider]: {
    key: OrderStatusEnum.CanceledByProvider,
    get label() {
      return i18n.t("Canceled By Provider", { ns: "domains" });
    },
    value: OrderStatusEnum.CanceledByProvider,
  },
  [OrderStatusEnum.Consumed]: {
    key: OrderStatusEnum.Consumed,
    get label() {
      return i18n.t("Consumed", { ns: "domains" });
    },
    value: OrderStatusEnum.Consumed,
  },
  [OrderStatusEnum.ConsumedTimedOut]: {
    key: OrderStatusEnum.ConsumedTimedOut,
    get label() {
      return i18n.t("Consumed Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.ConsumedTimedOut,
  },
  [OrderStatusEnum.Delivered]: {
    key: OrderStatusEnum.Delivered,
    get label() {
      return i18n.t("Delivered", { ns: "domains" });
    },
    value: OrderStatusEnum.Delivered,
  },
  [OrderStatusEnum.DeliveredTimedOut]: {
    key: OrderStatusEnum.DeliveredTimedOut,
    get label() {
      return i18n.t("Delivered Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.DeliveredTimedOut,
  },
  [OrderStatusEnum.Dispensed]: {
    key: OrderStatusEnum.Dispensed,
    get label() {
      return i18n.t("Dispensed", { ns: "domains" });
    },
    value: OrderStatusEnum.Dispensed,
  },
  [OrderStatusEnum.Draft]: {
    key: OrderStatusEnum.Draft,
    get label() {
      return i18n.t("Draft", { ns: "domains" });
    },
    value: OrderStatusEnum.Draft,
  },
  [OrderStatusEnum.ErxHubAuthRequestFailed]: {
    key: OrderStatusEnum.ErxHubAuthRequestFailed,
    get label() {
      return i18n.t("ErxHubAuthRequestFailed", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubAuthRequestFailed,
  },
  [OrderStatusEnum.ErxHubAuthSubmitTimedOut]: {
    key: OrderStatusEnum.ErxHubAuthSubmitTimedOut,
    get label() {
      return i18n.t("Erx Hub Auth Submit Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubAuthSubmitTimedOut,
  },
  [OrderStatusEnum.ErxHubClaimApproved]: {
    key: OrderStatusEnum.ErxHubClaimApproved,
    get label() {
      return i18n.t("Erx Hub Claim Approved", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubClaimApproved,
  },
  [OrderStatusEnum.ErxHubClaimFailed]: {
    key: OrderStatusEnum.ErxHubClaimFailed,
    get label() {
      return i18n.t("Erx Hub Claim Failed", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubClaimFailed,
  },
  [OrderStatusEnum.ErxHubClaimRejected]: {
    key: OrderStatusEnum.ErxHubClaimRejected,
    get label() {
      return i18n.t("Erx Hub Claim Rejected", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubClaimRejected,
  },
  [OrderStatusEnum.ErxHubClaimSubmitTimedOut]: {
    key: OrderStatusEnum.ErxHubClaimSubmitTimedOut,
    get label() {
      return i18n.t("Erx Hub Claim Submit Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubClaimSubmitTimedOut,
  },
  [OrderStatusEnum.ErxHubFullyApproved]: {
    key: OrderStatusEnum.ErxHubFullyApproved,
    get label() {
      return i18n.t("Erx Hub Fully Approved", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubFullyApproved,
  },
  [OrderStatusEnum.ErxHubPartiallyApproved]: {
    key: OrderStatusEnum.ErxHubPartiallyApproved,
    get label() {
      return i18n.t("Erx Hub Partially Approved", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubPartiallyApproved,
  },
  [OrderStatusEnum.ErxHubRejected]: {
    key: OrderStatusEnum.ErxHubRejected,
    get label() {
      return i18n.t("Erx Hub Rejected", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubRejected,
  },
  [OrderStatusEnum.ErxHubTimedOut]: {
    key: OrderStatusEnum.ErxHubTimedOut,
    get label() {
      return i18n.t("Erx Hub Time dOut", { ns: "domains" });
    },
    value: OrderStatusEnum.ErxHubTimedOut,
  },
  [OrderStatusEnum.Failed]: {
    key: OrderStatusEnum.Failed,
    get label() {
      return i18n.t("Failed", { ns: "domains" });
    },
    value: OrderStatusEnum.Failed,
  },
  [OrderStatusEnum.Fulfilled]: {
    key: OrderStatusEnum.Fulfilled,
    get label() {
      return i18n.t("Fulfilled", { ns: "domains" });
    },
    value: OrderStatusEnum.Fulfilled,
  },
  [OrderStatusEnum.InProgress]: {
    key: OrderStatusEnum.InProgress,
    get label() {
      return i18n.t("In Progress", { ns: "domains" });
    },
    value: OrderStatusEnum.InProgress,
  },
  [OrderStatusEnum.NewRequest]: {
    key: OrderStatusEnum.NewRequest,
    get label() {
      return i18n.t("New Request", { ns: "domains" });
    },
    value: OrderStatusEnum.NewRequest,
  },
  [OrderStatusEnum.OutForDelivery]: {
    key: OrderStatusEnum.OutForDelivery,
    get label() {
      return i18n.t("Out For Delivery", { ns: "domains" });
    },
    value: OrderStatusEnum.OutForDelivery,
  },
  [OrderStatusEnum.OutForDeliveryTimedOut]: {
    key: OrderStatusEnum.OutForDeliveryTimedOut,
    get label() {
      return i18n.t("Out For Delivery Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.OutForDeliveryTimedOut,
  },
  [OrderStatusEnum.PartiallyFulfilled]: {
    key: OrderStatusEnum.PartiallyFulfilled,
    get label() {
      return i18n.t("Partially Fulfilled", { ns: "domains" });
    },
    value: OrderStatusEnum.PartiallyFulfilled,
  },
  [OrderStatusEnum.PaymentFailed]: {
    key: OrderStatusEnum.PaymentFailed,
    get label() {
      return i18n.t("Payment Failed", { ns: "domains" });
    },
    value: OrderStatusEnum.PaymentFailed,
  },
  [OrderStatusEnum.PaymentOnArrive]: {
    key: OrderStatusEnum.PaymentOnArrive,
    get label() {
      return i18n.t("Payment On Arrive", { ns: "domains" });
    },
    value: OrderStatusEnum.PaymentOnArrive,
  },
  [OrderStatusEnum.PaymentTimedOut]: {
    key: OrderStatusEnum.PaymentTimedOut,
    get label() {
      return i18n.t("Payment Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.PaymentTimedOut,
  },
  [OrderStatusEnum.PaymentPending]: {
    key: OrderStatusEnum.PaymentPending,
    get label() {
      return i18n.t("Payment Pending", { ns: "domains" });
    },
    value: OrderStatusEnum.PaymentPending,
  },
  [OrderStatusEnum.PaymentSucceeded]: {
    key: OrderStatusEnum.PaymentSucceeded,
    get label() {
      return i18n.t("Payment Succeeded", { ns: "domains" });
    },
    value: OrderStatusEnum.PaymentSucceeded,
  },
  [OrderStatusEnum.ReadyForCustomerPickup]: {
    key: OrderStatusEnum.ReadyForCustomerPickup,
    get label() {
      return i18n.t("Ready For Customer Pickup", { ns: "domains" });
    },
    value: OrderStatusEnum.ReadyForCustomerPickup,
  },
  [OrderStatusEnum.RejectedByProvider]: {
    key: OrderStatusEnum.RejectedByProvider,
    get label() {
      return i18n.t("Rejected By Provider", { ns: "domains" });
    },
    value: OrderStatusEnum.RejectedByProvider,
  },
  [OrderStatusEnum.ReturnInProgress]: {
    key: OrderStatusEnum.ReturnInProgress,
    get label() {
      return i18n.t("Return In Progress", { ns: "domains" });
    },
    value: OrderStatusEnum.ReturnInProgress,
  },
  [OrderStatusEnum.Returned]: {
    key: OrderStatusEnum.Returned,
    get label() {
      return i18n.t("Returned", { ns: "domains" });
    },
    value: OrderStatusEnum.Returned,
  },
  [OrderStatusEnum.ReturnedTimedOut]: {
    key: OrderStatusEnum.ReturnedTimedOut,
    get label() {
      return i18n.t("Returned Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.ReturnedTimedOut,
  },
  [OrderStatusEnum.Unfulfilled]: {
    key: OrderStatusEnum.Unfulfilled,
    get label() {
      return i18n.t("Unfulfilled", { ns: "domains" });
    },
    value: OrderStatusEnum.Unfulfilled,
  },
  [OrderStatusEnum.VendorResponseTimedOut]: {
    key: OrderStatusEnum.VendorResponseTimedOut,
    get label() {
      return i18n.t("Vendor Response Timed Out", { ns: "domains" });
    },
    value: OrderStatusEnum.VendorResponseTimedOut,
  },
  [OrderStatusEnum.WaitingErxHubApproval]: {
    key: OrderStatusEnum.WaitingErxHubApproval,
    get label() {
      return i18n.t("Waiting Erx Hub Approval", { ns: "domains" });
    },
    value: OrderStatusEnum.WaitingErxHubApproval,
  },
  [OrderStatusEnum.WaitingErxHubClaimApproval]: {
    key: OrderStatusEnum.WaitingErxHubClaimApproval,
    get label() {
      return i18n.t("Waiting Erx Hub Claim Approval", { ns: "domains" });
    },
    value: OrderStatusEnum.WaitingErxHubClaimApproval,
  },
  [OrderStatusEnum.WaitingProviderSelection]: {
    key: OrderStatusEnum.WaitingProviderSelection,
    get label() {
      return i18n.t("Waiting Provider Selection", { ns: "domains" });
    },
    value: OrderStatusEnum.WaitingProviderSelection,
  },
};

export const orderStatusOptions = Object.values(orderStatusOptionsMap);

export const orderStatusActiveOptions = Object.values(orderStatusOptionsMap).filter(
  item =>
    !(
      item.value === OrderStatusEnum.Draft ||
      item.value === OrderStatusEnum.Unfulfilled ||
      item.value === OrderStatusEnum.InProgress ||
      item.value === OrderStatusEnum.Fulfilled ||
      item.value === OrderStatusEnum.PartiallyFulfilled
    )
);
