import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { MuiOndemandVideoIcon } from "@toolkit/ui";
import { streamingPaths, streamingRoute } from "pages/Streaming/constants/settings-paths";
import { StreamingPage } from "pages/Streaming/StreamingPage.screen";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";

export const streamingRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }): RouteItem => {
  return {
    id: "streaming-routes",
    text: t("Streaming Management"),
    route: `${streamingRoute}/*`,
    fullPath: streamingPaths.mainPath.fullPath,
    element: <StreamingPage />,
    icon: <MuiOndemandVideoIcon color='inherit' />,
    isProhibited: !hasPermission(PermissionEnum.ManageSocialAndStreaming),
    onClick: (route: string) => navigate(route),
  };
};
