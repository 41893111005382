import { i18n } from "@toolkit/i18n";
import { TaskWorkflowType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const taskWorkflowTypeOptionsMap: IEnumToOptionsMap<TaskWorkflowType> = {
  [TaskWorkflowType.ConfirmAppointmentDate]: {
    key: TaskWorkflowType.ConfirmAppointmentDate,
    get label() {
      return i18n.t("Appointment", { ns: "domains" });
    },
    value: TaskWorkflowType.ConfirmAppointmentDate,
  },
  [TaskWorkflowType.ReviewMedicalFormAnswer]: {
    key: TaskWorkflowType.ReviewMedicalFormAnswer,
    get label() {
      return i18n.t("Medical Form", { ns: "domains" });
    },
    value: TaskWorkflowType.ReviewMedicalFormAnswer,
  },
  [TaskWorkflowType.SubmitMedicalFormAnswers]: {
    key: TaskWorkflowType.SubmitMedicalFormAnswers,
    get label() {
      return i18n.t("Medical Form", { ns: "domains" });
    },
    value: TaskWorkflowType.SubmitMedicalFormAnswers,
  },
  [TaskWorkflowType.EnrollmentJourneyCompleted]: {
    key: TaskWorkflowType.EnrollmentJourneyCompleted,
    get label() {
      return i18n.t("Enrollment Journey Completed", { ns: "domains" });
    },
    value: TaskWorkflowType.EnrollmentJourneyCompleted,
  },
};

export const taskWorkflowTypeOptions = Object.values(taskWorkflowTypeOptionsMap);
