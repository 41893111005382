import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelBulkFileMutationVariables = Types.Exact<{
  fileId: Types.Scalars['ID']['input'];
}>;


export type CancelBulkFileMutation = { __typename?: 'Mutation', cancelBulkFile?: { __typename?: 'BulkFile', id?: string | null, errors?: Array<{ __typename?: 'BulkGraphqlError', message?: string | null, field?: string | null, code?: Types.BulkErrorCodes | null } | null> | null } | null };


export const CancelBulkFileDocument = gql`
    mutation CancelBulkFile($fileId: ID!) {
  cancelBulkFile(fileId: $fileId) {
    id
    errors {
      message
      field
      code
    }
  }
}
    `;
export type CancelBulkFileMutationFn = Apollo.MutationFunction<CancelBulkFileMutation, CancelBulkFileMutationVariables>;

/**
 * __useCancelBulkFileMutation__
 *
 * To run a mutation, you first call `useCancelBulkFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBulkFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBulkFileMutation, { data, loading, error }] = useCancelBulkFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCancelBulkFileMutation(baseOptions?: Apollo.MutationHookOptions<CancelBulkFileMutation, CancelBulkFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBulkFileMutation, CancelBulkFileMutationVariables>(CancelBulkFileDocument, options);
      }
export type CancelBulkFileMutationHookResult = ReturnType<typeof useCancelBulkFileMutation>;
export type CancelBulkFileMutationResult = Apollo.MutationResult<CancelBulkFileMutation>;
export type CancelBulkFileMutationOptions = Apollo.BaseMutationOptions<CancelBulkFileMutation, CancelBulkFileMutationVariables>;