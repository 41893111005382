import { MarketplaceApprovalStatus } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, IconButton, TextField, Tooltip, useAddToast, XIcon } from "@toolkit/ui";
import { useHealthPackageApproveMutation } from "pages/HealthPackages/gql";
import { HealthPackagesQueryNode } from "pages/HealthPackages/types";
import React, { FC, useState } from "react";

const RejectHealthPackage: FC<{ row: HealthPackagesQueryNode }> = ({ row }) => {
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const { t } = useTranslation("admin");

  const { handleToggle, open } = useOpenState();
  const [error, setError] = useState<string>("");
  const { failed, succeeded } = useAddToast();
  const [healthPackageApproveMutation, { loading: isLoading }] = useHealthPackageApproveMutation({
    onCompleted: () => {
      handleToggle();
      succeeded(t("Health package rejected!"));
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: cache => {
      const normalizedId = cache.identify({ id: row?.id, __typename: "MarketplaceHealthPackage" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleRejectHealthPackage = () => {
    rejectionReason
      ? healthPackageApproveMutation({
          variables: {
            healthPackageApproveId: row?.id,
          },
        })
      : setError(t("Rejection reason is required"));
  };

  const handleSetRejectionReason = (input: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRejectionReason(input?.target?.value);
    setError("");
  };
  const isPendingApproval = row?.approvalStatus === MarketplaceApprovalStatus.PendingApproval;

  return (
    <CustomDialog
      type='base'
      open={open}
      DialogTitleProps={{
        title: t("Reject Health Package"),
        onClose: handleToggle,
      }}
      maxWidth='sm'
      button={
        <IconButton
          sx={{
            "& svg": {
              width: "19px",
              height: "19px",
            },
          }}
          disabled={!isPendingApproval || isLoading || !row?.updatable}
          onClick={handleToggle}
        >
          <XIcon />
        </IconButton>
      }
      DialogActionsProps={{
        children: (
          <Tooltip title={t("Please Enter Rejection Reason")}>
            <span>
              <Button color='error' disabled={isLoading} onClick={handleRejectHealthPackage}>
                {t("Reject")}
              </Button>
            </span>
          </Tooltip>
        ),
      }}
    >
      <TextField
        fullWidth
        minRows={3}
        value={rejectionReason}
        onChange={input => handleSetRejectionReason(input)}
        label={t("Rejection reason")}
        error={!!error}
        helperText={error || " "}
      />
    </CustomDialog>
  );
};
export default React.memo(RejectHealthPackage);
