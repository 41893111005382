import { IAutocompleteProps } from "@/types";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React, { FC } from "react";
import { HealthProgramMemberFilterInput } from "../../schema/types";
import { useHealthProgramMembersAutocompleteQuery } from "./gql";

type HealthProgramMembersAutocompleteProps = IAutocompleteProps<HealthProgramMemberFilterInput>;

export const HealthProgramMembersAutocomplete: FC<HealthProgramMembersAutocompleteProps> = props => {
  const { label, filter, skip, ...rest } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useHealthProgramMembersAutocompleteQuery,
    variables: { filter },
    searchKey: "name",
    skip: props.disabled || skip,
  });

  const options = mapToAutocompleteOptions(data, "id", item => `${item?.firstName} ${item?.lastName}`);

  return (
    <FormAutocomplete
      {...rest}
      {...restRelayStyleConnection}
      options={options}
      label={label || (props.multiple ? t("Program Members") : t("Program Member"))}
    />
  );
};
