import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { SubscriptionPlansAutocompleteQuery } from "./gql";

export type ISubscriptionPlanAutocomplete = ExtractNodeType<SubscriptionPlansAutocompleteQuery>;

export const subscriptionPlanSchema = createZodAutocompleteFromType<ISubscriptionPlanAutocomplete>({
  id: z.string(),
  name: z.string(),
});
