import { PatientEnrollmentRequest, PatientEnrollmentRequestSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter, patientEnrollmentRequestStatusOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { getRejectionReasonInfoItems } from "../../others/cellInfo.utils";

export const usePatientEnrollmentRequestsListContainerColumns = (): CustomTableColumnProps<PatientEnrollmentRequest>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "createdDate",
        header: t("Enrollment Date"),
        type: "datetime",
        accessor: "createdDate",
        sort: {
          columnEnum: PatientEnrollmentRequestSortingField.Created,
        },
      },
      {
        key: "care-team",
        header: t("Care Team"),
        accessor: ({ team }) => team?.name,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => patientEnrollmentRequestStatusOptionsMap[status!]?.label,
        filter: getAutocompleteEnumFilter("PatientEnrollmentRequestStatus", "status"),
      },
      {
        key: "provider",
        header: t("Provider"),
        accessor: ({ team }) => pickLocalizedValue(team?.provider?.name, team?.provider?.nameAr),
      },
      {
        key: "patient",
        header: t("Patient"),
        type: "truncated-text",
        accessor: ({ patient }) => `${patient?.firstName || ""} ${patient?.lastName || ""}`,
        filter: {
          type: "string",
          label: t("Patient"),
          name: "patientName",
        },
      },
      {
        key: "rejection-reason",
        header: t("Failed Reasons"),
        type: "info",
        infoCellOptions: {
          title: t("Failed Reasons"),
          items: ({ error }) => getRejectionReasonInfoItems(error),
          layout: "one-column",
          disableShowButton: ({ error }) => !error,
        },
      },
      {
        key: "requestedStartTimeGte",
        header: t("Requested Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "createdDate.gte",
          label: t("Requested Start Time From"),
        },
      },
      {
        key: "requestedStartTimeLte",
        header: t("Requested Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "createdDate.lte",
          label: t("Requested Start Time To"),
        },
      },
    ],
    [t]
  );
};
