import { FC } from "react";
import { EntityType } from "@/schema/types";
import { SubListsAutocomplete } from "@health/autocompletes";
import { userGenderOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormDatePickerField, FormNumberField, FormTextField, IAutocompleteOption } from "@toolkit/ui";
import { DecisionConditionAutocompletesForm } from "../DecisionConditionAutocompletes/DecisionConditionAutocompletesForm";
import { IDecisionConditionsFormValues } from "../DecisionConditions/DecisionConditionsFormSchema";
import { DecisionConditionFieldListCode, DecisionConditionFieldStringCode } from "../../types";
import { DecisionGuidedCareTemplatesAutocomplete } from "../../components";

type DecisionConditionFieldTypeFormProps = {
  conditionPath: string;
  condition: IDecisionConditionsFormValues;
  allowedValuesOptions: IAutocompleteOption<string>[];
};

export const DecisionConditionFieldTypeForm: FC<DecisionConditionFieldTypeFormProps> = props => {
  const { conditionPath, condition, allowedValuesOptions } = props;

  const { t } = useTranslation("domains");

  const type = condition?.field?.value?.type;
  const code = condition?.field?.value?.code;

  if (type === EntityType.Number) {
    return <FormNumberField name={`${conditionPath}.valueNumber`} placeholder={t("Number")} label={t("Number")} isFloatAllowed />;
  } else if (type === EntityType.String) {
    if (code === DecisionConditionFieldStringCode.Gender) {
      return <FormAutocomplete name={`${conditionPath}.valueString`} label={t("Gender")} options={userGenderOptions} />;
    } else if (code === DecisionConditionFieldStringCode.GuidedCareTemplate) {
      return <DecisionGuidedCareTemplatesAutocomplete name={`${conditionPath}.valueString`} />;
    } else if (allowedValuesOptions?.length) {
      return <FormAutocomplete name={`${conditionPath}.valueString`} label={t("Value")} options={allowedValuesOptions} />;
    } else {
      return <FormTextField name={`${conditionPath}.valueString`} placeholder={t("Text")} label={t("Text")} />;
    }
  } else if (type === EntityType.List) {
    return <SubListsAutocomplete name={`${conditionPath}.valueList`} filter={{ field: condition?.field?.value?.id }} />;
  } else if (type === EntityType.Id) {
    return <DecisionConditionAutocompletesForm name={`${conditionPath}.valueID`} code={code as DecisionConditionFieldListCode} />;
  } else if (type === EntityType.Date) {
    return <FormDatePickerField variant={"filled"} name={`${conditionPath}.valueDate`} label={t("Date")} placeholder={t("Date")} />;
  } else {
    return <></>;
  }
};
