import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersPermissionsGroupGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UsersPermissionsGroupGetQuery = { __typename?: 'Query', permissionGroup?: { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', id: string, code?: Types.PermissionEnum | null, name: string } | null> | null } | null };


export const UsersPermissionsGroupGetDocument = gql`
    query UsersPermissionsGroupGet($id: ID!) {
  permissionGroup(id: $id) {
    id
    name
    permissions {
      id
      code
      name
    }
  }
}
    `;

/**
 * __useUsersPermissionsGroupGetQuery__
 *
 * To run a query within a React component, call `useUsersPermissionsGroupGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPermissionsGroupGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPermissionsGroupGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUsersPermissionsGroupGetQuery(baseOptions: Apollo.QueryHookOptions<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables> & ({ variables: UsersPermissionsGroupGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables>(UsersPermissionsGroupGetDocument, options);
      }
export function useUsersPermissionsGroupGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables>(UsersPermissionsGroupGetDocument, options);
        }
export function useUsersPermissionsGroupGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables>(UsersPermissionsGroupGetDocument, options);
        }
export type UsersPermissionsGroupGetQueryHookResult = ReturnType<typeof useUsersPermissionsGroupGetQuery>;
export type UsersPermissionsGroupGetLazyQueryHookResult = ReturnType<typeof useUsersPermissionsGroupGetLazyQuery>;
export type UsersPermissionsGroupGetSuspenseQueryHookResult = ReturnType<typeof useUsersPermissionsGroupGetSuspenseQuery>;
export type UsersPermissionsGroupGetQueryResult = Apollo.QueryResult<UsersPermissionsGroupGetQuery, UsersPermissionsGroupGetQueryVariables>;