import { MarketplaceBrand, MarketplaceBrandInput } from "@/schema/types";
import { IBrandUpsertFormValues } from "../forms/BrandFormUpsert/BrandUpsertFormSchema";

export const convertBrandFormValuesToBackEndValues = (values: IBrandUpsertFormValues): MarketplaceBrandInput => {
  return {
    name: values.name,
    nameAr: values.nameAr!,
    image: values.image!,
  };
};

export const convertBrandToFormValues = (brand: MarketplaceBrand): IBrandUpsertFormValues => {
  return {
    name: brand.name!,
    nameAr: brand.nameAr,
    image: brand.image,
  };
};
