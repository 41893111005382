import React, { FC } from "react";
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import { useDecisionCitiesAutocompleteQuery } from "../../gql";

type DecisionCitiesAutocompleteProps = {
  name: string;
};

export const DecisionCitiesAutocomplete: FC<DecisionCitiesAutocompleteProps> = props => {
  const { name } = props;

  const { t } = useTranslation("domains");

  const { data, ...restRelayStyleConnection } = useRelayStyleConnection({
    useQuery: useDecisionCitiesAutocompleteQuery,
    searchKey: "name_Icontains",
  });

  const options = mapToAutocompleteOptions(data, "id", item => pickLocalizedValue(item?.name!, item?.nameAr!));

  return <FormAutocomplete {...restRelayStyleConnection} name={name} options={options} label={t("City")} />;
};
