import { Plan } from "@/schema/types";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import { SubscriptionInfo } from "./components/SubscriptionInfo.component";
import { useSubscriptionPlanFormState } from "./useSubscriptionPlanFormState";

const SubscriptionPlansForm: FC = () => {
  const { onSubmit, handleSubmit, methods, loading, mutationErrors, reactFromErrors, formButtonTitle, planData, isSubmitting } =
    useSubscriptionPlanFormState();

  const formErrors = combineErrors(reactFromErrors, mutationErrors);

  const { t } = useTranslation("admin");

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper actions={<FormActions hasCancel hasFormButton formButtonTitle={formButtonTitle()} newButtonDisabled={isSubmitting} />}>
          <FormCard loading={loading} title={t("General Information")} doYouHaveData={true}>
            <SubscriptionInfo formErrors={formErrors} formData={planData as Plan} />
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};

export default SubscriptionPlansForm;
