import { OrderDirection, UserFilterInput, UserSortField } from "@/schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { GridProvider } from "@/shared/components/GridProvider/GridProvider";
import { FC } from "react";
import { useUserListQuery, useUserDeleteMutation } from "../../gql";
import { IUserListNode } from "../../types";
import { useUserListColumns } from "./useUserListColumns";

export type IUserListEvent =
  | {
      type: "ADD_NEW_CLICK";
    }
  | {
      type: "EDIT_CLICK";
      payload: {
        item: IUserListNode;
      };
    };

type UserListProps = {
  filter?: UserFilterInput;
  isActionsShow?: boolean;
  onChange?: (event: IUserListEvent) => void;
};

export const UserList: FC<UserListProps> = props => {
  const { filter, isActionsShow = false, onChange } = props;

  const onAddNewItemClick = () => {
    if (!isActionsShow || !onChange) return;

    onChange({
      type: "ADD_NEW_CLICK",
    });
  };

  const onEditRowClick = (item: IUserListNode) => {
    if (!isActionsShow || !onChange) return;

    onChange({
      type: "EDIT_CLICK",
      payload: { item },
    });
  };

  return (
    <GridProvider
      gridName={"userList"}
      query={useUserListQuery}
      columns={useUserListColumns()}
      hideFilterInput
      hasTableSetting
      variables={{
        filter,
        sortBy: {
          field: UserSortField.DateJoined,
          direction: OrderDirection.Desc,
        },
      }}
      tableAction={{
        isEditVisible: isActionsShow,
        onEditRow: onEditRowClick,
        isDeleteVisible: isActionsShow,
      }}
      deleteItemProps={
        isActionsShow
          ? {
              entityTypeName: "User",
              name: "User",
              useDeleteMutation: useUserDeleteMutation,
            }
          : undefined
      }
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />

            {isActionsShow && <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
