import React, { useState } from "react";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { MutationHookOptions, MutationTuple, OperationVariables } from "@apollo/client";
import { useOpenState } from "@toolkit/core";
import { getMutationErrors } from "./utils";
import { useAddToast } from "../../hooks";
import { CustomDialog } from "../Dialogs";
import { CustomToggleButton } from "../ToggleButton";

type ActivationSwitchProps<T, V extends OperationVariables> = {
  id: string;
  isActive: boolean;
  isConfirmRequired?: boolean;
  mutation: (baseOptions?: MutationHookOptions<T, V>) => MutationTuple<T, V>;
};

export const ActivationSwitch = <T, V extends OperationVariables>(props: ActivationSwitchProps<T, V>) => {
  const { id, isActive, isConfirmRequired = false, mutation: useMutation } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation();
  const { succeeded, failed } = useAddToast();
  const { open, handleToggle } = useOpenState();

  const [activate, { loading: isSubmitting }] = useMutation({
    onCompleted: mutationData => {
      const errors = getMutationErrors(mutationData);

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(active ? t("Deactivated Successfully") : t("Activated Successfully"));
        setActive(prevState => !prevState);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onActivateMutation = () => {
    activate({
      variables: { id } as unknown as V,
    });
  };

  const onSwitchChange = () => {
    onActivateMutation();
  };

  const onCustomDialogConfirm = () => {
    onActivateMutation();
  };

  return (
    <>
      {isConfirmRequired ? (
        <CustomDialog
          type={"warning"}
          button={<CustomToggleButton checked={active} disabled={isSubmitting} isLoading={isSubmitting} onChange={handleToggle} />}
          isOpen={open}
          text={{ title: active ? t("Deactivate Confirmation") : t("Activate Confirmation") }}
          onClose={handleToggle}
          onConfirm={onCustomDialogConfirm}
        />
      ) : (
        <CustomToggleButton checked={active} disabled={isSubmitting} isLoading={isSubmitting} onChange={onSwitchChange} />
      )}
    </>
  );
};
