import { makeStyles } from "@toolkit/ui";

export const useCaseManagementPatientDigitalTwinLinearProgressStyle = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  category: {
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    fontStyle: "italic",
  },
}));
