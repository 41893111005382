import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCodeSystemConceptSearchQueryVariables = Types.Exact<{
  keyword: Types.Scalars['String']['input'];
  searchParameters?: Types.InputMaybe<Array<Types.InputMaybe<Types.SearchParameter>> | Types.InputMaybe<Types.SearchParameter>>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  codeSystemCode?: Types.InputMaybe<Types.CodeSystemCode>;
}>;


export type GetCodeSystemConceptSearchQuery = { __typename?: 'Query', codeSystemConceptSearch?: { __typename?: 'CodeSystemConceptConnection', totalCount?: number | null, pageInfo?: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } | null, edges?: Array<{ __typename?: 'CodeSystemConceptEdge', node?: { __typename?: 'CodeSystemConcept', code?: string | null, display?: string | null } | null } | null> | null } | null };


export const GetCodeSystemConceptSearchDocument = gql`
    query getCodeSystemConceptSearch($keyword: String!, $searchParameters: [SearchParameter], $first: Int, $after: String, $codeSystemCode: CodeSystemCode) {
  codeSystemConceptSearch(
    keyword: $keyword
    searchParameters: $searchParameters
    first: $first
    after: $after
    codeSystemCode: $codeSystemCode
  ) {
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
    edges {
      node {
        code
        display
      }
    }
  }
}
    `;

/**
 * __useGetCodeSystemConceptSearchQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemConceptSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemConceptSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemConceptSearchQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      searchParameters: // value for 'searchParameters'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      codeSystemCode: // value for 'codeSystemCode'
 *   },
 * });
 */
export function useGetCodeSystemConceptSearchQuery(baseOptions: Apollo.QueryHookOptions<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables> & ({ variables: GetCodeSystemConceptSearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables>(GetCodeSystemConceptSearchDocument, options);
      }
export function useGetCodeSystemConceptSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables>(GetCodeSystemConceptSearchDocument, options);
        }
export function useGetCodeSystemConceptSearchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables>(GetCodeSystemConceptSearchDocument, options);
        }
export type GetCodeSystemConceptSearchQueryHookResult = ReturnType<typeof useGetCodeSystemConceptSearchQuery>;
export type GetCodeSystemConceptSearchLazyQueryHookResult = ReturnType<typeof useGetCodeSystemConceptSearchLazyQuery>;
export type GetCodeSystemConceptSearchSuspenseQueryHookResult = ReturnType<typeof useGetCodeSystemConceptSearchSuspenseQuery>;
export type GetCodeSystemConceptSearchQueryResult = Apollo.QueryResult<GetCodeSystemConceptSearchQuery, GetCodeSystemConceptSearchQueryVariables>;