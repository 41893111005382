import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useLanguagesAutocompleteQuery } from "./gql";
import { pickLocalizedValue } from "@toolkit/i18n";
import { ILanguageAutocomplete } from "./schema";

export const createLanguageAutocompleteOption = (language: ILanguageAutocomplete) => {
  return createAutocompleteOption(
    {
      id: language?.id!,
      code: language?.code!,
      display: language?.display!,
      displayAr: language?.displayAr!,
    },
    "code",
    item => pickLocalizedValue(item?.display, item?.displayAr)
  );
};

export const getLanguageAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "languages",
    query: useLanguagesAutocompleteQuery,
    labelBy: item => pickLocalizedValue(item?.display, item?.displayAr!),
    backendAccessor: "code",
  });
};
