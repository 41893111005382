import { i18n } from "@toolkit/i18n";
import { ManualOrderStatus } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const manualOrderStatusOptionsMap: IEnumToOptionsMap<ManualOrderStatus> = {
  [ManualOrderStatus.AuthorizationApproved]: {
    key: ManualOrderStatus.AuthorizationApproved,
    get label() {
      return i18n.t("Authorization Approved", { ns: "domains" });
    },
    value: ManualOrderStatus.AuthorizationApproved,
  },
  [ManualOrderStatus.AuthorizationReject]: {
    key: ManualOrderStatus.AuthorizationReject,
    get label() {
      return i18n.t("Authorization Reject", { ns: "domains" });
    },
    value: ManualOrderStatus.AuthorizationReject,
  },
  [ManualOrderStatus.Cancelled]: {
    key: ManualOrderStatus.Cancelled,
    get label() {
      return i18n.t("Cancelled", { ns: "domains" });
    },
    value: ManualOrderStatus.Cancelled,
  },
  [ManualOrderStatus.Delivered]: {
    key: ManualOrderStatus.Delivered,
    get label() {
      return i18n.t("Delivered", { ns: "domains" });
    },
    value: ManualOrderStatus.Delivered,
  },
  [ManualOrderStatus.Failed]: {
    key: ManualOrderStatus.Failed,
    get label() {
      return i18n.t("Failed", { ns: "domains" });
    },
    value: ManualOrderStatus.Failed,
  },
  [ManualOrderStatus.OutForDelivery]: {
    key: ManualOrderStatus.OutForDelivery,
    get label() {
      return i18n.t("Out For Delivery", { ns: "domains" });
    },
    value: ManualOrderStatus.OutForDelivery,
  },
  [ManualOrderStatus.PickedUp]: {
    key: ManualOrderStatus.PickedUp,
    get label() {
      return i18n.t("Picked Up", { ns: "domains" });
    },
    value: ManualOrderStatus.PickedUp,
  },
  [ManualOrderStatus.Rejected]: {
    key: ManualOrderStatus.Rejected,
    get label() {
      return i18n.t("Rejected", { ns: "domains" });
    },
    value: ManualOrderStatus.Rejected,
  },
  [ManualOrderStatus.Timeout]: {
    key: ManualOrderStatus.Timeout,
    get label() {
      return i18n.t("Timeout", { ns: "domains" });
    },
    value: ManualOrderStatus.Timeout,
  },
  [ManualOrderStatus.WaitingAuthorizationApproval]: {
    key: ManualOrderStatus.WaitingAuthorizationApproval,
    get label() {
      return i18n.t("Waiting Authorization Approval", { ns: "domains" });
    },
    value: ManualOrderStatus.WaitingAuthorizationApproval,
  },
  [ManualOrderStatus.WaitingPatientApproval]: {
    key: ManualOrderStatus.WaitingPatientApproval,
    get label() {
      return i18n.t("Waiting Patient Approval", { ns: "domains" });
    },
    value: ManualOrderStatus.WaitingPatientApproval,
  },
  [ManualOrderStatus.WaitingProviderApproval]: {
    key: ManualOrderStatus.WaitingProviderApproval,
    get label() {
      return i18n.t("Waiting Provider Approval", { ns: "domains" });
    },
    value: ManualOrderStatus.WaitingProviderApproval,
  },
};

export const manualOrderStatusOptions = Object.values(manualOrderStatusOptionsMap);

export const manualOrderStatusActiveOptions = Object.values(manualOrderStatusOptionsMap).filter(
  item =>
    !(
      item.value === ManualOrderStatus.Cancelled ||
      item.value === ManualOrderStatus.Failed ||
      item.value === ManualOrderStatus.Rejected ||
      item.value === ManualOrderStatus.Timeout
    )
);
