import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CaseManagementPatientDigitalTwinGetQueryVariables = Types.Exact<{
  patientId: Types.Scalars['String']['input'];
}>;


export type CaseManagementPatientDigitalTwinGetQuery = { __typename?: 'Query', patientDashboard?: { __typename?: 'PatientDashboardOutput', diagnosticRiskScores?: { __typename?: 'DiagnosticRiskScores', diabetes?: number | null, categoryDiabetes?: string | null, asthma?: number | null, categoryHypertension?: string | null } | null, prognosticRiskScores?: { __typename?: 'PrognosticRiskScores', diabetesComplications?: { __typename?: 'DiabetesPrognosis', riskOfNephropathyComplications?: number | null, categoryNephropathy?: string | null, riskOfNeuropathyComplications?: number | null, categoryNeuropathy?: string | null, riskOfRetinopathyComplications?: number | null, categoryRetinopathy?: string | null, riskOfHypertensionComplications?: number | null, categoryHypertension?: string | null, riskOfIschemicHeartDiseaseComplications?: number | null, categoryIschemicHeartDisease?: string | null } | null, hypertensionComplications?: { __typename?: 'HypertensionPrognosis', riskOfNephropathyComplications?: number | null, categoryNephropathy?: string | null, riskOfRetinopathyComplications?: number | null, categoryRetinopathy?: string | null, riskOfHeartFailureComplications?: number | null, categoryHeartFailure?: string | null, riskOfCerebrovascularComplications?: number | null, categoryCerebrovascular?: string | null, riskOfIschemicHeartDiseaseComplications?: number | null, categoryIschemicHeartDisease?: string | null } | null } | null } | null };


export const CaseManagementPatientDigitalTwinGetDocument = gql`
    query CaseManagementPatientDigitalTwinGet($patientId: String!) {
  patientDashboard(patientId: $patientId) {
    diagnosticRiskScores {
      diabetes
      categoryDiabetes
      asthma
      categoryHypertension
    }
    prognosticRiskScores {
      diabetesComplications {
        riskOfNephropathyComplications
        categoryNephropathy
        riskOfNeuropathyComplications
        categoryNeuropathy
        riskOfRetinopathyComplications
        categoryRetinopathy
        riskOfHypertensionComplications
        categoryHypertension
        riskOfIschemicHeartDiseaseComplications
        categoryIschemicHeartDisease
      }
      hypertensionComplications {
        riskOfNephropathyComplications
        categoryNephropathy
        riskOfRetinopathyComplications
        categoryRetinopathy
        riskOfHeartFailureComplications
        categoryHeartFailure
        riskOfCerebrovascularComplications
        categoryCerebrovascular
        riskOfIschemicHeartDiseaseComplications
        categoryIschemicHeartDisease
      }
    }
  }
}
    `;

/**
 * __useCaseManagementPatientDigitalTwinGetQuery__
 *
 * To run a query within a React component, call `useCaseManagementPatientDigitalTwinGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseManagementPatientDigitalTwinGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseManagementPatientDigitalTwinGetQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCaseManagementPatientDigitalTwinGetQuery(baseOptions: Apollo.QueryHookOptions<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables> & ({ variables: CaseManagementPatientDigitalTwinGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables>(CaseManagementPatientDigitalTwinGetDocument, options);
      }
export function useCaseManagementPatientDigitalTwinGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables>(CaseManagementPatientDigitalTwinGetDocument, options);
        }
export function useCaseManagementPatientDigitalTwinGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables>(CaseManagementPatientDigitalTwinGetDocument, options);
        }
export type CaseManagementPatientDigitalTwinGetQueryHookResult = ReturnType<typeof useCaseManagementPatientDigitalTwinGetQuery>;
export type CaseManagementPatientDigitalTwinGetLazyQueryHookResult = ReturnType<typeof useCaseManagementPatientDigitalTwinGetLazyQuery>;
export type CaseManagementPatientDigitalTwinGetSuspenseQueryHookResult = ReturnType<typeof useCaseManagementPatientDigitalTwinGetSuspenseQuery>;
export type CaseManagementPatientDigitalTwinGetQueryResult = Apollo.QueryResult<CaseManagementPatientDigitalTwinGetQuery, CaseManagementPatientDigitalTwinGetQueryVariables>;