import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type ConditionFragment = { __typename?: 'Condition', id?: string | null, conditionOperation?: Types.ConditionOperation | null, entityType?: Types.EntityType | null, isGroup?: boolean | null, operator?: Types.Operator | null, value?: { __typename?: 'ConditionValue', extra?: string | null, valueDate?: any | null, valueList?: string | null, valueID?: string | null, valueNumber?: number | null, valueString?: string | null } | null, field?: { __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null, operators?: Array<Types.Operator | null> | null, allowedValues?: Array<string | null> | null, fieldType?: { __typename?: 'FieldType', id?: string | null, type?: Types.EntityType | null } | null, category?: { __typename?: 'FieldCategory', id?: string | null, code?: string | null, display?: string | null } | null } | null };

export const ConditionFragmentDoc = gql`
    fragment Condition on Condition {
  id
  conditionOperation
  entityType
  isGroup
  operator
  value {
    extra
    valueDate
    valueList
    valueID
    valueNumber
    valueString
  }
  field {
    id
    code
    display
    operators
    allowedValues
    fieldType {
      id
      type
    }
    category {
      id
      code
      display
    }
  }
}
    `;