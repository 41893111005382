import { SidebarDataType } from "@toolkit/ui";
import RouteItem, { ParentRoute, RouteWithElement } from "./routeTypes";

const isRouteSelected = (item: RouteWithElement): boolean => {
  const { route, fullPath } = item;
  const routeParts = route?.split("/").filter(Boolean);
  const pathnames = location?.pathname?.split("/").filter(Boolean);

  if (fullPath === location?.pathname) {
    return true;
  }
  if (!routeParts || routeParts.length <= 1) {
    return false;
  }
  return routeParts?.every(part => pathnames.includes(part));
};

export function routeWithSelectedItems(routes: RouteItem[]): SidebarDataType[] {
  return routes
    .filter((r: any) => !(r.isProhibited || r.hidden))
    .map((route: RouteItem) => ({
      ...route,
      selected: isRouteSelected((route as RouteWithElement)!),
      subItems: (route as ParentRoute).subItems ? routeWithSelectedItems((route as ParentRoute).subItems!) : undefined,
    }))
    .filter(Boolean);
}

export function getBreadCrumbLink(routes: (RouteWithElement | ParentRoute)[], path: string) {
  let fullPath = "";

  routes?.forEach(item => {
    if (item?.id?.toLowerCase() == path?.toLowerCase()) {
      fullPath = (item as RouteWithElement)?.fullPath || "";
    } else if ((item as ParentRoute)?.subItems?.length) {
      getBreadCrumbLink((item as ParentRoute).subItems!, path);
    }
  });
  return fullPath;
}
