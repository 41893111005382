interface DomainsConfig {
  fileServiceApiUrl: string;
  serverUrl: string;
  flowiseBaseUrl: string;
}

class Config {
  private static instance: Config;
  private config: DomainsConfig | null = null;

  private constructor() {}

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  loadConfig(config: DomainsConfig): void {
    this.config = config;
  }

  getConfig(): DomainsConfig {
    if (!this.config) {
      throw new Error("Configuration has not been loaded. Please call loadConfig() first.");
    }
    return this.config;
  }

  get(key: keyof DomainsConfig): string {
    const config = this.getConfig();
    if (config[key] !== undefined) {
      return config[key];
    } else {
      throw new Error(`Key ${key} is not present in the configuration.`);
    }
  }
}

export const domainsConfig = Config.getInstance();

export const initializeDomainsConfig = (config: DomainsConfig): void => {
  domainsConfig.loadConfig(config);
};
