import { makeStyles } from "../../../../base/mui";

export const useInfoDrawerStyles = makeStyles()(theme => ({
  drawer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    padding: theme.spacing(2),
  },
}));
