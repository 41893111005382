import { PermissionEnum, SurveyStatus } from "@/schema/types";
import { hasPermission, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ISurveyListNode } from "../../types";
import { useMutations } from "./useMutations";
import { surveysPaths } from "../../constants";

type UseSurveyListActionsProps = {
  survey?: ISurveyListNode | null;
};

export const useSurveyListActions = ({ survey }: UseSurveyListActionsProps) => {
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const { open: isUnPublishDialogOpen, handleToggle: handleToggleUnPublishModalOpen } = useOpenState();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { open: isCloneModalOpen, handleToggle: handleToggleCloneModalOpen } = useOpenState();

  const navigate = useNavigate();

  const { t } = useTranslation("admin");

  const canManageSurvey = hasPermission(PermissionEnum.ManageSurveys);

  const handleCloseCancelModal = () => {
    navigate(surveysPaths.list.fullPath);
  };

  const handleCancel = () => {
    navigate(surveysPaths.list.fullPath);
  };

  const handleCancelSurvey = () => {
    cancelSurveyMutation({
      variables: {
        cancelSurveyId: survey?.id as string,
      },
    });
    navigate(surveysPaths.list.fullPath);
  };

  const handlePublishModalOpen = () => {
    setIsPublishModalOpen(true);
  };

  const handleToggleCancelModalOpen = () => {
    setIsCancelModalOpen(prevState => !prevState);
  };

  const handleClosePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const {
    isPublishLoading,
    isUnPublishLoading,
    isCloneLoading,
    handlePublish,
    handleUnPublish,
    cancelSurveyMutation,
    cloneSurveyMutation,
  } = useMutations({ survey, onCloneCompleted: handleToggleCloneModalOpen });

  const handleCloneSurvey = (clonedSurveyLabel: string) => {
    cloneSurveyMutation({
      variables: {
        cloneSurveyId: survey?.id!,
        label: clonedSurveyLabel,
      },
    });
  };

  return {
    canUnPublishSurvey: survey?.status === SurveyStatus.Published,
    isSurveyCancelable: survey?.status !== SurveyStatus.Cancelled,
    isPublishModalOpen,
    isUnPublishDialogOpen,
    isPublishLoading,
    isUnPublishLoading,
    isPublishDisabled: survey?.status !== SurveyStatus.Draft && survey?.status !== SurveyStatus.Expired,
    isSaveDisabled: isPublishLoading || isCloneLoading,
    isCloneModalOpen,
    isCancelModalOpen,
    isCloneLoading,
    canManageSurvey,
    isDisabled: survey?.status === SurveyStatus.Published,
    t,
    handlePublish,
    handleUnPublish,
    handlePublishModalOpen,
    handleClosePublishModal,
    handleCancelSurvey,
    handleCancel,
    handleCloseCancelModal,
    handleToggleCancelModalOpen,
    handleToggleUnPublishModalOpen,
    handleCloneSurvey,
    handleToggleCloneModalOpen,
  };
};
