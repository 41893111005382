import { makeStyles } from "@toolkit/ui";

type DecisionConditionsFormStyle = {
  isFieldsMoreThanOne: boolean;
};

export const useDecisionConditionsFormStyle = makeStyles<DecisionConditionsFormStyle>()((theme, params) => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
  removeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapper: {
    marginTop: 24,
  },
  fieldsWrapper: {
    display: "flex",
  },
  formHelperText: {
    color: theme.palette.secondary.main,
  },
  conditionInfoGrid: {
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: 0,
    paddingBottom: "5px",
  },
  dashedConditionInfoGrid: {
    ["@media (max-width:1200px)"]: {
      borderTop: params.isFieldsMoreThanOne ? "dashed 2px" + theme.palette.stale[400] : undefined,
      marginTop: 30,
    },
  },
}));
