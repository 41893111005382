import { makeStyles } from "@toolkit/ui";

export const useOptimaVisitValidationResultDetailsStyles = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    marginTop: 10,
  },
  editsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  guidelineInsightsIcon: {
    position: "absolute",
    [theme.direction === "rtl" ? "left" : "right"]: 20,
    top: 20,
    cursor: "pointer",
  },
  badgesWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
    paddingInline: 6,
    margin: 4,
  },
  badgeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  badgeText: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.normal,
    textAlign: "center",
  },
  badge: {
    display: "flex",
    fontSize: theme.mixins.fonts.fontSize.xl,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    width: 35,
    height: 35,
    borderRadius: 20,
    color: theme.palette.common.white,
  },
}));
