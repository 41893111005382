import { makeStyles } from "@toolkit/ui";

export const useUserWorkspaceDoctorStyle = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    alignItems: "baseline",
    marginInlineEnd: 2,
    gap: 4,
  },
  message: {
    color: theme.palette.primary.main,
    fontSize: theme.mixins.fonts.fontSize.md,
    lineHeight: 1,
  },
}));
