import { FormSwitch, Grid } from "@toolkit/ui";
import React from "react";
import { useTranslation } from "@toolkit/i18n";

export const ProviderNotificationsSettings = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormSwitch name={"notifyByEmail"} label={t("Notify By Email")} />
      </Grid>
    </Grid>
  );
};
