import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { usePermissionsGroupsAutocompleteQuery } from "./gql";
import { IPermissionGroupAutocomplete } from "./schema";

export const createPermissionGroupAutocompleteOption = (permissionGroup: IPermissionGroupAutocomplete) => {
  return createAutocompleteOption({ id: permissionGroup?.id!, name: permissionGroup?.name! }, "id", "name");
};

export const getPermissionsGroupsAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "permissionGroups",
    // @ts-ignore
    query: usePermissionsGroupsAutocompleteQuery,
    labelBy: "name",
    backendAccessor: "id",
  });
};
