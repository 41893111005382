import { createZodAutocompleteFromType } from "@toolkit/core";
import { z } from "zod";

export type IOptimaEncounterTypeAutocomplete = {
  id: string;
  name: string;
};

export const optimaEncounterTypeSchema = createZodAutocompleteFromType<IOptimaEncounterTypeAutocomplete>({
  id: z.string(),
  name: z.string(),
});
