import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ItemCodeMaximumQuantityAllowedListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filter?: Types.InputMaybe<Types.ItemCodeMaximumQuantityAllowedFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  sortBy?: Types.InputMaybe<Types.ItemCodeMaximumQuantityAllowedSorting>;
}>;


export type ItemCodeMaximumQuantityAllowedListQuery = { __typename?: 'Query', getAllItemCodeMaximumQuantityAllowed?: { __typename?: 'ItemCodeMaximumQuantityAllowedConnection', edges: Array<{ __typename?: 'ItemCodeMaximumQuantityAllowedEdge', node: { __typename?: 'ItemCodeMaximumQuantityAllowed', id: string, itemCode: string, maximumAllowed: number, codeSystem: string, description?: string | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const ItemCodeMaximumQuantityAllowedListDocument = gql`
    query ItemCodeMaximumQuantityAllowedList($after: String, $before: String, $filter: ItemCodeMaximumQuantityAllowedFilter, $first: Int, $last: Int, $sortBy: ItemCodeMaximumQuantityAllowedSorting) {
  getAllItemCodeMaximumQuantityAllowed(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        itemCode
        maximumAllowed
        codeSystem
        description
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;

/**
 * __useItemCodeMaximumQuantityAllowedListQuery__
 *
 * To run a query within a React component, call `useItemCodeMaximumQuantityAllowedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCodeMaximumQuantityAllowedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCodeMaximumQuantityAllowedListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useItemCodeMaximumQuantityAllowedListQuery(baseOptions?: Apollo.QueryHookOptions<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>(ItemCodeMaximumQuantityAllowedListDocument, options);
      }
export function useItemCodeMaximumQuantityAllowedListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>(ItemCodeMaximumQuantityAllowedListDocument, options);
        }
export function useItemCodeMaximumQuantityAllowedListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>(ItemCodeMaximumQuantityAllowedListDocument, options);
        }
export type ItemCodeMaximumQuantityAllowedListQueryHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedListQuery>;
export type ItemCodeMaximumQuantityAllowedListLazyQueryHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedListLazyQuery>;
export type ItemCodeMaximumQuantityAllowedListSuspenseQueryHookResult = ReturnType<typeof useItemCodeMaximumQuantityAllowedListSuspenseQuery>;
export type ItemCodeMaximumQuantityAllowedListQueryResult = Apollo.QueryResult<ItemCodeMaximumQuantityAllowedListQuery, ItemCodeMaximumQuantityAllowedListQueryVariables>;