import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormAutocomplete, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider } from "react-hook-form";
import {
  bulkMessageSenderFormDefaultValues,
  bulkMessageSenderFormSchema,
  IBulkMessageSenderFormValues,
} from "./BulkMessageSenderFormSchema";
import { sendBulkMessagesMethodOptions } from "@health/enum-options";

export type IBulkMessageSenderUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IBulkMessageSenderFormValues;
  };
};

type BulkMessageSenderUpsertFormProps = {
  isSubmitting?: boolean;
  onChange: (event: IBulkMessageSenderUpsertFormEvent) => void;
};

export const BulkMessageSenderUpsertForm: FC<BulkMessageSenderUpsertFormProps> = props => {
  const { isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IBulkMessageSenderFormValues>({
    defaultValues: bulkMessageSenderFormDefaultValues,
    schema: bulkMessageSenderFormSchema,
  });

  const { handleSubmit } = form;

  const onSubmit = (values: IBulkMessageSenderFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper actions={<FormActions hasCancel hasFormButton formButtonTitle={t("Publish")} isSaveDisabled={isSubmitting} />}>
          <FormCard title={t("Bulk Message Information")} loading={false} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name='title' label={t("Title")} placeholder={t("Title")} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormTextField name='titleAr' label={t("Title (Arabic)")} placeholder={t("Title (Arabic)")} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormAutocomplete name='method' label={t("Method")} placeholder={t("Method")} options={sendBulkMessagesMethodOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name='message' label={t("Message")} placeholder={t("Message")} multiline rows={6} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField name='messageAr' label={t("Message (Arabic)")} placeholder={t("Message (Arabic)")} multiline rows={6} />
              </Grid>
              <Grid item xs={12}>
                <FormSwitch name='publishWithCriteria' label={t("Publish By Search Criteria")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
