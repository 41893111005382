import { z } from "zod";

export const medicalFormClassificationUpsertFormSchema = z.object({
  name: z.string().min(1).max(255),
  rangeFrom: z.coerce.number().min(0),
  rangeTo: z.coerce.number(),
});

export type IMedicalFormClassificationUpsertFormValues = z.infer<typeof medicalFormClassificationUpsertFormSchema>;

export const medicalFormClassificationUpsertFormDefaultValues: Partial<IMedicalFormClassificationUpsertFormValues> = {
  name: "",
  rangeFrom: undefined,
  rangeTo: undefined,
};
