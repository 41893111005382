import { i18n } from "@toolkit/i18n";
import { OptimaEditLevel } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const optimaEditLevelTypeOptionsMap: IEnumToOptionsMap<OptimaEditLevel> = {
  [OptimaEditLevel.All]: {
    key: OptimaEditLevel.All,
    get label() {
      return i18n.t("All", { ns: "domains" });
    },
    value: OptimaEditLevel.All,
  },
  [OptimaEditLevel.Rcm]: {
    key: OptimaEditLevel.Rcm,
    get label() {
      return i18n.t("Rcm", { ns: "domains" });
    },
    value: OptimaEditLevel.Rcm,
  },
};

export const optimaEditLevelTypeOptions = Object.values(optimaEditLevelTypeOptionsMap);
