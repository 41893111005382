import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OptimaActionUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.OptimaActionInput;
}>;


export type OptimaActionUpdateMutation = { __typename?: 'Mutation', optimaActionUpdate?: { __typename?: 'OptimaAction', id: string } | null };


export const OptimaActionUpdateDocument = gql`
    mutation OptimaActionUpdate($id: ID!, $input: OptimaActionInput!) {
  optimaActionUpdate(id: $id, input: $input) {
    id
  }
}
    `;
export type OptimaActionUpdateMutationFn = Apollo.MutationFunction<OptimaActionUpdateMutation, OptimaActionUpdateMutationVariables>;

/**
 * __useOptimaActionUpdateMutation__
 *
 * To run a mutation, you first call `useOptimaActionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptimaActionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optimaActionUpdateMutation, { data, loading, error }] = useOptimaActionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOptimaActionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OptimaActionUpdateMutation, OptimaActionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OptimaActionUpdateMutation, OptimaActionUpdateMutationVariables>(OptimaActionUpdateDocument, options);
      }
export type OptimaActionUpdateMutationHookResult = ReturnType<typeof useOptimaActionUpdateMutation>;
export type OptimaActionUpdateMutationResult = Apollo.MutationResult<OptimaActionUpdateMutation>;
export type OptimaActionUpdateMutationOptions = Apollo.BaseMutationOptions<OptimaActionUpdateMutation, OptimaActionUpdateMutationVariables>;