import { Branch } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useBranchGetQuery, useBranchUpdateMutation } from "../../gql";
import { convertBranchFormValuesToBackEndValues } from "../../others";
import { BranchUpsertForm } from "../../forms/BranchUpsert/BranchUpsertForm";
import { IBranchUpsertForm } from "../../forms/BranchUpsert/BranchUpsertFormSchema";

type BranchUpdateContainerProps = {
  branchId: string;
  isAdmin?: boolean;
};

export const BranchUpdateContainer: FC<BranchUpdateContainerProps> = props => {
  const { branchId, isAdmin } = props;

  const { t } = useTranslation("domains");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { data, loading } = useBranchGetQuery({
    variables: { id: branchId! },
    skip: !branchId,
    fetchPolicy: "no-cache",
  });

  const branch = data?.branch as Branch;

  const [updateBranch, { loading: isSubmitting }] = useBranchUpdateMutation({
    onCompleted: mutationData => {
      const errors = mutationData?.branchUpdate?.branchErrors;

      if (errors?.length) {
        failed(formatMessageErrors(errors));
      } else {
        succeeded(t("Branch updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onBranchUpsertFormChange = (values: IBranchUpsertForm) => {
    updateBranch({
      variables: {
        id: branchId,
        input: convertBranchFormValuesToBackEndValues(values),
      },
    });
  };

  return (
    <BranchUpsertForm
      buttonLabel={t("Update")}
      branch={branch}
      isAdmin={isAdmin}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onBranchUpsertFormChange}
    />
  );
};
