import { makeStyles, Theme } from "@toolkit/ui";

export const useGoogleMapSearchWrapperStyles = makeStyles()((theme: Theme) => ({
  root: {
    flex: 1,
    height: "100%",
    flexDirection: "column",
    backgroundColor: "inherit",
  },
  container: {
    border: `1px solid ${theme.palette.background.default}`,
    borderRadius: 10,
    paddingLeft: 10,
    width: 250,
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchBoxContainer: {
    position: "relative",
    width: "100%",
  },
  locationButton: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 0,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "0 5px 5px 0",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  buttonText: {
    fontSize: theme.mixins.fonts.fontSize.xs,
    margin: "0 5px",
  },
  placesListContainer: {
    position: "absolute",
    zIndex: 4,
  },
  text: {
    width: "100%",
  },
}));
