import { ItemCodeMaximumQuantityAllowed } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { IItemCodeMaximumQuantityAllowedUpdateContainerParams } from "../../types";
import { convertItemCodeMaximumQuantityAllowedFormValuesToBackEndValues } from "../../others";
import { useItemCodeMaximumQuantityAllowedGetQuery, useItemCodeMaximumQuantityAllowedUpdateMutation } from "../../gql";
import {
  IItemCodeMaximumQuantityAllowedUpsertFormEvent,
  ItemCodeMaximumQuantityAllowedUpsertForm,
} from "../../forms/ItemCodeMaximumQuantityAllowedInformationUpsert/ItemCodeMaximumQuantityAllowedInformationUpsertForm";
import { useSetItemCodeMaximumQuantityAllowedsBreadcrumbs } from "../../hooks/useSetItemCodeMaximumQuantityAllowedsBreadcrumbs";

export const ItemCodeMaximumQuantityAllowedUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { itemCodeMaximumQuantityAllowedId } = useParams<IItemCodeMaximumQuantityAllowedUpdateContainerParams>();

  const { data, loading } = useItemCodeMaximumQuantityAllowedGetQuery({
    variables: { getItemCodeMaximumQuantityAllowedId: itemCodeMaximumQuantityAllowedId! },
    skip: !itemCodeMaximumQuantityAllowedId,
    fetchPolicy: "no-cache",
  });

  const itemCodeMaximumQuantityAllowed = data?.getItemCodeMaximumQuantityAllowed as ItemCodeMaximumQuantityAllowed;

  const [updateItemCodeMaximumQuantityAllowed, { loading: isSubmitting }] = useItemCodeMaximumQuantityAllowedUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.updateItemCodeMaximumQuantityAllowed?.id) {
        succeeded(t("Item Code Maximum Quantity Allowed updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onItemCodeMaximumQuantityAllowedUpsertFormChange = (event: IItemCodeMaximumQuantityAllowedUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertItemCodeMaximumQuantityAllowedFormValuesToBackEndValues(event.payload.values);

      updateItemCodeMaximumQuantityAllowed({
        variables: {
          updateItemCodeMaximumQuantityAllowedId: itemCodeMaximumQuantityAllowedId!,
          input: values,
        },
      });
    }
  };

  useSetItemCodeMaximumQuantityAllowedsBreadcrumbs("UPDATE", itemCodeMaximumQuantityAllowed?.itemCode);

  return (
    <ItemCodeMaximumQuantityAllowedUpsertForm
      buttonLabel={t("Update")}
      itemCodeMaximumQuantityAllowed={itemCodeMaximumQuantityAllowed}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onItemCodeMaximumQuantityAllowedUpsertFormChange}
    />
  );
};
