import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UploadBulkFileMutationVariables = Types.Exact<{
  code: Types.BulkTemplateCode;
  fileName: Types.Scalars['String']['input'];
  entityId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type UploadBulkFileMutation = { __typename?: 'Mutation', uploadBulkFile?: { __typename?: 'BulkFile', id?: string | null, code?: Types.BulkTemplateCode | null, errors?: Array<{ __typename?: 'BulkGraphqlError', field?: string | null, message?: string | null } | null> | null } | null };


export const UploadBulkFileDocument = gql`
    mutation UploadBulkFile($code: BulkTemplateCode!, $fileName: String!, $entityId: ID) {
  uploadBulkFile(code: $code, fileName: $fileName, entityID: $entityId) {
    id
    code
    errors {
      field
      message
    }
  }
}
    `;
export type UploadBulkFileMutationFn = Apollo.MutationFunction<UploadBulkFileMutation, UploadBulkFileMutationVariables>;

/**
 * __useUploadBulkFileMutation__
 *
 * To run a mutation, you first call `useUploadBulkFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBulkFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBulkFileMutation, { data, loading, error }] = useUploadBulkFileMutation({
 *   variables: {
 *      code: // value for 'code'
 *      fileName: // value for 'fileName'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useUploadBulkFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadBulkFileMutation, UploadBulkFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadBulkFileMutation, UploadBulkFileMutationVariables>(UploadBulkFileDocument, options);
      }
export type UploadBulkFileMutationHookResult = ReturnType<typeof useUploadBulkFileMutation>;
export type UploadBulkFileMutationResult = Apollo.MutationResult<UploadBulkFileMutation>;
export type UploadBulkFileMutationOptions = Apollo.BaseMutationOptions<UploadBulkFileMutation, UploadBulkFileMutationVariables>;