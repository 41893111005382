import { makeStyles } from "@toolkit/ui";

export const useBranchWorkingHoursStyle = makeStyles()(theme => ({
  root: {
    borderRadius: "12px",
    overflow: "hidden",
  },
  tableCellHeader: {
    fontWeight: theme.mixins.fonts.fontWeight.bold,
  },
  tableCellDay: {
    verticalAlign: "top",
  },
  borderBottom: {
    borderBottom: "solid",
    borderBottomColor: theme.palette.stale[200],
  },
}));
