import { cloneElement, FC, ReactElement } from "react";
import { Button, MuiDrawer, TruncateTypography, useMediaQuery } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { getEnvVariable } from "@/env";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { usePatientProfileDrawerStyle } from "./usePatientProfileDrawerStyle";

type PatientProfileDrawerProps = {
  patientId: string;
  patientName: string;
  button?: ReactElement;
};

export const PatientProfileDrawer: FC<PatientProfileDrawerProps> = props => {
  const { patientId, patientName, button } = props;

  const { accessToken } = useOidcUserProfile();

  const { open, handleToggle } = useOpenState();

  const isMobile = useMediaQuery("(max-width:1600px)");

  const { classes } = usePatientProfileDrawerStyle();

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: handleToggle })
      ) : (
        <Button color={"secondary"} variant={"text"} className={classes.button} onClick={handleToggle}>
          <TruncateTypography text={patientName} className={classes.buttonTruncate} />
        </Button>
      )}

      <MuiDrawer anchor={"right"} open={open} onClose={handleToggle} PaperProps={{ sx: { width: isMobile ? "100%" : "80%" } }}>
        <iframe
          title={"patient-profile"}
          src={`${getEnvVariable("SERVER_URL").replace("api", "vendor")}/embed/patient-profile?token=${accessToken}&patientId=${patientId}`}
          className={classes.iFrame}
        />
      </MuiDrawer>
    </>
  );
};
