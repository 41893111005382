import { CptHcpcPrimaryProcedureSortingField } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { ICptHcpcPrimaryProcedureListNode } from "../../types";
import { getDiscrptionsInfoItems } from "@/pages/OptimaMedicalNecessity/others/cellInfo.utils";

export const useCptHcpcPrimaryProcedureListContainerColumns = (): CustomTableColumnProps<ICptHcpcPrimaryProcedureListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "primaryProcedure",
        header: t("Primary Procedure"),
        accessor: "primaryProcedure",
        sort: {
          columnEnum: CptHcpcPrimaryProcedureSortingField.PrimaryProcedure,
        },
        filter: {
          type: "string",
          name: "primaryProcedure",
        },
      },
      {
        key: "itemCode",
        header: t("Item Code"),
        accessor: "itemCode",
        sort: {
          columnEnum: CptHcpcPrimaryProcedureSortingField.ItemCode,
        },
        filter: {
          type: "string",
          name: "itemCode",
        },
      },
      {
        key: "description",
        header: t("Description"),
        type: "info",
        infoCellOptions: {
          title: t("Description"),
          items: ({ description }) => getDiscrptionsInfoItems(description),
          disableShowButton: ({ description }) => !description?.length,
        },
      },
    ],
    [t]
  );
};
