import { useEffect, useState } from "react";
import { useOpenState } from "@toolkit/core";
import { CustomDrawer } from "@toolkit/ui";
import { CaseManagementPatientNotes } from "../../components/CaseManagementPatientNotes/CaseManagementPatientNotes";

type ICaseManagementPatientNotesDrawerData = {
  patientId: string;
};

type IOpen = (data: ICaseManagementPatientNotesDrawerData) => void;
type IClose = () => void;

export const CaseManagementPatientNotesDrawerApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

export const CaseManagementPatientNotesDrawer = () => {
  const [data, setData] = useState<ICaseManagementPatientNotesDrawerData>();

  const { open: isOpen, handleOpen, handleClose } = useOpenState();

  const open: IOpen = _data => {
    handleOpen();
    setData(_data);
  };

  useEffect(() => {
    CaseManagementPatientNotesDrawerApi.open = open;
    CaseManagementPatientNotesDrawerApi.close = handleClose;
  }, []);

  return (
    <CustomDrawer isOpen={isOpen} width={"25vw"} onClose={handleClose}>
      <CaseManagementPatientNotes patientId={data?.patientId!} />
    </CustomDrawer>
  );
};
