import { i18n } from "@toolkit/i18n";
import { HealthDocumentType } from "../../schema/types";
import { IEnumToOptionsMap } from "../../types";

export const healthDocumentTypeOptionsMap: IEnumToOptionsMap<HealthDocumentType> = {
  [HealthDocumentType.Laboratory]: {
    key: HealthDocumentType.Laboratory,
    get label() {
      return i18n.t("Laboratory", { ns: "domains" });
    },
    value: HealthDocumentType.Laboratory,
  },
  [HealthDocumentType.Medication]: {
    key: HealthDocumentType.Medication,
    get label() {
      return i18n.t("Medication", { ns: "domains" });
    },
    value: HealthDocumentType.Medication,
  },
  [HealthDocumentType.Prescription]: {
    key: HealthDocumentType.Prescription,
    get label() {
      return i18n.t("Prescription", { ns: "domains" });
    },
    value: HealthDocumentType.Prescription,
  },
  [HealthDocumentType.Radiology]: {
    key: HealthDocumentType.Radiology,
    get label() {
      return i18n.t("Radiology", { ns: "domains" });
    },
    value: HealthDocumentType.Radiology,
  },
  [HealthDocumentType.Visit]: {
    key: HealthDocumentType.Visit,
    get label() {
      return i18n.t("Visit", { ns: "domains" });
    },
    value: HealthDocumentType.Visit,
  },
};

export const healthDocumentTypeOptions = Object.values(healthDocumentTypeOptionsMap);
