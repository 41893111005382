import { formatGraphQLError } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { brandsBreadcrumb } from "../../constants";
import { BrandUpsertForm, IBrandUpsertFormValues } from "../../forms";
import { MarketplaceBrandUpdateMutation, useMarketplaceBrandQuery, useMarketplaceBrandUpdateMutation } from "../../gql";
import { IBrandUpdateContainerParams } from "../../types";
import { convertBrandFormValuesToBackEndValues } from "../../utils";

export const BrandUpdateContainer: React.FC = () => {
  const { t } = useTranslation("admin");
  const { brandId } = useParams<IBrandUpdateContainerParams>();

  const { data, loading } = useMarketplaceBrandQuery({
    variables: { marketplaceBrandId: brandId! },
    skip: !brandId,
  });
  const brand = data?.marketplaceBrand;

  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const { succeeded, failed } = useAddToast();
  const [fetchBrandUpdateMutation, { loading: isSubmitting }] = useMarketplaceBrandUpdateMutation({
    onCompleted: (data: MarketplaceBrandUpdateMutation) => {
      if (data?.marketplaceBrandUpdate?.id) {
        succeeded(t("Brand updated successfully!"));
        navigate(-1);
      } else {
        failed(t("Brand update failed!"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
  });
  const onBrandUpsertFormChange = (values: IBrandUpsertFormValues) => {
    const _values = convertBrandFormValuesToBackEndValues(values);

    fetchBrandUpdateMutation({
      variables: {
        marketplaceBrandUpdateId: brandId!,
        input: _values,
      },
    });
  };
  useEffect(() => {
    const { title, main, update } = brandsBreadcrumb();
    setBreadCrumb({ title, values: [main, update(pickLocalizedValue(brand?.name!, brand?.nameAr!))] });
  }, [setBreadCrumb, brand, t]);
  return (
    <BrandUpsertForm
      isLoading={loading}
      brand={brand!}
      isSubmitting={isSubmitting}
      submitButtonLabel={t("Update")}
      onChange={onBrandUpsertFormChange}
    />
  );
};
