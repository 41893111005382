import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSubListQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetSubListQuery = { __typename?: 'Query', subList?: { __typename: 'SubList', display?: string | null, id?: string | null, field?: { __typename?: 'Field', code?: string | null, display?: string | null, id?: string | null } | null } | null };


export const GetSubListDocument = gql`
    query getSubList($id: ID!) {
  subList: getSubList(id: $id) {
    __typename
    display
    field {
      code
      display
      id
    }
    id
  }
}
    `;

/**
 * __useGetSubListQuery__
 *
 * To run a query within a React component, call `useGetSubListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSubListQuery(baseOptions: Apollo.QueryHookOptions<GetSubListQuery, GetSubListQueryVariables> & ({ variables: GetSubListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubListQuery, GetSubListQueryVariables>(GetSubListDocument, options);
      }
export function useGetSubListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubListQuery, GetSubListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubListQuery, GetSubListQueryVariables>(GetSubListDocument, options);
        }
export function useGetSubListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSubListQuery, GetSubListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubListQuery, GetSubListQueryVariables>(GetSubListDocument, options);
        }
export type GetSubListQueryHookResult = ReturnType<typeof useGetSubListQuery>;
export type GetSubListLazyQueryHookResult = ReturnType<typeof useGetSubListLazyQuery>;
export type GetSubListSuspenseQueryHookResult = ReturnType<typeof useGetSubListSuspenseQuery>;
export type GetSubListQueryResult = Apollo.QueryResult<GetSubListQuery, GetSubListQueryVariables>;