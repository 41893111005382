/* eslint-disable no-nested-ternary */
import { PermissionEnum } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useHealthProgramNetworkActivationMutation } from "../../gql";

type HealthProgramNetworkActivationProps = {
  id: string;
  isActive: boolean;
};

export const HealthProgramNetworkActivation: FC<HealthProgramNetworkActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const [activateHealthProgramNetwork, { loading: isSubmitting }] = useHealthProgramNetworkActivationMutation({
    onCompleted: data => {
      if (data?.healthProgramNetworkUpdateActiveStatus?.entity?.id) {
        setActive(!!data?.healthProgramNetworkUpdateActiveStatus?.entity?.isActive);
        succeeded(t("Network updated successfully"));
      } else {
        failed(t("Network update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    activateHealthProgramNetwork({
      variables: { id },
    });
  };

  return (
    <>
      {hasManageHealthProgramsPermission ? (
        <CustomToggleButton
          disabled={isSubmitting}
          isLoading={isSubmitting}
          isMarginDisabled
          checked={active}
          onChange={handleChangeToggle}
        />
      ) : active ? (
        t("Yes")
      ) : (
        t("No")
      )}
    </>
  );
};
