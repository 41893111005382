import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBlockQuery } from "../../gql/queries";
import { Block } from "@/schema/types";
import { useBlockUpdateMutation } from "../../gql/mutations";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { BlockUpsertForm, IBlockUpsertFormEvent } from "../../forms/BlockUpsert/BlockUpsertForm";
import { convertBlockFormValuesToBackEndValues } from "../../others";
import { useSetBlocksBreadcrumbs } from "../../hooks";

export const BlockUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const { blockId } = useParams<{ blockId }>();

  const { data, loading } = useGetBlockQuery({
    variables: {
      blockId,
    },
    skip: !blockId,
    fetchPolicy: "no-cache",
  });
  const block = data?.block as Block;

  const [blockUpdateMutation, { loading: isMutationLoading }] = useBlockUpdateMutation({
    onCompleted: blockUpdateMutationData => {
      if (blockUpdateMutationData?.blockUpdate?.blockErrors?.length! > 0) {
        const error = t(formatMessageErrors(blockUpdateMutationData?.blockUpdate?.blockErrors));
        failed(error);
      } else {
        succeeded(t("Block updated successfully"));
        navigate(-1);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });
  const onBlockUpsertFormChange = (event: IBlockUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertBlockFormValuesToBackEndValues(event.payload.values);

      blockUpdateMutation({
        variables: {
          id: blockId!,
          input: values,
        },
      });
    }
  };

  useSetBlocksBreadcrumbs("UPDATE", pickLocalizedValue(block?.name, block?.nameAr!));
  return (
    <BlockUpsertForm
      buttonLabel={t("Update")}
      block={block}
      isLoading={loading}
      isSubmitting={isMutationLoading}
      onChange={onBlockUpsertFormChange}
    />
  );
};
