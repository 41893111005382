import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { DoctorsTeamLeadersAutocompleteQuery } from "./gql";

export type IDoctorTeamLeaderAutocomplete = Omit<NonNullable<ExtractNodeType<DoctorsTeamLeadersAutocompleteQuery>["user"]>, "__typename">;

export const doctorTeamLeaderSchema = createZodAutocompleteFromType<IDoctorTeamLeaderAutocomplete>({
  id: z.string(),
  fullName: z.string().nullish(),
});
