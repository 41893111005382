import { CodeSystemConceptFieldDefinition, CodeSystemConceptInput } from "@/schema/types";
import { toSentenceCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { getConceptPropertyValue } from "../AdminManagedListDetails/utils";
import {
  adminManageListBreadcrumb,
  adminManageListFieldBreadcrumb,
  adminManageListsTitleBreadcrumb,
  adminManagedListsPaths,
} from "../constants";
import { useFetchDataForEditCodeSystemConceptQuery, useUpdateCodeSystemConceptMutation } from "../gql";
import { getPropertyValueObjectForBackend, getValidationErrors } from "./utils";

export const useEditAdminManagedListDetailsHook = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation("domains");
  const { failed, succeeded } = useAddToast();
  const { setBreadCrumb } = useBreadCrumbs();
  const { code, id, codeSystemConceptId } = useParams();
  const [isLoadingPropValues, setIsLoadingPropValues] = useState(true);
  const { setValue, formState } = methods;
  const isChanged = formState?.isDirty;

  const { data: editCodeSystemFetchData, loading: isLoadingEditCodeSystemFetchData } = useFetchDataForEditCodeSystemConceptQuery({
    variables: {
      id: id as string,
      codeSystemConceptId: codeSystemConceptId!,
    },
    fetchPolicy: "no-cache",
    onCompleted: ({ propertiesData }) => {
      const properties = propertiesData?.properties || [];
      properties.forEach(propertyItem => {
        setValue(propertyItem?.code!, getConceptPropertyValue(propertyItem));
      });
      setIsLoadingPropValues(false);
    },
  });

  const [updateCodeSystemConcept, { loading: isFetchingUpdate }] = useUpdateCodeSystemConceptMutation({
    onCompleted: () => {
      succeeded(t("You have updated the data successfully"));
      navigate(-1);
    },
    onError: ({ message }) => {
      failed(message);
    },
  });

  const isLoading = isLoadingPropValues || isLoadingEditCodeSystemFetchData;
  const { propertiesData, schemaData } = editCodeSystemFetchData || {};
  const codeDisplayName = toSentenceCase(code!);
  const properties = propertiesData?.properties || [];
  const conceptFieldsDefenitions = (schemaData?.codeSystemDefinition?.codeSystemConceptFields || []) as CodeSystemConceptFieldDefinition[];

  useEffect(() => {
    if (code && id) {
      setBreadCrumb({
        title: adminManageListsTitleBreadcrumb(t),
        values: [
          adminManageListBreadcrumb(t),
          adminManageListFieldBreadcrumb(toSentenceCase(code), adminManagedListsPaths.detailsPath.fullPathWithParams(code, id)),
        ],
      });
    }
  }, [code, id]);

  function reportErrors(errors: ReturnType<typeof getValidationErrors>) {
    errors.forEach(err => {
      failed(err.message);
      methods.setError(err.code, {
        message: err.message,
        type: "required",
      });
    });
  }

  const onSubmit = (data: Record<string, string>) => {
    const validationErrors = getValidationErrors(conceptFieldsDefenitions, data);
    if (validationErrors.length > 0) {
      reportErrors(validationErrors);
      return;
    }
    const updatedData =
      conceptFieldsDefenitions?.map(item => {
        return getPropertyValueObjectForBackend(item!, data[item?.code ?? ""]!);
      }) || [];
    const updatedValues: CodeSystemConceptInput = { ...propertiesData, properties: updatedData };
    delete updatedValues["__typename"];
    delete updatedValues["display"];
    updateCodeSystemConcept({
      variables: {
        codeSystemConcept: updatedValues,
      },
    });
  };

  return {
    methods,
    isChanged,
    isLoading,
    properties,
    codeDisplayName,
    isFetchingUpdate,
    conceptFieldsDefenitions,
    onSubmit,
  };
};
