import { IAutocompleteFilter, getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useHealthProgramMembersAutocompleteQuery } from "./gql";
import { IHealthProgramMemberAutocomplete } from "./schema";

export const createHealthProgramMemberAutocompleteOption = (member: IHealthProgramMemberAutocomplete) => {
  return createAutocompleteOption(
    {
      id: member?.id!,
      firstName: member?.firstName,
      lastName: member?.lastName,
    },
    "id",
    item => `${item?.firstName} ${item?.lastName}`
  );
};

export const getHealthProgramMembersAutocompleteFilter = ({ name, multiple }: IAutocompleteFilter) => {
  return getAutocompleteFilter({
    name,
    multiple,
    accessor: "healthProgramMembers",
    query: useHealthProgramMembersAutocompleteQuery,
    labelBy: option => `${option?.firstName} ${option?.lastName}`,
    backendAccessor: "id",
    filterSearchKey: "name",
  });
};
