import { Grid } from "@toolkit/ui";
import { DecisionActionFieldTypeForm } from "../DecisionActionFieldType/DecisionActionFieldTypeForm";
import { useCustomFormContext } from "@toolkit/core";
import { IDecisionUpsertFormValues } from "../DecisionUpsert/DecisionUpsertFormSchema";

export const DecisionEffectsForm = () => {
  const form = useCustomFormContext<IDecisionUpsertFormValues>();

  const { watch } = form;

  const actionFields = watch("actionFields");

  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        {actionFields?.map((item, index) => (
          <Grid key={item?.code} item xs={12} md={4}>
            <DecisionActionFieldTypeForm index={index} item={item} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
