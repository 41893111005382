import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramMemberInput;
}>;


export type HealthProgramMemberCreateMutation = { __typename?: 'Mutation', healthProgramMemberCreate?: { __typename?: 'HealthProgramMemberCRUD', entity?: { __typename?: 'HealthProgramMember', id: string } | null } | null };


export const HealthProgramMemberCreateDocument = gql`
    mutation HealthProgramMemberCreate($input: HealthProgramMemberInput!) {
  healthProgramMemberCreate(input: $input) {
    entity {
      id
    }
  }
}
    `;
export type HealthProgramMemberCreateMutationFn = Apollo.MutationFunction<HealthProgramMemberCreateMutation, HealthProgramMemberCreateMutationVariables>;

/**
 * __useHealthProgramMemberCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramMemberCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMemberCreateMutation, { data, loading, error }] = useHealthProgramMemberCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramMemberCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMemberCreateMutation, HealthProgramMemberCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMemberCreateMutation, HealthProgramMemberCreateMutationVariables>(HealthProgramMemberCreateDocument, options);
      }
export type HealthProgramMemberCreateMutationHookResult = ReturnType<typeof useHealthProgramMemberCreateMutation>;
export type HealthProgramMemberCreateMutationResult = Apollo.MutationResult<HealthProgramMemberCreateMutation>;
export type HealthProgramMemberCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramMemberCreateMutation, HealthProgramMemberCreateMutationVariables>;