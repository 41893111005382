import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProviderSubscriptionTerminationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ProviderSubscriptionTerminationMutation = { __typename?: 'Mutation', terminateVendorSubscription?: { __typename?: 'TerminateVendorSubscription', vendorErrors: Array<{ __typename?: 'VendorError', field?: string | null, message?: string | null }> } | null };


export const ProviderSubscriptionTerminationDocument = gql`
    mutation ProviderSubscriptionTermination($id: ID!) {
  terminateVendorSubscription(vendor: $id) {
    vendorErrors {
      field
      message
    }
  }
}
    `;
export type ProviderSubscriptionTerminationMutationFn = Apollo.MutationFunction<ProviderSubscriptionTerminationMutation, ProviderSubscriptionTerminationMutationVariables>;

/**
 * __useProviderSubscriptionTerminationMutation__
 *
 * To run a mutation, you first call `useProviderSubscriptionTerminationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderSubscriptionTerminationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerSubscriptionTerminationMutation, { data, loading, error }] = useProviderSubscriptionTerminationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderSubscriptionTerminationMutation(baseOptions?: Apollo.MutationHookOptions<ProviderSubscriptionTerminationMutation, ProviderSubscriptionTerminationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProviderSubscriptionTerminationMutation, ProviderSubscriptionTerminationMutationVariables>(ProviderSubscriptionTerminationDocument, options);
      }
export type ProviderSubscriptionTerminationMutationHookResult = ReturnType<typeof useProviderSubscriptionTerminationMutation>;
export type ProviderSubscriptionTerminationMutationResult = Apollo.MutationResult<ProviderSubscriptionTerminationMutation>;
export type ProviderSubscriptionTerminationMutationOptions = Apollo.BaseMutationOptions<ProviderSubscriptionTerminationMutation, ProviderSubscriptionTerminationMutationVariables>;