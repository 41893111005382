import { z } from "zod";
import { ExtractNodeType } from "@toolkit/apollo";
import { createZodAutocompleteFromType } from "@toolkit/core";
import { MedicalMessagesAutocompleteQuery } from "./gql";

export type IMedicalMessageAutocomplete = ExtractNodeType<MedicalMessagesAutocompleteQuery>;

export const medicalMessageSchema = createZodAutocompleteFromType<IMedicalMessageAutocomplete>({
  id: z.string(),
  code: z.string().nullish(),
  name: z.string().nullish(),
});
