import { FeatureFlag } from "@/schema/types";
import { useGetFeatureFlagQuery } from "./gql/queries";

export enum FeatureEnum {
  HealthPackage = "HealthPackage",
  Marketplace = "Marketplace",
  Prescription = "Prescription",
  ManualPrescription = "ManualPrescription",
  FamilySync = "FamilySync",
  DependentCreate = "DependentCreate",
}

const FeatureEnumMap: Record<FeatureEnum, keyof FeatureFlag> = {
  [FeatureEnum.HealthPackage]: "healthPackageFeatureEnabled",
  [FeatureEnum.Marketplace]: "marketplaceFeatureEnabled",
  [FeatureEnum.Prescription]: "PrescriptionFeatureEnabled",
  [FeatureEnum.ManualPrescription]: "manualPrescriptionFeatureEnabled",
  [FeatureEnum.DependentCreate]: "dependentCreationFeatureEnabled",
  [FeatureEnum.FamilySync]: "familySyncFeatureEnabled",
};

export type IsFeatureEnabledType = (features: Array<FeatureEnum>) => boolean;

export const useFeatureFlag = () => {
  const { data, loading } = useGetFeatureFlagQuery();
  const featureFlag = data?.featureFlag;

  // const featureFlag = {
  //   healthPackageFeatureEnabled: true,
  //   marketplaceFeatureEnabled: true,
  // };

  const isFeatureEnabled: IsFeatureEnabledType = (features: Array<FeatureEnum>) => {
    if (loading) {
      return false;
    }
    return features.length ? features.some(feature => featureFlag?.[FeatureEnumMap[feature]]) : false;
  };

  return {
    isFeatureEnabled,
  };
};
