import { HealthProgramNetworkProviderProfessional } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";
import { HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnable } from "../../components/HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnable/HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnable";

export const useHealthProgramNetworkProviderProfessionalsColumns =
  (): CustomTableColumnProps<HealthProgramNetworkProviderProfessional>[] => {
    const { t } = useTranslation("admin");

    return useMemo(
      () => [
        {
          key: "name",
          header: t("Name"),
          accessor: ({ professional }) => professional?.user?.fullName,
        },
        {
          key: "overridingVirtualCall",
          header: t("Overriding Virtual Call"),
          accessor: ({ id, overridingVirtualCallEnabled }) => (
            <HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnable id={id} isEnabled={!!overridingVirtualCallEnabled} />
          ),
        },
      ],
      [t]
    );
  };
