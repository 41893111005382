import { createPathDefinition } from "@toolkit/core";
import { optimaMedicalNecessityPath } from "../../constants/OptimaMedicalNecessityPaths";

export const diagnosisValidProceduresRoute = optimaMedicalNecessityPath + "/diagnosis-valid-procedure";

export const diagnosisValidProceduresPaths = createPathDefinition(diagnosisValidProceduresRoute, {
  list: "",
  create: "new",
  update: ":diagnosisValidProcedureId",
});
