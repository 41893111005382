import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DiagnosisValidProcedureGetQueryVariables = Types.Exact<{
  getDiagnosisValidProcedureId: Types.Scalars['ID']['input'];
}>;


export type DiagnosisValidProcedureGetQuery = { __typename?: 'Query', getDiagnosisValidProcedure?: { __typename?: 'DiagnosisValidProcedure', procedureCode: string, diagnosisCode: string, description?: string | null } | null };


export const DiagnosisValidProcedureGetDocument = gql`
    query DiagnosisValidProcedureGet($getDiagnosisValidProcedureId: ID!) {
  getDiagnosisValidProcedure(id: $getDiagnosisValidProcedureId) {
    procedureCode
    diagnosisCode
    description
  }
}
    `;

/**
 * __useDiagnosisValidProcedureGetQuery__
 *
 * To run a query within a React component, call `useDiagnosisValidProcedureGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagnosisValidProcedureGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagnosisValidProcedureGetQuery({
 *   variables: {
 *      getDiagnosisValidProcedureId: // value for 'getDiagnosisValidProcedureId'
 *   },
 * });
 */
export function useDiagnosisValidProcedureGetQuery(baseOptions: Apollo.QueryHookOptions<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables> & ({ variables: DiagnosisValidProcedureGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables>(DiagnosisValidProcedureGetDocument, options);
      }
export function useDiagnosisValidProcedureGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables>(DiagnosisValidProcedureGetDocument, options);
        }
export function useDiagnosisValidProcedureGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables>(DiagnosisValidProcedureGetDocument, options);
        }
export type DiagnosisValidProcedureGetQueryHookResult = ReturnType<typeof useDiagnosisValidProcedureGetQuery>;
export type DiagnosisValidProcedureGetLazyQueryHookResult = ReturnType<typeof useDiagnosisValidProcedureGetLazyQuery>;
export type DiagnosisValidProcedureGetSuspenseQueryHookResult = ReturnType<typeof useDiagnosisValidProcedureGetSuspenseQuery>;
export type DiagnosisValidProcedureGetQueryResult = Apollo.QueryResult<DiagnosisValidProcedureGetQuery, DiagnosisValidProcedureGetQueryVariables>;