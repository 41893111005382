import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Grid } from "@toolkit/ui";
import { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from "react";
import { FormProvider } from "react-hook-form";

import {
  bulkMessageSenderUsersFormDefaultValues,
  bulkMessageSenderUsersFormSchema,
  IBulkMessageSenderUsersFormValues,
} from "./BulkMessageSenderUsersFormSchema";
import { UsersAutocomplete } from "@health/autocompletes";
import { AppTypes } from "@/schema/types";

export type IBulkMessageSenderUsersUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IBulkMessageSenderUsersFormValues;
  };
};

type BulkMessageSenderUsersUpsertFormProps = {
  isSubmitting?: boolean;
  onChange: (event: IBulkMessageSenderUsersUpsertFormEvent) => void;
};

export type BulkMessageSenderUsersUpsertFormRef = {
  submit: () => void;
};

const BulkMessageSenderUsersUpsertFormForwardRef: ForwardRefRenderFunction<
  BulkMessageSenderUsersUpsertFormRef,
  BulkMessageSenderUsersUpsertFormProps
> = (props, ref) => {
  const { onChange } = props;

  const { t } = useTranslation("admin");

  const form = useCustomForm<IBulkMessageSenderUsersFormValues>({
    defaultValues: bulkMessageSenderUsersFormDefaultValues,
    schema: bulkMessageSenderUsersFormSchema,
  });

  const onSubmit = (values: IBulkMessageSenderUsersFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  useImperativeHandle(ref, () => ({
    submit: () => form.handleSubmit(onSubmit)(),
  }));

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UsersAutocomplete
              name='users'
              label={t("Patients")}
              placeholder={t("Patients")}
              multiple
              filter={{
                appType: [AppTypes.Customer],
              }}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const BulkMessageSenderUsersUpsertForm = forwardRef(BulkMessageSenderUsersUpsertFormForwardRef);
