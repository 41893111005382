import { Button, CustomIcon, Divider, Menu, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useActionsMenuHooks } from "./ActionsMenu.hooks";
import { ApproveRejectButtons } from "./ApproveRejectButtons";
import { AssignButton } from "./AssignButton";
import { CloneButton } from "./CloneButton";
import { DownloadButton } from "./DownloadButton";
import { FetchToSubListButton } from "./FetchToSublistButton";
import { ImportConceptsDialog } from "./ImportConceptsDialog";
import { PublishButton } from "./PublishButton";
import { SendToReviewButton } from "./SendToReviewButton";

export const ActionsMenu: FC = () => {
  const {
    hasData,
    hasClone,
    hasImport,
    hasDownload,
    hasApproveReject,
    hasPublish,
    isDraft,
    hasReassign,
    isMenuOpened,
    menuActionsAnchorEl,
    t,
    handleOpenMenuToAnchor,
    handleCloseMenu,
  } = useActionsMenuHooks();
  const theme = useTheme();
  return (
    <>
      <Button
        id='med-list-actions-dropDown-button'
        aria-controls={isMenuOpened ? "med-list-dropDown-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={isMenuOpened ? "true" : undefined}
        onClick={handleOpenMenuToAnchor}
        endIcon={<CustomIcon icon='expandArrow' color={theme.palette.common.white} />}
      >
        {t("Actions")}
      </Button>
      <Menu
        id='med-list-dropDown-menu'
        anchorEl={menuActionsAnchorEl}
        open={isMenuOpened}
        keepMounted
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "med-list-actions-dropDown-button",
        }}
      >
        <>
          {hasImport && <ImportConceptsDialog onClick={handleCloseMenu} />}
          {hasDownload && <DownloadButton onClick={handleCloseMenu} hasData={hasData} />}
          <Divider />
          {hasClone && <CloneButton disabled={!hasData} onClick={handleCloseMenu} />}
          {hasApproveReject && <ApproveRejectButtons onClick={handleCloseMenu} />}
          {hasPublish && <PublishButton onClick={handleCloseMenu} />}
          {isDraft && <SendToReviewButton onClick={handleCloseMenu} />}
          {hasReassign && <AssignButton onClick={handleCloseMenu} />}
          <FetchToSubListButton isDisabled={!hasData} onClick={handleCloseMenu} />
        </>
      </Menu>
    </>
  );
};
